import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { LocalDateC } from "../../codecs/localDate";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { LocalDate } from "@js-joda/core";

export type ReminderC = t.TypeC<{
  notifyDate: LocalDateC
}>;
export type Reminder = {
  notifyDate: LocalDate
};
export const reminderC: ReminderC = t.type({
  notifyDate: LocalDateC
}) satisfies t.Type<Reminder, unknown>;


export const document = {
  _tag: `Document`,
  name: `Document`
} as const;

export type DocumentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Document`>
}>;
export const documentTaggedC: DocumentTaggedC = t.type({
  _tag: t.literal(`Document`)
});
export type DocumentTagged = t.TypeOf<DocumentTaggedC>;
export type Document = DocumentTagged & typeof document;
export type DocumentC = t.Type<Document, DocumentTagged>;
export const documentC: DocumentC = pipe(documentTaggedC, c => new t.Type<Document, DocumentTagged>(
  `Document`,
  (u: unknown): u is Document => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Document> => pipe(c.decode(u), E.map(x => ({ ...x, ...document }))),
  (x: Document): DocumentTagged => ({ ...x, _tag: `Document`}),
)) satisfies t.Type<Document, unknown>;


export const offering = {
  _tag: `Offering`,
  name: `Offering`
} as const;

export type OfferingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Offering`>
}>;
export const offeringTaggedC: OfferingTaggedC = t.type({
  _tag: t.literal(`Offering`)
});
export type OfferingTagged = t.TypeOf<OfferingTaggedC>;
export type Offering = OfferingTagged & typeof offering;
export type OfferingC = t.Type<Offering, OfferingTagged>;
export const offeringC: OfferingC = pipe(offeringTaggedC, c => new t.Type<Offering, OfferingTagged>(
  `Offering`,
  (u: unknown): u is Offering => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Offering> => pipe(c.decode(u), E.map(x => ({ ...x, ...offering }))),
  (x: Offering): OfferingTagged => ({ ...x, _tag: `Offering`}),
)) satisfies t.Type<Offering, unknown>;


export const allReminderTypeC = [documentC, offeringC] as const;
export const allReminderTypeNames = [`Document`, `Offering`] as const;
export type ReminderTypeName = (typeof allReminderTypeNames)[number];

export type ReminderTypeCU = t.UnionC<[DocumentC, OfferingC]>;
export type ReminderTypeU = Document | Offering;
export const ReminderTypeCU: ReminderTypeCU = t.union([documentC, offeringC]) satisfies t.Type<ReminderTypeU, unknown>;

export const reminderTypeOrd: Ord.Ord<ReminderTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allReminderType = [document, offering] as const;
export type ReminderTypeMap<A> = { [K in ReminderTypeName]: A };


export type NextReminderDataC = t.TypeC<{
  notifyDate: LocalDateC,
  reminderType: ReminderTypeCU,
  displayName: t.StringC
}>;
export type NextReminderData = {
  notifyDate: LocalDate,
  reminderType: ReminderTypeU,
  displayName: string
};
export const nextReminderDataC: NextReminderDataC = t.type({
  notifyDate: LocalDateC,
  reminderType: ReminderTypeCU,
  displayName: t.string
}) satisfies t.Type<NextReminderData, unknown>;


