import { IssuerC as imported35_IssuerC, Issuer as imported35_Issuer, issuerC as imported33_issuerC } from "./issuer";
import * as t from "io-ts";

export type PrivateSiteC = t.TypeC<{
  issuer: imported35_IssuerC,
  redirect: t.StringC
}>;
export type PrivateSite = {
  issuer: imported35_Issuer,
  redirect: string
};
export const privateSiteC: PrivateSiteC = t.type({
  issuer: imported33_issuerC,
  redirect: t.string
}) satisfies t.Type<PrivateSite, unknown>;


export type ConfirmSitePasswordC = t.TypeC<{
  id: t.NumberC,
  publishPassword: t.StringC,
  redirect: t.StringC
}>;
export type ConfirmSitePassword = {
  id: number,
  publishPassword: string,
  redirect: string
};
export const confirmSitePasswordC: ConfirmSitePasswordC = t.type({
  id: t.number,
  publishPassword: t.string,
  redirect: t.string
}) satisfies t.Type<ConfirmSitePassword, unknown>;


