import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as t from "io-ts";
import V from "voca";

import { type PageU, SortableCU, type SortableU } from "@scripts/generated/domaintables/pages";
import type { SortColumnU } from "@scripts/generated/domaintables/sortColumns";
import { ASC, DESC } from "@scripts/generated/domaintables/sortDir";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import { type PageSort, pageSortC, type PageSorts } from "@scripts/generated/models/pageSorts";
import type { SelectOption } from "@scripts/react/components/form/Select";

import { getPage } from "./pageConfig";

const pageSortTupleC = t.tuple([SortableCU, pageSortC]);

const isPageSorts = (x: PageSorts | ReadonlyArray<PageConfig<PageU>>): x is PageSorts => pipe(
  RA.head<unknown>(x),
  O.fold(() => true, pageSortTupleC.is),
);

export const getPageSort = (page: SortableU) => (pages: PageSorts | ReadonlyArray<PageConfig<PageU>>): PageSort => pipe(
  isPageSorts(pages)
    ? pipe(pages, RA.findFirst(p => p[0].id === page.id), O.map(_ => _[1]))
    : pipe(getPage(page)(pages), O.chain(_ => _.sort)),
  O.getOrElse((): PageSort => ({ column: page.defaultSortCol, direction: page.defaultSortDir }))
);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-unnecessary-type-parameters
export const makeSortOptionValue = <SB extends string>(sort: PageSort): SB => makeSortableColumnName(sort.column.columnName, sort.direction.dir) as SB;

export const makeSortableColumnName = (column: string, direction: string) => `${V.camelCase(column)}-${direction}`;

export const makeTwoSortOptions = (column: SortColumnU): ReadonlyArray<SelectOption<PageSort>> => {
  return [
    {
      label: `${column.humanName} ↑`,
      value: { column, direction: ASC },
    },
    {
      label: `${column.humanName} ↓`,
      value: { column, direction: DESC },
    },
  ];
};

export const makeSortOptions = (columns: ReadonlyArray<SortColumnU>) =>
  columns.reduce((acc: ReadonlyArray<SelectOption<PageSort>>, curr: SortColumnU) => [...acc, ...(makeTwoSortOptions(curr))], []);
