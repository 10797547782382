import * as t from "io-ts";

export type ValidateUrlC = t.TypeC<{
  url: t.StringC
}>;
export type ValidateUrl = {
  url: string
};
export const validateUrlC: ValidateUrlC = t.type({
  url: t.string
}) satisfies t.Type<ValidateUrl, unknown>;


