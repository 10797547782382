import type { ReactElement } from "react";
import { StrictMode } from "react";
import { Provider } from "react-redux";
import type { Store } from "redux";
import { parseLocation } from "ssr/issuersites/assets/scripts/router";

import type { BLConfigWithLog } from "@scripts/bondlink";
import type { PhotoEditableU } from "@scripts/generated/domaintables/pages";
import { ErrorBoundary } from "@scripts/react/components/error/ErrorBoundary";
import { ConfigProvider } from "@scripts/react/context/Config";
import { SentryTransactionBoundary } from "@scripts/sentry-components";

import type { IssuerSitesState } from "../state/state";
import type { IssuerSitesActions } from "../state/store";
import { type PageProps, PageWithProfiler } from "./Page";

type PageLoaderProps = {
  config: BLConfigWithLog;
  render: () => ReactElement;
  pageTitle: string;
  photoPage: PhotoEditableU;
  store: Store<IssuerSitesState, IssuerSitesActions>;
  url: string;
  blpLoginRequired: PageProps["blpLoginRequired"];
};

export const PageLoader = (
  props: PageLoaderProps
) => {
  const sitesPageMeta = parseLocation(props.url);

  return (
    <StrictMode>
      <ErrorBoundary config={props.config}>
        <SentryTransactionBoundary
          transactionName={"SsrPage"}
          appName={"IssuerSites"}
          pageTitle={props.pageTitle}
        >
          <ConfigProvider value={props.config}>
            <Provider store={props.store}>
              <PageWithProfiler
                title={props.pageTitle}
                url={props.url}
                photoPage={props.photoPage}
                routeMeta={sitesPageMeta}
                blpLoginRequired={props.blpLoginRequired}
              >
                {props.render()}
              </PageWithProfiler>
            </Provider>
          </ConfigProvider>
        </SentryTransactionBoundary>
      </ErrorBoundary>
    </StrictMode>
  );
};
