import * as t from "io-ts";
import { NumberFromString } from "io-ts-types";

import { archiveStatusC } from "@scripts/codecs/archiveStatus";
import { allC, defaultOperationType, type FilterOperationType } from "@scripts/codecs/filter";
import { LocalDateC } from "@scripts/codecs/localDate";
import { allProductFocusLookupC, type AllProductFocusU } from "@scripts/codecs/productFocus";
import { recipientTypeC } from "@scripts/codecs/recipients";
import { QueryAnyAllNone, QueryStringArray } from "@scripts/codecs/routing";
import { type AllSectorOptionU, sectorLookupC } from "@scripts/codecs/sector";
import { stateLookupC } from "@scripts/codecs/states";
import { tagLookupC } from "@scripts/codecs/tagLookup";
import { allUserPersonaLookupC } from "@scripts/codecs/userPersona";
import { BiddingStateCU, type BiddingStateU } from "@scripts/generated/domaintables/biddingStates";
import type { StateInfoU } from "@scripts/generated/domaintables/states";
import type { UserPersonaU } from "@scripts/generated/domaintables/userPersonas";
import { localDateNow } from "@scripts/syntax/date/joda";
import { defaultUserStatus, userStatusC } from "@scripts/syntax/userStatus";

import { defaultStartDate } from "./date";
import { dealIdC } from "./dealIdC";

export const analyticsActionC = t.union([
  allC,
  t.literal("pageViews"),
  t.literal("documentDownloads"),
  t.literal("roadshowViews"),
  t.literal("infoRequests"),
  t.literal("emailActions"),
]);
type AnalyticsActionC = typeof analyticsActionC;
export type AnalyticsAction = t.TypeOf<AnalyticsActionC>;

export const analyticsActionParamC = t.type({ action: analyticsActionC });
type AnalyticsActionParamC = typeof analyticsActionParamC;
export type AnalyticsActionParam = t.TypeOf<AnalyticsActionParamC>;
export const analyticsActionParamDefault: AnalyticsActionParam = { action: allC.value };

export const recipientsParamC = t.type({ recipients: recipientTypeC });
type RecipientsParamC = typeof recipientsParamC;
export type RecipientsParam = t.TypeOf<RecipientsParamC>;
export const recipientsParamDefault: RecipientsParam = { recipients: allC.value };

export const organizationsKey = "organizations";
export type OrganizationsKey = typeof organizationsKey;
export const organizationsParamC = t.type({ [organizationsKey]: QueryStringArray(t.string) });
type OrganizationsParamC = typeof organizationsParamC;
export type OrganizationsParam = t.TypeOf<OrganizationsParamC>;
export const organizationsParamDefault: OrganizationsParam = { [organizationsKey]: [] };

export const userTypeKey = "userType";
export type UserTypeKey = typeof userTypeKey;
export const userTypeParamC: t.TypeC<{ [userTypeKey]: t.Type<ReadonlyArray<UserPersonaU>, string | undefined> }> =
  t.type({ [userTypeKey]: QueryStringArray(allUserPersonaLookupC) });
type UserTypeParamC = typeof userTypeParamC;
export type UserTypeParam = t.TypeOf<UserTypeParamC>;
export const userTypeParamDefault: UserTypeParam = { [userTypeKey]: [] };

export const sectorFocusKey = "sectorFocus";
export type SectorFocusKey = typeof sectorFocusKey;
export const sectorFocusParamC: t.TypeC<{ [sectorFocusKey]: t.Type<ReadonlyArray<AllSectorOptionU>, string | undefined> }> =
  t.type({ [sectorFocusKey]: QueryStringArray(sectorLookupC) });
type SectorFocusParamC = typeof sectorFocusParamC;
export type SectorFocusParam = t.TypeOf<SectorFocusParamC>;
export const sectorFocusParamDefault: SectorFocusParam = { [sectorFocusKey]: [] };

export const productFocusKey = "productFocus";
export type ProductFocusKey = typeof productFocusKey;
export const productFocusParamC: t.TypeC<{ [productFocusKey]: t.Type<ReadonlyArray<AllProductFocusU>, string | undefined> }> =
  t.type({ [productFocusKey]: QueryStringArray(allProductFocusLookupC) });
type ProductFocusParamC = typeof productFocusParamC;
export type ProductFocusParam = t.TypeOf<ProductFocusParamC>;
export const productFocusParamDefault: ProductFocusParam = { [productFocusKey]: [] };

export const userStatusKey = "userStatus";
export type UserStatusKey = typeof userStatusKey;
export const userStatusParamC = t.type({ [userStatusKey]: userStatusC });
type UserStatusParamC = typeof userStatusParamC;
export type UserStatusParam = t.TypeOf<UserStatusParamC>;
export const userStatusParamDefault: UserStatusParam = { [userStatusKey]: defaultUserStatus };

export const startDateKey = "startDate";
export type StartDateKey = typeof startDateKey;
export const startDateParamC = t.type({ [startDateKey]: LocalDateC });
type StartDateParamC = typeof startDateParamC;
export type StartDateParam = t.TypeOf<StartDateParamC>;
export const startDateParamDefault: StartDateParam = { [startDateKey]: defaultStartDate() };

export const endDateKey = "endDate";
export type EndDateKey = typeof endDateKey;
export const endDateParamC = t.type({ [endDateKey]: LocalDateC });
type EndDateParamC = typeof endDateParamC;
export type EndDateParam = t.TypeOf<EndDateParamC>;
export const endDateParamDefault: EndDateParam = { [endDateKey]: localDateNow() };

export const bondIdsKey = "bondIds";
export type BondIdsKey = typeof bondIdsKey;
export const bondIdsParamC = t.type({ [bondIdsKey]: QueryStringArray(NumberFromString) });
type BondIdsParamC = typeof bondIdsParamC;
export type BondIdsParam = t.TypeOf<BondIdsParamC>;
export const bondIdsParamDefault: BondIdsParam = { [bondIdsKey]: [] };

export const issuerIdsKey = "issuerIds";
export type IssuerIdsKey = typeof issuerIdsKey;
export const issuerIdsParamC = t.type({ [issuerIdsKey]: QueryStringArray(NumberFromString) });
type IssuerIdsParamC = typeof issuerIdsParamC;
export type IssuerIdsParam = t.TypeOf<IssuerIdsParamC>;
export const issuerIdsParamDefault: IssuerIdsParam = { [issuerIdsKey]: [] };

export const rfpIdsKey = "rfpIds";
export type RfpIdsKey = typeof rfpIdsKey;
export const rfpIdsParamC = t.type({ [rfpIdsKey]: QueryStringArray(NumberFromString) });
type RfpIdsParamC = typeof rfpIdsParamC;
export type RfpIdsParam = t.TypeOf<RfpIdsParamC>;
export const rfpIdsParamDefault: RfpIdsParam = { [rfpIdsKey]: [] };

export const sectorsKey = "sectors";
export type SectorsKey = typeof sectorsKey;
export const sectorsParamC: t.TypeC<{ [sectorsKey]: t.Type<ReadonlyArray<AllSectorOptionU>, string | undefined> }> =
  t.type({ [sectorsKey]: QueryStringArray(sectorLookupC) });
type SectorsParamC = typeof sectorsParamC;
export type SectorsParam = t.TypeOf<SectorsParamC>;
export const sectorsParamDefault: SectorsParam = { [sectorsKey]: [] };

export const statesKey = "states";
export type StatesKey = typeof statesKey;
export const statesParamC: t.TypeC<{ [statesKey]: t.Type<ReadonlyArray<StateInfoU>, string | undefined> }> =
  t.type({ [statesKey]: QueryStringArray(stateLookupC) });
type StatesParamC = typeof statesParamC;
export type StatesParam = t.TypeOf<StatesParamC>;
export const statesParamDefault: StatesParam = { [statesKey]: [] };

export const bondStatusKey = "bondStatus";
export type BondStatusKey = typeof bondStatusKey;
export const bondStatusParamC = t.type({ [bondStatusKey]: archiveStatusC });
type BondStatusParamC = typeof bondStatusParamC;
export type BondStatusParam = t.TypeOf<BondStatusParamC>;
export const bondStatusParamDefault: BondStatusParam = { [bondStatusKey]: allC.value };

export type OperationFilterParam<T> = { operation: FilterOperationType, selection: ReadonlyArray<T> };
export const allAnyNoneParamDefault = <T>(): OperationFilterParam<T> => ({ operation: defaultOperationType, selection: [] });

const documentCategoriesKey = "documentCategories";
export type DocumentCategoriesKey = typeof documentCategoriesKey;
export const documentCategoriesParamC = t.type({ [documentCategoriesKey]: QueryAnyAllNone(NumberFromString) });
type DocumentCategoriesParamC = typeof documentCategoriesParamC;
export type DocumentCategoriesParam = t.TypeOf<DocumentCategoriesParamC>;
export const documentCategoriesParamDefault: DocumentCategoriesParam = { [documentCategoriesKey]: allAnyNoneParamDefault() };

const rfpStatusKey = "rfpStatus";
export type RfpStatusKey = typeof rfpStatusKey;
export const rfpStatusParamC: t.TypeC<{ [rfpStatusKey]: t.Type<ReadonlyArray<BiddingStateU>, string | undefined> }> =
  t.type({ [rfpStatusKey]: QueryStringArray(tagLookupC(BiddingStateCU)) });
type RfpStatusParamC = typeof rfpStatusParamC;
export type RfpStatusParam = t.TypeOf<RfpStatusParamC>;
export const rfpStatusParamDefault: RfpStatusParam = { [rfpStatusKey]: [] };

export const dealIdsKey = "dealIds";
export type DealIdsKey = typeof dealIdsKey;
export const dealIdsParamC = t.type({ [dealIdsKey]: QueryStringArray(dealIdC) });
type DealIdsParamC = typeof dealIdsParamC;
export type DealIdsParam = t.TypeOf<DealIdsParamC>;
export const dealIdsParamDefault: DealIdsParam = { [dealIdsKey]: [] };


// Combined params

export const bondFiltersParamBaseC = t.type({ ...issuerIdsParamC.props, ...sectorsParamC.props, ...statesParamC.props });
type BondFiltersParamBaseC = typeof bondFiltersParamBaseC;
export type BondFiltersParamBase = t.TypeOf<BondFiltersParamBaseC>;
export const bondFiltersParamBaseDefault: BondFiltersParamBase = { ...issuerIdsParamDefault, ...sectorsParamDefault, ...statesParamDefault };
export type BondFiltersBaseKeys = keyof typeof bondFiltersParamBaseDefault;

export const bondFiltersParamC = t.type({ ...bondFiltersParamBaseC.props, ...bondStatusParamC.props });
type BondFiltersParamC = typeof bondFiltersParamC;
export type BondFiltersParam = t.TypeOf<BondFiltersParamC>;
export const bondFiltersParamDefault: BondFiltersParam = { ...bondFiltersParamBaseDefault, ...bondStatusParamDefault };
export type BondFiltersKeys = keyof typeof bondFiltersParamDefault;

export const bondFiltersWithIdsParamC = t.type({ ...bondFiltersParamC.props, ...bondIdsParamC.props });
type BondFiltersWithIdsParamC = typeof bondFiltersWithIdsParamC;
export type BondFiltersWithIdsParam = t.TypeOf<BondFiltersWithIdsParamC>;
export const bondFiltersWithIdsParamDefault: BondFiltersWithIdsParam = { ...bondFiltersParamDefault, ...bondIdsParamDefault };
export type BondFiltersWithIdsKeys = keyof typeof bondFiltersWithIdsParamDefault;

export const rfpsFiltersParamBaseC = t.type({ ...issuerIdsParamC.props, ...sectorsParamC.props, ...statesParamC.props });
type rfpsFiltersParamBaseC = typeof rfpsFiltersParamBaseC;
export type rfpsFiltersParamBase = t.TypeOf<rfpsFiltersParamBaseC>;
export const rfpsFiltersParamBaseDefault: rfpsFiltersParamBase = { ...issuerIdsParamDefault, ...sectorsParamDefault, ...statesParamDefault };
export type rfpsFiltersBaseKeys = keyof typeof rfpsFiltersParamBaseDefault;

export const dealFiltersWithIdsParamsC = t.type({
  ...issuerIdsParamC.props,
  ...sectorsParamC.props,
  ...statesParamC.props,
  ...bondStatusParamC.props,
  ...dealIdsParamC.props,
});
type DealFiltersWithIdsParamC = typeof dealFiltersWithIdsParamsC;
export type DealFiltersWithIdsParam = t.TypeOf<DealFiltersWithIdsParamC>;
export const dealFiltersWithIdsParamDefault: DealFiltersWithIdsParam = {
  ...bondFiltersParamBaseDefault,
  ...bondStatusParamDefault,
  ...dealIdsParamDefault,
};
export type DealFiltersWithIdsKeys = keyof typeof dealFiltersWithIdsParamDefault;

export const userFiltersParamBaseC = t.type({ ...organizationsParamC.props, ...userTypeParamC.props, ...sectorFocusParamC.props, ...productFocusParamC.props, ...analyticsActionParamC.props });
type UserFiltersParamBaseC = typeof userFiltersParamBaseC;
export type UserFiltersParamBase = t.TypeOf<UserFiltersParamBaseC>;
export const userFiltersParamBaseDefault: UserFiltersParamBase = { ...organizationsParamDefault, ...userTypeParamDefault, ...sectorFocusParamDefault, ...productFocusParamDefault, ...analyticsActionParamDefault };
export type UserFiltersBaseKeys = keyof typeof userFiltersParamBaseDefault;

export const userFiltersParamC = t.type({ ...userFiltersParamBaseC.props, ...userStatusParamC.props });
type UserFiltersParamC = typeof userFiltersParamC;
export type UserFiltersParam = t.TypeOf<UserFiltersParamC>;
export const userFiltersParamDefault: UserFiltersParam = { ...userFiltersParamBaseDefault, ...userStatusParamDefault };
export type UserFiltersKeys = keyof typeof userFiltersParamDefault;

export const analyticsBaseQueryParamsC = t.type({
  ...analyticsActionParamC.props,
  ...userFiltersParamBaseC.props,
});

export type AnalyticsBaseQueryParams = typeof analyticsBaseQueryParamsC._A;
export const analyticsBaseDefaultParams: AnalyticsBaseQueryParams = {
  ...analyticsActionParamDefault,
  ...userFiltersParamBaseDefault,
};
