import { E } from "@scripts/fp-ts";
import * as p from "@scripts/generated/domaintables/pages";
import type { Officer } from "@scripts/generated/models/officer";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";
import { fullName } from "@scripts/syntax/fullName";

export const officer = (o: WithStatusU<Officer>): SitesJumpLink =>
  SitesJumpLink(fullName(o.data.record), [], { hidden: !o.data.record.officerPageVisibility });

export const allStatic: ReadonlyArray<SitesJumpLink> = [SitesJumpLink("Team", [E.right(p.financeTeam)])];
