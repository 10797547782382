import { withIdC as imported198_withIdC, WithModInfoC as imported157_WithModInfoC, WithIdC as imported198_WithIdC, WithId as imported198_WithId, WithModInfo as imported157_WithModInfo, withModInfoC as imported157_withModInfoC } from "./threadThrough";
import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { LocalDateTime } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as O from "fp-ts/lib/Option";
import { ContentNoteC as imported4_ContentNoteC, ContentNote as imported4_ContentNote, contentNoteC as imported3_contentNoteC } from "./contentNote";

export type ContactPostC = t.TypeC<{
  email: t.StringC,
  phoneNumber: OptionFromNullableC<t.StringC>,
  firstName: t.StringC,
  lastName: t.StringC,
  company: OptionFromNullableC<t.StringC>,
  title: OptionFromNullableC<t.StringC>,
  body: t.StringC,
  captcha: t.StringC,
  bondOfferingId: OptionFromNullableC<t.NumberC>,
  rfpId: OptionFromNullableC<t.NumberC>
}>;
export type ContactPost = {
  email: string,
  phoneNumber: O.Option<string>,
  firstName: string,
  lastName: string,
  company: O.Option<string>,
  title: O.Option<string>,
  body: string,
  captcha: string,
  bondOfferingId: O.Option<number>,
  rfpId: O.Option<number>
};
export const contactPostC: ContactPostC = t.type({
  email: t.string,
  phoneNumber: optionFromNullable(t.string),
  firstName: t.string,
  lastName: t.string,
  company: optionFromNullable(t.string),
  title: optionFromNullable(t.string),
  body: t.string,
  captcha: t.string,
  bondOfferingId: optionFromNullable(t.number),
  rfpId: optionFromNullable(t.number)
}) satisfies t.Type<ContactPost, unknown>;


export type ContactFormSubmissionC = t.TypeC<{
  email: t.StringC,
  phoneNumber: OptionFromNullableC<t.StringC>,
  firstName: t.StringC,
  lastName: t.StringC,
  subject: t.StringC,
  body: t.StringC,
  company: OptionFromNullableC<t.StringC>,
  title: OptionFromNullableC<t.StringC>,
  userId: OptionFromNullableC<t.NumberC>,
  sent: LocalDateTimeFromIsoStringC
}>;
export type ContactFormSubmission = {
  email: string,
  phoneNumber: O.Option<string>,
  firstName: string,
  lastName: string,
  subject: string,
  body: string,
  company: O.Option<string>,
  title: O.Option<string>,
  userId: O.Option<number>,
  sent: LocalDateTime
};
export const contactFormSubmissionC: ContactFormSubmissionC = t.type({
  email: t.string,
  phoneNumber: optionFromNullable(t.string),
  firstName: t.string,
  lastName: t.string,
  subject: t.string,
  body: t.string,
  company: optionFromNullable(t.string),
  title: optionFromNullable(t.string),
  userId: optionFromNullable(t.number),
  sent: LocalDateTimeFromIsoStringC
}) satisfies t.Type<ContactFormSubmission, unknown>;


export type ContactFormSubmissionWithNoteC = t.TypeC<{
  contactFormSubmission: ContactFormSubmissionC,
  contentNote: OptionFromNullableC<imported198_WithIdC<imported157_WithModInfoC<imported4_ContentNoteC>>>
}>;
export type ContactFormSubmissionWithNote = {
  contactFormSubmission: ContactFormSubmission,
  contentNote: O.Option<imported198_WithId<imported157_WithModInfo<imported4_ContentNote>>>
};
export const contactFormSubmissionWithNoteC: ContactFormSubmissionWithNoteC = t.type({
  contactFormSubmission: contactFormSubmissionC,
  contentNote: optionFromNullable(imported198_withIdC(imported157_withModInfoC(imported3_contentNoteC)))
}) satisfies t.Type<ContactFormSubmissionWithNote, unknown>;


