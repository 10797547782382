import type { BLConfigWithLog } from "@scripts/bondlink";
import { O, pipe, s } from "@scripts/fp-ts";
import type { DateQualifier } from "@scripts/generated/models/dateQualifier";
import type { DocumentWithCategory } from "@scripts/generated/models/document";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { Anchor } from "@scripts/react/components/Anchor";
import type { TableColumnRow, TableSortColumnName } from "@scripts/react/components/table/tableSyntax";
import { TooltipO, type TooltipProps } from "@scripts/react/components/Tooltip";
import { useConfig } from "@scripts/react/context/Config";
import { dateQualifierOrd, qualifierFormat } from "@scripts/syntax/date/dateQualifier";

import { IssuerSitesTable, IssuerSitesTableColumnHeader, type IssuerSitesTableSortProps } from "../../components/IssuerSitesTable";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { useIssuerSitesSelector } from "../../state/store";

export type DocumentsRowModel = {
  date: DateQualifier;
  name: string;
  category: string;
};

export type DocumentsTableModel = TableColumnRow<DocumentsRowModel, WithStatusU<TaggedContent<DocumentWithCategory>>, ["download"]>;

export type DocumentsSortColumnTypes = TableSortColumnName<keyof DocumentsRowModel>;

type DocumentsSortProps = IssuerSitesTableSortProps<keyof DocumentsRowModel>;

export const DocumentsDataMapFn = (document: WithStatusU<TaggedContent<DocumentWithCategory>>): DocumentsTableModel["Row"] => ({
  __klass: O.none,
  __metadata: document,
  __rowId: document.data.id,
  date: document.data.record.data.document.mediaDate,
  name: document.data.record.data.document.uploadResponse.viewName,
  category: pipe(document.data.record.data.docType, O.fold(() => document.data.record.data.category.categoryName, dt => dt.categoryName)),
});

const nameCell = (issuer: Issuer) => (r: DocumentsTableModel["Row"]) => pipe(
  r.__metadata.data.record.data.document.caption,
  O.map((summary): TooltipProps => ({
    headerBar: {
      type: "HeaderBarNoButton",
      title: "Document Summary",
    },
    description: {
      type: "DescriptionContent",
      text: summary,
    },
    delay: "table",
  })),
  tooltip => <TooltipO tooltip={tooltip}>
    <span>
      <Anchor
        target="_self"
        route={{
          title: r.name,
          route: SitesRouter.issuersitesReportsControllerViewFile({ issuerSlug: issuer.slug, issuerId: issuer.id, mediaId: r.__metadata.data.id }),
        }}
        klasses="mr-05"
      />
      <IssuerSitesLeafIcon taggedContent={r.__metadata.data.record} />
    </span>
  </TooltipO>
);

const columns = (config: BLConfigWithLog) => (issuer: Issuer, sort: DocumentsSortProps): DocumentsTableModel["Columns"] => ({
  "date": {
    title: "Date",
    dataCellKlass: O.some("white-space-nowrap"),
    headerComponent: () => <IssuerSitesTableColumnHeader {...sort} colName="date" />,
    dataCellComponent: r => qualifierFormat(config)(r.date),
    sort: { direction: "both", ord: dateQualifierOrd(config) },
  },
  "name": {
    title: "Name",
    dataCellKlass: O.none,
    headerComponent: () => <IssuerSitesTableColumnHeader {...sort} colName="name" />,
    dataCellComponent: nameCell(issuer),
    sort: { direction: "both", ord: s.Ord },

  },
  "category": {
    title: "Category",
    dataCellKlass: O.none,
    headerComponent: () => <IssuerSitesTableColumnHeader {...sort} colName="category" />,
    sort: { direction: "both", ord: s.Ord },
  },
  "download": {
    title: "Download",
    excludeFromSearch: true,
    headerComponent: "empty",
    dataCellKlass: O.some("white-space-nowrap"),
    dataCellComponent: r => <Anchor target="_blank" externalLinkLocation="none" route={{
      title: "Download ↓",
      route: SitesRouter.issuersitesReportsControllerDownloadRedirect({
        issuerId: issuer.id,
        issuerSlug: issuer.slug,
        mediaId: r.__metadata.data.id,
      }),
    }}
    />,
  },
});

export const DocumentsTable = (props: {
  documents: ReadonlyArray<DocumentsTableModel["Row"]>;
  sort: DocumentsSortProps;
  search: O.Option<string>;
  page: O.Option<number>;
  setPage: (page: O.Option<number>) => void;
}) => {
  const config = useConfig();
  const issuer = useIssuerSitesSelector("issuer");
  return <IssuerSitesTable
    data={props.documents}
    detailCell={O.none}
    columns={columns(config)(issuer, props.sort)}
    exporter={O.none}
    sortable={O.some<DocumentsSortColumnTypes>(props.sort.sortBy)}
    searchable={pipe(props.search, O.map(_ => [O.none, _]))}
    paginate={props.page}
    onParamsChanged={({ page }) => props.setPage(O.fromNullable(page))}
    tableAction={O.none}
    hideActionsPanel
    variant="issuersites"
  />;
};
