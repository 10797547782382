import * as t from "io-ts";
import { ActorIdCU as imported5_ActorIdCU, ActorIdU as imported2_ActorIdU, ActorIdCU as imported4_ActorIdCU } from "./actor";

export type ValidateEmailC = t.TypeC<{
  email: t.StringC
}>;
export type ValidateEmail = {
  email: string
};
export const validateEmailC: ValidateEmailC = t.type({
  email: t.string
}) satisfies t.Type<ValidateEmail, unknown>;


export type ActorPostEmailRecipientC = t.TypeC<{
  _tag: t.LiteralC<`ActorPostEmailRecipient`>,
  id: imported5_ActorIdCU
}>;
export type ActorPostEmailRecipient = {
  _tag: `ActorPostEmailRecipient`,
  id: imported2_ActorIdU
};
export const actorPostEmailRecipientC: ActorPostEmailRecipientC = t.type({
  _tag: t.literal(`ActorPostEmailRecipient`),
  id: imported4_ActorIdCU
}) satisfies t.Type<ActorPostEmailRecipient, unknown>;


export type CustomPostEmailRecipientC = t.TypeC<{
  _tag: t.LiteralC<`CustomPostEmailRecipient`>,
  email: t.StringC
}>;
export type CustomPostEmailRecipient = {
  _tag: `CustomPostEmailRecipient`,
  email: string
};
export const customPostEmailRecipientC: CustomPostEmailRecipientC = t.type({
  _tag: t.literal(`CustomPostEmailRecipient`),
  email: t.string
}) satisfies t.Type<CustomPostEmailRecipient, unknown>;


export const allPostEmailRecipientC = [actorPostEmailRecipientC, customPostEmailRecipientC] as const;
export const allPostEmailRecipientNames = [`ActorPostEmailRecipient`, `CustomPostEmailRecipient`] as const;
export type PostEmailRecipientName = (typeof allPostEmailRecipientNames)[number];

export type PostEmailRecipientCU = t.UnionC<[ActorPostEmailRecipientC, CustomPostEmailRecipientC]>;
export type PostEmailRecipientU = ActorPostEmailRecipient | CustomPostEmailRecipient;
export const PostEmailRecipientCU: PostEmailRecipientCU = t.union([actorPostEmailRecipientC, customPostEmailRecipientC]) satisfies t.Type<PostEmailRecipientU, unknown>;

export type PostEmailRecipientMap<A> = { [K in PostEmailRecipientName]: A };


