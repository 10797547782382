import { useCallback } from "react";

import { O, pipe } from "@scripts/fp-ts";
import type { UserWithRoles } from "@scripts/generated/models/user";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { type LoginReasonO, type OnSubscribe } from "@scripts/react/components/SubscriptionToggle";
import { useConfig } from "@scripts/react/context/Config";
import { openInSameTab } from "@scripts/routes/router";
import { getAbsoluteUrl } from "@scripts/routes/urlInterface";

export type LoginParams = Pick<V2Router.BaseAuthControllerLoginParams, "bankId" | "issuerId">;

const useRedirectAndSubscribe = (): (loginParams: LoginParams, redirect: string, reason: LoginReasonO) => void => {
  const config = useConfig();

  const makeRedirectAndSubscribe = useCallback((
    loginParams: LoginParams,
    redirect: string,
    reason: LoginReasonO
  ) => openInSameTab(
    `${config.baseUrl}${V2Router.baseAuthControllerLogin({
      ...loginParams,
      reason: reason,
      redirect: O.some(redirect),
      uhash: O.none,
    }).url}`),
    [config.baseUrl]
  );

  return makeRedirectAndSubscribe;
};

export type MakeDealSubscribeRedirect = (
  loginParams: LoginParams,
  dealId: number
) => OnSubscribe;

export const useBondSubscribeRedirect = (): MakeDealSubscribeRedirect => {
  const makeRedirect = useRedirectAndSubscribe();

  const makeBondSubscribeRedirect = useCallback((
    loginParams: LoginParams,
    offeringId: number
  ): OnSubscribe =>
    () => makeRedirect(
      loginParams,
      V2Router.investorPortalOfferingsControllerSubscribeRedirect({ offeringId, redirect: getAbsoluteUrl() }).url,
      O.some("bookmark-bond")
    ),
    [makeRedirect]
  );

  return makeBondSubscribeRedirect;
};

export const useRfpSubscribeRedirect = (): MakeDealSubscribeRedirect => {
  const makeRedirect = useRedirectAndSubscribe();

  const makeRfpSubscribeRedirect = useCallback((
    loginParams: LoginParams,
    rfpId: number
  ): OnSubscribe =>
    () => makeRedirect(
      loginParams,
      V2Router.investorPortalRfpsControllerSubscribeRedirect({ rfpId, redirect: getAbsoluteUrl() }).url,
      O.some("bookmark-rfp")
    ),
    [makeRedirect]
  );

  return makeRfpSubscribeRedirect;
};

export const useIssuerSubscribeRedirect = (): (issuerId: number) => OnSubscribe => {
  const makeRedirect = useRedirectAndSubscribe();

  const makeIssuerSubscribeRedirect = useCallback((
    issuerId: number,
  ): OnSubscribe =>
    () => makeRedirect(
      { issuerId: O.some(issuerId), bankId: O.none },
      V2Router.investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirect({
        issuerId,
        redirect: getAbsoluteUrl(),
      }).url,
      O.some("notifications")
    ),
    [makeRedirect]
  );

  return makeIssuerSubscribeRedirect;
};

export const handleUser = (
  onLoggedIn: OnSubscribe,
  onLoggedOut: OnSubscribe,
  user: O.Option<UserWithRoles>
): OnSubscribe => () => {
  pipe(
    user,
    O.fold(
      onLoggedOut,
      onLoggedIn
    )
  );
};
