import { UserContentSubscriptionTypeCU as imported5_UserContentSubscriptionTypeCU, UserContentSubscriptionTypeU as imported2_UserContentSubscriptionTypeU, UserContentSubscriptionTypeCU as imported4_UserContentSubscriptionTypeCU } from "../domaintables/userContentSubscriptionTypes";
import { IpreoHoldingsC as imported10_IpreoHoldingsC, IpreoHoldings as imported10_IpreoHoldings, ipreoHoldingsC as imported10_ipreoHoldingsC } from "./ipreoHoldings";
import * as t from "io-ts";
import { InvestorActivityCU as imported4_InvestorActivityCU, InvestorActivityCU as imported5_InvestorActivityCU, TabCountsC as imported3_TabCountsC, InvestorActivityU as imported2_InvestorActivityU, tabCountsC as imported3_tabCountsC, TabCounts as imported3_TabCounts } from "./investorActivityActions";
import { ActorC as imported7_ActorC, Actor as imported7_Actor, actorC as imported7_actorC } from "./actor";

export type InvestorProfileInfoC = t.TypeC<{
  actor: imported7_ActorC,
  notificationSubscriptions: t.ReadonlyArrayC<imported5_UserContentSubscriptionTypeCU>,
  investorActivities: t.ReadonlyArrayC<imported5_InvestorActivityCU>,
  tabCounts: imported3_TabCountsC,
  holdings: t.ReadonlyArrayC<imported10_IpreoHoldingsC>
}>;
export type InvestorProfileInfo = {
  actor: imported7_Actor,
  notificationSubscriptions: ReadonlyArray<imported2_UserContentSubscriptionTypeU>,
  investorActivities: ReadonlyArray<imported2_InvestorActivityU>,
  tabCounts: imported3_TabCounts,
  holdings: ReadonlyArray<imported10_IpreoHoldings>
};
export const investorProfileInfoC: InvestorProfileInfoC = t.type({
  actor: imported7_actorC,
  notificationSubscriptions: t.readonlyArray(imported4_UserContentSubscriptionTypeCU),
  investorActivities: t.readonlyArray(imported4_InvestorActivityCU),
  tabCounts: imported3_tabCountsC,
  holdings: t.readonlyArray(imported10_ipreoHoldingsC)
}) satisfies t.Type<InvestorProfileInfo, unknown>;


