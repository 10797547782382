import type { BLConfigWithLog } from "@scripts/bondlink";
import { makeDeepLinkBasic } from "@scripts/codecs/deepLinkTarget";
import { O } from "@scripts/fp-ts";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { Issuer } from "@scripts/generated/models/issuer";
import * as SR from "@scripts/generated/routers/sitesRouter";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { issuerSiteAbsUrl } from "@scripts/syntax/issuer";

export const pageIssuerSiteAbsoluteUrl = (config: BLConfigWithLog, page: PageU, issuer: Issuer): string => {
  const mkUrl = issuerSiteAbsUrl(config)(issuer, O.none);

  const home = () => mkUrl(SR.issuersitesIssuerControllerIndex)({});
  const resources = () => mkUrl(SR.issuersitesResourcesControllerIndex)({});
  const custom = mkUrl(SR.issuersitesIssuerControllerCustom);
  const cusips = () => mkUrl(SR.issuersitesEmmaControllerLinks)({ cusip9: O.none }).url;

  switch (page._tag) {
    case "FinanceTeam": return mkUrl(SR.issuersitesAboutControllerTeam)({}).url;
    case "BondRatings":
    case "IssuerRatings":
      return mkUrl(SR.issuersitesBondOfferingsControllerRatings)({}).url;
    case "EmmaLinks":
    case "Cusip6":
      return cusips();
    case "InfrastructureProjects": return mkUrl(SR.issuersitesAboutControllerProjects)({}).url;
    case "NewsEventsPage":
    case "News":
      return mkUrl(SR.issuersitesAboutControllerNewsEvents)({}).url;
    case "RelatedIssuers": return makeDeepLinkBasic(jl.home.relatedIssuers.sectionId)(home());
    case "CustomPage1": return custom({ i: 1 }).url;
    case "CustomPage2": return custom({ i: 2 }).url;
    case "CustomPage3": return custom({ i: 3 }).url;
    case "CustomPage4": return custom({ i: 4 }).url;
    case "CustomPage5": return custom({ i: 5 }).url;
    case "Contact":
    case "RequestMoreInfoButton":
      return makeDeepLinkBasic(jl.resources.contact.sectionId)(resources());
    case "Faq": return mkUrl(SR.issuersitesResourcesControllerFaq)({}).url;
    case "Homepage": return home().url;
    case "AboutPage": return mkUrl(SR.issuersitesAboutControllerIndex)({}).url;
    case "DocumentsPage": return mkUrl(SR.issuersitesReportsControllerIndex)({}).url;
    case "ResourcesPage": return resources().url;
    case "BondsPage": return mkUrl(SR.issuersitesBondOfferingsControllerIndex)({}).url;
    case "IrmaLetter": return mkUrl(SR.issuersitesReportsControllerIrmaLetter)({}).url;
    case "EsgProgram": return mkUrl(SR.issuersitesAboutControllerEsgProgram)({}).url;
    case "LinksPage": return mkUrl(SR.issuersitesResourcesControllerLinks)({}).url;
    case "BondArchive": return mkUrl(SR.issuersitesBondOfferingsControllerArchivedBonds)({}).url;
    case "RfpsPage": return mkUrl(SR.issuersitesRfpsControllerRfps)({}).url;
    case "BondProgramsPage": return mkUrl(SR.issuersitesBondProgramsControllerBondPrograms)({}).url;

    default: return config.exhaustive(page);
  }
};

export const pageIssuerSiteAbsoluteUrlNoCustomDomain = (config: BLConfigWithLog, page: PageU, issuer: Issuer): string =>
  pageIssuerSiteAbsoluteUrl(config, page, { ...issuer, customDomain: O.none });
