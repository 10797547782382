import { useCallback, useState } from "react";

import type { Markdown as MarkdownType } from "@scripts/codecs/markdown";
import { O, pipe, RNEA } from "@scripts/fp-ts";
import { documents } from "@scripts/generated/domaintables/featureFlags";
import type { BankRfpSitesData, GenRfpWithRelatedContent, RfpSitesRelatedContent } from "@scripts/generated/models/rfp";
import type { Subscribed } from "@scripts/generated/models/subscribed";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { RfpFacts } from "@scripts/react/actions/rfp-pages/RfpSummary";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { RFPQuestionsAndAnswers } from "@scripts/react/components/Faq";
import { Grid } from "@scripts/react/components/layout/Grid";
import { MainGridLeft, SideBarGridRightWithBorder } from "@scripts/react/components/layout/PageGrid";
import { AccentDividerSection, DividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import { Participants } from "@scripts/react/components/offering-pages/OfferingParticipants";
import { makeRelatedContentDataO, makeRoadshowDataO, parseRoadshows, ProgramWithRatingRelatedContent, RoadshowsSection, SummaryRelatedContent } from "@scripts/react/components/offering-pages/RelatedContent";
import { WinningBidSectionSites } from "@scripts/react/components/rfp-pages/WinningBidOverview";
import { SidebarAboutSection } from "@scripts/react/components/SidebarAbout";
import { ProjectSidebarSection, TeamSidebarSection } from "@scripts/react/components/SidebarModalLinks";
import { RfpBadges, useRfpBidSubmissionState } from "@scripts/react/rfp/rfpBadge";
import { klass } from "@scripts/react/util/classnames";
import { useModal } from "@scripts/react/util/useModal";
import { isDrafted, modifyWithStatus } from "@scripts/syntax/threadThrough";

import { useRfpSubscribe } from "../../api/watchlist";
import { BidSubmissionSection } from "../../components/BidSubmissionSection";
import { IssuerSitesLeafIcon } from "../../components/LeafIcon";
import { HeaderWithSubscription } from "../../components/offering-pages/Header";
import { useIssuerSitesSelector } from "../../state/store";
import { useOnSubscribe } from "../../syntax/onSubscribe";
import { bidSubmissionLens } from "../../syntax/rfp";
import { RfpQuestionsAndAnswersCallout } from "../direct-rfp/RfpRelatedContent";

type BankRfp = Subscribed<WithStatusU<TaggedContent<GenRfpWithRelatedContent<RfpSitesRelatedContent>>>>;

export const BankRfpPage = (props: BankRfpSitesData) => {
  const [rfp, setRfp] = useState(props.rfp);
  const iffs = useIssuerSitesSelector("iffs");
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const makeOnSubscribe = useRfpSubscribe();
  const user = useIssuerSitesSelector("user");

  const [isSubscribed, onSubscribe, setIsSubscribed] = useOnSubscribe(rfp.subscribed, makeOnSubscribe, rfp.data.data.id);
  const [modalOpen, openModal, closeModal] = useModal("Bid Submission Modal");

  const setSubscribedToRfp = useCallback(() => {
    if (O.isSome(user)) {
      setIsSubscribed(true);
    }
  }, [setIsSubscribed, user]);

  const bidSubmissionState = useRfpBidSubmissionState(
    rfp.data.data.id,
    rfp.data.data.record.data.rfp,
    rfp.data.data.record.data.relatedContent.bidSubmissionTemplate,
    rfp.data.data.record.data.relatedContent.bidSubmission,
  );

  const roadshowDataO = makeRoadshowDataO(iffs, parseRoadshows(rfp.data.data.record.data.relatedContent.roadShows));
  const documentsDataO = makeRelatedContentDataO(documents, iffs, rfp.data.data.record.data.relatedContent.documents.map(_ => _.data));
  const linksDataO = RNEA.fromReadonlyArray(rfp.data.data.record.data.relatedContent.externalLinks);

  return (
    <div {...klass("container")}>
      <Grid klasses={O.none} attrs={O.none}>
        <MainGridLeft>
          <AccentDividerSection title={O.none} suppressDivider="xs" klasses={"mb-0"}>
            <RfpBadges
              rfp={rfp.data.data.record.data.rfp}
              bidSubmissionState={bidSubmissionState}
            />
            <HeaderWithSubscription
              isSubscribed={isSubscribed}
              onSubscribe={onSubscribe}
              taggedContent={O.some(rfp.data.data.record)}
              title={rfp.data.data.record.data.rfp.name}
              viewAllRoute={O.none}
              subscribeDisabled={isDrafted(rfp.data)}
            />
          </AccentDividerSection>
          <AccentDividerSection title={O.some("Summary")}>
            <DividerSection title={O.none}>
              <RfpFacts rfp={rfp.data.data.record.data.rfp} />
            </DividerSection>
            {mapOrEmpty((md: MarkdownType) =>
              <DividerSection
                klasses={O.none}
                title={O.some("Project Description")}
              >
                <Markdown content={md} />
              </DividerSection>
            )(rfp.data.data.record.data.rfp.projectDescription)}
            {mapOrEmpty((md: MarkdownType) =>
              <DividerSection
                klasses={O.none}
                title={O.some("Submission Requirements")}
              >
                <Markdown content={md} />
              </DividerSection>
            )(rfp.data.data.record.data.rfp.submissionRequirements)}
            <SummaryRelatedContent
              documentsO={documentsDataO}
              documentsHeadline="Documents"
              documentDownloadRoute={(issuerId, mediaId) => V2Router.investorPortalRfpsControllerDownloadDocument({ issuerId, rfpId: rfp.data.data.id, mediaId })}
              issuer={issuer}
              linksO={linksDataO}
              variant="page"
              leafIcon={IssuerSitesLeafIcon}
            />
            {pipe(
              rfp.data.data.record.data.relatedContent.program,
              mapOrEmpty(_ =>
                <ProgramWithRatingRelatedContent
                  program={_}
                  issuer={issuer}
                  variant={"page"}
                />
              )
            )}
          </AccentDividerSection>
          <BidSubmissionSection
            user={user}
            rfp={pipe(rfp.data, modifyWithStatus(_ => _.data.rfp))}
            bidSubmissionTemplate={rfp.data.data.record.data.relatedContent.bidSubmissionTemplate}
            bidSubmission={rfp.data.data.record.data.relatedContent.bidSubmission}
            setBidSubmission={s => setRfp(bidSubmissionLens<BankRfp>().set(O.some(s))(rfp))}
            issuer={issuer}
            bidSubmissionState={bidSubmissionState}
            subscription={O.some({ isSubscribed, onSubscribe })}
            setSubscribedToRfp={setSubscribedToRfp}
            openModal={openModal}
            closeModal={closeModal}
            modalOpen={modalOpen}
          />
          <WinningBidSectionSites rfp={props.rfp.data.data.record.data.rfp} pages={pages} />
          <RoadshowsSection
            issuer={issuer}
            pages={pages}
            roadshows={roadshowDataO}
            leafIcon={IssuerSitesLeafIcon}
          />
          {pipe(
            RNEA.fromReadonlyArray(props.participants),
            mapOrEmpty(offeringParticipants =>
              <Participants
                title="Participants"
                participants={offeringParticipants}
              />
            ))}
          <RFPQuestionsAndAnswers
            rfpData={props.rfp.data.data.record.data}
            contactCallout={<RfpQuestionsAndAnswersCallout
              rfp={rfp.data.data.record.data.rfp}
              rfpId={rfp.data.data.id}
            />}
          />
        </MainGridLeft>
        <SideBarGridRightWithBorder>
          {pipe(
            props.issuerAboutText,
            mapOrEmpty(issuerAboutText =>
              <SidebarAboutSection issuer={issuer}>
                <Markdown content={issuerAboutText.data} />
              </SidebarAboutSection>
            )
          )}
          {pipe(
            props.officers,
            RNEA.fromReadonlyArray,
            mapOrEmpty(team => <TeamSidebarSection team={team} />)
          )}
          {pipe(
            props.projects,
            RNEA.fromReadonlyArray,
            mapOrEmpty(projects => <ProjectSidebarSection projects={projects} />)
          )}
        </SideBarGridRightWithBorder>
      </Grid>
    </div>
  );
};
