import { WithStatusCU as imported509_WithStatusCU, WithStatusCU as imported512_WithStatusCU, WithStatusU as imported256_WithStatusU, WithStatusCU as imported515_WithStatusCU, WithStatusCU as imported513_WithStatusCU, WithStatusCU as imported508_WithStatusCU, WithStatusU as imported253_WithStatusU, WithStatusU as imported257_WithStatusU, WithStatusCU as imported514_WithStatusCU, WithStatusCU as imported507_WithStatusCU, WithStatusCU as imported506_WithStatusCU, WithStatusU as imported255_WithStatusU, WithStatusU as imported254_WithStatusU, WithStatusCU as imported511_WithStatusCU, WithStatusCU as imported510_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { mediaC as imported51_mediaC, MediaC as imported55_MediaC, ImageUploadResponse as imported6_ImageUploadResponse, MediaC as imported53_MediaC, Media as imported54_Media, mediaC as imported50_mediaC, mediaC as imported52_mediaC, Media as imported53_Media, imageUploadResponseC as imported6_imageUploadResponseC, ImageUploadResponseC as imported6_ImageUploadResponseC, Media as imported55_Media, MediaC as imported54_MediaC } from "./media";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";
import { SortOrderC as imported22_SortOrderC, SortOrder as imported22_SortOrder, sortOrderC as imported22_sortOrderC } from "./sortOrder";
import { CustomPageCU as imported11_CustomPageCU, CustomPageU as imported3_CustomPageU, BucketCU as imported16_BucketCU, CustomPageCU as imported9_CustomPageCU, BucketCU as imported14_BucketCU, BucketCU as imported17_BucketCU, BucketU as imported8_BucketU, CustomPageU as imported4_CustomPageU, BucketCU as imported13_BucketCU, BucketU as imported6_BucketU, CustomPageU as imported5_CustomPageU, CustomPageCU as imported10_CustomPageCU, BucketCU as imported15_BucketCU, CustomPageCU as imported7_CustomPageCU, BucketU as imported7_BucketU, BucketCU as imported12_BucketCU, CustomPageCU as imported8_CustomPageCU, CustomPageCU as imported6_CustomPageCU } from "../domaintables/pages";
import { DataAndMetaC as imported3_DataAndMetaC, DataAndMeta as imported3_DataAndMeta, dataAndMetaC as imported3_dataAndMetaC } from "./relatedContent";
import { ClientTextItem as imported23_ClientTextItem, clientTextItemC as imported22_clientTextItemC, ClientTextItemC as imported23_ClientTextItemC, clientTextItemC as imported23_clientTextItemC, ClientTextItem as imported21_ClientTextItem, clientTextItemC as imported21_clientTextItemC, ClientTextItemC as imported21_ClientTextItemC, ClientTextItemC as imported22_ClientTextItemC, ClientTextItem as imported22_ClientTextItem } from "./clientTextItem";

export type CustomPageC = t.TypeC<{
  index: t.NumberC,
  title: t.StringC,
  bucket: imported13_BucketCU
}>;
export type CustomPage = {
  index: number,
  title: string,
  bucket: imported6_BucketU
};
export const customPageC: CustomPageC = t.type({
  index: t.number,
  title: t.string,
  bucket: imported12_BucketCU
}) satisfies t.Type<CustomPage, unknown>;


export type CustomPagesC = t.TypeC<{
  customPages: t.ReadonlyArrayC<CustomPageC>
}>;
export type CustomPages = {
  customPages: ReadonlyArray<CustomPage>
};
export const customPagesC: CustomPagesC = t.type({
  customPages: t.readonlyArray(customPageC)
}) satisfies t.Type<CustomPages, unknown>;


export type CustomPageDataIdC = t.TypeC<{
  page: imported7_CustomPageCU,
  title: t.StringC,
  bucket: imported15_BucketCU,
  data: imported508_WithStatusCU<imported21_ClientTextItemC>,
  photos: t.ReadonlyArrayC<imported509_WithStatusCU<imported53_MediaC>>
}>;
export type CustomPageDataId = {
  page: imported3_CustomPageU,
  title: string,
  bucket: imported7_BucketU,
  data: imported253_WithStatusU<imported21_ClientTextItem>,
  photos: ReadonlyArray<imported254_WithStatusU<imported53_Media>>
};
export const customPageDataIdC: CustomPageDataIdC = t.type({
  page: imported6_CustomPageCU,
  title: t.string,
  bucket: imported14_BucketCU,
  data: imported506_WithStatusCU(imported21_clientTextItemC),
  photos: t.readonlyArray(imported507_WithStatusCU(imported50_mediaC))
}) satisfies t.Type<CustomPageDataId, unknown>;


export type CustomPageDataOC = t.TypeC<{
  page: imported9_CustomPageCU,
  title: t.StringC,
  bucket: imported17_BucketCU,
  data: OptionFromNullableC<imported512_WithStatusCU<imported22_ClientTextItemC>>,
  photos: t.ReadonlyArrayC<imported513_WithStatusCU<imported54_MediaC>>
}>;
export type CustomPageDataO = {
  page: imported4_CustomPageU,
  title: string,
  bucket: imported8_BucketU,
  data: O.Option<imported255_WithStatusU<imported22_ClientTextItem>>,
  photos: ReadonlyArray<imported256_WithStatusU<imported54_Media>>
};
export const customPageDataOC: CustomPageDataOC = t.type({
  page: imported8_CustomPageCU,
  title: t.string,
  bucket: imported16_BucketCU,
  data: optionFromNullable(imported510_WithStatusCU(imported22_clientTextItemC)),
  photos: t.readonlyArray(imported511_WithStatusCU(imported51_mediaC))
}) satisfies t.Type<CustomPageDataO, unknown>;


export type CustomPagePostBodyC = t.TypeC<{
  customPageIndex: t.NumberC,
  title: t.StringC,
  parentSectionId: t.NumberC,
  hidden: t.BooleanC,
  data: MarkdownC,
  photos: t.ReadonlyArrayC<imported3_DataAndMetaC<imported6_ImageUploadResponseC, imported22_SortOrderC>>
}>;
export type CustomPagePostBody = {
  customPageIndex: number,
  title: string,
  parentSectionId: number,
  hidden: boolean,
  data: Markdown,
  photos: ReadonlyArray<imported3_DataAndMeta<imported6_ImageUploadResponse, imported22_SortOrder>>
};
export const customPagePostBodyC: CustomPagePostBodyC = t.type({
  customPageIndex: t.number,
  title: t.string,
  parentSectionId: t.number,
  hidden: t.boolean,
  data: markdownC,
  photos: t.readonlyArray(imported3_dataAndMetaC(imported6_imageUploadResponseC, imported22_sortOrderC))
}) satisfies t.Type<CustomPagePostBody, unknown>;


export type SitesCustomPageDataC = t.TypeC<{
  page: imported11_CustomPageCU,
  text: OptionFromNullableC<imported23_ClientTextItemC>,
  photos: t.ReadonlyArrayC<imported515_WithStatusCU<imported55_MediaC>>
}>;
export type SitesCustomPageData = {
  page: imported5_CustomPageU,
  text: O.Option<imported23_ClientTextItem>,
  photos: ReadonlyArray<imported257_WithStatusU<imported55_Media>>
};
export const sitesCustomPageDataC: SitesCustomPageDataC = t.type({
  page: imported10_CustomPageCU,
  text: optionFromNullable(imported23_clientTextItemC),
  photos: t.readonlyArray(imported514_WithStatusCU(imported52_mediaC))
}) satisfies t.Type<SitesCustomPageData, unknown>;


