import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const cafrs = {
  _tag: `Cafrs`,
  categoryGroupId: 1,
  categoryName: `Annual Comprehensive Financial Reports`,
  id: 1
} as const;

export type CafrsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Cafrs`>
}>;
export const cafrsTaggedC: CafrsTaggedC = t.type({
  _tag: t.literal(`Cafrs`)
});
export type CafrsTagged = t.TypeOf<CafrsTaggedC>;
export type Cafrs = CafrsTagged & typeof cafrs;
export type CafrsC = t.Type<Cafrs, CafrsTagged>;
export const cafrsC: CafrsC = pipe(cafrsTaggedC, c => new t.Type<Cafrs, CafrsTagged>(
  `Cafrs`,
  (u: unknown): u is Cafrs => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cafrs> => pipe(c.decode(u), E.map(x => ({ ...x, ...cafrs }))),
  (x: Cafrs): CafrsTagged => ({ ...x, _tag: `Cafrs`}),
)) satisfies t.Type<Cafrs, unknown>;


export const annualOpBdgt = {
  _tag: `AnnualOpBdgt`,
  categoryGroupId: 1,
  categoryName: `Annual Operating Budget`,
  id: 2
} as const;

export type AnnualOpBdgtTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AnnualOpBdgt`>
}>;
export const annualOpBdgtTaggedC: AnnualOpBdgtTaggedC = t.type({
  _tag: t.literal(`AnnualOpBdgt`)
});
export type AnnualOpBdgtTagged = t.TypeOf<AnnualOpBdgtTaggedC>;
export type AnnualOpBdgt = AnnualOpBdgtTagged & typeof annualOpBdgt;
export type AnnualOpBdgtC = t.Type<AnnualOpBdgt, AnnualOpBdgtTagged>;
export const annualOpBdgtC: AnnualOpBdgtC = pipe(annualOpBdgtTaggedC, c => new t.Type<AnnualOpBdgt, AnnualOpBdgtTagged>(
  `AnnualOpBdgt`,
  (u: unknown): u is AnnualOpBdgt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AnnualOpBdgt> => pipe(c.decode(u), E.map(x => ({ ...x, ...annualOpBdgt }))),
  (x: AnnualOpBdgt): AnnualOpBdgtTagged => ({ ...x, _tag: `AnnualOpBdgt`}),
)) satisfies t.Type<AnnualOpBdgt, unknown>;


export const interimBdgt = {
  _tag: `InterimBdgt`,
  categoryGroupId: 1,
  categoryName: `Interim Budgets`,
  id: 3
} as const;

export type InterimBdgtTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InterimBdgt`>
}>;
export const interimBdgtTaggedC: InterimBdgtTaggedC = t.type({
  _tag: t.literal(`InterimBdgt`)
});
export type InterimBdgtTagged = t.TypeOf<InterimBdgtTaggedC>;
export type InterimBdgt = InterimBdgtTagged & typeof interimBdgt;
export type InterimBdgtC = t.Type<InterimBdgt, InterimBdgtTagged>;
export const interimBdgtC: InterimBdgtC = pipe(interimBdgtTaggedC, c => new t.Type<InterimBdgt, InterimBdgtTagged>(
  `InterimBdgt`,
  (u: unknown): u is InterimBdgt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InterimBdgt> => pipe(c.decode(u), E.map(x => ({ ...x, ...interimBdgt }))),
  (x: InterimBdgt): InterimBdgtTagged => ({ ...x, _tag: `InterimBdgt`}),
)) satisfies t.Type<InterimBdgt, unknown>;


export const pensionRpt = {
  _tag: `PensionRpt`,
  categoryGroupId: 1,
  categoryName: `Pension Report/Schedule`,
  id: 4
} as const;

export type PensionRptTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PensionRpt`>
}>;
export const pensionRptTaggedC: PensionRptTaggedC = t.type({
  _tag: t.literal(`PensionRpt`)
});
export type PensionRptTagged = t.TypeOf<PensionRptTaggedC>;
export type PensionRpt = PensionRptTagged & typeof pensionRpt;
export type PensionRptC = t.Type<PensionRpt, PensionRptTagged>;
export const pensionRptC: PensionRptC = pipe(pensionRptTaggedC, c => new t.Type<PensionRpt, PensionRptTagged>(
  `PensionRpt`,
  (u: unknown): u is PensionRpt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PensionRpt> => pipe(c.decode(u), E.map(x => ({ ...x, ...pensionRpt }))),
  (x: PensionRpt): PensionRptTagged => ({ ...x, _tag: `PensionRpt`}),
)) satisfies t.Type<PensionRpt, unknown>;


export const opebRpt = {
  _tag: `OpebRpt`,
  categoryGroupId: 1,
  categoryName: `OPEB Reports`,
  id: 5
} as const;

export type OpebRptTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OpebRpt`>
}>;
export const opebRptTaggedC: OpebRptTaggedC = t.type({
  _tag: t.literal(`OpebRpt`)
});
export type OpebRptTagged = t.TypeOf<OpebRptTaggedC>;
export type OpebRpt = OpebRptTagged & typeof opebRpt;
export type OpebRptC = t.Type<OpebRpt, OpebRptTagged>;
export const opebRptC: OpebRptC = pipe(opebRptTaggedC, c => new t.Type<OpebRpt, OpebRptTagged>(
  `OpebRpt`,
  (u: unknown): u is OpebRpt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OpebRpt> => pipe(c.decode(u), E.map(x => ({ ...x, ...opebRpt }))),
  (x: OpebRpt): OpebRptTagged => ({ ...x, _tag: `OpebRpt`}),
)) satisfies t.Type<OpebRpt, unknown>;


export const quarterlyStmts = {
  _tag: `QuarterlyStmts`,
  categoryGroupId: 1,
  categoryName: `Quarterly Financial Statements`,
  id: 6
} as const;

export type QuarterlyStmtsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`QuarterlyStmts`>
}>;
export const quarterlyStmtsTaggedC: QuarterlyStmtsTaggedC = t.type({
  _tag: t.literal(`QuarterlyStmts`)
});
export type QuarterlyStmtsTagged = t.TypeOf<QuarterlyStmtsTaggedC>;
export type QuarterlyStmts = QuarterlyStmtsTagged & typeof quarterlyStmts;
export type QuarterlyStmtsC = t.Type<QuarterlyStmts, QuarterlyStmtsTagged>;
export const quarterlyStmtsC: QuarterlyStmtsC = pipe(quarterlyStmtsTaggedC, c => new t.Type<QuarterlyStmts, QuarterlyStmtsTagged>(
  `QuarterlyStmts`,
  (u: unknown): u is QuarterlyStmts => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, QuarterlyStmts> => pipe(c.decode(u), E.map(x => ({ ...x, ...quarterlyStmts }))),
  (x: QuarterlyStmts): QuarterlyStmtsTagged => ({ ...x, _tag: `QuarterlyStmts`}),
)) satisfies t.Type<QuarterlyStmts, unknown>;


export const taxCollectionRpt = {
  _tag: `TaxCollectionRpt`,
  categoryGroupId: 1,
  categoryName: `Tax Collection Reports`,
  id: 7
} as const;

export type TaxCollectionRptTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TaxCollectionRpt`>
}>;
export const taxCollectionRptTaggedC: TaxCollectionRptTaggedC = t.type({
  _tag: t.literal(`TaxCollectionRpt`)
});
export type TaxCollectionRptTagged = t.TypeOf<TaxCollectionRptTaggedC>;
export type TaxCollectionRpt = TaxCollectionRptTagged & typeof taxCollectionRpt;
export type TaxCollectionRptC = t.Type<TaxCollectionRpt, TaxCollectionRptTagged>;
export const taxCollectionRptC: TaxCollectionRptC = pipe(taxCollectionRptTaggedC, c => new t.Type<TaxCollectionRpt, TaxCollectionRptTagged>(
  `TaxCollectionRpt`,
  (u: unknown): u is TaxCollectionRpt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TaxCollectionRpt> => pipe(c.decode(u), E.map(x => ({ ...x, ...taxCollectionRpt }))),
  (x: TaxCollectionRpt): TaxCollectionRptTagged => ({ ...x, _tag: `TaxCollectionRpt`}),
)) satisfies t.Type<TaxCollectionRpt, unknown>;


export const bondTrusteeRpt = {
  _tag: `BondTrusteeRpt`,
  categoryGroupId: 1,
  categoryName: `Bond Trustee Reports`,
  id: 8
} as const;

export type BondTrusteeRptTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondTrusteeRpt`>
}>;
export const bondTrusteeRptTaggedC: BondTrusteeRptTaggedC = t.type({
  _tag: t.literal(`BondTrusteeRpt`)
});
export type BondTrusteeRptTagged = t.TypeOf<BondTrusteeRptTaggedC>;
export type BondTrusteeRpt = BondTrusteeRptTagged & typeof bondTrusteeRpt;
export type BondTrusteeRptC = t.Type<BondTrusteeRpt, BondTrusteeRptTagged>;
export const bondTrusteeRptC: BondTrusteeRptC = pipe(bondTrusteeRptTaggedC, c => new t.Type<BondTrusteeRpt, BondTrusteeRptTagged>(
  `BondTrusteeRpt`,
  (u: unknown): u is BondTrusteeRpt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondTrusteeRpt> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondTrusteeRpt }))),
  (x: BondTrusteeRpt): BondTrusteeRptTagged => ({ ...x, _tag: `BondTrusteeRpt`}),
)) satisfies t.Type<BondTrusteeRpt, unknown>;


export const unauditedStmt = {
  _tag: `UnauditedStmt`,
  categoryGroupId: 1,
  categoryName: `Cash-Based or Unaudited Financial Statements`,
  id: 9
} as const;

export type UnauditedStmtTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UnauditedStmt`>
}>;
export const unauditedStmtTaggedC: UnauditedStmtTaggedC = t.type({
  _tag: t.literal(`UnauditedStmt`)
});
export type UnauditedStmtTagged = t.TypeOf<UnauditedStmtTaggedC>;
export type UnauditedStmt = UnauditedStmtTagged & typeof unauditedStmt;
export type UnauditedStmtC = t.Type<UnauditedStmt, UnauditedStmtTagged>;
export const unauditedStmtC: UnauditedStmtC = pipe(unauditedStmtTaggedC, c => new t.Type<UnauditedStmt, UnauditedStmtTagged>(
  `UnauditedStmt`,
  (u: unknown): u is UnauditedStmt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UnauditedStmt> => pipe(c.decode(u), E.map(x => ({ ...x, ...unauditedStmt }))),
  (x: UnauditedStmt): UnauditedStmtTagged => ({ ...x, _tag: `UnauditedStmt`}),
)) satisfies t.Type<UnauditedStmt, unknown>;


export const cip = {
  _tag: `Cip`,
  categoryGroupId: 1,
  categoryName: `Capital Improvement Plan (CIP)`,
  id: 10
} as const;

export type CipTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Cip`>
}>;
export const cipTaggedC: CipTaggedC = t.type({
  _tag: t.literal(`Cip`)
});
export type CipTagged = t.TypeOf<CipTaggedC>;
export type Cip = CipTagged & typeof cip;
export type CipC = t.Type<Cip, CipTagged>;
export const cipC: CipC = pipe(cipTaggedC, c => new t.Type<Cip, CipTagged>(
  `Cip`,
  (u: unknown): u is Cip => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cip> => pipe(c.decode(u), E.map(x => ({ ...x, ...cip }))),
  (x: Cip): CipTagged => ({ ...x, _tag: `Cip`}),
)) satisfies t.Type<Cip, unknown>;


export const debtAffordRpt = {
  _tag: `DebtAffordRpt`,
  categoryGroupId: 1,
  categoryName: `Debt Affordability Report`,
  id: 11
} as const;

export type DebtAffordRptTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DebtAffordRpt`>
}>;
export const debtAffordRptTaggedC: DebtAffordRptTaggedC = t.type({
  _tag: t.literal(`DebtAffordRpt`)
});
export type DebtAffordRptTagged = t.TypeOf<DebtAffordRptTaggedC>;
export type DebtAffordRpt = DebtAffordRptTagged & typeof debtAffordRpt;
export type DebtAffordRptC = t.Type<DebtAffordRpt, DebtAffordRptTagged>;
export const debtAffordRptC: DebtAffordRptC = pipe(debtAffordRptTaggedC, c => new t.Type<DebtAffordRpt, DebtAffordRptTagged>(
  `DebtAffordRpt`,
  (u: unknown): u is DebtAffordRpt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DebtAffordRpt> => pipe(c.decode(u), E.map(x => ({ ...x, ...debtAffordRpt }))),
  (x: DebtAffordRpt): DebtAffordRptTagged => ({ ...x, _tag: `DebtAffordRpt`}),
)) satisfies t.Type<DebtAffordRpt, unknown>;


export const studentForecast = {
  _tag: `StudentForecast`,
  categoryGroupId: 1,
  categoryName: `Student Enrollment Forecast`,
  id: 12
} as const;

export type StudentForecastTaggedC = t.TypeC<{
  _tag: t.LiteralC<`StudentForecast`>
}>;
export const studentForecastTaggedC: StudentForecastTaggedC = t.type({
  _tag: t.literal(`StudentForecast`)
});
export type StudentForecastTagged = t.TypeOf<StudentForecastTaggedC>;
export type StudentForecast = StudentForecastTagged & typeof studentForecast;
export type StudentForecastC = t.Type<StudentForecast, StudentForecastTagged>;
export const studentForecastC: StudentForecastC = pipe(studentForecastTaggedC, c => new t.Type<StudentForecast, StudentForecastTagged>(
  `StudentForecast`,
  (u: unknown): u is StudentForecast => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StudentForecast> => pipe(c.decode(u), E.map(x => ({ ...x, ...studentForecast }))),
  (x: StudentForecast): StudentForecastTagged => ({ ...x, _tag: `StudentForecast`}),
)) satisfies t.Type<StudentForecast, unknown>;


export const feasibilityStudy = {
  _tag: `FeasibilityStudy`,
  categoryGroupId: 1,
  categoryName: `Consultant Report/Feasibility Study`,
  id: 13
} as const;

export type FeasibilityStudyTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FeasibilityStudy`>
}>;
export const feasibilityStudyTaggedC: FeasibilityStudyTaggedC = t.type({
  _tag: t.literal(`FeasibilityStudy`)
});
export type FeasibilityStudyTagged = t.TypeOf<FeasibilityStudyTaggedC>;
export type FeasibilityStudy = FeasibilityStudyTagged & typeof feasibilityStudy;
export type FeasibilityStudyC = t.Type<FeasibilityStudy, FeasibilityStudyTagged>;
export const feasibilityStudyC: FeasibilityStudyC = pipe(feasibilityStudyTaggedC, c => new t.Type<FeasibilityStudy, FeasibilityStudyTagged>(
  `FeasibilityStudy`,
  (u: unknown): u is FeasibilityStudy => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FeasibilityStudy> => pipe(c.decode(u), E.map(x => ({ ...x, ...feasibilityStudy }))),
  (x: FeasibilityStudy): FeasibilityStudyTagged => ({ ...x, _tag: `FeasibilityStudy`}),
)) satisfies t.Type<FeasibilityStudy, unknown>;


export const economicRpt = {
  _tag: `EconomicRpt`,
  categoryGroupId: 1,
  categoryName: `Economic Report/Forecast`,
  id: 14
} as const;

export type EconomicRptTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EconomicRpt`>
}>;
export const economicRptTaggedC: EconomicRptTaggedC = t.type({
  _tag: t.literal(`EconomicRpt`)
});
export type EconomicRptTagged = t.TypeOf<EconomicRptTaggedC>;
export type EconomicRpt = EconomicRptTagged & typeof economicRpt;
export type EconomicRptC = t.Type<EconomicRpt, EconomicRptTagged>;
export const economicRptC: EconomicRptC = pipe(economicRptTaggedC, c => new t.Type<EconomicRpt, EconomicRptTagged>(
  `EconomicRpt`,
  (u: unknown): u is EconomicRpt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EconomicRpt> => pipe(c.decode(u), E.map(x => ({ ...x, ...economicRpt }))),
  (x: EconomicRpt): EconomicRptTagged => ({ ...x, _tag: `EconomicRpt`}),
)) satisfies t.Type<EconomicRpt, unknown>;


export const bankLoan = {
  _tag: `BankLoan`,
  categoryGroupId: 1,
  categoryName: `Bank Loan/Direct Sale`,
  id: 15
} as const;

export type BankLoanTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BankLoan`>
}>;
export const bankLoanTaggedC: BankLoanTaggedC = t.type({
  _tag: t.literal(`BankLoan`)
});
export type BankLoanTagged = t.TypeOf<BankLoanTaggedC>;
export type BankLoan = BankLoanTagged & typeof bankLoan;
export type BankLoanC = t.Type<BankLoan, BankLoanTagged>;
export const bankLoanC: BankLoanC = pipe(bankLoanTaggedC, c => new t.Type<BankLoan, BankLoanTagged>(
  `BankLoan`,
  (u: unknown): u is BankLoan => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankLoan> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankLoan }))),
  (x: BankLoan): BankLoanTagged => ({ ...x, _tag: `BankLoan`}),
)) satisfies t.Type<BankLoan, unknown>;


export const swapIsda = {
  _tag: `SwapIsda`,
  categoryGroupId: 1,
  categoryName: `Swap ISDA`,
  id: 16
} as const;

export type SwapIsdaTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SwapIsda`>
}>;
export const swapIsdaTaggedC: SwapIsdaTaggedC = t.type({
  _tag: t.literal(`SwapIsda`)
});
export type SwapIsdaTagged = t.TypeOf<SwapIsdaTaggedC>;
export type SwapIsda = SwapIsdaTagged & typeof swapIsda;
export type SwapIsdaC = t.Type<SwapIsda, SwapIsdaTagged>;
export const swapIsdaC: SwapIsdaC = pipe(swapIsdaTaggedC, c => new t.Type<SwapIsda, SwapIsdaTagged>(
  `SwapIsda`,
  (u: unknown): u is SwapIsda => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SwapIsda> => pipe(c.decode(u), E.map(x => ({ ...x, ...swapIsda }))),
  (x: SwapIsda): SwapIsdaTagged => ({ ...x, _tag: `SwapIsda`}),
)) satisfies t.Type<SwapIsda, unknown>;


export const utilityRpts = {
  _tag: `UtilityRpts`,
  categoryGroupId: 1,
  categoryName: `Utility System Reports`,
  id: 17
} as const;

export type UtilityRptsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UtilityRpts`>
}>;
export const utilityRptsTaggedC: UtilityRptsTaggedC = t.type({
  _tag: t.literal(`UtilityRpts`)
});
export type UtilityRptsTagged = t.TypeOf<UtilityRptsTaggedC>;
export type UtilityRpts = UtilityRptsTagged & typeof utilityRpts;
export type UtilityRptsC = t.Type<UtilityRpts, UtilityRptsTagged>;
export const utilityRptsC: UtilityRptsC = pipe(utilityRptsTaggedC, c => new t.Type<UtilityRpts, UtilityRptsTagged>(
  `UtilityRpts`,
  (u: unknown): u is UtilityRpts => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UtilityRpts> => pipe(c.decode(u), E.map(x => ({ ...x, ...utilityRpts }))),
  (x: UtilityRpts): UtilityRptsTagged => ({ ...x, _tag: `UtilityRpts`}),
)) satisfies t.Type<UtilityRpts, unknown>;


export const other = {
  _tag: `Other`,
  categoryGroupId: 1,
  categoryName: `Other`,
  id: 23
} as const;

export type OtherTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Other`>
}>;
export const otherTaggedC: OtherTaggedC = t.type({
  _tag: t.literal(`Other`)
});
export type OtherTagged = t.TypeOf<OtherTaggedC>;
export type Other = OtherTagged & typeof other;
export type OtherC = t.Type<Other, OtherTagged>;
export const otherC: OtherC = pipe(otherTaggedC, c => new t.Type<Other, OtherTagged>(
  `Other`,
  (u: unknown): u is Other => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Other> => pipe(c.decode(u), E.map(x => ({ ...x, ...other }))),
  (x: Other): OtherTagged => ({ ...x, _tag: `Other`}),
)) satisfies t.Type<Other, unknown>;


export const otherRpts = {
  _tag: `OtherRpts`,
  categoryGroupId: 1,
  categoryName: `Other Reports`,
  id: 18
} as const;

export type OtherRptsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OtherRpts`>
}>;
export const otherRptsTaggedC: OtherRptsTaggedC = t.type({
  _tag: t.literal(`OtherRpts`)
});
export type OtherRptsTagged = t.TypeOf<OtherRptsTaggedC>;
export type OtherRpts = OtherRptsTagged & typeof otherRpts;
export type OtherRptsC = t.Type<OtherRpts, OtherRptsTagged>;
export const otherRptsC: OtherRptsC = pipe(otherRptsTaggedC, c => new t.Type<OtherRpts, OtherRptsTagged>(
  `OtherRpts`,
  (u: unknown): u is OtherRpts => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OtherRpts> => pipe(c.decode(u), E.map(x => ({ ...x, ...otherRpts }))),
  (x: OtherRpts): OtherRptsTagged => ({ ...x, _tag: `OtherRpts`}),
)) satisfies t.Type<OtherRpts, unknown>;


export const presentations = {
  _tag: `Presentations`,
  categoryGroupId: 1,
  categoryName: `Presentations`,
  id: 19
} as const;

export type PresentationsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Presentations`>
}>;
export const presentationsTaggedC: PresentationsTaggedC = t.type({
  _tag: t.literal(`Presentations`)
});
export type PresentationsTagged = t.TypeOf<PresentationsTaggedC>;
export type Presentations = PresentationsTagged & typeof presentations;
export type PresentationsC = t.Type<Presentations, PresentationsTagged>;
export const presentationsC: PresentationsC = pipe(presentationsTaggedC, c => new t.Type<Presentations, PresentationsTagged>(
  `Presentations`,
  (u: unknown): u is Presentations => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Presentations> => pipe(c.decode(u), E.map(x => ({ ...x, ...presentations }))),
  (x: Presentations): PresentationsTagged => ({ ...x, _tag: `Presentations`}),
)) satisfies t.Type<Presentations, unknown>;


export const officialStmts = {
  _tag: `OfficialStmts`,
  categoryGroupId: 1,
  categoryName: `Official Statements`,
  id: 20
} as const;

export type OfficialStmtsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OfficialStmts`>
}>;
export const officialStmtsTaggedC: OfficialStmtsTaggedC = t.type({
  _tag: t.literal(`OfficialStmts`)
});
export type OfficialStmtsTagged = t.TypeOf<OfficialStmtsTaggedC>;
export type OfficialStmts = OfficialStmtsTagged & typeof officialStmts;
export type OfficialStmtsC = t.Type<OfficialStmts, OfficialStmtsTagged>;
export const officialStmtsC: OfficialStmtsC = pipe(officialStmtsTaggedC, c => new t.Type<OfficialStmts, OfficialStmtsTagged>(
  `OfficialStmts`,
  (u: unknown): u is OfficialStmts => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfficialStmts> => pipe(c.decode(u), E.map(x => ({ ...x, ...officialStmts }))),
  (x: OfficialStmts): OfficialStmtsTagged => ({ ...x, _tag: `OfficialStmts`}),
)) satisfies t.Type<OfficialStmts, unknown>;


export const userImage = {
  _tag: `UserImage`,
  categoryGroupId: 4,
  categoryName: `User Image`,
  id: 21
} as const;

export type UserImageTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UserImage`>
}>;
export const userImageTaggedC: UserImageTaggedC = t.type({
  _tag: t.literal(`UserImage`)
});
export type UserImageTagged = t.TypeOf<UserImageTaggedC>;
export type UserImage = UserImageTagged & typeof userImage;
export type UserImageC = t.Type<UserImage, UserImageTagged>;
export const userImageC: UserImageC = pipe(userImageTaggedC, c => new t.Type<UserImage, UserImageTagged>(
  `UserImage`,
  (u: unknown): u is UserImage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserImage> => pipe(c.decode(u), E.map(x => ({ ...x, ...userImage }))),
  (x: UserImage): UserImageTagged => ({ ...x, _tag: `UserImage`}),
)) satisfies t.Type<UserImage, unknown>;


export const issuerImage = {
  _tag: `IssuerImage`,
  categoryGroupId: 4,
  categoryName: `Issuer Image`,
  id: 22
} as const;

export type IssuerImageTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerImage`>
}>;
export const issuerImageTaggedC: IssuerImageTaggedC = t.type({
  _tag: t.literal(`IssuerImage`)
});
export type IssuerImageTagged = t.TypeOf<IssuerImageTaggedC>;
export type IssuerImage = IssuerImageTagged & typeof issuerImage;
export type IssuerImageC = t.Type<IssuerImage, IssuerImageTagged>;
export const issuerImageC: IssuerImageC = pipe(issuerImageTaggedC, c => new t.Type<IssuerImage, IssuerImageTagged>(
  `IssuerImage`,
  (u: unknown): u is IssuerImage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerImage> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerImage }))),
  (x: IssuerImage): IssuerImageTagged => ({ ...x, _tag: `IssuerImage`}),
)) satisfies t.Type<IssuerImage, unknown>;


export const issuerProjectImg = {
  _tag: `IssuerProjectImg`,
  categoryGroupId: 4,
  categoryName: `Issuer Project Image`,
  id: 24
} as const;

export type IssuerProjectImgTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerProjectImg`>
}>;
export const issuerProjectImgTaggedC: IssuerProjectImgTaggedC = t.type({
  _tag: t.literal(`IssuerProjectImg`)
});
export type IssuerProjectImgTagged = t.TypeOf<IssuerProjectImgTaggedC>;
export type IssuerProjectImg = IssuerProjectImgTagged & typeof issuerProjectImg;
export type IssuerProjectImgC = t.Type<IssuerProjectImg, IssuerProjectImgTagged>;
export const issuerProjectImgC: IssuerProjectImgC = pipe(issuerProjectImgTaggedC, c => new t.Type<IssuerProjectImg, IssuerProjectImgTagged>(
  `IssuerProjectImg`,
  (u: unknown): u is IssuerProjectImg => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerProjectImg> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerProjectImg }))),
  (x: IssuerProjectImg): IssuerProjectImgTagged => ({ ...x, _tag: `IssuerProjectImg`}),
)) satisfies t.Type<IssuerProjectImg, unknown>;


export const inlineContentImg = {
  _tag: `InlineContentImg`,
  categoryGroupId: 4,
  categoryName: `Inline Content Image`,
  id: 25
} as const;

export type InlineContentImgTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InlineContentImg`>
}>;
export const inlineContentImgTaggedC: InlineContentImgTaggedC = t.type({
  _tag: t.literal(`InlineContentImg`)
});
export type InlineContentImgTagged = t.TypeOf<InlineContentImgTaggedC>;
export type InlineContentImg = InlineContentImgTagged & typeof inlineContentImg;
export type InlineContentImgC = t.Type<InlineContentImg, InlineContentImgTagged>;
export const inlineContentImgC: InlineContentImgC = pipe(inlineContentImgTaggedC, c => new t.Type<InlineContentImg, InlineContentImgTagged>(
  `InlineContentImg`,
  (u: unknown): u is InlineContentImg => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InlineContentImg> => pipe(c.decode(u), E.map(x => ({ ...x, ...inlineContentImg }))),
  (x: InlineContentImg): InlineContentImgTagged => ({ ...x, _tag: `InlineContentImg`}),
)) satisfies t.Type<InlineContentImg, unknown>;


export const homepageFeatured = {
  _tag: `HomepageFeatured`,
  categoryGroupId: 4,
  categoryName: `Issuer Homepage Featured Image`,
  id: 26
} as const;

export type HomepageFeaturedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`HomepageFeatured`>
}>;
export const homepageFeaturedTaggedC: HomepageFeaturedTaggedC = t.type({
  _tag: t.literal(`HomepageFeatured`)
});
export type HomepageFeaturedTagged = t.TypeOf<HomepageFeaturedTaggedC>;
export type HomepageFeatured = HomepageFeaturedTagged & typeof homepageFeatured;
export type HomepageFeaturedC = t.Type<HomepageFeatured, HomepageFeaturedTagged>;
export const homepageFeaturedC: HomepageFeaturedC = pipe(homepageFeaturedTaggedC, c => new t.Type<HomepageFeatured, HomepageFeaturedTagged>(
  `HomepageFeatured`,
  (u: unknown): u is HomepageFeatured => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HomepageFeatured> => pipe(c.decode(u), E.map(x => ({ ...x, ...homepageFeatured }))),
  (x: HomepageFeatured): HomepageFeaturedTagged => ({ ...x, _tag: `HomepageFeatured`}),
)) satisfies t.Type<HomepageFeatured, unknown>;


export const pos = {
  _tag: `Pos`,
  categoryGroupId: 4,
  categoryName: `Preliminary Official Statement`,
  id: 27
} as const;

export type PosTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Pos`>
}>;
export const posTaggedC: PosTaggedC = t.type({
  _tag: t.literal(`Pos`)
});
export type PosTagged = t.TypeOf<PosTaggedC>;
export type Pos = PosTagged & typeof pos;
export type PosC = t.Type<Pos, PosTagged>;
export const posC: PosC = pipe(posTaggedC, c => new t.Type<Pos, PosTagged>(
  `Pos`,
  (u: unknown): u is Pos => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Pos> => pipe(c.decode(u), E.map(x => ({ ...x, ...pos }))),
  (x: Pos): PosTagged => ({ ...x, _tag: `Pos`}),
)) satisfies t.Type<Pos, unknown>;


export const custom = {
  _tag: `Custom`,
  categoryGroupId: 0,
  categoryName: `Custom`,
  id: 29
} as const;

export type CustomTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Custom`>
}>;
export const customTaggedC: CustomTaggedC = t.type({
  _tag: t.literal(`Custom`)
});
export type CustomTagged = t.TypeOf<CustomTaggedC>;
export type Custom = CustomTagged & typeof custom;
export type CustomC = t.Type<Custom, CustomTagged>;
export const customC: CustomC = pipe(customTaggedC, c => new t.Type<Custom, CustomTagged>(
  `Custom`,
  (u: unknown): u is Custom => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Custom> => pipe(c.decode(u), E.map(x => ({ ...x, ...custom }))),
  (x: Custom): CustomTagged => ({ ...x, _tag: `Custom`}),
)) satisfies t.Type<Custom, unknown>;


export const bulkUpload = {
  _tag: `BulkUpload`,
  categoryGroupId: 5,
  categoryName: `Bulk Upload`,
  id: 30
} as const;

export type BulkUploadTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BulkUpload`>
}>;
export const bulkUploadTaggedC: BulkUploadTaggedC = t.type({
  _tag: t.literal(`BulkUpload`)
});
export type BulkUploadTagged = t.TypeOf<BulkUploadTaggedC>;
export type BulkUpload = BulkUploadTagged & typeof bulkUpload;
export type BulkUploadC = t.Type<BulkUpload, BulkUploadTagged>;
export const bulkUploadC: BulkUploadC = pipe(bulkUploadTaggedC, c => new t.Type<BulkUpload, BulkUploadTagged>(
  `BulkUpload`,
  (u: unknown): u is BulkUpload => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BulkUpload> => pipe(c.decode(u), E.map(x => ({ ...x, ...bulkUpload }))),
  (x: BulkUpload): BulkUploadTagged => ({ ...x, _tag: `BulkUpload`}),
)) satisfies t.Type<BulkUpload, unknown>;


export const invoices = {
  _tag: `Invoices`,
  categoryGroupId: 5,
  categoryName: `BondLink Invoices`,
  id: 31
} as const;

export type InvoicesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Invoices`>
}>;
export const invoicesTaggedC: InvoicesTaggedC = t.type({
  _tag: t.literal(`Invoices`)
});
export type InvoicesTagged = t.TypeOf<InvoicesTaggedC>;
export type Invoices = InvoicesTagged & typeof invoices;
export type InvoicesC = t.Type<Invoices, InvoicesTagged>;
export const invoicesC: InvoicesC = pipe(invoicesTaggedC, c => new t.Type<Invoices, InvoicesTagged>(
  `Invoices`,
  (u: unknown): u is Invoices => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Invoices> => pipe(c.decode(u), E.map(x => ({ ...x, ...invoices }))),
  (x: Invoices): InvoicesTagged => ({ ...x, _tag: `Invoices`}),
)) satisfies t.Type<Invoices, unknown>;


export const roadShowAudio = {
  _tag: `RoadShowAudio`,
  categoryGroupId: 5,
  categoryName: `Roadshow Audio`,
  id: 35
} as const;

export type RoadShowAudioTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RoadShowAudio`>
}>;
export const roadShowAudioTaggedC: RoadShowAudioTaggedC = t.type({
  _tag: t.literal(`RoadShowAudio`)
});
export type RoadShowAudioTagged = t.TypeOf<RoadShowAudioTaggedC>;
export type RoadShowAudio = RoadShowAudioTagged & typeof roadShowAudio;
export type RoadShowAudioC = t.Type<RoadShowAudio, RoadShowAudioTagged>;
export const roadShowAudioC: RoadShowAudioC = pipe(roadShowAudioTaggedC, c => new t.Type<RoadShowAudio, RoadShowAudioTagged>(
  `RoadShowAudio`,
  (u: unknown): u is RoadShowAudio => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RoadShowAudio> => pipe(c.decode(u), E.map(x => ({ ...x, ...roadShowAudio }))),
  (x: RoadShowAudio): RoadShowAudioTagged => ({ ...x, _tag: `RoadShowAudio`}),
)) satisfies t.Type<RoadShowAudio, unknown>;


export const roadShowSlide = {
  _tag: `RoadShowSlide`,
  categoryGroupId: 4,
  categoryName: `Roadshow Slides`,
  id: 36
} as const;

export type RoadShowSlideTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RoadShowSlide`>
}>;
export const roadShowSlideTaggedC: RoadShowSlideTaggedC = t.type({
  _tag: t.literal(`RoadShowSlide`)
});
export type RoadShowSlideTagged = t.TypeOf<RoadShowSlideTaggedC>;
export type RoadShowSlide = RoadShowSlideTagged & typeof roadShowSlide;
export type RoadShowSlideC = t.Type<RoadShowSlide, RoadShowSlideTagged>;
export const roadShowSlideC: RoadShowSlideC = pipe(roadShowSlideTaggedC, c => new t.Type<RoadShowSlide, RoadShowSlideTagged>(
  `RoadShowSlide`,
  (u: unknown): u is RoadShowSlide => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RoadShowSlide> => pipe(c.decode(u), E.map(x => ({ ...x, ...roadShowSlide }))),
  (x: RoadShowSlide): RoadShowSlideTagged => ({ ...x, _tag: `RoadShowSlide`}),
)) satisfies t.Type<RoadShowSlide, unknown>;


export const ratingDocument = {
  _tag: `RatingDocument`,
  categoryGroupId: 1,
  categoryName: `Rating Reports`,
  id: 37
} as const;

export type RatingDocumentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RatingDocument`>
}>;
export const ratingDocumentTaggedC: RatingDocumentTaggedC = t.type({
  _tag: t.literal(`RatingDocument`)
});
export type RatingDocumentTagged = t.TypeOf<RatingDocumentTaggedC>;
export type RatingDocument = RatingDocumentTagged & typeof ratingDocument;
export type RatingDocumentC = t.Type<RatingDocument, RatingDocumentTagged>;
export const ratingDocumentC: RatingDocumentC = pipe(ratingDocumentTaggedC, c => new t.Type<RatingDocument, RatingDocumentTagged>(
  `RatingDocument`,
  (u: unknown): u is RatingDocument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RatingDocument> => pipe(c.decode(u), E.map(x => ({ ...x, ...ratingDocument }))),
  (x: RatingDocument): RatingDocumentTagged => ({ ...x, _tag: `RatingDocument`}),
)) satisfies t.Type<RatingDocument, unknown>;


export const hrefDocument = {
  _tag: `HrefDocument`,
  categoryGroupId: 5,
  categoryName: `Menu Item Document`,
  id: 38
} as const;

export type HrefDocumentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`HrefDocument`>
}>;
export const hrefDocumentTaggedC: HrefDocumentTaggedC = t.type({
  _tag: t.literal(`HrefDocument`)
});
export type HrefDocumentTagged = t.TypeOf<HrefDocumentTaggedC>;
export type HrefDocument = HrefDocumentTagged & typeof hrefDocument;
export type HrefDocumentC = t.Type<HrefDocument, HrefDocumentTagged>;
export const hrefDocumentC: HrefDocumentC = pipe(hrefDocumentTaggedC, c => new t.Type<HrefDocument, HrefDocumentTagged>(
  `HrefDocument`,
  (u: unknown): u is HrefDocument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HrefDocument> => pipe(c.decode(u), E.map(x => ({ ...x, ...hrefDocument }))),
  (x: HrefDocument): HrefDocumentTagged => ({ ...x, _tag: `HrefDocument`}),
)) satisfies t.Type<HrefDocument, unknown>;


export const newsDocument = {
  _tag: `NewsDocument`,
  categoryGroupId: 5,
  categoryName: `News Document`,
  id: 39
} as const;

export type NewsDocumentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`NewsDocument`>
}>;
export const newsDocumentTaggedC: NewsDocumentTaggedC = t.type({
  _tag: t.literal(`NewsDocument`)
});
export type NewsDocumentTagged = t.TypeOf<NewsDocumentTaggedC>;
export type NewsDocument = NewsDocumentTagged & typeof newsDocument;
export type NewsDocumentC = t.Type<NewsDocument, NewsDocumentTagged>;
export const newsDocumentC: NewsDocumentC = pipe(newsDocumentTaggedC, c => new t.Type<NewsDocument, NewsDocumentTagged>(
  `NewsDocument`,
  (u: unknown): u is NewsDocument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewsDocument> => pipe(c.decode(u), E.map(x => ({ ...x, ...newsDocument }))),
  (x: NewsDocument): NewsDocumentTagged => ({ ...x, _tag: `NewsDocument`}),
)) satisfies t.Type<NewsDocument, unknown>;


export const adminFiles = {
  _tag: `AdminFiles`,
  categoryGroupId: 5,
  categoryName: `Admin Files`,
  id: 41
} as const;

export type AdminFilesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AdminFiles`>
}>;
export const adminFilesTaggedC: AdminFilesTaggedC = t.type({
  _tag: t.literal(`AdminFiles`)
});
export type AdminFilesTagged = t.TypeOf<AdminFilesTaggedC>;
export type AdminFiles = AdminFilesTagged & typeof adminFiles;
export type AdminFilesC = t.Type<AdminFiles, AdminFilesTagged>;
export const adminFilesC: AdminFilesC = pipe(adminFilesTaggedC, c => new t.Type<AdminFiles, AdminFilesTagged>(
  `AdminFiles`,
  (u: unknown): u is AdminFiles => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AdminFiles> => pipe(c.decode(u), E.map(x => ({ ...x, ...adminFiles }))),
  (x: AdminFiles): AdminFilesTagged => ({ ...x, _tag: `AdminFiles`}),
)) satisfies t.Type<AdminFiles, unknown>;


export const audioFiles = {
  _tag: `AudioFiles`,
  categoryGroupId: 7,
  categoryName: `Audio Files`,
  id: 42
} as const;

export type AudioFilesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`AudioFiles`>
}>;
export const audioFilesTaggedC: AudioFilesTaggedC = t.type({
  _tag: t.literal(`AudioFiles`)
});
export type AudioFilesTagged = t.TypeOf<AudioFilesTaggedC>;
export type AudioFiles = AudioFilesTagged & typeof audioFiles;
export type AudioFilesC = t.Type<AudioFiles, AudioFilesTagged>;
export const audioFilesC: AudioFilesC = pipe(audioFilesTaggedC, c => new t.Type<AudioFiles, AudioFilesTagged>(
  `AudioFiles`,
  (u: unknown): u is AudioFiles => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AudioFiles> => pipe(c.decode(u), E.map(x => ({ ...x, ...audioFiles }))),
  (x: AudioFiles): AudioFilesTagged => ({ ...x, _tag: `AudioFiles`}),
)) satisfies t.Type<AudioFiles, unknown>;


export const headerPhoto = {
  _tag: `HeaderPhoto`,
  categoryGroupId: 4,
  categoryName: `Header Photo Image`,
  id: 43
} as const;

export type HeaderPhotoTaggedC = t.TypeC<{
  _tag: t.LiteralC<`HeaderPhoto`>
}>;
export const headerPhotoTaggedC: HeaderPhotoTaggedC = t.type({
  _tag: t.literal(`HeaderPhoto`)
});
export type HeaderPhotoTagged = t.TypeOf<HeaderPhotoTaggedC>;
export type HeaderPhoto = HeaderPhotoTagged & typeof headerPhoto;
export type HeaderPhotoC = t.Type<HeaderPhoto, HeaderPhotoTagged>;
export const headerPhotoC: HeaderPhotoC = pipe(headerPhotoTaggedC, c => new t.Type<HeaderPhoto, HeaderPhotoTagged>(
  `HeaderPhoto`,
  (u: unknown): u is HeaderPhoto => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HeaderPhoto> => pipe(c.decode(u), E.map(x => ({ ...x, ...headerPhoto }))),
  (x: HeaderPhoto): HeaderPhotoTagged => ({ ...x, _tag: `HeaderPhoto`}),
)) satisfies t.Type<HeaderPhoto, unknown>;


export const irmaLetter = {
  _tag: `IrmaLetter`,
  categoryGroupId: 8,
  categoryName: `IRMA Letter`,
  id: 45
} as const;

export type IrmaLetterTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IrmaLetter`>
}>;
export const irmaLetterTaggedC: IrmaLetterTaggedC = t.type({
  _tag: t.literal(`IrmaLetter`)
});
export type IrmaLetterTagged = t.TypeOf<IrmaLetterTaggedC>;
export type IrmaLetter = IrmaLetterTagged & typeof irmaLetter;
export type IrmaLetterC = t.Type<IrmaLetter, IrmaLetterTagged>;
export const irmaLetterC: IrmaLetterC = pipe(irmaLetterTaggedC, c => new t.Type<IrmaLetter, IrmaLetterTagged>(
  `IrmaLetter`,
  (u: unknown): u is IrmaLetter => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IrmaLetter> => pipe(c.decode(u), E.map(x => ({ ...x, ...irmaLetter }))),
  (x: IrmaLetter): IrmaLetterTagged => ({ ...x, _tag: `IrmaLetter`}),
)) satisfies t.Type<IrmaLetter, unknown>;


export const eventDocument = {
  _tag: `EventDocument`,
  categoryGroupId: 5,
  categoryName: `Event Document`,
  id: 46
} as const;

export type EventDocumentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EventDocument`>
}>;
export const eventDocumentTaggedC: EventDocumentTaggedC = t.type({
  _tag: t.literal(`EventDocument`)
});
export type EventDocumentTagged = t.TypeOf<EventDocumentTaggedC>;
export type EventDocument = EventDocumentTagged & typeof eventDocument;
export type EventDocumentC = t.Type<EventDocument, EventDocumentTagged>;
export const eventDocumentC: EventDocumentC = pipe(eventDocumentTaggedC, c => new t.Type<EventDocument, EventDocumentTagged>(
  `EventDocument`,
  (u: unknown): u is EventDocument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EventDocument> => pipe(c.decode(u), E.map(x => ({ ...x, ...eventDocument }))),
  (x: EventDocument): EventDocumentTagged => ({ ...x, _tag: `EventDocument`}),
)) satisfies t.Type<EventDocument, unknown>;


export const esgProgram = {
  _tag: `EsgProgram`,
  categoryGroupId: 4,
  categoryName: `ESG Overview Page Image`,
  id: 47
} as const;

export type EsgProgramTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EsgProgram`>
}>;
export const esgProgramTaggedC: EsgProgramTaggedC = t.type({
  _tag: t.literal(`EsgProgram`)
});
export type EsgProgramTagged = t.TypeOf<EsgProgramTaggedC>;
export type EsgProgram = EsgProgramTagged & typeof esgProgram;
export type EsgProgramC = t.Type<EsgProgram, EsgProgramTagged>;
export const esgProgramC: EsgProgramC = pipe(esgProgramTaggedC, c => new t.Type<EsgProgram, EsgProgramTagged>(
  `EsgProgram`,
  (u: unknown): u is EsgProgram => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EsgProgram> => pipe(c.decode(u), E.map(x => ({ ...x, ...esgProgram }))),
  (x: EsgProgram): EsgProgramTagged => ({ ...x, _tag: `EsgProgram`}),
)) satisfies t.Type<EsgProgram, unknown>;


export const projectDocument = {
  _tag: `ProjectDocument`,
  categoryGroupId: 5,
  categoryName: `Project Document`,
  id: 48
} as const;

export type ProjectDocumentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ProjectDocument`>
}>;
export const projectDocumentTaggedC: ProjectDocumentTaggedC = t.type({
  _tag: t.literal(`ProjectDocument`)
});
export type ProjectDocumentTagged = t.TypeOf<ProjectDocumentTaggedC>;
export type ProjectDocument = ProjectDocumentTagged & typeof projectDocument;
export type ProjectDocumentC = t.Type<ProjectDocument, ProjectDocumentTagged>;
export const projectDocumentC: ProjectDocumentC = pipe(projectDocumentTaggedC, c => new t.Type<ProjectDocument, ProjectDocumentTagged>(
  `ProjectDocument`,
  (u: unknown): u is ProjectDocument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ProjectDocument> => pipe(c.decode(u), E.map(x => ({ ...x, ...projectDocument }))),
  (x: ProjectDocument): ProjectDocumentTagged => ({ ...x, _tag: `ProjectDocument`}),
)) satisfies t.Type<ProjectDocument, unknown>;


export const customPage1Photos = {
  _tag: `CustomPage1Photos`,
  categoryGroupId: 4,
  categoryName: `Custom Page 1 Photos`,
  id: 49
} as const;

export type CustomPage1PhotosTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage1Photos`>
}>;
export const customPage1PhotosTaggedC: CustomPage1PhotosTaggedC = t.type({
  _tag: t.literal(`CustomPage1Photos`)
});
export type CustomPage1PhotosTagged = t.TypeOf<CustomPage1PhotosTaggedC>;
export type CustomPage1Photos = CustomPage1PhotosTagged & typeof customPage1Photos;
export type CustomPage1PhotosC = t.Type<CustomPage1Photos, CustomPage1PhotosTagged>;
export const customPage1PhotosC: CustomPage1PhotosC = pipe(customPage1PhotosTaggedC, c => new t.Type<CustomPage1Photos, CustomPage1PhotosTagged>(
  `CustomPage1Photos`,
  (u: unknown): u is CustomPage1Photos => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage1Photos> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage1Photos }))),
  (x: CustomPage1Photos): CustomPage1PhotosTagged => ({ ...x, _tag: `CustomPage1Photos`}),
)) satisfies t.Type<CustomPage1Photos, unknown>;


export const customPage2Photos = {
  _tag: `CustomPage2Photos`,
  categoryGroupId: 4,
  categoryName: `Custom Page 2 Photos`,
  id: 50
} as const;

export type CustomPage2PhotosTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage2Photos`>
}>;
export const customPage2PhotosTaggedC: CustomPage2PhotosTaggedC = t.type({
  _tag: t.literal(`CustomPage2Photos`)
});
export type CustomPage2PhotosTagged = t.TypeOf<CustomPage2PhotosTaggedC>;
export type CustomPage2Photos = CustomPage2PhotosTagged & typeof customPage2Photos;
export type CustomPage2PhotosC = t.Type<CustomPage2Photos, CustomPage2PhotosTagged>;
export const customPage2PhotosC: CustomPage2PhotosC = pipe(customPage2PhotosTaggedC, c => new t.Type<CustomPage2Photos, CustomPage2PhotosTagged>(
  `CustomPage2Photos`,
  (u: unknown): u is CustomPage2Photos => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage2Photos> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage2Photos }))),
  (x: CustomPage2Photos): CustomPage2PhotosTagged => ({ ...x, _tag: `CustomPage2Photos`}),
)) satisfies t.Type<CustomPage2Photos, unknown>;


export const customPage3Photos = {
  _tag: `CustomPage3Photos`,
  categoryGroupId: 4,
  categoryName: `Custom Page 3 Photos`,
  id: 51
} as const;

export type CustomPage3PhotosTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage3Photos`>
}>;
export const customPage3PhotosTaggedC: CustomPage3PhotosTaggedC = t.type({
  _tag: t.literal(`CustomPage3Photos`)
});
export type CustomPage3PhotosTagged = t.TypeOf<CustomPage3PhotosTaggedC>;
export type CustomPage3Photos = CustomPage3PhotosTagged & typeof customPage3Photos;
export type CustomPage3PhotosC = t.Type<CustomPage3Photos, CustomPage3PhotosTagged>;
export const customPage3PhotosC: CustomPage3PhotosC = pipe(customPage3PhotosTaggedC, c => new t.Type<CustomPage3Photos, CustomPage3PhotosTagged>(
  `CustomPage3Photos`,
  (u: unknown): u is CustomPage3Photos => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage3Photos> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage3Photos }))),
  (x: CustomPage3Photos): CustomPage3PhotosTagged => ({ ...x, _tag: `CustomPage3Photos`}),
)) satisfies t.Type<CustomPage3Photos, unknown>;


export const customPage4Photos = {
  _tag: `CustomPage4Photos`,
  categoryGroupId: 4,
  categoryName: `Custom Page 4 Photos`,
  id: 52
} as const;

export type CustomPage4PhotosTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage4Photos`>
}>;
export const customPage4PhotosTaggedC: CustomPage4PhotosTaggedC = t.type({
  _tag: t.literal(`CustomPage4Photos`)
});
export type CustomPage4PhotosTagged = t.TypeOf<CustomPage4PhotosTaggedC>;
export type CustomPage4Photos = CustomPage4PhotosTagged & typeof customPage4Photos;
export type CustomPage4PhotosC = t.Type<CustomPage4Photos, CustomPage4PhotosTagged>;
export const customPage4PhotosC: CustomPage4PhotosC = pipe(customPage4PhotosTaggedC, c => new t.Type<CustomPage4Photos, CustomPage4PhotosTagged>(
  `CustomPage4Photos`,
  (u: unknown): u is CustomPage4Photos => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage4Photos> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage4Photos }))),
  (x: CustomPage4Photos): CustomPage4PhotosTagged => ({ ...x, _tag: `CustomPage4Photos`}),
)) satisfies t.Type<CustomPage4Photos, unknown>;


export const customPage5Photos = {
  _tag: `CustomPage5Photos`,
  categoryGroupId: 4,
  categoryName: `Custom Page 5 Photos`,
  id: 53
} as const;

export type CustomPage5PhotosTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage5Photos`>
}>;
export const customPage5PhotosTaggedC: CustomPage5PhotosTaggedC = t.type({
  _tag: t.literal(`CustomPage5Photos`)
});
export type CustomPage5PhotosTagged = t.TypeOf<CustomPage5PhotosTaggedC>;
export type CustomPage5Photos = CustomPage5PhotosTagged & typeof customPage5Photos;
export type CustomPage5PhotosC = t.Type<CustomPage5Photos, CustomPage5PhotosTagged>;
export const customPage5PhotosC: CustomPage5PhotosC = pipe(customPage5PhotosTaggedC, c => new t.Type<CustomPage5Photos, CustomPage5PhotosTagged>(
  `CustomPage5Photos`,
  (u: unknown): u is CustomPage5Photos => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage5Photos> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage5Photos }))),
  (x: CustomPage5Photos): CustomPage5PhotosTagged => ({ ...x, _tag: `CustomPage5Photos`}),
)) satisfies t.Type<CustomPage5Photos, unknown>;


export const rfpDocument = {
  _tag: `RfpDocument`,
  categoryGroupId: 5,
  categoryName: `RFP Document`,
  id: 54
} as const;

export type RfpDocumentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RfpDocument`>
}>;
export const rfpDocumentTaggedC: RfpDocumentTaggedC = t.type({
  _tag: t.literal(`RfpDocument`)
});
export type RfpDocumentTagged = t.TypeOf<RfpDocumentTaggedC>;
export type RfpDocument = RfpDocumentTagged & typeof rfpDocument;
export type RfpDocumentC = t.Type<RfpDocument, RfpDocumentTagged>;
export const rfpDocumentC: RfpDocumentC = pipe(rfpDocumentTaggedC, c => new t.Type<RfpDocument, RfpDocumentTagged>(
  `RfpDocument`,
  (u: unknown): u is RfpDocument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpDocument> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpDocument }))),
  (x: RfpDocument): RfpDocumentTagged => ({ ...x, _tag: `RfpDocument`}),
)) satisfies t.Type<RfpDocument, unknown>;


export const privateOfferingDocument = {
  _tag: `PrivateOfferingDocument`,
  categoryGroupId: 5,
  categoryName: `Private Offering Document`,
  id: 55
} as const;

export type PrivateOfferingDocumentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PrivateOfferingDocument`>
}>;
export const privateOfferingDocumentTaggedC: PrivateOfferingDocumentTaggedC = t.type({
  _tag: t.literal(`PrivateOfferingDocument`)
});
export type PrivateOfferingDocumentTagged = t.TypeOf<PrivateOfferingDocumentTaggedC>;
export type PrivateOfferingDocument = PrivateOfferingDocumentTagged & typeof privateOfferingDocument;
export type PrivateOfferingDocumentC = t.Type<PrivateOfferingDocument, PrivateOfferingDocumentTagged>;
export const privateOfferingDocumentC: PrivateOfferingDocumentC = pipe(privateOfferingDocumentTaggedC, c => new t.Type<PrivateOfferingDocument, PrivateOfferingDocumentTagged>(
  `PrivateOfferingDocument`,
  (u: unknown): u is PrivateOfferingDocument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PrivateOfferingDocument> => pipe(c.decode(u), E.map(x => ({ ...x, ...privateOfferingDocument }))),
  (x: PrivateOfferingDocument): PrivateOfferingDocumentTagged => ({ ...x, _tag: `PrivateOfferingDocument`}),
)) satisfies t.Type<PrivateOfferingDocument, unknown>;


export const bondProgramImg = {
  _tag: `BondProgramImg`,
  categoryGroupId: 4,
  categoryName: `Bond Program Image`,
  id: 56
} as const;

export type BondProgramImgTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondProgramImg`>
}>;
export const bondProgramImgTaggedC: BondProgramImgTaggedC = t.type({
  _tag: t.literal(`BondProgramImg`)
});
export type BondProgramImgTagged = t.TypeOf<BondProgramImgTaggedC>;
export type BondProgramImg = BondProgramImgTagged & typeof bondProgramImg;
export type BondProgramImgC = t.Type<BondProgramImg, BondProgramImgTagged>;
export const bondProgramImgC: BondProgramImgC = pipe(bondProgramImgTaggedC, c => new t.Type<BondProgramImg, BondProgramImgTagged>(
  `BondProgramImg`,
  (u: unknown): u is BondProgramImg => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondProgramImg> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondProgramImg }))),
  (x: BondProgramImg): BondProgramImgTagged => ({ ...x, _tag: `BondProgramImg`}),
)) satisfies t.Type<BondProgramImg, unknown>;


export const bondProgramDocument = {
  _tag: `BondProgramDocument`,
  categoryGroupId: 5,
  categoryName: `Bond Program Document`,
  id: 57
} as const;

export type BondProgramDocumentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondProgramDocument`>
}>;
export const bondProgramDocumentTaggedC: BondProgramDocumentTaggedC = t.type({
  _tag: t.literal(`BondProgramDocument`)
});
export type BondProgramDocumentTagged = t.TypeOf<BondProgramDocumentTaggedC>;
export type BondProgramDocument = BondProgramDocumentTagged & typeof bondProgramDocument;
export type BondProgramDocumentC = t.Type<BondProgramDocument, BondProgramDocumentTagged>;
export const bondProgramDocumentC: BondProgramDocumentC = pipe(bondProgramDocumentTaggedC, c => new t.Type<BondProgramDocument, BondProgramDocumentTagged>(
  `BondProgramDocument`,
  (u: unknown): u is BondProgramDocument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondProgramDocument> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondProgramDocument }))),
  (x: BondProgramDocument): BondProgramDocumentTagged => ({ ...x, _tag: `BondProgramDocument`}),
)) satisfies t.Type<BondProgramDocument, unknown>;


export const bidSubmissionDocument = {
  _tag: `BidSubmissionDocument`,
  categoryGroupId: 5,
  categoryName: `Bid Submission Document`,
  id: 58
} as const;

export type BidSubmissionDocumentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BidSubmissionDocument`>
}>;
export const bidSubmissionDocumentTaggedC: BidSubmissionDocumentTaggedC = t.type({
  _tag: t.literal(`BidSubmissionDocument`)
});
export type BidSubmissionDocumentTagged = t.TypeOf<BidSubmissionDocumentTaggedC>;
export type BidSubmissionDocument = BidSubmissionDocumentTagged & typeof bidSubmissionDocument;
export type BidSubmissionDocumentC = t.Type<BidSubmissionDocument, BidSubmissionDocumentTagged>;
export const bidSubmissionDocumentC: BidSubmissionDocumentC = pipe(bidSubmissionDocumentTaggedC, c => new t.Type<BidSubmissionDocument, BidSubmissionDocumentTagged>(
  `BidSubmissionDocument`,
  (u: unknown): u is BidSubmissionDocument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidSubmissionDocument> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidSubmissionDocument }))),
  (x: BidSubmissionDocument): BidSubmissionDocumentTagged => ({ ...x, _tag: `BidSubmissionDocument`}),
)) satisfies t.Type<BidSubmissionDocument, unknown>;


export const plom = {
  _tag: `Plom`,
  categoryGroupId: 1,
  categoryName: `Preliminary Limited Offering Memorandum (PLOM)`,
  id: 59
} as const;

export type PlomTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Plom`>
}>;
export const plomTaggedC: PlomTaggedC = t.type({
  _tag: t.literal(`Plom`)
});
export type PlomTagged = t.TypeOf<PlomTaggedC>;
export type Plom = PlomTagged & typeof plom;
export type PlomC = t.Type<Plom, PlomTagged>;
export const plomC: PlomC = pipe(plomTaggedC, c => new t.Type<Plom, PlomTagged>(
  `Plom`,
  (u: unknown): u is Plom => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Plom> => pipe(c.decode(u), E.map(x => ({ ...x, ...plom }))),
  (x: Plom): PlomTagged => ({ ...x, _tag: `Plom`}),
)) satisfies t.Type<Plom, unknown>;


export const allMediaCategoryC = [cafrsC, annualOpBdgtC, interimBdgtC, pensionRptC, opebRptC, quarterlyStmtsC, taxCollectionRptC, bondTrusteeRptC, unauditedStmtC, cipC, debtAffordRptC, studentForecastC, feasibilityStudyC, economicRptC, bankLoanC, swapIsdaC, utilityRptsC, otherRptsC, presentationsC, officialStmtsC, userImageC, issuerImageC, otherC, issuerProjectImgC, inlineContentImgC, homepageFeaturedC, posC, customC, bulkUploadC, invoicesC, roadShowAudioC, roadShowSlideC, ratingDocumentC, hrefDocumentC, newsDocumentC, adminFilesC, audioFilesC, headerPhotoC, irmaLetterC, eventDocumentC, esgProgramC, projectDocumentC, customPage1PhotosC, customPage2PhotosC, customPage3PhotosC, customPage4PhotosC, customPage5PhotosC, rfpDocumentC, privateOfferingDocumentC, bondProgramImgC, bondProgramDocumentC, bidSubmissionDocumentC, plomC] as const;
export const allMediaCategoryNames = [`Cafrs`, `AnnualOpBdgt`, `InterimBdgt`, `PensionRpt`, `OpebRpt`, `QuarterlyStmts`, `TaxCollectionRpt`, `BondTrusteeRpt`, `UnauditedStmt`, `Cip`, `DebtAffordRpt`, `StudentForecast`, `FeasibilityStudy`, `EconomicRpt`, `BankLoan`, `SwapIsda`, `UtilityRpts`, `OtherRpts`, `Presentations`, `OfficialStmts`, `UserImage`, `IssuerImage`, `Other`, `IssuerProjectImg`, `InlineContentImg`, `HomepageFeatured`, `Pos`, `Custom`, `BulkUpload`, `Invoices`, `RoadShowAudio`, `RoadShowSlide`, `RatingDocument`, `HrefDocument`, `NewsDocument`, `AdminFiles`, `AudioFiles`, `HeaderPhoto`, `IrmaLetter`, `EventDocument`, `EsgProgram`, `ProjectDocument`, `CustomPage1Photos`, `CustomPage2Photos`, `CustomPage3Photos`, `CustomPage4Photos`, `CustomPage5Photos`, `RfpDocument`, `PrivateOfferingDocument`, `BondProgramImg`, `BondProgramDocument`, `BidSubmissionDocument`, `Plom`] as const;
export type MediaCategoryName = (typeof allMediaCategoryNames)[number];

export type MediaCategoryCU = t.UnionC<[CafrsC, AnnualOpBdgtC, InterimBdgtC, PensionRptC, OpebRptC, QuarterlyStmtsC, TaxCollectionRptC, BondTrusteeRptC, UnauditedStmtC, CipC, DebtAffordRptC, StudentForecastC, FeasibilityStudyC, EconomicRptC, BankLoanC, SwapIsdaC, UtilityRptsC, OtherRptsC, PresentationsC, OfficialStmtsC, UserImageC, IssuerImageC, OtherC, IssuerProjectImgC, InlineContentImgC, HomepageFeaturedC, PosC, CustomC, BulkUploadC, InvoicesC, RoadShowAudioC, RoadShowSlideC, RatingDocumentC, HrefDocumentC, NewsDocumentC, AdminFilesC, AudioFilesC, HeaderPhotoC, IrmaLetterC, EventDocumentC, EsgProgramC, ProjectDocumentC, CustomPage1PhotosC, CustomPage2PhotosC, CustomPage3PhotosC, CustomPage4PhotosC, CustomPage5PhotosC, RfpDocumentC, PrivateOfferingDocumentC, BondProgramImgC, BondProgramDocumentC, BidSubmissionDocumentC, PlomC]>;
export type MediaCategoryU = Cafrs | AnnualOpBdgt | InterimBdgt | PensionRpt | OpebRpt | QuarterlyStmts | TaxCollectionRpt | BondTrusteeRpt | UnauditedStmt | Cip | DebtAffordRpt | StudentForecast | FeasibilityStudy | EconomicRpt | BankLoan | SwapIsda | UtilityRpts | OtherRpts | Presentations | OfficialStmts | UserImage | IssuerImage | Other | IssuerProjectImg | InlineContentImg | HomepageFeatured | Pos | Custom | BulkUpload | Invoices | RoadShowAudio | RoadShowSlide | RatingDocument | HrefDocument | NewsDocument | AdminFiles | AudioFiles | HeaderPhoto | IrmaLetter | EventDocument | EsgProgram | ProjectDocument | CustomPage1Photos | CustomPage2Photos | CustomPage3Photos | CustomPage4Photos | CustomPage5Photos | RfpDocument | PrivateOfferingDocument | BondProgramImg | BondProgramDocument | BidSubmissionDocument | Plom;
export const MediaCategoryCU: MediaCategoryCU = t.union([cafrsC, annualOpBdgtC, interimBdgtC, pensionRptC, opebRptC, quarterlyStmtsC, taxCollectionRptC, bondTrusteeRptC, unauditedStmtC, cipC, debtAffordRptC, studentForecastC, feasibilityStudyC, economicRptC, bankLoanC, swapIsdaC, utilityRptsC, otherRptsC, presentationsC, officialStmtsC, userImageC, issuerImageC, otherC, issuerProjectImgC, inlineContentImgC, homepageFeaturedC, posC, customC, bulkUploadC, invoicesC, roadShowAudioC, roadShowSlideC, ratingDocumentC, hrefDocumentC, newsDocumentC, adminFilesC, audioFilesC, headerPhotoC, irmaLetterC, eventDocumentC, esgProgramC, projectDocumentC, customPage1PhotosC, customPage2PhotosC, customPage3PhotosC, customPage4PhotosC, customPage5PhotosC, rfpDocumentC, privateOfferingDocumentC, bondProgramImgC, bondProgramDocumentC, bidSubmissionDocumentC, plomC]) satisfies t.Type<MediaCategoryU, unknown>;

export const mediaCategoryOrd: Ord.Ord<MediaCategoryU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allMediaCategory = [cafrs, annualOpBdgt, interimBdgt, pensionRpt, opebRpt, quarterlyStmts, taxCollectionRpt, bondTrusteeRpt, unauditedStmt, cip, debtAffordRpt, studentForecast, feasibilityStudy, economicRpt, bankLoan, swapIsda, utilityRpts, otherRpts, presentations, officialStmts, userImage, issuerImage, other, issuerProjectImg, inlineContentImg, homepageFeatured, pos, custom, bulkUpload, invoices, roadShowAudio, roadShowSlide, ratingDocument, hrefDocument, newsDocument, adminFiles, audioFiles, headerPhoto, irmaLetter, eventDocument, esgProgram, projectDocument, customPage1Photos, customPage2Photos, customPage3Photos, customPage4Photos, customPage5Photos, rfpDocument, privateOfferingDocument, bondProgramImg, bondProgramDocument, bidSubmissionDocument, plom] as const;
export type MediaCategoryMap<A> = { [K in MediaCategoryName]: A };


