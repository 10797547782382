import { TaggedContent as imported201_TaggedContent, TaggedContentC as imported201_TaggedContentC, taggedContentC as imported202_taggedContentC, TaggedContent as imported202_TaggedContent, taggedContentC as imported201_taggedContentC, TaggedContentC as imported202_TaggedContentC } from "./taggedContent";
import { BondOfferingWithRelatedContent as imported16_BondOfferingWithRelatedContent, bondOfferingWithRelatedContentC as imported17_bondOfferingWithRelatedContentC, BondOfferingWithRelatedContentC as imported16_BondOfferingWithRelatedContentC, BondOfferingWithRelatedContent as imported17_BondOfferingWithRelatedContent, bondOfferingWithRelatedContentC as imported16_bondOfferingWithRelatedContentC, BondOfferingWithRelatedContentC as imported17_BondOfferingWithRelatedContentC } from "./bondOffering";
import { WithStatusCU as imported754_WithStatusCU, WithStatusU as imported377_WithStatusU, WithStatusCU as imported755_WithStatusCU, WithStatusU as imported376_WithStatusU, WithStatusCU as imported752_WithStatusCU, WithStatusCU as imported753_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";

export type PortalOfferingsC = t.TypeC<{
  active: t.ReadonlyArrayC<imported754_WithStatusCU<imported201_TaggedContentC<imported16_BondOfferingWithRelatedContentC>>>,
  archived: t.ReadonlyArrayC<imported755_WithStatusCU<imported202_TaggedContentC<imported17_BondOfferingWithRelatedContentC>>>
}>;
export type PortalOfferings = {
  active: ReadonlyArray<imported376_WithStatusU<imported201_TaggedContent<imported16_BondOfferingWithRelatedContent>>>,
  archived: ReadonlyArray<imported377_WithStatusU<imported202_TaggedContent<imported17_BondOfferingWithRelatedContent>>>
};
export const portalOfferingsC: PortalOfferingsC = t.type({
  active: t.readonlyArray(imported752_WithStatusCU(imported201_taggedContentC(imported16_bondOfferingWithRelatedContentC))),
  archived: t.readonlyArray(imported753_WithStatusCU(imported202_taggedContentC(imported17_bondOfferingWithRelatedContentC)))
}) satisfies t.Type<PortalOfferings, unknown>;


