import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { bankerUser as imported46_bankerUser, bankerAdmin as imported48_bankerAdmin, admin as imported4_admin, issuerAdmin as imported232_issuerAdmin, issuerUser as imported210_issuerUser, salesAdmin as imported2_salesAdmin, issuerAdmin as imported234_issuerAdmin, admin as imported6_admin } from "./roles";

export const billing = {
  _tag: `Billing`,
  name: `Billing`
} as const;

export type BillingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Billing`>
}>;
export const billingTaggedC: BillingTaggedC = t.type({
  _tag: t.literal(`Billing`)
});
export type BillingTagged = t.TypeOf<BillingTaggedC>;
export type Billing = BillingTagged & typeof billing;
export type BillingC = t.Type<Billing, BillingTagged>;
export const billingC: BillingC = pipe(billingTaggedC, c => new t.Type<Billing, BillingTagged>(
  `Billing`,
  (u: unknown): u is Billing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Billing> => pipe(c.decode(u), E.map(x => ({ ...x, ...billing }))),
  (x: Billing): BillingTagged => ({ ...x, _tag: `Billing`}),
)) satisfies t.Type<Billing, unknown>;


export const disclaimer = {
  _tag: `Disclaimer`,
  name: `Disclaimers`
} as const;

export type DisclaimerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Disclaimer`>
}>;
export const disclaimerTaggedC: DisclaimerTaggedC = t.type({
  _tag: t.literal(`Disclaimer`)
});
export type DisclaimerTagged = t.TypeOf<DisclaimerTaggedC>;
export type Disclaimer = DisclaimerTagged & typeof disclaimer;
export type DisclaimerC = t.Type<Disclaimer, DisclaimerTagged>;
export const disclaimerC: DisclaimerC = pipe(disclaimerTaggedC, c => new t.Type<Disclaimer, DisclaimerTagged>(
  `Disclaimer`,
  (u: unknown): u is Disclaimer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Disclaimer> => pipe(c.decode(u), E.map(x => ({ ...x, ...disclaimer }))),
  (x: Disclaimer): DisclaimerTagged => ({ ...x, _tag: `Disclaimer`}),
)) satisfies t.Type<Disclaimer, unknown>;


export const internalFlag = {
  _tag: `InternalFlag`,
  name: `Internal Flags`
} as const;

export type InternalFlagTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InternalFlag`>
}>;
export const internalFlagTaggedC: InternalFlagTaggedC = t.type({
  _tag: t.literal(`InternalFlag`)
});
export type InternalFlagTagged = t.TypeOf<InternalFlagTaggedC>;
export type InternalFlag = InternalFlagTagged & typeof internalFlag;
export type InternalFlagC = t.Type<InternalFlag, InternalFlagTagged>;
export const internalFlagC: InternalFlagC = pipe(internalFlagTaggedC, c => new t.Type<InternalFlag, InternalFlagTagged>(
  `InternalFlag`,
  (u: unknown): u is InternalFlag => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InternalFlag> => pipe(c.decode(u), E.map(x => ({ ...x, ...internalFlag }))),
  (x: InternalFlag): InternalFlagTagged => ({ ...x, _tag: `InternalFlag`}),
)) satisfies t.Type<InternalFlag, unknown>;


export const investorRequirement = {
  _tag: `InvestorRequirement`,
  name: `Investor Requirements`
} as const;

export type InvestorRequirementTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InvestorRequirement`>
}>;
export const investorRequirementTaggedC: InvestorRequirementTaggedC = t.type({
  _tag: t.literal(`InvestorRequirement`)
});
export type InvestorRequirementTagged = t.TypeOf<InvestorRequirementTaggedC>;
export type InvestorRequirement = InvestorRequirementTagged & typeof investorRequirement;
export type InvestorRequirementC = t.Type<InvestorRequirement, InvestorRequirementTagged>;
export const investorRequirementC: InvestorRequirementC = pipe(investorRequirementTaggedC, c => new t.Type<InvestorRequirement, InvestorRequirementTagged>(
  `InvestorRequirement`,
  (u: unknown): u is InvestorRequirement => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvestorRequirement> => pipe(c.decode(u), E.map(x => ({ ...x, ...investorRequirement }))),
  (x: InvestorRequirement): InvestorRequirementTagged => ({ ...x, _tag: `InvestorRequirement`}),
)) satisfies t.Type<InvestorRequirement, unknown>;


export const sectionToggle = {
  _tag: `SectionToggle`,
  name: `Section Toggles`
} as const;

export type SectionToggleTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SectionToggle`>
}>;
export const sectionToggleTaggedC: SectionToggleTaggedC = t.type({
  _tag: t.literal(`SectionToggle`)
});
export type SectionToggleTagged = t.TypeOf<SectionToggleTaggedC>;
export type SectionToggle = SectionToggleTagged & typeof sectionToggle;
export type SectionToggleC = t.Type<SectionToggle, SectionToggleTagged>;
export const sectionToggleC: SectionToggleC = pipe(sectionToggleTaggedC, c => new t.Type<SectionToggle, SectionToggleTagged>(
  `SectionToggle`,
  (u: unknown): u is SectionToggle => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SectionToggle> => pipe(c.decode(u), E.map(x => ({ ...x, ...sectionToggle }))),
  (x: SectionToggle): SectionToggleTagged => ({ ...x, _tag: `SectionToggle`}),
)) satisfies t.Type<SectionToggle, unknown>;


export const visual = {
  _tag: `Visual`,
  name: `Visual`
} as const;

export type VisualTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Visual`>
}>;
export const visualTaggedC: VisualTaggedC = t.type({
  _tag: t.literal(`Visual`)
});
export type VisualTagged = t.TypeOf<VisualTaggedC>;
export type Visual = VisualTagged & typeof visual;
export type VisualC = t.Type<Visual, VisualTagged>;
export const visualC: VisualC = pipe(visualTaggedC, c => new t.Type<Visual, VisualTagged>(
  `Visual`,
  (u: unknown): u is Visual => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Visual> => pipe(c.decode(u), E.map(x => ({ ...x, ...visual }))),
  (x: Visual): VisualTagged => ({ ...x, _tag: `Visual`}),
)) satisfies t.Type<Visual, unknown>;


export const allFeatureTypeC = [billingC, disclaimerC, internalFlagC, investorRequirementC, sectionToggleC, visualC] as const;
export const allFeatureTypeNames = [`Billing`, `Disclaimer`, `InternalFlag`, `InvestorRequirement`, `SectionToggle`, `Visual`] as const;
export type FeatureTypeName = (typeof allFeatureTypeNames)[number];

export type FeatureTypeCU = t.UnionC<[BillingC, DisclaimerC, InternalFlagC, InvestorRequirementC, SectionToggleC, VisualC]>;
export type FeatureTypeU = Billing | Disclaimer | InternalFlag | InvestorRequirement | SectionToggle | Visual;
export const FeatureTypeCU: FeatureTypeCU = t.union([billingC, disclaimerC, internalFlagC, investorRequirementC, sectionToggleC, visualC]) satisfies t.Type<FeatureTypeU, unknown>;

export const featureTypeOrd: Ord.Ord<FeatureTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allFeatureType = [billing, disclaimer, internalFlag, investorRequirement, sectionToggle, visual] as const;
export type FeatureTypeMap<A> = { [K in FeatureTypeName]: A };


export const bankUserRoles = {
  _tag: `BankUserRoles`,
  roles: [imported48_bankerAdmin, imported46_bankerUser]
} as const;

export type BankUserRolesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BankUserRoles`>
}>;
export const bankUserRolesTaggedC: BankUserRolesTaggedC = t.type({
  _tag: t.literal(`BankUserRoles`)
});
export type BankUserRolesTagged = t.TypeOf<BankUserRolesTaggedC>;
export type BankUserRoles = BankUserRolesTagged & typeof bankUserRoles;
export type BankUserRolesC = t.Type<BankUserRoles, BankUserRolesTagged>;
export const bankUserRolesC: BankUserRolesC = pipe(bankUserRolesTaggedC, c => new t.Type<BankUserRoles, BankUserRolesTagged>(
  `BankUserRoles`,
  (u: unknown): u is BankUserRoles => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankUserRoles> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankUserRoles }))),
  (x: BankUserRoles): BankUserRolesTagged => ({ ...x, _tag: `BankUserRoles`}),
)) satisfies t.Type<BankUserRoles, unknown>;


export const issuerUserRoles = {
  _tag: `IssuerUserRoles`,
  roles: [imported232_issuerAdmin, imported210_issuerUser]
} as const;

export type IssuerUserRolesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerUserRoles`>
}>;
export const issuerUserRolesTaggedC: IssuerUserRolesTaggedC = t.type({
  _tag: t.literal(`IssuerUserRoles`)
});
export type IssuerUserRolesTagged = t.TypeOf<IssuerUserRolesTaggedC>;
export type IssuerUserRoles = IssuerUserRolesTagged & typeof issuerUserRoles;
export type IssuerUserRolesC = t.Type<IssuerUserRoles, IssuerUserRolesTagged>;
export const issuerUserRolesC: IssuerUserRolesC = pipe(issuerUserRolesTaggedC, c => new t.Type<IssuerUserRoles, IssuerUserRolesTagged>(
  `IssuerUserRoles`,
  (u: unknown): u is IssuerUserRoles => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerUserRoles> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerUserRoles }))),
  (x: IssuerUserRoles): IssuerUserRolesTagged => ({ ...x, _tag: `IssuerUserRoles`}),
)) satisfies t.Type<IssuerUserRoles, unknown>;


export const issuerAdminRoles = {
  _tag: `IssuerAdminRoles`,
  roles: [imported234_issuerAdmin]
} as const;

export type IssuerAdminRolesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerAdminRoles`>
}>;
export const issuerAdminRolesTaggedC: IssuerAdminRolesTaggedC = t.type({
  _tag: t.literal(`IssuerAdminRoles`)
});
export type IssuerAdminRolesTagged = t.TypeOf<IssuerAdminRolesTaggedC>;
export type IssuerAdminRoles = IssuerAdminRolesTagged & typeof issuerAdminRoles;
export type IssuerAdminRolesC = t.Type<IssuerAdminRoles, IssuerAdminRolesTagged>;
export const issuerAdminRolesC: IssuerAdminRolesC = pipe(issuerAdminRolesTaggedC, c => new t.Type<IssuerAdminRoles, IssuerAdminRolesTagged>(
  `IssuerAdminRoles`,
  (u: unknown): u is IssuerAdminRoles => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerAdminRoles> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerAdminRoles }))),
  (x: IssuerAdminRoles): IssuerAdminRolesTagged => ({ ...x, _tag: `IssuerAdminRoles`}),
)) satisfies t.Type<IssuerAdminRoles, unknown>;


export const bondLinkAdminRoles = {
  _tag: `BondLinkAdminRoles`,
  roles: [imported4_admin, imported2_salesAdmin]
} as const;

export type BondLinkAdminRolesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondLinkAdminRoles`>
}>;
export const bondLinkAdminRolesTaggedC: BondLinkAdminRolesTaggedC = t.type({
  _tag: t.literal(`BondLinkAdminRoles`)
});
export type BondLinkAdminRolesTagged = t.TypeOf<BondLinkAdminRolesTaggedC>;
export type BondLinkAdminRoles = BondLinkAdminRolesTagged & typeof bondLinkAdminRoles;
export type BondLinkAdminRolesC = t.Type<BondLinkAdminRoles, BondLinkAdminRolesTagged>;
export const bondLinkAdminRolesC: BondLinkAdminRolesC = pipe(bondLinkAdminRolesTaggedC, c => new t.Type<BondLinkAdminRoles, BondLinkAdminRolesTagged>(
  `BondLinkAdminRoles`,
  (u: unknown): u is BondLinkAdminRoles => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondLinkAdminRoles> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondLinkAdminRoles }))),
  (x: BondLinkAdminRoles): BondLinkAdminRolesTagged => ({ ...x, _tag: `BondLinkAdminRoles`}),
)) satisfies t.Type<BondLinkAdminRoles, unknown>;


export const bondLinkSuperAdminRoles = {
  _tag: `BondLinkSuperAdminRoles`,
  roles: [imported6_admin]
} as const;

export type BondLinkSuperAdminRolesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondLinkSuperAdminRoles`>
}>;
export const bondLinkSuperAdminRolesTaggedC: BondLinkSuperAdminRolesTaggedC = t.type({
  _tag: t.literal(`BondLinkSuperAdminRoles`)
});
export type BondLinkSuperAdminRolesTagged = t.TypeOf<BondLinkSuperAdminRolesTaggedC>;
export type BondLinkSuperAdminRoles = BondLinkSuperAdminRolesTagged & typeof bondLinkSuperAdminRoles;
export type BondLinkSuperAdminRolesC = t.Type<BondLinkSuperAdminRoles, BondLinkSuperAdminRolesTagged>;
export const bondLinkSuperAdminRolesC: BondLinkSuperAdminRolesC = pipe(bondLinkSuperAdminRolesTaggedC, c => new t.Type<BondLinkSuperAdminRoles, BondLinkSuperAdminRolesTagged>(
  `BondLinkSuperAdminRoles`,
  (u: unknown): u is BondLinkSuperAdminRoles => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondLinkSuperAdminRoles> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondLinkSuperAdminRoles }))),
  (x: BondLinkSuperAdminRoles): BondLinkSuperAdminRolesTagged => ({ ...x, _tag: `BondLinkSuperAdminRoles`}),
)) satisfies t.Type<BondLinkSuperAdminRoles, unknown>;


export const allFeatureRolesC = [bankUserRolesC, issuerUserRolesC, issuerAdminRolesC, bondLinkAdminRolesC, bondLinkSuperAdminRolesC] as const;
export const allFeatureRolesNames = [`BankUserRoles`, `IssuerUserRoles`, `IssuerAdminRoles`, `BondLinkAdminRoles`, `BondLinkSuperAdminRoles`] as const;
export type FeatureRolesName = (typeof allFeatureRolesNames)[number];

export type FeatureRolesCU = t.UnionC<[BankUserRolesC, IssuerUserRolesC, IssuerAdminRolesC, BondLinkAdminRolesC, BondLinkSuperAdminRolesC]>;
export type FeatureRolesU = BankUserRoles | IssuerUserRoles | IssuerAdminRoles | BondLinkAdminRoles | BondLinkSuperAdminRoles;
export const FeatureRolesCU: FeatureRolesCU = t.union([bankUserRolesC, issuerUserRolesC, issuerAdminRolesC, bondLinkAdminRolesC, bondLinkSuperAdminRolesC]) satisfies t.Type<FeatureRolesU, unknown>;

export const featureRolesOrd: Ord.Ord<FeatureRolesU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allFeatureRoles = [bankUserRoles, issuerUserRoles, issuerAdminRoles, bondLinkAdminRoles, bondLinkSuperAdminRoles] as const;
export type FeatureRolesMap<A> = { [K in FeatureRolesName]: A };


export const bankCalendar = {
  _tag: `BankCalendar`,
  default: false,
  dependsOn: O.none,
  description: `Show bank calendar on site`,
  featureType: sectionToggle,
  humanName: `Bank Calendar`,
  id: 85,
  notes: O.none,
  roles: bankUserRoles
} as const;

export type BankCalendarTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BankCalendar`>
}>;
export const bankCalendarTaggedC: BankCalendarTaggedC = t.type({
  _tag: t.literal(`BankCalendar`)
});
export type BankCalendarTagged = t.TypeOf<BankCalendarTaggedC>;
export type BankCalendar = BankCalendarTagged & typeof bankCalendar;
export type BankCalendarC = t.Type<BankCalendar, BankCalendarTagged>;
export const bankCalendarC: BankCalendarC = pipe(bankCalendarTaggedC, c => new t.Type<BankCalendar, BankCalendarTagged>(
  `BankCalendar`,
  (u: unknown): u is BankCalendar => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankCalendar> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankCalendar }))),
  (x: BankCalendar): BankCalendarTagged => ({ ...x, _tag: `BankCalendar`}),
)) satisfies t.Type<BankCalendar, unknown>;


export const dealView = {
  _tag: `DealView`,
  default: true,
  dependsOn: O.none,
  description: `Show deal view on site`,
  featureType: sectionToggle,
  humanName: `Deal View`,
  id: 88,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export type DealViewTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DealView`>
}>;
export const dealViewTaggedC: DealViewTaggedC = t.type({
  _tag: t.literal(`DealView`)
});
export type DealViewTagged = t.TypeOf<DealViewTaggedC>;
export type DealView = DealViewTagged & typeof dealView;
export type DealViewC = t.Type<DealView, DealViewTagged>;
export const dealViewC: DealViewC = pipe(dealViewTaggedC, c => new t.Type<DealView, DealViewTagged>(
  `DealView`,
  (u: unknown): u is DealView => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DealView> => pipe(c.decode(u), E.map(x => ({ ...x, ...dealView }))),
  (x: DealView): DealViewTagged => ({ ...x, _tag: `DealView`}),
)) satisfies t.Type<DealView, unknown>;


export const copyBankAsIssuerParticipant = {
  _tag: `CopyBankAsIssuerParticipant`,
  default: false,
  dependsOn: O.none,
  description: `Whether to add the bank as an offering participant for every newly created issuer`,
  featureType: internalFlag,
  humanName: `Copy Bank As Issuer Participant`,
  id: 89,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export type CopyBankAsIssuerParticipantTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CopyBankAsIssuerParticipant`>
}>;
export const copyBankAsIssuerParticipantTaggedC: CopyBankAsIssuerParticipantTaggedC = t.type({
  _tag: t.literal(`CopyBankAsIssuerParticipant`)
});
export type CopyBankAsIssuerParticipantTagged = t.TypeOf<CopyBankAsIssuerParticipantTaggedC>;
export type CopyBankAsIssuerParticipant = CopyBankAsIssuerParticipantTagged & typeof copyBankAsIssuerParticipant;
export type CopyBankAsIssuerParticipantC = t.Type<CopyBankAsIssuerParticipant, CopyBankAsIssuerParticipantTagged>;
export const copyBankAsIssuerParticipantC: CopyBankAsIssuerParticipantC = pipe(copyBankAsIssuerParticipantTaggedC, c => new t.Type<CopyBankAsIssuerParticipant, CopyBankAsIssuerParticipantTagged>(
  `CopyBankAsIssuerParticipant`,
  (u: unknown): u is CopyBankAsIssuerParticipant => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CopyBankAsIssuerParticipant> => pipe(c.decode(u), E.map(x => ({ ...x, ...copyBankAsIssuerParticipant }))),
  (x: CopyBankAsIssuerParticipant): CopyBankAsIssuerParticipantTagged => ({ ...x, _tag: `CopyBankAsIssuerParticipant`}),
)) satisfies t.Type<CopyBankAsIssuerParticipant, unknown>;


export const bondOfferings = {
  _tag: `BondOfferings`,
  default: false,
  dependsOn: O.none,
  description: `Show bond offerings on site`,
  featureType: sectionToggle,
  humanName: `Bond Offerings`,
  id: 1,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type BondOfferingsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondOfferings`>
}>;
export const bondOfferingsTaggedC: BondOfferingsTaggedC = t.type({
  _tag: t.literal(`BondOfferings`)
});
export type BondOfferingsTagged = t.TypeOf<BondOfferingsTaggedC>;
export type BondOfferings = BondOfferingsTagged & typeof bondOfferings;
export type BondOfferingsC = t.Type<BondOfferings, BondOfferingsTagged>;
export const bondOfferingsC: BondOfferingsC = pipe(bondOfferingsTaggedC, c => new t.Type<BondOfferings, BondOfferingsTagged>(
  `BondOfferings`,
  (u: unknown): u is BondOfferings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferings> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferings }))),
  (x: BondOfferings): BondOfferingsTagged => ({ ...x, _tag: `BondOfferings`}),
)) satisfies t.Type<BondOfferings, unknown>;


export const bondOfferingsGeneralInfo = {
  _tag: `BondOfferingsGeneralInfo`,
  default: false,
  dependsOn: O.none,
  description: `Show general offering information message`,
  featureType: sectionToggle,
  humanName: `Bond Offerings General Info`,
  id: 3,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type BondOfferingsGeneralInfoTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondOfferingsGeneralInfo`>
}>;
export const bondOfferingsGeneralInfoTaggedC: BondOfferingsGeneralInfoTaggedC = t.type({
  _tag: t.literal(`BondOfferingsGeneralInfo`)
});
export type BondOfferingsGeneralInfoTagged = t.TypeOf<BondOfferingsGeneralInfoTaggedC>;
export type BondOfferingsGeneralInfo = BondOfferingsGeneralInfoTagged & typeof bondOfferingsGeneralInfo;
export type BondOfferingsGeneralInfoC = t.Type<BondOfferingsGeneralInfo, BondOfferingsGeneralInfoTagged>;
export const bondOfferingsGeneralInfoC: BondOfferingsGeneralInfoC = pipe(bondOfferingsGeneralInfoTaggedC, c => new t.Type<BondOfferingsGeneralInfo, BondOfferingsGeneralInfoTagged>(
  `BondOfferingsGeneralInfo`,
  (u: unknown): u is BondOfferingsGeneralInfo => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsGeneralInfo> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsGeneralInfo }))),
  (x: BondOfferingsGeneralInfo): BondOfferingsGeneralInfoTagged => ({ ...x, _tag: `BondOfferingsGeneralInfo`}),
)) satisfies t.Type<BondOfferingsGeneralInfo, unknown>;


export const bondOfferingsDisclaimer = {
  _tag: `BondOfferingsDisclaimer`,
  default: true,
  dependsOn: O.none,
  description: `Enable offering disclaimer`,
  featureType: disclaimer,
  humanName: `Bond Offerings Disclaimer`,
  id: 4,
  notes: O.some(`When enabled, a link to the disclaimer will automatically be added to your site footer.`),
  roles: issuerUserRoles
} as const;

export type BondOfferingsDisclaimerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondOfferingsDisclaimer`>
}>;
export const bondOfferingsDisclaimerTaggedC: BondOfferingsDisclaimerTaggedC = t.type({
  _tag: t.literal(`BondOfferingsDisclaimer`)
});
export type BondOfferingsDisclaimerTagged = t.TypeOf<BondOfferingsDisclaimerTaggedC>;
export type BondOfferingsDisclaimer = BondOfferingsDisclaimerTagged & typeof bondOfferingsDisclaimer;
export type BondOfferingsDisclaimerC = t.Type<BondOfferingsDisclaimer, BondOfferingsDisclaimerTagged>;
export const bondOfferingsDisclaimerC: BondOfferingsDisclaimerC = pipe(bondOfferingsDisclaimerTaggedC, c => new t.Type<BondOfferingsDisclaimer, BondOfferingsDisclaimerTagged>(
  `BondOfferingsDisclaimer`,
  (u: unknown): u is BondOfferingsDisclaimer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsDisclaimer> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsDisclaimer }))),
  (x: BondOfferingsDisclaimer): BondOfferingsDisclaimerTagged => ({ ...x, _tag: `BondOfferingsDisclaimer`}),
)) satisfies t.Type<BondOfferingsDisclaimer, unknown>;


export const documents = {
  _tag: `Documents`,
  default: true,
  dependsOn: O.none,
  description: `Show documents on site`,
  featureType: sectionToggle,
  humanName: `Documents`,
  id: 55,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export type DocumentsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Documents`>
}>;
export const documentsTaggedC: DocumentsTaggedC = t.type({
  _tag: t.literal(`Documents`)
});
export type DocumentsTagged = t.TypeOf<DocumentsTaggedC>;
export type Documents = DocumentsTagged & typeof documents;
export type DocumentsC = t.Type<Documents, DocumentsTagged>;
export const documentsC: DocumentsC = pipe(documentsTaggedC, c => new t.Type<Documents, DocumentsTagged>(
  `Documents`,
  (u: unknown): u is Documents => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Documents> => pipe(c.decode(u), E.map(x => ({ ...x, ...documents }))),
  (x: Documents): DocumentsTagged => ({ ...x, _tag: `Documents`}),
)) satisfies t.Type<Documents, unknown>;


export const reportsRequireLogin = {
  _tag: `ReportsRequireLogin`,
  default: false,
  dependsOn: O.none,
  description: `Require visitors to log in before viewing or downloading documents`,
  featureType: investorRequirement,
  humanName: `Reports Require Login`,
  id: 5,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type ReportsRequireLoginTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ReportsRequireLogin`>
}>;
export const reportsRequireLoginTaggedC: ReportsRequireLoginTaggedC = t.type({
  _tag: t.literal(`ReportsRequireLogin`)
});
export type ReportsRequireLoginTagged = t.TypeOf<ReportsRequireLoginTaggedC>;
export type ReportsRequireLogin = ReportsRequireLoginTagged & typeof reportsRequireLogin;
export type ReportsRequireLoginC = t.Type<ReportsRequireLogin, ReportsRequireLoginTagged>;
export const reportsRequireLoginC: ReportsRequireLoginC = pipe(reportsRequireLoginTaggedC, c => new t.Type<ReportsRequireLogin, ReportsRequireLoginTagged>(
  `ReportsRequireLogin`,
  (u: unknown): u is ReportsRequireLogin => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ReportsRequireLogin> => pipe(c.decode(u), E.map(x => ({ ...x, ...reportsRequireLogin }))),
  (x: ReportsRequireLogin): ReportsRequireLoginTagged => ({ ...x, _tag: `ReportsRequireLogin`}),
)) satisfies t.Type<ReportsRequireLogin, unknown>;


export const siteDisclaimer = {
  _tag: `SiteDisclaimer`,
  default: false,
  dependsOn: O.none,
  description: `Enable general disclaimer`,
  featureType: disclaimer,
  humanName: `Site Disclaimer`,
  id: 6,
  notes: O.some(`When enabled, a link to the disclaimer will automatically be added to your site footer.`),
  roles: issuerUserRoles
} as const;

export type SiteDisclaimerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SiteDisclaimer`>
}>;
export const siteDisclaimerTaggedC: SiteDisclaimerTaggedC = t.type({
  _tag: t.literal(`SiteDisclaimer`)
});
export type SiteDisclaimerTagged = t.TypeOf<SiteDisclaimerTaggedC>;
export type SiteDisclaimer = SiteDisclaimerTagged & typeof siteDisclaimer;
export type SiteDisclaimerC = t.Type<SiteDisclaimer, SiteDisclaimerTagged>;
export const siteDisclaimerC: SiteDisclaimerC = pipe(siteDisclaimerTaggedC, c => new t.Type<SiteDisclaimer, SiteDisclaimerTagged>(
  `SiteDisclaimer`,
  (u: unknown): u is SiteDisclaimer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SiteDisclaimer> => pipe(c.decode(u), E.map(x => ({ ...x, ...siteDisclaimer }))),
  (x: SiteDisclaimer): SiteDisclaimerTagged => ({ ...x, _tag: `SiteDisclaimer`}),
)) satisfies t.Type<SiteDisclaimer, unknown>;


export const siteDisclaimerModal = {
  _tag: `SiteDisclaimerModal`,
  default: false,
  dependsOn: O.some(siteDisclaimer),
  description: `Require visitors to accept general disclaimer before viewing site`,
  featureType: disclaimer,
  humanName: `Site Disclaimer Modal`,
  id: 7,
  notes: O.some(`You must have "Enable general disclaimer" turned on for this setting to take effect.`),
  roles: issuerUserRoles
} as const;

export type SiteDisclaimerModalTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SiteDisclaimerModal`>
}>;
export const siteDisclaimerModalTaggedC: SiteDisclaimerModalTaggedC = t.type({
  _tag: t.literal(`SiteDisclaimerModal`)
});
export type SiteDisclaimerModalTagged = t.TypeOf<SiteDisclaimerModalTaggedC>;
export type SiteDisclaimerModal = SiteDisclaimerModalTagged & typeof siteDisclaimerModal;
export type SiteDisclaimerModalC = t.Type<SiteDisclaimerModal, SiteDisclaimerModalTagged>;
export const siteDisclaimerModalC: SiteDisclaimerModalC = pipe(siteDisclaimerModalTaggedC, c => new t.Type<SiteDisclaimerModal, SiteDisclaimerModalTagged>(
  `SiteDisclaimerModal`,
  (u: unknown): u is SiteDisclaimerModal => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SiteDisclaimerModal> => pipe(c.decode(u), E.map(x => ({ ...x, ...siteDisclaimerModal }))),
  (x: SiteDisclaimerModal): SiteDisclaimerModalTagged => ({ ...x, _tag: `SiteDisclaimerModal`}),
)) satisfies t.Type<SiteDisclaimerModal, unknown>;


export const offeringsDisclaimerModal = {
  _tag: `OfferingsDisclaimerModal`,
  default: true,
  dependsOn: O.some(bondOfferingsDisclaimer),
  description: `Require visitors to accept offering disclaimer before viewing bond information`,
  featureType: disclaimer,
  humanName: `Offerings Disclaimer Modal`,
  id: 8,
  notes: O.some(`You must have "Enable offering disclaimer" turned on for this setting to take effect.`),
  roles: issuerUserRoles
} as const;

export type OfferingsDisclaimerModalTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OfferingsDisclaimerModal`>
}>;
export const offeringsDisclaimerModalTaggedC: OfferingsDisclaimerModalTaggedC = t.type({
  _tag: t.literal(`OfferingsDisclaimerModal`)
});
export type OfferingsDisclaimerModalTagged = t.TypeOf<OfferingsDisclaimerModalTaggedC>;
export type OfferingsDisclaimerModal = OfferingsDisclaimerModalTagged & typeof offeringsDisclaimerModal;
export type OfferingsDisclaimerModalC = t.Type<OfferingsDisclaimerModal, OfferingsDisclaimerModalTagged>;
export const offeringsDisclaimerModalC: OfferingsDisclaimerModalC = pipe(offeringsDisclaimerModalTaggedC, c => new t.Type<OfferingsDisclaimerModal, OfferingsDisclaimerModalTagged>(
  `OfferingsDisclaimerModal`,
  (u: unknown): u is OfferingsDisclaimerModal => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferingsDisclaimerModal> => pipe(c.decode(u), E.map(x => ({ ...x, ...offeringsDisclaimerModal }))),
  (x: OfferingsDisclaimerModal): OfferingsDisclaimerModalTagged => ({ ...x, _tag: `OfferingsDisclaimerModal`}),
)) satisfies t.Type<OfferingsDisclaimerModal, unknown>;


export const relatedIssuers = {
  _tag: `RelatedIssuers`,
  default: false,
  dependsOn: O.none,
  description: `Show related issuers in site navigation`,
  featureType: sectionToggle,
  humanName: `Related Issuers`,
  id: 9,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type RelatedIssuersTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RelatedIssuers`>
}>;
export const relatedIssuersTaggedC: RelatedIssuersTaggedC = t.type({
  _tag: t.literal(`RelatedIssuers`)
});
export type RelatedIssuersTagged = t.TypeOf<RelatedIssuersTaggedC>;
export type RelatedIssuers = RelatedIssuersTagged & typeof relatedIssuers;
export type RelatedIssuersC = t.Type<RelatedIssuers, RelatedIssuersTagged>;
export const relatedIssuersC: RelatedIssuersC = pipe(relatedIssuersTaggedC, c => new t.Type<RelatedIssuers, RelatedIssuersTagged>(
  `RelatedIssuers`,
  (u: unknown): u is RelatedIssuers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RelatedIssuers> => pipe(c.decode(u), E.map(x => ({ ...x, ...relatedIssuers }))),
  (x: RelatedIssuers): RelatedIssuersTagged => ({ ...x, _tag: `RelatedIssuers`}),
)) satisfies t.Type<RelatedIssuers, unknown>;


export const projects = {
  _tag: `Projects`,
  default: true,
  dependsOn: O.none,
  description: `Show projects on site`,
  featureType: sectionToggle,
  humanName: `Projects`,
  id: 13,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type ProjectsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Projects`>
}>;
export const projectsTaggedC: ProjectsTaggedC = t.type({
  _tag: t.literal(`Projects`)
});
export type ProjectsTagged = t.TypeOf<ProjectsTaggedC>;
export type Projects = ProjectsTagged & typeof projects;
export type ProjectsC = t.Type<Projects, ProjectsTagged>;
export const projectsC: ProjectsC = pipe(projectsTaggedC, c => new t.Type<Projects, ProjectsTagged>(
  `Projects`,
  (u: unknown): u is Projects => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Projects> => pipe(c.decode(u), E.map(x => ({ ...x, ...projects }))),
  (x: Projects): ProjectsTagged => ({ ...x, _tag: `Projects`}),
)) satisfies t.Type<Projects, unknown>;


export const emma = {
  _tag: `Emma`,
  default: true,
  dependsOn: O.none,
  description: `Show CUSIP-9 section on site`,
  featureType: sectionToggle,
  humanName: `Emma`,
  id: 19,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type EmmaTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Emma`>
}>;
export const emmaTaggedC: EmmaTaggedC = t.type({
  _tag: t.literal(`Emma`)
});
export type EmmaTagged = t.TypeOf<EmmaTaggedC>;
export type Emma = EmmaTagged & typeof emma;
export type EmmaC = t.Type<Emma, EmmaTagged>;
export const emmaC: EmmaC = pipe(emmaTaggedC, c => new t.Type<Emma, EmmaTagged>(
  `Emma`,
  (u: unknown): u is Emma => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Emma> => pipe(c.decode(u), E.map(x => ({ ...x, ...emma }))),
  (x: Emma): EmmaTagged => ({ ...x, _tag: `Emma`}),
)) satisfies t.Type<Emma, unknown>;


export const draftMode = {
  _tag: `DraftMode`,
  default: false,
  dependsOn: O.none,
  description: `Require draft approval before publishing changes`,
  featureType: sectionToggle,
  humanName: `Draft Mode`,
  id: 23,
  notes: O.none,
  roles: issuerAdminRoles
} as const;

export type DraftModeTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DraftMode`>
}>;
export const draftModeTaggedC: DraftModeTaggedC = t.type({
  _tag: t.literal(`DraftMode`)
});
export type DraftModeTagged = t.TypeOf<DraftModeTaggedC>;
export type DraftMode = DraftModeTagged & typeof draftMode;
export type DraftModeC = t.Type<DraftMode, DraftModeTagged>;
export const draftModeC: DraftModeC = pipe(draftModeTaggedC, c => new t.Type<DraftMode, DraftModeTagged>(
  `DraftMode`,
  (u: unknown): u is DraftMode => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DraftMode> => pipe(c.decode(u), E.map(x => ({ ...x, ...draftMode }))),
  (x: DraftMode): DraftModeTagged => ({ ...x, _tag: `DraftMode`}),
)) satisfies t.Type<DraftMode, unknown>;


export const exitDisclaimer = {
  _tag: `ExitDisclaimer`,
  default: false,
  dependsOn: O.none,
  description: `Show exit disclaimer before viewing a related issuer site`,
  featureType: disclaimer,
  humanName: `Exit Disclaimer`,
  id: 24,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type ExitDisclaimerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ExitDisclaimer`>
}>;
export const exitDisclaimerTaggedC: ExitDisclaimerTaggedC = t.type({
  _tag: t.literal(`ExitDisclaimer`)
});
export type ExitDisclaimerTagged = t.TypeOf<ExitDisclaimerTaggedC>;
export type ExitDisclaimer = ExitDisclaimerTagged & typeof exitDisclaimer;
export type ExitDisclaimerC = t.Type<ExitDisclaimer, ExitDisclaimerTagged>;
export const exitDisclaimerC: ExitDisclaimerC = pipe(exitDisclaimerTaggedC, c => new t.Type<ExitDisclaimer, ExitDisclaimerTagged>(
  `ExitDisclaimer`,
  (u: unknown): u is ExitDisclaimer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ExitDisclaimer> => pipe(c.decode(u), E.map(x => ({ ...x, ...exitDisclaimer }))),
  (x: ExitDisclaimer): ExitDisclaimerTagged => ({ ...x, _tag: `ExitDisclaimer`}),
)) satisfies t.Type<ExitDisclaimer, unknown>;


export const news = {
  _tag: `News`,
  default: true,
  dependsOn: O.none,
  description: `Show news on site`,
  featureType: sectionToggle,
  humanName: `News`,
  id: 26,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type NewsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`News`>
}>;
export const newsTaggedC: NewsTaggedC = t.type({
  _tag: t.literal(`News`)
});
export type NewsTagged = t.TypeOf<NewsTaggedC>;
export type News = NewsTagged & typeof news;
export type NewsC = t.Type<News, NewsTagged>;
export const newsC: NewsC = pipe(newsTaggedC, c => new t.Type<News, NewsTagged>(
  `News`,
  (u: unknown): u is News => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, News> => pipe(c.decode(u), E.map(x => ({ ...x, ...news }))),
  (x: News): NewsTagged => ({ ...x, _tag: `News`}),
)) satisfies t.Type<News, unknown>;


export const faq = {
  _tag: `Faq`,
  default: true,
  dependsOn: O.none,
  description: `Show FAQ page on site`,
  featureType: sectionToggle,
  humanName: `Faq`,
  id: 27,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type FaqTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Faq`>
}>;
export const faqTaggedC: FaqTaggedC = t.type({
  _tag: t.literal(`Faq`)
});
export type FaqTagged = t.TypeOf<FaqTaggedC>;
export type Faq = FaqTagged & typeof faq;
export type FaqC = t.Type<Faq, FaqTagged>;
export const faqC: FaqC = pipe(faqTaggedC, c => new t.Type<Faq, FaqTagged>(
  `Faq`,
  (u: unknown): u is Faq => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Faq> => pipe(c.decode(u), E.map(x => ({ ...x, ...faq }))),
  (x: Faq): FaqTagged => ({ ...x, _tag: `Faq`}),
)) satisfies t.Type<Faq, unknown>;


export const bondRatings = {
  _tag: `BondRatings`,
  default: true,
  dependsOn: O.none,
  description: `Show bond ratings on site`,
  featureType: sectionToggle,
  humanName: `Bond Ratings`,
  id: 29,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type BondRatingsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondRatings`>
}>;
export const bondRatingsTaggedC: BondRatingsTaggedC = t.type({
  _tag: t.literal(`BondRatings`)
});
export type BondRatingsTagged = t.TypeOf<BondRatingsTaggedC>;
export type BondRatings = BondRatingsTagged & typeof bondRatings;
export type BondRatingsC = t.Type<BondRatings, BondRatingsTagged>;
export const bondRatingsC: BondRatingsC = pipe(bondRatingsTaggedC, c => new t.Type<BondRatings, BondRatingsTagged>(
  `BondRatings`,
  (u: unknown): u is BondRatings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondRatings> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondRatings }))),
  (x: BondRatings): BondRatingsTagged => ({ ...x, _tag: `BondRatings`}),
)) satisfies t.Type<BondRatings, unknown>;


export const showArchivedDocs = {
  _tag: `ShowArchivedDocs`,
  default: false,
  dependsOn: O.none,
  description: `Show document archive on site`,
  featureType: sectionToggle,
  humanName: `Show Archived Docs`,
  id: 32,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type ShowArchivedDocsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ShowArchivedDocs`>
}>;
export const showArchivedDocsTaggedC: ShowArchivedDocsTaggedC = t.type({
  _tag: t.literal(`ShowArchivedDocs`)
});
export type ShowArchivedDocsTagged = t.TypeOf<ShowArchivedDocsTaggedC>;
export type ShowArchivedDocs = ShowArchivedDocsTagged & typeof showArchivedDocs;
export type ShowArchivedDocsC = t.Type<ShowArchivedDocs, ShowArchivedDocsTagged>;
export const showArchivedDocsC: ShowArchivedDocsC = pipe(showArchivedDocsTaggedC, c => new t.Type<ShowArchivedDocs, ShowArchivedDocsTagged>(
  `ShowArchivedDocs`,
  (u: unknown): u is ShowArchivedDocs => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ShowArchivedDocs> => pipe(c.decode(u), E.map(x => ({ ...x, ...showArchivedDocs }))),
  (x: ShowArchivedDocs): ShowArchivedDocsTagged => ({ ...x, _tag: `ShowArchivedDocs`}),
)) satisfies t.Type<ShowArchivedDocs, unknown>;


export const financeTeam = {
  _tag: `FinanceTeam`,
  default: true,
  dependsOn: O.none,
  description: `Show team on site`,
  featureType: sectionToggle,
  humanName: `Finance Team`,
  id: 34,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type FinanceTeamTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FinanceTeam`>
}>;
export const financeTeamTaggedC: FinanceTeamTaggedC = t.type({
  _tag: t.literal(`FinanceTeam`)
});
export type FinanceTeamTagged = t.TypeOf<FinanceTeamTaggedC>;
export type FinanceTeam = FinanceTeamTagged & typeof financeTeam;
export type FinanceTeamC = t.Type<FinanceTeam, FinanceTeamTagged>;
export const financeTeamC: FinanceTeamC = pipe(financeTeamTaggedC, c => new t.Type<FinanceTeam, FinanceTeamTagged>(
  `FinanceTeam`,
  (u: unknown): u is FinanceTeam => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FinanceTeam> => pipe(c.decode(u), E.map(x => ({ ...x, ...financeTeam }))),
  (x: FinanceTeam): FinanceTeamTagged => ({ ...x, _tag: `FinanceTeam`}),
)) satisfies t.Type<FinanceTeam, unknown>;


export const financeCalendar = {
  _tag: `FinanceCalendar`,
  default: true,
  dependsOn: O.none,
  description: `Show events on site`,
  featureType: sectionToggle,
  humanName: `Finance Calendar`,
  id: 35,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type FinanceCalendarTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FinanceCalendar`>
}>;
export const financeCalendarTaggedC: FinanceCalendarTaggedC = t.type({
  _tag: t.literal(`FinanceCalendar`)
});
export type FinanceCalendarTagged = t.TypeOf<FinanceCalendarTaggedC>;
export type FinanceCalendar = FinanceCalendarTagged & typeof financeCalendar;
export type FinanceCalendarC = t.Type<FinanceCalendar, FinanceCalendarTagged>;
export const financeCalendarC: FinanceCalendarC = pipe(financeCalendarTaggedC, c => new t.Type<FinanceCalendar, FinanceCalendarTagged>(
  `FinanceCalendar`,
  (u: unknown): u is FinanceCalendar => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FinanceCalendar> => pipe(c.decode(u), E.map(x => ({ ...x, ...financeCalendar }))),
  (x: FinanceCalendar): FinanceCalendarTagged => ({ ...x, _tag: `FinanceCalendar`}),
)) satisfies t.Type<FinanceCalendar, unknown>;


export const menuItems = {
  _tag: `MenuItems`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable custom menu items`,
  featureType: sectionToggle,
  humanName: `Menu Items`,
  id: 37,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export type MenuItemsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MenuItems`>
}>;
export const menuItemsTaggedC: MenuItemsTaggedC = t.type({
  _tag: t.literal(`MenuItems`)
});
export type MenuItemsTagged = t.TypeOf<MenuItemsTaggedC>;
export type MenuItems = MenuItemsTagged & typeof menuItems;
export type MenuItemsC = t.Type<MenuItems, MenuItemsTagged>;
export const menuItemsC: MenuItemsC = pipe(menuItemsTaggedC, c => new t.Type<MenuItems, MenuItemsTagged>(
  `MenuItems`,
  (u: unknown): u is MenuItems => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MenuItems> => pipe(c.decode(u), E.map(x => ({ ...x, ...menuItems }))),
  (x: MenuItems): MenuItemsTagged => ({ ...x, _tag: `MenuItems`}),
)) satisfies t.Type<MenuItems, unknown>;


export const showDocumentDisclaimer = {
  _tag: `ShowDocumentDisclaimer`,
  default: false,
  dependsOn: O.none,
  description: `Enable document disclaimer`,
  featureType: disclaimer,
  humanName: `Show Document Disclaimer`,
  id: 39,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type ShowDocumentDisclaimerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ShowDocumentDisclaimer`>
}>;
export const showDocumentDisclaimerTaggedC: ShowDocumentDisclaimerTaggedC = t.type({
  _tag: t.literal(`ShowDocumentDisclaimer`)
});
export type ShowDocumentDisclaimerTagged = t.TypeOf<ShowDocumentDisclaimerTaggedC>;
export type ShowDocumentDisclaimer = ShowDocumentDisclaimerTagged & typeof showDocumentDisclaimer;
export type ShowDocumentDisclaimerC = t.Type<ShowDocumentDisclaimer, ShowDocumentDisclaimerTagged>;
export const showDocumentDisclaimerC: ShowDocumentDisclaimerC = pipe(showDocumentDisclaimerTaggedC, c => new t.Type<ShowDocumentDisclaimer, ShowDocumentDisclaimerTagged>(
  `ShowDocumentDisclaimer`,
  (u: unknown): u is ShowDocumentDisclaimer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ShowDocumentDisclaimer> => pipe(c.decode(u), E.map(x => ({ ...x, ...showDocumentDisclaimer }))),
  (x: ShowDocumentDisclaimer): ShowDocumentDisclaimerTagged => ({ ...x, _tag: `ShowDocumentDisclaimer`}),
)) satisfies t.Type<ShowDocumentDisclaimer, unknown>;


export const documentDisclaimerModal = {
  _tag: `DocumentDisclaimerModal`,
  default: false,
  dependsOn: O.some(showDocumentDisclaimer),
  description: `Require visitors to accept document disclaimer before viewing or downloading documents`,
  featureType: disclaimer,
  humanName: `Document Disclaimer Modal`,
  id: 40,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type DocumentDisclaimerModalTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DocumentDisclaimerModal`>
}>;
export const documentDisclaimerModalTaggedC: DocumentDisclaimerModalTaggedC = t.type({
  _tag: t.literal(`DocumentDisclaimerModal`)
});
export type DocumentDisclaimerModalTagged = t.TypeOf<DocumentDisclaimerModalTaggedC>;
export type DocumentDisclaimerModal = DocumentDisclaimerModalTagged & typeof documentDisclaimerModal;
export type DocumentDisclaimerModalC = t.Type<DocumentDisclaimerModal, DocumentDisclaimerModalTagged>;
export const documentDisclaimerModalC: DocumentDisclaimerModalC = pipe(documentDisclaimerModalTaggedC, c => new t.Type<DocumentDisclaimerModal, DocumentDisclaimerModalTagged>(
  `DocumentDisclaimerModal`,
  (u: unknown): u is DocumentDisclaimerModal => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DocumentDisclaimerModal> => pipe(c.decode(u), E.map(x => ({ ...x, ...documentDisclaimerModal }))),
  (x: DocumentDisclaimerModal): DocumentDisclaimerModalTagged => ({ ...x, _tag: `DocumentDisclaimerModal`}),
)) satisfies t.Type<DocumentDisclaimerModal, unknown>;


export const customPage1 = {
  _tag: `CustomPage1`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable custom page 1`,
  featureType: sectionToggle,
  humanName: `Custom Page 1`,
  id: 41,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type CustomPage1TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage1`>
}>;
export const customPage1TaggedC: CustomPage1TaggedC = t.type({
  _tag: t.literal(`CustomPage1`)
});
export type CustomPage1Tagged = t.TypeOf<CustomPage1TaggedC>;
export type CustomPage1 = CustomPage1Tagged & typeof customPage1;
export type CustomPage1C = t.Type<CustomPage1, CustomPage1Tagged>;
export const customPage1C: CustomPage1C = pipe(customPage1TaggedC, c => new t.Type<CustomPage1, CustomPage1Tagged>(
  `CustomPage1`,
  (u: unknown): u is CustomPage1 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage1> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage1 }))),
  (x: CustomPage1): CustomPage1Tagged => ({ ...x, _tag: `CustomPage1`}),
)) satisfies t.Type<CustomPage1, unknown>;


export const customPage2 = {
  _tag: `CustomPage2`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable custom page 2`,
  featureType: sectionToggle,
  humanName: `Custom Page 2`,
  id: 42,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type CustomPage2TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage2`>
}>;
export const customPage2TaggedC: CustomPage2TaggedC = t.type({
  _tag: t.literal(`CustomPage2`)
});
export type CustomPage2Tagged = t.TypeOf<CustomPage2TaggedC>;
export type CustomPage2 = CustomPage2Tagged & typeof customPage2;
export type CustomPage2C = t.Type<CustomPage2, CustomPage2Tagged>;
export const customPage2C: CustomPage2C = pipe(customPage2TaggedC, c => new t.Type<CustomPage2, CustomPage2Tagged>(
  `CustomPage2`,
  (u: unknown): u is CustomPage2 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage2> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage2 }))),
  (x: CustomPage2): CustomPage2Tagged => ({ ...x, _tag: `CustomPage2`}),
)) satisfies t.Type<CustomPage2, unknown>;


export const cusip6 = {
  _tag: `Cusip6`,
  default: false,
  dependsOn: O.none,
  description: `Show CUSIP-6 section on site`,
  featureType: sectionToggle,
  humanName: `Cusip 6`,
  id: 49,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type Cusip6TaggedC = t.TypeC<{
  _tag: t.LiteralC<`Cusip6`>
}>;
export const cusip6TaggedC: Cusip6TaggedC = t.type({
  _tag: t.literal(`Cusip6`)
});
export type Cusip6Tagged = t.TypeOf<Cusip6TaggedC>;
export type Cusip6 = Cusip6Tagged & typeof cusip6;
export type Cusip6C = t.Type<Cusip6, Cusip6Tagged>;
export const cusip6C: Cusip6C = pipe(cusip6TaggedC, c => new t.Type<Cusip6, Cusip6Tagged>(
  `Cusip6`,
  (u: unknown): u is Cusip6 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip6> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip6 }))),
  (x: Cusip6): Cusip6Tagged => ({ ...x, _tag: `Cusip6`}),
)) satisfies t.Type<Cusip6, unknown>;


export const contact = {
  _tag: `Contact`,
  default: true,
  dependsOn: O.none,
  description: `Show contact info on site`,
  featureType: sectionToggle,
  humanName: `Contact`,
  id: 52,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export type ContactTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Contact`>
}>;
export const contactTaggedC: ContactTaggedC = t.type({
  _tag: t.literal(`Contact`)
});
export type ContactTagged = t.TypeOf<ContactTaggedC>;
export type Contact = ContactTagged & typeof contact;
export type ContactC = t.Type<Contact, ContactTagged>;
export const contactC: ContactC = pipe(contactTaggedC, c => new t.Type<Contact, ContactTagged>(
  `Contact`,
  (u: unknown): u is Contact => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Contact> => pipe(c.decode(u), E.map(x => ({ ...x, ...contact }))),
  (x: Contact): ContactTagged => ({ ...x, _tag: `Contact`}),
)) satisfies t.Type<Contact, unknown>;


export const invoiceEmails = {
  _tag: `InvoiceEmails`,
  default: true,
  dependsOn: O.none,
  description: `Send email notifications of new invoices`,
  featureType: billing,
  humanName: `Invoice Emails`,
  id: 54,
  notes: O.none,
  roles: issuerAdminRoles
} as const;

export type InvoiceEmailsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`InvoiceEmails`>
}>;
export const invoiceEmailsTaggedC: InvoiceEmailsTaggedC = t.type({
  _tag: t.literal(`InvoiceEmails`)
});
export type InvoiceEmailsTagged = t.TypeOf<InvoiceEmailsTaggedC>;
export type InvoiceEmails = InvoiceEmailsTagged & typeof invoiceEmails;
export type InvoiceEmailsC = t.Type<InvoiceEmails, InvoiceEmailsTagged>;
export const invoiceEmailsC: InvoiceEmailsC = pipe(invoiceEmailsTaggedC, c => new t.Type<InvoiceEmails, InvoiceEmailsTagged>(
  `InvoiceEmails`,
  (u: unknown): u is InvoiceEmails => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvoiceEmails> => pipe(c.decode(u), E.map(x => ({ ...x, ...invoiceEmails }))),
  (x: InvoiceEmails): InvoiceEmailsTagged => ({ ...x, _tag: `InvoiceEmails`}),
)) satisfies t.Type<InvoiceEmails, unknown>;


export const roadShows = {
  _tag: `RoadShows`,
  default: false,
  dependsOn: O.none,
  description: `Show roadshows on site`,
  featureType: sectionToggle,
  humanName: `Road Shows`,
  id: 58,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type RoadShowsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RoadShows`>
}>;
export const roadShowsTaggedC: RoadShowsTaggedC = t.type({
  _tag: t.literal(`RoadShows`)
});
export type RoadShowsTagged = t.TypeOf<RoadShowsTaggedC>;
export type RoadShows = RoadShowsTagged & typeof roadShows;
export type RoadShowsC = t.Type<RoadShows, RoadShowsTagged>;
export const roadShowsC: RoadShowsC = pipe(roadShowsTaggedC, c => new t.Type<RoadShows, RoadShowsTagged>(
  `RoadShows`,
  (u: unknown): u is RoadShows => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RoadShows> => pipe(c.decode(u), E.map(x => ({ ...x, ...roadShows }))),
  (x: RoadShows): RoadShowsTagged => ({ ...x, _tag: `RoadShows`}),
)) satisfies t.Type<RoadShows, unknown>;


export const displayCalendarOfferings = {
  _tag: `DisplayCalendarOfferings`,
  default: true,
  dependsOn: O.some(financeCalendar),
  description: `Include upcoming bond sales on events list`,
  featureType: visual,
  humanName: `Display Calendar Offerings`,
  id: 62,
  notes: O.some(`You must have "Show events on site" turned on for this setting to take effect.`),
  roles: issuerUserRoles
} as const;

export type DisplayCalendarOfferingsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DisplayCalendarOfferings`>
}>;
export const displayCalendarOfferingsTaggedC: DisplayCalendarOfferingsTaggedC = t.type({
  _tag: t.literal(`DisplayCalendarOfferings`)
});
export type DisplayCalendarOfferingsTagged = t.TypeOf<DisplayCalendarOfferingsTaggedC>;
export type DisplayCalendarOfferings = DisplayCalendarOfferingsTagged & typeof displayCalendarOfferings;
export type DisplayCalendarOfferingsC = t.Type<DisplayCalendarOfferings, DisplayCalendarOfferingsTagged>;
export const displayCalendarOfferingsC: DisplayCalendarOfferingsC = pipe(displayCalendarOfferingsTaggedC, c => new t.Type<DisplayCalendarOfferings, DisplayCalendarOfferingsTagged>(
  `DisplayCalendarOfferings`,
  (u: unknown): u is DisplayCalendarOfferings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DisplayCalendarOfferings> => pipe(c.decode(u), E.map(x => ({ ...x, ...displayCalendarOfferings }))),
  (x: DisplayCalendarOfferings): DisplayCalendarOfferingsTagged => ({ ...x, _tag: `DisplayCalendarOfferings`}),
)) satisfies t.Type<DisplayCalendarOfferings, unknown>;


export const offeringParticipants = {
  _tag: `OfferingParticipants`,
  default: true,
  dependsOn: O.none,
  description: `Show offering participants on bonds landing page`,
  featureType: sectionToggle,
  humanName: `Offering Participants`,
  id: 64,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type OfferingParticipantsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OfferingParticipants`>
}>;
export const offeringParticipantsTaggedC: OfferingParticipantsTaggedC = t.type({
  _tag: t.literal(`OfferingParticipants`)
});
export type OfferingParticipantsTagged = t.TypeOf<OfferingParticipantsTaggedC>;
export type OfferingParticipants = OfferingParticipantsTagged & typeof offeringParticipants;
export type OfferingParticipantsC = t.Type<OfferingParticipants, OfferingParticipantsTagged>;
export const offeringParticipantsC: OfferingParticipantsC = pipe(offeringParticipantsTaggedC, c => new t.Type<OfferingParticipants, OfferingParticipantsTagged>(
  `OfferingParticipants`,
  (u: unknown): u is OfferingParticipants => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferingParticipants> => pipe(c.decode(u), E.map(x => ({ ...x, ...offeringParticipants }))),
  (x: OfferingParticipants): OfferingParticipantsTagged => ({ ...x, _tag: `OfferingParticipants`}),
)) satisfies t.Type<OfferingParticipants, unknown>;


export const customPage3 = {
  _tag: `CustomPage3`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable custom page 3`,
  featureType: sectionToggle,
  humanName: `Custom Page 3`,
  id: 65,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type CustomPage3TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage3`>
}>;
export const customPage3TaggedC: CustomPage3TaggedC = t.type({
  _tag: t.literal(`CustomPage3`)
});
export type CustomPage3Tagged = t.TypeOf<CustomPage3TaggedC>;
export type CustomPage3 = CustomPage3Tagged & typeof customPage3;
export type CustomPage3C = t.Type<CustomPage3, CustomPage3Tagged>;
export const customPage3C: CustomPage3C = pipe(customPage3TaggedC, c => new t.Type<CustomPage3, CustomPage3Tagged>(
  `CustomPage3`,
  (u: unknown): u is CustomPage3 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage3> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage3 }))),
  (x: CustomPage3): CustomPage3Tagged => ({ ...x, _tag: `CustomPage3`}),
)) satisfies t.Type<CustomPage3, unknown>;


export const customPage4 = {
  _tag: `CustomPage4`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable custom page 4`,
  featureType: sectionToggle,
  humanName: `Custom Page 4`,
  id: 66,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type CustomPage4TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage4`>
}>;
export const customPage4TaggedC: CustomPage4TaggedC = t.type({
  _tag: t.literal(`CustomPage4`)
});
export type CustomPage4Tagged = t.TypeOf<CustomPage4TaggedC>;
export type CustomPage4 = CustomPage4Tagged & typeof customPage4;
export type CustomPage4C = t.Type<CustomPage4, CustomPage4Tagged>;
export const customPage4C: CustomPage4C = pipe(customPage4TaggedC, c => new t.Type<CustomPage4, CustomPage4Tagged>(
  `CustomPage4`,
  (u: unknown): u is CustomPage4 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage4> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage4 }))),
  (x: CustomPage4): CustomPage4Tagged => ({ ...x, _tag: `CustomPage4`}),
)) satisfies t.Type<CustomPage4, unknown>;


export const customPage5 = {
  _tag: `CustomPage5`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable custom page 5`,
  featureType: sectionToggle,
  humanName: `Custom Page 5`,
  id: 67,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type CustomPage5TaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomPage5`>
}>;
export const customPage5TaggedC: CustomPage5TaggedC = t.type({
  _tag: t.literal(`CustomPage5`)
});
export type CustomPage5Tagged = t.TypeOf<CustomPage5TaggedC>;
export type CustomPage5 = CustomPage5Tagged & typeof customPage5;
export type CustomPage5C = t.Type<CustomPage5, CustomPage5Tagged>;
export const customPage5C: CustomPage5C = pipe(customPage5TaggedC, c => new t.Type<CustomPage5, CustomPage5Tagged>(
  `CustomPage5`,
  (u: unknown): u is CustomPage5 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage5> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage5 }))),
  (x: CustomPage5): CustomPage5Tagged => ({ ...x, _tag: `CustomPage5`}),
)) satisfies t.Type<CustomPage5, unknown>;


export const roadshowLoginRequired = {
  _tag: `RoadshowLoginRequired`,
  default: true,
  dependsOn: O.some(roadShows),
  description: `Require visitors to log in before viewing roadshows`,
  featureType: investorRequirement,
  humanName: `Roadshow Login Required`,
  id: 68,
  notes: O.some(`You must have "Show roadshows on site" turned on for this setting to take effect.`),
  roles: issuerUserRoles
} as const;

export type RoadshowLoginRequiredTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RoadshowLoginRequired`>
}>;
export const roadshowLoginRequiredTaggedC: RoadshowLoginRequiredTaggedC = t.type({
  _tag: t.literal(`RoadshowLoginRequired`)
});
export type RoadshowLoginRequiredTagged = t.TypeOf<RoadshowLoginRequiredTaggedC>;
export type RoadshowLoginRequired = RoadshowLoginRequiredTagged & typeof roadshowLoginRequired;
export type RoadshowLoginRequiredC = t.Type<RoadshowLoginRequired, RoadshowLoginRequiredTagged>;
export const roadshowLoginRequiredC: RoadshowLoginRequiredC = pipe(roadshowLoginRequiredTaggedC, c => new t.Type<RoadshowLoginRequired, RoadshowLoginRequiredTagged>(
  `RoadshowLoginRequired`,
  (u: unknown): u is RoadshowLoginRequired => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RoadshowLoginRequired> => pipe(c.decode(u), E.map(x => ({ ...x, ...roadshowLoginRequired }))),
  (x: RoadshowLoginRequired): RoadshowLoginRequiredTagged => ({ ...x, _tag: `RoadshowLoginRequired`}),
)) satisfies t.Type<RoadshowLoginRequired, unknown>;


export const disclaimerSessionLengths = {
  _tag: `DisclaimerSessionLengths`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable custom disclaimer session lengths`,
  featureType: disclaimer,
  humanName: `Disclaimer Session Lengths`,
  id: 69,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export type DisclaimerSessionLengthsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DisclaimerSessionLengths`>
}>;
export const disclaimerSessionLengthsTaggedC: DisclaimerSessionLengthsTaggedC = t.type({
  _tag: t.literal(`DisclaimerSessionLengths`)
});
export type DisclaimerSessionLengthsTagged = t.TypeOf<DisclaimerSessionLengthsTaggedC>;
export type DisclaimerSessionLengths = DisclaimerSessionLengthsTagged & typeof disclaimerSessionLengths;
export type DisclaimerSessionLengthsC = t.Type<DisclaimerSessionLengths, DisclaimerSessionLengthsTagged>;
export const disclaimerSessionLengthsC: DisclaimerSessionLengthsC = pipe(disclaimerSessionLengthsTaggedC, c => new t.Type<DisclaimerSessionLengths, DisclaimerSessionLengthsTagged>(
  `DisclaimerSessionLengths`,
  (u: unknown): u is DisclaimerSessionLengths => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DisclaimerSessionLengths> => pipe(c.decode(u), E.map(x => ({ ...x, ...disclaimerSessionLengths }))),
  (x: DisclaimerSessionLengths): DisclaimerSessionLengthsTagged => ({ ...x, _tag: `DisclaimerSessionLengths`}),
)) satisfies t.Type<DisclaimerSessionLengths, unknown>;


export const privateDocuments = {
  _tag: `PrivateDocuments`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable private document management in the issuer portal`,
  featureType: sectionToggle,
  humanName: `Private Documents`,
  id: 71,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export type PrivateDocumentsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PrivateDocuments`>
}>;
export const privateDocumentsTaggedC: PrivateDocumentsTaggedC = t.type({
  _tag: t.literal(`PrivateDocuments`)
});
export type PrivateDocumentsTagged = t.TypeOf<PrivateDocumentsTaggedC>;
export type PrivateDocuments = PrivateDocumentsTagged & typeof privateDocuments;
export type PrivateDocumentsC = t.Type<PrivateDocuments, PrivateDocumentsTagged>;
export const privateDocumentsC: PrivateDocumentsC = pipe(privateDocumentsTaggedC, c => new t.Type<PrivateDocuments, PrivateDocumentsTagged>(
  `PrivateDocuments`,
  (u: unknown): u is PrivateDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PrivateDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...privateDocuments }))),
  (x: PrivateDocuments): PrivateDocumentsTagged => ({ ...x, _tag: `PrivateDocuments`}),
)) satisfies t.Type<PrivateDocuments, unknown>;


export const emmaIntegrated = {
  _tag: `EmmaIntegrated`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable the ability to publish documents to MSRB EMMA® when uploading to BondLink`,
  featureType: internalFlag,
  humanName: `Emma Integrated`,
  id: 72,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export type EmmaIntegratedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EmmaIntegrated`>
}>;
export const emmaIntegratedTaggedC: EmmaIntegratedTaggedC = t.type({
  _tag: t.literal(`EmmaIntegrated`)
});
export type EmmaIntegratedTagged = t.TypeOf<EmmaIntegratedTaggedC>;
export type EmmaIntegrated = EmmaIntegratedTagged & typeof emmaIntegrated;
export type EmmaIntegratedC = t.Type<EmmaIntegrated, EmmaIntegratedTagged>;
export const emmaIntegratedC: EmmaIntegratedC = pipe(emmaIntegratedTaggedC, c => new t.Type<EmmaIntegrated, EmmaIntegratedTagged>(
  `EmmaIntegrated`,
  (u: unknown): u is EmmaIntegrated => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmmaIntegrated> => pipe(c.decode(u), E.map(x => ({ ...x, ...emmaIntegrated }))),
  (x: EmmaIntegrated): EmmaIntegratedTagged => ({ ...x, _tag: `EmmaIntegrated`}),
)) satisfies t.Type<EmmaIntegrated, unknown>;


export const showArchivedBonds = {
  _tag: `ShowArchivedBonds`,
  default: false,
  dependsOn: O.none,
  description: `Show bond archive on site`,
  featureType: sectionToggle,
  humanName: `Show Archived Bonds`,
  id: 77,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type ShowArchivedBondsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ShowArchivedBonds`>
}>;
export const showArchivedBondsTaggedC: ShowArchivedBondsTaggedC = t.type({
  _tag: t.literal(`ShowArchivedBonds`)
});
export type ShowArchivedBondsTagged = t.TypeOf<ShowArchivedBondsTaggedC>;
export type ShowArchivedBonds = ShowArchivedBondsTagged & typeof showArchivedBonds;
export type ShowArchivedBondsC = t.Type<ShowArchivedBonds, ShowArchivedBondsTagged>;
export const showArchivedBondsC: ShowArchivedBondsC = pipe(showArchivedBondsTaggedC, c => new t.Type<ShowArchivedBonds, ShowArchivedBondsTagged>(
  `ShowArchivedBonds`,
  (u: unknown): u is ShowArchivedBonds => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ShowArchivedBonds> => pipe(c.decode(u), E.map(x => ({ ...x, ...showArchivedBonds }))),
  (x: ShowArchivedBonds): ShowArchivedBondsTagged => ({ ...x, _tag: `ShowArchivedBonds`}),
)) satisfies t.Type<ShowArchivedBonds, unknown>;


export const showIRMALetter = {
  _tag: `ShowIRMALetter`,
  default: false,
  dependsOn: O.none,
  description: `Show IRMA Letter on site`,
  featureType: sectionToggle,
  humanName: `Show IRMA Letter`,
  id: 78,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type ShowIRMALetterTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ShowIRMALetter`>
}>;
export const showIRMALetterTaggedC: ShowIRMALetterTaggedC = t.type({
  _tag: t.literal(`ShowIRMALetter`)
});
export type ShowIRMALetterTagged = t.TypeOf<ShowIRMALetterTaggedC>;
export type ShowIRMALetter = ShowIRMALetterTagged & typeof showIRMALetter;
export type ShowIRMALetterC = t.Type<ShowIRMALetter, ShowIRMALetterTagged>;
export const showIRMALetterC: ShowIRMALetterC = pipe(showIRMALetterTaggedC, c => new t.Type<ShowIRMALetter, ShowIRMALetterTagged>(
  `ShowIRMALetter`,
  (u: unknown): u is ShowIRMALetter => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ShowIRMALetter> => pipe(c.decode(u), E.map(x => ({ ...x, ...showIRMALetter }))),
  (x: ShowIRMALetter): ShowIRMALetterTagged => ({ ...x, _tag: `ShowIRMALetter`}),
)) satisfies t.Type<ShowIRMALetter, unknown>;


export const homepageFeaturedItems = {
  _tag: `HomepageFeaturedItems`,
  default: false,
  dependsOn: O.none,
  description: `Show "Featured Items" section`,
  featureType: sectionToggle,
  humanName: `Homepage Featured Items`,
  id: 79,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export type HomepageFeaturedItemsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`HomepageFeaturedItems`>
}>;
export const homepageFeaturedItemsTaggedC: HomepageFeaturedItemsTaggedC = t.type({
  _tag: t.literal(`HomepageFeaturedItems`)
});
export type HomepageFeaturedItemsTagged = t.TypeOf<HomepageFeaturedItemsTaggedC>;
export type HomepageFeaturedItems = HomepageFeaturedItemsTagged & typeof homepageFeaturedItems;
export type HomepageFeaturedItemsC = t.Type<HomepageFeaturedItems, HomepageFeaturedItemsTagged>;
export const homepageFeaturedItemsC: HomepageFeaturedItemsC = pipe(homepageFeaturedItemsTaggedC, c => new t.Type<HomepageFeaturedItems, HomepageFeaturedItemsTagged>(
  `HomepageFeaturedItems`,
  (u: unknown): u is HomepageFeaturedItems => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HomepageFeaturedItems> => pipe(c.decode(u), E.map(x => ({ ...x, ...homepageFeaturedItems }))),
  (x: HomepageFeaturedItems): HomepageFeaturedItemsTagged => ({ ...x, _tag: `HomepageFeaturedItems`}),
)) satisfies t.Type<HomepageFeaturedItems, unknown>;


export const debtProfile = {
  _tag: `DebtProfile`,
  default: false,
  dependsOn: O.none,
  description: `Whether to enable or disable the debt data section`,
  featureType: sectionToggle,
  humanName: `Debt Profile`,
  id: 80,
  notes: O.none,
  roles: bondLinkAdminRoles
} as const;

export type DebtProfileTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DebtProfile`>
}>;
export const debtProfileTaggedC: DebtProfileTaggedC = t.type({
  _tag: t.literal(`DebtProfile`)
});
export type DebtProfileTagged = t.TypeOf<DebtProfileTaggedC>;
export type DebtProfile = DebtProfileTagged & typeof debtProfile;
export type DebtProfileC = t.Type<DebtProfile, DebtProfileTagged>;
export const debtProfileC: DebtProfileC = pipe(debtProfileTaggedC, c => new t.Type<DebtProfile, DebtProfileTagged>(
  `DebtProfile`,
  (u: unknown): u is DebtProfile => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DebtProfile> => pipe(c.decode(u), E.map(x => ({ ...x, ...debtProfile }))),
  (x: DebtProfile): DebtProfileTagged => ({ ...x, _tag: `DebtProfile`}),
)) satisfies t.Type<DebtProfile, unknown>;


export const esgProgram = {
  _tag: `EsgProgram`,
  default: false,
  dependsOn: O.none,
  description: `Show ESG program on site`,
  featureType: sectionToggle,
  humanName: `Esg Program`,
  id: 81,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type EsgProgramTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EsgProgram`>
}>;
export const esgProgramTaggedC: EsgProgramTaggedC = t.type({
  _tag: t.literal(`EsgProgram`)
});
export type EsgProgramTagged = t.TypeOf<EsgProgramTaggedC>;
export type EsgProgram = EsgProgramTagged & typeof esgProgram;
export type EsgProgramC = t.Type<EsgProgram, EsgProgramTagged>;
export const esgProgramC: EsgProgramC = pipe(esgProgramTaggedC, c => new t.Type<EsgProgram, EsgProgramTagged>(
  `EsgProgram`,
  (u: unknown): u is EsgProgram => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EsgProgram> => pipe(c.decode(u), E.map(x => ({ ...x, ...esgProgram }))),
  (x: EsgProgram): EsgProgramTagged => ({ ...x, _tag: `EsgProgram`}),
)) satisfies t.Type<EsgProgram, unknown>;


export const linksPage = {
  _tag: `LinksPage`,
  default: false,
  dependsOn: O.none,
  description: `Show links page on site`,
  featureType: sectionToggle,
  humanName: `Links Page`,
  id: 82,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type LinksPageTaggedC = t.TypeC<{
  _tag: t.LiteralC<`LinksPage`>
}>;
export const linksPageTaggedC: LinksPageTaggedC = t.type({
  _tag: t.literal(`LinksPage`)
});
export type LinksPageTagged = t.TypeOf<LinksPageTaggedC>;
export type LinksPage = LinksPageTagged & typeof linksPage;
export type LinksPageC = t.Type<LinksPage, LinksPageTagged>;
export const linksPageC: LinksPageC = pipe(linksPageTaggedC, c => new t.Type<LinksPage, LinksPageTagged>(
  `LinksPage`,
  (u: unknown): u is LinksPage => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, LinksPage> => pipe(c.decode(u), E.map(x => ({ ...x, ...linksPage }))),
  (x: LinksPage): LinksPageTagged => ({ ...x, _tag: `LinksPage`}),
)) satisfies t.Type<LinksPage, unknown>;


export const activeRfps = {
  _tag: `ActiveRfps`,
  default: false,
  dependsOn: O.none,
  description: `Show active RFPs on site`,
  featureType: sectionToggle,
  humanName: `Active Rfps`,
  id: 83,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type ActiveRfpsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ActiveRfps`>
}>;
export const activeRfpsTaggedC: ActiveRfpsTaggedC = t.type({
  _tag: t.literal(`ActiveRfps`)
});
export type ActiveRfpsTagged = t.TypeOf<ActiveRfpsTaggedC>;
export type ActiveRfps = ActiveRfpsTagged & typeof activeRfps;
export type ActiveRfpsC = t.Type<ActiveRfps, ActiveRfpsTagged>;
export const activeRfpsC: ActiveRfpsC = pipe(activeRfpsTaggedC, c => new t.Type<ActiveRfps, ActiveRfpsTagged>(
  `ActiveRfps`,
  (u: unknown): u is ActiveRfps => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ActiveRfps> => pipe(c.decode(u), E.map(x => ({ ...x, ...activeRfps }))),
  (x: ActiveRfps): ActiveRfpsTagged => ({ ...x, _tag: `ActiveRfps`}),
)) satisfies t.Type<ActiveRfps, unknown>;


export const archivedRfps = {
  _tag: `ArchivedRfps`,
  default: false,
  dependsOn: O.none,
  description: `Show archived RFPs on site`,
  featureType: sectionToggle,
  humanName: `Archived Rfps`,
  id: 84,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type ArchivedRfpsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ArchivedRfps`>
}>;
export const archivedRfpsTaggedC: ArchivedRfpsTaggedC = t.type({
  _tag: t.literal(`ArchivedRfps`)
});
export type ArchivedRfpsTagged = t.TypeOf<ArchivedRfpsTaggedC>;
export type ArchivedRfps = ArchivedRfpsTagged & typeof archivedRfps;
export type ArchivedRfpsC = t.Type<ArchivedRfps, ArchivedRfpsTagged>;
export const archivedRfpsC: ArchivedRfpsC = pipe(archivedRfpsTaggedC, c => new t.Type<ArchivedRfps, ArchivedRfpsTagged>(
  `ArchivedRfps`,
  (u: unknown): u is ArchivedRfps => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ArchivedRfps> => pipe(c.decode(u), E.map(x => ({ ...x, ...archivedRfps }))),
  (x: ArchivedRfps): ArchivedRfpsTagged => ({ ...x, _tag: `ArchivedRfps`}),
)) satisfies t.Type<ArchivedRfps, unknown>;


export const displayCalendarRfps = {
  _tag: `DisplayCalendarRfps`,
  default: false,
  dependsOn: O.some(financeCalendar),
  description: `Include upcoming RFPs on events list`,
  featureType: visual,
  humanName: `Display Calendar Rfps`,
  id: 86,
  notes: O.some(`You must have "Show events on site" turned on for this setting to take effect.`),
  roles: issuerUserRoles
} as const;

export type DisplayCalendarRfpsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`DisplayCalendarRfps`>
}>;
export const displayCalendarRfpsTaggedC: DisplayCalendarRfpsTaggedC = t.type({
  _tag: t.literal(`DisplayCalendarRfps`)
});
export type DisplayCalendarRfpsTagged = t.TypeOf<DisplayCalendarRfpsTaggedC>;
export type DisplayCalendarRfps = DisplayCalendarRfpsTagged & typeof displayCalendarRfps;
export type DisplayCalendarRfpsC = t.Type<DisplayCalendarRfps, DisplayCalendarRfpsTagged>;
export const displayCalendarRfpsC: DisplayCalendarRfpsC = pipe(displayCalendarRfpsTaggedC, c => new t.Type<DisplayCalendarRfps, DisplayCalendarRfpsTagged>(
  `DisplayCalendarRfps`,
  (u: unknown): u is DisplayCalendarRfps => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DisplayCalendarRfps> => pipe(c.decode(u), E.map(x => ({ ...x, ...displayCalendarRfps }))),
  (x: DisplayCalendarRfps): DisplayCalendarRfpsTagged => ({ ...x, _tag: `DisplayCalendarRfps`}),
)) satisfies t.Type<DisplayCalendarRfps, unknown>;


export const bondPrograms = {
  _tag: `BondPrograms`,
  default: false,
  dependsOn: O.none,
  description: `Show bond programs on site`,
  featureType: sectionToggle,
  humanName: `Bond Programs`,
  id: 87,
  notes: O.none,
  roles: issuerUserRoles
} as const;

export type BondProgramsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondPrograms`>
}>;
export const bondProgramsTaggedC: BondProgramsTaggedC = t.type({
  _tag: t.literal(`BondPrograms`)
});
export type BondProgramsTagged = t.TypeOf<BondProgramsTaggedC>;
export type BondPrograms = BondProgramsTagged & typeof bondPrograms;
export type BondProgramsC = t.Type<BondPrograms, BondProgramsTagged>;
export const bondProgramsC: BondProgramsC = pipe(bondProgramsTaggedC, c => new t.Type<BondPrograms, BondProgramsTagged>(
  `BondPrograms`,
  (u: unknown): u is BondPrograms => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondPrograms> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondPrograms }))),
  (x: BondPrograms): BondProgramsTagged => ({ ...x, _tag: `BondPrograms`}),
)) satisfies t.Type<BondPrograms, unknown>;


export const allFeatureC = [bankCalendarC, dealViewC, copyBankAsIssuerParticipantC, bondOfferingsC, bondOfferingsGeneralInfoC, bondOfferingsDisclaimerC, reportsRequireLoginC, siteDisclaimerC, siteDisclaimerModalC, offeringsDisclaimerModalC, relatedIssuersC, projectsC, emmaC, draftModeC, exitDisclaimerC, newsC, faqC, bondRatingsC, showArchivedDocsC, financeTeamC, financeCalendarC, menuItemsC, showDocumentDisclaimerC, documentDisclaimerModalC, customPage1C, customPage2C, cusip6C, contactC, invoiceEmailsC, documentsC, roadShowsC, displayCalendarOfferingsC, offeringParticipantsC, customPage3C, customPage4C, customPage5C, roadshowLoginRequiredC, disclaimerSessionLengthsC, privateDocumentsC, emmaIntegratedC, showArchivedBondsC, showIRMALetterC, homepageFeaturedItemsC, debtProfileC, esgProgramC, linksPageC, activeRfpsC, archivedRfpsC, displayCalendarRfpsC, bondProgramsC] as const;
export const allFeatureNames = [`BankCalendar`, `DealView`, `CopyBankAsIssuerParticipant`, `BondOfferings`, `BondOfferingsGeneralInfo`, `BondOfferingsDisclaimer`, `ReportsRequireLogin`, `SiteDisclaimer`, `SiteDisclaimerModal`, `OfferingsDisclaimerModal`, `RelatedIssuers`, `Projects`, `Emma`, `DraftMode`, `ExitDisclaimer`, `News`, `Faq`, `BondRatings`, `ShowArchivedDocs`, `FinanceTeam`, `FinanceCalendar`, `MenuItems`, `ShowDocumentDisclaimer`, `DocumentDisclaimerModal`, `CustomPage1`, `CustomPage2`, `Cusip6`, `Contact`, `InvoiceEmails`, `Documents`, `RoadShows`, `DisplayCalendarOfferings`, `OfferingParticipants`, `CustomPage3`, `CustomPage4`, `CustomPage5`, `RoadshowLoginRequired`, `DisclaimerSessionLengths`, `PrivateDocuments`, `EmmaIntegrated`, `ShowArchivedBonds`, `ShowIRMALetter`, `HomepageFeaturedItems`, `DebtProfile`, `EsgProgram`, `LinksPage`, `ActiveRfps`, `ArchivedRfps`, `DisplayCalendarRfps`, `BondPrograms`] as const;
export type FeatureName = (typeof allFeatureNames)[number];

export type FeatureCU = t.UnionC<[BankCalendarC, DealViewC, CopyBankAsIssuerParticipantC, BondOfferingsC, BondOfferingsGeneralInfoC, BondOfferingsDisclaimerC, ReportsRequireLoginC, SiteDisclaimerC, SiteDisclaimerModalC, OfferingsDisclaimerModalC, RelatedIssuersC, ProjectsC, EmmaC, DraftModeC, ExitDisclaimerC, NewsC, FaqC, BondRatingsC, ShowArchivedDocsC, FinanceTeamC, FinanceCalendarC, MenuItemsC, ShowDocumentDisclaimerC, DocumentDisclaimerModalC, CustomPage1C, CustomPage2C, Cusip6C, ContactC, InvoiceEmailsC, DocumentsC, RoadShowsC, DisplayCalendarOfferingsC, OfferingParticipantsC, CustomPage3C, CustomPage4C, CustomPage5C, RoadshowLoginRequiredC, DisclaimerSessionLengthsC, PrivateDocumentsC, EmmaIntegratedC, ShowArchivedBondsC, ShowIRMALetterC, HomepageFeaturedItemsC, DebtProfileC, EsgProgramC, LinksPageC, ActiveRfpsC, ArchivedRfpsC, DisplayCalendarRfpsC, BondProgramsC]>;
export type FeatureU = BankCalendar | DealView | CopyBankAsIssuerParticipant | BondOfferings | BondOfferingsGeneralInfo | BondOfferingsDisclaimer | ReportsRequireLogin | SiteDisclaimer | SiteDisclaimerModal | OfferingsDisclaimerModal | RelatedIssuers | Projects | Emma | DraftMode | ExitDisclaimer | News | Faq | BondRatings | ShowArchivedDocs | FinanceTeam | FinanceCalendar | MenuItems | ShowDocumentDisclaimer | DocumentDisclaimerModal | CustomPage1 | CustomPage2 | Cusip6 | Contact | InvoiceEmails | Documents | RoadShows | DisplayCalendarOfferings | OfferingParticipants | CustomPage3 | CustomPage4 | CustomPage5 | RoadshowLoginRequired | DisclaimerSessionLengths | PrivateDocuments | EmmaIntegrated | ShowArchivedBonds | ShowIRMALetter | HomepageFeaturedItems | DebtProfile | EsgProgram | LinksPage | ActiveRfps | ArchivedRfps | DisplayCalendarRfps | BondPrograms;
export const FeatureCU: FeatureCU = t.union([bankCalendarC, dealViewC, copyBankAsIssuerParticipantC, bondOfferingsC, bondOfferingsGeneralInfoC, bondOfferingsDisclaimerC, reportsRequireLoginC, siteDisclaimerC, siteDisclaimerModalC, offeringsDisclaimerModalC, relatedIssuersC, projectsC, emmaC, draftModeC, exitDisclaimerC, newsC, faqC, bondRatingsC, showArchivedDocsC, financeTeamC, financeCalendarC, menuItemsC, showDocumentDisclaimerC, documentDisclaimerModalC, customPage1C, customPage2C, cusip6C, contactC, invoiceEmailsC, documentsC, roadShowsC, displayCalendarOfferingsC, offeringParticipantsC, customPage3C, customPage4C, customPage5C, roadshowLoginRequiredC, disclaimerSessionLengthsC, privateDocumentsC, emmaIntegratedC, showArchivedBondsC, showIRMALetterC, homepageFeaturedItemsC, debtProfileC, esgProgramC, linksPageC, activeRfpsC, archivedRfpsC, displayCalendarRfpsC, bondProgramsC]) satisfies t.Type<FeatureU, unknown>;

export const featureOrd: Ord.Ord<FeatureU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allFeature = [bankCalendar, dealView, copyBankAsIssuerParticipant, bondOfferings, bondOfferingsGeneralInfo, bondOfferingsDisclaimer, reportsRequireLogin, siteDisclaimer, siteDisclaimerModal, offeringsDisclaimerModal, relatedIssuers, projects, emma, draftMode, exitDisclaimer, news, faq, bondRatings, showArchivedDocs, financeTeam, financeCalendar, menuItems, showDocumentDisclaimer, documentDisclaimerModal, customPage1, customPage2, cusip6, contact, invoiceEmails, documents, roadShows, displayCalendarOfferings, offeringParticipants, customPage3, customPage4, customPage5, roadshowLoginRequired, disclaimerSessionLengths, privateDocuments, emmaIntegrated, showArchivedBonds, showIRMALetter, homepageFeaturedItems, debtProfile, esgProgram, linksPage, activeRfps, archivedRfps, displayCalendarRfps, bondPrograms] as const;
export type FeatureMap<A> = { [K in FeatureName]: A };


