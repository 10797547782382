import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const obligor = {
  _tag: `Obligor`,
  id: 1,
  relationship: `Obligor`
} as const;

export type ObligorTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Obligor`>
}>;
export const obligorTaggedC: ObligorTaggedC = t.type({
  _tag: t.literal(`Obligor`)
});
export type ObligorTagged = t.TypeOf<ObligorTaggedC>;
export type Obligor = ObligorTagged & typeof obligor;
export type ObligorC = t.Type<Obligor, ObligorTagged>;
export const obligorC: ObligorC = pipe(obligorTaggedC, c => new t.Type<Obligor, ObligorTagged>(
  `Obligor`,
  (u: unknown): u is Obligor => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Obligor> => pipe(c.decode(u), E.map(x => ({ ...x, ...obligor }))),
  (x: Obligor): ObligorTagged => ({ ...x, _tag: `Obligor`}),
)) satisfies t.Type<Obligor, unknown>;


export const financialAdvisor = {
  _tag: `FinancialAdvisor`,
  id: 2,
  relationship: `Financial Advisor`
} as const;

export type FinancialAdvisorTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FinancialAdvisor`>
}>;
export const financialAdvisorTaggedC: FinancialAdvisorTaggedC = t.type({
  _tag: t.literal(`FinancialAdvisor`)
});
export type FinancialAdvisorTagged = t.TypeOf<FinancialAdvisorTaggedC>;
export type FinancialAdvisor = FinancialAdvisorTagged & typeof financialAdvisor;
export type FinancialAdvisorC = t.Type<FinancialAdvisor, FinancialAdvisorTagged>;
export const financialAdvisorC: FinancialAdvisorC = pipe(financialAdvisorTaggedC, c => new t.Type<FinancialAdvisor, FinancialAdvisorTagged>(
  `FinancialAdvisor`,
  (u: unknown): u is FinancialAdvisor => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FinancialAdvisor> => pipe(c.decode(u), E.map(x => ({ ...x, ...financialAdvisor }))),
  (x: FinancialAdvisor): FinancialAdvisorTagged => ({ ...x, _tag: `FinancialAdvisor`}),
)) satisfies t.Type<FinancialAdvisor, unknown>;


export const issuer = {
  _tag: `Issuer`,
  id: 3,
  relationship: `Issuer`
} as const;

export type IssuerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Issuer`>
}>;
export const issuerTaggedC: IssuerTaggedC = t.type({
  _tag: t.literal(`Issuer`)
});
export type IssuerTagged = t.TypeOf<IssuerTaggedC>;
export type Issuer = IssuerTagged & typeof issuer;
export type IssuerC = t.Type<Issuer, IssuerTagged>;
export const issuerC: IssuerC = pipe(issuerTaggedC, c => new t.Type<Issuer, IssuerTagged>(
  `Issuer`,
  (u: unknown): u is Issuer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Issuer> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuer }))),
  (x: Issuer): IssuerTagged => ({ ...x, _tag: `Issuer`}),
)) satisfies t.Type<Issuer, unknown>;


export const allIssuerRelationshipC = [obligorC, financialAdvisorC, issuerC] as const;
export const allIssuerRelationshipNames = [`Obligor`, `FinancialAdvisor`, `Issuer`] as const;
export type IssuerRelationshipName = (typeof allIssuerRelationshipNames)[number];

export type IssuerRelationshipCU = t.UnionC<[ObligorC, FinancialAdvisorC, IssuerC]>;
export type IssuerRelationshipU = Obligor | FinancialAdvisor | Issuer;
export const IssuerRelationshipCU: IssuerRelationshipCU = t.union([obligorC, financialAdvisorC, issuerC]) satisfies t.Type<IssuerRelationshipU, unknown>;

export const issuerRelationshipOrd: Ord.Ord<IssuerRelationshipU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allIssuerRelationship = [obligor, financialAdvisor, issuer] as const;
export type IssuerRelationshipMap<A> = { [K in IssuerRelationshipName]: A };


