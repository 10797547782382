import { sequenceS } from "fp-ts/lib/Apply";

import { O, pipe, RNEA } from "@scripts/fp-ts";
import { type IssuerRatings, issuerRatings, type PageU } from "@scripts/generated/domaintables/pages";
import type { DirectOfferingData } from "@scripts/generated/models/bondOffering";
import type { BondProgramWithRatings } from "@scripts/generated/models/bondProgramWithRatings";
import type { Issuer } from "@scripts/generated/models/issuer";
import { type PageConfig } from "@scripts/generated/models/pageConfig";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import { Empty, mapOrEmpty } from "@scripts/react/components/Empty";
import { klass } from "@scripts/react/util/classnames";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { getPage } from "@scripts/syntax/pageConfig";
import type { CommonRatingsByAgency } from "@scripts/syntax/rating";

import { AccentDividerSection } from "../layout/Section";
import { defaultRatingBlockBreakpoint, type RatingBlockProps, RatingBlockSection } from "../ratings/RatingBlock";

type IssuerRatingsPage = PageConfig<IssuerRatings>;

export type IssuerRatingsPageO = O.Option<IssuerRatingsPage>;

export const findIssuerRatingsPage: (pages: ReadonlyArray<PageConfig<PageU>>) => IssuerRatingsPageO = getPage(issuerRatings);

export const issuerRatingsDefaultTitle = "Issuer Ratings";

export const issuerRatingPageToTitle = (issuerRatingsPageO: IssuerRatingsPageO) => pipe(
  issuerRatingsPageO,
  O.chain(_ => _.title),
  O.getOrElse(() => issuerRatingsDefaultTitle)
);

export const RatingsBlockSection = (props: {
  issuerRatingsPageO: IssuerRatingsPageO;
  issuerRatings: DirectOfferingData["issuerRatings"];
  bondRatings: O.Option<CommonRatingsByAgency>;
  program: O.Option<WithStatusU<BondProgramWithRatings>>;
  isBLP: boolean;
  issuer: Issuer;
  pages: ReadonlyArray<PageConfig<PageU>>;
  sectionId?: string;
} & RatingBlockProps) => {
  const programWithRatings = sequenceS(O.Apply)({
    program: props.program,
    ratings: pipe(props.program, O.chain(_ => RNEA.fromReadonlyArray(_.data.record.ratings))),
  });
  const issuerRatingsO = RNEA.fromReadonlyArray(props.issuerRatings);
  const issuerRatingTitle = issuerRatingPageToTitle(props.issuerRatingsPageO);

  return O.isNone(props.bondRatings) && O.isNone(programWithRatings) && O.isNone(issuerRatingsO) ? <Empty />
    : <AccentDividerSection title={O.some(jl.bonds.ratings.text(props.pages))} sectionId={props.sectionId}>
      {pipe(props.bondRatings, mapOrEmpty(r =>
        <RatingBlockSection
          documentDownloadRoute={props.documentDownloadRoute}
          ratings={r}
          name={O.some("Bond Offering Ratings")}
          modalName="Bond Offering Ratings"
          issuer={props.issuer}
          breakpoint={props.isBLP ? "xl" : defaultRatingBlockBreakpoint}
        />
      ))}
      {pipe(programWithRatings, mapOrEmpty(p =>
        <div {...klass("mt-2")}>
          <RatingBlockSection
            documentDownloadRoute={props.documentDownloadRoute}
            ratings={p.ratings}
            name={O.some(`Program Ratings: ${p.program.data.record.program.name}`)}
            modalName={p.program.data.record.program.name}
            issuer={props.issuer}
            breakpoint={props.isBLP ? "xl" : defaultRatingBlockBreakpoint}
          />
        </div>
      ))}
      {pipe(issuerRatingsO, mapOrEmpty(r =>
        <div {...klass("mt-2")}>
          <RatingBlockSection
            documentDownloadRoute={props.documentDownloadRoute}
            ratings={r}
            name={O.some(issuerRatingTitle)}
            modalName={issuerRatingTitle}
            issuer={props.issuer}
            breakpoint={props.isBLP ? "xl" : defaultRatingBlockBreakpoint}
          />
        </div>
      ))}
    </AccentDividerSection>;
};
