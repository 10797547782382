import { WithStatusCU as imported619_WithStatusCU, WithStatusCU as imported617_WithStatusCU, WithStatusU as imported309_WithStatusU, WithStatusCU as imported614_WithStatusCU, WithStatusCU as imported618_WithStatusCU, WithStatusCU as imported616_WithStatusCU, WithStatusU as imported308_WithStatusU, WithStatusCU as imported615_WithStatusCU, WithStatusU as imported307_WithStatusU } from "./threadThrough";
import * as t from "io-ts";
import { MediaUploadResponseC as imported21_MediaUploadResponseC, mediaUploadResponseC as imported21_mediaUploadResponseC, MediaC as imported61_MediaC, Media as imported61_Media, mediaC as imported57_mediaC, MediaUploadResponse as imported21_MediaUploadResponse } from "./media";
import * as O from "fp-ts/lib/Option";
import { DataAndIdC as imported8_DataAndIdC, DataAndId as imported7_DataAndId, dataAndIdC as imported7_dataAndIdC, DataAndIdC as imported7_DataAndIdC, DataAndId as imported8_DataAndId, dataAndIdC as imported8_dataAndIdC } from "./relatedContent";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { BidSubmissionTemplateDetailStateU as imported3_BidSubmissionTemplateDetailStateU, BidSubmissionTemplateDetailStateCU as imported6_BidSubmissionTemplateDetailStateCU, BidSubmissionTemplateDetailStateCU as imported9_BidSubmissionTemplateDetailStateCU, BidSubmissionTemplateDetailStateU as imported5_BidSubmissionTemplateDetailStateU, BidSubmissionTemplateDetailStateCU as imported11_BidSubmissionTemplateDetailStateCU, BidSubmissionTemplateDetailStateCU as imported10_BidSubmissionTemplateDetailStateCU, BidSubmissionTemplateDetailStateU as imported4_BidSubmissionTemplateDetailStateU, BidSubmissionTemplateDetailStateCU as imported7_BidSubmissionTemplateDetailStateCU, BidSubmissionTemplateDetailStateCU as imported8_BidSubmissionTemplateDetailStateCU } from "../domaintables/bidSubmissionTemplateDetailsState";

export type BidSubmissionTemplateC = t.TypeC<{
  interestRate: imported9_BidSubmissionTemplateDetailStateCU,
  fees: imported10_BidSubmissionTemplateDetailStateCU,
  additionalComments: imported11_BidSubmissionTemplateDetailStateCU,
  introductionText: OptionFromNullableC<t.StringC>
}>;
export type BidSubmissionTemplate = {
  interestRate: imported3_BidSubmissionTemplateDetailStateU,
  fees: imported4_BidSubmissionTemplateDetailStateU,
  additionalComments: imported5_BidSubmissionTemplateDetailStateU,
  introductionText: O.Option<string>
};
export const bidSubmissionTemplateC: BidSubmissionTemplateC = t.type({
  interestRate: imported6_BidSubmissionTemplateDetailStateCU,
  fees: imported7_BidSubmissionTemplateDetailStateCU,
  additionalComments: imported8_BidSubmissionTemplateDetailStateCU,
  introductionText: optionFromNullable(t.string)
}) satisfies t.Type<BidSubmissionTemplate, unknown>;


export type BidSubmissionTemplateQuestionC = t.TypeC<{
  order: t.NumberC,
  bidSubmissionTemplateId: t.NumberC,
  required: t.BooleanC,
  yesOrNo: t.BooleanC,
  questionText: t.StringC
}>;
export type BidSubmissionTemplateQuestion = {
  order: number,
  bidSubmissionTemplateId: number,
  required: boolean,
  yesOrNo: boolean,
  questionText: string
};
export const bidSubmissionTemplateQuestionC: BidSubmissionTemplateQuestionC = t.type({
  order: t.number,
  bidSubmissionTemplateId: t.number,
  required: t.boolean,
  yesOrNo: t.boolean,
  questionText: t.string
}) satisfies t.Type<BidSubmissionTemplateQuestion, unknown>;


export type BidSubmissionTemplateDocumentC = t.TypeC<{
  order: t.NumberC,
  bidSubmissionTemplateId: t.NumberC,
  documentTemplateId: OptionFromNullableC<t.NumberC>,
  name: t.StringC,
  required: t.BooleanC
}>;
export type BidSubmissionTemplateDocument = {
  order: number,
  bidSubmissionTemplateId: number,
  documentTemplateId: O.Option<number>,
  name: string,
  required: boolean
};
export const bidSubmissionTemplateDocumentC: BidSubmissionTemplateDocumentC = t.type({
  order: t.number,
  bidSubmissionTemplateId: t.number,
  documentTemplateId: optionFromNullable(t.number),
  name: t.string,
  required: t.boolean
}) satisfies t.Type<BidSubmissionTemplateDocument, unknown>;


export type BidSubmissionTemplateDocumentDataC = t.TypeC<{
  bidSubmissionTemplateDocument: BidSubmissionTemplateDocumentC,
  documentTemplate: OptionFromNullableC<imported619_WithStatusCU<imported61_MediaC>>
}>;
export type BidSubmissionTemplateDocumentData = {
  bidSubmissionTemplateDocument: BidSubmissionTemplateDocument,
  documentTemplate: O.Option<imported309_WithStatusU<imported61_Media>>
};
export const bidSubmissionTemplateDocumentDataC: BidSubmissionTemplateDocumentDataC = t.type({
  bidSubmissionTemplateDocument: bidSubmissionTemplateDocumentC,
  documentTemplate: optionFromNullable(imported618_WithStatusCU(imported57_mediaC))
}) satisfies t.Type<BidSubmissionTemplateDocumentData, unknown>;


export type BidSubmissionTemplateDataC = t.TypeC<{
  bidSubmissionTemplate: BidSubmissionTemplateC,
  questions: t.ReadonlyArrayC<imported616_WithStatusCU<BidSubmissionTemplateQuestionC>>,
  documents: t.ReadonlyArrayC<imported617_WithStatusCU<BidSubmissionTemplateDocumentDataC>>
}>;
export type BidSubmissionTemplateData = {
  bidSubmissionTemplate: BidSubmissionTemplate,
  questions: ReadonlyArray<imported307_WithStatusU<BidSubmissionTemplateQuestion>>,
  documents: ReadonlyArray<imported308_WithStatusU<BidSubmissionTemplateDocumentData>>
};
export const bidSubmissionTemplateDataC: BidSubmissionTemplateDataC = t.type({
  bidSubmissionTemplate: bidSubmissionTemplateC,
  questions: t.readonlyArray(imported614_WithStatusCU(bidSubmissionTemplateQuestionC)),
  documents: t.readonlyArray(imported615_WithStatusCU(bidSubmissionTemplateDocumentDataC))
}) satisfies t.Type<BidSubmissionTemplateData, unknown>;


export type BidSubmissionTemplateDocumentPostC = t.TypeC<{
  order: t.NumberC,
  documentTemplate: OptionFromNullableC<imported21_MediaUploadResponseC>,
  name: t.StringC,
  required: t.BooleanC
}>;
export type BidSubmissionTemplateDocumentPost = {
  order: number,
  documentTemplate: O.Option<imported21_MediaUploadResponse>,
  name: string,
  required: boolean
};
export const bidSubmissionTemplateDocumentPostC: BidSubmissionTemplateDocumentPostC = t.type({
  order: t.number,
  documentTemplate: optionFromNullable(imported21_mediaUploadResponseC),
  name: t.string,
  required: t.boolean
}) satisfies t.Type<BidSubmissionTemplateDocumentPost, unknown>;


export type BidSubmissionTemplateQuestionPostC = t.TypeC<{
  order: t.NumberC,
  required: t.BooleanC,
  yesOrNo: t.BooleanC,
  questionText: t.StringC
}>;
export type BidSubmissionTemplateQuestionPost = {
  order: number,
  required: boolean,
  yesOrNo: boolean,
  questionText: string
};
export const bidSubmissionTemplateQuestionPostC: BidSubmissionTemplateQuestionPostC = t.type({
  order: t.number,
  required: t.boolean,
  yesOrNo: t.boolean,
  questionText: t.string
}) satisfies t.Type<BidSubmissionTemplateQuestionPost, unknown>;


export type BidSubmissionTemplatePostRelatedContentC = t.TypeC<{
  documents: t.ReadonlyArrayC<imported7_DataAndIdC<BidSubmissionTemplateDocumentPostC>>,
  questions: t.ReadonlyArrayC<imported8_DataAndIdC<BidSubmissionTemplateQuestionPostC>>
}>;
export type BidSubmissionTemplatePostRelatedContent = {
  documents: ReadonlyArray<imported7_DataAndId<BidSubmissionTemplateDocumentPost>>,
  questions: ReadonlyArray<imported8_DataAndId<BidSubmissionTemplateQuestionPost>>
};
export const bidSubmissionTemplatePostRelatedContentC: BidSubmissionTemplatePostRelatedContentC = t.type({
  documents: t.readonlyArray(imported7_dataAndIdC(bidSubmissionTemplateDocumentPostC)),
  questions: t.readonlyArray(imported8_dataAndIdC(bidSubmissionTemplateQuestionPostC))
}) satisfies t.Type<BidSubmissionTemplatePostRelatedContent, unknown>;


export type BidSubmissionTemplatePostC = t.TypeC<{
  interestRate: t.NumberC,
  fees: t.NumberC,
  additionalComments: t.NumberC,
  introductionText: OptionFromNullableC<t.StringC>,
  relatedContent: BidSubmissionTemplatePostRelatedContentC
}>;
export type BidSubmissionTemplatePost = {
  interestRate: number,
  fees: number,
  additionalComments: number,
  introductionText: O.Option<string>,
  relatedContent: BidSubmissionTemplatePostRelatedContent
};
export const bidSubmissionTemplatePostC: BidSubmissionTemplatePostC = t.type({
  interestRate: t.number,
  fees: t.number,
  additionalComments: t.number,
  introductionText: optionFromNullable(t.string),
  relatedContent: bidSubmissionTemplatePostRelatedContentC
}) satisfies t.Type<BidSubmissionTemplatePost, unknown>;


