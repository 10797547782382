import type { BLConfigWithLog } from "@scripts/bondlink";
import type { O } from "@scripts/fp-ts";
import type { PartnerU } from "@scripts/generated/domaintables/partners";
import type { Issuer } from "@scripts/generated/models/issuer";
import { type AnchorProps, AnchorWithChildren, type ArrowType } from "@scripts/react/components/Anchor";
import { useConfig } from "@scripts/react/context/Config";
import type { UrlInterface } from "@scripts/routes/urlInterface";
import { getDomain } from "@scripts/syntax/issuer";
import { addQueryParams } from "@scripts/syntax/url";
import type { ChildStringOrAriaLabel } from "@scripts/types/ariaLabel";

export type PartnerLanderLinkProps = Omit<AnchorProps, "route" | "target" | "externalLinkLocation"> & ArrowType & ChildStringOrAriaLabel & {
  issuer: O.Option<Issuer>;
  partner: PartnerU;
  url: UrlInterface<"GET">;
};

export const partnerLanderLinkUrl = (
  config: BLConfigWithLog,
  issuer: O.Option<Issuer>,
  partner: PartnerU,
  url: UrlInterface<"GET">
): UrlInterface<"GET"> => ({
  ...url,
  url: addQueryParams(
    new URL(url.url.startsWith("/") ? `https://${getDomain(config)(issuer)}${url.url}` : url.url),
    new URLSearchParams([["utm_source", partner._tag.toLowerCase()], ["utm_medium", "affiliate"]]),
  ).toString(),
});

export const PartnerLanderLink = (props: PartnerLanderLinkProps) => {
  const config = useConfig();

  return <AnchorWithChildren
    {...props}
    target="_blank"
    externalLinkLocation="none"
    route={{ title: "", route: partnerLanderLinkUrl(config, props.issuer, props.partner, props.url) }}
  />;
};
