import { withIdC as imported288_withIdC, WithIdC as imported289_WithIdC, WithId as imported288_WithId, WithId as imported289_WithId, WithIdC as imported288_WithIdC, withIdC as imported289_withIdC } from "./threadThrough";
import * as t from "io-ts";
import { LocalDate } from "@js-joda/core";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";
import { Media as imported80_Media, mediaC as imported75_mediaC, MediaC as imported79_MediaC, MediaC as imported80_MediaC, Media as imported79_Media, mediaC as imported76_mediaC } from "./media";
import { BondLinkResourceTypeCU as imported3_BondLinkResourceTypeCU, BondLinkResourceTypeU as imported1_BondLinkResourceTypeU, BondLinkResourceTypeCU as imported2_BondLinkResourceTypeCU } from "../domaintables/bondLinkResourceTypes";
import { LocalDateC } from "../../codecs/localDate";

export type BondLinkResourceC = t.TypeC<{
  resourceType: imported3_BondLinkResourceTypeCU,
  title: t.StringC,
  description: MarkdownC,
  resourceDate: LocalDateC,
  resource: imported288_WithIdC<imported79_MediaC>,
  image: imported289_WithIdC<imported80_MediaC>
}>;
export type BondLinkResource = {
  resourceType: imported1_BondLinkResourceTypeU,
  title: string,
  description: Markdown,
  resourceDate: LocalDate,
  resource: imported288_WithId<imported79_Media>,
  image: imported289_WithId<imported80_Media>
};
export const bondLinkResourceC: BondLinkResourceC = t.type({
  resourceType: imported2_BondLinkResourceTypeCU,
  title: t.string,
  description: markdownC,
  resourceDate: LocalDateC,
  resource: imported288_withIdC(imported75_mediaC),
  image: imported289_withIdC(imported76_mediaC)
}) satisfies t.Type<BondLinkResource, unknown>;


