import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { PostEmailRecipientCU as imported5_PostEmailRecipientCU, PostEmailRecipientCU as imported4_PostEmailRecipientCU, PostEmailRecipientCU as imported7_PostEmailRecipientCU, PostEmailRecipientU as imported2_PostEmailRecipientU, PostEmailRecipientCU as imported6_PostEmailRecipientCU, PostEmailRecipientU as imported3_PostEmailRecipientU } from "./email";
import * as O from "fp-ts/lib/Option";
import { LocalDateTime } from "@js-joda/core";
import { HtmlC, Html, htmlC } from "../../codecs/html";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { MediaUploadResponseC as imported25_MediaUploadResponseC, MediaUploadResponse as imported25_MediaUploadResponse, mediaUploadResponseC as imported25_mediaUploadResponseC } from "./media";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { EmailContactIdC as imported7_EmailContactIdC, ActorIdU as imported3_ActorIdU, emailContactIdC as imported5_emailContactIdC, ActorIdCU as imported6_ActorIdCU, EmailContactId as imported7_EmailContactId, ActorIdCU as imported7_ActorIdCU, UserIdC as imported8_UserIdC, userIdC as imported5_userIdC, UserId as imported8_UserId } from "./actor";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type IssuerContactEmailPostBodyC = t.TypeC<{
  recipients: ReadonlyNonEmptyArrayType<imported5_PostEmailRecipientCU>,
  subject: t.StringC,
  message: MarkdownC,
  ccs: OptionFromNullableC<ReadonlyNonEmptyArrayType<t.StringC>>,
  replyTo: OptionFromNullableC<t.StringC>,
  bondOfferingId: OptionFromNullableC<t.NumberC>,
  rfpId: OptionFromNullableC<t.NumberC>
}>;
export type IssuerContactEmailPostBody = {
  recipients: RNEA.ReadonlyNonEmptyArray<imported2_PostEmailRecipientU>,
  subject: string,
  message: Markdown,
  ccs: O.Option<RNEA.ReadonlyNonEmptyArray<string>>,
  replyTo: O.Option<string>,
  bondOfferingId: O.Option<number>,
  rfpId: O.Option<number>
};
export const issuerContactEmailPostBodyC: IssuerContactEmailPostBodyC = t.type({
  recipients: readonlyNonEmptyArrayC(imported4_PostEmailRecipientCU),
  subject: t.string,
  message: markdownC,
  ccs: optionFromNullable(readonlyNonEmptyArrayC(t.string)),
  replyTo: optionFromNullable(t.string),
  bondOfferingId: optionFromNullable(t.number),
  rfpId: optionFromNullable(t.number)
}) satisfies t.Type<IssuerContactEmailPostBody, unknown>;


export type BankContactEmailPostBodyC = t.TypeC<{
  recipients: ReadonlyNonEmptyArrayType<imported7_PostEmailRecipientCU>,
  subject: t.StringC,
  message: MarkdownC,
  ccs: OptionFromNullableC<ReadonlyNonEmptyArrayType<t.StringC>>,
  replyTo: OptionFromNullableC<t.StringC>,
  associatedIssuerId: OptionFromNullableC<t.NumberC>,
  bondOfferingId: OptionFromNullableC<t.NumberC>,
  rfpId: OptionFromNullableC<t.NumberC>
}>;
export type BankContactEmailPostBody = {
  recipients: RNEA.ReadonlyNonEmptyArray<imported3_PostEmailRecipientU>,
  subject: string,
  message: Markdown,
  ccs: O.Option<RNEA.ReadonlyNonEmptyArray<string>>,
  replyTo: O.Option<string>,
  associatedIssuerId: O.Option<number>,
  bondOfferingId: O.Option<number>,
  rfpId: O.Option<number>
};
export const bankContactEmailPostBodyC: BankContactEmailPostBodyC = t.type({
  recipients: readonlyNonEmptyArrayC(imported6_PostEmailRecipientCU),
  subject: t.string,
  message: markdownC,
  ccs: optionFromNullable(readonlyNonEmptyArrayC(t.string)),
  replyTo: optionFromNullable(t.string),
  associatedIssuerId: optionFromNullable(t.number),
  bondOfferingId: optionFromNullable(t.number),
  rfpId: optionFromNullable(t.number)
}) satisfies t.Type<BankContactEmailPostBody, unknown>;


export type EmailUserRecipientC = t.TypeC<{
  _tag: t.LiteralC<`EmailUserRecipient`>,
  id: imported8_UserIdC,
  name: t.StringC
}>;
export type EmailUserRecipient = {
  _tag: `EmailUserRecipient`,
  id: imported8_UserId,
  name: string
};
export const emailUserRecipientC: EmailUserRecipientC = t.type({
  _tag: t.literal(`EmailUserRecipient`),
  id: imported5_userIdC,
  name: t.string
}) satisfies t.Type<EmailUserRecipient, unknown>;


export type EmailContactRecipientC = t.TypeC<{
  _tag: t.LiteralC<`EmailContactRecipient`>,
  id: imported7_EmailContactIdC
}>;
export type EmailContactRecipient = {
  _tag: `EmailContactRecipient`,
  id: imported7_EmailContactId
};
export const emailContactRecipientC: EmailContactRecipientC = t.type({
  _tag: t.literal(`EmailContactRecipient`),
  id: imported5_emailContactIdC
}) satisfies t.Type<EmailContactRecipient, unknown>;


export const allEmailRecipientC = [emailUserRecipientC, emailContactRecipientC] as const;
export const allEmailRecipientNames = [`EmailUserRecipient`, `EmailContactRecipient`] as const;
export type EmailRecipientName = (typeof allEmailRecipientNames)[number];

export type EmailRecipientCU = t.UnionC<[EmailUserRecipientC, EmailContactRecipientC]>;
export type EmailRecipientU = EmailUserRecipient | EmailContactRecipient;
export const EmailRecipientCU: EmailRecipientCU = t.union([emailUserRecipientC, emailContactRecipientC]) satisfies t.Type<EmailRecipientU, unknown>;

export type EmailRecipientMap<A> = { [K in EmailRecipientName]: A };


export type EmailInfoC = t.TypeC<{
  recipient: OptionFromNullableC<EmailRecipientCU>,
  emailAddress: t.StringC,
  subject: t.StringC,
  hash: t.StringC,
  created: LocalDateTimeFromIsoStringC
}>;
export type EmailInfo = {
  recipient: O.Option<EmailRecipientU>,
  emailAddress: string,
  subject: string,
  hash: string,
  created: LocalDateTime
};
export const emailInfoC: EmailInfoC = t.type({
  recipient: optionFromNullable(EmailRecipientCU),
  emailAddress: t.string,
  subject: t.string,
  hash: t.string,
  created: LocalDateTimeFromIsoStringC
}) satisfies t.Type<EmailInfo, unknown>;


export type PreviewEmailC = t.TypeC<{
  preview: HtmlC
}>;
export type PreviewEmail = {
  preview: Html
};
export const previewEmailC: PreviewEmailC = t.type({
  preview: htmlC
}) satisfies t.Type<PreviewEmail, unknown>;


export type BulkUploadEmailsPostC = t.TypeC<{
  dryRun: t.BooleanC,
  uploadResponse: imported25_MediaUploadResponseC
}>;
export type BulkUploadEmailsPost = {
  dryRun: boolean,
  uploadResponse: imported25_MediaUploadResponse
};
export const bulkUploadEmailsPostC: BulkUploadEmailsPostC = t.type({
  dryRun: t.boolean,
  uploadResponse: imported25_mediaUploadResponseC
}) satisfies t.Type<BulkUploadEmailsPost, unknown>;


export type BulkUploadEmailsRespC = t.TypeC<{
  count: t.NumberC,
  actorIds: t.ReadonlyArrayC<imported7_ActorIdCU>,
  emails: t.ReadonlyArrayC<t.StringC>
}>;
export type BulkUploadEmailsResp = {
  count: number,
  actorIds: ReadonlyArray<imported3_ActorIdU>,
  emails: ReadonlyArray<string>
};
export const bulkUploadEmailsRespC: BulkUploadEmailsRespC = t.type({
  count: t.number,
  actorIds: t.readonlyArray(imported6_ActorIdCU),
  emails: t.readonlyArray(t.string)
}) satisfies t.Type<BulkUploadEmailsResp, unknown>;


export type SentEmailReportC = t.TypeC<{
  received: t.ReadonlyArrayC<EmailInfoC>,
  suppressed: t.ReadonlyArrayC<EmailInfoC>
}>;
export type SentEmailReport = {
  received: ReadonlyArray<EmailInfo>,
  suppressed: ReadonlyArray<EmailInfo>
};
export const sentEmailReportC: SentEmailReportC = t.type({
  received: t.readonlyArray(emailInfoC),
  suppressed: t.readonlyArray(emailInfoC)
}) satisfies t.Type<SentEmailReport, unknown>;


