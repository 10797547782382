import { pipe } from "fp-ts/lib/function";
import type { Store } from "redux";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { E, O, R, s } from "@scripts/fp-ts";
import { reactChildToStringStripTagsE } from "@scripts/react/syntax/react";
import { fuzzyMultiSearchFilter } from "@scripts/util/uFuzzy";

import { isDataCellComponent, type TableColumnDataCell, type TableColumnRow, type TableRowModel } from "../table/tableSyntax";

export const stringifyCell = <A, MetaData>(
  config: BLConfigWithLog,
  column: TableColumnDataCell<A, MetaData>,
  row: TableRowModel<A, MetaData>,
  expandedRowIds: number[],
  store?: Store,
) => pipe(
  column.dataCellComponent(row, expandedRowIds),
  (cell) => reactChildToStringStripTagsE(config, cell, store),
  E.fold(() => "", s.toLowerCase)
);

export const getTableSearchableValues = <A, MetaData, ExtraColumns extends string[]>(
  config: BLConfigWithLog,
  tableColumns: TableColumnRow<A, MetaData, ExtraColumns>["Columns"],
  store?: Store,
  expandedRowIds?: number[],
) => {
  return R.toArray(tableColumns).map(([colName, col]) => (row: TableColumnRow<A, MetaData, ExtraColumns>["Row"]) => {
    if (isDataCellComponent(col)) {
      return col.excludeFromSearch
        ? ""
        : stringifyCell(config, col, row, expandedRowIds ?? [], store);
    } else {
      return pipe(
        R.lookup(colName)(row),
        O.filter(() => !col.excludeFromSearch),
        O.fold(
          () => "",
          cellContent => typeof cellContent === "string"
            ? cellContent.toLowerCase()
            : typeof cellContent === "number"
              ? cellContent.toString() : ""
        )
      );
    }
  });
};

export const tableSearchFilterFn = <A, MetaData, ExtraColumns extends string[]>(
  config: BLConfigWithLog,
  tableColumns: TableColumnRow<A, MetaData, ExtraColumns>["Columns"],
  store?: Store,
  expandedRowIds?: number[],
) => fuzzyMultiSearchFilter(getTableSearchableValues(config, tableColumns, store, expandedRowIds));
