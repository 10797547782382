import type { RNEA } from "@scripts/fp-ts";
import { omit } from "@scripts/fp-ts/struct";
import type { BondOfferingRelatedContent, BondOfferingWithRelatedContent } from "@scripts/generated/models/bondOffering";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";

import * as bonds from "./bondsJumpLinks";
import { relatedContent as genRelatedContent } from "./relatedContentJumpLinks";

export const offeringSummary = SitesJumpLink("Offering Summary", []);

export const ratings = (rs: BondOfferingRelatedContent["ratings"]): SitesJumpLink =>
  SitesJumpLink(bonds.ratings.defaultText, bonds.ratings.sections, { hidden: rs.length === 0 });

export const roadshows = (rs: BondOfferingRelatedContent["roadShows"]): SitesJumpLink =>
  SitesJumpLink(bonds.roadshows.defaultText, [], { hidden: rs.length === 0 });

export const participants = (ps: BondOfferingRelatedContent["participants"]): SitesJumpLink =>
  SitesJumpLink(bonds.participants.defaultText, [], { hidden: ps.length === 0 });

export const relatedContent = (rc: BondOfferingRelatedContent): SitesJumpLink =>
  genRelatedContent(omit("documents", "participants", "program", "ratings", "roadShows")(rc));

export const all = (o: BondOfferingWithRelatedContent): RNEA.ReadonlyNonEmptyArray<SitesJumpLink> => [
  offeringSummary,
  ratings(o.relatedContent.ratings),
  roadshows(o.relatedContent.roadShows),
  participants(o.relatedContent.participants),
  relatedContent(o.relatedContent),
  bonds.generalOfferingInfo,
];
