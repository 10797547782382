import { UserC as imported11_UserC, User as imported11_User, userC as imported11_userC } from "./user";
import * as t from "io-ts";

export type IssuerAdminUserC = t.TypeC<{
  user: imported11_UserC,
  draftNotifications: t.BooleanC
}>;
export type IssuerAdminUser = {
  user: imported11_User,
  draftNotifications: boolean
};
export const issuerAdminUserC: IssuerAdminUserC = t.type({
  user: imported11_userC,
  draftNotifications: t.boolean
}) satisfies t.Type<IssuerAdminUser, unknown>;


