import type { PageLink } from "@scripts/codecs/linkable";
import { O, pipe } from "@scripts/fp-ts";
import type * as p from "@scripts/generated/domaintables/partners";
import type { IssuerPartnerLanderPageData } from "@scripts/generated/models/issuerPageData";
import * as AssetsRouter from "@scripts/generated/routers/assetsRouter";
import * as CorpRouter from "@scripts/generated/routers/corpRouter";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { klass, klassNullableProp, klassNullableToList, type KlassProp, klassProp } from "@scripts/react/util/classnames";

import { PartnerLanderAbout } from "./PartnerLanderAbout";
import { PartnerLanderIssuerHeader } from "./PartnerLanderIssuerHeader";
import { PartnerLanderLink } from "./PartnerLanderLink";
import { PartnerLanderLinks } from "./PartnerLanderLinks";
import { PartnerLanderMainImg } from "./PartnerLanderMainImg";
import { PartnerLanderOfficer } from "./PartnerLanderOfficer";
import { PartnerLanderQuickFacts } from "./PartnerLanderQuickFacts";
import { PartnerLanderWelcomeLetterOrProjects } from "./PartnerLanderWelcomeLetterOrProjects";

export type PartnerWithFrame = p.PartnerU & { readonly _tag: p.Ipreo["_tag"] };

const BondLinkHeaderLink = (props: { partner: PartnerWithFrame, containerKlass: KlassProp, linkKlass?: KlassProp }) =>
  <div {...klassNullableProp(props.containerKlass)}>
    <PartnerLanderLink
      issuer={O.none}
      partner={props.partner}
      url={CorpRouter.corporateStaticIndex()}
      aria-label="BondLink Homepage"
      klasses={["bondlink-logo-link text-md-center no-decoration d-block", ...klassNullableToList(props.linkKlass)]}
    >
      <img src={AssetsRouter.assetImgLogosBondlink2019Svg().url} alt="BondLink Logo" />
      <h4 className="mt-025">Investor Relations Platform</h4>
    </PartnerLanderLink>
  </div>;

const Officer = () =>
  pipe(PartnerLanderOfficer(), mapOrEmpty(_ => <>
    <h3 className="mt-0">Primary Contact</h3>
    {_}
  </>));

const Links = (props: { data: IssuerPartnerLanderPageData, links: ReadonlyArray<PageLink>, klass: KlassProp }) =>
  <div {...klassProp("c-md-12")(props.klass)}>
    <h3>More Info</h3>
    <PartnerLanderLinks partner={props.data.partner} links={props.links} />
  </div>;

export const PartnerLanderFrame = (props: {
  partner: PartnerWithFrame;
  data: IssuerPartnerLanderPageData;
  links: ReadonlyArray<PageLink>;
}) =>
  <>
    <BondLinkHeaderLink partner={props.partner} containerKlass="container box-shadow-2 d-md-none" />
    <div className="container mb-05 d-flex justify-content-between">
      <PartnerLanderIssuerHeader partner={props.partner} klass="mt-1 mt-md-05 flex-grow" />
      <BondLinkHeaderLink partner={props.partner} containerKlass="d-none d-md-block" linkKlass="box-shadow-2" />
    </div>

    <div className="d-block d-md-none content-container">
      <div className="relative mb-1">
        <div className="container py-1">
          <div className="bg-stripe z-index-below-all" />
          <PartnerLanderMainImg mainImg={props.data.mainImg} />
          <div className="quick-facts mt-1">
            <PartnerLanderQuickFacts quickFacts={props.data.quickFacts} />
          </div>
        </div>
      </div>

      <div className="container">
        <PartnerLanderAbout aboutText={props.data.aboutText} />
        <Officer />
        <PartnerLanderWelcomeLetterOrProjects data={props.data} />
        <Links data={props.data} links={props.links} klass="d-block d-md-none" />
      </div>
    </div>

    <div className="d-none d-md-block relative content-container">
      <div className="container">
        <div className="bg-stripe" />
        <div className="grid grid-sx-2 mt-0">
          <div {...klass("c-md-12", ...pipe(props.data.mainImg, O.fold(() => ["col-left-no-img"], () => [])))}>
            <PartnerLanderMainImg mainImg={props.data.mainImg} />
            <div className="mt-15"><PartnerLanderAbout aboutText={props.data.aboutText} /></div>
          </div>
          <div className="c-md-12">
            <div className="quick-facts d-none d-md-flex align-items-center">
              <PartnerLanderQuickFacts quickFacts={props.data.quickFacts} />
            </div>
            <div className="grid grid-sx-1 mt-15">
              <div className="c-md-12"><Officer /></div>
              <Links data={props.data} links={props.links} klass="d-none d-md-block" />
            </div>
          </div>
          <div className="c-md-18">
            <PartnerLanderWelcomeLetterOrProjects data={props.data} />
          </div>
        </div>
      </div>
    </div>
  </>;
