import { E } from "@scripts/fp-ts";
import * as p from "@scripts/generated/domaintables/pages";
import { emmaName } from "@scripts/literals/emma";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";
import { contactMeta, linksMeta } from "@scripts/routes/routing/issuerportal/dataMeta";;

export const emma = SitesJumpLink(`${emmaName} Links`, [E.right(p.emmaLinks)]);

export const faq = SitesJumpLink("Frequently Asked Questions", [E.right(p.faq)]);

export const links = SitesJumpLink(linksMeta, [E.right(p.linksPage)]);

export const contact = SitesJumpLink(contactMeta, [E.right(p.contact)]);

export const allStatic = [emma, faq, links, contact];
