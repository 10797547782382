import { Route, query, lit, end, Match } from "fp-ts-routing/lib";
import * as t from "io-ts";

import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { Describe } from "../../fp-ts/lib/types/describe";
import { UrlInterface, urlInterface } from "../../routes/urlInterface";

const assetImgDealPortalDashboardHeaderJpgPath = lit("assets").then(lit("img")).then(lit("deal-portal-dashboard-header.jpg"));
const assetImgDealPortalDashboardHeaderJpgPathParts = ["assets", "img", "deal-portal-dashboard-header.jpg"] as const;
export type AssetImgDealPortalDashboardHeaderJpgPathParts = typeof assetImgDealPortalDashboardHeaderJpgPathParts;

const assetImgDealPortalDashboardHeaderJpgQuery = t.strict({});
export type AssetImgDealPortalDashboardHeaderJpgParams = Describe<typeof assetImgDealPortalDashboardHeaderJpgPath._A & typeof assetImgDealPortalDashboardHeaderJpgQuery._A>;

export type AssetImgDealPortalDashboardHeaderJpgRoute<A = {}> = { match: Match<AssetImgDealPortalDashboardHeaderJpgParams & A>; pathParts: AssetImgDealPortalDashboardHeaderJpgPathParts };

export function assetImgDealPortalDashboardHeaderJpgRoute(): AssetImgDealPortalDashboardHeaderJpgRoute;
export function assetImgDealPortalDashboardHeaderJpgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgDealPortalDashboardHeaderJpgParams>): AssetImgDealPortalDashboardHeaderJpgRoute<t.TypeOf<A>>;
export function assetImgDealPortalDashboardHeaderJpgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgDealPortalDashboardHeaderJpgParams>) {
  return {
    match: (q ? assetImgDealPortalDashboardHeaderJpgPath.then(query(t.intersection([assetImgDealPortalDashboardHeaderJpgQuery, q]))) : assetImgDealPortalDashboardHeaderJpgPath.then(query(assetImgDealPortalDashboardHeaderJpgQuery))).then(end),
    pathParts: assetImgDealPortalDashboardHeaderJpgPathParts,
  };
}

export const assetImgDealPortalDashboardHeaderJpg = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgDealPortalDashboardHeaderJpgRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailBondlinkEmailLogoBannerPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("bondlink-email-logo-banner.png"));
const assetImgEmailBondlinkEmailLogoBannerPngPathParts = [
  "assets", "img", "email", "bondlink-email-logo-banner.png"
] as const;
export type AssetImgEmailBondlinkEmailLogoBannerPngPathParts = typeof assetImgEmailBondlinkEmailLogoBannerPngPathParts;

const assetImgEmailBondlinkEmailLogoBannerPngQuery = t.strict({});
export type AssetImgEmailBondlinkEmailLogoBannerPngParams = Describe<typeof assetImgEmailBondlinkEmailLogoBannerPngPath._A & typeof assetImgEmailBondlinkEmailLogoBannerPngQuery._A>;

export type AssetImgEmailBondlinkEmailLogoBannerPngRoute<A = {}> = { match: Match<AssetImgEmailBondlinkEmailLogoBannerPngParams & A>; pathParts: AssetImgEmailBondlinkEmailLogoBannerPngPathParts };

export function assetImgEmailBondlinkEmailLogoBannerPngRoute(): AssetImgEmailBondlinkEmailLogoBannerPngRoute;
export function assetImgEmailBondlinkEmailLogoBannerPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailBondlinkEmailLogoBannerPngParams>): AssetImgEmailBondlinkEmailLogoBannerPngRoute<t.TypeOf<A>>;
export function assetImgEmailBondlinkEmailLogoBannerPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailBondlinkEmailLogoBannerPngParams>) {
  return {
    match: (q ? assetImgEmailBondlinkEmailLogoBannerPngPath.then(query(t.intersection([assetImgEmailBondlinkEmailLogoBannerPngQuery, q]))) : assetImgEmailBondlinkEmailLogoBannerPngPath.then(query(assetImgEmailBondlinkEmailLogoBannerPngQuery))).then(end),
    pathParts: assetImgEmailBondlinkEmailLogoBannerPngPathParts,
  };
}

export const assetImgEmailBondlinkEmailLogoBannerPng = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgEmailBondlinkEmailLogoBannerPngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailBondlinkEmailDividerPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("bondlink-email-divider.png"));
const assetImgEmailBondlinkEmailDividerPngPathParts = ["assets", "img", "email", "bondlink-email-divider.png"] as const;
export type AssetImgEmailBondlinkEmailDividerPngPathParts = typeof assetImgEmailBondlinkEmailDividerPngPathParts;

const assetImgEmailBondlinkEmailDividerPngQuery = t.strict({});
export type AssetImgEmailBondlinkEmailDividerPngParams = Describe<typeof assetImgEmailBondlinkEmailDividerPngPath._A & typeof assetImgEmailBondlinkEmailDividerPngQuery._A>;

export type AssetImgEmailBondlinkEmailDividerPngRoute<A = {}> = { match: Match<AssetImgEmailBondlinkEmailDividerPngParams & A>; pathParts: AssetImgEmailBondlinkEmailDividerPngPathParts };

export function assetImgEmailBondlinkEmailDividerPngRoute(): AssetImgEmailBondlinkEmailDividerPngRoute;
export function assetImgEmailBondlinkEmailDividerPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailBondlinkEmailDividerPngParams>): AssetImgEmailBondlinkEmailDividerPngRoute<t.TypeOf<A>>;
export function assetImgEmailBondlinkEmailDividerPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailBondlinkEmailDividerPngParams>) {
  return {
    match: (q ? assetImgEmailBondlinkEmailDividerPngPath.then(query(t.intersection([assetImgEmailBondlinkEmailDividerPngQuery, q]))) : assetImgEmailBondlinkEmailDividerPngPath.then(query(assetImgEmailBondlinkEmailDividerPngQuery))).then(end),
    pathParts: assetImgEmailBondlinkEmailDividerPngPathParts,
  };
}

export const assetImgEmailBondlinkEmailDividerPng = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgEmailBondlinkEmailDividerPngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailIconBondPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-bond.png"));
const assetImgEmailIconBondPngPathParts = ["assets", "img", "email", "icon-bond.png"] as const;
export type AssetImgEmailIconBondPngPathParts = typeof assetImgEmailIconBondPngPathParts;

const assetImgEmailIconBondPngQuery = t.strict({});
export type AssetImgEmailIconBondPngParams = Describe<typeof assetImgEmailIconBondPngPath._A & typeof assetImgEmailIconBondPngQuery._A>;

export type AssetImgEmailIconBondPngRoute<A = {}> = { match: Match<AssetImgEmailIconBondPngParams & A>; pathParts: AssetImgEmailIconBondPngPathParts };

export function assetImgEmailIconBondPngRoute(): AssetImgEmailIconBondPngRoute;
export function assetImgEmailIconBondPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconBondPngParams>): AssetImgEmailIconBondPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconBondPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconBondPngParams>) {
  return {
    match: (q ? assetImgEmailIconBondPngPath.then(query(t.intersection([assetImgEmailIconBondPngQuery, q]))) : assetImgEmailIconBondPngPath.then(query(assetImgEmailIconBondPngQuery))).then(end),
    pathParts: assetImgEmailIconBondPngPathParts,
  };
}

export const assetImgEmailIconBondPng = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgEmailIconBondPngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailIconDocumentPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-document.png"));
const assetImgEmailIconDocumentPngPathParts = ["assets", "img", "email", "icon-document.png"] as const;
export type AssetImgEmailIconDocumentPngPathParts = typeof assetImgEmailIconDocumentPngPathParts;

const assetImgEmailIconDocumentPngQuery = t.strict({});
export type AssetImgEmailIconDocumentPngParams = Describe<typeof assetImgEmailIconDocumentPngPath._A & typeof assetImgEmailIconDocumentPngQuery._A>;

export type AssetImgEmailIconDocumentPngRoute<A = {}> = { match: Match<AssetImgEmailIconDocumentPngParams & A>; pathParts: AssetImgEmailIconDocumentPngPathParts };

export function assetImgEmailIconDocumentPngRoute(): AssetImgEmailIconDocumentPngRoute;
export function assetImgEmailIconDocumentPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconDocumentPngParams>): AssetImgEmailIconDocumentPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconDocumentPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconDocumentPngParams>) {
  return {
    match: (q ? assetImgEmailIconDocumentPngPath.then(query(t.intersection([assetImgEmailIconDocumentPngQuery, q]))) : assetImgEmailIconDocumentPngPath.then(query(assetImgEmailIconDocumentPngQuery))).then(end),
    pathParts: assetImgEmailIconDocumentPngPathParts,
  };
}

export const assetImgEmailIconDocumentPng = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgEmailIconDocumentPngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailIconQuestionAndAnswerPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-question-and-answer.png"));
const assetImgEmailIconQuestionAndAnswerPngPathParts = [
  "assets", "img", "email", "icon-question-and-answer.png"
] as const;
export type AssetImgEmailIconQuestionAndAnswerPngPathParts = typeof assetImgEmailIconQuestionAndAnswerPngPathParts;

const assetImgEmailIconQuestionAndAnswerPngQuery = t.strict({});
export type AssetImgEmailIconQuestionAndAnswerPngParams = Describe<typeof assetImgEmailIconQuestionAndAnswerPngPath._A & typeof assetImgEmailIconQuestionAndAnswerPngQuery._A>;

export type AssetImgEmailIconQuestionAndAnswerPngRoute<A = {}> = { match: Match<AssetImgEmailIconQuestionAndAnswerPngParams & A>; pathParts: AssetImgEmailIconQuestionAndAnswerPngPathParts };

export function assetImgEmailIconQuestionAndAnswerPngRoute(): AssetImgEmailIconQuestionAndAnswerPngRoute;
export function assetImgEmailIconQuestionAndAnswerPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconQuestionAndAnswerPngParams>): AssetImgEmailIconQuestionAndAnswerPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconQuestionAndAnswerPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconQuestionAndAnswerPngParams>) {
  return {
    match: (q ? assetImgEmailIconQuestionAndAnswerPngPath.then(query(t.intersection([assetImgEmailIconQuestionAndAnswerPngQuery, q]))) : assetImgEmailIconQuestionAndAnswerPngPath.then(query(assetImgEmailIconQuestionAndAnswerPngQuery))).then(end),
    pathParts: assetImgEmailIconQuestionAndAnswerPngPathParts,
  };
}

export const assetImgEmailIconQuestionAndAnswerPng = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgEmailIconQuestionAndAnswerPngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailIconRoadshowPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-roadshow.png"));
const assetImgEmailIconRoadshowPngPathParts = ["assets", "img", "email", "icon-roadshow.png"] as const;
export type AssetImgEmailIconRoadshowPngPathParts = typeof assetImgEmailIconRoadshowPngPathParts;

const assetImgEmailIconRoadshowPngQuery = t.strict({});
export type AssetImgEmailIconRoadshowPngParams = Describe<typeof assetImgEmailIconRoadshowPngPath._A & typeof assetImgEmailIconRoadshowPngQuery._A>;

export type AssetImgEmailIconRoadshowPngRoute<A = {}> = { match: Match<AssetImgEmailIconRoadshowPngParams & A>; pathParts: AssetImgEmailIconRoadshowPngPathParts };

export function assetImgEmailIconRoadshowPngRoute(): AssetImgEmailIconRoadshowPngRoute;
export function assetImgEmailIconRoadshowPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconRoadshowPngParams>): AssetImgEmailIconRoadshowPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconRoadshowPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconRoadshowPngParams>) {
  return {
    match: (q ? assetImgEmailIconRoadshowPngPath.then(query(t.intersection([assetImgEmailIconRoadshowPngQuery, q]))) : assetImgEmailIconRoadshowPngPath.then(query(assetImgEmailIconRoadshowPngQuery))).then(end),
    pathParts: assetImgEmailIconRoadshowPngPathParts,
  };
}

export const assetImgEmailIconRoadshowPng = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgEmailIconRoadshowPngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailIconSocialTwitterPngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-social-twitter.png"));
const assetImgEmailIconSocialTwitterPngPathParts = ["assets", "img", "email", "icon-social-twitter.png"] as const;
export type AssetImgEmailIconSocialTwitterPngPathParts = typeof assetImgEmailIconSocialTwitterPngPathParts;

const assetImgEmailIconSocialTwitterPngQuery = t.strict({});
export type AssetImgEmailIconSocialTwitterPngParams = Describe<typeof assetImgEmailIconSocialTwitterPngPath._A & typeof assetImgEmailIconSocialTwitterPngQuery._A>;

export type AssetImgEmailIconSocialTwitterPngRoute<A = {}> = { match: Match<AssetImgEmailIconSocialTwitterPngParams & A>; pathParts: AssetImgEmailIconSocialTwitterPngPathParts };

export function assetImgEmailIconSocialTwitterPngRoute(): AssetImgEmailIconSocialTwitterPngRoute;
export function assetImgEmailIconSocialTwitterPngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconSocialTwitterPngParams>): AssetImgEmailIconSocialTwitterPngRoute<t.TypeOf<A>>;
export function assetImgEmailIconSocialTwitterPngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconSocialTwitterPngParams>) {
  return {
    match: (q ? assetImgEmailIconSocialTwitterPngPath.then(query(t.intersection([assetImgEmailIconSocialTwitterPngQuery, q]))) : assetImgEmailIconSocialTwitterPngPath.then(query(assetImgEmailIconSocialTwitterPngQuery))).then(end),
    pathParts: assetImgEmailIconSocialTwitterPngPathParts,
  };
}

export const assetImgEmailIconSocialTwitterPng = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgEmailIconSocialTwitterPngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgEmailIconWebsitePngPath = lit("assets").then(lit("img")).then(lit("email")).then(lit("icon-website.png"));
const assetImgEmailIconWebsitePngPathParts = ["assets", "img", "email", "icon-website.png"] as const;
export type AssetImgEmailIconWebsitePngPathParts = typeof assetImgEmailIconWebsitePngPathParts;

const assetImgEmailIconWebsitePngQuery = t.strict({});
export type AssetImgEmailIconWebsitePngParams = Describe<typeof assetImgEmailIconWebsitePngPath._A & typeof assetImgEmailIconWebsitePngQuery._A>;

export type AssetImgEmailIconWebsitePngRoute<A = {}> = { match: Match<AssetImgEmailIconWebsitePngParams & A>; pathParts: AssetImgEmailIconWebsitePngPathParts };

export function assetImgEmailIconWebsitePngRoute(): AssetImgEmailIconWebsitePngRoute;
export function assetImgEmailIconWebsitePngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgEmailIconWebsitePngParams>): AssetImgEmailIconWebsitePngRoute<t.TypeOf<A>>;
export function assetImgEmailIconWebsitePngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgEmailIconWebsitePngParams>) {
  return {
    match: (q ? assetImgEmailIconWebsitePngPath.then(query(t.intersection([assetImgEmailIconWebsitePngQuery, q]))) : assetImgEmailIconWebsitePngPath.then(query(assetImgEmailIconWebsitePngQuery))).then(end),
    pathParts: assetImgEmailIconWebsitePngPathParts,
  };
}

export const assetImgEmailIconWebsitePng = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgEmailIconWebsitePngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgLogosBondlink2019SvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("bondlink-2019.svg"));
const assetImgLogosBondlink2019SvgPathParts = ["assets", "img", "logos", "bondlink-2019.svg"] as const;
export type AssetImgLogosBondlink2019SvgPathParts = typeof assetImgLogosBondlink2019SvgPathParts;

const assetImgLogosBondlink2019SvgQuery = t.strict({});
export type AssetImgLogosBondlink2019SvgParams = Describe<typeof assetImgLogosBondlink2019SvgPath._A & typeof assetImgLogosBondlink2019SvgQuery._A>;

export type AssetImgLogosBondlink2019SvgRoute<A = {}> = { match: Match<AssetImgLogosBondlink2019SvgParams & A>; pathParts: AssetImgLogosBondlink2019SvgPathParts };

export function assetImgLogosBondlink2019SvgRoute(): AssetImgLogosBondlink2019SvgRoute;
export function assetImgLogosBondlink2019SvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosBondlink2019SvgParams>): AssetImgLogosBondlink2019SvgRoute<t.TypeOf<A>>;
export function assetImgLogosBondlink2019SvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosBondlink2019SvgParams>) {
  return {
    match: (q ? assetImgLogosBondlink2019SvgPath.then(query(t.intersection([assetImgLogosBondlink2019SvgQuery, q]))) : assetImgLogosBondlink2019SvgPath.then(query(assetImgLogosBondlink2019SvgQuery))).then(end),
    pathParts: assetImgLogosBondlink2019SvgPathParts,
  };
}

export const assetImgLogosBondlink2019Svg = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgLogosBondlink2019SvgRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgLogosBondlink2019BlackSvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("bondlink-2019-black.svg"));
const assetImgLogosBondlink2019BlackSvgPathParts = ["assets", "img", "logos", "bondlink-2019-black.svg"] as const;
export type AssetImgLogosBondlink2019BlackSvgPathParts = typeof assetImgLogosBondlink2019BlackSvgPathParts;

const assetImgLogosBondlink2019BlackSvgQuery = t.strict({});
export type AssetImgLogosBondlink2019BlackSvgParams = Describe<typeof assetImgLogosBondlink2019BlackSvgPath._A & typeof assetImgLogosBondlink2019BlackSvgQuery._A>;

export type AssetImgLogosBondlink2019BlackSvgRoute<A = {}> = { match: Match<AssetImgLogosBondlink2019BlackSvgParams & A>; pathParts: AssetImgLogosBondlink2019BlackSvgPathParts };

export function assetImgLogosBondlink2019BlackSvgRoute(): AssetImgLogosBondlink2019BlackSvgRoute;
export function assetImgLogosBondlink2019BlackSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosBondlink2019BlackSvgParams>): AssetImgLogosBondlink2019BlackSvgRoute<t.TypeOf<A>>;
export function assetImgLogosBondlink2019BlackSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosBondlink2019BlackSvgParams>) {
  return {
    match: (q ? assetImgLogosBondlink2019BlackSvgPath.then(query(t.intersection([assetImgLogosBondlink2019BlackSvgQuery, q]))) : assetImgLogosBondlink2019BlackSvgPath.then(query(assetImgLogosBondlink2019BlackSvgQuery))).then(end),
    pathParts: assetImgLogosBondlink2019BlackSvgPathParts,
  };
}

export const assetImgLogosBondlink2019BlackSvg = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgLogosBondlink2019BlackSvgRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgLogosBondlink2019InvertedSvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("bondlink-2019-inverted.svg"));
const assetImgLogosBondlink2019InvertedSvgPathParts = ["assets", "img", "logos", "bondlink-2019-inverted.svg"] as const;
export type AssetImgLogosBondlink2019InvertedSvgPathParts = typeof assetImgLogosBondlink2019InvertedSvgPathParts;

const assetImgLogosBondlink2019InvertedSvgQuery = t.strict({});
export type AssetImgLogosBondlink2019InvertedSvgParams = Describe<typeof assetImgLogosBondlink2019InvertedSvgPath._A & typeof assetImgLogosBondlink2019InvertedSvgQuery._A>;

export type AssetImgLogosBondlink2019InvertedSvgRoute<A = {}> = { match: Match<AssetImgLogosBondlink2019InvertedSvgParams & A>; pathParts: AssetImgLogosBondlink2019InvertedSvgPathParts };

export function assetImgLogosBondlink2019InvertedSvgRoute(): AssetImgLogosBondlink2019InvertedSvgRoute;
export function assetImgLogosBondlink2019InvertedSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosBondlink2019InvertedSvgParams>): AssetImgLogosBondlink2019InvertedSvgRoute<t.TypeOf<A>>;
export function assetImgLogosBondlink2019InvertedSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosBondlink2019InvertedSvgParams>) {
  return {
    match: (q ? assetImgLogosBondlink2019InvertedSvgPath.then(query(t.intersection([assetImgLogosBondlink2019InvertedSvgQuery, q]))) : assetImgLogosBondlink2019InvertedSvgPath.then(query(assetImgLogosBondlink2019InvertedSvgQuery))).then(end),
    pathParts: assetImgLogosBondlink2019InvertedSvgPathParts,
  };
}

export const assetImgLogosBondlink2019InvertedSvg = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgLogosBondlink2019InvertedSvgRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgLogosBondlinkRoadshowLogoSvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("bondlink-roadshow-logo.svg"));
const assetImgLogosBondlinkRoadshowLogoSvgPathParts = ["assets", "img", "logos", "bondlink-roadshow-logo.svg"] as const;
export type AssetImgLogosBondlinkRoadshowLogoSvgPathParts = typeof assetImgLogosBondlinkRoadshowLogoSvgPathParts;

const assetImgLogosBondlinkRoadshowLogoSvgQuery = t.strict({});
export type AssetImgLogosBondlinkRoadshowLogoSvgParams = Describe<typeof assetImgLogosBondlinkRoadshowLogoSvgPath._A & typeof assetImgLogosBondlinkRoadshowLogoSvgQuery._A>;

export type AssetImgLogosBondlinkRoadshowLogoSvgRoute<A = {}> = { match: Match<AssetImgLogosBondlinkRoadshowLogoSvgParams & A>; pathParts: AssetImgLogosBondlinkRoadshowLogoSvgPathParts };

export function assetImgLogosBondlinkRoadshowLogoSvgRoute(): AssetImgLogosBondlinkRoadshowLogoSvgRoute;
export function assetImgLogosBondlinkRoadshowLogoSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosBondlinkRoadshowLogoSvgParams>): AssetImgLogosBondlinkRoadshowLogoSvgRoute<t.TypeOf<A>>;
export function assetImgLogosBondlinkRoadshowLogoSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosBondlinkRoadshowLogoSvgParams>) {
  return {
    match: (q ? assetImgLogosBondlinkRoadshowLogoSvgPath.then(query(t.intersection([assetImgLogosBondlinkRoadshowLogoSvgQuery, q]))) : assetImgLogosBondlinkRoadshowLogoSvgPath.then(query(assetImgLogosBondlinkRoadshowLogoSvgQuery))).then(end),
    pathParts: assetImgLogosBondlinkRoadshowLogoSvgPathParts,
  };
}

export const assetImgLogosBondlinkRoadshowLogoSvg = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgLogosBondlinkRoadshowLogoSvgRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgLogosBondlinkSocialSharePngPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("bondlink-social-share.png"));
const assetImgLogosBondlinkSocialSharePngPathParts = ["assets", "img", "logos", "bondlink-social-share.png"] as const;
export type AssetImgLogosBondlinkSocialSharePngPathParts = typeof assetImgLogosBondlinkSocialSharePngPathParts;

const assetImgLogosBondlinkSocialSharePngQuery = t.strict({});
export type AssetImgLogosBondlinkSocialSharePngParams = Describe<typeof assetImgLogosBondlinkSocialSharePngPath._A & typeof assetImgLogosBondlinkSocialSharePngQuery._A>;

export type AssetImgLogosBondlinkSocialSharePngRoute<A = {}> = { match: Match<AssetImgLogosBondlinkSocialSharePngParams & A>; pathParts: AssetImgLogosBondlinkSocialSharePngPathParts };

export function assetImgLogosBondlinkSocialSharePngRoute(): AssetImgLogosBondlinkSocialSharePngRoute;
export function assetImgLogosBondlinkSocialSharePngRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosBondlinkSocialSharePngParams>): AssetImgLogosBondlinkSocialSharePngRoute<t.TypeOf<A>>;
export function assetImgLogosBondlinkSocialSharePngRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosBondlinkSocialSharePngParams>) {
  return {
    match: (q ? assetImgLogosBondlinkSocialSharePngPath.then(query(t.intersection([assetImgLogosBondlinkSocialSharePngQuery, q]))) : assetImgLogosBondlinkSocialSharePngPath.then(query(assetImgLogosBondlinkSocialSharePngQuery))).then(end),
    pathParts: assetImgLogosBondlinkSocialSharePngPathParts,
  };
}

export const assetImgLogosBondlinkSocialSharePng = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgLogosBondlinkSocialSharePngRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgLogosLogoBondlinkPrimarySvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("logo-bondlink-primary.svg"));
const assetImgLogosLogoBondlinkPrimarySvgPathParts = ["assets", "img", "logos", "logo-bondlink-primary.svg"] as const;
export type AssetImgLogosLogoBondlinkPrimarySvgPathParts = typeof assetImgLogosLogoBondlinkPrimarySvgPathParts;

const assetImgLogosLogoBondlinkPrimarySvgQuery = t.strict({});
export type AssetImgLogosLogoBondlinkPrimarySvgParams = Describe<typeof assetImgLogosLogoBondlinkPrimarySvgPath._A & typeof assetImgLogosLogoBondlinkPrimarySvgQuery._A>;

export type AssetImgLogosLogoBondlinkPrimarySvgRoute<A = {}> = { match: Match<AssetImgLogosLogoBondlinkPrimarySvgParams & A>; pathParts: AssetImgLogosLogoBondlinkPrimarySvgPathParts };

export function assetImgLogosLogoBondlinkPrimarySvgRoute(): AssetImgLogosLogoBondlinkPrimarySvgRoute;
export function assetImgLogosLogoBondlinkPrimarySvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosLogoBondlinkPrimarySvgParams>): AssetImgLogosLogoBondlinkPrimarySvgRoute<t.TypeOf<A>>;
export function assetImgLogosLogoBondlinkPrimarySvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosLogoBondlinkPrimarySvgParams>) {
  return {
    match: (q ? assetImgLogosLogoBondlinkPrimarySvgPath.then(query(t.intersection([assetImgLogosLogoBondlinkPrimarySvgQuery, q]))) : assetImgLogosLogoBondlinkPrimarySvgPath.then(query(assetImgLogosLogoBondlinkPrimarySvgQuery))).then(end),
    pathParts: assetImgLogosLogoBondlinkPrimarySvgPathParts,
  };
}

export const assetImgLogosLogoBondlinkPrimarySvg = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgLogosLogoBondlinkPrimarySvgRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetImgLogosLogoBondlinkPrimaryFullColorSvgPath = lit("assets").then(lit("img")).then(lit("logos")).then(lit("logo-bondlink-primary-full-color.svg"));
const assetImgLogosLogoBondlinkPrimaryFullColorSvgPathParts = [
  "assets", "img", "logos", "logo-bondlink-primary-full-color.svg"
] as const;
export type AssetImgLogosLogoBondlinkPrimaryFullColorSvgPathParts = typeof assetImgLogosLogoBondlinkPrimaryFullColorSvgPathParts;

const assetImgLogosLogoBondlinkPrimaryFullColorSvgQuery = t.strict({});
export type AssetImgLogosLogoBondlinkPrimaryFullColorSvgParams = Describe<typeof assetImgLogosLogoBondlinkPrimaryFullColorSvgPath._A & typeof assetImgLogosLogoBondlinkPrimaryFullColorSvgQuery._A>;

export type AssetImgLogosLogoBondlinkPrimaryFullColorSvgRoute<A = {}> = { match: Match<AssetImgLogosLogoBondlinkPrimaryFullColorSvgParams & A>; pathParts: AssetImgLogosLogoBondlinkPrimaryFullColorSvgPathParts };

export function assetImgLogosLogoBondlinkPrimaryFullColorSvgRoute(): AssetImgLogosLogoBondlinkPrimaryFullColorSvgRoute;
export function assetImgLogosLogoBondlinkPrimaryFullColorSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetImgLogosLogoBondlinkPrimaryFullColorSvgParams>): AssetImgLogosLogoBondlinkPrimaryFullColorSvgRoute<t.TypeOf<A>>;
export function assetImgLogosLogoBondlinkPrimaryFullColorSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetImgLogosLogoBondlinkPrimaryFullColorSvgParams>) {
  return {
    match: (q ? assetImgLogosLogoBondlinkPrimaryFullColorSvgPath.then(query(t.intersection([assetImgLogosLogoBondlinkPrimaryFullColorSvgQuery, q]))) : assetImgLogosLogoBondlinkPrimaryFullColorSvgPath.then(query(assetImgLogosLogoBondlinkPrimaryFullColorSvgQuery))).then(end),
    pathParts: assetImgLogosLogoBondlinkPrimaryFullColorSvgPathParts,
  };
}

export const assetImgLogosLogoBondlinkPrimaryFullColorSvg = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetImgLogosLogoBondlinkPrimaryFullColorSvgRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetSvgsChevronUpSvgPath = lit("assets").then(lit("svgs")).then(lit("chevron-up.svg"));
const assetSvgsChevronUpSvgPathParts = ["assets", "svgs", "chevron-up.svg"] as const;
export type AssetSvgsChevronUpSvgPathParts = typeof assetSvgsChevronUpSvgPathParts;

const assetSvgsChevronUpSvgQuery = t.strict({});
export type AssetSvgsChevronUpSvgParams = Describe<typeof assetSvgsChevronUpSvgPath._A & typeof assetSvgsChevronUpSvgQuery._A>;

export type AssetSvgsChevronUpSvgRoute<A = {}> = { match: Match<AssetSvgsChevronUpSvgParams & A>; pathParts: AssetSvgsChevronUpSvgPathParts };

export function assetSvgsChevronUpSvgRoute(): AssetSvgsChevronUpSvgRoute;
export function assetSvgsChevronUpSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetSvgsChevronUpSvgParams>): AssetSvgsChevronUpSvgRoute<t.TypeOf<A>>;
export function assetSvgsChevronUpSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetSvgsChevronUpSvgParams>) {
  return {
    match: (q ? assetSvgsChevronUpSvgPath.then(query(t.intersection([assetSvgsChevronUpSvgQuery, q]))) : assetSvgsChevronUpSvgPath.then(query(assetSvgsChevronUpSvgQuery))).then(end),
    pathParts: assetSvgsChevronUpSvgPathParts,
  };
}

export const assetSvgsChevronUpSvg = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetSvgsChevronUpSvgRoute().match.formatter.run(Route.empty, {}).toString(true));

const assetSvgsTrashSvgPath = lit("assets").then(lit("svgs")).then(lit("trash.svg"));
const assetSvgsTrashSvgPathParts = ["assets", "svgs", "trash.svg"] as const;
export type AssetSvgsTrashSvgPathParts = typeof assetSvgsTrashSvgPathParts;

const assetSvgsTrashSvgQuery = t.strict({});
export type AssetSvgsTrashSvgParams = Describe<typeof assetSvgsTrashSvgPath._A & typeof assetSvgsTrashSvgQuery._A>;

export type AssetSvgsTrashSvgRoute<A = {}> = { match: Match<AssetSvgsTrashSvgParams & A>; pathParts: AssetSvgsTrashSvgPathParts };

export function assetSvgsTrashSvgRoute(): AssetSvgsTrashSvgRoute;
export function assetSvgsTrashSvgRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AssetSvgsTrashSvgParams>): AssetSvgsTrashSvgRoute<t.TypeOf<A>>;
export function assetSvgsTrashSvgRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AssetSvgsTrashSvgParams>) {
  return {
    match: (q ? assetSvgsTrashSvgPath.then(query(t.intersection([assetSvgsTrashSvgQuery, q]))) : assetSvgsTrashSvgPath.then(query(assetSvgsTrashSvgQuery))).then(end),
    pathParts: assetSvgsTrashSvgPathParts,
  };
}

export const assetSvgsTrashSvg = (): UrlInterface<"GET"> =>
  urlInterface("GET", assetSvgsTrashSvgRoute().match.formatter.run(Route.empty, {}).toString(true));