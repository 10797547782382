import type { PropsWithChildren } from "react";

import { O, pipe } from "@scripts/fp-ts";
import { ButtonPrimary } from "@scripts/react/components/Button";
import { InputRaw } from "@scripts/react/components/form/TextInput";
import { Grid, GridCol } from "@scripts/react/components/layout/Grid";
import { Svg } from "@scripts/react/components/Svg";
import { klass, type KlassProp, mergeKlassPropO } from "@scripts/react/util/classnames";
import type { LabelOrAriaLabel } from "@scripts/util/labelOrAriaLabel";

import closeIcon from "@svgs/close-x.svg";
import searchIcon from "@svgs/magnifying-glass.svg";

export type IssuerSitesSearchInputProps = {
  ariaLabelledById: string;
  placeholder: O.Option<string>;
  search: O.Option<string>;
  labelOrAriaLabel: LabelOrAriaLabel;
  setSearch: (s: O.Option<string>) => void;
  disabled?: boolean;
};

export const IssuerSitesSearchInput = (props: IssuerSitesSearchInputProps) => <div {...klass("form-input input-group issuer-sites-search")}>
    <div {...klass("input-group-container")}>
      <InputRaw
        ariaLabelledById={props.ariaLabelledById}
        onChange={e => props.setSearch(O.fromPredicate((_: string) => _.length > 0)(e.target.value))}
        placeholder={props.placeholder}
        errorComponent={O.none}
        labelComponent={O.none}
        labelOrAriaLabel={props.labelOrAriaLabel}
        type="text"
        value={props.search}
        disabled={props.disabled}
      />
      <div {...klass("input-group-postfix")}>
        <ButtonPrimary {...klass("p-0 mt-0")} onClick={() => props.setSearch(O.none)} disabled={props.disabled}>
          <Svg src={pipe(props.search, O.filter(_ => _.length > 0), O.fold(() => searchIcon, () => closeIcon))} />
        </ButtonPrimary>
      </div>
    </div>
  </div>;

export const IssuerSitesSearchInputGrid = (props: PropsWithChildren<IssuerSitesSearchInputProps & { klass?: KlassProp }>) =>
  <Grid attrs={O.some(".grid-sx-1")} klasses={mergeKlassPropO("justify-content-between mb-md-15")(props.klass)}>
    <GridCol cols={[".c-md-10", ".c-lg-8"]} klasses="mb-1 mb-md-0">
      <IssuerSitesSearchInput {...props} />
    </GridCol>
    {props.children}
  </Grid>;
