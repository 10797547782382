import { Route, query, lit, str, int, end, type, Match } from "fp-ts-routing/lib";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { Describe } from "../../fp-ts/lib/types/describe";
import { NullableToOptionalFields, optionalFieldsToNullable } from "../../fp-ts/Record";
import { issuerIdC } from "../../routes/routing/params/issuerId";
import { UrlInterface, urlInterface } from "../../routes/urlInterface";

const adminAdminControllerBuildCorpPath = lit("v2").then(lit("admin")).then(lit("issuer")).then(lit("build-corp"));
const adminAdminControllerBuildCorpPathParts = ["v2", "admin", "issuer", "build-corp"] as const;
export type AdminAdminControllerBuildCorpPathParts = typeof adminAdminControllerBuildCorpPathParts;

const adminAdminControllerBuildCorpQuery = t.strict({});
export type AdminAdminControllerBuildCorpParams = Describe<typeof adminAdminControllerBuildCorpPath._A & typeof adminAdminControllerBuildCorpQuery._A>;

export type AdminAdminControllerBuildCorpRoute<A = {}> = { match: Match<AdminAdminControllerBuildCorpParams & A>; pathParts: AdminAdminControllerBuildCorpPathParts };

export function adminAdminControllerBuildCorpRoute(): AdminAdminControllerBuildCorpRoute;
export function adminAdminControllerBuildCorpRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerBuildCorpParams>): AdminAdminControllerBuildCorpRoute<t.TypeOf<A>>;
export function adminAdminControllerBuildCorpRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerBuildCorpParams>) {
  return {
    match: (q ? adminAdminControllerBuildCorpPath.then(query(t.intersection([adminAdminControllerBuildCorpQuery, q]))) : adminAdminControllerBuildCorpPath.then(query(adminAdminControllerBuildCorpQuery))).then(end),
    pathParts: adminAdminControllerBuildCorpPathParts,
  };
}

export const adminAdminControllerBuildCorp = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminControllerBuildCorpRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminControllerDeployCorpPath = lit("v2").then(lit("admin")).then(lit("issuer")).then(lit("deploy-corp"));
const adminAdminControllerDeployCorpPathParts = ["v2", "admin", "issuer", "deploy-corp"] as const;
export type AdminAdminControllerDeployCorpPathParts = typeof adminAdminControllerDeployCorpPathParts;

const adminAdminControllerDeployCorpQuery = t.strict({});
export type AdminAdminControllerDeployCorpParams = Describe<typeof adminAdminControllerDeployCorpPath._A & typeof adminAdminControllerDeployCorpQuery._A>;

export type AdminAdminControllerDeployCorpRoute<A = {}> = { match: Match<AdminAdminControllerDeployCorpParams & A>; pathParts: AdminAdminControllerDeployCorpPathParts };

export function adminAdminControllerDeployCorpRoute(): AdminAdminControllerDeployCorpRoute;
export function adminAdminControllerDeployCorpRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerDeployCorpParams>): AdminAdminControllerDeployCorpRoute<t.TypeOf<A>>;
export function adminAdminControllerDeployCorpRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerDeployCorpParams>) {
  return {
    match: (q ? adminAdminControllerDeployCorpPath.then(query(t.intersection([adminAdminControllerDeployCorpQuery, q]))) : adminAdminControllerDeployCorpPath.then(query(adminAdminControllerDeployCorpQuery))).then(end),
    pathParts: adminAdminControllerDeployCorpPathParts,
  };
}

export const adminAdminControllerDeployCorp = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminControllerDeployCorpRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminControllerAccountStatusPath = lit("v2").then(lit("admin")).then(lit("issuer")).then(lit("account-status"));
const adminAdminControllerAccountStatusPathParts = ["v2", "admin", "issuer", "account-status"] as const;
export type AdminAdminControllerAccountStatusPathParts = typeof adminAdminControllerAccountStatusPathParts;

const adminAdminControllerAccountStatusQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminAdminControllerAccountStatusParams = Describe<typeof adminAdminControllerAccountStatusPath._A & typeof adminAdminControllerAccountStatusQuery._A>;

export type AdminAdminControllerAccountStatusRoute<A = {}> = { match: Match<AdminAdminControllerAccountStatusParams & A>; pathParts: AdminAdminControllerAccountStatusPathParts };

export function adminAdminControllerAccountStatusRoute(): AdminAdminControllerAccountStatusRoute;
export function adminAdminControllerAccountStatusRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerAccountStatusParams>): AdminAdminControllerAccountStatusRoute<t.TypeOf<A>>;
export function adminAdminControllerAccountStatusRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerAccountStatusParams>) {
  return {
    match: (q ? adminAdminControllerAccountStatusPath.then(query(t.intersection([adminAdminControllerAccountStatusQuery, q]))) : adminAdminControllerAccountStatusPath.then(query(adminAdminControllerAccountStatusQuery))).then(end),
    pathParts: adminAdminControllerAccountStatusPathParts,
  };
}

export const adminAdminControllerAccountStatus = (p: AdminAdminControllerAccountStatusParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminControllerAccountStatusRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminControllerBuildCorporateProdPath = lit("admin").then(lit("build-corporate"));
const adminAdminControllerBuildCorporateProdPathParts = ["admin", "build-corporate"] as const;
export type AdminAdminControllerBuildCorporateProdPathParts = typeof adminAdminControllerBuildCorporateProdPathParts;

const adminAdminControllerBuildCorporateProdQuery = t.strict({});
export type AdminAdminControllerBuildCorporateProdParams = Describe<typeof adminAdminControllerBuildCorporateProdPath._A & typeof adminAdminControllerBuildCorporateProdQuery._A>;

export type AdminAdminControllerBuildCorporateProdRoute<A = {}> = { match: Match<AdminAdminControllerBuildCorporateProdParams & A>; pathParts: AdminAdminControllerBuildCorporateProdPathParts };

export function adminAdminControllerBuildCorporateProdRoute(): AdminAdminControllerBuildCorporateProdRoute;
export function adminAdminControllerBuildCorporateProdRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerBuildCorporateProdParams>): AdminAdminControllerBuildCorporateProdRoute<t.TypeOf<A>>;
export function adminAdminControllerBuildCorporateProdRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerBuildCorporateProdParams>) {
  return {
    match: (q ? adminAdminControllerBuildCorporateProdPath.then(query(t.intersection([adminAdminControllerBuildCorporateProdQuery, q]))) : adminAdminControllerBuildCorporateProdPath.then(query(adminAdminControllerBuildCorporateProdQuery))).then(end),
    pathParts: adminAdminControllerBuildCorporateProdPathParts,
  };
}

export const adminAdminControllerBuildCorporateProd = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminControllerBuildCorporateProdRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminControllerClientContactsPath = lit("admin").then(lit("client-contacts"));
const adminAdminControllerClientContactsPathParts = ["admin", "client-contacts"] as const;
export type AdminAdminControllerClientContactsPathParts = typeof adminAdminControllerClientContactsPathParts;

const adminAdminControllerClientContactsQuery = t.strict({});
export type AdminAdminControllerClientContactsParams = Describe<typeof adminAdminControllerClientContactsPath._A & typeof adminAdminControllerClientContactsQuery._A>;

export type AdminAdminControllerClientContactsRoute<A = {}> = { match: Match<AdminAdminControllerClientContactsParams & A>; pathParts: AdminAdminControllerClientContactsPathParts };

export function adminAdminControllerClientContactsRoute(): AdminAdminControllerClientContactsRoute;
export function adminAdminControllerClientContactsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerClientContactsParams>): AdminAdminControllerClientContactsRoute<t.TypeOf<A>>;
export function adminAdminControllerClientContactsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerClientContactsParams>) {
  return {
    match: (q ? adminAdminControllerClientContactsPath.then(query(t.intersection([adminAdminControllerClientContactsQuery, q]))) : adminAdminControllerClientContactsPath.then(query(adminAdminControllerClientContactsQuery))).then(end),
    pathParts: adminAdminControllerClientContactsPathParts,
  };
}

export const adminAdminControllerClientContacts = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminControllerClientContactsRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminControllerCustomPagesPath = lit("admin").then(lit("custom-pages"));
const adminAdminControllerCustomPagesPathParts = ["admin", "custom-pages"] as const;
export type AdminAdminControllerCustomPagesPathParts = typeof adminAdminControllerCustomPagesPathParts;

const adminAdminControllerCustomPagesQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminAdminControllerCustomPagesParams = Describe<typeof adminAdminControllerCustomPagesPath._A & typeof adminAdminControllerCustomPagesQuery._A>;

export type AdminAdminControllerCustomPagesRoute<A = {}> = { match: Match<AdminAdminControllerCustomPagesParams & A>; pathParts: AdminAdminControllerCustomPagesPathParts };

export function adminAdminControllerCustomPagesRoute(): AdminAdminControllerCustomPagesRoute;
export function adminAdminControllerCustomPagesRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerCustomPagesParams>): AdminAdminControllerCustomPagesRoute<t.TypeOf<A>>;
export function adminAdminControllerCustomPagesRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerCustomPagesParams>) {
  return {
    match: (q ? adminAdminControllerCustomPagesPath.then(query(t.intersection([adminAdminControllerCustomPagesQuery, q]))) : adminAdminControllerCustomPagesPath.then(query(adminAdminControllerCustomPagesQuery))).then(end),
    pathParts: adminAdminControllerCustomPagesPathParts,
  };
}

export const adminAdminControllerCustomPages = (p: AdminAdminControllerCustomPagesParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminControllerCustomPagesRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminControllerCustomPagesPostPath = lit("v2").then(lit("admin")).then(lit("custom-pages"));
const adminAdminControllerCustomPagesPostPathParts = ["v2", "admin", "custom-pages"] as const;
export type AdminAdminControllerCustomPagesPostPathParts = typeof adminAdminControllerCustomPagesPostPathParts;

const adminAdminControllerCustomPagesPostQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminAdminControllerCustomPagesPostParams = Describe<typeof adminAdminControllerCustomPagesPostPath._A & typeof adminAdminControllerCustomPagesPostQuery._A>;

export type AdminAdminControllerCustomPagesPostRoute<A = {}> = { match: Match<AdminAdminControllerCustomPagesPostParams & A>; pathParts: AdminAdminControllerCustomPagesPostPathParts };

export function adminAdminControllerCustomPagesPostRoute(): AdminAdminControllerCustomPagesPostRoute;
export function adminAdminControllerCustomPagesPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerCustomPagesPostParams>): AdminAdminControllerCustomPagesPostRoute<t.TypeOf<A>>;
export function adminAdminControllerCustomPagesPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerCustomPagesPostParams>) {
  return {
    match: (q ? adminAdminControllerCustomPagesPostPath.then(query(t.intersection([adminAdminControllerCustomPagesPostQuery, q]))) : adminAdminControllerCustomPagesPostPath.then(query(adminAdminControllerCustomPagesPostQuery))).then(end),
    pathParts: adminAdminControllerCustomPagesPostPathParts,
  };
}

export const adminAdminControllerCustomPagesPost = (p: AdminAdminControllerCustomPagesPostParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminControllerCustomPagesPostRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminControllerBankFeatureFlagsPath = lit("admin").then(lit("bank")).then(lit("feature-flags"));
const adminAdminControllerBankFeatureFlagsPathParts = ["admin", "bank", "feature-flags"] as const;
export type AdminAdminControllerBankFeatureFlagsPathParts = typeof adminAdminControllerBankFeatureFlagsPathParts;

const adminAdminControllerBankFeatureFlagsQuery = t.exact(t.type({
  "bankId": NumberFromString
}));
export type AdminAdminControllerBankFeatureFlagsParams = Describe<typeof adminAdminControllerBankFeatureFlagsPath._A & typeof adminAdminControllerBankFeatureFlagsQuery._A>;

export type AdminAdminControllerBankFeatureFlagsRoute<A = {}> = { match: Match<AdminAdminControllerBankFeatureFlagsParams & A>; pathParts: AdminAdminControllerBankFeatureFlagsPathParts };

export function adminAdminControllerBankFeatureFlagsRoute(): AdminAdminControllerBankFeatureFlagsRoute;
export function adminAdminControllerBankFeatureFlagsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerBankFeatureFlagsParams>): AdminAdminControllerBankFeatureFlagsRoute<t.TypeOf<A>>;
export function adminAdminControllerBankFeatureFlagsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerBankFeatureFlagsParams>) {
  return {
    match: (q ? adminAdminControllerBankFeatureFlagsPath.then(query(t.intersection([adminAdminControllerBankFeatureFlagsQuery, q]))) : adminAdminControllerBankFeatureFlagsPath.then(query(adminAdminControllerBankFeatureFlagsQuery))).then(end),
    pathParts: adminAdminControllerBankFeatureFlagsPathParts,
  };
}

export const adminAdminControllerBankFeatureFlags = (p: AdminAdminControllerBankFeatureFlagsParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminControllerBankFeatureFlagsRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminControllerIssuerFeatureFlagsPath = lit("admin").then(lit("issuer")).then(lit("feature-flags"));
const adminAdminControllerIssuerFeatureFlagsPathParts = ["admin", "issuer", "feature-flags"] as const;
export type AdminAdminControllerIssuerFeatureFlagsPathParts = typeof adminAdminControllerIssuerFeatureFlagsPathParts;

const adminAdminControllerIssuerFeatureFlagsQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminAdminControllerIssuerFeatureFlagsParams = Describe<typeof adminAdminControllerIssuerFeatureFlagsPath._A & typeof adminAdminControllerIssuerFeatureFlagsQuery._A>;

export type AdminAdminControllerIssuerFeatureFlagsRoute<A = {}> = { match: Match<AdminAdminControllerIssuerFeatureFlagsParams & A>; pathParts: AdminAdminControllerIssuerFeatureFlagsPathParts };

export function adminAdminControllerIssuerFeatureFlagsRoute(): AdminAdminControllerIssuerFeatureFlagsRoute;
export function adminAdminControllerIssuerFeatureFlagsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerIssuerFeatureFlagsParams>): AdminAdminControllerIssuerFeatureFlagsRoute<t.TypeOf<A>>;
export function adminAdminControllerIssuerFeatureFlagsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerIssuerFeatureFlagsParams>) {
  return {
    match: (q ? adminAdminControllerIssuerFeatureFlagsPath.then(query(t.intersection([adminAdminControllerIssuerFeatureFlagsQuery, q]))) : adminAdminControllerIssuerFeatureFlagsPath.then(query(adminAdminControllerIssuerFeatureFlagsQuery))).then(end),
    pathParts: adminAdminControllerIssuerFeatureFlagsPathParts,
  };
}

export const adminAdminControllerIssuerFeatureFlags = (p: AdminAdminControllerIssuerFeatureFlagsParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminControllerIssuerFeatureFlagsRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminControllerIndexPath = lit("admin");
const adminAdminControllerIndexPathParts = ["admin"] as const;
export type AdminAdminControllerIndexPathParts = typeof adminAdminControllerIndexPathParts;

const adminAdminControllerIndexQuery = t.strict({});
export type AdminAdminControllerIndexParams = Describe<typeof adminAdminControllerIndexPath._A & typeof adminAdminControllerIndexQuery._A>;

export type AdminAdminControllerIndexRoute<A = {}> = { match: Match<AdminAdminControllerIndexParams & A>; pathParts: AdminAdminControllerIndexPathParts };

export function adminAdminControllerIndexRoute(): AdminAdminControllerIndexRoute;
export function adminAdminControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerIndexParams>): AdminAdminControllerIndexRoute<t.TypeOf<A>>;
export function adminAdminControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerIndexParams>) {
  return {
    match: (q ? adminAdminControllerIndexPath.then(query(t.intersection([adminAdminControllerIndexQuery, q]))) : adminAdminControllerIndexPath.then(query(adminAdminControllerIndexQuery))).then(end),
    pathParts: adminAdminControllerIndexPathParts,
  };
}

export const adminAdminControllerIndex = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminControllerIndexRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminControllerDataTablePath = lit("admin").then(lit("data-table"));
const adminAdminControllerDataTablePathParts = ["admin", "data-table"] as const;
export type AdminAdminControllerDataTablePathParts = typeof adminAdminControllerDataTablePathParts;

const adminAdminControllerDataTableQuery = t.strict({});
export type AdminAdminControllerDataTableParams = Describe<typeof adminAdminControllerDataTablePath._A & typeof adminAdminControllerDataTableQuery._A>;

export type AdminAdminControllerDataTableRoute<A = {}> = { match: Match<AdminAdminControllerDataTableParams & A>; pathParts: AdminAdminControllerDataTablePathParts };

export function adminAdminControllerDataTableRoute(): AdminAdminControllerDataTableRoute;
export function adminAdminControllerDataTableRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerDataTableParams>): AdminAdminControllerDataTableRoute<t.TypeOf<A>>;
export function adminAdminControllerDataTableRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerDataTableParams>) {
  return {
    match: (q ? adminAdminControllerDataTablePath.then(query(t.intersection([adminAdminControllerDataTableQuery, q]))) : adminAdminControllerDataTablePath.then(query(adminAdminControllerDataTableQuery))).then(end),
    pathParts: adminAdminControllerDataTablePathParts,
  };
}

export const adminAdminControllerDataTable = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminControllerDataTableRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminControllerStripeImportPath = lit("admin").then(lit("stripe-import"));
const adminAdminControllerStripeImportPathParts = ["admin", "stripe-import"] as const;
export type AdminAdminControllerStripeImportPathParts = typeof adminAdminControllerStripeImportPathParts;

const adminAdminControllerStripeImportQuery = t.strict({});
export type AdminAdminControllerStripeImportParams = Describe<typeof adminAdminControllerStripeImportPath._A & typeof adminAdminControllerStripeImportQuery._A>;

export type AdminAdminControllerStripeImportRoute<A = {}> = { match: Match<AdminAdminControllerStripeImportParams & A>; pathParts: AdminAdminControllerStripeImportPathParts };

export function adminAdminControllerStripeImportRoute(): AdminAdminControllerStripeImportRoute;
export function adminAdminControllerStripeImportRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerStripeImportParams>): AdminAdminControllerStripeImportRoute<t.TypeOf<A>>;
export function adminAdminControllerStripeImportRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerStripeImportParams>) {
  return {
    match: (q ? adminAdminControllerStripeImportPath.then(query(t.intersection([adminAdminControllerStripeImportQuery, q]))) : adminAdminControllerStripeImportPath.then(query(adminAdminControllerStripeImportQuery))).then(end),
    pathParts: adminAdminControllerStripeImportPathParts,
  };
}

export const adminAdminControllerStripeImport = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminControllerStripeImportRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminControllerStripeInvoicePath = lit("admin").then(lit("stripe-invoice"));
const adminAdminControllerStripeInvoicePathParts = ["admin", "stripe-invoice"] as const;
export type AdminAdminControllerStripeInvoicePathParts = typeof adminAdminControllerStripeInvoicePathParts;

const adminAdminControllerStripeInvoiceQuery = t.strict({});
export type AdminAdminControllerStripeInvoiceParams = Describe<typeof adminAdminControllerStripeInvoicePath._A & typeof adminAdminControllerStripeInvoiceQuery._A>;

export type AdminAdminControllerStripeInvoiceRoute<A = {}> = { match: Match<AdminAdminControllerStripeInvoiceParams & A>; pathParts: AdminAdminControllerStripeInvoicePathParts };

export function adminAdminControllerStripeInvoiceRoute(): AdminAdminControllerStripeInvoiceRoute;
export function adminAdminControllerStripeInvoiceRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerStripeInvoiceParams>): AdminAdminControllerStripeInvoiceRoute<t.TypeOf<A>>;
export function adminAdminControllerStripeInvoiceRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerStripeInvoiceParams>) {
  return {
    match: (q ? adminAdminControllerStripeInvoicePath.then(query(t.intersection([adminAdminControllerStripeInvoiceQuery, q]))) : adminAdminControllerStripeInvoicePath.then(query(adminAdminControllerStripeInvoiceQuery))).then(end),
    pathParts: adminAdminControllerStripeInvoicePathParts,
  };
}

export const adminAdminControllerStripeInvoice = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminControllerStripeInvoiceRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminControllerToggleBankFeatureFlagPath = lit("admin").then(lit("bank")).then(lit("toggle-feature-flag"));
const adminAdminControllerToggleBankFeatureFlagPathParts = ["admin", "bank", "toggle-feature-flag"] as const;
export type AdminAdminControllerToggleBankFeatureFlagPathParts = typeof adminAdminControllerToggleBankFeatureFlagPathParts;

const adminAdminControllerToggleBankFeatureFlagQuery = t.exact(t.type({
  "bankId": NumberFromString
}));
export type AdminAdminControllerToggleBankFeatureFlagParams = Describe<typeof adminAdminControllerToggleBankFeatureFlagPath._A & typeof adminAdminControllerToggleBankFeatureFlagQuery._A>;

export type AdminAdminControllerToggleBankFeatureFlagRoute<A = {}> = { match: Match<AdminAdminControllerToggleBankFeatureFlagParams & A>; pathParts: AdminAdminControllerToggleBankFeatureFlagPathParts };

export function adminAdminControllerToggleBankFeatureFlagRoute(): AdminAdminControllerToggleBankFeatureFlagRoute;
export function adminAdminControllerToggleBankFeatureFlagRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerToggleBankFeatureFlagParams>): AdminAdminControllerToggleBankFeatureFlagRoute<t.TypeOf<A>>;
export function adminAdminControllerToggleBankFeatureFlagRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerToggleBankFeatureFlagParams>) {
  return {
    match: (q ? adminAdminControllerToggleBankFeatureFlagPath.then(query(t.intersection([adminAdminControllerToggleBankFeatureFlagQuery, q]))) : adminAdminControllerToggleBankFeatureFlagPath.then(query(adminAdminControllerToggleBankFeatureFlagQuery))).then(end),
    pathParts: adminAdminControllerToggleBankFeatureFlagPathParts,
  };
}

export const adminAdminControllerToggleBankFeatureFlag = (p: AdminAdminControllerToggleBankFeatureFlagParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminControllerToggleBankFeatureFlagRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminControllerToggleIssuerFeatureFlagPath = lit("admin").then(lit("issuer")).then(lit("toggle-feature-flag"));
const adminAdminControllerToggleIssuerFeatureFlagPathParts = ["admin", "issuer", "toggle-feature-flag"] as const;
export type AdminAdminControllerToggleIssuerFeatureFlagPathParts = typeof adminAdminControllerToggleIssuerFeatureFlagPathParts;

const adminAdminControllerToggleIssuerFeatureFlagQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminAdminControllerToggleIssuerFeatureFlagParams = Describe<typeof adminAdminControllerToggleIssuerFeatureFlagPath._A & typeof adminAdminControllerToggleIssuerFeatureFlagQuery._A>;

export type AdminAdminControllerToggleIssuerFeatureFlagRoute<A = {}> = { match: Match<AdminAdminControllerToggleIssuerFeatureFlagParams & A>; pathParts: AdminAdminControllerToggleIssuerFeatureFlagPathParts };

export function adminAdminControllerToggleIssuerFeatureFlagRoute(): AdminAdminControllerToggleIssuerFeatureFlagRoute;
export function adminAdminControllerToggleIssuerFeatureFlagRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminControllerToggleIssuerFeatureFlagParams>): AdminAdminControllerToggleIssuerFeatureFlagRoute<t.TypeOf<A>>;
export function adminAdminControllerToggleIssuerFeatureFlagRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminControllerToggleIssuerFeatureFlagParams>) {
  return {
    match: (q ? adminAdminControllerToggleIssuerFeatureFlagPath.then(query(t.intersection([adminAdminControllerToggleIssuerFeatureFlagQuery, q]))) : adminAdminControllerToggleIssuerFeatureFlagPath.then(query(adminAdminControllerToggleIssuerFeatureFlagQuery))).then(end),
    pathParts: adminAdminControllerToggleIssuerFeatureFlagPathParts,
  };
}

export const adminAdminControllerToggleIssuerFeatureFlag = (p: AdminAdminControllerToggleIssuerFeatureFlagParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminControllerToggleIssuerFeatureFlagRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminCronJobsControllerIndexPath = lit("admin").then(lit("cron-jobs"));
const adminAdminCronJobsControllerIndexPathParts = ["admin", "cron-jobs"] as const;
export type AdminAdminCronJobsControllerIndexPathParts = typeof adminAdminCronJobsControllerIndexPathParts;

const adminAdminCronJobsControllerIndexQuery = t.strict({});
export type AdminAdminCronJobsControllerIndexParams = Describe<typeof adminAdminCronJobsControllerIndexPath._A & typeof adminAdminCronJobsControllerIndexQuery._A>;

export type AdminAdminCronJobsControllerIndexRoute<A = {}> = { match: Match<AdminAdminCronJobsControllerIndexParams & A>; pathParts: AdminAdminCronJobsControllerIndexPathParts };

export function adminAdminCronJobsControllerIndexRoute(): AdminAdminCronJobsControllerIndexRoute;
export function adminAdminCronJobsControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminCronJobsControllerIndexParams>): AdminAdminCronJobsControllerIndexRoute<t.TypeOf<A>>;
export function adminAdminCronJobsControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminCronJobsControllerIndexParams>) {
  return {
    match: (q ? adminAdminCronJobsControllerIndexPath.then(query(t.intersection([adminAdminCronJobsControllerIndexQuery, q]))) : adminAdminCronJobsControllerIndexPath.then(query(adminAdminCronJobsControllerIndexQuery))).then(end),
    pathParts: adminAdminCronJobsControllerIndexPathParts,
  };
}

export const adminAdminCronJobsControllerIndex = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminCronJobsControllerIndexRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminCronJobsControllerPausePath = lit("admin").then(lit("cron-jobs")).then(lit("pause")).then(str("name"));
const adminAdminCronJobsControllerPausePathParts = ["admin", "cron-jobs", "pause", "name"] as const;
export type AdminAdminCronJobsControllerPausePathParts = typeof adminAdminCronJobsControllerPausePathParts;

const adminAdminCronJobsControllerPauseQuery = t.strict({});
export type AdminAdminCronJobsControllerPauseParams = Describe<typeof adminAdminCronJobsControllerPausePath._A & typeof adminAdminCronJobsControllerPauseQuery._A>;

export type AdminAdminCronJobsControllerPauseRoute<A = {}> = { match: Match<AdminAdminCronJobsControllerPauseParams & A>; pathParts: AdminAdminCronJobsControllerPausePathParts };

export function adminAdminCronJobsControllerPauseRoute(): AdminAdminCronJobsControllerPauseRoute;
export function adminAdminCronJobsControllerPauseRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminCronJobsControllerPauseParams>): AdminAdminCronJobsControllerPauseRoute<t.TypeOf<A>>;
export function adminAdminCronJobsControllerPauseRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminCronJobsControllerPauseParams>) {
  return {
    match: (q ? adminAdminCronJobsControllerPausePath.then(query(t.intersection([adminAdminCronJobsControllerPauseQuery, q]))) : adminAdminCronJobsControllerPausePath.then(query(adminAdminCronJobsControllerPauseQuery))).then(end),
    pathParts: adminAdminCronJobsControllerPausePathParts,
  };
}

export const adminAdminCronJobsControllerPause = (p: AdminAdminCronJobsControllerPauseParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminCronJobsControllerPauseRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminCronJobsControllerResumePath = lit("admin").then(lit("cron-jobs")).then(lit("resume")).then(str("name"));
const adminAdminCronJobsControllerResumePathParts = ["admin", "cron-jobs", "resume", "name"] as const;
export type AdminAdminCronJobsControllerResumePathParts = typeof adminAdminCronJobsControllerResumePathParts;

const adminAdminCronJobsControllerResumeQuery = t.strict({});
export type AdminAdminCronJobsControllerResumeParams = Describe<typeof adminAdminCronJobsControllerResumePath._A & typeof adminAdminCronJobsControllerResumeQuery._A>;

export type AdminAdminCronJobsControllerResumeRoute<A = {}> = { match: Match<AdminAdminCronJobsControllerResumeParams & A>; pathParts: AdminAdminCronJobsControllerResumePathParts };

export function adminAdminCronJobsControllerResumeRoute(): AdminAdminCronJobsControllerResumeRoute;
export function adminAdminCronJobsControllerResumeRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminCronJobsControllerResumeParams>): AdminAdminCronJobsControllerResumeRoute<t.TypeOf<A>>;
export function adminAdminCronJobsControllerResumeRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminCronJobsControllerResumeParams>) {
  return {
    match: (q ? adminAdminCronJobsControllerResumePath.then(query(t.intersection([adminAdminCronJobsControllerResumeQuery, q]))) : adminAdminCronJobsControllerResumePath.then(query(adminAdminCronJobsControllerResumeQuery))).then(end),
    pathParts: adminAdminCronJobsControllerResumePathParts,
  };
}

export const adminAdminCronJobsControllerResume = (p: AdminAdminCronJobsControllerResumeParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminCronJobsControllerResumeRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminCronJobsControllerRunPath = lit("admin").then(lit("cron-jobs")).then(lit("run")).then(str("name"));
const adminAdminCronJobsControllerRunPathParts = ["admin", "cron-jobs", "run", "name"] as const;
export type AdminAdminCronJobsControllerRunPathParts = typeof adminAdminCronJobsControllerRunPathParts;

const adminAdminCronJobsControllerRunQuery = t.strict({});
export type AdminAdminCronJobsControllerRunParams = Describe<typeof adminAdminCronJobsControllerRunPath._A & typeof adminAdminCronJobsControllerRunQuery._A>;

export type AdminAdminCronJobsControllerRunRoute<A = {}> = { match: Match<AdminAdminCronJobsControllerRunParams & A>; pathParts: AdminAdminCronJobsControllerRunPathParts };

export function adminAdminCronJobsControllerRunRoute(): AdminAdminCronJobsControllerRunRoute;
export function adminAdminCronJobsControllerRunRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminCronJobsControllerRunParams>): AdminAdminCronJobsControllerRunRoute<t.TypeOf<A>>;
export function adminAdminCronJobsControllerRunRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminCronJobsControllerRunParams>) {
  return {
    match: (q ? adminAdminCronJobsControllerRunPath.then(query(t.intersection([adminAdminCronJobsControllerRunQuery, q]))) : adminAdminCronJobsControllerRunPath.then(query(adminAdminCronJobsControllerRunQuery))).then(end),
    pathParts: adminAdminCronJobsControllerRunPathParts,
  };
}

export const adminAdminCronJobsControllerRun = (p: AdminAdminCronJobsControllerRunParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminCronJobsControllerRunRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminEmailsControllerIndexPath = lit("admin").then(lit("emails"));
const adminAdminEmailsControllerIndexPathParts = ["admin", "emails"] as const;
export type AdminAdminEmailsControllerIndexPathParts = typeof adminAdminEmailsControllerIndexPathParts;

const adminAdminEmailsControllerIndexQuery = t.strict({});
export type AdminAdminEmailsControllerIndexParams = Describe<typeof adminAdminEmailsControllerIndexPath._A & typeof adminAdminEmailsControllerIndexQuery._A>;

export type AdminAdminEmailsControllerIndexRoute<A = {}> = { match: Match<AdminAdminEmailsControllerIndexParams & A>; pathParts: AdminAdminEmailsControllerIndexPathParts };

export function adminAdminEmailsControllerIndexRoute(): AdminAdminEmailsControllerIndexRoute;
export function adminAdminEmailsControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminEmailsControllerIndexParams>): AdminAdminEmailsControllerIndexRoute<t.TypeOf<A>>;
export function adminAdminEmailsControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminEmailsControllerIndexParams>) {
  return {
    match: (q ? adminAdminEmailsControllerIndexPath.then(query(t.intersection([adminAdminEmailsControllerIndexQuery, q]))) : adminAdminEmailsControllerIndexPath.then(query(adminAdminEmailsControllerIndexQuery))).then(end),
    pathParts: adminAdminEmailsControllerIndexPathParts,
  };
}

export const adminAdminEmailsControllerIndex = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminEmailsControllerIndexRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminEmailsControllerDataTablePath = lit("admin").then(lit("emails")).then(lit("data-table"));
const adminAdminEmailsControllerDataTablePathParts = ["admin", "emails", "data-table"] as const;
export type AdminAdminEmailsControllerDataTablePathParts = typeof adminAdminEmailsControllerDataTablePathParts;

const adminAdminEmailsControllerDataTableQuery = t.strict({});
export type AdminAdminEmailsControllerDataTableParams = Describe<typeof adminAdminEmailsControllerDataTablePath._A & typeof adminAdminEmailsControllerDataTableQuery._A>;

export type AdminAdminEmailsControllerDataTableRoute<A = {}> = { match: Match<AdminAdminEmailsControllerDataTableParams & A>; pathParts: AdminAdminEmailsControllerDataTablePathParts };

export function adminAdminEmailsControllerDataTableRoute(): AdminAdminEmailsControllerDataTableRoute;
export function adminAdminEmailsControllerDataTableRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminEmailsControllerDataTableParams>): AdminAdminEmailsControllerDataTableRoute<t.TypeOf<A>>;
export function adminAdminEmailsControllerDataTableRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminEmailsControllerDataTableParams>) {
  return {
    match: (q ? adminAdminEmailsControllerDataTablePath.then(query(t.intersection([adminAdminEmailsControllerDataTableQuery, q]))) : adminAdminEmailsControllerDataTablePath.then(query(adminAdminEmailsControllerDataTableQuery))).then(end),
    pathParts: adminAdminEmailsControllerDataTablePathParts,
  };
}

export const adminAdminEmailsControllerDataTable = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminEmailsControllerDataTableRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminEmailsControllerViewEmailPath = lit("admin").then(lit("emails")).then(int("emailId"));
const adminAdminEmailsControllerViewEmailPathParts = ["admin", "emails", "emailId"] as const;
export type AdminAdminEmailsControllerViewEmailPathParts = typeof adminAdminEmailsControllerViewEmailPathParts;

const adminAdminEmailsControllerViewEmailQuery = t.strict({});
export type AdminAdminEmailsControllerViewEmailParams = Describe<typeof adminAdminEmailsControllerViewEmailPath._A & typeof adminAdminEmailsControllerViewEmailQuery._A>;

export type AdminAdminEmailsControllerViewEmailRoute<A = {}> = { match: Match<AdminAdminEmailsControllerViewEmailParams & A>; pathParts: AdminAdminEmailsControllerViewEmailPathParts };

export function adminAdminEmailsControllerViewEmailRoute(): AdminAdminEmailsControllerViewEmailRoute;
export function adminAdminEmailsControllerViewEmailRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminEmailsControllerViewEmailParams>): AdminAdminEmailsControllerViewEmailRoute<t.TypeOf<A>>;
export function adminAdminEmailsControllerViewEmailRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminEmailsControllerViewEmailParams>) {
  return {
    match: (q ? adminAdminEmailsControllerViewEmailPath.then(query(t.intersection([adminAdminEmailsControllerViewEmailQuery, q]))) : adminAdminEmailsControllerViewEmailPath.then(query(adminAdminEmailsControllerViewEmailQuery))).then(end),
    pathParts: adminAdminEmailsControllerViewEmailPathParts,
  };
}

export const adminAdminEmailsControllerViewEmail = (p: AdminAdminEmailsControllerViewEmailParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminEmailsControllerViewEmailRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminOfferingsControllerIndexPath = lit("admin").then(lit("offerings"));
const adminAdminOfferingsControllerIndexPathParts = ["admin", "offerings"] as const;
export type AdminAdminOfferingsControllerIndexPathParts = typeof adminAdminOfferingsControllerIndexPathParts;

const adminAdminOfferingsControllerIndexQuery = t.strict({});
export type AdminAdminOfferingsControllerIndexParams = Describe<typeof adminAdminOfferingsControllerIndexPath._A & typeof adminAdminOfferingsControllerIndexQuery._A>;

export type AdminAdminOfferingsControllerIndexRoute<A = {}> = { match: Match<AdminAdminOfferingsControllerIndexParams & A>; pathParts: AdminAdminOfferingsControllerIndexPathParts };

export function adminAdminOfferingsControllerIndexRoute(): AdminAdminOfferingsControllerIndexRoute;
export function adminAdminOfferingsControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminOfferingsControllerIndexParams>): AdminAdminOfferingsControllerIndexRoute<t.TypeOf<A>>;
export function adminAdminOfferingsControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminOfferingsControllerIndexParams>) {
  return {
    match: (q ? adminAdminOfferingsControllerIndexPath.then(query(t.intersection([adminAdminOfferingsControllerIndexQuery, q]))) : adminAdminOfferingsControllerIndexPath.then(query(adminAdminOfferingsControllerIndexQuery))).then(end),
    pathParts: adminAdminOfferingsControllerIndexPathParts,
  };
}

export const adminAdminOfferingsControllerIndex = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminOfferingsControllerIndexRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminOfferingsControllerDeleteOfferingOverridePath = lit("admin").then(lit("offerings")).then(lit("delete"));
const adminAdminOfferingsControllerDeleteOfferingOverridePathParts = ["admin", "offerings", "delete"] as const;
export type AdminAdminOfferingsControllerDeleteOfferingOverridePathParts = typeof adminAdminOfferingsControllerDeleteOfferingOverridePathParts;

const adminAdminOfferingsControllerDeleteOfferingOverrideQuery = t.exact(t.type({
  "id": NumberFromString
}));
export type AdminAdminOfferingsControllerDeleteOfferingOverrideParams = Describe<typeof adminAdminOfferingsControllerDeleteOfferingOverridePath._A & typeof adminAdminOfferingsControllerDeleteOfferingOverrideQuery._A>;

export type AdminAdminOfferingsControllerDeleteOfferingOverrideRoute<A = {}> = { match: Match<AdminAdminOfferingsControllerDeleteOfferingOverrideParams & A>; pathParts: AdminAdminOfferingsControllerDeleteOfferingOverridePathParts };

export function adminAdminOfferingsControllerDeleteOfferingOverrideRoute(): AdminAdminOfferingsControllerDeleteOfferingOverrideRoute;
export function adminAdminOfferingsControllerDeleteOfferingOverrideRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminOfferingsControllerDeleteOfferingOverrideParams>): AdminAdminOfferingsControllerDeleteOfferingOverrideRoute<t.TypeOf<A>>;
export function adminAdminOfferingsControllerDeleteOfferingOverrideRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminOfferingsControllerDeleteOfferingOverrideParams>) {
  return {
    match: (q ? adminAdminOfferingsControllerDeleteOfferingOverridePath.then(query(t.intersection([adminAdminOfferingsControllerDeleteOfferingOverrideQuery, q]))) : adminAdminOfferingsControllerDeleteOfferingOverridePath.then(query(adminAdminOfferingsControllerDeleteOfferingOverrideQuery))).then(end),
    pathParts: adminAdminOfferingsControllerDeleteOfferingOverridePathParts,
  };
}

export const adminAdminOfferingsControllerDeleteOfferingOverride = (p: AdminAdminOfferingsControllerDeleteOfferingOverrideParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminOfferingsControllerDeleteOfferingOverrideRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminOfferingsControllerPreferOfferingOverridePath = lit("admin").then(lit("offerings")).then(lit("prefer"));
const adminAdminOfferingsControllerPreferOfferingOverridePathParts = ["admin", "offerings", "prefer"] as const;
export type AdminAdminOfferingsControllerPreferOfferingOverridePathParts = typeof adminAdminOfferingsControllerPreferOfferingOverridePathParts;

const adminAdminOfferingsControllerPreferOfferingOverrideQuery = t.strict({});
export type AdminAdminOfferingsControllerPreferOfferingOverrideParams = Describe<typeof adminAdminOfferingsControllerPreferOfferingOverridePath._A & typeof adminAdminOfferingsControllerPreferOfferingOverrideQuery._A>;

export type AdminAdminOfferingsControllerPreferOfferingOverrideRoute<A = {}> = { match: Match<AdminAdminOfferingsControllerPreferOfferingOverrideParams & A>; pathParts: AdminAdminOfferingsControllerPreferOfferingOverridePathParts };

export function adminAdminOfferingsControllerPreferOfferingOverrideRoute(): AdminAdminOfferingsControllerPreferOfferingOverrideRoute;
export function adminAdminOfferingsControllerPreferOfferingOverrideRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminOfferingsControllerPreferOfferingOverrideParams>): AdminAdminOfferingsControllerPreferOfferingOverrideRoute<t.TypeOf<A>>;
export function adminAdminOfferingsControllerPreferOfferingOverrideRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminOfferingsControllerPreferOfferingOverrideParams>) {
  return {
    match: (q ? adminAdminOfferingsControllerPreferOfferingOverridePath.then(query(t.intersection([adminAdminOfferingsControllerPreferOfferingOverrideQuery, q]))) : adminAdminOfferingsControllerPreferOfferingOverridePath.then(query(adminAdminOfferingsControllerPreferOfferingOverrideQuery))).then(end),
    pathParts: adminAdminOfferingsControllerPreferOfferingOverridePathParts,
  };
}

export const adminAdminOfferingsControllerPreferOfferingOverride = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminOfferingsControllerPreferOfferingOverrideRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminPortalSettingsControllerPortalSettingsPath = lit("admin").then(lit("portal-settings"));
const adminAdminPortalSettingsControllerPortalSettingsPathParts = ["admin", "portal-settings"] as const;
export type AdminAdminPortalSettingsControllerPortalSettingsPathParts = typeof adminAdminPortalSettingsControllerPortalSettingsPathParts;

const adminAdminPortalSettingsControllerPortalSettingsQuery = t.exact(t.partial({
  "issuerId": NumberFromString
}));
export type AdminAdminPortalSettingsControllerPortalSettingsParamsRaw = Describe<typeof adminAdminPortalSettingsControllerPortalSettingsPath._A & typeof adminAdminPortalSettingsControllerPortalSettingsQuery._A>;
export type AdminAdminPortalSettingsControllerPortalSettingsParams = Describe<NullableToOptionalFields<AdminAdminPortalSettingsControllerPortalSettingsParamsRaw>>;

export type AdminAdminPortalSettingsControllerPortalSettingsRoute<A = {}> = { match: Match<AdminAdminPortalSettingsControllerPortalSettingsParamsRaw & A>; pathParts: AdminAdminPortalSettingsControllerPortalSettingsPathParts };

export function adminAdminPortalSettingsControllerPortalSettingsRoute(): AdminAdminPortalSettingsControllerPortalSettingsRoute;
export function adminAdminPortalSettingsControllerPortalSettingsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminPortalSettingsControllerPortalSettingsParamsRaw>): AdminAdminPortalSettingsControllerPortalSettingsRoute<t.TypeOf<A>>;
export function adminAdminPortalSettingsControllerPortalSettingsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminPortalSettingsControllerPortalSettingsParamsRaw>) {
  return {
    match: (q ? adminAdminPortalSettingsControllerPortalSettingsPath.then(query(t.intersection([adminAdminPortalSettingsControllerPortalSettingsQuery, q]))) : adminAdminPortalSettingsControllerPortalSettingsPath.then(query(adminAdminPortalSettingsControllerPortalSettingsQuery))).then(end),
    pathParts: adminAdminPortalSettingsControllerPortalSettingsPathParts,
  };
}

export const adminAdminPortalSettingsControllerPortalSettings = (p: AdminAdminPortalSettingsControllerPortalSettingsParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminPortalSettingsControllerPortalSettingsRoute().match.formatter.run(Route.empty, optionalFieldsToNullable(p)).toString(true));

const adminAdminPortalSettingsControllerPostPortalSettingsPath = lit("admin").then(lit("portal-settings"));
const adminAdminPortalSettingsControllerPostPortalSettingsPathParts = ["admin", "portal-settings"] as const;
export type AdminAdminPortalSettingsControllerPostPortalSettingsPathParts = typeof adminAdminPortalSettingsControllerPostPortalSettingsPathParts;

const adminAdminPortalSettingsControllerPostPortalSettingsQuery = t.strict({});
export type AdminAdminPortalSettingsControllerPostPortalSettingsParams = Describe<typeof adminAdminPortalSettingsControllerPostPortalSettingsPath._A & typeof adminAdminPortalSettingsControllerPostPortalSettingsQuery._A>;

export type AdminAdminPortalSettingsControllerPostPortalSettingsRoute<A = {}> = { match: Match<AdminAdminPortalSettingsControllerPostPortalSettingsParams & A>; pathParts: AdminAdminPortalSettingsControllerPostPortalSettingsPathParts };

export function adminAdminPortalSettingsControllerPostPortalSettingsRoute(): AdminAdminPortalSettingsControllerPostPortalSettingsRoute;
export function adminAdminPortalSettingsControllerPostPortalSettingsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminPortalSettingsControllerPostPortalSettingsParams>): AdminAdminPortalSettingsControllerPostPortalSettingsRoute<t.TypeOf<A>>;
export function adminAdminPortalSettingsControllerPostPortalSettingsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminPortalSettingsControllerPostPortalSettingsParams>) {
  return {
    match: (q ? adminAdminPortalSettingsControllerPostPortalSettingsPath.then(query(t.intersection([adminAdminPortalSettingsControllerPostPortalSettingsQuery, q]))) : adminAdminPortalSettingsControllerPostPortalSettingsPath.then(query(adminAdminPortalSettingsControllerPostPortalSettingsQuery))).then(end),
    pathParts: adminAdminPortalSettingsControllerPostPortalSettingsPathParts,
  };
}

export const adminAdminPortalSettingsControllerPostPortalSettings = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminPortalSettingsControllerPostPortalSettingsRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminPortalSettingsControllerPostPortalSettingsIssuerPath = lit("admin").then(lit("portal-settings")).then(type("issuerId", issuerIdC));
const adminAdminPortalSettingsControllerPostPortalSettingsIssuerPathParts = [
  "admin", "portal-settings", "issuerId"
] as const;
export type AdminAdminPortalSettingsControllerPostPortalSettingsIssuerPathParts = typeof adminAdminPortalSettingsControllerPostPortalSettingsIssuerPathParts;

const adminAdminPortalSettingsControllerPostPortalSettingsIssuerQuery = t.strict({});
export type AdminAdminPortalSettingsControllerPostPortalSettingsIssuerParams = Describe<typeof adminAdminPortalSettingsControllerPostPortalSettingsIssuerPath._A & typeof adminAdminPortalSettingsControllerPostPortalSettingsIssuerQuery._A>;

export type AdminAdminPortalSettingsControllerPostPortalSettingsIssuerRoute<A = {}> = { match: Match<AdminAdminPortalSettingsControllerPostPortalSettingsIssuerParams & A>; pathParts: AdminAdminPortalSettingsControllerPostPortalSettingsIssuerPathParts };

export function adminAdminPortalSettingsControllerPostPortalSettingsIssuerRoute(): AdminAdminPortalSettingsControllerPostPortalSettingsIssuerRoute;
export function adminAdminPortalSettingsControllerPostPortalSettingsIssuerRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminPortalSettingsControllerPostPortalSettingsIssuerParams>): AdminAdminPortalSettingsControllerPostPortalSettingsIssuerRoute<t.TypeOf<A>>;
export function adminAdminPortalSettingsControllerPostPortalSettingsIssuerRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminPortalSettingsControllerPostPortalSettingsIssuerParams>) {
  return {
    match: (q ? adminAdminPortalSettingsControllerPostPortalSettingsIssuerPath.then(query(t.intersection([adminAdminPortalSettingsControllerPostPortalSettingsIssuerQuery, q]))) : adminAdminPortalSettingsControllerPostPortalSettingsIssuerPath.then(query(adminAdminPortalSettingsControllerPostPortalSettingsIssuerQuery))).then(end),
    pathParts: adminAdminPortalSettingsControllerPostPortalSettingsIssuerPathParts,
  };
}

export const adminAdminPortalSettingsControllerPostPortalSettingsIssuer = (p: AdminAdminPortalSettingsControllerPostPortalSettingsIssuerParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminPortalSettingsControllerPostPortalSettingsIssuerRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminPortalSettingsControllerDeletePortalSettingsPath = lit("admin").then(lit("delete-portal-settings"));
const adminAdminPortalSettingsControllerDeletePortalSettingsPathParts = ["admin", "delete-portal-settings"] as const;
export type AdminAdminPortalSettingsControllerDeletePortalSettingsPathParts = typeof adminAdminPortalSettingsControllerDeletePortalSettingsPathParts;

const adminAdminPortalSettingsControllerDeletePortalSettingsQuery = t.strict({});
export type AdminAdminPortalSettingsControllerDeletePortalSettingsParams = Describe<typeof adminAdminPortalSettingsControllerDeletePortalSettingsPath._A & typeof adminAdminPortalSettingsControllerDeletePortalSettingsQuery._A>;

export type AdminAdminPortalSettingsControllerDeletePortalSettingsRoute<A = {}> = { match: Match<AdminAdminPortalSettingsControllerDeletePortalSettingsParams & A>; pathParts: AdminAdminPortalSettingsControllerDeletePortalSettingsPathParts };

export function adminAdminPortalSettingsControllerDeletePortalSettingsRoute(): AdminAdminPortalSettingsControllerDeletePortalSettingsRoute;
export function adminAdminPortalSettingsControllerDeletePortalSettingsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminPortalSettingsControllerDeletePortalSettingsParams>): AdminAdminPortalSettingsControllerDeletePortalSettingsRoute<t.TypeOf<A>>;
export function adminAdminPortalSettingsControllerDeletePortalSettingsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminPortalSettingsControllerDeletePortalSettingsParams>) {
  return {
    match: (q ? adminAdminPortalSettingsControllerDeletePortalSettingsPath.then(query(t.intersection([adminAdminPortalSettingsControllerDeletePortalSettingsQuery, q]))) : adminAdminPortalSettingsControllerDeletePortalSettingsPath.then(query(adminAdminPortalSettingsControllerDeletePortalSettingsQuery))).then(end),
    pathParts: adminAdminPortalSettingsControllerDeletePortalSettingsPathParts,
  };
}

export const adminAdminPortalSettingsControllerDeletePortalSettings = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminPortalSettingsControllerDeletePortalSettingsRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminPortalSettingsControllerDeletePortalSettingsIssuerPath = lit("admin").then(lit("delete-portal-settings")).then(type("issuerId", issuerIdC));
const adminAdminPortalSettingsControllerDeletePortalSettingsIssuerPathParts = [
  "admin", "delete-portal-settings", "issuerId"
] as const;
export type AdminAdminPortalSettingsControllerDeletePortalSettingsIssuerPathParts = typeof adminAdminPortalSettingsControllerDeletePortalSettingsIssuerPathParts;

const adminAdminPortalSettingsControllerDeletePortalSettingsIssuerQuery = t.strict({});
export type AdminAdminPortalSettingsControllerDeletePortalSettingsIssuerParams = Describe<typeof adminAdminPortalSettingsControllerDeletePortalSettingsIssuerPath._A & typeof adminAdminPortalSettingsControllerDeletePortalSettingsIssuerQuery._A>;

export type AdminAdminPortalSettingsControllerDeletePortalSettingsIssuerRoute<A = {}> = { match: Match<AdminAdminPortalSettingsControllerDeletePortalSettingsIssuerParams & A>; pathParts: AdminAdminPortalSettingsControllerDeletePortalSettingsIssuerPathParts };

export function adminAdminPortalSettingsControllerDeletePortalSettingsIssuerRoute(): AdminAdminPortalSettingsControllerDeletePortalSettingsIssuerRoute;
export function adminAdminPortalSettingsControllerDeletePortalSettingsIssuerRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminPortalSettingsControllerDeletePortalSettingsIssuerParams>): AdminAdminPortalSettingsControllerDeletePortalSettingsIssuerRoute<t.TypeOf<A>>;
export function adminAdminPortalSettingsControllerDeletePortalSettingsIssuerRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminPortalSettingsControllerDeletePortalSettingsIssuerParams>) {
  return {
    match: (q ? adminAdminPortalSettingsControllerDeletePortalSettingsIssuerPath.then(query(t.intersection([adminAdminPortalSettingsControllerDeletePortalSettingsIssuerQuery, q]))) : adminAdminPortalSettingsControllerDeletePortalSettingsIssuerPath.then(query(adminAdminPortalSettingsControllerDeletePortalSettingsIssuerQuery))).then(end),
    pathParts: adminAdminPortalSettingsControllerDeletePortalSettingsIssuerPathParts,
  };
}

export const adminAdminPortalSettingsControllerDeletePortalSettingsIssuer = (p: AdminAdminPortalSettingsControllerDeletePortalSettingsIssuerParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminPortalSettingsControllerDeletePortalSettingsIssuerRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminUsersControllerIndexPath = lit("admin").then(lit("users"));
const adminAdminUsersControllerIndexPathParts = ["admin", "users"] as const;
export type AdminAdminUsersControllerIndexPathParts = typeof adminAdminUsersControllerIndexPathParts;

const adminAdminUsersControllerIndexQuery = t.strict({});
export type AdminAdminUsersControllerIndexParams = Describe<typeof adminAdminUsersControllerIndexPath._A & typeof adminAdminUsersControllerIndexQuery._A>;

export type AdminAdminUsersControllerIndexRoute<A = {}> = { match: Match<AdminAdminUsersControllerIndexParams & A>; pathParts: AdminAdminUsersControllerIndexPathParts };

export function adminAdminUsersControllerIndexRoute(): AdminAdminUsersControllerIndexRoute;
export function adminAdminUsersControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminUsersControllerIndexParams>): AdminAdminUsersControllerIndexRoute<t.TypeOf<A>>;
export function adminAdminUsersControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminUsersControllerIndexParams>) {
  return {
    match: (q ? adminAdminUsersControllerIndexPath.then(query(t.intersection([adminAdminUsersControllerIndexQuery, q]))) : adminAdminUsersControllerIndexPath.then(query(adminAdminUsersControllerIndexQuery))).then(end),
    pathParts: adminAdminUsersControllerIndexPathParts,
  };
}

export const adminAdminUsersControllerIndex = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminUsersControllerIndexRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminUsersControllerEditPath = lit("admin").then(lit("users")).then(int("userId"));
const adminAdminUsersControllerEditPathParts = ["admin", "users", "userId"] as const;
export type AdminAdminUsersControllerEditPathParts = typeof adminAdminUsersControllerEditPathParts;

const adminAdminUsersControllerEditQuery = t.strict({});
export type AdminAdminUsersControllerEditParams = Describe<typeof adminAdminUsersControllerEditPath._A & typeof adminAdminUsersControllerEditQuery._A>;

export type AdminAdminUsersControllerEditRoute<A = {}> = { match: Match<AdminAdminUsersControllerEditParams & A>; pathParts: AdminAdminUsersControllerEditPathParts };

export function adminAdminUsersControllerEditRoute(): AdminAdminUsersControllerEditRoute;
export function adminAdminUsersControllerEditRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminUsersControllerEditParams>): AdminAdminUsersControllerEditRoute<t.TypeOf<A>>;
export function adminAdminUsersControllerEditRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminUsersControllerEditParams>) {
  return {
    match: (q ? adminAdminUsersControllerEditPath.then(query(t.intersection([adminAdminUsersControllerEditQuery, q]))) : adminAdminUsersControllerEditPath.then(query(adminAdminUsersControllerEditQuery))).then(end),
    pathParts: adminAdminUsersControllerEditPathParts,
  };
}

export const adminAdminUsersControllerEdit = (p: AdminAdminUsersControllerEditParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminUsersControllerEditRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminUsersControllerDeletePath = lit("admin").then(lit("users")).then(int("userId")).then(lit("delete"));
const adminAdminUsersControllerDeletePathParts = ["admin", "users", "userId", "delete"] as const;
export type AdminAdminUsersControllerDeletePathParts = typeof adminAdminUsersControllerDeletePathParts;

const adminAdminUsersControllerDeleteQuery = t.strict({});
export type AdminAdminUsersControllerDeleteParams = Describe<typeof adminAdminUsersControllerDeletePath._A & typeof adminAdminUsersControllerDeleteQuery._A>;

export type AdminAdminUsersControllerDeleteRoute<A = {}> = { match: Match<AdminAdminUsersControllerDeleteParams & A>; pathParts: AdminAdminUsersControllerDeletePathParts };

export function adminAdminUsersControllerDeleteRoute(): AdminAdminUsersControllerDeleteRoute;
export function adminAdminUsersControllerDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminUsersControllerDeleteParams>): AdminAdminUsersControllerDeleteRoute<t.TypeOf<A>>;
export function adminAdminUsersControllerDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminUsersControllerDeleteParams>) {
  return {
    match: (q ? adminAdminUsersControllerDeletePath.then(query(t.intersection([adminAdminUsersControllerDeleteQuery, q]))) : adminAdminUsersControllerDeletePath.then(query(adminAdminUsersControllerDeleteQuery))).then(end),
    pathParts: adminAdminUsersControllerDeletePathParts,
  };
}

export const adminAdminUsersControllerDelete = (p: AdminAdminUsersControllerDeleteParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminUsersControllerDeleteRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminUsersControllerRestorePath = lit("admin").then(lit("users")).then(int("userId")).then(lit("restore"));
const adminAdminUsersControllerRestorePathParts = ["admin", "users", "userId", "restore"] as const;
export type AdminAdminUsersControllerRestorePathParts = typeof adminAdminUsersControllerRestorePathParts;

const adminAdminUsersControllerRestoreQuery = t.strict({});
export type AdminAdminUsersControllerRestoreParams = Describe<typeof adminAdminUsersControllerRestorePath._A & typeof adminAdminUsersControllerRestoreQuery._A>;

export type AdminAdminUsersControllerRestoreRoute<A = {}> = { match: Match<AdminAdminUsersControllerRestoreParams & A>; pathParts: AdminAdminUsersControllerRestorePathParts };

export function adminAdminUsersControllerRestoreRoute(): AdminAdminUsersControllerRestoreRoute;
export function adminAdminUsersControllerRestoreRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminUsersControllerRestoreParams>): AdminAdminUsersControllerRestoreRoute<t.TypeOf<A>>;
export function adminAdminUsersControllerRestoreRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminUsersControllerRestoreParams>) {
  return {
    match: (q ? adminAdminUsersControllerRestorePath.then(query(t.intersection([adminAdminUsersControllerRestoreQuery, q]))) : adminAdminUsersControllerRestorePath.then(query(adminAdminUsersControllerRestoreQuery))).then(end),
    pathParts: adminAdminUsersControllerRestorePathParts,
  };
}

export const adminAdminUsersControllerRestore = (p: AdminAdminUsersControllerRestoreParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminUsersControllerRestoreRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminUsersControllerDataTablePath = lit("admin").then(lit("users")).then(lit("data-table"));
const adminAdminUsersControllerDataTablePathParts = ["admin", "users", "data-table"] as const;
export type AdminAdminUsersControllerDataTablePathParts = typeof adminAdminUsersControllerDataTablePathParts;

const adminAdminUsersControllerDataTableQuery = t.strict({});
export type AdminAdminUsersControllerDataTableParams = Describe<typeof adminAdminUsersControllerDataTablePath._A & typeof adminAdminUsersControllerDataTableQuery._A>;

export type AdminAdminUsersControllerDataTableRoute<A = {}> = { match: Match<AdminAdminUsersControllerDataTableParams & A>; pathParts: AdminAdminUsersControllerDataTablePathParts };

export function adminAdminUsersControllerDataTableRoute(): AdminAdminUsersControllerDataTableRoute;
export function adminAdminUsersControllerDataTableRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminUsersControllerDataTableParams>): AdminAdminUsersControllerDataTableRoute<t.TypeOf<A>>;
export function adminAdminUsersControllerDataTableRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminUsersControllerDataTableParams>) {
  return {
    match: (q ? adminAdminUsersControllerDataTablePath.then(query(t.intersection([adminAdminUsersControllerDataTableQuery, q]))) : adminAdminUsersControllerDataTablePath.then(query(adminAdminUsersControllerDataTableQuery))).then(end),
    pathParts: adminAdminUsersControllerDataTablePathParts,
  };
}

export const adminAdminUsersControllerDataTable = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminUsersControllerDataTableRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminUsersControllerAddUserSubscriptionsPath = lit("admin").then(lit("users")).then(int("userId")).then(lit("subscribe"));
const adminAdminUsersControllerAddUserSubscriptionsPathParts = ["admin", "users", "userId", "subscribe"] as const;
export type AdminAdminUsersControllerAddUserSubscriptionsPathParts = typeof adminAdminUsersControllerAddUserSubscriptionsPathParts;

const adminAdminUsersControllerAddUserSubscriptionsQuery = t.strict({});
export type AdminAdminUsersControllerAddUserSubscriptionsParams = Describe<typeof adminAdminUsersControllerAddUserSubscriptionsPath._A & typeof adminAdminUsersControllerAddUserSubscriptionsQuery._A>;

export type AdminAdminUsersControllerAddUserSubscriptionsRoute<A = {}> = { match: Match<AdminAdminUsersControllerAddUserSubscriptionsParams & A>; pathParts: AdminAdminUsersControllerAddUserSubscriptionsPathParts };

export function adminAdminUsersControllerAddUserSubscriptionsRoute(): AdminAdminUsersControllerAddUserSubscriptionsRoute;
export function adminAdminUsersControllerAddUserSubscriptionsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminUsersControllerAddUserSubscriptionsParams>): AdminAdminUsersControllerAddUserSubscriptionsRoute<t.TypeOf<A>>;
export function adminAdminUsersControllerAddUserSubscriptionsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminUsersControllerAddUserSubscriptionsParams>) {
  return {
    match: (q ? adminAdminUsersControllerAddUserSubscriptionsPath.then(query(t.intersection([adminAdminUsersControllerAddUserSubscriptionsQuery, q]))) : adminAdminUsersControllerAddUserSubscriptionsPath.then(query(adminAdminUsersControllerAddUserSubscriptionsQuery))).then(end),
    pathParts: adminAdminUsersControllerAddUserSubscriptionsPathParts,
  };
}

export const adminAdminUsersControllerAddUserSubscriptions = (p: AdminAdminUsersControllerAddUserSubscriptionsParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminUsersControllerAddUserSubscriptionsRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminUsersControllerSubscribeUserPath = lit("admin").then(lit("users")).then(int("userId")).then(lit("subscribe"));
const adminAdminUsersControllerSubscribeUserPathParts = ["admin", "users", "userId", "subscribe"] as const;
export type AdminAdminUsersControllerSubscribeUserPathParts = typeof adminAdminUsersControllerSubscribeUserPathParts;

const adminAdminUsersControllerSubscribeUserQuery = t.strict({});
export type AdminAdminUsersControllerSubscribeUserParams = Describe<typeof adminAdminUsersControllerSubscribeUserPath._A & typeof adminAdminUsersControllerSubscribeUserQuery._A>;

export type AdminAdminUsersControllerSubscribeUserRoute<A = {}> = { match: Match<AdminAdminUsersControllerSubscribeUserParams & A>; pathParts: AdminAdminUsersControllerSubscribeUserPathParts };

export function adminAdminUsersControllerSubscribeUserRoute(): AdminAdminUsersControllerSubscribeUserRoute;
export function adminAdminUsersControllerSubscribeUserRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminUsersControllerSubscribeUserParams>): AdminAdminUsersControllerSubscribeUserRoute<t.TypeOf<A>>;
export function adminAdminUsersControllerSubscribeUserRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminUsersControllerSubscribeUserParams>) {
  return {
    match: (q ? adminAdminUsersControllerSubscribeUserPath.then(query(t.intersection([adminAdminUsersControllerSubscribeUserQuery, q]))) : adminAdminUsersControllerSubscribeUserPath.then(query(adminAdminUsersControllerSubscribeUserQuery))).then(end),
    pathParts: adminAdminUsersControllerSubscribeUserPathParts,
  };
}

export const adminAdminUsersControllerSubscribeUser = (p: AdminAdminUsersControllerSubscribeUserParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminUsersControllerSubscribeUserRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminUsersControllerUnsubscribeUserPath = lit("admin").then(lit("users")).then(int("userId")).then(lit("unsubscribe"));
const adminAdminUsersControllerUnsubscribeUserPathParts = ["admin", "users", "userId", "unsubscribe"] as const;
export type AdminAdminUsersControllerUnsubscribeUserPathParts = typeof adminAdminUsersControllerUnsubscribeUserPathParts;

const adminAdminUsersControllerUnsubscribeUserQuery = t.strict({});
export type AdminAdminUsersControllerUnsubscribeUserParams = Describe<typeof adminAdminUsersControllerUnsubscribeUserPath._A & typeof adminAdminUsersControllerUnsubscribeUserQuery._A>;

export type AdminAdminUsersControllerUnsubscribeUserRoute<A = {}> = { match: Match<AdminAdminUsersControllerUnsubscribeUserParams & A>; pathParts: AdminAdminUsersControllerUnsubscribeUserPathParts };

export function adminAdminUsersControllerUnsubscribeUserRoute(): AdminAdminUsersControllerUnsubscribeUserRoute;
export function adminAdminUsersControllerUnsubscribeUserRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminUsersControllerUnsubscribeUserParams>): AdminAdminUsersControllerUnsubscribeUserRoute<t.TypeOf<A>>;
export function adminAdminUsersControllerUnsubscribeUserRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminUsersControllerUnsubscribeUserParams>) {
  return {
    match: (q ? adminAdminUsersControllerUnsubscribeUserPath.then(query(t.intersection([adminAdminUsersControllerUnsubscribeUserQuery, q]))) : adminAdminUsersControllerUnsubscribeUserPath.then(query(adminAdminUsersControllerUnsubscribeUserQuery))).then(end),
    pathParts: adminAdminUsersControllerUnsubscribeUserPathParts,
  };
}

export const adminAdminUsersControllerUnsubscribeUser = (p: AdminAdminUsersControllerUnsubscribeUserParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminUsersControllerUnsubscribeUserRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminUsersControllerUpdateUserPath = lit("admin").then(lit("users"));
const adminAdminUsersControllerUpdateUserPathParts = ["admin", "users"] as const;
export type AdminAdminUsersControllerUpdateUserPathParts = typeof adminAdminUsersControllerUpdateUserPathParts;

const adminAdminUsersControllerUpdateUserQuery = t.strict({});
export type AdminAdminUsersControllerUpdateUserParams = Describe<typeof adminAdminUsersControllerUpdateUserPath._A & typeof adminAdminUsersControllerUpdateUserQuery._A>;

export type AdminAdminUsersControllerUpdateUserRoute<A = {}> = { match: Match<AdminAdminUsersControllerUpdateUserParams & A>; pathParts: AdminAdminUsersControllerUpdateUserPathParts };

export function adminAdminUsersControllerUpdateUserRoute(): AdminAdminUsersControllerUpdateUserRoute;
export function adminAdminUsersControllerUpdateUserRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminUsersControllerUpdateUserParams>): AdminAdminUsersControllerUpdateUserRoute<t.TypeOf<A>>;
export function adminAdminUsersControllerUpdateUserRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminUsersControllerUpdateUserParams>) {
  return {
    match: (q ? adminAdminUsersControllerUpdateUserPath.then(query(t.intersection([adminAdminUsersControllerUpdateUserQuery, q]))) : adminAdminUsersControllerUpdateUserPath.then(query(adminAdminUsersControllerUpdateUserQuery))).then(end),
    pathParts: adminAdminUsersControllerUpdateUserPathParts,
  };
}

export const adminAdminUsersControllerUpdateUser = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminUsersControllerUpdateUserRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminUsersControllerLoginAsUserModalPath = lit("admin").then(lit("users")).then(lit("login-as")).then(int("userId"));
const adminAdminUsersControllerLoginAsUserModalPathParts = ["admin", "users", "login-as", "userId"] as const;
export type AdminAdminUsersControllerLoginAsUserModalPathParts = typeof adminAdminUsersControllerLoginAsUserModalPathParts;

const adminAdminUsersControllerLoginAsUserModalQuery = t.strict({});
export type AdminAdminUsersControllerLoginAsUserModalParams = Describe<typeof adminAdminUsersControllerLoginAsUserModalPath._A & typeof adminAdminUsersControllerLoginAsUserModalQuery._A>;

export type AdminAdminUsersControllerLoginAsUserModalRoute<A = {}> = { match: Match<AdminAdminUsersControllerLoginAsUserModalParams & A>; pathParts: AdminAdminUsersControllerLoginAsUserModalPathParts };

export function adminAdminUsersControllerLoginAsUserModalRoute(): AdminAdminUsersControllerLoginAsUserModalRoute;
export function adminAdminUsersControllerLoginAsUserModalRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminUsersControllerLoginAsUserModalParams>): AdminAdminUsersControllerLoginAsUserModalRoute<t.TypeOf<A>>;
export function adminAdminUsersControllerLoginAsUserModalRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminUsersControllerLoginAsUserModalParams>) {
  return {
    match: (q ? adminAdminUsersControllerLoginAsUserModalPath.then(query(t.intersection([adminAdminUsersControllerLoginAsUserModalQuery, q]))) : adminAdminUsersControllerLoginAsUserModalPath.then(query(adminAdminUsersControllerLoginAsUserModalQuery))).then(end),
    pathParts: adminAdminUsersControllerLoginAsUserModalPathParts,
  };
}

export const adminAdminUsersControllerLoginAsUserModal = (p: AdminAdminUsersControllerLoginAsUserModalParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminUsersControllerLoginAsUserModalRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminBulkUploadControllerIndexPath = lit("admin").then(lit("bulk-upload"));
const adminAdminBulkUploadControllerIndexPathParts = ["admin", "bulk-upload"] as const;
export type AdminAdminBulkUploadControllerIndexPathParts = typeof adminAdminBulkUploadControllerIndexPathParts;

const adminAdminBulkUploadControllerIndexQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminAdminBulkUploadControllerIndexParams = Describe<typeof adminAdminBulkUploadControllerIndexPath._A & typeof adminAdminBulkUploadControllerIndexQuery._A>;

export type AdminAdminBulkUploadControllerIndexRoute<A = {}> = { match: Match<AdminAdminBulkUploadControllerIndexParams & A>; pathParts: AdminAdminBulkUploadControllerIndexPathParts };

export function adminAdminBulkUploadControllerIndexRoute(): AdminAdminBulkUploadControllerIndexRoute;
export function adminAdminBulkUploadControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminBulkUploadControllerIndexParams>): AdminAdminBulkUploadControllerIndexRoute<t.TypeOf<A>>;
export function adminAdminBulkUploadControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminBulkUploadControllerIndexParams>) {
  return {
    match: (q ? adminAdminBulkUploadControllerIndexPath.then(query(t.intersection([adminAdminBulkUploadControllerIndexQuery, q]))) : adminAdminBulkUploadControllerIndexPath.then(query(adminAdminBulkUploadControllerIndexQuery))).then(end),
    pathParts: adminAdminBulkUploadControllerIndexPathParts,
  };
}

export const adminAdminBulkUploadControllerIndex = (p: AdminAdminBulkUploadControllerIndexParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminBulkUploadControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminBulkUploadControllerPostPath = lit("admin").then(lit("bulk-upload"));
const adminAdminBulkUploadControllerPostPathParts = ["admin", "bulk-upload"] as const;
export type AdminAdminBulkUploadControllerPostPathParts = typeof adminAdminBulkUploadControllerPostPathParts;

const adminAdminBulkUploadControllerPostQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminAdminBulkUploadControllerPostParams = Describe<typeof adminAdminBulkUploadControllerPostPath._A & typeof adminAdminBulkUploadControllerPostQuery._A>;

export type AdminAdminBulkUploadControllerPostRoute<A = {}> = { match: Match<AdminAdminBulkUploadControllerPostParams & A>; pathParts: AdminAdminBulkUploadControllerPostPathParts };

export function adminAdminBulkUploadControllerPostRoute(): AdminAdminBulkUploadControllerPostRoute;
export function adminAdminBulkUploadControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminBulkUploadControllerPostParams>): AdminAdminBulkUploadControllerPostRoute<t.TypeOf<A>>;
export function adminAdminBulkUploadControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminBulkUploadControllerPostParams>) {
  return {
    match: (q ? adminAdminBulkUploadControllerPostPath.then(query(t.intersection([adminAdminBulkUploadControllerPostQuery, q]))) : adminAdminBulkUploadControllerPostPath.then(query(adminAdminBulkUploadControllerPostQuery))).then(end),
    pathParts: adminAdminBulkUploadControllerPostPathParts,
  };
}

export const adminAdminBulkUploadControllerPost = (p: AdminAdminBulkUploadControllerPostParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminBulkUploadControllerPostRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminCompaniesControllerIndexPath = lit("admin").then(lit("companies"));
const adminAdminCompaniesControllerIndexPathParts = ["admin", "companies"] as const;
export type AdminAdminCompaniesControllerIndexPathParts = typeof adminAdminCompaniesControllerIndexPathParts;

const adminAdminCompaniesControllerIndexQuery = t.strict({});
export type AdminAdminCompaniesControllerIndexParams = Describe<typeof adminAdminCompaniesControllerIndexPath._A & typeof adminAdminCompaniesControllerIndexQuery._A>;

export type AdminAdminCompaniesControllerIndexRoute<A = {}> = { match: Match<AdminAdminCompaniesControllerIndexParams & A>; pathParts: AdminAdminCompaniesControllerIndexPathParts };

export function adminAdminCompaniesControllerIndexRoute(): AdminAdminCompaniesControllerIndexRoute;
export function adminAdminCompaniesControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminCompaniesControllerIndexParams>): AdminAdminCompaniesControllerIndexRoute<t.TypeOf<A>>;
export function adminAdminCompaniesControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminCompaniesControllerIndexParams>) {
  return {
    match: (q ? adminAdminCompaniesControllerIndexPath.then(query(t.intersection([adminAdminCompaniesControllerIndexQuery, q]))) : adminAdminCompaniesControllerIndexPath.then(query(adminAdminCompaniesControllerIndexQuery))).then(end),
    pathParts: adminAdminCompaniesControllerIndexPathParts,
  };
}

export const adminAdminCompaniesControllerIndex = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminCompaniesControllerIndexRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminCompaniesControllerDataTablePath = lit("admin").then(lit("companies")).then(lit("data-table"));
const adminAdminCompaniesControllerDataTablePathParts = ["admin", "companies", "data-table"] as const;
export type AdminAdminCompaniesControllerDataTablePathParts = typeof adminAdminCompaniesControllerDataTablePathParts;

const adminAdminCompaniesControllerDataTableQuery = t.strict({});
export type AdminAdminCompaniesControllerDataTableParams = Describe<typeof adminAdminCompaniesControllerDataTablePath._A & typeof adminAdminCompaniesControllerDataTableQuery._A>;

export type AdminAdminCompaniesControllerDataTableRoute<A = {}> = { match: Match<AdminAdminCompaniesControllerDataTableParams & A>; pathParts: AdminAdminCompaniesControllerDataTablePathParts };

export function adminAdminCompaniesControllerDataTableRoute(): AdminAdminCompaniesControllerDataTableRoute;
export function adminAdminCompaniesControllerDataTableRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminCompaniesControllerDataTableParams>): AdminAdminCompaniesControllerDataTableRoute<t.TypeOf<A>>;
export function adminAdminCompaniesControllerDataTableRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminCompaniesControllerDataTableParams>) {
  return {
    match: (q ? adminAdminCompaniesControllerDataTablePath.then(query(t.intersection([adminAdminCompaniesControllerDataTableQuery, q]))) : adminAdminCompaniesControllerDataTablePath.then(query(adminAdminCompaniesControllerDataTableQuery))).then(end),
    pathParts: adminAdminCompaniesControllerDataTablePathParts,
  };
}

export const adminAdminCompaniesControllerDataTable = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminCompaniesControllerDataTableRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminCompaniesControllerEditPath = lit("admin").then(lit("companies")).then(lit("edit")).then(int("id"));
const adminAdminCompaniesControllerEditPathParts = ["admin", "companies", "edit", "id"] as const;
export type AdminAdminCompaniesControllerEditPathParts = typeof adminAdminCompaniesControllerEditPathParts;

const adminAdminCompaniesControllerEditQuery = t.strict({});
export type AdminAdminCompaniesControllerEditParams = Describe<typeof adminAdminCompaniesControllerEditPath._A & typeof adminAdminCompaniesControllerEditQuery._A>;

export type AdminAdminCompaniesControllerEditRoute<A = {}> = { match: Match<AdminAdminCompaniesControllerEditParams & A>; pathParts: AdminAdminCompaniesControllerEditPathParts };

export function adminAdminCompaniesControllerEditRoute(): AdminAdminCompaniesControllerEditRoute;
export function adminAdminCompaniesControllerEditRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminCompaniesControllerEditParams>): AdminAdminCompaniesControllerEditRoute<t.TypeOf<A>>;
export function adminAdminCompaniesControllerEditRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminCompaniesControllerEditParams>) {
  return {
    match: (q ? adminAdminCompaniesControllerEditPath.then(query(t.intersection([adminAdminCompaniesControllerEditQuery, q]))) : adminAdminCompaniesControllerEditPath.then(query(adminAdminCompaniesControllerEditQuery))).then(end),
    pathParts: adminAdminCompaniesControllerEditPathParts,
  };
}

export const adminAdminCompaniesControllerEdit = (p: AdminAdminCompaniesControllerEditParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminCompaniesControllerEditRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminCompaniesControllerPostPath = lit("admin").then(lit("companies"));
const adminAdminCompaniesControllerPostPathParts = ["admin", "companies"] as const;
export type AdminAdminCompaniesControllerPostPathParts = typeof adminAdminCompaniesControllerPostPathParts;

const adminAdminCompaniesControllerPostQuery = t.strict({});
export type AdminAdminCompaniesControllerPostParams = Describe<typeof adminAdminCompaniesControllerPostPath._A & typeof adminAdminCompaniesControllerPostQuery._A>;

export type AdminAdminCompaniesControllerPostRoute<A = {}> = { match: Match<AdminAdminCompaniesControllerPostParams & A>; pathParts: AdminAdminCompaniesControllerPostPathParts };

export function adminAdminCompaniesControllerPostRoute(): AdminAdminCompaniesControllerPostRoute;
export function adminAdminCompaniesControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminCompaniesControllerPostParams>): AdminAdminCompaniesControllerPostRoute<t.TypeOf<A>>;
export function adminAdminCompaniesControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminCompaniesControllerPostParams>) {
  return {
    match: (q ? adminAdminCompaniesControllerPostPath.then(query(t.intersection([adminAdminCompaniesControllerPostQuery, q]))) : adminAdminCompaniesControllerPostPath.then(query(adminAdminCompaniesControllerPostQuery))).then(end),
    pathParts: adminAdminCompaniesControllerPostPathParts,
  };
}

export const adminAdminCompaniesControllerPost = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminCompaniesControllerPostRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminCompaniesControllerMergePath = lit("admin").then(lit("companies")).then(lit("merge")).then(int("id"));
const adminAdminCompaniesControllerMergePathParts = ["admin", "companies", "merge", "id"] as const;
export type AdminAdminCompaniesControllerMergePathParts = typeof adminAdminCompaniesControllerMergePathParts;

const adminAdminCompaniesControllerMergeQuery = t.strict({});
export type AdminAdminCompaniesControllerMergeParams = Describe<typeof adminAdminCompaniesControllerMergePath._A & typeof adminAdminCompaniesControllerMergeQuery._A>;

export type AdminAdminCompaniesControllerMergeRoute<A = {}> = { match: Match<AdminAdminCompaniesControllerMergeParams & A>; pathParts: AdminAdminCompaniesControllerMergePathParts };

export function adminAdminCompaniesControllerMergeRoute(): AdminAdminCompaniesControllerMergeRoute;
export function adminAdminCompaniesControllerMergeRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminCompaniesControllerMergeParams>): AdminAdminCompaniesControllerMergeRoute<t.TypeOf<A>>;
export function adminAdminCompaniesControllerMergeRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminCompaniesControllerMergeParams>) {
  return {
    match: (q ? adminAdminCompaniesControllerMergePath.then(query(t.intersection([adminAdminCompaniesControllerMergeQuery, q]))) : adminAdminCompaniesControllerMergePath.then(query(adminAdminCompaniesControllerMergeQuery))).then(end),
    pathParts: adminAdminCompaniesControllerMergePathParts,
  };
}

export const adminAdminCompaniesControllerMerge = (p: AdminAdminCompaniesControllerMergeParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminCompaniesControllerMergeRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminCompaniesControllerMergePostPath = lit("admin").then(lit("companies")).then(lit("merge"));
const adminAdminCompaniesControllerMergePostPathParts = ["admin", "companies", "merge"] as const;
export type AdminAdminCompaniesControllerMergePostPathParts = typeof adminAdminCompaniesControllerMergePostPathParts;

const adminAdminCompaniesControllerMergePostQuery = t.strict({});
export type AdminAdminCompaniesControllerMergePostParams = Describe<typeof adminAdminCompaniesControllerMergePostPath._A & typeof adminAdminCompaniesControllerMergePostQuery._A>;

export type AdminAdminCompaniesControllerMergePostRoute<A = {}> = { match: Match<AdminAdminCompaniesControllerMergePostParams & A>; pathParts: AdminAdminCompaniesControllerMergePostPathParts };

export function adminAdminCompaniesControllerMergePostRoute(): AdminAdminCompaniesControllerMergePostRoute;
export function adminAdminCompaniesControllerMergePostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminCompaniesControllerMergePostParams>): AdminAdminCompaniesControllerMergePostRoute<t.TypeOf<A>>;
export function adminAdminCompaniesControllerMergePostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminCompaniesControllerMergePostParams>) {
  return {
    match: (q ? adminAdminCompaniesControllerMergePostPath.then(query(t.intersection([adminAdminCompaniesControllerMergePostQuery, q]))) : adminAdminCompaniesControllerMergePostPath.then(query(adminAdminCompaniesControllerMergePostQuery))).then(end),
    pathParts: adminAdminCompaniesControllerMergePostPathParts,
  };
}

export const adminAdminCompaniesControllerMergePost = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminCompaniesControllerMergePostRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminCompaniesControllerLinkToIpreoOrgsPath = lit("admin").then(lit("companies")).then(lit("link")).then(int("id"));
const adminAdminCompaniesControllerLinkToIpreoOrgsPathParts = ["admin", "companies", "link", "id"] as const;
export type AdminAdminCompaniesControllerLinkToIpreoOrgsPathParts = typeof adminAdminCompaniesControllerLinkToIpreoOrgsPathParts;

const adminAdminCompaniesControllerLinkToIpreoOrgsQuery = t.strict({});
export type AdminAdminCompaniesControllerLinkToIpreoOrgsParams = Describe<typeof adminAdminCompaniesControllerLinkToIpreoOrgsPath._A & typeof adminAdminCompaniesControllerLinkToIpreoOrgsQuery._A>;

export type AdminAdminCompaniesControllerLinkToIpreoOrgsRoute<A = {}> = { match: Match<AdminAdminCompaniesControllerLinkToIpreoOrgsParams & A>; pathParts: AdminAdminCompaniesControllerLinkToIpreoOrgsPathParts };

export function adminAdminCompaniesControllerLinkToIpreoOrgsRoute(): AdminAdminCompaniesControllerLinkToIpreoOrgsRoute;
export function adminAdminCompaniesControllerLinkToIpreoOrgsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminCompaniesControllerLinkToIpreoOrgsParams>): AdminAdminCompaniesControllerLinkToIpreoOrgsRoute<t.TypeOf<A>>;
export function adminAdminCompaniesControllerLinkToIpreoOrgsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminCompaniesControllerLinkToIpreoOrgsParams>) {
  return {
    match: (q ? adminAdminCompaniesControllerLinkToIpreoOrgsPath.then(query(t.intersection([adminAdminCompaniesControllerLinkToIpreoOrgsQuery, q]))) : adminAdminCompaniesControllerLinkToIpreoOrgsPath.then(query(adminAdminCompaniesControllerLinkToIpreoOrgsQuery))).then(end),
    pathParts: adminAdminCompaniesControllerLinkToIpreoOrgsPathParts,
  };
}

export const adminAdminCompaniesControllerLinkToIpreoOrgs = (p: AdminAdminCompaniesControllerLinkToIpreoOrgsParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminCompaniesControllerLinkToIpreoOrgsRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminCompaniesControllerLinkToIpreoOrgsPostPath = lit("admin").then(lit("companies")).then(lit("link"));
const adminAdminCompaniesControllerLinkToIpreoOrgsPostPathParts = ["admin", "companies", "link"] as const;
export type AdminAdminCompaniesControllerLinkToIpreoOrgsPostPathParts = typeof adminAdminCompaniesControllerLinkToIpreoOrgsPostPathParts;

const adminAdminCompaniesControllerLinkToIpreoOrgsPostQuery = t.strict({});
export type AdminAdminCompaniesControllerLinkToIpreoOrgsPostParams = Describe<typeof adminAdminCompaniesControllerLinkToIpreoOrgsPostPath._A & typeof adminAdminCompaniesControllerLinkToIpreoOrgsPostQuery._A>;

export type AdminAdminCompaniesControllerLinkToIpreoOrgsPostRoute<A = {}> = { match: Match<AdminAdminCompaniesControllerLinkToIpreoOrgsPostParams & A>; pathParts: AdminAdminCompaniesControllerLinkToIpreoOrgsPostPathParts };

export function adminAdminCompaniesControllerLinkToIpreoOrgsPostRoute(): AdminAdminCompaniesControllerLinkToIpreoOrgsPostRoute;
export function adminAdminCompaniesControllerLinkToIpreoOrgsPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminCompaniesControllerLinkToIpreoOrgsPostParams>): AdminAdminCompaniesControllerLinkToIpreoOrgsPostRoute<t.TypeOf<A>>;
export function adminAdminCompaniesControllerLinkToIpreoOrgsPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminCompaniesControllerLinkToIpreoOrgsPostParams>) {
  return {
    match: (q ? adminAdminCompaniesControllerLinkToIpreoOrgsPostPath.then(query(t.intersection([adminAdminCompaniesControllerLinkToIpreoOrgsPostQuery, q]))) : adminAdminCompaniesControllerLinkToIpreoOrgsPostPath.then(query(adminAdminCompaniesControllerLinkToIpreoOrgsPostQuery))).then(end),
    pathParts: adminAdminCompaniesControllerLinkToIpreoOrgsPostPathParts,
  };
}

export const adminAdminCompaniesControllerLinkToIpreoOrgsPost = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminCompaniesControllerLinkToIpreoOrgsPostRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminDomainControllerIndexPath = lit("admin").then(lit("domains"));
const adminAdminDomainControllerIndexPathParts = ["admin", "domains"] as const;
export type AdminAdminDomainControllerIndexPathParts = typeof adminAdminDomainControllerIndexPathParts;

const adminAdminDomainControllerIndexQuery = t.strict({});
export type AdminAdminDomainControllerIndexParams = Describe<typeof adminAdminDomainControllerIndexPath._A & typeof adminAdminDomainControllerIndexQuery._A>;

export type AdminAdminDomainControllerIndexRoute<A = {}> = { match: Match<AdminAdminDomainControllerIndexParams & A>; pathParts: AdminAdminDomainControllerIndexPathParts };

export function adminAdminDomainControllerIndexRoute(): AdminAdminDomainControllerIndexRoute;
export function adminAdminDomainControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerIndexParams>): AdminAdminDomainControllerIndexRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerIndexParams>) {
  return {
    match: (q ? adminAdminDomainControllerIndexPath.then(query(t.intersection([adminAdminDomainControllerIndexQuery, q]))) : adminAdminDomainControllerIndexPath.then(query(adminAdminDomainControllerIndexQuery))).then(end),
    pathParts: adminAdminDomainControllerIndexPathParts,
  };
}

export const adminAdminDomainControllerIndex = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminDomainControllerIndexRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminDomainControllerAssociateCustomDomainPostPath = lit("admin").then(lit("domains")).then(lit("associate-issuer"));
const adminAdminDomainControllerAssociateCustomDomainPostPathParts = ["admin", "domains", "associate-issuer"] as const;
export type AdminAdminDomainControllerAssociateCustomDomainPostPathParts = typeof adminAdminDomainControllerAssociateCustomDomainPostPathParts;

const adminAdminDomainControllerAssociateCustomDomainPostQuery = t.strict({});
export type AdminAdminDomainControllerAssociateCustomDomainPostParams = Describe<typeof adminAdminDomainControllerAssociateCustomDomainPostPath._A & typeof adminAdminDomainControllerAssociateCustomDomainPostQuery._A>;

export type AdminAdminDomainControllerAssociateCustomDomainPostRoute<A = {}> = { match: Match<AdminAdminDomainControllerAssociateCustomDomainPostParams & A>; pathParts: AdminAdminDomainControllerAssociateCustomDomainPostPathParts };

export function adminAdminDomainControllerAssociateCustomDomainPostRoute(): AdminAdminDomainControllerAssociateCustomDomainPostRoute;
export function adminAdminDomainControllerAssociateCustomDomainPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerAssociateCustomDomainPostParams>): AdminAdminDomainControllerAssociateCustomDomainPostRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerAssociateCustomDomainPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerAssociateCustomDomainPostParams>) {
  return {
    match: (q ? adminAdminDomainControllerAssociateCustomDomainPostPath.then(query(t.intersection([adminAdminDomainControllerAssociateCustomDomainPostQuery, q]))) : adminAdminDomainControllerAssociateCustomDomainPostPath.then(query(adminAdminDomainControllerAssociateCustomDomainPostQuery))).then(end),
    pathParts: adminAdminDomainControllerAssociateCustomDomainPostPathParts,
  };
}

export const adminAdminDomainControllerAssociateCustomDomainPost = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminDomainControllerAssociateCustomDomainPostRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminDomainControllerAssociateWithIssuerModalPath = lit("admin").then(lit("domains")).then(lit("associate-issuer"));
const adminAdminDomainControllerAssociateWithIssuerModalPathParts = ["admin", "domains", "associate-issuer"] as const;
export type AdminAdminDomainControllerAssociateWithIssuerModalPathParts = typeof adminAdminDomainControllerAssociateWithIssuerModalPathParts;

const adminAdminDomainControllerAssociateWithIssuerModalQuery = t.exact(t.type({
  "zoneId": t.string
}));
export type AdminAdminDomainControllerAssociateWithIssuerModalParams = Describe<typeof adminAdminDomainControllerAssociateWithIssuerModalPath._A & typeof adminAdminDomainControllerAssociateWithIssuerModalQuery._A>;

export type AdminAdminDomainControllerAssociateWithIssuerModalRoute<A = {}> = { match: Match<AdminAdminDomainControllerAssociateWithIssuerModalParams & A>; pathParts: AdminAdminDomainControllerAssociateWithIssuerModalPathParts };

export function adminAdminDomainControllerAssociateWithIssuerModalRoute(): AdminAdminDomainControllerAssociateWithIssuerModalRoute;
export function adminAdminDomainControllerAssociateWithIssuerModalRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerAssociateWithIssuerModalParams>): AdminAdminDomainControllerAssociateWithIssuerModalRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerAssociateWithIssuerModalRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerAssociateWithIssuerModalParams>) {
  return {
    match: (q ? adminAdminDomainControllerAssociateWithIssuerModalPath.then(query(t.intersection([adminAdminDomainControllerAssociateWithIssuerModalQuery, q]))) : adminAdminDomainControllerAssociateWithIssuerModalPath.then(query(adminAdminDomainControllerAssociateWithIssuerModalQuery))).then(end),
    pathParts: adminAdminDomainControllerAssociateWithIssuerModalPathParts,
  };
}

export const adminAdminDomainControllerAssociateWithIssuerModal = (p: AdminAdminDomainControllerAssociateWithIssuerModalParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminDomainControllerAssociateWithIssuerModalRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminDomainControllerConfigureServersModalPath = lit("admin").then(lit("domains")).then(lit("configure-servers"));
const adminAdminDomainControllerConfigureServersModalPathParts = ["admin", "domains", "configure-servers"] as const;
export type AdminAdminDomainControllerConfigureServersModalPathParts = typeof adminAdminDomainControllerConfigureServersModalPathParts;

const adminAdminDomainControllerConfigureServersModalQuery = t.exact(t.type({
  "zoneId": t.string
}));
export type AdminAdminDomainControllerConfigureServersModalParams = Describe<typeof adminAdminDomainControllerConfigureServersModalPath._A & typeof adminAdminDomainControllerConfigureServersModalQuery._A>;

export type AdminAdminDomainControllerConfigureServersModalRoute<A = {}> = { match: Match<AdminAdminDomainControllerConfigureServersModalParams & A>; pathParts: AdminAdminDomainControllerConfigureServersModalPathParts };

export function adminAdminDomainControllerConfigureServersModalRoute(): AdminAdminDomainControllerConfigureServersModalRoute;
export function adminAdminDomainControllerConfigureServersModalRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerConfigureServersModalParams>): AdminAdminDomainControllerConfigureServersModalRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerConfigureServersModalRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerConfigureServersModalParams>) {
  return {
    match: (q ? adminAdminDomainControllerConfigureServersModalPath.then(query(t.intersection([adminAdminDomainControllerConfigureServersModalQuery, q]))) : adminAdminDomainControllerConfigureServersModalPath.then(query(adminAdminDomainControllerConfigureServersModalQuery))).then(end),
    pathParts: adminAdminDomainControllerConfigureServersModalPathParts,
  };
}

export const adminAdminDomainControllerConfigureServersModal = (p: AdminAdminDomainControllerConfigureServersModalParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminDomainControllerConfigureServersModalRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminDomainControllerConfigureServersPostPath = lit("admin").then(lit("domains")).then(lit("configure-servers"));
const adminAdminDomainControllerConfigureServersPostPathParts = ["admin", "domains", "configure-servers"] as const;
export type AdminAdminDomainControllerConfigureServersPostPathParts = typeof adminAdminDomainControllerConfigureServersPostPathParts;

const adminAdminDomainControllerConfigureServersPostQuery = t.strict({});
export type AdminAdminDomainControllerConfigureServersPostParams = Describe<typeof adminAdminDomainControllerConfigureServersPostPath._A & typeof adminAdminDomainControllerConfigureServersPostQuery._A>;

export type AdminAdminDomainControllerConfigureServersPostRoute<A = {}> = { match: Match<AdminAdminDomainControllerConfigureServersPostParams & A>; pathParts: AdminAdminDomainControllerConfigureServersPostPathParts };

export function adminAdminDomainControllerConfigureServersPostRoute(): AdminAdminDomainControllerConfigureServersPostRoute;
export function adminAdminDomainControllerConfigureServersPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerConfigureServersPostParams>): AdminAdminDomainControllerConfigureServersPostRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerConfigureServersPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerConfigureServersPostParams>) {
  return {
    match: (q ? adminAdminDomainControllerConfigureServersPostPath.then(query(t.intersection([adminAdminDomainControllerConfigureServersPostQuery, q]))) : adminAdminDomainControllerConfigureServersPostPath.then(query(adminAdminDomainControllerConfigureServersPostQuery))).then(end),
    pathParts: adminAdminDomainControllerConfigureServersPostPathParts,
  };
}

export const adminAdminDomainControllerConfigureServersPost = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminDomainControllerConfigureServersPostRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminDomainControllerCreateDefaultEntriesPath = lit("admin").then(lit("domains")).then(lit("default-entries"));
const adminAdminDomainControllerCreateDefaultEntriesPathParts = ["admin", "domains", "default-entries"] as const;
export type AdminAdminDomainControllerCreateDefaultEntriesPathParts = typeof adminAdminDomainControllerCreateDefaultEntriesPathParts;

const adminAdminDomainControllerCreateDefaultEntriesQuery = t.strict({});
export type AdminAdminDomainControllerCreateDefaultEntriesParams = Describe<typeof adminAdminDomainControllerCreateDefaultEntriesPath._A & typeof adminAdminDomainControllerCreateDefaultEntriesQuery._A>;

export type AdminAdminDomainControllerCreateDefaultEntriesRoute<A = {}> = { match: Match<AdminAdminDomainControllerCreateDefaultEntriesParams & A>; pathParts: AdminAdminDomainControllerCreateDefaultEntriesPathParts };

export function adminAdminDomainControllerCreateDefaultEntriesRoute(): AdminAdminDomainControllerCreateDefaultEntriesRoute;
export function adminAdminDomainControllerCreateDefaultEntriesRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerCreateDefaultEntriesParams>): AdminAdminDomainControllerCreateDefaultEntriesRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerCreateDefaultEntriesRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerCreateDefaultEntriesParams>) {
  return {
    match: (q ? adminAdminDomainControllerCreateDefaultEntriesPath.then(query(t.intersection([adminAdminDomainControllerCreateDefaultEntriesQuery, q]))) : adminAdminDomainControllerCreateDefaultEntriesPath.then(query(adminAdminDomainControllerCreateDefaultEntriesQuery))).then(end),
    pathParts: adminAdminDomainControllerCreateDefaultEntriesPathParts,
  };
}

export const adminAdminDomainControllerCreateDefaultEntries = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminDomainControllerCreateDefaultEntriesRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminDomainControllerCreateHostedZonePostPath = lit("admin").then(lit("domains")).then(lit("created-hosted-zone"));
const adminAdminDomainControllerCreateHostedZonePostPathParts = ["admin", "domains", "created-hosted-zone"] as const;
export type AdminAdminDomainControllerCreateHostedZonePostPathParts = typeof adminAdminDomainControllerCreateHostedZonePostPathParts;

const adminAdminDomainControllerCreateHostedZonePostQuery = t.strict({});
export type AdminAdminDomainControllerCreateHostedZonePostParams = Describe<typeof adminAdminDomainControllerCreateHostedZonePostPath._A & typeof adminAdminDomainControllerCreateHostedZonePostQuery._A>;

export type AdminAdminDomainControllerCreateHostedZonePostRoute<A = {}> = { match: Match<AdminAdminDomainControllerCreateHostedZonePostParams & A>; pathParts: AdminAdminDomainControllerCreateHostedZonePostPathParts };

export function adminAdminDomainControllerCreateHostedZonePostRoute(): AdminAdminDomainControllerCreateHostedZonePostRoute;
export function adminAdminDomainControllerCreateHostedZonePostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerCreateHostedZonePostParams>): AdminAdminDomainControllerCreateHostedZonePostRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerCreateHostedZonePostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerCreateHostedZonePostParams>) {
  return {
    match: (q ? adminAdminDomainControllerCreateHostedZonePostPath.then(query(t.intersection([adminAdminDomainControllerCreateHostedZonePostQuery, q]))) : adminAdminDomainControllerCreateHostedZonePostPath.then(query(adminAdminDomainControllerCreateHostedZonePostQuery))).then(end),
    pathParts: adminAdminDomainControllerCreateHostedZonePostPathParts,
  };
}

export const adminAdminDomainControllerCreateHostedZonePost = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminDomainControllerCreateHostedZonePostRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminDomainControllerDeleteParkedDomainPath = lit("admin").then(lit("domains")).then(lit("delete-parked-domain"));
const adminAdminDomainControllerDeleteParkedDomainPathParts = ["admin", "domains", "delete-parked-domain"] as const;
export type AdminAdminDomainControllerDeleteParkedDomainPathParts = typeof adminAdminDomainControllerDeleteParkedDomainPathParts;

const adminAdminDomainControllerDeleteParkedDomainQuery = t.exact(t.type({
  "zoneId": t.string
}));
export type AdminAdminDomainControllerDeleteParkedDomainParams = Describe<typeof adminAdminDomainControllerDeleteParkedDomainPath._A & typeof adminAdminDomainControllerDeleteParkedDomainQuery._A>;

export type AdminAdminDomainControllerDeleteParkedDomainRoute<A = {}> = { match: Match<AdminAdminDomainControllerDeleteParkedDomainParams & A>; pathParts: AdminAdminDomainControllerDeleteParkedDomainPathParts };

export function adminAdminDomainControllerDeleteParkedDomainRoute(): AdminAdminDomainControllerDeleteParkedDomainRoute;
export function adminAdminDomainControllerDeleteParkedDomainRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerDeleteParkedDomainParams>): AdminAdminDomainControllerDeleteParkedDomainRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerDeleteParkedDomainRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerDeleteParkedDomainParams>) {
  return {
    match: (q ? adminAdminDomainControllerDeleteParkedDomainPath.then(query(t.intersection([adminAdminDomainControllerDeleteParkedDomainQuery, q]))) : adminAdminDomainControllerDeleteParkedDomainPath.then(query(adminAdminDomainControllerDeleteParkedDomainQuery))).then(end),
    pathParts: adminAdminDomainControllerDeleteParkedDomainPathParts,
  };
}

export const adminAdminDomainControllerDeleteParkedDomain = (p: AdminAdminDomainControllerDeleteParkedDomainParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminDomainControllerDeleteParkedDomainRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminDomainControllerDomainSearchPath = lit("admin").then(lit("domains")).then(lit("search"));
const adminAdminDomainControllerDomainSearchPathParts = ["admin", "domains", "search"] as const;
export type AdminAdminDomainControllerDomainSearchPathParts = typeof adminAdminDomainControllerDomainSearchPathParts;

const adminAdminDomainControllerDomainSearchQuery = t.strict({});
export type AdminAdminDomainControllerDomainSearchParams = Describe<typeof adminAdminDomainControllerDomainSearchPath._A & typeof adminAdminDomainControllerDomainSearchQuery._A>;

export type AdminAdminDomainControllerDomainSearchRoute<A = {}> = { match: Match<AdminAdminDomainControllerDomainSearchParams & A>; pathParts: AdminAdminDomainControllerDomainSearchPathParts };

export function adminAdminDomainControllerDomainSearchRoute(): AdminAdminDomainControllerDomainSearchRoute;
export function adminAdminDomainControllerDomainSearchRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerDomainSearchParams>): AdminAdminDomainControllerDomainSearchRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerDomainSearchRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerDomainSearchParams>) {
  return {
    match: (q ? adminAdminDomainControllerDomainSearchPath.then(query(t.intersection([adminAdminDomainControllerDomainSearchQuery, q]))) : adminAdminDomainControllerDomainSearchPath.then(query(adminAdminDomainControllerDomainSearchQuery))).then(end),
    pathParts: adminAdminDomainControllerDomainSearchPathParts,
  };
}

export const adminAdminDomainControllerDomainSearch = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminDomainControllerDomainSearchRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminDomainControllerDomainSearchPostPath = lit("admin").then(lit("domains")).then(lit("search"));
const adminAdminDomainControllerDomainSearchPostPathParts = ["admin", "domains", "search"] as const;
export type AdminAdminDomainControllerDomainSearchPostPathParts = typeof adminAdminDomainControllerDomainSearchPostPathParts;

const adminAdminDomainControllerDomainSearchPostQuery = t.strict({});
export type AdminAdminDomainControllerDomainSearchPostParams = Describe<typeof adminAdminDomainControllerDomainSearchPostPath._A & typeof adminAdminDomainControllerDomainSearchPostQuery._A>;

export type AdminAdminDomainControllerDomainSearchPostRoute<A = {}> = { match: Match<AdminAdminDomainControllerDomainSearchPostParams & A>; pathParts: AdminAdminDomainControllerDomainSearchPostPathParts };

export function adminAdminDomainControllerDomainSearchPostRoute(): AdminAdminDomainControllerDomainSearchPostRoute;
export function adminAdminDomainControllerDomainSearchPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerDomainSearchPostParams>): AdminAdminDomainControllerDomainSearchPostRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerDomainSearchPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerDomainSearchPostParams>) {
  return {
    match: (q ? adminAdminDomainControllerDomainSearchPostPath.then(query(t.intersection([adminAdminDomainControllerDomainSearchPostQuery, q]))) : adminAdminDomainControllerDomainSearchPostPath.then(query(adminAdminDomainControllerDomainSearchPostQuery))).then(end),
    pathParts: adminAdminDomainControllerDomainSearchPostPathParts,
  };
}

export const adminAdminDomainControllerDomainSearchPost = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminDomainControllerDomainSearchPostRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminDomainControllerParkedDomainsModalPath = lit("admin").then(lit("domains")).then(lit("configure-parked"));
const adminAdminDomainControllerParkedDomainsModalPathParts = ["admin", "domains", "configure-parked"] as const;
export type AdminAdminDomainControllerParkedDomainsModalPathParts = typeof adminAdminDomainControllerParkedDomainsModalPathParts;

const adminAdminDomainControllerParkedDomainsModalQuery = t.exact(t.type({
  "zoneId": t.string
}));
export type AdminAdminDomainControllerParkedDomainsModalParams = Describe<typeof adminAdminDomainControllerParkedDomainsModalPath._A & typeof adminAdminDomainControllerParkedDomainsModalQuery._A>;

export type AdminAdminDomainControllerParkedDomainsModalRoute<A = {}> = { match: Match<AdminAdminDomainControllerParkedDomainsModalParams & A>; pathParts: AdminAdminDomainControllerParkedDomainsModalPathParts };

export function adminAdminDomainControllerParkedDomainsModalRoute(): AdminAdminDomainControllerParkedDomainsModalRoute;
export function adminAdminDomainControllerParkedDomainsModalRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerParkedDomainsModalParams>): AdminAdminDomainControllerParkedDomainsModalRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerParkedDomainsModalRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerParkedDomainsModalParams>) {
  return {
    match: (q ? adminAdminDomainControllerParkedDomainsModalPath.then(query(t.intersection([adminAdminDomainControllerParkedDomainsModalQuery, q]))) : adminAdminDomainControllerParkedDomainsModalPath.then(query(adminAdminDomainControllerParkedDomainsModalQuery))).then(end),
    pathParts: adminAdminDomainControllerParkedDomainsModalPathParts,
  };
}

export const adminAdminDomainControllerParkedDomainsModal = (p: AdminAdminDomainControllerParkedDomainsModalParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminDomainControllerParkedDomainsModalRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminDomainControllerParkedDomainsPostPath = lit("admin").then(lit("domains")).then(lit("configure-parked"));
const adminAdminDomainControllerParkedDomainsPostPathParts = ["admin", "domains", "configure-parked"] as const;
export type AdminAdminDomainControllerParkedDomainsPostPathParts = typeof adminAdminDomainControllerParkedDomainsPostPathParts;

const adminAdminDomainControllerParkedDomainsPostQuery = t.strict({});
export type AdminAdminDomainControllerParkedDomainsPostParams = Describe<typeof adminAdminDomainControllerParkedDomainsPostPath._A & typeof adminAdminDomainControllerParkedDomainsPostQuery._A>;

export type AdminAdminDomainControllerParkedDomainsPostRoute<A = {}> = { match: Match<AdminAdminDomainControllerParkedDomainsPostParams & A>; pathParts: AdminAdminDomainControllerParkedDomainsPostPathParts };

export function adminAdminDomainControllerParkedDomainsPostRoute(): AdminAdminDomainControllerParkedDomainsPostRoute;
export function adminAdminDomainControllerParkedDomainsPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerParkedDomainsPostParams>): AdminAdminDomainControllerParkedDomainsPostRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerParkedDomainsPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerParkedDomainsPostParams>) {
  return {
    match: (q ? adminAdminDomainControllerParkedDomainsPostPath.then(query(t.intersection([adminAdminDomainControllerParkedDomainsPostQuery, q]))) : adminAdminDomainControllerParkedDomainsPostPath.then(query(adminAdminDomainControllerParkedDomainsPostQuery))).then(end),
    pathParts: adminAdminDomainControllerParkedDomainsPostPathParts,
  };
}

export const adminAdminDomainControllerParkedDomainsPost = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminDomainControllerParkedDomainsPostRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminDomainControllerRegisterDomainPath = lit("admin").then(lit("domains")).then(lit("register-domain"));
const adminAdminDomainControllerRegisterDomainPathParts = ["admin", "domains", "register-domain"] as const;
export type AdminAdminDomainControllerRegisterDomainPathParts = typeof adminAdminDomainControllerRegisterDomainPathParts;

const adminAdminDomainControllerRegisterDomainQuery = t.strict({});
export type AdminAdminDomainControllerRegisterDomainParams = Describe<typeof adminAdminDomainControllerRegisterDomainPath._A & typeof adminAdminDomainControllerRegisterDomainQuery._A>;

export type AdminAdminDomainControllerRegisterDomainRoute<A = {}> = { match: Match<AdminAdminDomainControllerRegisterDomainParams & A>; pathParts: AdminAdminDomainControllerRegisterDomainPathParts };

export function adminAdminDomainControllerRegisterDomainRoute(): AdminAdminDomainControllerRegisterDomainRoute;
export function adminAdminDomainControllerRegisterDomainRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerRegisterDomainParams>): AdminAdminDomainControllerRegisterDomainRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerRegisterDomainRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerRegisterDomainParams>) {
  return {
    match: (q ? adminAdminDomainControllerRegisterDomainPath.then(query(t.intersection([adminAdminDomainControllerRegisterDomainQuery, q]))) : adminAdminDomainControllerRegisterDomainPath.then(query(adminAdminDomainControllerRegisterDomainQuery))).then(end),
    pathParts: adminAdminDomainControllerRegisterDomainPathParts,
  };
}

export const adminAdminDomainControllerRegisterDomain = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminDomainControllerRegisterDomainRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminDomainControllerRegisterDomainModalPath = lit("admin").then(lit("domains")).then(lit("register-domain"));
const adminAdminDomainControllerRegisterDomainModalPathParts = ["admin", "domains", "register-domain"] as const;
export type AdminAdminDomainControllerRegisterDomainModalPathParts = typeof adminAdminDomainControllerRegisterDomainModalPathParts;

const adminAdminDomainControllerRegisterDomainModalQuery = t.exact(t.type({
  "domain": t.string
}));
export type AdminAdminDomainControllerRegisterDomainModalParams = Describe<typeof adminAdminDomainControllerRegisterDomainModalPath._A & typeof adminAdminDomainControllerRegisterDomainModalQuery._A>;

export type AdminAdminDomainControllerRegisterDomainModalRoute<A = {}> = { match: Match<AdminAdminDomainControllerRegisterDomainModalParams & A>; pathParts: AdminAdminDomainControllerRegisterDomainModalPathParts };

export function adminAdminDomainControllerRegisterDomainModalRoute(): AdminAdminDomainControllerRegisterDomainModalRoute;
export function adminAdminDomainControllerRegisterDomainModalRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerRegisterDomainModalParams>): AdminAdminDomainControllerRegisterDomainModalRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerRegisterDomainModalRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerRegisterDomainModalParams>) {
  return {
    match: (q ? adminAdminDomainControllerRegisterDomainModalPath.then(query(t.intersection([adminAdminDomainControllerRegisterDomainModalQuery, q]))) : adminAdminDomainControllerRegisterDomainModalPath.then(query(adminAdminDomainControllerRegisterDomainModalQuery))).then(end),
    pathParts: adminAdminDomainControllerRegisterDomainModalPathParts,
  };
}

export const adminAdminDomainControllerRegisterDomainModal = (p: AdminAdminDomainControllerRegisterDomainModalParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminDomainControllerRegisterDomainModalRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminDomainControllerShowIssuersForZonePath = lit("admin").then(lit("domains")).then(lit("issuers-shared-domain"));
const adminAdminDomainControllerShowIssuersForZonePathParts = ["admin", "domains", "issuers-shared-domain"] as const;
export type AdminAdminDomainControllerShowIssuersForZonePathParts = typeof adminAdminDomainControllerShowIssuersForZonePathParts;

const adminAdminDomainControllerShowIssuersForZoneQuery = t.exact(t.type({
  "domain": t.string
}));
export type AdminAdminDomainControllerShowIssuersForZoneParams = Describe<typeof adminAdminDomainControllerShowIssuersForZonePath._A & typeof adminAdminDomainControllerShowIssuersForZoneQuery._A>;

export type AdminAdminDomainControllerShowIssuersForZoneRoute<A = {}> = { match: Match<AdminAdminDomainControllerShowIssuersForZoneParams & A>; pathParts: AdminAdminDomainControllerShowIssuersForZonePathParts };

export function adminAdminDomainControllerShowIssuersForZoneRoute(): AdminAdminDomainControllerShowIssuersForZoneRoute;
export function adminAdminDomainControllerShowIssuersForZoneRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerShowIssuersForZoneParams>): AdminAdminDomainControllerShowIssuersForZoneRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerShowIssuersForZoneRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerShowIssuersForZoneParams>) {
  return {
    match: (q ? adminAdminDomainControllerShowIssuersForZonePath.then(query(t.intersection([adminAdminDomainControllerShowIssuersForZoneQuery, q]))) : adminAdminDomainControllerShowIssuersForZonePath.then(query(adminAdminDomainControllerShowIssuersForZoneQuery))).then(end),
    pathParts: adminAdminDomainControllerShowIssuersForZonePathParts,
  };
}

export const adminAdminDomainControllerShowIssuersForZone = (p: AdminAdminDomainControllerShowIssuersForZoneParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminDomainControllerShowIssuersForZoneRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminDomainControllerViewZoneDataPath = lit("admin").then(lit("domains")).then(lit("hosted-zone"));
const adminAdminDomainControllerViewZoneDataPathParts = ["admin", "domains", "hosted-zone"] as const;
export type AdminAdminDomainControllerViewZoneDataPathParts = typeof adminAdminDomainControllerViewZoneDataPathParts;

const adminAdminDomainControllerViewZoneDataQuery = t.exact(t.type({
  "zoneId": t.string
}));
export type AdminAdminDomainControllerViewZoneDataParams = Describe<typeof adminAdminDomainControllerViewZoneDataPath._A & typeof adminAdminDomainControllerViewZoneDataQuery._A>;

export type AdminAdminDomainControllerViewZoneDataRoute<A = {}> = { match: Match<AdminAdminDomainControllerViewZoneDataParams & A>; pathParts: AdminAdminDomainControllerViewZoneDataPathParts };

export function adminAdminDomainControllerViewZoneDataRoute(): AdminAdminDomainControllerViewZoneDataRoute;
export function adminAdminDomainControllerViewZoneDataRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminDomainControllerViewZoneDataParams>): AdminAdminDomainControllerViewZoneDataRoute<t.TypeOf<A>>;
export function adminAdminDomainControllerViewZoneDataRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminDomainControllerViewZoneDataParams>) {
  return {
    match: (q ? adminAdminDomainControllerViewZoneDataPath.then(query(t.intersection([adminAdminDomainControllerViewZoneDataQuery, q]))) : adminAdminDomainControllerViewZoneDataPath.then(query(adminAdminDomainControllerViewZoneDataQuery))).then(end),
    pathParts: adminAdminDomainControllerViewZoneDataPathParts,
  };
}

export const adminAdminDomainControllerViewZoneData = (p: AdminAdminDomainControllerViewZoneDataParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminDomainControllerViewZoneDataRoute().match.formatter.run(Route.empty, p).toString(true));

const adminRoadShowsControllerIndexPath = lit("admin").then(lit("road-shows"));
const adminRoadShowsControllerIndexPathParts = ["admin", "road-shows"] as const;
export type AdminRoadShowsControllerIndexPathParts = typeof adminRoadShowsControllerIndexPathParts;

const adminRoadShowsControllerIndexQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminRoadShowsControllerIndexParams = Describe<typeof adminRoadShowsControllerIndexPath._A & typeof adminRoadShowsControllerIndexQuery._A>;

export type AdminRoadShowsControllerIndexRoute<A = {}> = { match: Match<AdminRoadShowsControllerIndexParams & A>; pathParts: AdminRoadShowsControllerIndexPathParts };

export function adminRoadShowsControllerIndexRoute(): AdminRoadShowsControllerIndexRoute;
export function adminRoadShowsControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminRoadShowsControllerIndexParams>): AdminRoadShowsControllerIndexRoute<t.TypeOf<A>>;
export function adminRoadShowsControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminRoadShowsControllerIndexParams>) {
  return {
    match: (q ? adminRoadShowsControllerIndexPath.then(query(t.intersection([adminRoadShowsControllerIndexQuery, q]))) : adminRoadShowsControllerIndexPath.then(query(adminRoadShowsControllerIndexQuery))).then(end),
    pathParts: adminRoadShowsControllerIndexPathParts,
  };
}

export const adminRoadShowsControllerIndex = (p: AdminRoadShowsControllerIndexParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminRoadShowsControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true));

const adminRoadShowsControllerDeletePath = lit("admin").then(lit("road-shows")).then(int("id"));
const adminRoadShowsControllerDeletePathParts = ["admin", "road-shows", "id"] as const;
export type AdminRoadShowsControllerDeletePathParts = typeof adminRoadShowsControllerDeletePathParts;

const adminRoadShowsControllerDeleteQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminRoadShowsControllerDeleteParams = Describe<typeof adminRoadShowsControllerDeletePath._A & typeof adminRoadShowsControllerDeleteQuery._A>;

export type AdminRoadShowsControllerDeleteRoute<A = {}> = { match: Match<AdminRoadShowsControllerDeleteParams & A>; pathParts: AdminRoadShowsControllerDeletePathParts };

export function adminRoadShowsControllerDeleteRoute(): AdminRoadShowsControllerDeleteRoute;
export function adminRoadShowsControllerDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminRoadShowsControllerDeleteParams>): AdminRoadShowsControllerDeleteRoute<t.TypeOf<A>>;
export function adminRoadShowsControllerDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminRoadShowsControllerDeleteParams>) {
  return {
    match: (q ? adminRoadShowsControllerDeletePath.then(query(t.intersection([adminRoadShowsControllerDeleteQuery, q]))) : adminRoadShowsControllerDeletePath.then(query(adminRoadShowsControllerDeleteQuery))).then(end),
    pathParts: adminRoadShowsControllerDeletePathParts,
  };
}

export const adminRoadShowsControllerDelete = (p: AdminRoadShowsControllerDeleteParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminRoadShowsControllerDeleteRoute().match.formatter.run(Route.empty, p).toString(true));

const adminRoadShowsControllerEditPath = lit("admin").then(lit("road-shows")).then(lit("edit")).then(int("id"));
const adminRoadShowsControllerEditPathParts = ["admin", "road-shows", "edit", "id"] as const;
export type AdminRoadShowsControllerEditPathParts = typeof adminRoadShowsControllerEditPathParts;

const adminRoadShowsControllerEditQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminRoadShowsControllerEditParams = Describe<typeof adminRoadShowsControllerEditPath._A & typeof adminRoadShowsControllerEditQuery._A>;

export type AdminRoadShowsControllerEditRoute<A = {}> = { match: Match<AdminRoadShowsControllerEditParams & A>; pathParts: AdminRoadShowsControllerEditPathParts };

export function adminRoadShowsControllerEditRoute(): AdminRoadShowsControllerEditRoute;
export function adminRoadShowsControllerEditRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminRoadShowsControllerEditParams>): AdminRoadShowsControllerEditRoute<t.TypeOf<A>>;
export function adminRoadShowsControllerEditRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminRoadShowsControllerEditParams>) {
  return {
    match: (q ? adminRoadShowsControllerEditPath.then(query(t.intersection([adminRoadShowsControllerEditQuery, q]))) : adminRoadShowsControllerEditPath.then(query(adminRoadShowsControllerEditQuery))).then(end),
    pathParts: adminRoadShowsControllerEditPathParts,
  };
}

export const adminRoadShowsControllerEdit = (p: AdminRoadShowsControllerEditParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminRoadShowsControllerEditRoute().match.formatter.run(Route.empty, p).toString(true));

const adminRoadShowsControllerPostPath = lit("admin").then(lit("road-shows"));
const adminRoadShowsControllerPostPathParts = ["admin", "road-shows"] as const;
export type AdminRoadShowsControllerPostPathParts = typeof adminRoadShowsControllerPostPathParts;

const adminRoadShowsControllerPostQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminRoadShowsControllerPostParams = Describe<typeof adminRoadShowsControllerPostPath._A & typeof adminRoadShowsControllerPostQuery._A>;

export type AdminRoadShowsControllerPostRoute<A = {}> = { match: Match<AdminRoadShowsControllerPostParams & A>; pathParts: AdminRoadShowsControllerPostPathParts };

export function adminRoadShowsControllerPostRoute(): AdminRoadShowsControllerPostRoute;
export function adminRoadShowsControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminRoadShowsControllerPostParams>): AdminRoadShowsControllerPostRoute<t.TypeOf<A>>;
export function adminRoadShowsControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminRoadShowsControllerPostParams>) {
  return {
    match: (q ? adminRoadShowsControllerPostPath.then(query(t.intersection([adminRoadShowsControllerPostQuery, q]))) : adminRoadShowsControllerPostPath.then(query(adminRoadShowsControllerPostQuery))).then(end),
    pathParts: adminRoadShowsControllerPostPathParts,
  };
}

export const adminRoadShowsControllerPost = (p: AdminRoadShowsControllerPostParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminRoadShowsControllerPostRoute().match.formatter.run(Route.empty, p).toString(true));

const adminCustomReportsControllerDeletePath = lit("admin").then(lit("custom-reports")).then(lit("delete")).then(int("id"));
const adminCustomReportsControllerDeletePathParts = ["admin", "custom-reports", "delete", "id"] as const;
export type AdminCustomReportsControllerDeletePathParts = typeof adminCustomReportsControllerDeletePathParts;

const adminCustomReportsControllerDeleteQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminCustomReportsControllerDeleteParams = Describe<typeof adminCustomReportsControllerDeletePath._A & typeof adminCustomReportsControllerDeleteQuery._A>;

export type AdminCustomReportsControllerDeleteRoute<A = {}> = { match: Match<AdminCustomReportsControllerDeleteParams & A>; pathParts: AdminCustomReportsControllerDeletePathParts };

export function adminCustomReportsControllerDeleteRoute(): AdminCustomReportsControllerDeleteRoute;
export function adminCustomReportsControllerDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminCustomReportsControllerDeleteParams>): AdminCustomReportsControllerDeleteRoute<t.TypeOf<A>>;
export function adminCustomReportsControllerDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminCustomReportsControllerDeleteParams>) {
  return {
    match: (q ? adminCustomReportsControllerDeletePath.then(query(t.intersection([adminCustomReportsControllerDeleteQuery, q]))) : adminCustomReportsControllerDeletePath.then(query(adminCustomReportsControllerDeleteQuery))).then(end),
    pathParts: adminCustomReportsControllerDeletePathParts,
  };
}

export const adminCustomReportsControllerDelete = (p: AdminCustomReportsControllerDeleteParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminCustomReportsControllerDeleteRoute().match.formatter.run(Route.empty, p).toString(true));

const adminCustomReportsControllerEditPath = lit("admin").then(lit("custom-reports")).then(lit("edit")).then(int("id"));
const adminCustomReportsControllerEditPathParts = ["admin", "custom-reports", "edit", "id"] as const;
export type AdminCustomReportsControllerEditPathParts = typeof adminCustomReportsControllerEditPathParts;

const adminCustomReportsControllerEditQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminCustomReportsControllerEditParams = Describe<typeof adminCustomReportsControllerEditPath._A & typeof adminCustomReportsControllerEditQuery._A>;

export type AdminCustomReportsControllerEditRoute<A = {}> = { match: Match<AdminCustomReportsControllerEditParams & A>; pathParts: AdminCustomReportsControllerEditPathParts };

export function adminCustomReportsControllerEditRoute(): AdminCustomReportsControllerEditRoute;
export function adminCustomReportsControllerEditRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminCustomReportsControllerEditParams>): AdminCustomReportsControllerEditRoute<t.TypeOf<A>>;
export function adminCustomReportsControllerEditRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminCustomReportsControllerEditParams>) {
  return {
    match: (q ? adminCustomReportsControllerEditPath.then(query(t.intersection([adminCustomReportsControllerEditQuery, q]))) : adminCustomReportsControllerEditPath.then(query(adminCustomReportsControllerEditQuery))).then(end),
    pathParts: adminCustomReportsControllerEditPathParts,
  };
}

export const adminCustomReportsControllerEdit = (p: AdminCustomReportsControllerEditParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminCustomReportsControllerEditRoute().match.formatter.run(Route.empty, p).toString(true));

const adminCustomReportsControllerIndexPath = lit("admin").then(lit("custom-reports"));
const adminCustomReportsControllerIndexPathParts = ["admin", "custom-reports"] as const;
export type AdminCustomReportsControllerIndexPathParts = typeof adminCustomReportsControllerIndexPathParts;

const adminCustomReportsControllerIndexQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminCustomReportsControllerIndexParams = Describe<typeof adminCustomReportsControllerIndexPath._A & typeof adminCustomReportsControllerIndexQuery._A>;

export type AdminCustomReportsControllerIndexRoute<A = {}> = { match: Match<AdminCustomReportsControllerIndexParams & A>; pathParts: AdminCustomReportsControllerIndexPathParts };

export function adminCustomReportsControllerIndexRoute(): AdminCustomReportsControllerIndexRoute;
export function adminCustomReportsControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminCustomReportsControllerIndexParams>): AdminCustomReportsControllerIndexRoute<t.TypeOf<A>>;
export function adminCustomReportsControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminCustomReportsControllerIndexParams>) {
  return {
    match: (q ? adminCustomReportsControllerIndexPath.then(query(t.intersection([adminCustomReportsControllerIndexQuery, q]))) : adminCustomReportsControllerIndexPath.then(query(adminCustomReportsControllerIndexQuery))).then(end),
    pathParts: adminCustomReportsControllerIndexPathParts,
  };
}

export const adminCustomReportsControllerIndex = (p: AdminCustomReportsControllerIndexParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminCustomReportsControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true));

const adminCustomReportsControllerPostPath = lit("admin").then(lit("custom-reports"));
const adminCustomReportsControllerPostPathParts = ["admin", "custom-reports"] as const;
export type AdminCustomReportsControllerPostPathParts = typeof adminCustomReportsControllerPostPathParts;

const adminCustomReportsControllerPostQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminCustomReportsControllerPostParams = Describe<typeof adminCustomReportsControllerPostPath._A & typeof adminCustomReportsControllerPostQuery._A>;

export type AdminCustomReportsControllerPostRoute<A = {}> = { match: Match<AdminCustomReportsControllerPostParams & A>; pathParts: AdminCustomReportsControllerPostPathParts };

export function adminCustomReportsControllerPostRoute(): AdminCustomReportsControllerPostRoute;
export function adminCustomReportsControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminCustomReportsControllerPostParams>): AdminCustomReportsControllerPostRoute<t.TypeOf<A>>;
export function adminCustomReportsControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminCustomReportsControllerPostParams>) {
  return {
    match: (q ? adminCustomReportsControllerPostPath.then(query(t.intersection([adminCustomReportsControllerPostQuery, q]))) : adminCustomReportsControllerPostPath.then(query(adminCustomReportsControllerPostQuery))).then(end),
    pathParts: adminCustomReportsControllerPostPathParts,
  };
}

export const adminCustomReportsControllerPost = (p: AdminCustomReportsControllerPostParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminCustomReportsControllerPostRoute().match.formatter.run(Route.empty, p).toString(true));

const adminInvoicesControllerIndexPath = lit("admin").then(lit("invoices"));
const adminInvoicesControllerIndexPathParts = ["admin", "invoices"] as const;
export type AdminInvoicesControllerIndexPathParts = typeof adminInvoicesControllerIndexPathParts;

const adminInvoicesControllerIndexQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminInvoicesControllerIndexParams = Describe<typeof adminInvoicesControllerIndexPath._A & typeof adminInvoicesControllerIndexQuery._A>;

export type AdminInvoicesControllerIndexRoute<A = {}> = { match: Match<AdminInvoicesControllerIndexParams & A>; pathParts: AdminInvoicesControllerIndexPathParts };

export function adminInvoicesControllerIndexRoute(): AdminInvoicesControllerIndexRoute;
export function adminInvoicesControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminInvoicesControllerIndexParams>): AdminInvoicesControllerIndexRoute<t.TypeOf<A>>;
export function adminInvoicesControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminInvoicesControllerIndexParams>) {
  return {
    match: (q ? adminInvoicesControllerIndexPath.then(query(t.intersection([adminInvoicesControllerIndexQuery, q]))) : adminInvoicesControllerIndexPath.then(query(adminInvoicesControllerIndexQuery))).then(end),
    pathParts: adminInvoicesControllerIndexPathParts,
  };
}

export const adminInvoicesControllerIndex = (p: AdminInvoicesControllerIndexParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminInvoicesControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true));

const adminInvoicesControllerEditPath = lit("admin").then(lit("invoices")).then(lit("edit")).then(int("id"));
const adminInvoicesControllerEditPathParts = ["admin", "invoices", "edit", "id"] as const;
export type AdminInvoicesControllerEditPathParts = typeof adminInvoicesControllerEditPathParts;

const adminInvoicesControllerEditQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminInvoicesControllerEditParams = Describe<typeof adminInvoicesControllerEditPath._A & typeof adminInvoicesControllerEditQuery._A>;

export type AdminInvoicesControllerEditRoute<A = {}> = { match: Match<AdminInvoicesControllerEditParams & A>; pathParts: AdminInvoicesControllerEditPathParts };

export function adminInvoicesControllerEditRoute(): AdminInvoicesControllerEditRoute;
export function adminInvoicesControllerEditRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminInvoicesControllerEditParams>): AdminInvoicesControllerEditRoute<t.TypeOf<A>>;
export function adminInvoicesControllerEditRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminInvoicesControllerEditParams>) {
  return {
    match: (q ? adminInvoicesControllerEditPath.then(query(t.intersection([adminInvoicesControllerEditQuery, q]))) : adminInvoicesControllerEditPath.then(query(adminInvoicesControllerEditQuery))).then(end),
    pathParts: adminInvoicesControllerEditPathParts,
  };
}

export const adminInvoicesControllerEdit = (p: AdminInvoicesControllerEditParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminInvoicesControllerEditRoute().match.formatter.run(Route.empty, p).toString(true));

const adminInvoicesControllerRegeneratePath = lit("admin").then(lit("invoices")).then(lit("regenerate")).then(int("id"));
const adminInvoicesControllerRegeneratePathParts = ["admin", "invoices", "regenerate", "id"] as const;
export type AdminInvoicesControllerRegeneratePathParts = typeof adminInvoicesControllerRegeneratePathParts;

const adminInvoicesControllerRegenerateQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminInvoicesControllerRegenerateParams = Describe<typeof adminInvoicesControllerRegeneratePath._A & typeof adminInvoicesControllerRegenerateQuery._A>;

export type AdminInvoicesControllerRegenerateRoute<A = {}> = { match: Match<AdminInvoicesControllerRegenerateParams & A>; pathParts: AdminInvoicesControllerRegeneratePathParts };

export function adminInvoicesControllerRegenerateRoute(): AdminInvoicesControllerRegenerateRoute;
export function adminInvoicesControllerRegenerateRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminInvoicesControllerRegenerateParams>): AdminInvoicesControllerRegenerateRoute<t.TypeOf<A>>;
export function adminInvoicesControllerRegenerateRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminInvoicesControllerRegenerateParams>) {
  return {
    match: (q ? adminInvoicesControllerRegeneratePath.then(query(t.intersection([adminInvoicesControllerRegenerateQuery, q]))) : adminInvoicesControllerRegeneratePath.then(query(adminInvoicesControllerRegenerateQuery))).then(end),
    pathParts: adminInvoicesControllerRegeneratePathParts,
  };
}

export const adminInvoicesControllerRegenerate = (p: AdminInvoicesControllerRegenerateParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminInvoicesControllerRegenerateRoute().match.formatter.run(Route.empty, p).toString(true));

const adminInvoicesControllerPostPath = lit("admin").then(lit("invoices")).then(lit("netsuite"));
const adminInvoicesControllerPostPathParts = ["admin", "invoices", "netsuite"] as const;
export type AdminInvoicesControllerPostPathParts = typeof adminInvoicesControllerPostPathParts;

const adminInvoicesControllerPostQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminInvoicesControllerPostParams = Describe<typeof adminInvoicesControllerPostPath._A & typeof adminInvoicesControllerPostQuery._A>;

export type AdminInvoicesControllerPostRoute<A = {}> = { match: Match<AdminInvoicesControllerPostParams & A>; pathParts: AdminInvoicesControllerPostPathParts };

export function adminInvoicesControllerPostRoute(): AdminInvoicesControllerPostRoute;
export function adminInvoicesControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminInvoicesControllerPostParams>): AdminInvoicesControllerPostRoute<t.TypeOf<A>>;
export function adminInvoicesControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminInvoicesControllerPostParams>) {
  return {
    match: (q ? adminInvoicesControllerPostPath.then(query(t.intersection([adminInvoicesControllerPostQuery, q]))) : adminInvoicesControllerPostPath.then(query(adminInvoicesControllerPostQuery))).then(end),
    pathParts: adminInvoicesControllerPostPathParts,
  };
}

export const adminInvoicesControllerPost = (p: AdminInvoicesControllerPostParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminInvoicesControllerPostRoute().match.formatter.run(Route.empty, p).toString(true));

const adminInvoicesControllerDownloadPath = lit("admin").then(lit("invoices")).then(lit("download")).then(int("id"));
const adminInvoicesControllerDownloadPathParts = ["admin", "invoices", "download", "id"] as const;
export type AdminInvoicesControllerDownloadPathParts = typeof adminInvoicesControllerDownloadPathParts;

const adminInvoicesControllerDownloadQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminInvoicesControllerDownloadParams = Describe<typeof adminInvoicesControllerDownloadPath._A & typeof adminInvoicesControllerDownloadQuery._A>;

export type AdminInvoicesControllerDownloadRoute<A = {}> = { match: Match<AdminInvoicesControllerDownloadParams & A>; pathParts: AdminInvoicesControllerDownloadPathParts };

export function adminInvoicesControllerDownloadRoute(): AdminInvoicesControllerDownloadRoute;
export function adminInvoicesControllerDownloadRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminInvoicesControllerDownloadParams>): AdminInvoicesControllerDownloadRoute<t.TypeOf<A>>;
export function adminInvoicesControllerDownloadRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminInvoicesControllerDownloadParams>) {
  return {
    match: (q ? adminInvoicesControllerDownloadPath.then(query(t.intersection([adminInvoicesControllerDownloadQuery, q]))) : adminInvoicesControllerDownloadPath.then(query(adminInvoicesControllerDownloadQuery))).then(end),
    pathParts: adminInvoicesControllerDownloadPathParts,
  };
}

export const adminInvoicesControllerDownload = (p: AdminInvoicesControllerDownloadParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminInvoicesControllerDownloadRoute().match.formatter.run(Route.empty, p).toString(true));

const adminInvoicesControllerSendPath = lit("admin").then(lit("invoices")).then(lit("send")).then(int("id"));
const adminInvoicesControllerSendPathParts = ["admin", "invoices", "send", "id"] as const;
export type AdminInvoicesControllerSendPathParts = typeof adminInvoicesControllerSendPathParts;

const adminInvoicesControllerSendQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminInvoicesControllerSendParams = Describe<typeof adminInvoicesControllerSendPath._A & typeof adminInvoicesControllerSendQuery._A>;

export type AdminInvoicesControllerSendRoute<A = {}> = { match: Match<AdminInvoicesControllerSendParams & A>; pathParts: AdminInvoicesControllerSendPathParts };

export function adminInvoicesControllerSendRoute(): AdminInvoicesControllerSendRoute;
export function adminInvoicesControllerSendRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminInvoicesControllerSendParams>): AdminInvoicesControllerSendRoute<t.TypeOf<A>>;
export function adminInvoicesControllerSendRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminInvoicesControllerSendParams>) {
  return {
    match: (q ? adminInvoicesControllerSendPath.then(query(t.intersection([adminInvoicesControllerSendQuery, q]))) : adminInvoicesControllerSendPath.then(query(adminInvoicesControllerSendQuery))).then(end),
    pathParts: adminInvoicesControllerSendPathParts,
  };
}

export const adminInvoicesControllerSend = (p: AdminInvoicesControllerSendParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminInvoicesControllerSendRoute().match.formatter.run(Route.empty, p).toString(true));

const adminInvoicesControllerPostSendPath = lit("admin").then(lit("invoices")).then(lit("send")).then(int("id"));
const adminInvoicesControllerPostSendPathParts = ["admin", "invoices", "send", "id"] as const;
export type AdminInvoicesControllerPostSendPathParts = typeof adminInvoicesControllerPostSendPathParts;

const adminInvoicesControllerPostSendQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminInvoicesControllerPostSendParams = Describe<typeof adminInvoicesControllerPostSendPath._A & typeof adminInvoicesControllerPostSendQuery._A>;

export type AdminInvoicesControllerPostSendRoute<A = {}> = { match: Match<AdminInvoicesControllerPostSendParams & A>; pathParts: AdminInvoicesControllerPostSendPathParts };

export function adminInvoicesControllerPostSendRoute(): AdminInvoicesControllerPostSendRoute;
export function adminInvoicesControllerPostSendRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminInvoicesControllerPostSendParams>): AdminInvoicesControllerPostSendRoute<t.TypeOf<A>>;
export function adminInvoicesControllerPostSendRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminInvoicesControllerPostSendParams>) {
  return {
    match: (q ? adminInvoicesControllerPostSendPath.then(query(t.intersection([adminInvoicesControllerPostSendQuery, q]))) : adminInvoicesControllerPostSendPath.then(query(adminInvoicesControllerPostSendQuery))).then(end),
    pathParts: adminInvoicesControllerPostSendPathParts,
  };
}

export const adminInvoicesControllerPostSend = (p: AdminInvoicesControllerPostSendParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminInvoicesControllerPostSendRoute().match.formatter.run(Route.empty, p).toString(true));

const adminInvoicesControllerDeletePath = lit("admin").then(lit("invoices")).then(lit("delete")).then(int("id"));
const adminInvoicesControllerDeletePathParts = ["admin", "invoices", "delete", "id"] as const;
export type AdminInvoicesControllerDeletePathParts = typeof adminInvoicesControllerDeletePathParts;

const adminInvoicesControllerDeleteQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminInvoicesControllerDeleteParams = Describe<typeof adminInvoicesControllerDeletePath._A & typeof adminInvoicesControllerDeleteQuery._A>;

export type AdminInvoicesControllerDeleteRoute<A = {}> = { match: Match<AdminInvoicesControllerDeleteParams & A>; pathParts: AdminInvoicesControllerDeletePathParts };

export function adminInvoicesControllerDeleteRoute(): AdminInvoicesControllerDeleteRoute;
export function adminInvoicesControllerDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminInvoicesControllerDeleteParams>): AdminInvoicesControllerDeleteRoute<t.TypeOf<A>>;
export function adminInvoicesControllerDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminInvoicesControllerDeleteParams>) {
  return {
    match: (q ? adminInvoicesControllerDeletePath.then(query(t.intersection([adminInvoicesControllerDeleteQuery, q]))) : adminInvoicesControllerDeletePath.then(query(adminInvoicesControllerDeleteQuery))).then(end),
    pathParts: adminInvoicesControllerDeletePathParts,
  };
}

export const adminInvoicesControllerDelete = (p: AdminInvoicesControllerDeleteParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminInvoicesControllerDeleteRoute().match.formatter.run(Route.empty, p).toString(true));

const adminShortUrlsControllerIndexPath = lit("admin").then(lit("short-urls"));
const adminShortUrlsControllerIndexPathParts = ["admin", "short-urls"] as const;
export type AdminShortUrlsControllerIndexPathParts = typeof adminShortUrlsControllerIndexPathParts;

const adminShortUrlsControllerIndexQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminShortUrlsControllerIndexParams = Describe<typeof adminShortUrlsControllerIndexPath._A & typeof adminShortUrlsControllerIndexQuery._A>;

export type AdminShortUrlsControllerIndexRoute<A = {}> = { match: Match<AdminShortUrlsControllerIndexParams & A>; pathParts: AdminShortUrlsControllerIndexPathParts };

export function adminShortUrlsControllerIndexRoute(): AdminShortUrlsControllerIndexRoute;
export function adminShortUrlsControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminShortUrlsControllerIndexParams>): AdminShortUrlsControllerIndexRoute<t.TypeOf<A>>;
export function adminShortUrlsControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminShortUrlsControllerIndexParams>) {
  return {
    match: (q ? adminShortUrlsControllerIndexPath.then(query(t.intersection([adminShortUrlsControllerIndexQuery, q]))) : adminShortUrlsControllerIndexPath.then(query(adminShortUrlsControllerIndexQuery))).then(end),
    pathParts: adminShortUrlsControllerIndexPathParts,
  };
}

export const adminShortUrlsControllerIndex = (p: AdminShortUrlsControllerIndexParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminShortUrlsControllerIndexRoute().match.formatter.run(Route.empty, p).toString(true));

const adminShortUrlsControllerDeletePath = lit("admin").then(lit("short-urls")).then(lit("delete")).then(int("id"));
const adminShortUrlsControllerDeletePathParts = ["admin", "short-urls", "delete", "id"] as const;
export type AdminShortUrlsControllerDeletePathParts = typeof adminShortUrlsControllerDeletePathParts;

const adminShortUrlsControllerDeleteQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminShortUrlsControllerDeleteParams = Describe<typeof adminShortUrlsControllerDeletePath._A & typeof adminShortUrlsControllerDeleteQuery._A>;

export type AdminShortUrlsControllerDeleteRoute<A = {}> = { match: Match<AdminShortUrlsControllerDeleteParams & A>; pathParts: AdminShortUrlsControllerDeletePathParts };

export function adminShortUrlsControllerDeleteRoute(): AdminShortUrlsControllerDeleteRoute;
export function adminShortUrlsControllerDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminShortUrlsControllerDeleteParams>): AdminShortUrlsControllerDeleteRoute<t.TypeOf<A>>;
export function adminShortUrlsControllerDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminShortUrlsControllerDeleteParams>) {
  return {
    match: (q ? adminShortUrlsControllerDeletePath.then(query(t.intersection([adminShortUrlsControllerDeleteQuery, q]))) : adminShortUrlsControllerDeletePath.then(query(adminShortUrlsControllerDeleteQuery))).then(end),
    pathParts: adminShortUrlsControllerDeletePathParts,
  };
}

export const adminShortUrlsControllerDelete = (p: AdminShortUrlsControllerDeleteParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminShortUrlsControllerDeleteRoute().match.formatter.run(Route.empty, p).toString(true));

const adminShortUrlsControllerPostPath = lit("admin").then(lit("short-urls"));
const adminShortUrlsControllerPostPathParts = ["admin", "short-urls"] as const;
export type AdminShortUrlsControllerPostPathParts = typeof adminShortUrlsControllerPostPathParts;

const adminShortUrlsControllerPostQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminShortUrlsControllerPostParams = Describe<typeof adminShortUrlsControllerPostPath._A & typeof adminShortUrlsControllerPostQuery._A>;

export type AdminShortUrlsControllerPostRoute<A = {}> = { match: Match<AdminShortUrlsControllerPostParams & A>; pathParts: AdminShortUrlsControllerPostPathParts };

export function adminShortUrlsControllerPostRoute(): AdminShortUrlsControllerPostRoute;
export function adminShortUrlsControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminShortUrlsControllerPostParams>): AdminShortUrlsControllerPostRoute<t.TypeOf<A>>;
export function adminShortUrlsControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminShortUrlsControllerPostParams>) {
  return {
    match: (q ? adminShortUrlsControllerPostPath.then(query(t.intersection([adminShortUrlsControllerPostQuery, q]))) : adminShortUrlsControllerPostPath.then(query(adminShortUrlsControllerPostQuery))).then(end),
    pathParts: adminShortUrlsControllerPostPathParts,
  };
}

export const adminShortUrlsControllerPost = (p: AdminShortUrlsControllerPostParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminShortUrlsControllerPostRoute().match.formatter.run(Route.empty, p).toString(true));

const adminShortUrlsControllerShowPath = lit("admin").then(lit("short-urls")).then(int("id"));
const adminShortUrlsControllerShowPathParts = ["admin", "short-urls", "id"] as const;
export type AdminShortUrlsControllerShowPathParts = typeof adminShortUrlsControllerShowPathParts;

const adminShortUrlsControllerShowQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminShortUrlsControllerShowParams = Describe<typeof adminShortUrlsControllerShowPath._A & typeof adminShortUrlsControllerShowQuery._A>;

export type AdminShortUrlsControllerShowRoute<A = {}> = { match: Match<AdminShortUrlsControllerShowParams & A>; pathParts: AdminShortUrlsControllerShowPathParts };

export function adminShortUrlsControllerShowRoute(): AdminShortUrlsControllerShowRoute;
export function adminShortUrlsControllerShowRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminShortUrlsControllerShowParams>): AdminShortUrlsControllerShowRoute<t.TypeOf<A>>;
export function adminShortUrlsControllerShowRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminShortUrlsControllerShowParams>) {
  return {
    match: (q ? adminShortUrlsControllerShowPath.then(query(t.intersection([adminShortUrlsControllerShowQuery, q]))) : adminShortUrlsControllerShowPath.then(query(adminShortUrlsControllerShowQuery))).then(end),
    pathParts: adminShortUrlsControllerShowPathParts,
  };
}

export const adminShortUrlsControllerShow = (p: AdminShortUrlsControllerShowParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminShortUrlsControllerShowRoute().match.formatter.run(Route.empty, p).toString(true));

const adminMarketInsightsControllerIndexPath = lit("admin").then(lit("market-insights"));
const adminMarketInsightsControllerIndexPathParts = ["admin", "market-insights"] as const;
export type AdminMarketInsightsControllerIndexPathParts = typeof adminMarketInsightsControllerIndexPathParts;

const adminMarketInsightsControllerIndexQuery = t.strict({});
export type AdminMarketInsightsControllerIndexParams = Describe<typeof adminMarketInsightsControllerIndexPath._A & typeof adminMarketInsightsControllerIndexQuery._A>;

export type AdminMarketInsightsControllerIndexRoute<A = {}> = { match: Match<AdminMarketInsightsControllerIndexParams & A>; pathParts: AdminMarketInsightsControllerIndexPathParts };

export function adminMarketInsightsControllerIndexRoute(): AdminMarketInsightsControllerIndexRoute;
export function adminMarketInsightsControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminMarketInsightsControllerIndexParams>): AdminMarketInsightsControllerIndexRoute<t.TypeOf<A>>;
export function adminMarketInsightsControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminMarketInsightsControllerIndexParams>) {
  return {
    match: (q ? adminMarketInsightsControllerIndexPath.then(query(t.intersection([adminMarketInsightsControllerIndexQuery, q]))) : adminMarketInsightsControllerIndexPath.then(query(adminMarketInsightsControllerIndexQuery))).then(end),
    pathParts: adminMarketInsightsControllerIndexPathParts,
  };
}

export const adminMarketInsightsControllerIndex = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminMarketInsightsControllerIndexRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminMarketInsightsControllerCancelPath = lit("admin").then(lit("market-insights")).then(lit("cancel")).then(int("mId"));
const adminMarketInsightsControllerCancelPathParts = ["admin", "market-insights", "cancel", "mId"] as const;
export type AdminMarketInsightsControllerCancelPathParts = typeof adminMarketInsightsControllerCancelPathParts;

const adminMarketInsightsControllerCancelQuery = t.strict({});
export type AdminMarketInsightsControllerCancelParams = Describe<typeof adminMarketInsightsControllerCancelPath._A & typeof adminMarketInsightsControllerCancelQuery._A>;

export type AdminMarketInsightsControllerCancelRoute<A = {}> = { match: Match<AdminMarketInsightsControllerCancelParams & A>; pathParts: AdminMarketInsightsControllerCancelPathParts };

export function adminMarketInsightsControllerCancelRoute(): AdminMarketInsightsControllerCancelRoute;
export function adminMarketInsightsControllerCancelRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminMarketInsightsControllerCancelParams>): AdminMarketInsightsControllerCancelRoute<t.TypeOf<A>>;
export function adminMarketInsightsControllerCancelRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminMarketInsightsControllerCancelParams>) {
  return {
    match: (q ? adminMarketInsightsControllerCancelPath.then(query(t.intersection([adminMarketInsightsControllerCancelQuery, q]))) : adminMarketInsightsControllerCancelPath.then(query(adminMarketInsightsControllerCancelQuery))).then(end),
    pathParts: adminMarketInsightsControllerCancelPathParts,
  };
}

export const adminMarketInsightsControllerCancel = (p: AdminMarketInsightsControllerCancelParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminMarketInsightsControllerCancelRoute().match.formatter.run(Route.empty, p).toString(true));

const adminMarketInsightsControllerDeletePath = lit("admin").then(lit("market-insights")).then(lit("delete")).then(int("insightId"));
const adminMarketInsightsControllerDeletePathParts = ["admin", "market-insights", "delete", "insightId"] as const;
export type AdminMarketInsightsControllerDeletePathParts = typeof adminMarketInsightsControllerDeletePathParts;

const adminMarketInsightsControllerDeleteQuery = t.strict({});
export type AdminMarketInsightsControllerDeleteParams = Describe<typeof adminMarketInsightsControllerDeletePath._A & typeof adminMarketInsightsControllerDeleteQuery._A>;

export type AdminMarketInsightsControllerDeleteRoute<A = {}> = { match: Match<AdminMarketInsightsControllerDeleteParams & A>; pathParts: AdminMarketInsightsControllerDeletePathParts };

export function adminMarketInsightsControllerDeleteRoute(): AdminMarketInsightsControllerDeleteRoute;
export function adminMarketInsightsControllerDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminMarketInsightsControllerDeleteParams>): AdminMarketInsightsControllerDeleteRoute<t.TypeOf<A>>;
export function adminMarketInsightsControllerDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminMarketInsightsControllerDeleteParams>) {
  return {
    match: (q ? adminMarketInsightsControllerDeletePath.then(query(t.intersection([adminMarketInsightsControllerDeleteQuery, q]))) : adminMarketInsightsControllerDeletePath.then(query(adminMarketInsightsControllerDeleteQuery))).then(end),
    pathParts: adminMarketInsightsControllerDeletePathParts,
  };
}

export const adminMarketInsightsControllerDelete = (p: AdminMarketInsightsControllerDeleteParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminMarketInsightsControllerDeleteRoute().match.formatter.run(Route.empty, p).toString(true));

const adminMarketInsightsControllerEditPath = lit("admin").then(lit("market-insights")).then(lit("edit")).then(int("insightId"));
const adminMarketInsightsControllerEditPathParts = ["admin", "market-insights", "edit", "insightId"] as const;
export type AdminMarketInsightsControllerEditPathParts = typeof adminMarketInsightsControllerEditPathParts;

const adminMarketInsightsControllerEditQuery = t.strict({});
export type AdminMarketInsightsControllerEditParams = Describe<typeof adminMarketInsightsControllerEditPath._A & typeof adminMarketInsightsControllerEditQuery._A>;

export type AdminMarketInsightsControllerEditRoute<A = {}> = { match: Match<AdminMarketInsightsControllerEditParams & A>; pathParts: AdminMarketInsightsControllerEditPathParts };

export function adminMarketInsightsControllerEditRoute(): AdminMarketInsightsControllerEditRoute;
export function adminMarketInsightsControllerEditRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminMarketInsightsControllerEditParams>): AdminMarketInsightsControllerEditRoute<t.TypeOf<A>>;
export function adminMarketInsightsControllerEditRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminMarketInsightsControllerEditParams>) {
  return {
    match: (q ? adminMarketInsightsControllerEditPath.then(query(t.intersection([adminMarketInsightsControllerEditQuery, q]))) : adminMarketInsightsControllerEditPath.then(query(adminMarketInsightsControllerEditQuery))).then(end),
    pathParts: adminMarketInsightsControllerEditPathParts,
  };
}

export const adminMarketInsightsControllerEdit = (p: AdminMarketInsightsControllerEditParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminMarketInsightsControllerEditRoute().match.formatter.run(Route.empty, p).toString(true));

const adminMarketInsightsControllerPostPath = lit("admin").then(lit("market-insights"));
const adminMarketInsightsControllerPostPathParts = ["admin", "market-insights"] as const;
export type AdminMarketInsightsControllerPostPathParts = typeof adminMarketInsightsControllerPostPathParts;

const adminMarketInsightsControllerPostQuery = t.strict({});
export type AdminMarketInsightsControllerPostParams = Describe<typeof adminMarketInsightsControllerPostPath._A & typeof adminMarketInsightsControllerPostQuery._A>;

export type AdminMarketInsightsControllerPostRoute<A = {}> = { match: Match<AdminMarketInsightsControllerPostParams & A>; pathParts: AdminMarketInsightsControllerPostPathParts };

export function adminMarketInsightsControllerPostRoute(): AdminMarketInsightsControllerPostRoute;
export function adminMarketInsightsControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminMarketInsightsControllerPostParams>): AdminMarketInsightsControllerPostRoute<t.TypeOf<A>>;
export function adminMarketInsightsControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminMarketInsightsControllerPostParams>) {
  return {
    match: (q ? adminMarketInsightsControllerPostPath.then(query(t.intersection([adminMarketInsightsControllerPostQuery, q]))) : adminMarketInsightsControllerPostPath.then(query(adminMarketInsightsControllerPostQuery))).then(end),
    pathParts: adminMarketInsightsControllerPostPathParts,
  };
}

export const adminMarketInsightsControllerPost = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminMarketInsightsControllerPostRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminMarketInsightsControllerPublishPath = lit("admin").then(lit("market-insights")).then(lit("publish")).then(int("mId"));
const adminMarketInsightsControllerPublishPathParts = ["admin", "market-insights", "publish", "mId"] as const;
export type AdminMarketInsightsControllerPublishPathParts = typeof adminMarketInsightsControllerPublishPathParts;

const adminMarketInsightsControllerPublishQuery = t.strict({});
export type AdminMarketInsightsControllerPublishParams = Describe<typeof adminMarketInsightsControllerPublishPath._A & typeof adminMarketInsightsControllerPublishQuery._A>;

export type AdminMarketInsightsControllerPublishRoute<A = {}> = { match: Match<AdminMarketInsightsControllerPublishParams & A>; pathParts: AdminMarketInsightsControllerPublishPathParts };

export function adminMarketInsightsControllerPublishRoute(): AdminMarketInsightsControllerPublishRoute;
export function adminMarketInsightsControllerPublishRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminMarketInsightsControllerPublishParams>): AdminMarketInsightsControllerPublishRoute<t.TypeOf<A>>;
export function adminMarketInsightsControllerPublishRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminMarketInsightsControllerPublishParams>) {
  return {
    match: (q ? adminMarketInsightsControllerPublishPath.then(query(t.intersection([adminMarketInsightsControllerPublishQuery, q]))) : adminMarketInsightsControllerPublishPath.then(query(adminMarketInsightsControllerPublishQuery))).then(end),
    pathParts: adminMarketInsightsControllerPublishPathParts,
  };
}

export const adminMarketInsightsControllerPublish = (p: AdminMarketInsightsControllerPublishParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminMarketInsightsControllerPublishRoute().match.formatter.run(Route.empty, p).toString(true));

const adminBondLinkResourcesControllerDeletePath = lit("admin").then(lit("bondlink-resources")).then(lit("delete")).then(int("id"));
const adminBondLinkResourcesControllerDeletePathParts = ["admin", "bondlink-resources", "delete", "id"] as const;
export type AdminBondLinkResourcesControllerDeletePathParts = typeof adminBondLinkResourcesControllerDeletePathParts;

const adminBondLinkResourcesControllerDeleteQuery = t.strict({});
export type AdminBondLinkResourcesControllerDeleteParams = Describe<typeof adminBondLinkResourcesControllerDeletePath._A & typeof adminBondLinkResourcesControllerDeleteQuery._A>;

export type AdminBondLinkResourcesControllerDeleteRoute<A = {}> = { match: Match<AdminBondLinkResourcesControllerDeleteParams & A>; pathParts: AdminBondLinkResourcesControllerDeletePathParts };

export function adminBondLinkResourcesControllerDeleteRoute(): AdminBondLinkResourcesControllerDeleteRoute;
export function adminBondLinkResourcesControllerDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminBondLinkResourcesControllerDeleteParams>): AdminBondLinkResourcesControllerDeleteRoute<t.TypeOf<A>>;
export function adminBondLinkResourcesControllerDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminBondLinkResourcesControllerDeleteParams>) {
  return {
    match: (q ? adminBondLinkResourcesControllerDeletePath.then(query(t.intersection([adminBondLinkResourcesControllerDeleteQuery, q]))) : adminBondLinkResourcesControllerDeletePath.then(query(adminBondLinkResourcesControllerDeleteQuery))).then(end),
    pathParts: adminBondLinkResourcesControllerDeletePathParts,
  };
}

export const adminBondLinkResourcesControllerDelete = (p: AdminBondLinkResourcesControllerDeleteParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminBondLinkResourcesControllerDeleteRoute().match.formatter.run(Route.empty, p).toString(true));

const adminBondLinkResourcesControllerEditPath = lit("admin").then(lit("bondlink-resources")).then(int("id"));
const adminBondLinkResourcesControllerEditPathParts = ["admin", "bondlink-resources", "id"] as const;
export type AdminBondLinkResourcesControllerEditPathParts = typeof adminBondLinkResourcesControllerEditPathParts;

const adminBondLinkResourcesControllerEditQuery = t.strict({});
export type AdminBondLinkResourcesControllerEditParams = Describe<typeof adminBondLinkResourcesControllerEditPath._A & typeof adminBondLinkResourcesControllerEditQuery._A>;

export type AdminBondLinkResourcesControllerEditRoute<A = {}> = { match: Match<AdminBondLinkResourcesControllerEditParams & A>; pathParts: AdminBondLinkResourcesControllerEditPathParts };

export function adminBondLinkResourcesControllerEditRoute(): AdminBondLinkResourcesControllerEditRoute;
export function adminBondLinkResourcesControllerEditRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminBondLinkResourcesControllerEditParams>): AdminBondLinkResourcesControllerEditRoute<t.TypeOf<A>>;
export function adminBondLinkResourcesControllerEditRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminBondLinkResourcesControllerEditParams>) {
  return {
    match: (q ? adminBondLinkResourcesControllerEditPath.then(query(t.intersection([adminBondLinkResourcesControllerEditQuery, q]))) : adminBondLinkResourcesControllerEditPath.then(query(adminBondLinkResourcesControllerEditQuery))).then(end),
    pathParts: adminBondLinkResourcesControllerEditPathParts,
  };
}

export const adminBondLinkResourcesControllerEdit = (p: AdminBondLinkResourcesControllerEditParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminBondLinkResourcesControllerEditRoute().match.formatter.run(Route.empty, p).toString(true));

const adminBondLinkResourcesControllerIndexPath = lit("admin").then(lit("bondlink-resources"));
const adminBondLinkResourcesControllerIndexPathParts = ["admin", "bondlink-resources"] as const;
export type AdminBondLinkResourcesControllerIndexPathParts = typeof adminBondLinkResourcesControllerIndexPathParts;

const adminBondLinkResourcesControllerIndexQuery = t.strict({});
export type AdminBondLinkResourcesControllerIndexParams = Describe<typeof adminBondLinkResourcesControllerIndexPath._A & typeof adminBondLinkResourcesControllerIndexQuery._A>;

export type AdminBondLinkResourcesControllerIndexRoute<A = {}> = { match: Match<AdminBondLinkResourcesControllerIndexParams & A>; pathParts: AdminBondLinkResourcesControllerIndexPathParts };

export function adminBondLinkResourcesControllerIndexRoute(): AdminBondLinkResourcesControllerIndexRoute;
export function adminBondLinkResourcesControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminBondLinkResourcesControllerIndexParams>): AdminBondLinkResourcesControllerIndexRoute<t.TypeOf<A>>;
export function adminBondLinkResourcesControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminBondLinkResourcesControllerIndexParams>) {
  return {
    match: (q ? adminBondLinkResourcesControllerIndexPath.then(query(t.intersection([adminBondLinkResourcesControllerIndexQuery, q]))) : adminBondLinkResourcesControllerIndexPath.then(query(adminBondLinkResourcesControllerIndexQuery))).then(end),
    pathParts: adminBondLinkResourcesControllerIndexPathParts,
  };
}

export const adminBondLinkResourcesControllerIndex = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminBondLinkResourcesControllerIndexRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminBondLinkResourcesControllerPostPath = lit("admin").then(lit("bondlink-resources"));
const adminBondLinkResourcesControllerPostPathParts = ["admin", "bondlink-resources"] as const;
export type AdminBondLinkResourcesControllerPostPathParts = typeof adminBondLinkResourcesControllerPostPathParts;

const adminBondLinkResourcesControllerPostQuery = t.strict({});
export type AdminBondLinkResourcesControllerPostParams = Describe<typeof adminBondLinkResourcesControllerPostPath._A & typeof adminBondLinkResourcesControllerPostQuery._A>;

export type AdminBondLinkResourcesControllerPostRoute<A = {}> = { match: Match<AdminBondLinkResourcesControllerPostParams & A>; pathParts: AdminBondLinkResourcesControllerPostPathParts };

export function adminBondLinkResourcesControllerPostRoute(): AdminBondLinkResourcesControllerPostRoute;
export function adminBondLinkResourcesControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminBondLinkResourcesControllerPostParams>): AdminBondLinkResourcesControllerPostRoute<t.TypeOf<A>>;
export function adminBondLinkResourcesControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminBondLinkResourcesControllerPostParams>) {
  return {
    match: (q ? adminBondLinkResourcesControllerPostPath.then(query(t.intersection([adminBondLinkResourcesControllerPostQuery, q]))) : adminBondLinkResourcesControllerPostPath.then(query(adminBondLinkResourcesControllerPostQuery))).then(end),
    pathParts: adminBondLinkResourcesControllerPostPathParts,
  };
}

export const adminBondLinkResourcesControllerPost = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminBondLinkResourcesControllerPostRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminIssuerRegistrationControllerIndexPath = lit("issuer-registration");
const adminIssuerRegistrationControllerIndexPathParts = ["issuer-registration"] as const;
export type AdminIssuerRegistrationControllerIndexPathParts = typeof adminIssuerRegistrationControllerIndexPathParts;

const adminIssuerRegistrationControllerIndexQuery = t.strict({});
export type AdminIssuerRegistrationControllerIndexParams = Describe<typeof adminIssuerRegistrationControllerIndexPath._A & typeof adminIssuerRegistrationControllerIndexQuery._A>;

export type AdminIssuerRegistrationControllerIndexRoute<A = {}> = { match: Match<AdminIssuerRegistrationControllerIndexParams & A>; pathParts: AdminIssuerRegistrationControllerIndexPathParts };

export function adminIssuerRegistrationControllerIndexRoute(): AdminIssuerRegistrationControllerIndexRoute;
export function adminIssuerRegistrationControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminIssuerRegistrationControllerIndexParams>): AdminIssuerRegistrationControllerIndexRoute<t.TypeOf<A>>;
export function adminIssuerRegistrationControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminIssuerRegistrationControllerIndexParams>) {
  return {
    match: (q ? adminIssuerRegistrationControllerIndexPath.then(query(t.intersection([adminIssuerRegistrationControllerIndexQuery, q]))) : adminIssuerRegistrationControllerIndexPath.then(query(adminIssuerRegistrationControllerIndexQuery))).then(end),
    pathParts: adminIssuerRegistrationControllerIndexPathParts,
  };
}

export const adminIssuerRegistrationControllerIndex = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminIssuerRegistrationControllerIndexRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminIssuerRegistrationControllerPostPath = lit("issuer-registration");
const adminIssuerRegistrationControllerPostPathParts = ["issuer-registration"] as const;
export type AdminIssuerRegistrationControllerPostPathParts = typeof adminIssuerRegistrationControllerPostPathParts;

const adminIssuerRegistrationControllerPostQuery = t.strict({});
export type AdminIssuerRegistrationControllerPostParams = Describe<typeof adminIssuerRegistrationControllerPostPath._A & typeof adminIssuerRegistrationControllerPostQuery._A>;

export type AdminIssuerRegistrationControllerPostRoute<A = {}> = { match: Match<AdminIssuerRegistrationControllerPostParams & A>; pathParts: AdminIssuerRegistrationControllerPostPathParts };

export function adminIssuerRegistrationControllerPostRoute(): AdminIssuerRegistrationControllerPostRoute;
export function adminIssuerRegistrationControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminIssuerRegistrationControllerPostParams>): AdminIssuerRegistrationControllerPostRoute<t.TypeOf<A>>;
export function adminIssuerRegistrationControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminIssuerRegistrationControllerPostParams>) {
  return {
    match: (q ? adminIssuerRegistrationControllerPostPath.then(query(t.intersection([adminIssuerRegistrationControllerPostQuery, q]))) : adminIssuerRegistrationControllerPostPath.then(query(adminIssuerRegistrationControllerPostQuery))).then(end),
    pathParts: adminIssuerRegistrationControllerPostPathParts,
  };
}

export const adminIssuerRegistrationControllerPost = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminIssuerRegistrationControllerPostRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminBanksControllerIndexPath = lit("admin").then(lit("banks"));
const adminAdminBanksControllerIndexPathParts = ["admin", "banks"] as const;
export type AdminAdminBanksControllerIndexPathParts = typeof adminAdminBanksControllerIndexPathParts;

const adminAdminBanksControllerIndexQuery = t.strict({});
export type AdminAdminBanksControllerIndexParams = Describe<typeof adminAdminBanksControllerIndexPath._A & typeof adminAdminBanksControllerIndexQuery._A>;

export type AdminAdminBanksControllerIndexRoute<A = {}> = { match: Match<AdminAdminBanksControllerIndexParams & A>; pathParts: AdminAdminBanksControllerIndexPathParts };

export function adminAdminBanksControllerIndexRoute(): AdminAdminBanksControllerIndexRoute;
export function adminAdminBanksControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminBanksControllerIndexParams>): AdminAdminBanksControllerIndexRoute<t.TypeOf<A>>;
export function adminAdminBanksControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminBanksControllerIndexParams>) {
  return {
    match: (q ? adminAdminBanksControllerIndexPath.then(query(t.intersection([adminAdminBanksControllerIndexQuery, q]))) : adminAdminBanksControllerIndexPath.then(query(adminAdminBanksControllerIndexQuery))).then(end),
    pathParts: adminAdminBanksControllerIndexPathParts,
  };
}

export const adminAdminBanksControllerIndex = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminBanksControllerIndexRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminBanksControllerEditPath = lit("admin").then(lit("banks")).then(int("id"));
const adminAdminBanksControllerEditPathParts = ["admin", "banks", "id"] as const;
export type AdminAdminBanksControllerEditPathParts = typeof adminAdminBanksControllerEditPathParts;

const adminAdminBanksControllerEditQuery = t.strict({});
export type AdminAdminBanksControllerEditParams = Describe<typeof adminAdminBanksControllerEditPath._A & typeof adminAdminBanksControllerEditQuery._A>;

export type AdminAdminBanksControllerEditRoute<A = {}> = { match: Match<AdminAdminBanksControllerEditParams & A>; pathParts: AdminAdminBanksControllerEditPathParts };

export function adminAdminBanksControllerEditRoute(): AdminAdminBanksControllerEditRoute;
export function adminAdminBanksControllerEditRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminBanksControllerEditParams>): AdminAdminBanksControllerEditRoute<t.TypeOf<A>>;
export function adminAdminBanksControllerEditRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminBanksControllerEditParams>) {
  return {
    match: (q ? adminAdminBanksControllerEditPath.then(query(t.intersection([adminAdminBanksControllerEditQuery, q]))) : adminAdminBanksControllerEditPath.then(query(adminAdminBanksControllerEditQuery))).then(end),
    pathParts: adminAdminBanksControllerEditPathParts,
  };
}

export const adminAdminBanksControllerEdit = (p: AdminAdminBanksControllerEditParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminBanksControllerEditRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminBanksControllerEditLogoPath = lit("admin").then(lit("banks")).then(int("id")).then(lit("logo"));
const adminAdminBanksControllerEditLogoPathParts = ["admin", "banks", "id", "logo"] as const;
export type AdminAdminBanksControllerEditLogoPathParts = typeof adminAdminBanksControllerEditLogoPathParts;

const adminAdminBanksControllerEditLogoQuery = t.strict({});
export type AdminAdminBanksControllerEditLogoParams = Describe<typeof adminAdminBanksControllerEditLogoPath._A & typeof adminAdminBanksControllerEditLogoQuery._A>;

export type AdminAdminBanksControllerEditLogoRoute<A = {}> = { match: Match<AdminAdminBanksControllerEditLogoParams & A>; pathParts: AdminAdminBanksControllerEditLogoPathParts };

export function adminAdminBanksControllerEditLogoRoute(): AdminAdminBanksControllerEditLogoRoute;
export function adminAdminBanksControllerEditLogoRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminBanksControllerEditLogoParams>): AdminAdminBanksControllerEditLogoRoute<t.TypeOf<A>>;
export function adminAdminBanksControllerEditLogoRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminBanksControllerEditLogoParams>) {
  return {
    match: (q ? adminAdminBanksControllerEditLogoPath.then(query(t.intersection([adminAdminBanksControllerEditLogoQuery, q]))) : adminAdminBanksControllerEditLogoPath.then(query(adminAdminBanksControllerEditLogoQuery))).then(end),
    pathParts: adminAdminBanksControllerEditLogoPathParts,
  };
}

export const adminAdminBanksControllerEditLogo = (p: AdminAdminBanksControllerEditLogoParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminBanksControllerEditLogoRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminBanksControllerPostPath = lit("admin").then(lit("banks"));
const adminAdminBanksControllerPostPathParts = ["admin", "banks"] as const;
export type AdminAdminBanksControllerPostPathParts = typeof adminAdminBanksControllerPostPathParts;

const adminAdminBanksControllerPostQuery = t.strict({});
export type AdminAdminBanksControllerPostParams = Describe<typeof adminAdminBanksControllerPostPath._A & typeof adminAdminBanksControllerPostQuery._A>;

export type AdminAdminBanksControllerPostRoute<A = {}> = { match: Match<AdminAdminBanksControllerPostParams & A>; pathParts: AdminAdminBanksControllerPostPathParts };

export function adminAdminBanksControllerPostRoute(): AdminAdminBanksControllerPostRoute;
export function adminAdminBanksControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminBanksControllerPostParams>): AdminAdminBanksControllerPostRoute<t.TypeOf<A>>;
export function adminAdminBanksControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminBanksControllerPostParams>) {
  return {
    match: (q ? adminAdminBanksControllerPostPath.then(query(t.intersection([adminAdminBanksControllerPostQuery, q]))) : adminAdminBanksControllerPostPath.then(query(adminAdminBanksControllerPostQuery))).then(end),
    pathParts: adminAdminBanksControllerPostPathParts,
  };
}

export const adminAdminBanksControllerPost = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminBanksControllerPostRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminBanksControllerPostLogoPath = lit("admin").then(lit("banks")).then(int("id")).then(lit("logo"));
const adminAdminBanksControllerPostLogoPathParts = ["admin", "banks", "id", "logo"] as const;
export type AdminAdminBanksControllerPostLogoPathParts = typeof adminAdminBanksControllerPostLogoPathParts;

const adminAdminBanksControllerPostLogoQuery = t.strict({});
export type AdminAdminBanksControllerPostLogoParams = Describe<typeof adminAdminBanksControllerPostLogoPath._A & typeof adminAdminBanksControllerPostLogoQuery._A>;

export type AdminAdminBanksControllerPostLogoRoute<A = {}> = { match: Match<AdminAdminBanksControllerPostLogoParams & A>; pathParts: AdminAdminBanksControllerPostLogoPathParts };

export function adminAdminBanksControllerPostLogoRoute(): AdminAdminBanksControllerPostLogoRoute;
export function adminAdminBanksControllerPostLogoRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminBanksControllerPostLogoParams>): AdminAdminBanksControllerPostLogoRoute<t.TypeOf<A>>;
export function adminAdminBanksControllerPostLogoRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminBanksControllerPostLogoParams>) {
  return {
    match: (q ? adminAdminBanksControllerPostLogoPath.then(query(t.intersection([adminAdminBanksControllerPostLogoQuery, q]))) : adminAdminBanksControllerPostLogoPath.then(query(adminAdminBanksControllerPostLogoQuery))).then(end),
    pathParts: adminAdminBanksControllerPostLogoPathParts,
  };
}

export const adminAdminBanksControllerPostLogo = (p: AdminAdminBanksControllerPostLogoParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminBanksControllerPostLogoRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminClientOfferingsControllerBankIndexPath = lit("admin").then(lit("bank")).then(lit("offerings"));
const adminAdminClientOfferingsControllerBankIndexPathParts = ["admin", "bank", "offerings"] as const;
export type AdminAdminClientOfferingsControllerBankIndexPathParts = typeof adminAdminClientOfferingsControllerBankIndexPathParts;

const adminAdminClientOfferingsControllerBankIndexQuery = t.exact(t.type({
  "bankId": NumberFromString
}));
export type AdminAdminClientOfferingsControllerBankIndexParams = Describe<typeof adminAdminClientOfferingsControllerBankIndexPath._A & typeof adminAdminClientOfferingsControllerBankIndexQuery._A>;

export type AdminAdminClientOfferingsControllerBankIndexRoute<A = {}> = { match: Match<AdminAdminClientOfferingsControllerBankIndexParams & A>; pathParts: AdminAdminClientOfferingsControllerBankIndexPathParts };

export function adminAdminClientOfferingsControllerBankIndexRoute(): AdminAdminClientOfferingsControllerBankIndexRoute;
export function adminAdminClientOfferingsControllerBankIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminClientOfferingsControllerBankIndexParams>): AdminAdminClientOfferingsControllerBankIndexRoute<t.TypeOf<A>>;
export function adminAdminClientOfferingsControllerBankIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminClientOfferingsControllerBankIndexParams>) {
  return {
    match: (q ? adminAdminClientOfferingsControllerBankIndexPath.then(query(t.intersection([adminAdminClientOfferingsControllerBankIndexQuery, q]))) : adminAdminClientOfferingsControllerBankIndexPath.then(query(adminAdminClientOfferingsControllerBankIndexQuery))).then(end),
    pathParts: adminAdminClientOfferingsControllerBankIndexPathParts,
  };
}

export const adminAdminClientOfferingsControllerBankIndex = (p: AdminAdminClientOfferingsControllerBankIndexParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminClientOfferingsControllerBankIndexRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminClientOfferingsControllerIssuerIndexPath = lit("admin").then(lit("issuer-offerings"));
const adminAdminClientOfferingsControllerIssuerIndexPathParts = ["admin", "issuer-offerings"] as const;
export type AdminAdminClientOfferingsControllerIssuerIndexPathParts = typeof adminAdminClientOfferingsControllerIssuerIndexPathParts;

const adminAdminClientOfferingsControllerIssuerIndexQuery = t.exact(t.type({
  "issuerId": NumberFromString
}));
export type AdminAdminClientOfferingsControllerIssuerIndexParams = Describe<typeof adminAdminClientOfferingsControllerIssuerIndexPath._A & typeof adminAdminClientOfferingsControllerIssuerIndexQuery._A>;

export type AdminAdminClientOfferingsControllerIssuerIndexRoute<A = {}> = { match: Match<AdminAdminClientOfferingsControllerIssuerIndexParams & A>; pathParts: AdminAdminClientOfferingsControllerIssuerIndexPathParts };

export function adminAdminClientOfferingsControllerIssuerIndexRoute(): AdminAdminClientOfferingsControllerIssuerIndexRoute;
export function adminAdminClientOfferingsControllerIssuerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminClientOfferingsControllerIssuerIndexParams>): AdminAdminClientOfferingsControllerIssuerIndexRoute<t.TypeOf<A>>;
export function adminAdminClientOfferingsControllerIssuerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminClientOfferingsControllerIssuerIndexParams>) {
  return {
    match: (q ? adminAdminClientOfferingsControllerIssuerIndexPath.then(query(t.intersection([adminAdminClientOfferingsControllerIssuerIndexQuery, q]))) : adminAdminClientOfferingsControllerIssuerIndexPath.then(query(adminAdminClientOfferingsControllerIssuerIndexQuery))).then(end),
    pathParts: adminAdminClientOfferingsControllerIssuerIndexPathParts,
  };
}

export const adminAdminClientOfferingsControllerIssuerIndex = (p: AdminAdminClientOfferingsControllerIssuerIndexParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminClientOfferingsControllerIssuerIndexRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminClientOfferingsControllerApprovedInvestorsModalPath = lit("admin").then(lit("client")).then(lit("offerings")).then(lit("approved-investors")).then(int("bondId"));
const adminAdminClientOfferingsControllerApprovedInvestorsModalPathParts = [
  "admin", "client", "offerings", "approved-investors", "bondId"
] as const;
export type AdminAdminClientOfferingsControllerApprovedInvestorsModalPathParts = typeof adminAdminClientOfferingsControllerApprovedInvestorsModalPathParts;

const adminAdminClientOfferingsControllerApprovedInvestorsModalQuery = t.strict({});
export type AdminAdminClientOfferingsControllerApprovedInvestorsModalParams = Describe<typeof adminAdminClientOfferingsControllerApprovedInvestorsModalPath._A & typeof adminAdminClientOfferingsControllerApprovedInvestorsModalQuery._A>;

export type AdminAdminClientOfferingsControllerApprovedInvestorsModalRoute<A = {}> = { match: Match<AdminAdminClientOfferingsControllerApprovedInvestorsModalParams & A>; pathParts: AdminAdminClientOfferingsControllerApprovedInvestorsModalPathParts };

export function adminAdminClientOfferingsControllerApprovedInvestorsModalRoute(): AdminAdminClientOfferingsControllerApprovedInvestorsModalRoute;
export function adminAdminClientOfferingsControllerApprovedInvestorsModalRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminClientOfferingsControllerApprovedInvestorsModalParams>): AdminAdminClientOfferingsControllerApprovedInvestorsModalRoute<t.TypeOf<A>>;
export function adminAdminClientOfferingsControllerApprovedInvestorsModalRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminClientOfferingsControllerApprovedInvestorsModalParams>) {
  return {
    match: (q ? adminAdminClientOfferingsControllerApprovedInvestorsModalPath.then(query(t.intersection([adminAdminClientOfferingsControllerApprovedInvestorsModalQuery, q]))) : adminAdminClientOfferingsControllerApprovedInvestorsModalPath.then(query(adminAdminClientOfferingsControllerApprovedInvestorsModalQuery))).then(end),
    pathParts: adminAdminClientOfferingsControllerApprovedInvestorsModalPathParts,
  };
}

export const adminAdminClientOfferingsControllerApprovedInvestorsModal = (p: AdminAdminClientOfferingsControllerApprovedInvestorsModalParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminAdminClientOfferingsControllerApprovedInvestorsModalRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminClientOfferingsControllerDeleteApprovedInvestorPath = lit("admin").then(lit("client")).then(lit("offerings")).then(lit("delete-approved-investor"));
const adminAdminClientOfferingsControllerDeleteApprovedInvestorPathParts = [
  "admin", "client", "offerings", "delete-approved-investor"
] as const;
export type AdminAdminClientOfferingsControllerDeleteApprovedInvestorPathParts = typeof adminAdminClientOfferingsControllerDeleteApprovedInvestorPathParts;

const adminAdminClientOfferingsControllerDeleteApprovedInvestorQuery = t.exact(t.type({
  "bondOfferingId": NumberFromString,
  "email": t.string
}));
export type AdminAdminClientOfferingsControllerDeleteApprovedInvestorParams = Describe<typeof adminAdminClientOfferingsControllerDeleteApprovedInvestorPath._A & typeof adminAdminClientOfferingsControllerDeleteApprovedInvestorQuery._A>;

export type AdminAdminClientOfferingsControllerDeleteApprovedInvestorRoute<A = {}> = { match: Match<AdminAdminClientOfferingsControllerDeleteApprovedInvestorParams & A>; pathParts: AdminAdminClientOfferingsControllerDeleteApprovedInvestorPathParts };

export function adminAdminClientOfferingsControllerDeleteApprovedInvestorRoute(): AdminAdminClientOfferingsControllerDeleteApprovedInvestorRoute;
export function adminAdminClientOfferingsControllerDeleteApprovedInvestorRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminClientOfferingsControllerDeleteApprovedInvestorParams>): AdminAdminClientOfferingsControllerDeleteApprovedInvestorRoute<t.TypeOf<A>>;
export function adminAdminClientOfferingsControllerDeleteApprovedInvestorRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminClientOfferingsControllerDeleteApprovedInvestorParams>) {
  return {
    match: (q ? adminAdminClientOfferingsControllerDeleteApprovedInvestorPath.then(query(t.intersection([adminAdminClientOfferingsControllerDeleteApprovedInvestorQuery, q]))) : adminAdminClientOfferingsControllerDeleteApprovedInvestorPath.then(query(adminAdminClientOfferingsControllerDeleteApprovedInvestorQuery))).then(end),
    pathParts: adminAdminClientOfferingsControllerDeleteApprovedInvestorPathParts,
  };
}

export const adminAdminClientOfferingsControllerDeleteApprovedInvestor = (p: AdminAdminClientOfferingsControllerDeleteApprovedInvestorParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminClientOfferingsControllerDeleteApprovedInvestorRoute().match.formatter.run(Route.empty, p).toString(true));

const adminAdminClientOfferingsControllerAddApprovedInvestorPath = lit("admin").then(lit("client")).then(lit("offerings")).then(lit("add-approved-investor"));
const adminAdminClientOfferingsControllerAddApprovedInvestorPathParts = [
  "admin", "client", "offerings", "add-approved-investor"
] as const;
export type AdminAdminClientOfferingsControllerAddApprovedInvestorPathParts = typeof adminAdminClientOfferingsControllerAddApprovedInvestorPathParts;

const adminAdminClientOfferingsControllerAddApprovedInvestorQuery = t.strict({});
export type AdminAdminClientOfferingsControllerAddApprovedInvestorParams = Describe<typeof adminAdminClientOfferingsControllerAddApprovedInvestorPath._A & typeof adminAdminClientOfferingsControllerAddApprovedInvestorQuery._A>;

export type AdminAdminClientOfferingsControllerAddApprovedInvestorRoute<A = {}> = { match: Match<AdminAdminClientOfferingsControllerAddApprovedInvestorParams & A>; pathParts: AdminAdminClientOfferingsControllerAddApprovedInvestorPathParts };

export function adminAdminClientOfferingsControllerAddApprovedInvestorRoute(): AdminAdminClientOfferingsControllerAddApprovedInvestorRoute;
export function adminAdminClientOfferingsControllerAddApprovedInvestorRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminClientOfferingsControllerAddApprovedInvestorParams>): AdminAdminClientOfferingsControllerAddApprovedInvestorRoute<t.TypeOf<A>>;
export function adminAdminClientOfferingsControllerAddApprovedInvestorRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminClientOfferingsControllerAddApprovedInvestorParams>) {
  return {
    match: (q ? adminAdminClientOfferingsControllerAddApprovedInvestorPath.then(query(t.intersection([adminAdminClientOfferingsControllerAddApprovedInvestorQuery, q]))) : adminAdminClientOfferingsControllerAddApprovedInvestorPath.then(query(adminAdminClientOfferingsControllerAddApprovedInvestorQuery))).then(end),
    pathParts: adminAdminClientOfferingsControllerAddApprovedInvestorPathParts,
  };
}

export const adminAdminClientOfferingsControllerAddApprovedInvestor = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminClientOfferingsControllerAddApprovedInvestorRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminAdminClientOfferingsControllerToggleDealTrackingPath = lit("admin").then(lit("client")).then(lit("offerings")).then(lit("toggle-deal-tracking")).then(int("offeringId"));
const adminAdminClientOfferingsControllerToggleDealTrackingPathParts = [
  "admin", "client", "offerings", "toggle-deal-tracking", "offeringId"
] as const;
export type AdminAdminClientOfferingsControllerToggleDealTrackingPathParts = typeof adminAdminClientOfferingsControllerToggleDealTrackingPathParts;

const adminAdminClientOfferingsControllerToggleDealTrackingQuery = t.strict({});
export type AdminAdminClientOfferingsControllerToggleDealTrackingParams = Describe<typeof adminAdminClientOfferingsControllerToggleDealTrackingPath._A & typeof adminAdminClientOfferingsControllerToggleDealTrackingQuery._A>;

export type AdminAdminClientOfferingsControllerToggleDealTrackingRoute<A = {}> = { match: Match<AdminAdminClientOfferingsControllerToggleDealTrackingParams & A>; pathParts: AdminAdminClientOfferingsControllerToggleDealTrackingPathParts };

export function adminAdminClientOfferingsControllerToggleDealTrackingRoute(): AdminAdminClientOfferingsControllerToggleDealTrackingRoute;
export function adminAdminClientOfferingsControllerToggleDealTrackingRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminAdminClientOfferingsControllerToggleDealTrackingParams>): AdminAdminClientOfferingsControllerToggleDealTrackingRoute<t.TypeOf<A>>;
export function adminAdminClientOfferingsControllerToggleDealTrackingRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminAdminClientOfferingsControllerToggleDealTrackingParams>) {
  return {
    match: (q ? adminAdminClientOfferingsControllerToggleDealTrackingPath.then(query(t.intersection([adminAdminClientOfferingsControllerToggleDealTrackingQuery, q]))) : adminAdminClientOfferingsControllerToggleDealTrackingPath.then(query(adminAdminClientOfferingsControllerToggleDealTrackingQuery))).then(end),
    pathParts: adminAdminClientOfferingsControllerToggleDealTrackingPathParts,
  };
}

export const adminAdminClientOfferingsControllerToggleDealTracking = (p: AdminAdminClientOfferingsControllerToggleDealTrackingParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminAdminClientOfferingsControllerToggleDealTrackingRoute().match.formatter.run(Route.empty, p).toString(true));

const adminParticipantsControllerIndexPath = lit("admin").then(lit("participants"));
const adminParticipantsControllerIndexPathParts = ["admin", "participants"] as const;
export type AdminParticipantsControllerIndexPathParts = typeof adminParticipantsControllerIndexPathParts;

const adminParticipantsControllerIndexQuery = t.strict({});
export type AdminParticipantsControllerIndexParams = Describe<typeof adminParticipantsControllerIndexPath._A & typeof adminParticipantsControllerIndexQuery._A>;

export type AdminParticipantsControllerIndexRoute<A = {}> = { match: Match<AdminParticipantsControllerIndexParams & A>; pathParts: AdminParticipantsControllerIndexPathParts };

export function adminParticipantsControllerIndexRoute(): AdminParticipantsControllerIndexRoute;
export function adminParticipantsControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminParticipantsControllerIndexParams>): AdminParticipantsControllerIndexRoute<t.TypeOf<A>>;
export function adminParticipantsControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminParticipantsControllerIndexParams>) {
  return {
    match: (q ? adminParticipantsControllerIndexPath.then(query(t.intersection([adminParticipantsControllerIndexQuery, q]))) : adminParticipantsControllerIndexPath.then(query(adminParticipantsControllerIndexQuery))).then(end),
    pathParts: adminParticipantsControllerIndexPathParts,
  };
}

export const adminParticipantsControllerIndex = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminParticipantsControllerIndexRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminParticipantsControllerEditPath = lit("admin").then(lit("participants")).then(int("id"));
const adminParticipantsControllerEditPathParts = ["admin", "participants", "id"] as const;
export type AdminParticipantsControllerEditPathParts = typeof adminParticipantsControllerEditPathParts;

const adminParticipantsControllerEditQuery = t.strict({});
export type AdminParticipantsControllerEditParams = Describe<typeof adminParticipantsControllerEditPath._A & typeof adminParticipantsControllerEditQuery._A>;

export type AdminParticipantsControllerEditRoute<A = {}> = { match: Match<AdminParticipantsControllerEditParams & A>; pathParts: AdminParticipantsControllerEditPathParts };

export function adminParticipantsControllerEditRoute(): AdminParticipantsControllerEditRoute;
export function adminParticipantsControllerEditRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminParticipantsControllerEditParams>): AdminParticipantsControllerEditRoute<t.TypeOf<A>>;
export function adminParticipantsControllerEditRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminParticipantsControllerEditParams>) {
  return {
    match: (q ? adminParticipantsControllerEditPath.then(query(t.intersection([adminParticipantsControllerEditQuery, q]))) : adminParticipantsControllerEditPath.then(query(adminParticipantsControllerEditQuery))).then(end),
    pathParts: adminParticipantsControllerEditPathParts,
  };
}

export const adminParticipantsControllerEdit = (p: AdminParticipantsControllerEditParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminParticipantsControllerEditRoute().match.formatter.run(Route.empty, p).toString(true));

const adminParticipantsControllerPostPath = lit("admin").then(lit("participants"));
const adminParticipantsControllerPostPathParts = ["admin", "participants"] as const;
export type AdminParticipantsControllerPostPathParts = typeof adminParticipantsControllerPostPathParts;

const adminParticipantsControllerPostQuery = t.strict({});
export type AdminParticipantsControllerPostParams = Describe<typeof adminParticipantsControllerPostPath._A & typeof adminParticipantsControllerPostQuery._A>;

export type AdminParticipantsControllerPostRoute<A = {}> = { match: Match<AdminParticipantsControllerPostParams & A>; pathParts: AdminParticipantsControllerPostPathParts };

export function adminParticipantsControllerPostRoute(): AdminParticipantsControllerPostRoute;
export function adminParticipantsControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminParticipantsControllerPostParams>): AdminParticipantsControllerPostRoute<t.TypeOf<A>>;
export function adminParticipantsControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminParticipantsControllerPostParams>) {
  return {
    match: (q ? adminParticipantsControllerPostPath.then(query(t.intersection([adminParticipantsControllerPostQuery, q]))) : adminParticipantsControllerPostPath.then(query(adminParticipantsControllerPostQuery))).then(end),
    pathParts: adminParticipantsControllerPostPathParts,
  };
}

export const adminParticipantsControllerPost = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminParticipantsControllerPostRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminParticipantsControllerDeletePath = lit("admin").then(lit("participants")).then(int("id"));
const adminParticipantsControllerDeletePathParts = ["admin", "participants", "id"] as const;
export type AdminParticipantsControllerDeletePathParts = typeof adminParticipantsControllerDeletePathParts;

const adminParticipantsControllerDeleteQuery = t.strict({});
export type AdminParticipantsControllerDeleteParams = Describe<typeof adminParticipantsControllerDeletePath._A & typeof adminParticipantsControllerDeleteQuery._A>;

export type AdminParticipantsControllerDeleteRoute<A = {}> = { match: Match<AdminParticipantsControllerDeleteParams & A>; pathParts: AdminParticipantsControllerDeletePathParts };

export function adminParticipantsControllerDeleteRoute(): AdminParticipantsControllerDeleteRoute;
export function adminParticipantsControllerDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminParticipantsControllerDeleteParams>): AdminParticipantsControllerDeleteRoute<t.TypeOf<A>>;
export function adminParticipantsControllerDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminParticipantsControllerDeleteParams>) {
  return {
    match: (q ? adminParticipantsControllerDeletePath.then(query(t.intersection([adminParticipantsControllerDeleteQuery, q]))) : adminParticipantsControllerDeletePath.then(query(adminParticipantsControllerDeleteQuery))).then(end),
    pathParts: adminParticipantsControllerDeletePathParts,
  };
}

export const adminParticipantsControllerDelete = (p: AdminParticipantsControllerDeleteParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminParticipantsControllerDeleteRoute().match.formatter.run(Route.empty, p).toString(true));

const adminHeaderPhotosControllerIndexPath = lit("admin").then(lit("header-photos"));
const adminHeaderPhotosControllerIndexPathParts = ["admin", "header-photos"] as const;
export type AdminHeaderPhotosControllerIndexPathParts = typeof adminHeaderPhotosControllerIndexPathParts;

const adminHeaderPhotosControllerIndexQuery = t.strict({});
export type AdminHeaderPhotosControllerIndexParams = Describe<typeof adminHeaderPhotosControllerIndexPath._A & typeof adminHeaderPhotosControllerIndexQuery._A>;

export type AdminHeaderPhotosControllerIndexRoute<A = {}> = { match: Match<AdminHeaderPhotosControllerIndexParams & A>; pathParts: AdminHeaderPhotosControllerIndexPathParts };

export function adminHeaderPhotosControllerIndexRoute(): AdminHeaderPhotosControllerIndexRoute;
export function adminHeaderPhotosControllerIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminHeaderPhotosControllerIndexParams>): AdminHeaderPhotosControllerIndexRoute<t.TypeOf<A>>;
export function adminHeaderPhotosControllerIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminHeaderPhotosControllerIndexParams>) {
  return {
    match: (q ? adminHeaderPhotosControllerIndexPath.then(query(t.intersection([adminHeaderPhotosControllerIndexQuery, q]))) : adminHeaderPhotosControllerIndexPath.then(query(adminHeaderPhotosControllerIndexQuery))).then(end),
    pathParts: adminHeaderPhotosControllerIndexPathParts,
  };
}

export const adminHeaderPhotosControllerIndex = (): UrlInterface<"GET"> =>
  urlInterface("GET", adminHeaderPhotosControllerIndexRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminHeaderPhotosControllerEditPath = lit("admin").then(lit("header-photos")).then(int("id"));
const adminHeaderPhotosControllerEditPathParts = ["admin", "header-photos", "id"] as const;
export type AdminHeaderPhotosControllerEditPathParts = typeof adminHeaderPhotosControllerEditPathParts;

const adminHeaderPhotosControllerEditQuery = t.strict({});
export type AdminHeaderPhotosControllerEditParams = Describe<typeof adminHeaderPhotosControllerEditPath._A & typeof adminHeaderPhotosControllerEditQuery._A>;

export type AdminHeaderPhotosControllerEditRoute<A = {}> = { match: Match<AdminHeaderPhotosControllerEditParams & A>; pathParts: AdminHeaderPhotosControllerEditPathParts };

export function adminHeaderPhotosControllerEditRoute(): AdminHeaderPhotosControllerEditRoute;
export function adminHeaderPhotosControllerEditRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminHeaderPhotosControllerEditParams>): AdminHeaderPhotosControllerEditRoute<t.TypeOf<A>>;
export function adminHeaderPhotosControllerEditRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminHeaderPhotosControllerEditParams>) {
  return {
    match: (q ? adminHeaderPhotosControllerEditPath.then(query(t.intersection([adminHeaderPhotosControllerEditQuery, q]))) : adminHeaderPhotosControllerEditPath.then(query(adminHeaderPhotosControllerEditQuery))).then(end),
    pathParts: adminHeaderPhotosControllerEditPathParts,
  };
}

export const adminHeaderPhotosControllerEdit = (p: AdminHeaderPhotosControllerEditParams): UrlInterface<"GET"> =>
  urlInterface("GET", adminHeaderPhotosControllerEditRoute().match.formatter.run(Route.empty, p).toString(true));

const adminHeaderPhotosControllerPostPath = lit("admin").then(lit("header-photos"));
const adminHeaderPhotosControllerPostPathParts = ["admin", "header-photos"] as const;
export type AdminHeaderPhotosControllerPostPathParts = typeof adminHeaderPhotosControllerPostPathParts;

const adminHeaderPhotosControllerPostQuery = t.strict({});
export type AdminHeaderPhotosControllerPostParams = Describe<typeof adminHeaderPhotosControllerPostPath._A & typeof adminHeaderPhotosControllerPostQuery._A>;

export type AdminHeaderPhotosControllerPostRoute<A = {}> = { match: Match<AdminHeaderPhotosControllerPostParams & A>; pathParts: AdminHeaderPhotosControllerPostPathParts };

export function adminHeaderPhotosControllerPostRoute(): AdminHeaderPhotosControllerPostRoute;
export function adminHeaderPhotosControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminHeaderPhotosControllerPostParams>): AdminHeaderPhotosControllerPostRoute<t.TypeOf<A>>;
export function adminHeaderPhotosControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminHeaderPhotosControllerPostParams>) {
  return {
    match: (q ? adminHeaderPhotosControllerPostPath.then(query(t.intersection([adminHeaderPhotosControllerPostQuery, q]))) : adminHeaderPhotosControllerPostPath.then(query(adminHeaderPhotosControllerPostQuery))).then(end),
    pathParts: adminHeaderPhotosControllerPostPathParts,
  };
}

export const adminHeaderPhotosControllerPost = (): UrlInterface<"POST"> =>
  urlInterface("POST", adminHeaderPhotosControllerPostRoute().match.formatter.run(Route.empty, {}).toString(true));

const adminHeaderPhotosControllerDeletePath = lit("admin").then(lit("header-photos")).then(int("id"));
const adminHeaderPhotosControllerDeletePathParts = ["admin", "header-photos", "id"] as const;
export type AdminHeaderPhotosControllerDeletePathParts = typeof adminHeaderPhotosControllerDeletePathParts;

const adminHeaderPhotosControllerDeleteQuery = t.strict({});
export type AdminHeaderPhotosControllerDeleteParams = Describe<typeof adminHeaderPhotosControllerDeletePath._A & typeof adminHeaderPhotosControllerDeleteQuery._A>;

export type AdminHeaderPhotosControllerDeleteRoute<A = {}> = { match: Match<AdminHeaderPhotosControllerDeleteParams & A>; pathParts: AdminHeaderPhotosControllerDeletePathParts };

export function adminHeaderPhotosControllerDeleteRoute(): AdminHeaderPhotosControllerDeleteRoute;
export function adminHeaderPhotosControllerDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AdminHeaderPhotosControllerDeleteParams>): AdminHeaderPhotosControllerDeleteRoute<t.TypeOf<A>>;
export function adminHeaderPhotosControllerDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AdminHeaderPhotosControllerDeleteParams>) {
  return {
    match: (q ? adminHeaderPhotosControllerDeletePath.then(query(t.intersection([adminHeaderPhotosControllerDeleteQuery, q]))) : adminHeaderPhotosControllerDeletePath.then(query(adminHeaderPhotosControllerDeleteQuery))).then(end),
    pathParts: adminHeaderPhotosControllerDeletePathParts,
  };
}

export const adminHeaderPhotosControllerDelete = (p: AdminHeaderPhotosControllerDeleteParams): UrlInterface<"POST"> =>
  urlInterface("POST", adminHeaderPhotosControllerDeleteRoute().match.formatter.run(Route.empty, p).toString(true));