

export * from "fp-ts/string";
export { literal, postfix, prefix, stripPrefix, toLower, toUpper } from "./lib/_internal";

import type * as Eq from "fp-ts/lib/Eq";
import { flow, pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import { Eq as sEq } from "fp-ts/lib/string";

import * as I from "./lib/_internal";

export type Trim<S extends string>
  = S extends `${I.Whitespace}${infer R}`
  ? Trim<R>
  : S extends `${infer L}${I.Whitespace}`
  ? Trim<L>
  : S;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const trim = <S extends string>(s: S): Trim<S> => s.trim() as Trim<S>;
export const normalize = flow(trim, I.toLower);

export type Between = <Prefix extends string, Suffix extends string>(p: Prefix, s: Suffix) =>
  <A extends string>(a: A) => `${Prefix}${A}${Suffix}`;
export const between: Between = (p, s) => flow(I.prefix(p), I.postfix(s));
export const surround = <End extends string>(end: End) => between(end, end);
// eslint-disable-next-line curly-quotes/no-straight-quotes
export const quote = surround(`"`);

export type NonEmptyString<S extends string> = Exclude<S, ``>;
export type EmptyString<S extends string> = S extends `` ? S : never;

export const isEmpty = <S extends string>(s: S): s is EmptyString<S> => s === ``;
export const isNonEmpty = <S extends string>(s: S | ``): s is NonEmptyString<S> => s !== ``;

/** @category refinements */
export const is = I.refinementFor.string;

// hash function based on this stackoverflow answer: https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
export const toId = (s: string): number => pipe(
  s.split(""),
  // eslint-disable-next-line no-bitwise
  RA.reduce(0, (acc, curr) => (((acc << 5) - acc) + curr.charCodeAt(0)) | 0)
);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const typedEq = <K extends string>(): Eq.Eq<K> => sEq as unknown as Eq.Eq<K>;
