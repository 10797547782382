import { Fragment } from "react";
import loaderIcon from "@imgs/bondlink-loader.svg";
import { plural } from "pluralize";

import { bliName } from "@scripts/literals/bondlink";
import { Svg } from "@scripts/react/components/Svg";

import { loader } from "@styles/components/_loader";

import { klass } from "../util/classnames";
import { LoadingCircle } from "./LoadingCircle";
import { PortalBase } from "./Portal";;

export const LoaderBLIcon = () => (<Svg src={loaderIcon} />);

export const LoaderBL = () => (
  <Fragment>
    <div {...klass("spinner-overlay", "fade", "show")} />
    <div {...klass("icon-bondlink-loader-container", "fade", "show")}>
      <LoaderBLIcon />
      <div {...klass("bondlink-loading-text")}>Loading…</div>
    </div>
  </Fragment>
);

const bliLoaderMessages = (issuerName: string, pageTypeSingular: string) => {
  const pageTypePlural = plural(pageTypeSingular);

  return [
    `${bliName} is hard at work on your behalf, this may take a few moments...`,
    `Searching ${pageTypePlural} for ${issuerName}...`,
    `Gathering ${pageTypePlural}...`,
    `Researching ${pageTypePlural}...`,
    `Compiling ${pageTypeSingular} references...`,
  ];
};

export const PortalLoaderBL = (props: {
  issuerName: string;
  pageType: string;
}) => <PortalBase
  container={document.querySelector("#root") ?? document.body}
  id={"loader-bl"}
>
  <div {...klass("spinner-overlay", "fade", "show")} />
  <div {...klass("portal-loader-bl-icon-container")}>
    <LoaderBLIcon />
    <div {...klass("scroll-window")}>
        <div {...klass("messages")}>{bliLoaderMessages(props.issuerName, props.pageType).map((_, i) =>
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            {...klass("loading-message")}
          >{_}</div>
        )}</div>
    </div>
  </div>
</PortalBase>;

export const LoaderBars = () => (
  <div {...klass(loader[".loader-bars-container"])}>
    <div {...klass(loader[".loader-bars"])}>
      <div {...klass(loader[".loader-bars"][".loader-bar"])} />
    </div>
  </div>
);

export const LoaderBarsPill = () => (
  <div {...klass(loader[".loader-pill-container"])}>
    <div {...klass(loader[".loader-pill-container"][".loader-pill"])}>
      <LoaderBars />
      <div {...klass(loader[".loader-pill-container"][".loader-copy"])}>Loading...</div>
    </div>
  </div>
);

export const LoaderFindIssuers = () => <div {...klass("d-flex", "align-self-center")}>
  <LoadingCircle loadingMsg={"Loading Issuers..."} />
</div>;
