import type { ReactElement } from "react";
import * as t from "io-ts";
import { singular } from "pluralize";
import type { CreativeWork, Event, Thing, WithContext } from "schema-dts";

import { type BLConfigWithLog } from "@scripts/bondlink";
import type { Markdown } from "@scripts/codecs/markdown";
import { constant, E, flow, identity, O, pipe, RA, RNEA } from "@scripts/fp-ts";
import {
  esgProgram as esgProgramFF,
  type FeatureU,
  financeTeam as teamFF,
  news as newsFF,
  projects as projectsFF,
} from "@scripts/generated/domaintables/featureFlags";
import type {
  CustomPageU,
  PageU,
  PhotoEditableU,
} from "@scripts/generated/domaintables/pages";
import {
  aboutPage,
  bondArchive as bondArchivePage,
  bondProgramsPage,
  bondRatings,
  bondsPage,
  customPage1,
  customPage2,
  customPage3,
  customPage4,
  customPage5,
  CustomPageCU,
  documentsPage,
  emmaLinks as emmaLinksPage,
  esgProgram as esgProgramPage,
  faq as faqPage,
  financeTeam,
  homepage,
  infrastructureProjects,
  irmaLetter as irmaLetterPage,
  linksPage,
  news,
  newsEventsPage,
  resourcesPage,
  rfpsPage,
} from "@scripts/generated/domaintables/pages";
import { news as newsPostType, pressRelease } from "@scripts/generated/domaintables/postTypes";
import type { BankOfferingData, DirectOfferingData } from "@scripts/generated/models/bondOffering";
import type { BondProgramWithRelatedContent, BondProgramWithRelatedContentC } from "@scripts/generated/models/bondProgram";
import type { ArchivedBondsSsrData, BondsSsrData } from "@scripts/generated/models/bondsSsrData";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import { type EmmaLinksPageData, type EmmaLinksPageDataC, emmaLinksPageDataC } from "@scripts/generated/models/cusip";
import { type SitesCustomPageData, type SitesCustomPageDataC, sitesCustomPageDataC } from "@scripts/generated/models/customPages";
import type { DocumentWithCategory, DocumentWithCategoryC } from "@scripts/generated/models/document";
import type { IssuerFaqsWithSections, IssuerFaqsWithSectionsC } from "@scripts/generated/models/faq";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { IssuerLink, IssuerLinkC, IssuerLinkSection, IssuerLinkSectionC } from "@scripts/generated/models/issuerLinks";
import type { IssuerNewsWithRelatedContent, IssuerNewsWithRelatedContentC, NewsAndEventsData, NewsAndEventsDataC } from "@scripts/generated/models/issuerNews";
import { type BLPDealUnavailablePageData, type BLPDealUnavailablePageDataC, type IssuerAboutPageData, type IssuerAboutPageDataC, type IssuerDocumentCategoriesPageData, type IssuerDocumentCategoriesPageDataC, type IssuerDownloadsPageData, type IssuerDownloadsPageDataC, type IssuerEsgPageData, type IssuerHomepageData, type IssuerHomepageDataC, type IssuerPartnerLanderPageData, type IssuerPartnerLanderPageDataC, issuerPartnerLanderPageDataC, type IssuerResourcesPageData, type IssuerResourcesPageDataC, type RoadShowSsrData, type RoadShowSsrDataC, type SitesErrorPageData, type SitesErrorPageDataC, sitesErrorPageDataC } from "@scripts/generated/models/issuerPageData";
import type { Media, MediaC } from "@scripts/generated/models/media";
import type { Officer, OfficerC } from "@scripts/generated/models/officer";
import { type PageConfig, pageConfigC } from "@scripts/generated/models/pageConfig";
import type { ProjectWithPhotos, ProjectWithPhotosC, ProjectWithRelatedContent, ProjectWithRelatedContentC } from "@scripts/generated/models/project";
import type { IssuerAndProgramRatingsByAgency } from "@scripts/generated/models/rating";
import type { BankRfpSitesData, DirectRfpSitesData, RfpsSitesList, RfpsSitesListC } from "@scripts/generated/models/rfp";
import type { RoadShowData, RoadShowDataC } from "@scripts/generated/models/roadshow";
import type { TaggedContent, TaggedContentC } from "@scripts/generated/models/taggedContent";
import type { ToMany, ToManyC, WithId, WithIdC, WithStatusCU, WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { emmaName } from "@scripts/literals/emma";
import { type DataMetaBase, displayName, esgProgramMeta, programMeta, programsMeta, rfpMeta, rfpsMeta } from "@scripts/meta/dataMeta";
import type { SitesJumpLink } from "@scripts/react/components/SidebarLinks";
import type { KlassBase } from "@scripts/react/util/classnames";
import { oxfordCommaString, oxfordCommaStringN } from "@scripts/react/util/oxfordComma";
import type { RouteMeta } from "@scripts/routes/routing/ssr";
import { getEditableTitleOrDefault, getPageCustomDescriptionOrDefault } from "@scripts/routes/routing/ssr";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { isFFEnabled } from "@scripts/syntax/featureFlags";
import { issuerShortNameOrName } from "@scripts/syntax/issuer";
import { coerceStringAsMarkdown } from "@scripts/syntax/markdown";
import { getPage } from "@scripts/syntax/pageConfig";
import { getCustomTitleO, getCustomTitleOrName } from "@scripts/syntax/pageTitles";
import {
  makeSchemaOrgCreativeWork,
  type SchemaOrgOutput,
} from "@scripts/syntax/schemaOrg";
import { exhaustiveNoLog } from "@scripts/util/exhaustive";

import { format } from "../base";
import { linksMeta, projectsMeta, resourcesMeta, teamMeta } from "../issuerportal/dataMeta";
import { makeBondSchemaOrgEventO, makeEventSchemaOrgEventO, makeRfpSchemaOrgEventO } from "./issuerSitesSchemaOrg";

type DescriptionFn<A> = (pages: ReadonlyArray<PageConfig<PageU>>, ffs: ClientFeatureFlags, issuer: Issuer) => A;

export type BaseTitleFn = (pages: ReadonlyArray<PageConfig<PageU>>, issuer: Issuer) => string;
type TitleWithDataFn<A> = (data: A) => BaseTitleFn;
type TitleWithData<A> = { titleWithData: TitleWithDataFn<A> };
type TitleFn<A> = BaseTitleFn | TitleWithData<A>;

const isTitleWithData = <A>(f: TitleFn<A>): f is TitleWithData<A> => "titleWithData" in f;

export const normalizeTitleFn = <A>(f: TitleFn<A>) => (data: A): BaseTitleFn => isTitleWithData(f) ? f.titleWithData(data) : f;

export type BasePhotoPageFn = (pages: ReadonlyArray<PageConfig<PageU>>) => PhotoEditableU;
type PhotoPageDataFn<A> = (data: A) => BasePhotoPageFn;
type PhotoPageWithData<A> = { photoPageWithData: PhotoPageDataFn<A> };
type PhotoPageFn<A> = BasePhotoPageFn | PhotoPageWithData<A>;

const isPhotoPageWithData = <A>(f: PhotoPageFn<A>): f is PhotoPageWithData<A> => "photoPageWithData" in f;

export const normalizePhotoPageFn = <A>(f: PhotoPageFn<A>) => (data: A): BasePhotoPageFn =>
  isPhotoPageWithData(f) ? f.photoPageWithData(data) : f;

export type StaticJumpLinksFn = (issuer: Issuer, ffs: ClientFeatureFlags, pages: ReadonlyArray<PageConfig<PageU>>) => ReadonlyArray<SitesJumpLink>;

type MakeSchemaOrg<A, T extends Thing = Thing> = (
  data: A,
  config: BLConfigWithLog,
  issuer: Issuer
) => SchemaOrgOutput<T>;

export type IssuerSitesRouteMeta<A, Output, Tag> = Omit<RouteMeta<A, Output>, "description" | "_tag" | "title"> & {
  description?: DescriptionFn<Markdown>;
  descriptionMeta: (data: A) => DescriptionFn<string>;
  photoPage: PhotoPageFn<A>;
  _tag: Tag;
  title: TitleFn<A>;
  titleMeta: TitleWithDataFn<A>;
  makeSchemaOrg: O.Option<MakeSchemaOrg<A>>;
  blpLoginRequired?: (data: A) => (issuer: Issuer) => ({ required: boolean, redirect: string });
  renderChrome: boolean;
  bodyKlass?: KlassBase;
  isErrorPage?: true;
  staticJumpLinks?: StaticJumpLinksFn;
};

export type IssuerSitesRouteMetaWithRender<A, O, Tag> = IssuerSitesRouteMeta<A, O, Tag> & {
  render: (props: A) => ReactElement<A>;
};

type DescriptionTextFn = (issuer: Issuer, page: O.Option<PageConfig<PageU>>, pages: ReadonlyArray<PageConfig<PageU>>, ffs: ClientFeatureFlags) => string;

const makeDescription = (page: PageU, makeDescriptionText: DescriptionTextFn): DescriptionFn<Markdown> =>
  (pages, ffs, issuer) => getPageCustomDescriptionOrDefault(
    page,
    (p) => coerceStringAsMarkdown(makeDescriptionText(issuer, p, pages, ffs)),
  )(pages);

export const makeDescriptionMeta = <A>(page: PageU, makeDescriptionText: (data: A) => DescriptionTextFn) =>
  (data: A) => (pages: ReadonlyArray<PageConfig<PageU>>, ffs: ClientFeatureFlags, issuer: Issuer) => pipe(
    getPage(page)(pages),
    (p) => makeDescriptionText(data)(issuer, p, pages, ffs),
  );

const getPageTitleOrDefault = (pageMeta: DataMetaBase<string>) => flow(
  O.chain((_: PageConfig<PageU>) => _.title),
  O.getOrElse(() => pageMeta.type)
);

// HOME PAGE
const makeHomeDescription = (issuer: Issuer) =>
  `The ${issuerShortNameOrName(issuer)} website features bond offerings and ratings, financial documents, news & updates about our municipality, and other information about our municipal bond programs. Powered by BondLink.`;

export const homeMatch: SR.IssuersitesIssuerControllerIndexRoute["match"] = SR.issuersitesIssuerControllerIndexRoute().match;
export type HomeUrlParams = typeof homeMatch._A;

export const home = (reqParams: SR.IssuersitesIssuerControllerIndexParams) => ({
  descriptionMeta: () => (_ps, _fs, issuer) => makeHomeDescription(issuer),
  _tag: "home",
  propsCodec: SR.issuersitesIssuerControllerIndexCodecs.ssrInput,
  url: () => format(homeMatch, reqParams),
  title: (_, issuer) => issuer.name,
  titleMeta: () => () => "Bonds, Documents, Resources",
  photoPage: () => homepage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<IssuerHomepageData, t.OutputOf<IssuerHomepageDataC>, "home">);

// RFP SUMMARY PAGE
export const rfpsMatch: SR.IssuersitesRfpsControllerRfpsRoute["match"] = SR.issuersitesRfpsControllerRfpsRoute().match;

export type RfpsUrlParams = typeof rfpsMatch._A;

const rfpsTitleFn = getEditableTitleOrDefault(rfpsPage, rfpsMeta);

export const rfps = (reqParams: SR.IssuersitesRfpsControllerRfpsParams) => {
  return {
    description: makeDescription(
      rfpsPage,
      (_, page) => `Get details and submission information about ${getPageTitleOrDefault(rfpsMeta)(page)}.`,
    ),
    descriptionMeta: makeDescriptionMeta(
      rfpsPage,
      () => (issuer, page) => `Get details and submission information about ${getPageTitleOrDefault(rfpsMeta)(page)} from ${issuerShortNameOrName(issuer)}.`
    ),
    _tag: "rfps",
    propsCodec: SR.issuersitesRfpsControllerRfpsCodecs.ssrInput,
    title: rfpsTitleFn,
    titleMeta: () => rfpsTitleFn,
    url: () => format(rfpsMatch, { ...reqParams }),
    staticJumpLinks: () => jl.rfps.allStatic,
    photoPage: () => bondsPage,
    makeSchemaOrg: O.none,
    renderChrome: true,
  } as const satisfies IssuerSitesRouteMeta<RfpsSitesList, t.OutputOf<RfpsSitesListC>, "rfps">;
};

// RFP PAGE
export const rfpMatch: SR.IssuersitesRfpsControllerRfpRoute["match"] = SR.issuersitesRfpsControllerRfpRoute().match;
export type RfpUrlParams = typeof rfpMatch._A;

export type RfpPageDataC = typeof SR.issuersitesRfpsControllerRfpCodecs.ssrInput;

const rfpDesc: DescriptionTextFn = (issuer, page) =>
  `Get details about this ${getPageTitleOrDefault(rfpMeta)(page)} from ${issuerShortNameOrName(issuer)}, including project information, submission requirements, and other related content.`;

const rfpTitleFn = flow(
  getEditableTitleOrDefault(rfpsPage, rfpMeta),
  singular
);

export const rfp = (reqParams: SR.IssuersitesRfpsControllerRfpParams) => ({
  descriptionMeta: makeDescriptionMeta(
    rfpsPage,
    () => rfpDesc
  ),
  _tag: "rfp",
  propsCodec: SR.issuersitesRfpsControllerRfpCodecs.ssrInput,
  url: () => format(rfpMatch, reqParams),
  title: rfpTitleFn,
  titleMeta: () => rfpTitleFn,
  photoPage: () => bondsPage,
  makeSchemaOrg: O.some((data, config, issuer) => RA.compact<WithContext<Event> | WithContext<CreativeWork>>([
    makeRfpSchemaOrgEventO(E.toUnion(data).rfp.data, config, issuer),
    O.some(makeSchemaOrgCreativeWork({ isAccessibleForFree: E.isRight(data) })),
  ])),
  blpLoginRequired: data => issuer => ({
    required: true,
    redirect: SR.issuersitesRfpsControllerRfp({
      issuerSlug: issuer.slug,
      issuerId: issuer.id,
      rfpId: E.toUnion(data).rfp.data.data.id,
    }).url,
  }),
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<E.Either<BankRfpSitesData, DirectRfpSitesData>, t.OutputOf<RfpPageDataC>, "rfp">);

// BONDS SUMMARY PAGE
export const bondsMatch: SR.IssuersitesBondOfferingsControllerIndexRoute["match"] = SR.issuersitesBondOfferingsControllerIndexRoute().match;

export type BondsPageDataC = typeof SR.issuersitesBondOfferingsControllerIndexCodecs.ssrInput;

const bondsTitleFn = () => "Bonds";

export const bonds = (reqParams: SR.IssuersitesBondOfferingsControllerIndexParams) => {
  return {
    description: makeDescription(
      bondsPage,
      () => "Get an overview of our bonds and ratings.",
    ),
    descriptionMeta: makeDescriptionMeta(
      bondsPage,
      () => (issuer) => `Get an overview of bonds and ratings from ${issuerShortNameOrName(issuer)}.`
    ),
    _tag: "bonds",
    propsCodec: SR.issuersitesBondOfferingsControllerIndexCodecs.ssrInput,
    title: bondsTitleFn,
    titleMeta: () => bondsTitleFn,
    url: () => format(bondsMatch, { ...reqParams }),
    photoPage: () => bondsPage,
    staticJumpLinks: () => jl.bonds.allStatic,
    makeSchemaOrg: O.none,
    renderChrome: true,
  } as const satisfies IssuerSitesRouteMeta<BondsSsrData, t.OutputOf<BondsPageDataC>, "bonds">;
};

// BOND ARCHIVE PAGE
export const bondArchiveMatch: SR.IssuersitesBondOfferingsControllerArchivedBondsRoute["match"] = SR.issuersitesBondOfferingsControllerArchivedBondsRoute().match;

export type BondArchivePageDataC = typeof SR.issuersitesBondOfferingsControllerArchivedBondsCodecs.ssrInput;

const bondArchiveTitleFn = () => "Bond Archive";

export const bondArchive = (reqParams: SR.IssuersitesBondOfferingsControllerArchivedBondsParams) => {
  return {
    description: makeDescription(
      bondArchivePage,
      () => "Sort, filter, and view archived bond offerings.",
    ),
    descriptionMeta: makeDescriptionMeta(
      bondArchivePage,
      () => (issuer) => `Sort, filter, and view archived bond offerings from ${issuerShortNameOrName(issuer)}.`
    ),
    _tag: "bond-archive",
    propsCodec: SR.issuersitesBondOfferingsControllerArchivedBondsCodecs.ssrInput,
    title: bondArchiveTitleFn,
    titleMeta: () => bondArchiveTitleFn,
    url: () => format(bondArchiveMatch, { ...reqParams }),
    photoPage: () => bondsPage,
    staticJumpLinks: () => jl.archivedBonds.allStatic,
    makeSchemaOrg: O.none,
    renderChrome: true,
  } as const satisfies IssuerSitesRouteMeta<ArchivedBondsSsrData, t.OutputOf<BondArchivePageDataC>, "bond-archive">;
};

// OFFERING PAGE
const offeringPageDescription = (issuer: Issuer) =>
  `Details and offering statement about this municipal bond offering from ${issuerShortNameOrName(issuer)}.`;

export const offeringPageMatch: SR.IssuersitesBondOfferingsControllerOfferingRoute["match"] = SR.issuersitesBondOfferingsControllerOfferingRoute().match;

export type OfferingPageDataC = typeof SR.issuersitesBondOfferingsControllerOfferingCodecs.ssrInput;

const offeringPageTitleFn = () => "Bond Offering";

export const offeringPage = (reqParams: SR.IssuersitesBondOfferingsControllerOfferingParams) => ({
  descriptionMeta: makeDescriptionMeta(
    bondsPage,
    () => offeringPageDescription
  ),
  _tag: "offering-page",
  propsCodec: SR.issuersitesBondOfferingsControllerOfferingCodecs.ssrInput,
  url: () => format(offeringPageMatch, reqParams),
  title: offeringPageTitleFn,
  titleMeta: () => offeringPageTitleFn,
  photoPage: () => bondsPage,
  makeSchemaOrg: O.some((data, config, issuer) =>
    RA.compact<WithContext<Event> | WithContext<CreativeWork>>([
      makeBondSchemaOrgEventO(E.toUnion(data).offering.data, config, issuer),
      O.some(makeSchemaOrgCreativeWork({ isAccessibleForFree: E.isRight(data) })),
    ])
  ),
  blpLoginRequired: data => issuer => ({
    required: E.toUnion(data).offering.data.data.record.data.offering.loginRequired,
    redirect: SR.issuersitesBondOfferingsControllerOffering({
      issuerSlug: issuer.slug,
      issuerId: issuer.id,
      offeringId: E.toUnion(data).offering.data.data.id,
    }).url,
  }),
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<E.Either<BankOfferingData, DirectOfferingData>, t.OutputOf<OfferingPageDataC>, "offering-page">);

// NEWS AND EVENTS PAGE
const makeNewsAndEventsDescription: DescriptionTextFn = (issuer) =>
  `Learn about the latest News & Events for ${issuerShortNameOrName(issuer)}, and sign up to receive news updates.`;

export const newsAndEventsMatch: SR.IssuersitesAboutControllerNewsEventsRoute["match"] = SR.issuersitesAboutControllerNewsEventsRoute().match;
export type NewsAndEventsUrlParams = typeof newsAndEventsMatch._A;

const newsAndEventsTitleFn = () => "News & Events";

export const newsAndEvents = (reqParams: SR.IssuersitesAboutControllerNewsEventsParams) => ({
  description: makeDescription(
    newsEventsPage,
    makeNewsAndEventsDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    newsEventsPage,
    () => makeNewsAndEventsDescription
  ),
  _tag: "news-and-events",
  propsCodec: SR.issuersitesAboutControllerNewsEventsCodecs.ssrInput,
  url: () => format(newsAndEventsMatch, reqParams),
  title: newsAndEventsTitleFn,
  titleMeta: () => newsAndEventsTitleFn,
  photoPage: () => aboutPage,
  staticJumpLinks: () => jl.newsAndEvents.allStatic,
  makeSchemaOrg: O.some((data, config, issuer) => pipe(
    data.eventItems,
    RA.filterMap(flow(
      E.fold(
        eventData => makeEventSchemaOrgEventO(eventData, config, issuer),
        E.fold(
          bondData => makeBondSchemaOrgEventO(bondData.data, config, issuer),
          rfpData => makeRfpSchemaOrgEventO(rfpData.data, config, issuer),
        ))
    )
    )),
  ),
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<NewsAndEventsData, t.OutputOf<NewsAndEventsDataC>, "news-and-events">);

// News item page
export type NewsItemDataC = WithStatusCU<TaggedContentC<IssuerNewsWithRelatedContentC>>;
export type NewsItemData = WithStatusU<TaggedContent<IssuerNewsWithRelatedContent>>;

const newsItemC: NewsItemDataC = SR.issuersitesAboutControllerNewsItemCodecs.ssrInput;

export const newsItemMatch: SR.IssuersitesAboutControllerNewsItemRoute["match"] = SR.issuersitesAboutControllerNewsItemRoute().match;

export const newsItemDisplayType = (n: NewsItemData): "News Story" | "Press Release" => {
  switch (n.data.record.data.news.postType._tag) {
    case newsPostType._tag: return "News Story";
    case pressRelease._tag: return "Press Release";
    default: return exhaustiveNoLog(n.data.record.data.news.postType);
  }
};

const newsItemTitleFn: TitleFn<NewsItemData> = { titleWithData: n => () => newsItemDisplayType(n) };

export const newsItem = (reqParams: SR.IssuersitesAboutControllerNewsItemParams) => ({
  descriptionMeta: n => (_ps, _fs, issuer) => `View this ${newsItemDisplayType(n)} from ${issuer.name}.`,
  _tag: "news-item",
  propsCodec: newsItemC,
  url: () => format(newsItemMatch, reqParams),
  title: newsItemTitleFn,
  titleMeta: newsItemTitleFn.titleWithData,
  photoPage: () => aboutPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<NewsItemData, t.OutputOf<NewsItemDataC>, "news-item">);

// RATINGS PAGE
export type RatingsPageDataC = typeof SR.issuersitesBondOfferingsControllerRatingsCodecs.ssrInput;

export const ratingsMatch: SR.IssuersitesBondOfferingsControllerRatingsRoute["match"] = SR.issuersitesBondOfferingsControllerRatingsRoute().match;

const ratingsTitleFn = () => "Ratings";

export const ratings = (reqParams: SR.IssuersitesBondOfferingsControllerRatingsParams) => {
  return {
    description: makeDescription(
      bondRatings,
      () => "View ratings for our bond programs from rating agencies such as Moody’s Investors Service, S&P Global Ratings, and Fitch Ratings. Ratings for individual bonds are shown on their respective offering pages."
    ),
    descriptionMeta: makeDescriptionMeta(
      bondRatings,
      () => (issuer) => `View ratings for ${issuerShortNameOrName(issuer)} and our bond programs from agencies such as Moody’s, S&P, Fitch, and Kroll.`
    ),
    _tag: "ratings",
    propsCodec: SR.issuersitesBondOfferingsControllerRatingsCodecs.ssrInput,
    title: ratingsTitleFn,
    titleMeta: () => ratingsTitleFn,
    url: () => format(ratingsMatch, { ...reqParams }),
    photoPage: () => bondsPage,
    staticJumpLinks: () => jl.ratings.allStatic,
    makeSchemaOrg: O.none,
    renderChrome: true,
  } as const satisfies IssuerSitesRouteMeta<IssuerAndProgramRatingsByAgency, t.OutputOf<RatingsPageDataC>, "ratings">;
};

// PROGRAMS PAGE
export type ProgramsPageDataC = typeof SR.issuersitesBondProgramsControllerBondProgramsCodecs.ssrInput;

export const programsMatch: SR.IssuersitesBondProgramsControllerBondProgramsRoute["match"] = SR.issuersitesBondProgramsControllerBondProgramsRoute().match;

const programsTitleFn = getEditableTitleOrDefault(bondProgramsPage, programsMeta);

export const programs = (reqParams: SR.IssuersitesBondProgramsControllerBondProgramsParams) => ({
  description: makeDescription(
    bondProgramsPage,
    (_, page) => `Learn about our ${getPageTitleOrDefault(programsMeta)(page)}, including information about bond offerings, ratings, CUSIPs, and more.`,
  ),
  descriptionMeta: makeDescriptionMeta(
    bondProgramsPage,
    () => (issuer, page) => `Learn about ${getPageTitleOrDefault(programsMeta)(page)} from ${issuerShortNameOrName(issuer)}, including information about bond offerings, ratings, CUSIPs, and more.`,
  ),
  _tag: "programs",
  propsCodec: SR.issuersitesBondProgramsControllerBondProgramsCodecs.ssrInput,
  title: programsTitleFn,
  titleMeta: () => programsTitleFn,
  url: () => format(programsMatch, { ...reqParams }),
  photoPage: () => bondsPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<ReadonlyArray<WithStatusU<BondProgramWithRelatedContent>>, t.OutputOf<ProgramsPageDataC>, "programs">);

// PROGRAM PAGE
const programPageDataC: WithStatusCU<BondProgramWithRelatedContentC> = SR.issuersitesBondProgramsControllerBondProgramCodecs.ssrInput;
export type ProgramPageDataC = typeof programPageDataC;
export type ProgramPageData = WithStatusU<BondProgramWithRelatedContent>;

const makeProgramDescription: DescriptionTextFn = (issuer, page) => pipe(
  getPageTitleOrDefault(programMeta)(page),
  singular,
  pageTitle => `Get details about this ${pageTitle} from ${issuerShortNameOrName(issuer)}, including related bond offerings, ratings, CUSIPs, and more.`
);

export const programMatch: SR.IssuersitesBondProgramsControllerBondProgramRoute["match"] = SR.issuersitesBondProgramsControllerBondProgramRoute().match;

const programTitleFn = flow(
  getEditableTitleOrDefault(bondProgramsPage, programMeta),
  singular
);

export const program = (reqParams: SR.IssuersitesBondProgramsControllerBondProgramParams) => ({
  descriptionMeta: makeDescriptionMeta(
    bondProgramsPage,
    () => makeProgramDescription
  ),
  _tag: "bond-program",
  propsCodec: programPageDataC,
  title: programTitleFn,
  titleMeta: () => programTitleFn,
  url: () => format(programMatch, { ...reqParams }),
  photoPage: () => bondsPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<ProgramPageData, t.OutputOf<ProgramPageDataC>, "bond-program">);

// FAQs PAGE
const faqPageDataC: IssuerFaqsWithSectionsC = SR.issuersitesResourcesControllerFaqCodecs.ssrInput;
export type FAQPageDataC = typeof faqPageDataC;
export type FAQPageData = IssuerFaqsWithSections;

export const faqMatch: SR.IssuersitesResourcesControllerFaqRoute["match"] = SR.issuersitesResourcesControllerFaqRoute().match;

const makefaqDescription: DescriptionTextFn = (issuer) => `View frequently asked questions (FAQ) for ${issuer.name}.`;

const faqTitleFn = () => "FAQ";

export const faq = (reqParams: SR.IssuersitesResourcesControllerFaqParams) => ({
  description: makeDescription(faqPage, makefaqDescription),
  descriptionMeta: makeDescriptionMeta(faqPage, () => makefaqDescription),
  _tag: "faq",
  propsCodec: faqPageDataC,
  title: faqTitleFn,
  titleMeta: () => faqTitleFn,
  url: () => format(faqMatch, { ...reqParams }),
  photoPage: () => resourcesPage,
  staticJumpLinks: () => jl.faq.allStatic,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<FAQPageData, t.OutputOf<FAQPageDataC>, "faq">);

// ABOUT PAGE
const aboutPageDataC: IssuerAboutPageDataC = SR.issuersitesAboutControllerIndexCodecs.ssrInput;
export type AboutPageDataC = typeof aboutPageDataC;
export type AboutPageData = IssuerAboutPageData;

export const aboutMatch: SR.IssuersitesAboutControllerIndexRoute["match"] = SR.issuersitesAboutControllerIndexRoute().match;

const aboutDescriptionSections: ReadonlyArray<[FeatureU, PageU, E.Either<string, DataMetaBase<string>>]> = [
  [esgProgramFF, esgProgramPage, E.right(esgProgramMeta)],
  [newsFF, news, E.left(news.name)],
  [projectsFF, infrastructureProjects, E.right(projectsMeta)],
  [teamFF, financeTeam, E.right(teamMeta)],
];

const aboutDescription: DescriptionTextFn = (issuer, _, pages, ffs) =>
  `Learn about ${issuer.name}` + pipe(
    aboutDescriptionSections,
    RA.filterMap(([f, p, defaultOrMeta]) => isFFEnabled(f)(ffs)
      ? O.some(O.getOrElse(() => pipe(defaultOrMeta, E.fold(identity, m => displayName(m))))(getCustomTitleO(p)(pages)))
      : O.none),
    RNEA.fromReadonlyArray,
    O.fold(() => ".", ss => ` including our ${oxfordCommaStringN(ss)}.`),
  );

export const about = (reqParams: SR.IssuersitesAboutControllerIndexParams) => ({
  description: makeDescription(
    aboutPage,
    aboutDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    aboutPage,
    () => aboutDescription
  ),
  _tag: "about",
  propsCodec: aboutPageDataC,
  title: () => "About",
  titleMeta: () => () => "Team, News, Projects",
  url: () => format(aboutMatch, { ...reqParams }),
  photoPage: () => aboutPage,
  staticJumpLinks: jl.about.allStatic,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<AboutPageData, t.OutputOf<AboutPageDataC>, "about">);

// ESG PROGRAM PAGE
export type EsgProgramPageDataC = typeof SR.issuersitesAboutControllerEsgProgramCodecs.ssrInput;

export const esgProgramMatch: SR.IssuersitesAboutControllerEsgProgramRoute["match"] = SR.issuersitesAboutControllerEsgProgramRoute().match;
export const esgTitle = getEditableTitleOrDefault(esgProgramPage, esgProgramMeta);

export const esgDescription = makeDescription(
  esgProgramPage,
  () => "Learn about our environmental, social, and governance program, and how we bring those values to life with green bonds, sustainable projects, and more."
);

export const esgProgram = (reqParams: SR.IssuersitesAboutControllerEsgProgramParams) => {
  return {
    description: esgDescription,
    descriptionMeta: makeDescriptionMeta(
      esgProgramPage,
      () => (issuer) => `Learn about the environmental, social, and governance program from ${issuerShortNameOrName(issuer)}, and how we bring those values to life with green bonds, sustainable projects, and more.`
    ),
    _tag: "esg-program",
    propsCodec: SR.issuersitesAboutControllerEsgProgramCodecs.ssrInput,
    title: esgTitle,
    titleMeta: () => esgTitle,
    url: () => format(esgProgramMatch, { ...reqParams }),
    photoPage: () => aboutPage,
    staticJumpLinks: () => jl.esgProgram.allStatic,
    makeSchemaOrg: O.none,
    renderChrome: true,
  } as const satisfies IssuerSitesRouteMeta<IssuerEsgPageData, t.OutputOf<EsgProgramPageDataC>, "esg-program">;
};

// Team Page
export const teamTitle = getEditableTitleOrDefault(financeTeam, teamMeta);

const teamPageDataC: t.ReadonlyArrayC<WithStatusCU<OfficerC>> = SR.issuersitesAboutControllerTeamCodecs.ssrInput;
export type TeamPageDataC = typeof teamPageDataC;
export type TeamPageData = ReadonlyArray<WithStatusU<Officer>>;

export const teamMatch: SR.IssuersitesAboutControllerTeamRoute["match"] = SR.issuersitesAboutControllerTeamRoute().match;

const teamDescription: DescriptionTextFn = (issuer) =>
  `Learn about the team at ${issuerShortNameOrName(issuer)}.`;
export const team = (reqParams: SR.IssuersitesAboutControllerTeamParams) => ({
  description: makeDescription(
    financeTeam,
    teamDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    financeTeam,
    () => teamDescription
  ),
  _tag: "team",
  title: teamTitle,
  titleMeta: () => teamTitle,
  propsCodec: teamPageDataC,
  url: () => format(teamMatch, reqParams),
  photoPage: () => aboutPage,
  staticJumpLinks: () => jl.team.allStatic,
  makeSchemaOrg: O.none,
  renderChrome: true,
}) as const satisfies IssuerSitesRouteMeta<TeamPageData, t.OutputOf<TeamPageDataC>, "team">;

// Projects Page

export const projectsTitle = getEditableTitleOrDefault(infrastructureProjects, projectsMeta);

const projectsPageDataC: t.ReadonlyArrayC<WithStatusCU<TaggedContentC<ProjectWithPhotosC>>> = SR.issuersitesAboutControllerProjectsCodecs.ssrInput;
export type ProjectsPageDataC = typeof projectsPageDataC;
export type ProjectsPageData = ReadonlyArray<WithStatusU<TaggedContent<ProjectWithPhotos>>>;

export const projectsMatch: SR.IssuersitesAboutControllerProjectsRoute["match"] = SR.issuersitesAboutControllerProjectsRoute().match;

const projectsDescription: DescriptionTextFn = (issuer, _) =>
  `Learn about ${getPageTitleOrDefault(projectsMeta)(_)} for ${issuerShortNameOrName(issuer)}.`;

export const projects = (reqParams: SR.IssuersitesAboutControllerProjectsParams) => ({
  description: makeDescription(
    infrastructureProjects,
    projectsDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    infrastructureProjects,
    () => projectsDescription
  ),
  _tag: "projects",
  propsCodec: projectsPageDataC,
  url: () => format(projectsMatch, reqParams),
  title: projectsTitle,
  titleMeta: () => projectsTitle,
  photoPage: () => aboutPage,
  staticJumpLinks: () => jl.projects.allStatic,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<ProjectsPageData, t.OutputOf<ProjectsPageDataC>, "projects">);

// Project page
export type ProjectPageDataC = WithStatusCU<TaggedContentC<ProjectWithRelatedContentC>>;
export type ProjectPageData = WithStatusU<TaggedContent<ProjectWithRelatedContent>>;
const projectPageDataC: ProjectPageDataC = SR.issuersitesAboutControllerProjectItemCodecs.ssrInput;

export const projectMatch: SR.IssuersitesAboutControllerProjectItemRoute["match"] = SR.issuersitesAboutControllerProjectItemRoute().match;

export const project = (reqParams: SR.IssuersitesAboutControllerProjectItemParams) => ({
  descriptionMeta: p => (_ps, _fs, issuer) => `View ${p.data.record.data.project.projectTitle} from ${issuer.name}.`,
  _tag: "project",
  propsCodec: projectPageDataC,
  url: () => format(projectMatch, reqParams),
  title: projectsTitle,
  titleMeta: () => projectsTitle,
  photoPage: () => aboutPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<ProjectPageData, t.OutputOf<ProjectPageDataC>, "project">);

// DOCUMENTS PAGE
const documentCategoriesPageDataC: IssuerDocumentCategoriesPageDataC = SR.issuersitesReportsControllerIndexCodecs.ssrInput;
export type DocumentCategoriesPageDataC = typeof documentCategoriesPageDataC;
export type DocumentCategoriesPageData = IssuerDocumentCategoriesPageData;

export const documentCategoriesMatch: SR.IssuersitesReportsControllerIndexRoute["match"] = SR.issuersitesReportsControllerIndexRoute().match;
const documentCategoriesDescription: DescriptionTextFn = (issuer) =>
  `Browse documents from ${issuer.name} by category.`;

const documentTitle = constant("Documents");
export const documentCategories = (reqParams: SR.IssuersitesReportsControllerIndexParams) => ({
  description: makeDescription(
    documentsPage,
    documentCategoriesDescription
  ),
  descriptionMeta: makeDescriptionMeta(
    documentsPage,
    () => documentCategoriesDescription
  ),
  _tag: "document-categories",
  propsCodec: documentCategoriesPageDataC,
  title: documentTitle,
  titleMeta: () => documentTitle,
  url: () => format(documentCategoriesMatch, { ...reqParams }),
  photoPage: () => documentsPage,
  staticJumpLinks: () => jl.documents.allStatic,
  makeSchemaOrg: O.none,
  renderChrome: true,
}) as const satisfies IssuerSitesRouteMeta<DocumentCategoriesPageData, t.OutputOf<DocumentCategoriesPageDataC>, "document-categories">;

// DOWNLOADS PAGE
const downloadsPageDataC: IssuerDownloadsPageDataC = SR.issuersitesReportsControllerDownloadsCodecs.ssrInput;
export type DownloadsPageDataC = typeof downloadsPageDataC;
export type DownloadsPageData = IssuerDownloadsPageData;

export const downloadsMatch: SR.IssuersitesReportsControllerDownloadsRoute["match"] = SR.issuersitesReportsControllerDownloadsRoute().match;

const downloadsDescription: DescriptionTextFn = (issuer) =>
  `Download documents from ${issuer.name}, sort and filter documents, and sign up to receive document updates.`;

const downloadsTitleFn = () => "Downloads";

export const downloads = (reqParams: SR.IssuersitesReportsControllerDownloadsParamsRaw) => ({
  description: makeDescription(
    documentsPage,
    downloadsDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    documentsPage,
    () => downloadsDescription
  ),
  _tag: "downloads",
  propsCodec: downloadsPageDataC,
  title: downloadsTitleFn,
  titleMeta: () => downloadsTitleFn,
  url: () => format(downloadsMatch, { ...reqParams }),
  photoPage: () => documentsPage,
  staticJumpLinks: () => [jl.documents.downloads],
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<DownloadsPageData, t.OutputOf<DownloadsPageDataC>, "downloads">);

// ARCHIVED DOWNLOADS PAGE

export const archivedDownloadsMatch: SR.IssuersitesReportsControllerArchiveDownloadsRoute["match"] = SR.issuersitesReportsControllerArchiveDownloadsRoute().match;

const archivedDownloadsDescription: DescriptionTextFn = (issuer) =>
  `Download archived documents from ${issuer.name}.`;

const archivedDocumentsTitleFn = () => "Archived Documents";

export const archivedDocuments = (reqParams: SR.IssuersitesReportsControllerDownloadsParamsRaw) => ({
  description: makeDescription(
    documentsPage,
    archivedDownloadsDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    documentsPage,
    () => archivedDownloadsDescription
  ),
  _tag: "archived-documents",
  propsCodec: downloadsPageDataC,
  title: archivedDocumentsTitleFn,
  titleMeta: () => archivedDocumentsTitleFn,
  url: () => format(archivedDownloadsMatch, { ...reqParams }),
  photoPage: () => documentsPage,
  staticJumpLinks: () => [jl.documents.archivedDocuments],
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<DownloadsPageData, t.OutputOf<DownloadsPageDataC>, "archived-documents">);

// LINKS PAGE
const linksPageDataC: t.ReadonlyArrayC<ToManyC<WithIdC<IssuerLinkSectionC>, WithIdC<IssuerLinkC>>> = SR.issuersitesResourcesControllerLinksCodecs.ssrInput;
export type LinksPageDataC = typeof linksPageDataC;
export type LinksPageData = ReadonlyArray<ToMany<WithId<IssuerLinkSection>, WithId<IssuerLink>>>;

export const linksMatch: SR.IssuersitesResourcesControllerLinksRoute["match"] = SR.issuersitesResourcesControllerLinksRoute().match;

const makeLinksDescription: DescriptionTextFn = (issuer) => `View additional resources from ${issuer.name}, including our contact information, ${emmaName} Links, Frequently Asked Questions.`;

const linksTitleFn = getEditableTitleOrDefault(linksPage, linksMeta);

export const links = (reqParams: SR.IssuersitesResourcesControllerLinksParams) => ({
  description: makeDescription(
    linksPage,
    makeLinksDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    linksPage,
    () => makeLinksDescription
  ),
  _tag: "links",
  propsCodec: linksPageDataC,
  title: linksTitleFn,
  titleMeta: () => linksTitleFn,
  url: () => format(linksMatch, { ...reqParams }),
  photoPage: () => resourcesPage,
  staticJumpLinks: () => jl.links.allStatic,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<LinksPageData, t.OutputOf<LinksPageDataC>, "links">);

// RESOURCES PAGE

const resourcesPageDataC: IssuerResourcesPageDataC = SR.issuersitesResourcesControllerIndexCodecs.ssrInput;
export type ResourcePageDataC = typeof resourcesPageDataC;
export type ResourcePageData = IssuerResourcesPageData;

export const resourcesMatch: SR.IssuersitesResourcesControllerIndexRoute["match"] = SR.issuersitesResourcesControllerIndexRoute().match;

export const resourcesHeaderCopy = (
  issuer: Issuer,
  ffs: ClientFeatureFlags,
  pages: ReadonlyArray<PageConfig<PageU>>,
  customPageTitles: ReadonlyArray<string>,
) => pipe(
  [
    RA.fromPredicate(() => jl.resources.emma.enabled(ffs))(jl.resources.emma.text(pages)),
    RA.fromPredicate(() => jl.resources.faq.enabled(ffs))(jl.resources.faq.text(pages)),
    RA.fromPredicate(() => jl.resources.links.enabled(ffs))(jl.resources.links.text(pages)),
    RA.fromPredicate(() => jl.resources.contact.enabled(ffs))("contact information"),
    customPageTitles,
  ],
  RA.flatten,
  oxfordCommaString,
  _ => `View additional resources from ${issuer.name}${pipe(_, O.fold(() => "", s => `, including our ${s}`))}`,
);

const makeResourcesDescription: DescriptionTextFn = (issuer, page, pages, iffs) => {
  const customPageTitles = pipe(
    pages,
    RA.filter(pageConfigC(CustomPageCU).is),
    // Every Custom Page should have a title
    RA.filterMap(_ => _.title)
  );

  return resourcesHeaderCopy(issuer, iffs, pages, customPageTitles);
};

const resourcesTitleFn = getEditableTitleOrDefault(resourcesPage, resourcesMeta);

export const resources = (reqParams: SR.IssuersitesResourcesControllerIndexParams) => ({
  description: makeDescription(
    resourcesPage,
    makeResourcesDescription,
  ),
  descriptionMeta: makeDescriptionMeta(
    resourcesPage,
    () => makeResourcesDescription
  ),
  _tag: "resources",
  propsCodec: resourcesPageDataC,
  title: resourcesTitleFn,
  titleMeta: () => resourcesTitleFn,
  url: () => format(resourcesMatch, { ...reqParams }),
  photoPage: () => resourcesPage,
  staticJumpLinks: () => jl.resources.allStatic,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<ResourcePageData, t.OutputOf<ResourcePageDataC>, "resources">);

// Roadshow lander
export type RoadshowLanderDataC = WithIdC<TaggedContentC<RoadShowDataC>>;
export type RoadshowLanderData = WithId<TaggedContent<RoadShowData>>;
export const roadshowLanderDataC: RoadshowLanderDataC = SR.issuersitesRoadShowControllerRoadShowCodecs.ssrInput;

export const roadshowLanderMatch: SR.IssuersitesRoadShowControllerRoadShowRoute["match"] = SR.issuersitesRoadShowControllerRoadShowRoute().match;

export const roadshowLander = (reqParams: SR.IssuersitesRoadShowControllerRoadShowParams) => ({
  descriptionMeta: show => () => show.record.data.show.title,
  _tag: "roadshow-lander",
  propsCodec: roadshowLanderDataC,
  url: () => format(roadshowLanderMatch, reqParams),
  title: () => "Roadshow",
  titleMeta: show => () => show.record.data.show.title,
  photoPage: () => bondsPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<RoadshowLanderData, t.OutputOf<RoadshowLanderDataC>, "roadshow-lander">);

// Roadshow player
export const roadshowPlayerDataC: RoadShowSsrDataC = SR.issuersitesRoadShowControllerRoadShowPlayerCodecs.ssrInput;
export type RoadshowPlayerDataC = typeof roadshowPlayerDataC;
export type RoadshowPlayerData = RoadShowSsrData;

export const roadshowPlayerMatch: SR.IssuersitesRoadShowControllerRoadShowPlayerRoute["match"] = SR.issuersitesRoadShowControllerRoadShowPlayerRoute().match;

export const roadshowPlayer = (reqParams: SR.IssuersitesRoadShowControllerRoadShowPlayerParams) => ({
  descriptionMeta: () => (_ps, _fs, issuer) => `Roadshow slide presentation from ${issuer.name}`,
  _tag: "roadshow-player",
  propsCodec: roadshowPlayerDataC,
  url: () => format(roadshowPlayerMatch, reqParams),
  title: () => "Roadshow",
  titleMeta: ({ show }) => () => show.record.data.show.title,
  photoPage: () => bondsPage,
  makeSchemaOrg: O.none,
  renderChrome: false,
  bodyKlass: "roadshow-player",
} as const satisfies IssuerSitesRouteMeta<RoadshowPlayerData, t.OutputOf<RoadshowPlayerDataC>, "roadshow-player">);

// IRMA LETTER PAGE

export const irmaLetterPageDataC: WithStatusCU<MediaC> = SR.issuersitesReportsControllerIrmaLetterCodecs.ssrInput;
export type IrmaLetterPageDataC = typeof irmaLetterPageDataC;
export type IrmaLetterPageData = WithStatusU<Media>;

export const irmaLetterMatch: SR.IssuersitesReportsControllerIrmaLetterRoute["match"] = SR.issuersitesReportsControllerIrmaLetterRoute().match;

const makeIrmaLetterDescription: DescriptionTextFn = (issuer: Issuer) =>
  `${irmaLetterPage.name} - ${issuer.name}`;

export const irmaLetter = (reqParams: SR.IssuersitesReportsControllerIrmaLetterParams) => ({
  descriptionMeta: makeDescriptionMeta(
    irmaLetterPage,
    () => makeIrmaLetterDescription
  ),
  _tag: "irma-letter",
  propsCodec: irmaLetterPageDataC,
  title: () => irmaLetterPage.name,
  titleMeta: () => () => irmaLetterPage.name,
  url: () => format(irmaLetterMatch, { ...reqParams }),
  photoPage: () => documentsPage,
  staticJumpLinks: () => [jl.documents.irmaLetter],
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<IrmaLetterPageData, t.OutputOf<IrmaLetterPageDataC>, "irma-letter">);

// VIEW FILE PAGE

const viewFilePageDataC: WithStatusCU<TaggedContentC<DocumentWithCategoryC>> = SR.issuersitesReportsControllerViewFileCodecs.ssrInput;
export type ViewFilePageDataC = typeof viewFilePageDataC;
export type ViewFilePageData = WithStatusU<TaggedContent<DocumentWithCategory>>;

export const viewFileMatch: SR.IssuersitesReportsControllerViewFileRoute["match"] = SR.issuersitesReportsControllerViewFileRoute().match;

const makeViewFileDescriptionMeta: (data: ViewFilePageData) => DescriptionTextFn = (data) => (issuer: Issuer) =>
  `${data.data.record.data.document.uploadResponse.viewName} - ${issuer.name}`;

export const viewFile = (reqParams: SR.IssuersitesReportsControllerViewFileParams) => ({
  descriptionMeta: makeDescriptionMeta(
    documentsPage,
    makeViewFileDescriptionMeta
  ),
  _tag: "view-file",
  propsCodec: viewFilePageDataC,
  title: () => "Downloads",
  titleMeta: () => () => "Downloads",
  url: () => format(viewFileMatch, { ...reqParams }),
  photoPage: () => documentsPage,
  makeSchemaOrg: O.none,
  renderChrome: true,
} as const satisfies IssuerSitesRouteMeta<ViewFilePageData, t.OutputOf<ViewFilePageDataC>, "view-file">);

// Custom page
export const customPageMatch: SR.IssuersitesIssuerControllerCustomRoute["match"] = SR.issuersitesIssuerControllerCustomRoute().match;

const customPageFromIndex = (i: SR.IssuersitesIssuerControllerCustomParams["i"]): CustomPageU => {
  switch (i) {
    case 1: return customPage1;
    case 2: return customPage2;
    case 3: return customPage3;
    case 4: return customPage4;
    case 5: return customPage5;
    default: return exhaustiveNoLog(i);
  }
};

export const customPageTitleFn = (p: CustomPageU) => getCustomTitleOrName(p);

export const customPage = (reqParams: SR.IssuersitesIssuerControllerCustomParams) => {
  const page = customPageFromIndex(reqParams.i);
  const titleFn = customPageTitleFn(page);
  return {
    descriptionMeta: () => titleFn,
    _tag: "custom-page",
    propsCodec: sitesCustomPageDataC,
    title: titleFn,
    titleMeta: () => titleFn,
    url: () => format(customPageMatch, { ...reqParams }),
    photoPage: pages => pipe(
      getPage(page)(pages),
      O.chain(_ => _.pageHeader),
      O.getOrElse((): PhotoEditableU => resourcesPage),
    ),
    makeSchemaOrg: O.none,
    renderChrome: true,
  } as const satisfies IssuerSitesRouteMeta<SitesCustomPageData, t.OutputOf<SitesCustomPageDataC>, "custom-page">;
};

export const partnerLanderMatch: SR.IssuersitesLanderControllerIndexRoute["match"] = SR.issuersitesLanderControllerIndexRoute().match;

export const partnerLander = (reqParams: SR.IssuersitesLanderControllerIndexParams) => {
  const hp = home({ issuerId: reqParams.issuerId, issuerSlug: reqParams.issuerSlug });
  return {
    descriptionMeta: hp.descriptionMeta,
    _tag: "partner-lander",
    propsCodec: issuerPartnerLanderPageDataC,
    title: hp.title,
    titleMeta: hp.titleMeta,
    url: () => format(partnerLanderMatch, { ...reqParams }),
    photoPage: hp.photoPage,
    makeSchemaOrg: O.none,
    renderChrome: false,
  } as const satisfies IssuerSitesRouteMeta<IssuerPartnerLanderPageData, t.OutputOf<IssuerPartnerLanderPageDataC>, "partner-lander">;
};

export const emmaFilingsMatch: SR.IssuersitesEmmaControllerLinksRoute["match"] = SR.issuersitesEmmaControllerLinksRoute().match;

const makeEmmaFilingsDescription: DescriptionTextFn = issuer =>
  `View links and CUSIPs for ${issuer.name} on the ${emmaName} website.`;

const makeEmmaFilingsTitle = jl.resources.emma.text;

export const emmaLinks = (reqParams: SR.IssuersitesEmmaControllerLinksParams) => ({
  description: makeDescription(emmaLinksPage, makeEmmaFilingsDescription),
  descriptionMeta: makeDescriptionMeta(emmaLinksPage, () => makeEmmaFilingsDescription),
  _tag: "emma-links",
  propsCodec: emmaLinksPageDataC,
  title: makeEmmaFilingsTitle,
  titleMeta: () => makeEmmaFilingsTitle,
  url: () => SR.issuersitesEmmaControllerLinks(reqParams).url,
  photoPage: () => resourcesPage,
  staticJumpLinks: () => jl.emma.allWithoutCustomTitles,
  makeSchemaOrg: O.none,
  renderChrome: true,
}) as const satisfies IssuerSitesRouteMeta<EmmaLinksPageData, t.OutputOf<EmmaLinksPageDataC>, "emma-links">;

// Error pages
const baseError = { url: () => "/", makeSchemaOrg: O.none, renderChrome: true, isErrorPage: true } as const;

// BLP deal not available
export const blpDealUnavailableMatch: SR.IssuersitesSsrShimBLPDealUnavailableRoute["match"] = SR.issuersitesSsrShimBLPDealUnavailableRoute().match;

const blpDealUnavailableTitle = "Deal unavailable";
export const blpDealUnavailableDescription = "Sorry, this deal is no longer available.";

export const blpDealUnavailable = {
  ...baseError,
  _tag: "blp-deal-unavailable",
  propsCodec: SR.issuersitesSsrShimBLPDealUnavailableCodecs.ssrInput,
  title: () => blpDealUnavailableTitle,
  titleMeta: () => () => blpDealUnavailableTitle,
  descriptionMeta: () => () => blpDealUnavailableDescription,
  photoPage: () => bondsPage,
} as const satisfies IssuerSitesRouteMeta<BLPDealUnavailablePageData, t.OutputOf<BLPDealUnavailablePageDataC>, "blp-deal-unavailable">;

// NOT FOUND
const notFoundTitleFn = () => "Not Found";

export const unexpectedNotFound = {
  ...baseError,
  _tag: "unexpected-not-found",
  propsCodec: t.unknown,
  title: notFoundTitleFn,
  titleMeta: () => notFoundTitleFn,
  descriptionMeta: () => notFoundTitleFn,
  photoPage: () => homepage,
} as const satisfies IssuerSitesRouteMeta<unknown, t.OutputOf<t.UnknownC>, "unexpected-not-found">;

const errorPagePhotoPageFn: PhotoPageWithData<SitesErrorPageData> = { photoPageWithData: _ => () => _.photoPage };

export const expectedNotFoundMatch: SR.IssuersitesSsrShimNotFoundRoute["match"] = SR.issuersitesSsrShimNotFoundRoute().match;

export const expectedNotFound = {
  ...unexpectedNotFound,
  _tag: "expected-not-found",
  propsCodec: sitesErrorPageDataC,
  photoPage: errorPagePhotoPageFn,
} as const satisfies IssuerSitesRouteMeta<SitesErrorPageData, t.OutputOf<SitesErrorPageDataC>, "expected-not-found">;

// Forbidden
export const forbiddenMatch: SR.IssuersitesSsrShimForbiddenRoute["match"] = SR.issuersitesSsrShimForbiddenRoute().match;
const forbiddenTitleFn = () => "Forbidden";

export const forbidden = {
  ...baseError,
  _tag: "forbidden",
  propsCodec: sitesErrorPageDataC,
  title: forbiddenTitleFn,
  titleMeta: () => forbiddenTitleFn,
  descriptionMeta: () => forbiddenTitleFn,
  photoPage: errorPagePhotoPageFn,
} as const satisfies IssuerSitesRouteMeta<SitesErrorPageData, t.OutputOf<SitesErrorPageDataC>, "forbidden">;

// Server Error
export const serverErrorMatch: SR.IssuersitesSsrShimServerErrorRoute["match"] = SR.issuersitesSsrShimServerErrorRoute().match;
const serverErrorTitleFn = () => "Server Error";

export const serverError = {
  ...baseError,
  _tag: "server-error",
  propsCodec: sitesErrorPageDataC,
  title: serverErrorTitleFn,
  titleMeta: () => serverErrorTitleFn,
  descriptionMeta: () => serverErrorTitleFn,
  photoPage: errorPagePhotoPageFn,
} as const satisfies IssuerSitesRouteMeta<SitesErrorPageData, t.OutputOf<SitesErrorPageDataC>, "server-error">;
