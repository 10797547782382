import * as t from "io-ts";

export type ApiRedirectC = t.TypeC<{
  redirect: t.StringC
}>;
export type ApiRedirect = {
  redirect: string
};
export const apiRedirectC: ApiRedirectC = t.type({
  redirect: t.string
}) satisfies t.Type<ApiRedirect, unknown>;


export const apiReload = {
  _tag: `ApiReload`
} as const;

export type ApiReloadC = t.TypeC<{
  _tag: t.LiteralC<`ApiReload`>
}>;
export type ApiReload = {
  _tag: `ApiReload`
};
export const apiReloadC: ApiReloadC = t.type({
  _tag: t.literal(`ApiReload`)
}) satisfies t.Type<ApiReload, unknown>;


