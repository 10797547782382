export * from "fp-ts/lib/Either";
import type { Either } from "fp-ts/lib/Either";
import * as E from "fp-ts/lib/Either";

import { tap } from "@scripts/util/tap";

import { eitherCodec, fromCodec } from "./lib/_internal";
import type { Match } from "./lib/types";

export const is = (u: unknown): u is Match.AnyEither => fromCodec(eitherCodec)(u);

export type Any = Either<unknown, unknown>;

export type InferLeft<E extends Match.AnyEither>
  = E extends Either<infer Left, any> ? Left // eslint-disable-line @typescript-eslint/no-explicit-any
  : never;
export type InferRight<E extends Match.AnyEither>
  = E extends Either<any, infer Right> ? Right // eslint-disable-line @typescript-eslint/no-explicit-any
  : never;

export const biTap = <L, R>(onLeft: (left: L) => void, onRight: (right: R) => void) => E.bimap(
  tap(onLeft),
  tap(onRight)
);
