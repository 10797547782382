import { Route, query, lit, str, int, end, Match } from "fp-ts-routing/lib";
import * as t from "io-ts";
import { EitherC, either } from "io-ts-types/lib/either";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

import { HtmlC, htmlC } from "../../codecs/html";
import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { Describe } from "../../fp-ts/lib/types/describe";
import { NullableToOptionalFields, optionalFieldsToNullable } from "../../fp-ts/Record";
import { UrlInterfaceIO, urlInterfaceIO, UrlInterface, urlInterface } from "../../routes/urlInterface";
import { LoginStatusCU as imported0_LoginStatusCU, RedirectC as imported2_RedirectC, LoginStatusCU as imported1_LoginStatusCU, redirectC as imported0_redirectC, mfaInitiatedC as imported2_mfaInitiatedC, MfaInitiatedC as imported4_MfaInitiatedC } from "../domaintables/loginStatus";
import { ApiRedirectC as imported4_ApiRedirectC, apiRedirectC as imported2_apiRedirectC, ApiRedirectC as imported1_ApiRedirectC, ApiRedirectC as imported0_ApiRedirectC, apiRedirectC as imported4_apiRedirectC, ApiRedirectC as imported2_ApiRedirectC, apiRedirectC as imported1_apiRedirectC, ApiRedirectC as imported3_ApiRedirectC, apiRedirectC as imported0_apiRedirectC, apiRedirectC as imported3_apiRedirectC } from "../models/apiRedirect";
import { BrowserRedirectC as imported6_BrowserRedirectC, browserRedirectC as imported10_browserRedirectC, browserRedirectC as imported0_browserRedirectC, BrowserRedirectC as imported2_BrowserRedirectC, browserRedirectC as imported6_browserRedirectC, BrowserRedirectC as imported7_BrowserRedirectC, BrowserRedirectC as imported9_BrowserRedirectC, BrowserRedirectC as imported0_BrowserRedirectC, browserRedirectC as imported2_browserRedirectC, browserRedirectC as imported3_browserRedirectC, BrowserRedirectC as imported3_BrowserRedirectC, browserRedirectC as imported8_browserRedirectC, browserRedirectC as imported5_browserRedirectC, browserRedirectC as imported4_browserRedirectC, BrowserRedirectC as imported1_BrowserRedirectC, browserRedirectC as imported9_browserRedirectC, BrowserRedirectC as imported10_BrowserRedirectC, BrowserRedirectC as imported4_BrowserRedirectC, browserRedirectC as imported7_browserRedirectC, BrowserRedirectC as imported8_BrowserRedirectC, browserRedirectC as imported1_browserRedirectC, BrowserRedirectC as imported5_BrowserRedirectC } from "../models/browserRedirect";
import { BuildInfoC as imported0_BuildInfoC, buildInfoC as imported0_buildInfoC } from "../models/buildInfo";
import { ValidateEmailC as imported0_ValidateEmailC, validateEmailC as imported0_validateEmailC } from "../models/email";
import { EmailUnsubscribeDataC as imported0_EmailUnsubscribeDataC, emailUnsubscribeDataC as imported0_emailUnsubscribeDataC, EmailUnsubscribePostC as imported0_EmailUnsubscribePostC, emailUnsubscribePostC as imported0_emailUnsubscribePostC } from "../models/emailCampaign";
import { EmptyObjectC as imported0_EmptyObjectC, emptyObjectC as imported0_emptyObjectC } from "../models/emptyObject";
import { IssuerSearchPostC as imported0_IssuerSearchPostC, IssuerSearchResultsC as imported0_IssuerSearchResultsC, issuerSearchPostC as imported0_issuerSearchPostC, issuerSearchResultsC as imported0_issuerSearchResultsC } from "../models/issuerSearch";
import { RefreshSessionC as imported0_RefreshSessionC, ForgotPasswordPostC as imported0_ForgotPasswordPostC, LoginAsPostC as imported0_LoginAsPostC, LoginPostC as imported0_LoginPostC, resetPasswordMfaDataC as imported0_resetPasswordMfaDataC, loginAsPostC as imported0_loginAsPostC, ResetPasswordDataC as imported2_ResetPasswordDataC, loginPageDataC as imported0_loginPageDataC, loginPostC as imported0_loginPostC, resetPasswordDataC as imported2_resetPasswordDataC, ResetPasswordMfaDataC as imported0_ResetPasswordMfaDataC, ResetForgottenPasswordPostC as imported0_ResetForgottenPasswordPostC, LoginPageDataC as imported1_LoginPageDataC, resetForgottenPasswordPostC as imported0_resetForgottenPasswordPostC, forgotPasswordPostC as imported0_forgotPasswordPostC, refreshSessionC as imported0_refreshSessionC, loginPageDataC as imported1_loginPageDataC, LoginPageDataC as imported0_LoginPageDataC } from "../models/login";
import { MediaPreviewC as imported0_MediaPreviewC, mediaPreviewC as imported0_mediaPreviewC } from "../models/media";
import { ResendMfaSmsC as imported0_ResendMfaSmsC, ResendMfaSmsC as imported1_ResendMfaSmsC, resendMfaSmsC as imported0_resendMfaSmsC, resendMfaSmsC as imported1_resendMfaSmsC } from "../models/mfa";
import { PrivateSiteC as imported0_PrivateSiteC, privateSiteC as imported0_privateSiteC } from "../models/privateSite";
import { s3ListMultipartUploadPartsResponseC as imported0_s3ListMultipartUploadPartsResponseC, S3ListMultipartUploadPartsResponseC as imported0_S3ListMultipartUploadPartsResponseC, s3MultipartUploadInfoC as imported6_s3MultipartUploadInfoC, s3MultipartUploadInfoC as imported7_s3MultipartUploadInfoC, s3PresignedMultipartUploadPartsC as imported0_s3PresignedMultipartUploadPartsC, s3CreateMultipartUploadPostC as imported0_s3CreateMultipartUploadPostC, S3ListMultipartUploadPartsPostC as imported0_S3ListMultipartUploadPartsPostC, S3PresignedMultipartUploadPartsC as imported0_S3PresignedMultipartUploadPartsC, S3PresignMultipartUploadPartsPostC as imported0_S3PresignMultipartUploadPartsPostC, s3PresignMultipartUploadPartsPostC as imported0_s3PresignMultipartUploadPartsPostC, S3CompleteMultipartUploadPostC as imported0_S3CompleteMultipartUploadPostC, S3CompleteMultipartUploadResponseC as imported0_S3CompleteMultipartUploadResponseC, s3CompleteMultipartUploadResponseC as imported0_s3CompleteMultipartUploadResponseC, S3MultipartUploadInfoC as imported7_S3MultipartUploadInfoC, s3SuccessPostC as imported0_s3SuccessPostC, S3CreateMultipartUploadPostC as imported0_S3CreateMultipartUploadPostC, S3SuccessPostC as imported0_S3SuccessPostC, s3ListMultipartUploadPartsPostC as imported0_s3ListMultipartUploadPartsPostC, S3MultipartUploadInfoC as imported6_S3MultipartUploadInfoC, s3CompleteMultipartUploadPostC as imported0_s3CompleteMultipartUploadPostC } from "../models/s3Upload";
import { SignupPostC as imported0_SignupPostC, signupPostC as imported0_signupPostC } from "../models/signup";
import { ValidateUrlC as imported0_ValidateUrlC, validateUrlC as imported0_validateUrlC } from "../models/url";
import { ConfirmAccountDataC as imported0_ConfirmAccountDataC, confirmAccountDataC as imported0_confirmAccountDataC, ConfirmAccountPostC as imported0_ConfirmAccountPostC, confirmAccountPostC as imported0_confirmAccountPostC } from "../models/user";
import { VerifyTotpPostC as imported0_VerifyTotpPostC, verifyTotpPostC as imported0_verifyTotpPostC } from "../models/userSettings";


const baseIndexPathParts = [] as const;
export type BaseIndexPathParts = typeof baseIndexPathParts;

const baseIndexQuery = t.strict({});
export type BaseIndexParams = Describe<typeof baseIndexQuery._A>;

export type BaseIndexRoute<A = {}> = { match: Match<BaseIndexParams & A>; pathParts: BaseIndexPathParts };

export function baseIndexRoute(): BaseIndexRoute;
export function baseIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseIndexParams>): BaseIndexRoute<t.TypeOf<A>>;
export function baseIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseIndexParams>) {
  return {
    match: (q ? query(t.intersection([baseIndexQuery, q])) : query(baseIndexQuery)).then(end),
    pathParts: baseIndexPathParts,
  };
}

export const baseIndexCodecs: { input: imported0_EmptyObjectC, output: imported0_EmptyObjectC } = {
  input: imported0_emptyObjectC,
  output: imported0_emptyObjectC
};

export const baseIndex = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  urlInterfaceIO("GET", baseIndexRoute().match.formatter.run(Route.empty, {}).toString(true), baseIndexCodecs);

const baseRobotsTxtPath = lit("robots.txt");
const baseRobotsTxtPathParts = ["robots.txt"] as const;
export type BaseRobotsTxtPathParts = typeof baseRobotsTxtPathParts;

const baseRobotsTxtQuery = t.strict({});
export type BaseRobotsTxtParams = Describe<typeof baseRobotsTxtPath._A & typeof baseRobotsTxtQuery._A>;

export type BaseRobotsTxtRoute<A = {}> = { match: Match<BaseRobotsTxtParams & A>; pathParts: BaseRobotsTxtPathParts };

export function baseRobotsTxtRoute(): BaseRobotsTxtRoute;
export function baseRobotsTxtRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseRobotsTxtParams>): BaseRobotsTxtRoute<t.TypeOf<A>>;
export function baseRobotsTxtRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseRobotsTxtParams>) {
  return {
    match: (q ? baseRobotsTxtPath.then(query(t.intersection([baseRobotsTxtQuery, q]))) : baseRobotsTxtPath.then(query(baseRobotsTxtQuery))).then(end),
    pathParts: baseRobotsTxtPathParts,
  };
}

export const baseRobotsTxtCodecs: { input: imported0_EmptyObjectC, output: t.StringC } = {
  input: imported0_emptyObjectC,
  output: t.string
};

export const baseRobotsTxt = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.StringC> =>
  urlInterfaceIO("GET", baseRobotsTxtRoute().match.formatter.run(Route.empty, {}).toString(true), baseRobotsTxtCodecs);

const baseBuildInfoPath = lit("build-info");
const baseBuildInfoPathParts = ["build-info"] as const;
export type BaseBuildInfoPathParts = typeof baseBuildInfoPathParts;

const baseBuildInfoQuery = t.strict({});
export type BaseBuildInfoParams = Describe<typeof baseBuildInfoPath._A & typeof baseBuildInfoQuery._A>;

export type BaseBuildInfoRoute<A = {}> = { match: Match<BaseBuildInfoParams & A>; pathParts: BaseBuildInfoPathParts };

export function baseBuildInfoRoute(): BaseBuildInfoRoute;
export function baseBuildInfoRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseBuildInfoParams>): BaseBuildInfoRoute<t.TypeOf<A>>;
export function baseBuildInfoRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseBuildInfoParams>) {
  return {
    match: (q ? baseBuildInfoPath.then(query(t.intersection([baseBuildInfoQuery, q]))) : baseBuildInfoPath.then(query(baseBuildInfoQuery))).then(end),
    pathParts: baseBuildInfoPathParts,
  };
}

export const baseBuildInfoCodecs: { input: imported0_EmptyObjectC, output: imported0_BuildInfoC } = {
  input: imported0_emptyObjectC,
  output: imported0_buildInfoC
};

export const baseBuildInfo = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_BuildInfoC> =>
  urlInterfaceIO("GET", baseBuildInfoRoute().match.formatter.run(Route.empty, {}).toString(true), baseBuildInfoCodecs);

const baseAuthControllerLoginPath = lit("login");
const baseAuthControllerLoginPathParts = ["login"] as const;
export type BaseAuthControllerLoginPathParts = typeof baseAuthControllerLoginPathParts;

const baseAuthControllerLoginQuery = t.exact(t.partial({
  "bankId": NumberFromString,
  "issuerId": NumberFromString,
  "uhash": t.string,
  "reason": t.union([
    t.literal("bookmark-bond"), t.literal("bookmark-rfp"), t.literal("download-doc"), t.literal("notifications"), t.literal("roadshow"), t.literal("submit-rfp-bid"), t.literal("view-bond"), t.literal("view-doc")
  ]),
  "redirect": t.string
}));
export type BaseAuthControllerLoginParamsRaw = Describe<typeof baseAuthControllerLoginPath._A & typeof baseAuthControllerLoginQuery._A>;
export type BaseAuthControllerLoginParams = Describe<NullableToOptionalFields<BaseAuthControllerLoginParamsRaw>>;

export type BaseAuthControllerLoginRoute<A = {}> = { match: Match<BaseAuthControllerLoginParamsRaw & A>; pathParts: BaseAuthControllerLoginPathParts };

export function baseAuthControllerLoginRoute(): BaseAuthControllerLoginRoute;
export function baseAuthControllerLoginRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerLoginParamsRaw>): BaseAuthControllerLoginRoute<t.TypeOf<A>>;
export function baseAuthControllerLoginRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerLoginParamsRaw>) {
  return {
    match: (q ? baseAuthControllerLoginPath.then(query(t.intersection([baseAuthControllerLoginQuery, q]))) : baseAuthControllerLoginPath.then(query(baseAuthControllerLoginQuery))).then(end),
    pathParts: baseAuthControllerLoginPathParts,
  };
}

export const baseAuthControllerLoginCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_LoginPageDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_loginPageDataC };

export const baseAuthControllerLogin = (p: BaseAuthControllerLoginParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  urlInterfaceIO("GET", baseAuthControllerLoginRoute().match.formatter.run(Route.empty, optionalFieldsToNullable(p)).toString(true), baseAuthControllerLoginCodecs);

const baseAuthControllerSignupPath = lit("signup");
const baseAuthControllerSignupPathParts = ["signup"] as const;
export type BaseAuthControllerSignupPathParts = typeof baseAuthControllerSignupPathParts;

const baseAuthControllerSignupQuery = t.exact(t.partial({
  "bankId": NumberFromString,
  "issuerId": NumberFromString,
  "uhash": t.string,
  "reason": t.union([
    t.literal("bookmark-bond"), t.literal("bookmark-rfp"), t.literal("download-doc"), t.literal("notifications"), t.literal("roadshow"), t.literal("submit-rfp-bid"), t.literal("view-bond"), t.literal("view-doc")
  ]),
  "redirect": t.string
}));
export type BaseAuthControllerSignupParamsRaw = Describe<typeof baseAuthControllerSignupPath._A & typeof baseAuthControllerSignupQuery._A>;
export type BaseAuthControllerSignupParams = Describe<NullableToOptionalFields<BaseAuthControllerSignupParamsRaw>>;

export type BaseAuthControllerSignupRoute<A = {}> = { match: Match<BaseAuthControllerSignupParamsRaw & A>; pathParts: BaseAuthControllerSignupPathParts };

export function baseAuthControllerSignupRoute(): BaseAuthControllerSignupRoute;
export function baseAuthControllerSignupRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerSignupParamsRaw>): BaseAuthControllerSignupRoute<t.TypeOf<A>>;
export function baseAuthControllerSignupRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerSignupParamsRaw>) {
  return {
    match: (q ? baseAuthControllerSignupPath.then(query(t.intersection([baseAuthControllerSignupQuery, q]))) : baseAuthControllerSignupPath.then(query(baseAuthControllerSignupQuery))).then(end),
    pathParts: baseAuthControllerSignupPathParts,
  };
}

export const baseAuthControllerSignupCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported1_LoginPageDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported1_loginPageDataC };

export const baseAuthControllerSignup = (p: BaseAuthControllerSignupParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  urlInterfaceIO("GET", baseAuthControllerSignupRoute().match.formatter.run(Route.empty, optionalFieldsToNullable(p)).toString(true), baseAuthControllerSignupCodecs);

const baseAuthControllerLoginPostPath = lit("login");
const baseAuthControllerLoginPostPathParts = ["login"] as const;
export type BaseAuthControllerLoginPostPathParts = typeof baseAuthControllerLoginPostPathParts;

const baseAuthControllerLoginPostQuery = t.strict({});
export type BaseAuthControllerLoginPostParams = Describe<typeof baseAuthControllerLoginPostPath._A & typeof baseAuthControllerLoginPostQuery._A>;

export type BaseAuthControllerLoginPostRoute<A = {}> = { match: Match<BaseAuthControllerLoginPostParams & A>; pathParts: BaseAuthControllerLoginPostPathParts };

export function baseAuthControllerLoginPostRoute(): BaseAuthControllerLoginPostRoute;
export function baseAuthControllerLoginPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerLoginPostParams>): BaseAuthControllerLoginPostRoute<t.TypeOf<A>>;
export function baseAuthControllerLoginPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerLoginPostParams>) {
  return {
    match: (q ? baseAuthControllerLoginPostPath.then(query(t.intersection([baseAuthControllerLoginPostQuery, q]))) : baseAuthControllerLoginPostPath.then(query(baseAuthControllerLoginPostQuery))).then(end),
    pathParts: baseAuthControllerLoginPostPathParts,
  };
}

export const baseAuthControllerLoginPostCodecs: { input: imported0_LoginPostC, output: imported0_LoginStatusCU } = {
  input: imported0_loginPostC,
  output: imported1_LoginStatusCU
};

export const baseAuthControllerLoginPost = (): UrlInterfaceIO<"POST", imported0_LoginPostC, imported0_LoginStatusCU> =>
  urlInterfaceIO("POST", baseAuthControllerLoginPostRoute().match.formatter.run(Route.empty, {}).toString(true), baseAuthControllerLoginPostCodecs);

const baseAuthControllerLoginAsPath = lit("admin").then(lit("login-as")).then(int("userId"));
const baseAuthControllerLoginAsPathParts = ["admin", "login-as", "userId"] as const;
export type BaseAuthControllerLoginAsPathParts = typeof baseAuthControllerLoginAsPathParts;

const baseAuthControllerLoginAsQuery = t.strict({});
export type BaseAuthControllerLoginAsParams = Describe<typeof baseAuthControllerLoginAsPath._A & typeof baseAuthControllerLoginAsQuery._A>;

export type BaseAuthControllerLoginAsRoute<A = {}> = { match: Match<BaseAuthControllerLoginAsParams & A>; pathParts: BaseAuthControllerLoginAsPathParts };

export function baseAuthControllerLoginAsRoute(): BaseAuthControllerLoginAsRoute;
export function baseAuthControllerLoginAsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerLoginAsParams>): BaseAuthControllerLoginAsRoute<t.TypeOf<A>>;
export function baseAuthControllerLoginAsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerLoginAsParams>) {
  return {
    match: (q ? baseAuthControllerLoginAsPath.then(query(t.intersection([baseAuthControllerLoginAsQuery, q]))) : baseAuthControllerLoginAsPath.then(query(baseAuthControllerLoginAsQuery))).then(end),
    pathParts: baseAuthControllerLoginAsPathParts,
  };
}

export const baseAuthControllerLoginAsCodecs: { input: imported0_LoginAsPostC, output: imported0_BrowserRedirectC } = {
  input: imported0_loginAsPostC,
  output: imported0_browserRedirectC
};

export const baseAuthControllerLoginAs = (p: BaseAuthControllerLoginAsParams): UrlInterfaceIO<"POST", imported0_LoginAsPostC, imported0_BrowserRedirectC> =>
  urlInterfaceIO("POST", baseAuthControllerLoginAsRoute().match.formatter.run(Route.empty, p).toString(true), baseAuthControllerLoginAsCodecs);

const baseAuthControllerSignupPostPath = lit("signup");
const baseAuthControllerSignupPostPathParts = ["signup"] as const;
export type BaseAuthControllerSignupPostPathParts = typeof baseAuthControllerSignupPostPathParts;

const baseAuthControllerSignupPostQuery = t.strict({});
export type BaseAuthControllerSignupPostParams = Describe<typeof baseAuthControllerSignupPostPath._A & typeof baseAuthControllerSignupPostQuery._A>;

export type BaseAuthControllerSignupPostRoute<A = {}> = { match: Match<BaseAuthControllerSignupPostParams & A>; pathParts: BaseAuthControllerSignupPostPathParts };

export function baseAuthControllerSignupPostRoute(): BaseAuthControllerSignupPostRoute;
export function baseAuthControllerSignupPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerSignupPostParams>): BaseAuthControllerSignupPostRoute<t.TypeOf<A>>;
export function baseAuthControllerSignupPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerSignupPostParams>) {
  return {
    match: (q ? baseAuthControllerSignupPostPath.then(query(t.intersection([baseAuthControllerSignupPostQuery, q]))) : baseAuthControllerSignupPostPath.then(query(baseAuthControllerSignupPostQuery))).then(end),
    pathParts: baseAuthControllerSignupPostPathParts,
  };
}

export const baseAuthControllerSignupPostCodecs: { input: imported0_SignupPostC, output: imported0_ApiRedirectC } = {
  input: imported0_signupPostC,
  output: imported0_apiRedirectC
};

export const baseAuthControllerSignupPost = (): UrlInterfaceIO<"POST", imported0_SignupPostC, imported0_ApiRedirectC> =>
  urlInterfaceIO("POST", baseAuthControllerSignupPostRoute().match.formatter.run(Route.empty, {}).toString(true), baseAuthControllerSignupPostCodecs);

const baseAuthControllerForgotPasswordRequestPath = lit("v2").then(lit("forgot-password")).then(lit("request"));
const baseAuthControllerForgotPasswordRequestPathParts = ["v2", "forgot-password", "request"] as const;
export type BaseAuthControllerForgotPasswordRequestPathParts = typeof baseAuthControllerForgotPasswordRequestPathParts;

const baseAuthControllerForgotPasswordRequestQuery = t.strict({});
export type BaseAuthControllerForgotPasswordRequestParams = Describe<typeof baseAuthControllerForgotPasswordRequestPath._A & typeof baseAuthControllerForgotPasswordRequestQuery._A>;

export type BaseAuthControllerForgotPasswordRequestRoute<A = {}> = { match: Match<BaseAuthControllerForgotPasswordRequestParams & A>; pathParts: BaseAuthControllerForgotPasswordRequestPathParts };

export function baseAuthControllerForgotPasswordRequestRoute(): BaseAuthControllerForgotPasswordRequestRoute;
export function baseAuthControllerForgotPasswordRequestRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerForgotPasswordRequestParams>): BaseAuthControllerForgotPasswordRequestRoute<t.TypeOf<A>>;
export function baseAuthControllerForgotPasswordRequestRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerForgotPasswordRequestParams>) {
  return {
    match: (q ? baseAuthControllerForgotPasswordRequestPath.then(query(t.intersection([baseAuthControllerForgotPasswordRequestQuery, q]))) : baseAuthControllerForgotPasswordRequestPath.then(query(baseAuthControllerForgotPasswordRequestQuery))).then(end),
    pathParts: baseAuthControllerForgotPasswordRequestPathParts,
  };
}

export const baseAuthControllerForgotPasswordRequestCodecs: {
  input: imported0_ForgotPasswordPostC,
  output: imported0_EmptyObjectC
} = { input: imported0_forgotPasswordPostC, output: imported0_emptyObjectC };

export const baseAuthControllerForgotPasswordRequest = (): UrlInterfaceIO<"POST", imported0_ForgotPasswordPostC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", baseAuthControllerForgotPasswordRequestRoute().match.formatter.run(Route.empty, {}).toString(true), baseAuthControllerForgotPasswordRequestCodecs);

const baseAuthControllerConfirmPasswordLinkPath = lit("password-reset");
const baseAuthControllerConfirmPasswordLinkPathParts = ["password-reset"] as const;
export type BaseAuthControllerConfirmPasswordLinkPathParts = typeof baseAuthControllerConfirmPasswordLinkPathParts;

const baseAuthControllerConfirmPasswordLinkQuery = t.exact(t.type({
  "e": t.string,
  "h": t.string,
  "r": t.string
}));
export type BaseAuthControllerConfirmPasswordLinkParams = Describe<typeof baseAuthControllerConfirmPasswordLinkPath._A & typeof baseAuthControllerConfirmPasswordLinkQuery._A>;

export type BaseAuthControllerConfirmPasswordLinkRoute<A = {}> = { match: Match<BaseAuthControllerConfirmPasswordLinkParams & A>; pathParts: BaseAuthControllerConfirmPasswordLinkPathParts };

export function baseAuthControllerConfirmPasswordLinkRoute(): BaseAuthControllerConfirmPasswordLinkRoute;
export function baseAuthControllerConfirmPasswordLinkRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerConfirmPasswordLinkParams>): BaseAuthControllerConfirmPasswordLinkRoute<t.TypeOf<A>>;
export function baseAuthControllerConfirmPasswordLinkRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerConfirmPasswordLinkParams>) {
  return {
    match: (q ? baseAuthControllerConfirmPasswordLinkPath.then(query(t.intersection([baseAuthControllerConfirmPasswordLinkQuery, q]))) : baseAuthControllerConfirmPasswordLinkPath.then(query(baseAuthControllerConfirmPasswordLinkQuery))).then(end),
    pathParts: baseAuthControllerConfirmPasswordLinkPathParts,
  };
}

export const baseAuthControllerConfirmPasswordLinkCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported2_ResetPasswordDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported2_resetPasswordDataC };

export const baseAuthControllerConfirmPasswordLink = (p: BaseAuthControllerConfirmPasswordLinkParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  urlInterfaceIO("GET", baseAuthControllerConfirmPasswordLinkRoute().match.formatter.run(Route.empty, p).toString(true), baseAuthControllerConfirmPasswordLinkCodecs);

const baseAuthControllerConfirmPasswordLinkMfaPath = lit("password-reset").then(lit("mfa"));
const baseAuthControllerConfirmPasswordLinkMfaPathParts = ["password-reset", "mfa"] as const;
export type BaseAuthControllerConfirmPasswordLinkMfaPathParts = typeof baseAuthControllerConfirmPasswordLinkMfaPathParts;

const baseAuthControllerConfirmPasswordLinkMfaQuery = t.exact(t.type({
  "e": t.string,
  "h": t.string,
  "r": t.string
}));
export type BaseAuthControllerConfirmPasswordLinkMfaParams = Describe<typeof baseAuthControllerConfirmPasswordLinkMfaPath._A & typeof baseAuthControllerConfirmPasswordLinkMfaQuery._A>;

export type BaseAuthControllerConfirmPasswordLinkMfaRoute<A = {}> = { match: Match<BaseAuthControllerConfirmPasswordLinkMfaParams & A>; pathParts: BaseAuthControllerConfirmPasswordLinkMfaPathParts };

export function baseAuthControllerConfirmPasswordLinkMfaRoute(): BaseAuthControllerConfirmPasswordLinkMfaRoute;
export function baseAuthControllerConfirmPasswordLinkMfaRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerConfirmPasswordLinkMfaParams>): BaseAuthControllerConfirmPasswordLinkMfaRoute<t.TypeOf<A>>;
export function baseAuthControllerConfirmPasswordLinkMfaRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerConfirmPasswordLinkMfaParams>) {
  return {
    match: (q ? baseAuthControllerConfirmPasswordLinkMfaPath.then(query(t.intersection([baseAuthControllerConfirmPasswordLinkMfaQuery, q]))) : baseAuthControllerConfirmPasswordLinkMfaPath.then(query(baseAuthControllerConfirmPasswordLinkMfaQuery))).then(end),
    pathParts: baseAuthControllerConfirmPasswordLinkMfaPathParts,
  };
}

export const baseAuthControllerConfirmPasswordLinkMfaCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_ResetPasswordMfaDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_resetPasswordMfaDataC };

export const baseAuthControllerConfirmPasswordLinkMfa = (p: BaseAuthControllerConfirmPasswordLinkMfaParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  urlInterfaceIO("GET", baseAuthControllerConfirmPasswordLinkMfaRoute().match.formatter.run(Route.empty, p).toString(true), baseAuthControllerConfirmPasswordLinkMfaCodecs);

const baseAuthControllerConfirmPasswordRequestMfaPath = lit("password-reset").then(lit("request-mfa"));
const baseAuthControllerConfirmPasswordRequestMfaPathParts = ["password-reset", "request-mfa"] as const;
export type BaseAuthControllerConfirmPasswordRequestMfaPathParts = typeof baseAuthControllerConfirmPasswordRequestMfaPathParts;

const baseAuthControllerConfirmPasswordRequestMfaQuery = t.exact(t.type({
  "e": t.string,
  "h": t.string
}));
export type BaseAuthControllerConfirmPasswordRequestMfaParams = Describe<typeof baseAuthControllerConfirmPasswordRequestMfaPath._A & typeof baseAuthControllerConfirmPasswordRequestMfaQuery._A>;

export type BaseAuthControllerConfirmPasswordRequestMfaRoute<A = {}> = { match: Match<BaseAuthControllerConfirmPasswordRequestMfaParams & A>; pathParts: BaseAuthControllerConfirmPasswordRequestMfaPathParts };

export function baseAuthControllerConfirmPasswordRequestMfaRoute(): BaseAuthControllerConfirmPasswordRequestMfaRoute;
export function baseAuthControllerConfirmPasswordRequestMfaRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerConfirmPasswordRequestMfaParams>): BaseAuthControllerConfirmPasswordRequestMfaRoute<t.TypeOf<A>>;
export function baseAuthControllerConfirmPasswordRequestMfaRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerConfirmPasswordRequestMfaParams>) {
  return {
    match: (q ? baseAuthControllerConfirmPasswordRequestMfaPath.then(query(t.intersection([baseAuthControllerConfirmPasswordRequestMfaQuery, q]))) : baseAuthControllerConfirmPasswordRequestMfaPath.then(query(baseAuthControllerConfirmPasswordRequestMfaQuery))).then(end),
    pathParts: baseAuthControllerConfirmPasswordRequestMfaPathParts,
  };
}

export const baseAuthControllerConfirmPasswordRequestMfaCodecs: {
  input: imported0_EmptyObjectC,
  output: imported4_MfaInitiatedC
} = { input: imported0_emptyObjectC, output: imported2_mfaInitiatedC };

export const baseAuthControllerConfirmPasswordRequestMfa = (p: BaseAuthControllerConfirmPasswordRequestMfaParams): UrlInterfaceIO<"POST", imported0_EmptyObjectC, imported4_MfaInitiatedC> =>
  urlInterfaceIO("POST", baseAuthControllerConfirmPasswordRequestMfaRoute().match.formatter.run(Route.empty, p).toString(true), baseAuthControllerConfirmPasswordRequestMfaCodecs);

const baseAuthControllerResetForgottenPasswordPath = lit("v2").then(lit("password-reset"));
const baseAuthControllerResetForgottenPasswordPathParts = ["v2", "password-reset"] as const;
export type BaseAuthControllerResetForgottenPasswordPathParts = typeof baseAuthControllerResetForgottenPasswordPathParts;

const baseAuthControllerResetForgottenPasswordQuery = t.strict({});
export type BaseAuthControllerResetForgottenPasswordParams = Describe<typeof baseAuthControllerResetForgottenPasswordPath._A & typeof baseAuthControllerResetForgottenPasswordQuery._A>;

export type BaseAuthControllerResetForgottenPasswordRoute<A = {}> = { match: Match<BaseAuthControllerResetForgottenPasswordParams & A>; pathParts: BaseAuthControllerResetForgottenPasswordPathParts };

export function baseAuthControllerResetForgottenPasswordRoute(): BaseAuthControllerResetForgottenPasswordRoute;
export function baseAuthControllerResetForgottenPasswordRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerResetForgottenPasswordParams>): BaseAuthControllerResetForgottenPasswordRoute<t.TypeOf<A>>;
export function baseAuthControllerResetForgottenPasswordRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerResetForgottenPasswordParams>) {
  return {
    match: (q ? baseAuthControllerResetForgottenPasswordPath.then(query(t.intersection([baseAuthControllerResetForgottenPasswordQuery, q]))) : baseAuthControllerResetForgottenPasswordPath.then(query(baseAuthControllerResetForgottenPasswordQuery))).then(end),
    pathParts: baseAuthControllerResetForgottenPasswordPathParts,
  };
}

export const baseAuthControllerResetForgottenPasswordCodecs: {
  input: imported0_ResetForgottenPasswordPostC,
  output: imported1_ApiRedirectC
} = { input: imported0_resetForgottenPasswordPostC, output: imported1_apiRedirectC };

export const baseAuthControllerResetForgottenPassword = (): UrlInterfaceIO<"POST", imported0_ResetForgottenPasswordPostC, imported1_ApiRedirectC> =>
  urlInterfaceIO("POST", baseAuthControllerResetForgottenPasswordRoute().match.formatter.run(Route.empty, {}).toString(true), baseAuthControllerResetForgottenPasswordCodecs);

const baseAuthControllerLogoutPath = lit("logout");
const baseAuthControllerLogoutPathParts = ["logout"] as const;
export type BaseAuthControllerLogoutPathParts = typeof baseAuthControllerLogoutPathParts;

const baseAuthControllerLogoutQuery = t.strict({});
export type BaseAuthControllerLogoutParams = Describe<typeof baseAuthControllerLogoutPath._A & typeof baseAuthControllerLogoutQuery._A>;

export type BaseAuthControllerLogoutRoute<A = {}> = { match: Match<BaseAuthControllerLogoutParams & A>; pathParts: BaseAuthControllerLogoutPathParts };

export function baseAuthControllerLogoutRoute(): BaseAuthControllerLogoutRoute;
export function baseAuthControllerLogoutRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerLogoutParams>): BaseAuthControllerLogoutRoute<t.TypeOf<A>>;
export function baseAuthControllerLogoutRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerLogoutParams>) {
  return {
    match: (q ? baseAuthControllerLogoutPath.then(query(t.intersection([baseAuthControllerLogoutQuery, q]))) : baseAuthControllerLogoutPath.then(query(baseAuthControllerLogoutQuery))).then(end),
    pathParts: baseAuthControllerLogoutPathParts,
  };
}

export const baseAuthControllerLogoutCodecs: { input: imported0_EmptyObjectC, output: imported1_BrowserRedirectC } = {
  input: imported0_emptyObjectC,
  output: imported1_browserRedirectC
};

export const baseAuthControllerLogout = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported1_BrowserRedirectC> =>
  urlInterfaceIO("GET", baseAuthControllerLogoutRoute().match.formatter.run(Route.empty, {}).toString(true), baseAuthControllerLogoutCodecs);

const baseAuthControllerInitXdAuthPath = lit("v2").then(lit("xd-auth")).then(lit("init")).then(str("token")).then(str("key"));
const baseAuthControllerInitXdAuthPathParts = ["v2", "xd-auth", "init", "token", "key"] as const;
export type BaseAuthControllerInitXdAuthPathParts = typeof baseAuthControllerInitXdAuthPathParts;

const baseAuthControllerInitXdAuthQuery = t.exact(t.type({
  "sourceDomain": t.string,
  "redirect": t.string
}));
export type BaseAuthControllerInitXdAuthParams = Describe<typeof baseAuthControllerInitXdAuthPath._A & typeof baseAuthControllerInitXdAuthQuery._A>;

export type BaseAuthControllerInitXdAuthRoute<A = {}> = { match: Match<BaseAuthControllerInitXdAuthParams & A>; pathParts: BaseAuthControllerInitXdAuthPathParts };

export function baseAuthControllerInitXdAuthRoute(): BaseAuthControllerInitXdAuthRoute;
export function baseAuthControllerInitXdAuthRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerInitXdAuthParams>): BaseAuthControllerInitXdAuthRoute<t.TypeOf<A>>;
export function baseAuthControllerInitXdAuthRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerInitXdAuthParams>) {
  return {
    match: (q ? baseAuthControllerInitXdAuthPath.then(query(t.intersection([baseAuthControllerInitXdAuthQuery, q]))) : baseAuthControllerInitXdAuthPath.then(query(baseAuthControllerInitXdAuthQuery))).then(end),
    pathParts: baseAuthControllerInitXdAuthPathParts,
  };
}

export const baseAuthControllerInitXdAuthCodecs: {
  input: imported0_EmptyObjectC,
  output: imported2_BrowserRedirectC
} = { input: imported0_emptyObjectC, output: imported2_browserRedirectC };

export const baseAuthControllerInitXdAuth = (p: BaseAuthControllerInitXdAuthParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported2_BrowserRedirectC> =>
  urlInterfaceIO("GET", baseAuthControllerInitXdAuthRoute().match.formatter.run(Route.empty, p).toString(true), baseAuthControllerInitXdAuthCodecs);

const baseAuthControllerFinishXdAuthPath = lit("v2").then(lit("xd-auth")).then(lit("finish")).then(str("token")).then(str("key"));
const baseAuthControllerFinishXdAuthPathParts = ["v2", "xd-auth", "finish", "token", "key"] as const;
export type BaseAuthControllerFinishXdAuthPathParts = typeof baseAuthControllerFinishXdAuthPathParts;

const baseAuthControllerFinishXdAuthQuery = t.exact(t.type({
  "redirect": t.string
}));
export type BaseAuthControllerFinishXdAuthParams = Describe<typeof baseAuthControllerFinishXdAuthPath._A & typeof baseAuthControllerFinishXdAuthQuery._A>;

export type BaseAuthControllerFinishXdAuthRoute<A = {}> = { match: Match<BaseAuthControllerFinishXdAuthParams & A>; pathParts: BaseAuthControllerFinishXdAuthPathParts };

export function baseAuthControllerFinishXdAuthRoute(): BaseAuthControllerFinishXdAuthRoute;
export function baseAuthControllerFinishXdAuthRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerFinishXdAuthParams>): BaseAuthControllerFinishXdAuthRoute<t.TypeOf<A>>;
export function baseAuthControllerFinishXdAuthRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerFinishXdAuthParams>) {
  return {
    match: (q ? baseAuthControllerFinishXdAuthPath.then(query(t.intersection([baseAuthControllerFinishXdAuthQuery, q]))) : baseAuthControllerFinishXdAuthPath.then(query(baseAuthControllerFinishXdAuthQuery))).then(end),
    pathParts: baseAuthControllerFinishXdAuthPathParts,
  };
}

export const baseAuthControllerFinishXdAuthCodecs: {
  input: imported0_EmptyObjectC,
  output: imported3_BrowserRedirectC
} = { input: imported0_emptyObjectC, output: imported3_browserRedirectC };

export const baseAuthControllerFinishXdAuth = (p: BaseAuthControllerFinishXdAuthParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported3_BrowserRedirectC> =>
  urlInterfaceIO("GET", baseAuthControllerFinishXdAuthRoute().match.formatter.run(Route.empty, p).toString(true), baseAuthControllerFinishXdAuthCodecs);

const baseAuthControllerReviewTermsPath = lit("review-terms");
const baseAuthControllerReviewTermsPathParts = ["review-terms"] as const;
export type BaseAuthControllerReviewTermsPathParts = typeof baseAuthControllerReviewTermsPathParts;

const baseAuthControllerReviewTermsQuery = t.exact(t.type({
  "redirect": t.string
}));
export type BaseAuthControllerReviewTermsParams = Describe<typeof baseAuthControllerReviewTermsPath._A & typeof baseAuthControllerReviewTermsQuery._A>;

export type BaseAuthControllerReviewTermsRoute<A = {}> = { match: Match<BaseAuthControllerReviewTermsParams & A>; pathParts: BaseAuthControllerReviewTermsPathParts };

export function baseAuthControllerReviewTermsRoute(): BaseAuthControllerReviewTermsRoute;
export function baseAuthControllerReviewTermsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerReviewTermsParams>): BaseAuthControllerReviewTermsRoute<t.TypeOf<A>>;
export function baseAuthControllerReviewTermsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerReviewTermsParams>) {
  return {
    match: (q ? baseAuthControllerReviewTermsPath.then(query(t.intersection([baseAuthControllerReviewTermsQuery, q]))) : baseAuthControllerReviewTermsPath.then(query(baseAuthControllerReviewTermsQuery))).then(end),
    pathParts: baseAuthControllerReviewTermsPathParts,
  };
}

export const baseAuthControllerReviewTermsCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported2_ApiRedirectC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported2_apiRedirectC };

export const baseAuthControllerReviewTerms = (p: BaseAuthControllerReviewTermsParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  urlInterfaceIO("GET", baseAuthControllerReviewTermsRoute().match.formatter.run(Route.empty, p).toString(true), baseAuthControllerReviewTermsCodecs);

const baseAuthControllerReviewBLPTermsPath = lit("review-terms").then(lit("bondlink-primary"));
const baseAuthControllerReviewBLPTermsPathParts = ["review-terms", "bondlink-primary"] as const;
export type BaseAuthControllerReviewBLPTermsPathParts = typeof baseAuthControllerReviewBLPTermsPathParts;

const baseAuthControllerReviewBLPTermsQuery = t.exact(t.type({
  "redirect": t.string
}));
export type BaseAuthControllerReviewBLPTermsParams = Describe<typeof baseAuthControllerReviewBLPTermsPath._A & typeof baseAuthControllerReviewBLPTermsQuery._A>;

export type BaseAuthControllerReviewBLPTermsRoute<A = {}> = { match: Match<BaseAuthControllerReviewBLPTermsParams & A>; pathParts: BaseAuthControllerReviewBLPTermsPathParts };

export function baseAuthControllerReviewBLPTermsRoute(): BaseAuthControllerReviewBLPTermsRoute;
export function baseAuthControllerReviewBLPTermsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerReviewBLPTermsParams>): BaseAuthControllerReviewBLPTermsRoute<t.TypeOf<A>>;
export function baseAuthControllerReviewBLPTermsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerReviewBLPTermsParams>) {
  return {
    match: (q ? baseAuthControllerReviewBLPTermsPath.then(query(t.intersection([baseAuthControllerReviewBLPTermsQuery, q]))) : baseAuthControllerReviewBLPTermsPath.then(query(baseAuthControllerReviewBLPTermsQuery))).then(end),
    pathParts: baseAuthControllerReviewBLPTermsPathParts,
  };
}

export const baseAuthControllerReviewBLPTermsCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported3_ApiRedirectC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported3_apiRedirectC };

export const baseAuthControllerReviewBLPTerms = (p: BaseAuthControllerReviewBLPTermsParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  urlInterfaceIO("GET", baseAuthControllerReviewBLPTermsRoute().match.formatter.run(Route.empty, p).toString(true), baseAuthControllerReviewBLPTermsCodecs);

const baseAuthControllerCheckSessionPath = lit("check-session");
const baseAuthControllerCheckSessionPathParts = ["check-session"] as const;
export type BaseAuthControllerCheckSessionPathParts = typeof baseAuthControllerCheckSessionPathParts;

const baseAuthControllerCheckSessionQuery = t.strict({});
export type BaseAuthControllerCheckSessionParams = Describe<typeof baseAuthControllerCheckSessionPath._A & typeof baseAuthControllerCheckSessionQuery._A>;

export type BaseAuthControllerCheckSessionRoute<A = {}> = { match: Match<BaseAuthControllerCheckSessionParams & A>; pathParts: BaseAuthControllerCheckSessionPathParts };

export function baseAuthControllerCheckSessionRoute(): BaseAuthControllerCheckSessionRoute;
export function baseAuthControllerCheckSessionRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerCheckSessionParams>): BaseAuthControllerCheckSessionRoute<t.TypeOf<A>>;
export function baseAuthControllerCheckSessionRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerCheckSessionParams>) {
  return {
    match: (q ? baseAuthControllerCheckSessionPath.then(query(t.intersection([baseAuthControllerCheckSessionQuery, q]))) : baseAuthControllerCheckSessionPath.then(query(baseAuthControllerCheckSessionQuery))).then(end),
    pathParts: baseAuthControllerCheckSessionPathParts,
  };
}

export const baseAuthControllerCheckSessionCodecs: { input: imported0_EmptyObjectC, output: t.StringC } = {
  input: imported0_emptyObjectC,
  output: t.string
};

export const baseAuthControllerCheckSession = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.StringC> =>
  urlInterfaceIO("GET", baseAuthControllerCheckSessionRoute().match.formatter.run(Route.empty, {}).toString(true), baseAuthControllerCheckSessionCodecs);

const baseAuthControllerRefreshSessionPath = lit("refresh-session");
const baseAuthControllerRefreshSessionPathParts = ["refresh-session"] as const;
export type BaseAuthControllerRefreshSessionPathParts = typeof baseAuthControllerRefreshSessionPathParts;

const baseAuthControllerRefreshSessionQuery = t.strict({});
export type BaseAuthControllerRefreshSessionParams = Describe<typeof baseAuthControllerRefreshSessionPath._A & typeof baseAuthControllerRefreshSessionQuery._A>;

export type BaseAuthControllerRefreshSessionRoute<A = {}> = { match: Match<BaseAuthControllerRefreshSessionParams & A>; pathParts: BaseAuthControllerRefreshSessionPathParts };

export function baseAuthControllerRefreshSessionRoute(): BaseAuthControllerRefreshSessionRoute;
export function baseAuthControllerRefreshSessionRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerRefreshSessionParams>): BaseAuthControllerRefreshSessionRoute<t.TypeOf<A>>;
export function baseAuthControllerRefreshSessionRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerRefreshSessionParams>) {
  return {
    match: (q ? baseAuthControllerRefreshSessionPath.then(query(t.intersection([baseAuthControllerRefreshSessionQuery, q]))) : baseAuthControllerRefreshSessionPath.then(query(baseAuthControllerRefreshSessionQuery))).then(end),
    pathParts: baseAuthControllerRefreshSessionPathParts,
  };
}

export const baseAuthControllerRefreshSessionCodecs: { input: imported0_RefreshSessionC, output: t.StringC } = {
  input: imported0_refreshSessionC,
  output: t.string
};

export const baseAuthControllerRefreshSession = (): UrlInterfaceIO<"POST", imported0_RefreshSessionC, t.StringC> =>
  urlInterfaceIO("POST", baseAuthControllerRefreshSessionRoute().match.formatter.run(Route.empty, {}).toString(true), baseAuthControllerRefreshSessionCodecs);

const baseAuthControllerVerifyTotpPath = lit("verify-totp");
const baseAuthControllerVerifyTotpPathParts = ["verify-totp"] as const;
export type BaseAuthControllerVerifyTotpPathParts = typeof baseAuthControllerVerifyTotpPathParts;

const baseAuthControllerVerifyTotpQuery = t.strict({});
export type BaseAuthControllerVerifyTotpParams = Describe<typeof baseAuthControllerVerifyTotpPath._A & typeof baseAuthControllerVerifyTotpQuery._A>;

export type BaseAuthControllerVerifyTotpRoute<A = {}> = { match: Match<BaseAuthControllerVerifyTotpParams & A>; pathParts: BaseAuthControllerVerifyTotpPathParts };

export function baseAuthControllerVerifyTotpRoute(): BaseAuthControllerVerifyTotpRoute;
export function baseAuthControllerVerifyTotpRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerVerifyTotpParams>): BaseAuthControllerVerifyTotpRoute<t.TypeOf<A>>;
export function baseAuthControllerVerifyTotpRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerVerifyTotpParams>) {
  return {
    match: (q ? baseAuthControllerVerifyTotpPath.then(query(t.intersection([baseAuthControllerVerifyTotpQuery, q]))) : baseAuthControllerVerifyTotpPath.then(query(baseAuthControllerVerifyTotpQuery))).then(end),
    pathParts: baseAuthControllerVerifyTotpPathParts,
  };
}

export const baseAuthControllerVerifyTotpCodecs: { input: imported0_VerifyTotpPostC, output: imported2_RedirectC } = {
  input: imported0_verifyTotpPostC,
  output: imported0_redirectC
};

export const baseAuthControllerVerifyTotp = (): UrlInterfaceIO<"POST", imported0_VerifyTotpPostC, imported2_RedirectC> =>
  urlInterfaceIO("POST", baseAuthControllerVerifyTotpRoute().match.formatter.run(Route.empty, {}).toString(true), baseAuthControllerVerifyTotpCodecs);

const baseAuthControllerResendMfaSmsPath = lit("v2").then(lit("mfa")).then(lit("resend-sms"));
const baseAuthControllerResendMfaSmsPathParts = ["v2", "mfa", "resend-sms"] as const;
export type BaseAuthControllerResendMfaSmsPathParts = typeof baseAuthControllerResendMfaSmsPathParts;

const baseAuthControllerResendMfaSmsQuery = t.strict({});
export type BaseAuthControllerResendMfaSmsParams = Describe<typeof baseAuthControllerResendMfaSmsPath._A & typeof baseAuthControllerResendMfaSmsQuery._A>;

export type BaseAuthControllerResendMfaSmsRoute<A = {}> = { match: Match<BaseAuthControllerResendMfaSmsParams & A>; pathParts: BaseAuthControllerResendMfaSmsPathParts };

export function baseAuthControllerResendMfaSmsRoute(): BaseAuthControllerResendMfaSmsRoute;
export function baseAuthControllerResendMfaSmsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerResendMfaSmsParams>): BaseAuthControllerResendMfaSmsRoute<t.TypeOf<A>>;
export function baseAuthControllerResendMfaSmsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerResendMfaSmsParams>) {
  return {
    match: (q ? baseAuthControllerResendMfaSmsPath.then(query(t.intersection([baseAuthControllerResendMfaSmsQuery, q]))) : baseAuthControllerResendMfaSmsPath.then(query(baseAuthControllerResendMfaSmsQuery))).then(end),
    pathParts: baseAuthControllerResendMfaSmsPathParts,
  };
}

export const baseAuthControllerResendMfaSmsCodecs: {
  input: imported0_ResendMfaSmsC,
  output: imported1_ResendMfaSmsC
} = { input: imported0_resendMfaSmsC, output: imported1_resendMfaSmsC };

export const baseAuthControllerResendMfaSms = (): UrlInterfaceIO<"POST", imported0_ResendMfaSmsC, imported1_ResendMfaSmsC> =>
  urlInterfaceIO("POST", baseAuthControllerResendMfaSmsRoute().match.formatter.run(Route.empty, {}).toString(true), baseAuthControllerResendMfaSmsCodecs);

const baseAuthControllerConfirmInvitePath = lit("accounts").then(lit("confirm"));
const baseAuthControllerConfirmInvitePathParts = ["accounts", "confirm"] as const;
export type BaseAuthControllerConfirmInvitePathParts = typeof baseAuthControllerConfirmInvitePathParts;

const baseAuthControllerConfirmInviteQuery = t.exact(t.type({
  "email": t.string,
  "hash": t.string
}));
export type BaseAuthControllerConfirmInviteParams = Describe<typeof baseAuthControllerConfirmInvitePath._A & typeof baseAuthControllerConfirmInviteQuery._A>;

export type BaseAuthControllerConfirmInviteRoute<A = {}> = { match: Match<BaseAuthControllerConfirmInviteParams & A>; pathParts: BaseAuthControllerConfirmInvitePathParts };

export function baseAuthControllerConfirmInviteRoute(): BaseAuthControllerConfirmInviteRoute;
export function baseAuthControllerConfirmInviteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerConfirmInviteParams>): BaseAuthControllerConfirmInviteRoute<t.TypeOf<A>>;
export function baseAuthControllerConfirmInviteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerConfirmInviteParams>) {
  return {
    match: (q ? baseAuthControllerConfirmInvitePath.then(query(t.intersection([baseAuthControllerConfirmInviteQuery, q]))) : baseAuthControllerConfirmInvitePath.then(query(baseAuthControllerConfirmInviteQuery))).then(end),
    pathParts: baseAuthControllerConfirmInvitePathParts,
  };
}

export const baseAuthControllerConfirmInviteCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_ConfirmAccountDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_confirmAccountDataC };

export const baseAuthControllerConfirmInvite = (p: BaseAuthControllerConfirmInviteParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  urlInterfaceIO("GET", baseAuthControllerConfirmInviteRoute().match.formatter.run(Route.empty, p).toString(true), baseAuthControllerConfirmInviteCodecs);

const baseAuthControllerConfirmInvitePostPath = lit("v2").then(lit("accounts")).then(lit("confirm"));
const baseAuthControllerConfirmInvitePostPathParts = ["v2", "accounts", "confirm"] as const;
export type BaseAuthControllerConfirmInvitePostPathParts = typeof baseAuthControllerConfirmInvitePostPathParts;

const baseAuthControllerConfirmInvitePostQuery = t.strict({});
export type BaseAuthControllerConfirmInvitePostParams = Describe<typeof baseAuthControllerConfirmInvitePostPath._A & typeof baseAuthControllerConfirmInvitePostQuery._A>;

export type BaseAuthControllerConfirmInvitePostRoute<A = {}> = { match: Match<BaseAuthControllerConfirmInvitePostParams & A>; pathParts: BaseAuthControllerConfirmInvitePostPathParts };

export function baseAuthControllerConfirmInvitePostRoute(): BaseAuthControllerConfirmInvitePostRoute;
export function baseAuthControllerConfirmInvitePostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAuthControllerConfirmInvitePostParams>): BaseAuthControllerConfirmInvitePostRoute<t.TypeOf<A>>;
export function baseAuthControllerConfirmInvitePostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAuthControllerConfirmInvitePostParams>) {
  return {
    match: (q ? baseAuthControllerConfirmInvitePostPath.then(query(t.intersection([baseAuthControllerConfirmInvitePostQuery, q]))) : baseAuthControllerConfirmInvitePostPath.then(query(baseAuthControllerConfirmInvitePostQuery))).then(end),
    pathParts: baseAuthControllerConfirmInvitePostPathParts,
  };
}

export const baseAuthControllerConfirmInvitePostCodecs: {
  input: imported0_ConfirmAccountPostC,
  output: imported4_ApiRedirectC
} = { input: imported0_confirmAccountPostC, output: imported4_apiRedirectC };

export const baseAuthControllerConfirmInvitePost = (): UrlInterfaceIO<"POST", imported0_ConfirmAccountPostC, imported4_ApiRedirectC> =>
  urlInterfaceIO("POST", baseAuthControllerConfirmInvitePostRoute().match.formatter.run(Route.empty, {}).toString(true), baseAuthControllerConfirmInvitePostCodecs);

const baseMyBondlinkPath = lit("my-bondlink");
const baseMyBondlinkPathParts = ["my-bondlink"] as const;
export type BaseMyBondlinkPathParts = typeof baseMyBondlinkPathParts;

const baseMyBondlinkQuery = t.exact(t.partial({
  "redirectDeepLink": t.string
}));
export type BaseMyBondlinkParamsRaw = Describe<typeof baseMyBondlinkPath._A & typeof baseMyBondlinkQuery._A>;
export type BaseMyBondlinkParams = Describe<NullableToOptionalFields<BaseMyBondlinkParamsRaw>>;

export type BaseMyBondlinkRoute<A = {}> = { match: Match<BaseMyBondlinkParamsRaw & A>; pathParts: BaseMyBondlinkPathParts };

export function baseMyBondlinkRoute(): BaseMyBondlinkRoute;
export function baseMyBondlinkRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseMyBondlinkParamsRaw>): BaseMyBondlinkRoute<t.TypeOf<A>>;
export function baseMyBondlinkRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseMyBondlinkParamsRaw>) {
  return {
    match: (q ? baseMyBondlinkPath.then(query(t.intersection([baseMyBondlinkQuery, q]))) : baseMyBondlinkPath.then(query(baseMyBondlinkQuery))).then(end),
    pathParts: baseMyBondlinkPathParts,
  };
}

export const baseMyBondlinkCodecs: { input: imported0_EmptyObjectC, output: imported4_BrowserRedirectC } = {
  input: imported0_emptyObjectC,
  output: imported4_browserRedirectC
};

export const baseMyBondlink = (p: BaseMyBondlinkParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported4_BrowserRedirectC> =>
  urlInterfaceIO("GET", baseMyBondlinkRoute().match.formatter.run(Route.empty, optionalFieldsToNullable(p)).toString(true), baseMyBondlinkCodecs);

const baseAccountSettingsPath = lit("portal").then(lit("settings"));
const baseAccountSettingsPathParts = ["portal", "settings"] as const;
export type BaseAccountSettingsPathParts = typeof baseAccountSettingsPathParts;

const baseAccountSettingsQuery = t.strict({});
export type BaseAccountSettingsParams = Describe<typeof baseAccountSettingsPath._A & typeof baseAccountSettingsQuery._A>;

export type BaseAccountSettingsRoute<A = {}> = { match: Match<BaseAccountSettingsParams & A>; pathParts: BaseAccountSettingsPathParts };

export function baseAccountSettingsRoute(): BaseAccountSettingsRoute;
export function baseAccountSettingsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseAccountSettingsParams>): BaseAccountSettingsRoute<t.TypeOf<A>>;
export function baseAccountSettingsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseAccountSettingsParams>) {
  return {
    match: (q ? baseAccountSettingsPath.then(query(t.intersection([baseAccountSettingsQuery, q]))) : baseAccountSettingsPath.then(query(baseAccountSettingsQuery))).then(end),
    pathParts: baseAccountSettingsPathParts,
  };
}

export const baseAccountSettingsCodecs: { input: imported0_EmptyObjectC, output: imported5_BrowserRedirectC } = {
  input: imported0_emptyObjectC,
  output: imported5_browserRedirectC
};

export const baseAccountSettings = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported5_BrowserRedirectC> =>
  urlInterfaceIO("GET", baseAccountSettingsRoute().match.formatter.run(Route.empty, {}).toString(true), baseAccountSettingsCodecs);

const baseNotificationSettingsPath = lit("portal").then(lit("notification-subscriptions"));
const baseNotificationSettingsPathParts = ["portal", "notification-subscriptions"] as const;
export type BaseNotificationSettingsPathParts = typeof baseNotificationSettingsPathParts;

const baseNotificationSettingsQuery = t.strict({});
export type BaseNotificationSettingsParams = Describe<typeof baseNotificationSettingsPath._A & typeof baseNotificationSettingsQuery._A>;

export type BaseNotificationSettingsRoute<A = {}> = { match: Match<BaseNotificationSettingsParams & A>; pathParts: BaseNotificationSettingsPathParts };

export function baseNotificationSettingsRoute(): BaseNotificationSettingsRoute;
export function baseNotificationSettingsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseNotificationSettingsParams>): BaseNotificationSettingsRoute<t.TypeOf<A>>;
export function baseNotificationSettingsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseNotificationSettingsParams>) {
  return {
    match: (q ? baseNotificationSettingsPath.then(query(t.intersection([baseNotificationSettingsQuery, q]))) : baseNotificationSettingsPath.then(query(baseNotificationSettingsQuery))).then(end),
    pathParts: baseNotificationSettingsPathParts,
  };
}

export const baseNotificationSettingsCodecs: { input: imported0_EmptyObjectC, output: imported6_BrowserRedirectC } = {
  input: imported0_emptyObjectC,
  output: imported6_browserRedirectC
};

export const baseNotificationSettings = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported6_BrowserRedirectC> =>
  urlInterfaceIO("GET", baseNotificationSettingsRoute().match.formatter.run(Route.empty, {}).toString(true), baseNotificationSettingsCodecs);

const baseNotificationSettingsExtraPath = lit("portal").then(lit("notification-subscriptions")).then(str("path"));
const baseNotificationSettingsExtraPathParts = ["portal", "notification-subscriptions", "path"] as const;
export type BaseNotificationSettingsExtraPathParts = typeof baseNotificationSettingsExtraPathParts;

const baseNotificationSettingsExtraQuery = t.strict({});
export type BaseNotificationSettingsExtraParams = Describe<typeof baseNotificationSettingsExtraPath._A & typeof baseNotificationSettingsExtraQuery._A>;

export type BaseNotificationSettingsExtraRoute<A = {}> = { match: Match<BaseNotificationSettingsExtraParams & A>; pathParts: BaseNotificationSettingsExtraPathParts };

export function baseNotificationSettingsExtraRoute(): BaseNotificationSettingsExtraRoute;
export function baseNotificationSettingsExtraRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseNotificationSettingsExtraParams>): BaseNotificationSettingsExtraRoute<t.TypeOf<A>>;
export function baseNotificationSettingsExtraRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseNotificationSettingsExtraParams>) {
  return {
    match: (q ? baseNotificationSettingsExtraPath.then(query(t.intersection([baseNotificationSettingsExtraQuery, q]))) : baseNotificationSettingsExtraPath.then(query(baseNotificationSettingsExtraQuery))).then(end),
    pathParts: baseNotificationSettingsExtraPathParts,
  };
}

export const baseNotificationSettingsExtraCodecs: {
  input: imported0_EmptyObjectC,
  output: imported7_BrowserRedirectC
} = { input: imported0_emptyObjectC, output: imported7_browserRedirectC };

export const baseNotificationSettingsExtra = (p: BaseNotificationSettingsExtraParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported7_BrowserRedirectC> =>
  urlInterfaceIO("GET", baseNotificationSettingsExtraRoute().match.formatter.run(Route.empty, p).toString(true), baseNotificationSettingsExtraCodecs);

const baseIssuerNotificationsPath = lit("portal").then(lit("issuer-notifications"));
const baseIssuerNotificationsPathParts = ["portal", "issuer-notifications"] as const;
export type BaseIssuerNotificationsPathParts = typeof baseIssuerNotificationsPathParts;

const baseIssuerNotificationsQuery = t.strict({});
export type BaseIssuerNotificationsParams = Describe<typeof baseIssuerNotificationsPath._A & typeof baseIssuerNotificationsQuery._A>;

export type BaseIssuerNotificationsRoute<A = {}> = { match: Match<BaseIssuerNotificationsParams & A>; pathParts: BaseIssuerNotificationsPathParts };

export function baseIssuerNotificationsRoute(): BaseIssuerNotificationsRoute;
export function baseIssuerNotificationsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseIssuerNotificationsParams>): BaseIssuerNotificationsRoute<t.TypeOf<A>>;
export function baseIssuerNotificationsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseIssuerNotificationsParams>) {
  return {
    match: (q ? baseIssuerNotificationsPath.then(query(t.intersection([baseIssuerNotificationsQuery, q]))) : baseIssuerNotificationsPath.then(query(baseIssuerNotificationsQuery))).then(end),
    pathParts: baseIssuerNotificationsPathParts,
  };
}

export const baseIssuerNotificationsCodecs: { input: imported0_EmptyObjectC, output: imported8_BrowserRedirectC } = {
  input: imported0_emptyObjectC,
  output: imported8_browserRedirectC
};

export const baseIssuerNotifications = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported8_BrowserRedirectC> =>
  urlInterfaceIO("GET", baseIssuerNotificationsRoute().match.formatter.run(Route.empty, {}).toString(true), baseIssuerNotificationsCodecs);

const baseCommonUserCompaniesPath = lit("v2").then(lit("common-user-companies"));
const baseCommonUserCompaniesPathParts = ["v2", "common-user-companies"] as const;
export type BaseCommonUserCompaniesPathParts = typeof baseCommonUserCompaniesPathParts;

const baseCommonUserCompaniesQuery = t.strict({});
export type BaseCommonUserCompaniesParams = Describe<typeof baseCommonUserCompaniesPath._A & typeof baseCommonUserCompaniesQuery._A>;

export type BaseCommonUserCompaniesRoute<A = {}> = { match: Match<BaseCommonUserCompaniesParams & A>; pathParts: BaseCommonUserCompaniesPathParts };

export function baseCommonUserCompaniesRoute(): BaseCommonUserCompaniesRoute;
export function baseCommonUserCompaniesRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseCommonUserCompaniesParams>): BaseCommonUserCompaniesRoute<t.TypeOf<A>>;
export function baseCommonUserCompaniesRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseCommonUserCompaniesParams>) {
  return {
    match: (q ? baseCommonUserCompaniesPath.then(query(t.intersection([baseCommonUserCompaniesQuery, q]))) : baseCommonUserCompaniesPath.then(query(baseCommonUserCompaniesQuery))).then(end),
    pathParts: baseCommonUserCompaniesPathParts,
  };
}

export const baseCommonUserCompaniesCodecs: { input: imported0_EmptyObjectC, output: t.ReadonlyArrayC<t.StringC> } = {
  input: imported0_emptyObjectC,
  output: t.readonlyArray(t.string)
};

export const baseCommonUserCompanies = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<t.StringC>> =>
  urlInterfaceIO("GET", baseCommonUserCompaniesRoute().match.formatter.run(Route.empty, {}).toString(true), baseCommonUserCompaniesCodecs);

const baseEmailBodyPath = lit("emails").then(lit("body")).then(str("hash"));
const baseEmailBodyPathParts = ["emails", "body", "hash"] as const;
export type BaseEmailBodyPathParts = typeof baseEmailBodyPathParts;

const baseEmailBodyQuery = t.exact(t.type({
  "email": t.string
}));
export type BaseEmailBodyParams = Describe<typeof baseEmailBodyPath._A & typeof baseEmailBodyQuery._A>;

export type BaseEmailBodyRoute<A = {}> = { match: Match<BaseEmailBodyParams & A>; pathParts: BaseEmailBodyPathParts };

export function baseEmailBodyRoute(): BaseEmailBodyRoute;
export function baseEmailBodyRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseEmailBodyParams>): BaseEmailBodyRoute<t.TypeOf<A>>;
export function baseEmailBodyRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseEmailBodyParams>) {
  return {
    match: (q ? baseEmailBodyPath.then(query(t.intersection([baseEmailBodyQuery, q]))) : baseEmailBodyPath.then(query(baseEmailBodyQuery))).then(end),
    pathParts: baseEmailBodyPathParts,
  };
}

export const baseEmailBodyCodecs: {
  input: imported0_EmptyObjectC,
  output: EitherC<imported9_BrowserRedirectC, HtmlC>
} = { input: imported0_emptyObjectC, output: either(imported9_browserRedirectC, htmlC) };

export const baseEmailBody = (p: BaseEmailBodyParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, EitherC<imported9_BrowserRedirectC, HtmlC>> =>
  urlInterfaceIO("GET", baseEmailBodyRoute().match.formatter.run(Route.empty, p).toString(true), baseEmailBodyCodecs);

const baseReportBodyPath = lit("reports").then(lit("body")).then(int("reportId"));
const baseReportBodyPathParts = ["reports", "body", "reportId"] as const;
export type BaseReportBodyPathParts = typeof baseReportBodyPathParts;

const baseReportBodyQuery = t.strict({});
export type BaseReportBodyParams = Describe<typeof baseReportBodyPath._A & typeof baseReportBodyQuery._A>;

export type BaseReportBodyRoute<A = {}> = { match: Match<BaseReportBodyParams & A>; pathParts: BaseReportBodyPathParts };

export function baseReportBodyRoute(): BaseReportBodyRoute;
export function baseReportBodyRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseReportBodyParams>): BaseReportBodyRoute<t.TypeOf<A>>;
export function baseReportBodyRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseReportBodyParams>) {
  return {
    match: (q ? baseReportBodyPath.then(query(t.intersection([baseReportBodyQuery, q]))) : baseReportBodyPath.then(query(baseReportBodyQuery))).then(end),
    pathParts: baseReportBodyPathParts,
  };
}

export const baseReportBodyCodecs: {
  input: imported0_EmptyObjectC,
  output: EitherC<imported10_BrowserRedirectC, HtmlC>
} = { input: imported0_emptyObjectC, output: either(imported10_browserRedirectC, htmlC) };

export const baseReportBody = (p: BaseReportBodyParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, EitherC<imported10_BrowserRedirectC, HtmlC>> =>
  urlInterfaceIO("GET", baseReportBodyRoute().match.formatter.run(Route.empty, p).toString(true), baseReportBodyCodecs);

const baseEmailUnsubscribeByHashPath = lit("emails").then(lit("unsubscribe")).then(str("hash"));
const baseEmailUnsubscribeByHashPathParts = ["emails", "unsubscribe", "hash"] as const;
export type BaseEmailUnsubscribeByHashPathParts = typeof baseEmailUnsubscribeByHashPathParts;

const baseEmailUnsubscribeByHashQuery = t.strict({});
export type BaseEmailUnsubscribeByHashParams = Describe<typeof baseEmailUnsubscribeByHashPath._A & typeof baseEmailUnsubscribeByHashQuery._A>;

export type BaseEmailUnsubscribeByHashRoute<A = {}> = { match: Match<BaseEmailUnsubscribeByHashParams & A>; pathParts: BaseEmailUnsubscribeByHashPathParts };

export function baseEmailUnsubscribeByHashRoute(): BaseEmailUnsubscribeByHashRoute;
export function baseEmailUnsubscribeByHashRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseEmailUnsubscribeByHashParams>): BaseEmailUnsubscribeByHashRoute<t.TypeOf<A>>;
export function baseEmailUnsubscribeByHashRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseEmailUnsubscribeByHashParams>) {
  return {
    match: (q ? baseEmailUnsubscribeByHashPath.then(query(t.intersection([baseEmailUnsubscribeByHashQuery, q]))) : baseEmailUnsubscribeByHashPath.then(query(baseEmailUnsubscribeByHashQuery))).then(end),
    pathParts: baseEmailUnsubscribeByHashPathParts,
  };
}

export const baseEmailUnsubscribeByHashCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_EmailUnsubscribeDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_emailUnsubscribeDataC };

export const baseEmailUnsubscribeByHash = (p: BaseEmailUnsubscribeByHashParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  urlInterfaceIO("GET", baseEmailUnsubscribeByHashRoute().match.formatter.run(Route.empty, p).toString(true), baseEmailUnsubscribeByHashCodecs);

const baseEmailUnsubscribePostPath = lit("emails").then(lit("unsubscribe"));
const baseEmailUnsubscribePostPathParts = ["emails", "unsubscribe"] as const;
export type BaseEmailUnsubscribePostPathParts = typeof baseEmailUnsubscribePostPathParts;

const baseEmailUnsubscribePostQuery = t.strict({});
export type BaseEmailUnsubscribePostParams = Describe<typeof baseEmailUnsubscribePostPath._A & typeof baseEmailUnsubscribePostQuery._A>;

export type BaseEmailUnsubscribePostRoute<A = {}> = { match: Match<BaseEmailUnsubscribePostParams & A>; pathParts: BaseEmailUnsubscribePostPathParts };

export function baseEmailUnsubscribePostRoute(): BaseEmailUnsubscribePostRoute;
export function baseEmailUnsubscribePostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseEmailUnsubscribePostParams>): BaseEmailUnsubscribePostRoute<t.TypeOf<A>>;
export function baseEmailUnsubscribePostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseEmailUnsubscribePostParams>) {
  return {
    match: (q ? baseEmailUnsubscribePostPath.then(query(t.intersection([baseEmailUnsubscribePostQuery, q]))) : baseEmailUnsubscribePostPath.then(query(baseEmailUnsubscribePostQuery))).then(end),
    pathParts: baseEmailUnsubscribePostPathParts,
  };
}

export const baseEmailUnsubscribePostCodecs: {
  input: imported0_EmailUnsubscribePostC,
  output: imported0_EmptyObjectC
} = { input: imported0_emailUnsubscribePostC, output: imported0_emptyObjectC };

export const baseEmailUnsubscribePost = (): UrlInterfaceIO<"POST", imported0_EmailUnsubscribePostC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", baseEmailUnsubscribePostRoute().match.formatter.run(Route.empty, {}).toString(true), baseEmailUnsubscribePostCodecs);

const baseSearchPath = lit("v2").then(lit("search"));
const baseSearchPathParts = ["v2", "search"] as const;
export type BaseSearchPathParts = typeof baseSearchPathParts;

const baseSearchQuery = t.strict({});
export type BaseSearchParams = Describe<typeof baseSearchPath._A & typeof baseSearchQuery._A>;

export type BaseSearchRoute<A = {}> = { match: Match<BaseSearchParams & A>; pathParts: BaseSearchPathParts };

export function baseSearchRoute(): BaseSearchRoute;
export function baseSearchRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseSearchParams>): BaseSearchRoute<t.TypeOf<A>>;
export function baseSearchRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseSearchParams>) {
  return {
    match: (q ? baseSearchPath.then(query(t.intersection([baseSearchQuery, q]))) : baseSearchPath.then(query(baseSearchQuery))).then(end),
    pathParts: baseSearchPathParts,
  };
}

export const baseSearchCodecs: { input: imported0_IssuerSearchPostC, output: imported0_IssuerSearchResultsC } = {
  input: imported0_issuerSearchPostC,
  output: imported0_issuerSearchResultsC
};

export const baseSearch = (): UrlInterfaceIO<"POST", imported0_IssuerSearchPostC, imported0_IssuerSearchResultsC> =>
  urlInterfaceIO("POST", baseSearchRoute().match.formatter.run(Route.empty, {}).toString(true), baseSearchCodecs);

const baseS3SuccessPath = lit("v2").then(lit("s3")).then(lit("success"));
const baseS3SuccessPathParts = ["v2", "s3", "success"] as const;
export type BaseS3SuccessPathParts = typeof baseS3SuccessPathParts;

const baseS3SuccessQuery = t.strict({});
export type BaseS3SuccessParams = Describe<typeof baseS3SuccessPath._A & typeof baseS3SuccessQuery._A>;

export type BaseS3SuccessRoute<A = {}> = { match: Match<BaseS3SuccessParams & A>; pathParts: BaseS3SuccessPathParts };

export function baseS3SuccessRoute(): BaseS3SuccessRoute;
export function baseS3SuccessRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseS3SuccessParams>): BaseS3SuccessRoute<t.TypeOf<A>>;
export function baseS3SuccessRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseS3SuccessParams>) {
  return {
    match: (q ? baseS3SuccessPath.then(query(t.intersection([baseS3SuccessQuery, q]))) : baseS3SuccessPath.then(query(baseS3SuccessQuery))).then(end),
    pathParts: baseS3SuccessPathParts,
  };
}

export const baseS3SuccessCodecs: { input: imported0_S3SuccessPostC, output: imported0_MediaPreviewC } = {
  input: imported0_s3SuccessPostC,
  output: imported0_mediaPreviewC
};

export const baseS3Success = (): UrlInterfaceIO<"POST", imported0_S3SuccessPostC, imported0_MediaPreviewC> =>
  urlInterfaceIO("POST", baseS3SuccessRoute().match.formatter.run(Route.empty, {}).toString(true), baseS3SuccessCodecs);

const baseS3AbortMultipartUploadPath = lit("v2").then(lit("s3")).then(lit("multipart")).then(lit("abort"));
const baseS3AbortMultipartUploadPathParts = ["v2", "s3", "multipart", "abort"] as const;
export type BaseS3AbortMultipartUploadPathParts = typeof baseS3AbortMultipartUploadPathParts;

const baseS3AbortMultipartUploadQuery = t.strict({});
export type BaseS3AbortMultipartUploadParams = Describe<typeof baseS3AbortMultipartUploadPath._A & typeof baseS3AbortMultipartUploadQuery._A>;

export type BaseS3AbortMultipartUploadRoute<A = {}> = { match: Match<BaseS3AbortMultipartUploadParams & A>; pathParts: BaseS3AbortMultipartUploadPathParts };

export function baseS3AbortMultipartUploadRoute(): BaseS3AbortMultipartUploadRoute;
export function baseS3AbortMultipartUploadRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseS3AbortMultipartUploadParams>): BaseS3AbortMultipartUploadRoute<t.TypeOf<A>>;
export function baseS3AbortMultipartUploadRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseS3AbortMultipartUploadParams>) {
  return {
    match: (q ? baseS3AbortMultipartUploadPath.then(query(t.intersection([baseS3AbortMultipartUploadQuery, q]))) : baseS3AbortMultipartUploadPath.then(query(baseS3AbortMultipartUploadQuery))).then(end),
    pathParts: baseS3AbortMultipartUploadPathParts,
  };
}

export const baseS3AbortMultipartUploadCodecs: {
  input: imported6_S3MultipartUploadInfoC,
  output: imported0_EmptyObjectC
} = { input: imported6_s3MultipartUploadInfoC, output: imported0_emptyObjectC };

export const baseS3AbortMultipartUpload = (): UrlInterfaceIO<"POST", imported6_S3MultipartUploadInfoC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", baseS3AbortMultipartUploadRoute().match.formatter.run(Route.empty, {}).toString(true), baseS3AbortMultipartUploadCodecs);

const baseS3CreateMultipartUploadPath = lit("v2").then(lit("s3")).then(lit("multipart")).then(lit("create"));
const baseS3CreateMultipartUploadPathParts = ["v2", "s3", "multipart", "create"] as const;
export type BaseS3CreateMultipartUploadPathParts = typeof baseS3CreateMultipartUploadPathParts;

const baseS3CreateMultipartUploadQuery = t.strict({});
export type BaseS3CreateMultipartUploadParams = Describe<typeof baseS3CreateMultipartUploadPath._A & typeof baseS3CreateMultipartUploadQuery._A>;

export type BaseS3CreateMultipartUploadRoute<A = {}> = { match: Match<BaseS3CreateMultipartUploadParams & A>; pathParts: BaseS3CreateMultipartUploadPathParts };

export function baseS3CreateMultipartUploadRoute(): BaseS3CreateMultipartUploadRoute;
export function baseS3CreateMultipartUploadRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseS3CreateMultipartUploadParams>): BaseS3CreateMultipartUploadRoute<t.TypeOf<A>>;
export function baseS3CreateMultipartUploadRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseS3CreateMultipartUploadParams>) {
  return {
    match: (q ? baseS3CreateMultipartUploadPath.then(query(t.intersection([baseS3CreateMultipartUploadQuery, q]))) : baseS3CreateMultipartUploadPath.then(query(baseS3CreateMultipartUploadQuery))).then(end),
    pathParts: baseS3CreateMultipartUploadPathParts,
  };
}

export const baseS3CreateMultipartUploadCodecs: {
  input: imported0_S3CreateMultipartUploadPostC,
  output: imported7_S3MultipartUploadInfoC
} = { input: imported0_s3CreateMultipartUploadPostC, output: imported7_s3MultipartUploadInfoC };

export const baseS3CreateMultipartUpload = (): UrlInterfaceIO<"POST", imported0_S3CreateMultipartUploadPostC, imported7_S3MultipartUploadInfoC> =>
  urlInterfaceIO("POST", baseS3CreateMultipartUploadRoute().match.formatter.run(Route.empty, {}).toString(true), baseS3CreateMultipartUploadCodecs);

const baseS3CompleteMultipartUploadPath = lit("v2").then(lit("s3")).then(lit("multipart")).then(lit("complete"));
const baseS3CompleteMultipartUploadPathParts = ["v2", "s3", "multipart", "complete"] as const;
export type BaseS3CompleteMultipartUploadPathParts = typeof baseS3CompleteMultipartUploadPathParts;

const baseS3CompleteMultipartUploadQuery = t.strict({});
export type BaseS3CompleteMultipartUploadParams = Describe<typeof baseS3CompleteMultipartUploadPath._A & typeof baseS3CompleteMultipartUploadQuery._A>;

export type BaseS3CompleteMultipartUploadRoute<A = {}> = { match: Match<BaseS3CompleteMultipartUploadParams & A>; pathParts: BaseS3CompleteMultipartUploadPathParts };

export function baseS3CompleteMultipartUploadRoute(): BaseS3CompleteMultipartUploadRoute;
export function baseS3CompleteMultipartUploadRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseS3CompleteMultipartUploadParams>): BaseS3CompleteMultipartUploadRoute<t.TypeOf<A>>;
export function baseS3CompleteMultipartUploadRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseS3CompleteMultipartUploadParams>) {
  return {
    match: (q ? baseS3CompleteMultipartUploadPath.then(query(t.intersection([baseS3CompleteMultipartUploadQuery, q]))) : baseS3CompleteMultipartUploadPath.then(query(baseS3CompleteMultipartUploadQuery))).then(end),
    pathParts: baseS3CompleteMultipartUploadPathParts,
  };
}

export const baseS3CompleteMultipartUploadCodecs: {
  input: imported0_S3CompleteMultipartUploadPostC,
  output: imported0_S3CompleteMultipartUploadResponseC
} = { input: imported0_s3CompleteMultipartUploadPostC, output: imported0_s3CompleteMultipartUploadResponseC };

export const baseS3CompleteMultipartUpload = (): UrlInterfaceIO<"POST", imported0_S3CompleteMultipartUploadPostC, imported0_S3CompleteMultipartUploadResponseC> =>
  urlInterfaceIO("POST", baseS3CompleteMultipartUploadRoute().match.formatter.run(Route.empty, {}).toString(true), baseS3CompleteMultipartUploadCodecs);

const baseS3ListMultipartUploadPartsPath = lit("v2").then(lit("s3")).then(lit("multipart")).then(lit("list-parts"));
const baseS3ListMultipartUploadPartsPathParts = ["v2", "s3", "multipart", "list-parts"] as const;
export type BaseS3ListMultipartUploadPartsPathParts = typeof baseS3ListMultipartUploadPartsPathParts;

const baseS3ListMultipartUploadPartsQuery = t.strict({});
export type BaseS3ListMultipartUploadPartsParams = Describe<typeof baseS3ListMultipartUploadPartsPath._A & typeof baseS3ListMultipartUploadPartsQuery._A>;

export type BaseS3ListMultipartUploadPartsRoute<A = {}> = { match: Match<BaseS3ListMultipartUploadPartsParams & A>; pathParts: BaseS3ListMultipartUploadPartsPathParts };

export function baseS3ListMultipartUploadPartsRoute(): BaseS3ListMultipartUploadPartsRoute;
export function baseS3ListMultipartUploadPartsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseS3ListMultipartUploadPartsParams>): BaseS3ListMultipartUploadPartsRoute<t.TypeOf<A>>;
export function baseS3ListMultipartUploadPartsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseS3ListMultipartUploadPartsParams>) {
  return {
    match: (q ? baseS3ListMultipartUploadPartsPath.then(query(t.intersection([baseS3ListMultipartUploadPartsQuery, q]))) : baseS3ListMultipartUploadPartsPath.then(query(baseS3ListMultipartUploadPartsQuery))).then(end),
    pathParts: baseS3ListMultipartUploadPartsPathParts,
  };
}

export const baseS3ListMultipartUploadPartsCodecs: {
  input: imported0_S3ListMultipartUploadPartsPostC,
  output: imported0_S3ListMultipartUploadPartsResponseC
} = { input: imported0_s3ListMultipartUploadPartsPostC, output: imported0_s3ListMultipartUploadPartsResponseC };

export const baseS3ListMultipartUploadParts = (): UrlInterfaceIO<"POST", imported0_S3ListMultipartUploadPartsPostC, imported0_S3ListMultipartUploadPartsResponseC> =>
  urlInterfaceIO("POST", baseS3ListMultipartUploadPartsRoute().match.formatter.run(Route.empty, {}).toString(true), baseS3ListMultipartUploadPartsCodecs);

const baseS3PresignMultipartUploadPartsPath = lit("v2").then(lit("s3")).then(lit("multipart")).then(lit("presign-parts"));
const baseS3PresignMultipartUploadPartsPathParts = ["v2", "s3", "multipart", "presign-parts"] as const;
export type BaseS3PresignMultipartUploadPartsPathParts = typeof baseS3PresignMultipartUploadPartsPathParts;

const baseS3PresignMultipartUploadPartsQuery = t.strict({});
export type BaseS3PresignMultipartUploadPartsParams = Describe<typeof baseS3PresignMultipartUploadPartsPath._A & typeof baseS3PresignMultipartUploadPartsQuery._A>;

export type BaseS3PresignMultipartUploadPartsRoute<A = {}> = { match: Match<BaseS3PresignMultipartUploadPartsParams & A>; pathParts: BaseS3PresignMultipartUploadPartsPathParts };

export function baseS3PresignMultipartUploadPartsRoute(): BaseS3PresignMultipartUploadPartsRoute;
export function baseS3PresignMultipartUploadPartsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseS3PresignMultipartUploadPartsParams>): BaseS3PresignMultipartUploadPartsRoute<t.TypeOf<A>>;
export function baseS3PresignMultipartUploadPartsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseS3PresignMultipartUploadPartsParams>) {
  return {
    match: (q ? baseS3PresignMultipartUploadPartsPath.then(query(t.intersection([baseS3PresignMultipartUploadPartsQuery, q]))) : baseS3PresignMultipartUploadPartsPath.then(query(baseS3PresignMultipartUploadPartsQuery))).then(end),
    pathParts: baseS3PresignMultipartUploadPartsPathParts,
  };
}

export const baseS3PresignMultipartUploadPartsCodecs: {
  input: imported0_S3PresignMultipartUploadPartsPostC,
  output: imported0_S3PresignedMultipartUploadPartsC
} = { input: imported0_s3PresignMultipartUploadPartsPostC, output: imported0_s3PresignedMultipartUploadPartsC };

export const baseS3PresignMultipartUploadParts = (): UrlInterfaceIO<"POST", imported0_S3PresignMultipartUploadPartsPostC, imported0_S3PresignedMultipartUploadPartsC> =>
  urlInterfaceIO("POST", baseS3PresignMultipartUploadPartsRoute().match.formatter.run(Route.empty, {}).toString(true), baseS3PresignMultipartUploadPartsCodecs);

const baseCloudWatchLogsControllerSnsMessagePath = lit("v2").then(lit("cw-logs")).then(lit("sns"));
const baseCloudWatchLogsControllerSnsMessagePathParts = ["v2", "cw-logs", "sns"] as const;
export type BaseCloudWatchLogsControllerSnsMessagePathParts = typeof baseCloudWatchLogsControllerSnsMessagePathParts;

const baseCloudWatchLogsControllerSnsMessageQuery = t.strict({});
export type BaseCloudWatchLogsControllerSnsMessageParams = Describe<typeof baseCloudWatchLogsControllerSnsMessagePath._A & typeof baseCloudWatchLogsControllerSnsMessageQuery._A>;

export type BaseCloudWatchLogsControllerSnsMessageRoute<A = {}> = { match: Match<BaseCloudWatchLogsControllerSnsMessageParams & A>; pathParts: BaseCloudWatchLogsControllerSnsMessagePathParts };

export function baseCloudWatchLogsControllerSnsMessageRoute(): BaseCloudWatchLogsControllerSnsMessageRoute;
export function baseCloudWatchLogsControllerSnsMessageRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseCloudWatchLogsControllerSnsMessageParams>): BaseCloudWatchLogsControllerSnsMessageRoute<t.TypeOf<A>>;
export function baseCloudWatchLogsControllerSnsMessageRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseCloudWatchLogsControllerSnsMessageParams>) {
  return {
    match: (q ? baseCloudWatchLogsControllerSnsMessagePath.then(query(t.intersection([baseCloudWatchLogsControllerSnsMessageQuery, q]))) : baseCloudWatchLogsControllerSnsMessagePath.then(query(baseCloudWatchLogsControllerSnsMessageQuery))).then(end),
    pathParts: baseCloudWatchLogsControllerSnsMessagePathParts,
  };
}

export const baseCloudWatchLogsControllerSnsMessageCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const baseCloudWatchLogsControllerSnsMessage = (): UrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", baseCloudWatchLogsControllerSnsMessageRoute().match.formatter.run(Route.empty, {}).toString(true), baseCloudWatchLogsControllerSnsMessageCodecs);

const baseValidationControllerValidateUrlPath = lit("v2").then(lit("validate-url"));
const baseValidationControllerValidateUrlPathParts = ["v2", "validate-url"] as const;
export type BaseValidationControllerValidateUrlPathParts = typeof baseValidationControllerValidateUrlPathParts;

const baseValidationControllerValidateUrlQuery = t.strict({});
export type BaseValidationControllerValidateUrlParams = Describe<typeof baseValidationControllerValidateUrlPath._A & typeof baseValidationControllerValidateUrlQuery._A>;

export type BaseValidationControllerValidateUrlRoute<A = {}> = { match: Match<BaseValidationControllerValidateUrlParams & A>; pathParts: BaseValidationControllerValidateUrlPathParts };

export function baseValidationControllerValidateUrlRoute(): BaseValidationControllerValidateUrlRoute;
export function baseValidationControllerValidateUrlRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseValidationControllerValidateUrlParams>): BaseValidationControllerValidateUrlRoute<t.TypeOf<A>>;
export function baseValidationControllerValidateUrlRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseValidationControllerValidateUrlParams>) {
  return {
    match: (q ? baseValidationControllerValidateUrlPath.then(query(t.intersection([baseValidationControllerValidateUrlQuery, q]))) : baseValidationControllerValidateUrlPath.then(query(baseValidationControllerValidateUrlQuery))).then(end),
    pathParts: baseValidationControllerValidateUrlPathParts,
  };
}

export const baseValidationControllerValidateUrlCodecs: {
  input: imported0_ValidateUrlC,
  output: imported0_EmptyObjectC
} = { input: imported0_validateUrlC, output: imported0_emptyObjectC };

export const baseValidationControllerValidateUrl = (): UrlInterfaceIO<"POST", imported0_ValidateUrlC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", baseValidationControllerValidateUrlRoute().match.formatter.run(Route.empty, {}).toString(true), baseValidationControllerValidateUrlCodecs);

const baseValidationControllerValidateEmailPath = lit("v2").then(lit("validate-email"));
const baseValidationControllerValidateEmailPathParts = ["v2", "validate-email"] as const;
export type BaseValidationControllerValidateEmailPathParts = typeof baseValidationControllerValidateEmailPathParts;

const baseValidationControllerValidateEmailQuery = t.strict({});
export type BaseValidationControllerValidateEmailParams = Describe<typeof baseValidationControllerValidateEmailPath._A & typeof baseValidationControllerValidateEmailQuery._A>;

export type BaseValidationControllerValidateEmailRoute<A = {}> = { match: Match<BaseValidationControllerValidateEmailParams & A>; pathParts: BaseValidationControllerValidateEmailPathParts };

export function baseValidationControllerValidateEmailRoute(): BaseValidationControllerValidateEmailRoute;
export function baseValidationControllerValidateEmailRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseValidationControllerValidateEmailParams>): BaseValidationControllerValidateEmailRoute<t.TypeOf<A>>;
export function baseValidationControllerValidateEmailRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseValidationControllerValidateEmailParams>) {
  return {
    match: (q ? baseValidationControllerValidateEmailPath.then(query(t.intersection([baseValidationControllerValidateEmailQuery, q]))) : baseValidationControllerValidateEmailPath.then(query(baseValidationControllerValidateEmailQuery))).then(end),
    pathParts: baseValidationControllerValidateEmailPathParts,
  };
}

export const baseValidationControllerValidateEmailCodecs: {
  input: imported0_ValidateEmailC,
  output: imported0_EmptyObjectC
} = { input: imported0_validateEmailC, output: imported0_emptyObjectC };

export const baseValidationControllerValidateEmail = (): UrlInterfaceIO<"POST", imported0_ValidateEmailC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", baseValidationControllerValidateEmailRoute().match.formatter.run(Route.empty, {}).toString(true), baseValidationControllerValidateEmailCodecs);

const baseSsrShimPrivateSitePath = lit("base").then(lit("private-site"));
const baseSsrShimPrivateSitePathParts = ["base", "private-site"] as const;
export type BaseSsrShimPrivateSitePathParts = typeof baseSsrShimPrivateSitePathParts;

const baseSsrShimPrivateSiteQuery = t.strict({});
export type BaseSsrShimPrivateSiteParams = Describe<typeof baseSsrShimPrivateSitePath._A & typeof baseSsrShimPrivateSiteQuery._A>;

export type BaseSsrShimPrivateSiteRoute<A = {}> = { match: Match<BaseSsrShimPrivateSiteParams & A>; pathParts: BaseSsrShimPrivateSitePathParts };

export function baseSsrShimPrivateSiteRoute(): BaseSsrShimPrivateSiteRoute;
export function baseSsrShimPrivateSiteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BaseSsrShimPrivateSiteParams>): BaseSsrShimPrivateSiteRoute<t.TypeOf<A>>;
export function baseSsrShimPrivateSiteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BaseSsrShimPrivateSiteParams>) {
  return {
    match: (q ? baseSsrShimPrivateSitePath.then(query(t.intersection([baseSsrShimPrivateSiteQuery, q]))) : baseSsrShimPrivateSitePath.then(query(baseSsrShimPrivateSiteQuery))).then(end),
    pathParts: baseSsrShimPrivateSitePathParts,
  };
}

export const baseSsrShimPrivateSiteCodecs: { ssrInput: imported0_PrivateSiteC } = { ssrInput: imported0_privateSiteC };

export const baseSsrShimPrivateSite = (): UrlInterface<"GET"> =>
  urlInterface("GET", baseSsrShimPrivateSiteRoute().match.formatter.run(Route.empty, {}).toString(true));