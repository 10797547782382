import { Route, query, lit, str, int, end, type, Match } from "fp-ts-routing/lib";
import { Ord as numberOrd } from "fp-ts/lib/number";
import * as t from "io-ts";
import { BooleanFromString } from "io-ts-types/lib/BooleanFromString";
import { EitherC, either } from "io-ts-types/lib/either";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { ReadonlyMapFromEntriesC, readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";

import { HtmlC, htmlC } from "../../codecs/html";
import { LocalDateC } from "../../codecs/localDate";
import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { Describe } from "../../fp-ts/lib/types/describe";
import { NullableToOptionalFields, optionalFieldsToNullable } from "../../fp-ts/Record";
import { urlInterface, UrlInterfaceIO, urlInterfaceIO } from "../../routes/urlInterface";
import * as dealPortalPages from "../domaintables/dealPortalPages";
import { PhotoEditableCU as imported32_PhotoEditableCU, PhotoEditableCU as imported33_PhotoEditableCU } from "../domaintables/pages";
import { activityReportTemplatePostC as imported0_activityReportTemplatePostC, ActivityReportTemplatePostC as imported0_ActivityReportTemplatePostC, ActivityReportTemplateC as imported1_ActivityReportTemplateC, ActivityReportTemplateC as imported0_ActivityReportTemplateC, activityReportTemplateC as imported0_activityReportTemplateC, activityReportTemplateC as imported1_activityReportTemplateC } from "../models/activityReportTemplate";
import { EmailContactActorC as imported2_EmailContactActorC, actorC as imported11_actorC, ActorC as imported10_ActorC, EmailContactActorC as imported3_EmailContactActorC, minimalActorC as imported6_minimalActorC, emailContactActorC as imported4_emailContactActorC, emailContactActorC as imported2_emailContactActorC, ActorC as imported11_ActorC, actorC as imported10_actorC, EmailContactActorC as imported4_EmailContactActorC, emailContactActorC as imported3_emailContactActorC, ActorC as imported12_ActorC, MinimalActorC as imported6_MinimalActorC, actorC as imported12_actorC } from "../models/actor";
import { BankSettingsPostC as imported0_BankSettingsPostC, bankSettingsPostC as imported0_bankSettingsPostC } from "../models/bankSettings";
import { BidSubmissionDataC as imported12_BidSubmissionDataC, bidSubmissionDataC as imported12_bidSubmissionDataC } from "../models/bidSubmissions";
import { BondOfferingWithDealFileRelatedContentC as imported0_BondOfferingWithDealFileRelatedContentC, bondOfferingWithDealFileRelatedContentC as imported0_bondOfferingWithDealFileRelatedContentC, DealFileDetailsC as imported0_DealFileDetailsC, dealFileDetailsC as imported0_dealFileDetailsC } from "../models/bondOffering";
import { BondOfferingC as imported34_BondOfferingC, bondOfferingC as imported34_bondOfferingC } from "../models/bondOfferingBase";
import { BrowserRedirectC as imported12_BrowserRedirectC, browserRedirectC as imported13_browserRedirectC, browserRedirectC as imported12_browserRedirectC, BrowserRedirectC as imported11_BrowserRedirectC, browserRedirectC as imported11_browserRedirectC, BrowserRedirectC as imported13_BrowserRedirectC } from "../models/browserRedirect";
import { ClientTextItemC as imported36_ClientTextItemC, clientTextItemC as imported39_clientTextItemC, ClientTextItemC as imported38_ClientTextItemC, ClientTextItemC as imported37_ClientTextItemC, clientTextItemC as imported36_clientTextItemC, clientTextItemC as imported38_clientTextItemC, clientTextItemC as imported37_clientTextItemC, ClientTextItemC as imported39_ClientTextItemC } from "../models/clientTextItem";
import { ContactFormSubmissionWithNoteC as imported4_ContactFormSubmissionWithNoteC, contactFormSubmissionWithNoteC as imported4_contactFormSubmissionWithNoteC, ContactFormSubmissionWithNoteC as imported5_ContactFormSubmissionWithNoteC, contactFormSubmissionWithNoteC as imported5_contactFormSubmissionWithNoteC } from "../models/contact";
import { ContentNoteForContactSubmissionPostC as imported0_ContentNoteForContactSubmissionPostC, rfpContentNotePostC as imported0_rfpContentNotePostC, RfpContentNotePostC as imported0_RfpContentNotePostC, offeringContentNotePostC as imported0_offeringContentNotePostC, activeDealsInfoRequestCountC as imported0_activeDealsInfoRequestCountC, contentNoteForContactSubmissionPostC as imported0_contentNoteForContactSubmissionPostC, ActiveDealsInfoRequestCountC as imported0_ActiveDealsInfoRequestCountC, OfferingContentNotePostC as imported0_OfferingContentNotePostC } from "../models/contentNote";
import { offeringAndRfpIdsC as imported0_offeringAndRfpIdsC, NonEmailSendActivityCU as imported4_NonEmailSendActivityCU, investorProfileInfoC as imported0_investorProfileInfoC, InvestorProfileInfoC as imported0_InvestorProfileInfoC, offeringIdsC as imported0_offeringIdsC, OfferingAndRfpIdsC as imported1_OfferingAndRfpIdsC, OfferingAndRfpIdsC as imported0_OfferingAndRfpIdsC, NonEmailSendActivityCU as imported5_NonEmailSendActivityCU, offeringAndRfpIdsC as imported1_offeringAndRfpIdsC, OfferingIdsC as imported0_OfferingIdsC } from "../models/dealPortalActivity";
import { ActivityFC as imported6_ActivityFC, activityFC as imported6_activityFC, activityFC as imported4_activityFC, activityFC as imported5_activityFC, ActivityFC as imported5_ActivityFC, ActivityFC as imported4_ActivityFC } from "../models/dealPortalAnalytics";
import { InvestorActivityTableC as imported0_InvestorActivityTableC, investorActivityTableC as imported0_investorActivityTableC } from "../models/dealPortalInvestorActivityTable";
import { DealPortalUserC as imported0_DealPortalUserC, bankerExistingUserUpdateC as imported0_bankerExistingUserUpdateC, dealPortalUserCreateC as imported0_dealPortalUserCreateC, BankerExistingUserUpdateC as imported0_BankerExistingUserUpdateC, DealPortalUserCreateC as imported0_DealPortalUserCreateC, dealPortalUserC as imported0_dealPortalUserC } from "../models/dealPortalUser";
import { OfferingPrivateDocumentPostBodyC as imported0_OfferingPrivateDocumentPostBodyC, offeringPrivateDocumentPostBodyC as imported0_offeringPrivateDocumentPostBodyC, RfpPrivateDocumentPostBodyC as imported0_RfpPrivateDocumentPostBodyC, rfpPrivateDocumentPostBodyC as imported0_rfpPrivateDocumentPostBodyC } from "../models/document";
import { bankEmailCampaignPostC as imported0_bankEmailCampaignPostC, EmailCampaignWithStatsC as imported2_EmailCampaignWithStatsC, EmailCampaignWithRecipientsC as imported0_EmailCampaignWithRecipientsC, emailCampaignWithRecipientsC as imported0_emailCampaignWithRecipientsC, emailCampaignWithStatsC as imported2_emailCampaignWithStatsC, BankEmailCampaignPostC as imported0_BankEmailCampaignPostC } from "../models/emailCampaign";
import { EmptyObjectC as imported0_EmptyObjectC, emptyObjectC as imported0_emptyObjectC } from "../models/emptyObject";
import { headerPhotoC as imported14_headerPhotoC, headerPhotoPostC as imported0_headerPhotoPostC, HeaderPhotoPostC as imported0_HeaderPhotoPostC, HeaderPhotoC as imported19_HeaderPhotoC, headerPhotoC as imported15_headerPhotoC, HeaderPhotoC as imported18_HeaderPhotoC } from "../models/headerPhoto";
import { BankContactEmailPostBodyC as imported0_BankContactEmailPostBodyC, previewEmailC as imported0_previewEmailC, bankContactEmailPostBodyC as imported0_bankContactEmailPostBodyC, BankContactEmailPostBodyC as imported1_BankContactEmailPostBodyC, PreviewEmailC as imported0_PreviewEmailC, SentEmailReportC as imported0_SentEmailReportC, bulkUploadEmailsRespC as imported0_bulkUploadEmailsRespC, BulkUploadEmailsPostC as imported0_BulkUploadEmailsPostC, sentEmailReportC as imported0_sentEmailReportC, bankContactEmailPostBodyC as imported1_bankContactEmailPostBodyC, BulkUploadEmailsRespC as imported0_BulkUploadEmailsRespC, bulkUploadEmailsPostC as imported0_bulkUploadEmailsPostC } from "../models/investorActivityTypes";
import { HoldingsWithCompanyDataC as imported0_HoldingsWithCompanyDataC, holdingsWithCompanyDataC as imported0_holdingsWithCompanyDataC } from "../models/ipreoHoldings";
import { issuerC as imported54_issuerC, IssuerC as imported59_IssuerC, issuerC as imported55_issuerC, WithIssuerC as imported15_WithIssuerC, WithIssuerC as imported14_WithIssuerC, CreateIssuerPostC as imported0_CreateIssuerPostC, withIssuerC as imported14_withIssuerC, withIssuerC as imported15_withIssuerC, createIssuerPostC as imported0_createIssuerPostC, IssuerC as imported58_IssuerC } from "../models/issuer";
import { IssuerWithDealsC as imported0_IssuerWithDealsC, issuerWithDealsC as imported0_issuerWithDealsC } from "../models/issuerWithDeals";
import { MediaC as imported88_MediaC, mediaC as imported84_mediaC } from "../models/media";
import { OfficerC as imported14_OfficerC, officerC as imported14_officerC } from "../models/officer";
import { ProjectC as imported38_ProjectC, projectC as imported30_projectC } from "../models/project";
import { RfpWinningBidDetailsC as imported0_RfpWinningBidDetailsC, RfpWithDealFileRelatedContentC as imported0_RfpWithDealFileRelatedContentC, rfpWithDealFileRelatedContentC as imported0_rfpWithDealFileRelatedContentC, genRfpWithRelatedContentC as imported24_genRfpWithRelatedContentC, rfpWinningBidDetailsC as imported0_rfpWinningBidDetailsC, DealFileRfpDetailsC as imported0_DealFileRfpDetailsC, GenRfpWithRelatedContentC as imported24_GenRfpWithRelatedContentC, dealFileRfpDetailsC as imported0_dealFileRfpDetailsC } from "../models/rfp";
import { SiteTemplateC as imported10_SiteTemplateC, siteTemplateC as imported8_siteTemplateC } from "../models/siteTemplate";
import { WithModInfoC as imported250_WithModInfoC, WithStatusCU as imported828_WithStatusCU, withModInfoC as imported250_withModInfoC, WithStatusCU as imported835_WithStatusCU, WithStatusCU as imported827_WithStatusCU, WithStatusCU as imported829_WithStatusCU, WithStatusCU as imported841_WithStatusCU, WithStatusCU as imported833_WithStatusCU, WithStatusCU as imported840_WithStatusCU, WithModInfoC as imported251_WithModInfoC, WithIdC as imported316_WithIdC, WithIdC as imported315_WithIdC, WithIdC as imported314_WithIdC, WithStatusCU as imported824_WithStatusCU, WithStatusCU as imported831_WithStatusCU, WithStatusCU as imported836_WithStatusCU, WithStatusCU as imported838_WithStatusCU, withModInfoC as imported252_withModInfoC, WithModInfoC as imported252_WithModInfoC, WithIdC as imported313_WithIdC, WithStatusCU as imported825_WithStatusCU, withIdC as imported312_withIdC, withIdC as imported313_withIdC, withIdC as imported316_withIdC, withModInfoC as imported251_withModInfoC, WithStatusCU as imported830_WithStatusCU, WithStatusCU as imported837_WithStatusCU, WithStatusCU as imported844_WithStatusCU, withIdC as imported315_withIdC, WithStatusCU as imported826_WithStatusCU, WithStatusCU as imported834_WithStatusCU, WithStatusCU as imported839_WithStatusCU, WithStatusCU as imported843_WithStatusCU, WithStatusCU as imported845_WithStatusCU, withIdC as imported314_withIdC, WithStatusCU as imported832_WithStatusCU, WithIdC as imported312_WithIdC, WithStatusCU as imported842_WithStatusCU } from "../models/threadThrough";
import { PortalTipC as imported4_PortalTipC, portalTipC as imported4_portalTipC } from "../models/welcomeBox";

export const dealPortalBankerControllerBootstrap = (p: { page: (routes: typeof dealPortalPages) => Match<Record<string, never>> }) =>
  urlInterface("GET", p.page(dealPortalPages).formatter.run(Route.empty, {}).toString(true));

const dealPortalBankerControllerTrackPageviewPath = lit("v2").then(lit("deal-portal")).then(lit("track-pageview"));
const dealPortalBankerControllerTrackPageviewPathParts = ["v2", "deal-portal", "track-pageview"] as const;
export type DealPortalBankerControllerTrackPageviewPathParts = typeof dealPortalBankerControllerTrackPageviewPathParts;

const dealPortalBankerControllerTrackPageviewQuery = t.exact(t.type({
  "url": t.string
}));
export type DealPortalBankerControllerTrackPageviewParams = Describe<typeof dealPortalBankerControllerTrackPageviewPath._A & typeof dealPortalBankerControllerTrackPageviewQuery._A>;

export type DealPortalBankerControllerTrackPageviewRoute<A = {}> = { match: Match<DealPortalBankerControllerTrackPageviewParams & A>; pathParts: DealPortalBankerControllerTrackPageviewPathParts };

export function dealPortalBankerControllerTrackPageviewRoute(): DealPortalBankerControllerTrackPageviewRoute;
export function dealPortalBankerControllerTrackPageviewRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalBankerControllerTrackPageviewParams>): DealPortalBankerControllerTrackPageviewRoute<t.TypeOf<A>>;
export function dealPortalBankerControllerTrackPageviewRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalBankerControllerTrackPageviewParams>) {
  return {
    match: (q ? dealPortalBankerControllerTrackPageviewPath.then(query(t.intersection([dealPortalBankerControllerTrackPageviewQuery, q]))) : dealPortalBankerControllerTrackPageviewPath.then(query(dealPortalBankerControllerTrackPageviewQuery))).then(end),
    pathParts: dealPortalBankerControllerTrackPageviewPathParts,
  };
}

export const dealPortalBankerControllerTrackPageviewCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const dealPortalBankerControllerTrackPageview = (p: DealPortalBankerControllerTrackPageviewParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  urlInterfaceIO("GET", dealPortalBankerControllerTrackPageviewRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalBankerControllerTrackPageviewCodecs);

const dealPortalBankerControllerGetSiteTemplatePath = lit("v2").then(lit("deal-portal")).then(lit("colors"));
const dealPortalBankerControllerGetSiteTemplatePathParts = ["v2", "deal-portal", "colors"] as const;
export type DealPortalBankerControllerGetSiteTemplatePathParts = typeof dealPortalBankerControllerGetSiteTemplatePathParts;

const dealPortalBankerControllerGetSiteTemplateQuery = t.strict({});
export type DealPortalBankerControllerGetSiteTemplateParams = Describe<typeof dealPortalBankerControllerGetSiteTemplatePath._A & typeof dealPortalBankerControllerGetSiteTemplateQuery._A>;

export type DealPortalBankerControllerGetSiteTemplateRoute<A = {}> = { match: Match<DealPortalBankerControllerGetSiteTemplateParams & A>; pathParts: DealPortalBankerControllerGetSiteTemplatePathParts };

export function dealPortalBankerControllerGetSiteTemplateRoute(): DealPortalBankerControllerGetSiteTemplateRoute;
export function dealPortalBankerControllerGetSiteTemplateRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalBankerControllerGetSiteTemplateParams>): DealPortalBankerControllerGetSiteTemplateRoute<t.TypeOf<A>>;
export function dealPortalBankerControllerGetSiteTemplateRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalBankerControllerGetSiteTemplateParams>) {
  return {
    match: (q ? dealPortalBankerControllerGetSiteTemplatePath.then(query(t.intersection([dealPortalBankerControllerGetSiteTemplateQuery, q]))) : dealPortalBankerControllerGetSiteTemplatePath.then(query(dealPortalBankerControllerGetSiteTemplateQuery))).then(end),
    pathParts: dealPortalBankerControllerGetSiteTemplatePathParts,
  };
}

export const dealPortalBankerControllerGetSiteTemplateCodecs: {
  input: imported0_EmptyObjectC,
  output: OptionFromNullableC<imported10_SiteTemplateC>
} = { input: imported0_emptyObjectC, output: optionFromNullable(imported8_siteTemplateC) };

export const dealPortalBankerControllerGetSiteTemplate = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, OptionFromNullableC<imported10_SiteTemplateC>> =>
  urlInterfaceIO("GET", dealPortalBankerControllerGetSiteTemplateRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalBankerControllerGetSiteTemplateCodecs);

const dealPortalBankerControllerPostSettingsPath = lit("v2").then(lit("deal-portal")).then(lit("settings"));
const dealPortalBankerControllerPostSettingsPathParts = ["v2", "deal-portal", "settings"] as const;
export type DealPortalBankerControllerPostSettingsPathParts = typeof dealPortalBankerControllerPostSettingsPathParts;

const dealPortalBankerControllerPostSettingsQuery = t.strict({});
export type DealPortalBankerControllerPostSettingsParams = Describe<typeof dealPortalBankerControllerPostSettingsPath._A & typeof dealPortalBankerControllerPostSettingsQuery._A>;

export type DealPortalBankerControllerPostSettingsRoute<A = {}> = { match: Match<DealPortalBankerControllerPostSettingsParams & A>; pathParts: DealPortalBankerControllerPostSettingsPathParts };

export function dealPortalBankerControllerPostSettingsRoute(): DealPortalBankerControllerPostSettingsRoute;
export function dealPortalBankerControllerPostSettingsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalBankerControllerPostSettingsParams>): DealPortalBankerControllerPostSettingsRoute<t.TypeOf<A>>;
export function dealPortalBankerControllerPostSettingsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalBankerControllerPostSettingsParams>) {
  return {
    match: (q ? dealPortalBankerControllerPostSettingsPath.then(query(t.intersection([dealPortalBankerControllerPostSettingsQuery, q]))) : dealPortalBankerControllerPostSettingsPath.then(query(dealPortalBankerControllerPostSettingsQuery))).then(end),
    pathParts: dealPortalBankerControllerPostSettingsPathParts,
  };
}

export const dealPortalBankerControllerPostSettingsCodecs: {
  input: imported0_BankSettingsPostC,
  output: imported0_EmptyObjectC
} = { input: imported0_bankSettingsPostC, output: imported0_emptyObjectC };

export const dealPortalBankerControllerPostSettings = (): UrlInterfaceIO<"POST", imported0_BankSettingsPostC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalBankerControllerPostSettingsRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalBankerControllerPostSettingsCodecs);

const dealPortalBankerControllerSwitchToPath = lit("deal-portal-for").then(int("bankId"));
const dealPortalBankerControllerSwitchToPathParts = ["deal-portal-for", "bankId"] as const;
export type DealPortalBankerControllerSwitchToPathParts = typeof dealPortalBankerControllerSwitchToPathParts;

const dealPortalBankerControllerSwitchToQuery = t.exact(t.partial({
  "redirect": t.string
}));
export type DealPortalBankerControllerSwitchToParamsRaw = Describe<typeof dealPortalBankerControllerSwitchToPath._A & typeof dealPortalBankerControllerSwitchToQuery._A>;
export type DealPortalBankerControllerSwitchToParams = Describe<NullableToOptionalFields<DealPortalBankerControllerSwitchToParamsRaw>>;

export type DealPortalBankerControllerSwitchToRoute<A = {}> = { match: Match<DealPortalBankerControllerSwitchToParamsRaw & A>; pathParts: DealPortalBankerControllerSwitchToPathParts };

export function dealPortalBankerControllerSwitchToRoute(): DealPortalBankerControllerSwitchToRoute;
export function dealPortalBankerControllerSwitchToRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalBankerControllerSwitchToParamsRaw>): DealPortalBankerControllerSwitchToRoute<t.TypeOf<A>>;
export function dealPortalBankerControllerSwitchToRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalBankerControllerSwitchToParamsRaw>) {
  return {
    match: (q ? dealPortalBankerControllerSwitchToPath.then(query(t.intersection([dealPortalBankerControllerSwitchToQuery, q]))) : dealPortalBankerControllerSwitchToPath.then(query(dealPortalBankerControllerSwitchToQuery))).then(end),
    pathParts: dealPortalBankerControllerSwitchToPathParts,
  };
}

export const dealPortalBankerControllerSwitchToCodecs: {
  input: imported0_EmptyObjectC,
  output: imported11_BrowserRedirectC
} = { input: imported0_emptyObjectC, output: imported11_browserRedirectC };

export const dealPortalBankerControllerSwitchTo = (p: DealPortalBankerControllerSwitchToParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported11_BrowserRedirectC> =>
  urlInterfaceIO("GET", dealPortalBankerControllerSwitchToRoute().match.formatter.run(Route.empty, optionalFieldsToNullable(p)).toString(true), dealPortalBankerControllerSwitchToCodecs);

const dealPortalBankerControllerGetPortalTipPath = lit("v2").then(lit("deal-portal")).then(lit("portal-tip"));
const dealPortalBankerControllerGetPortalTipPathParts = ["v2", "deal-portal", "portal-tip"] as const;
export type DealPortalBankerControllerGetPortalTipPathParts = typeof dealPortalBankerControllerGetPortalTipPathParts;

const dealPortalBankerControllerGetPortalTipQuery = t.strict({});
export type DealPortalBankerControllerGetPortalTipParams = Describe<typeof dealPortalBankerControllerGetPortalTipPath._A & typeof dealPortalBankerControllerGetPortalTipQuery._A>;

export type DealPortalBankerControllerGetPortalTipRoute<A = {}> = { match: Match<DealPortalBankerControllerGetPortalTipParams & A>; pathParts: DealPortalBankerControllerGetPortalTipPathParts };

export function dealPortalBankerControllerGetPortalTipRoute(): DealPortalBankerControllerGetPortalTipRoute;
export function dealPortalBankerControllerGetPortalTipRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalBankerControllerGetPortalTipParams>): DealPortalBankerControllerGetPortalTipRoute<t.TypeOf<A>>;
export function dealPortalBankerControllerGetPortalTipRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalBankerControllerGetPortalTipParams>) {
  return {
    match: (q ? dealPortalBankerControllerGetPortalTipPath.then(query(t.intersection([dealPortalBankerControllerGetPortalTipQuery, q]))) : dealPortalBankerControllerGetPortalTipPath.then(query(dealPortalBankerControllerGetPortalTipQuery))).then(end),
    pathParts: dealPortalBankerControllerGetPortalTipPathParts,
  };
}

export const dealPortalBankerControllerGetPortalTipCodecs: {
  input: imported0_EmptyObjectC,
  output: OptionFromNullableC<imported4_PortalTipC>
} = { input: imported0_emptyObjectC, output: optionFromNullable(imported4_portalTipC) };

export const dealPortalBankerControllerGetPortalTip = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, OptionFromNullableC<imported4_PortalTipC>> =>
  urlInterfaceIO("GET", dealPortalBankerControllerGetPortalTipRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalBankerControllerGetPortalTipCodecs);

const dealPortalBankerControllerGetAllDraftCountsPath = lit("v2").then(lit("deal-portal")).then(lit("draft-counts"));
const dealPortalBankerControllerGetAllDraftCountsPathParts = ["v2", "deal-portal", "draft-counts"] as const;
export type DealPortalBankerControllerGetAllDraftCountsPathParts = typeof dealPortalBankerControllerGetAllDraftCountsPathParts;

const dealPortalBankerControllerGetAllDraftCountsQuery = t.strict({});
export type DealPortalBankerControllerGetAllDraftCountsParams = Describe<typeof dealPortalBankerControllerGetAllDraftCountsPath._A & typeof dealPortalBankerControllerGetAllDraftCountsQuery._A>;

export type DealPortalBankerControllerGetAllDraftCountsRoute<A = {}> = { match: Match<DealPortalBankerControllerGetAllDraftCountsParams & A>; pathParts: DealPortalBankerControllerGetAllDraftCountsPathParts };

export function dealPortalBankerControllerGetAllDraftCountsRoute(): DealPortalBankerControllerGetAllDraftCountsRoute;
export function dealPortalBankerControllerGetAllDraftCountsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalBankerControllerGetAllDraftCountsParams>): DealPortalBankerControllerGetAllDraftCountsRoute<t.TypeOf<A>>;
export function dealPortalBankerControllerGetAllDraftCountsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalBankerControllerGetAllDraftCountsParams>) {
  return {
    match: (q ? dealPortalBankerControllerGetAllDraftCountsPath.then(query(t.intersection([dealPortalBankerControllerGetAllDraftCountsQuery, q]))) : dealPortalBankerControllerGetAllDraftCountsPath.then(query(dealPortalBankerControllerGetAllDraftCountsQuery))).then(end),
    pathParts: dealPortalBankerControllerGetAllDraftCountsPathParts,
  };
}

export const dealPortalBankerControllerGetAllDraftCountsCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<t.TupleC<[imported58_IssuerC, t.NumberC]>>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(t.tuple([imported54_issuerC, t.number])) };

export const dealPortalBankerControllerGetAllDraftCounts = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<t.TupleC<[imported58_IssuerC, t.NumberC]>>> =>
  urlInterfaceIO("GET", dealPortalBankerControllerGetAllDraftCountsRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalBankerControllerGetAllDraftCountsCodecs);

const dealPortalBankerControllerGetHoldingsDataPath = lit("v2").then(lit("deal-portal")).then(lit("holdings"));
const dealPortalBankerControllerGetHoldingsDataPathParts = ["v2", "deal-portal", "holdings"] as const;
export type DealPortalBankerControllerGetHoldingsDataPathParts = typeof dealPortalBankerControllerGetHoldingsDataPathParts;

const dealPortalBankerControllerGetHoldingsDataQuery = t.strict({});
export type DealPortalBankerControllerGetHoldingsDataParams = Describe<typeof dealPortalBankerControllerGetHoldingsDataPath._A & typeof dealPortalBankerControllerGetHoldingsDataQuery._A>;

export type DealPortalBankerControllerGetHoldingsDataRoute<A = {}> = { match: Match<DealPortalBankerControllerGetHoldingsDataParams & A>; pathParts: DealPortalBankerControllerGetHoldingsDataPathParts };

export function dealPortalBankerControllerGetHoldingsDataRoute(): DealPortalBankerControllerGetHoldingsDataRoute;
export function dealPortalBankerControllerGetHoldingsDataRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalBankerControllerGetHoldingsDataParams>): DealPortalBankerControllerGetHoldingsDataRoute<t.TypeOf<A>>;
export function dealPortalBankerControllerGetHoldingsDataRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalBankerControllerGetHoldingsDataParams>) {
  return {
    match: (q ? dealPortalBankerControllerGetHoldingsDataPath.then(query(t.intersection([dealPortalBankerControllerGetHoldingsDataQuery, q]))) : dealPortalBankerControllerGetHoldingsDataPath.then(query(dealPortalBankerControllerGetHoldingsDataQuery))).then(end),
    pathParts: dealPortalBankerControllerGetHoldingsDataPathParts,
  };
}

export const dealPortalBankerControllerGetHoldingsDataCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_HoldingsWithCompanyDataC
} = { input: imported0_emptyObjectC, output: imported0_holdingsWithCompanyDataC };

export const dealPortalBankerControllerGetHoldingsData = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_HoldingsWithCompanyDataC> =>
  urlInterfaceIO("GET", dealPortalBankerControllerGetHoldingsDataRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalBankerControllerGetHoldingsDataCodecs);

const dealPortalAnalyticsControllerComparePath = lit("v2").then(lit("deal-portal")).then(lit("analytics")).then(lit("compare")).then(type("comparison", t.union([
  t.literal("listing-date"), t.literal("sale-date")
])));
const dealPortalAnalyticsControllerComparePathParts = [
  "v2", "deal-portal", "analytics", "compare", "comparison"
] as const;
export type DealPortalAnalyticsControllerComparePathParts = typeof dealPortalAnalyticsControllerComparePathParts;

const dealPortalAnalyticsControllerCompareQuery = t.strict({});
export type DealPortalAnalyticsControllerCompareParams = Describe<typeof dealPortalAnalyticsControllerComparePath._A & typeof dealPortalAnalyticsControllerCompareQuery._A>;

export type DealPortalAnalyticsControllerCompareRoute<A = {}> = { match: Match<DealPortalAnalyticsControllerCompareParams & A>; pathParts: DealPortalAnalyticsControllerComparePathParts };

export function dealPortalAnalyticsControllerCompareRoute(): DealPortalAnalyticsControllerCompareRoute;
export function dealPortalAnalyticsControllerCompareRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalAnalyticsControllerCompareParams>): DealPortalAnalyticsControllerCompareRoute<t.TypeOf<A>>;
export function dealPortalAnalyticsControllerCompareRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalAnalyticsControllerCompareParams>) {
  return {
    match: (q ? dealPortalAnalyticsControllerComparePath.then(query(t.intersection([dealPortalAnalyticsControllerCompareQuery, q]))) : dealPortalAnalyticsControllerComparePath.then(query(dealPortalAnalyticsControllerCompareQuery))).then(end),
    pathParts: dealPortalAnalyticsControllerComparePathParts,
  };
}

export const dealPortalAnalyticsControllerCompareCodecs: {
  input: imported0_OfferingIdsC,
  output: imported4_ActivityFC<imported2_EmailContactActorC, imported10_ActorC>
} = { input: imported0_offeringIdsC, output: imported4_activityFC(imported2_emailContactActorC, imported10_actorC) };

export const dealPortalAnalyticsControllerCompare = (p: DealPortalAnalyticsControllerCompareParams): UrlInterfaceIO<"POST", imported0_OfferingIdsC, imported4_ActivityFC<imported2_EmailContactActorC, imported10_ActorC>> =>
  urlInterfaceIO("POST", dealPortalAnalyticsControllerCompareRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalAnalyticsControllerCompareCodecs);

const dealPortalAnalyticsControllerGetPath = lit("v2").then(lit("deal-portal")).then(lit("analytics"));
const dealPortalAnalyticsControllerGetPathParts = ["v2", "deal-portal", "analytics"] as const;
export type DealPortalAnalyticsControllerGetPathParts = typeof dealPortalAnalyticsControllerGetPathParts;

const dealPortalAnalyticsControllerGetQuery = t.exact(t.type({
  "startDate": LocalDateC,
  "endDate": LocalDateC
}));
export type DealPortalAnalyticsControllerGetParams = Describe<typeof dealPortalAnalyticsControllerGetPath._A & typeof dealPortalAnalyticsControllerGetQuery._A>;

export type DealPortalAnalyticsControllerGetRoute<A = {}> = { match: Match<DealPortalAnalyticsControllerGetParams & A>; pathParts: DealPortalAnalyticsControllerGetPathParts };

export function dealPortalAnalyticsControllerGetRoute(): DealPortalAnalyticsControllerGetRoute;
export function dealPortalAnalyticsControllerGetRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalAnalyticsControllerGetParams>): DealPortalAnalyticsControllerGetRoute<t.TypeOf<A>>;
export function dealPortalAnalyticsControllerGetRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalAnalyticsControllerGetParams>) {
  return {
    match: (q ? dealPortalAnalyticsControllerGetPath.then(query(t.intersection([dealPortalAnalyticsControllerGetQuery, q]))) : dealPortalAnalyticsControllerGetPath.then(query(dealPortalAnalyticsControllerGetQuery))).then(end),
    pathParts: dealPortalAnalyticsControllerGetPathParts,
  };
}

export const dealPortalAnalyticsControllerGetCodecs: {
  input: imported0_EmptyObjectC,
  output: imported5_ActivityFC<imported3_EmailContactActorC, imported11_ActorC>
} = { input: imported0_emptyObjectC, output: imported5_activityFC(imported3_emailContactActorC, imported11_actorC) };

export const dealPortalAnalyticsControllerGet = (p: DealPortalAnalyticsControllerGetParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported5_ActivityFC<imported3_EmailContactActorC, imported11_ActorC>> =>
  urlInterfaceIO("GET", dealPortalAnalyticsControllerGetRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalAnalyticsControllerGetCodecs);

const dealPortalAnalyticsControllerGetPast24Path = lit("v2").then(lit("deal-portal")).then(lit("analytics")).then(lit("past-24"));
const dealPortalAnalyticsControllerGetPast24PathParts = ["v2", "deal-portal", "analytics", "past-24"] as const;
export type DealPortalAnalyticsControllerGetPast24PathParts = typeof dealPortalAnalyticsControllerGetPast24PathParts;

const dealPortalAnalyticsControllerGetPast24Query = t.strict({});
export type DealPortalAnalyticsControllerGetPast24Params = Describe<typeof dealPortalAnalyticsControllerGetPast24Path._A & typeof dealPortalAnalyticsControllerGetPast24Query._A>;

export type DealPortalAnalyticsControllerGetPast24Route<A = {}> = { match: Match<DealPortalAnalyticsControllerGetPast24Params & A>; pathParts: DealPortalAnalyticsControllerGetPast24PathParts };

export function dealPortalAnalyticsControllerGetPast24Route(): DealPortalAnalyticsControllerGetPast24Route;
export function dealPortalAnalyticsControllerGetPast24Route<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalAnalyticsControllerGetPast24Params>): DealPortalAnalyticsControllerGetPast24Route<t.TypeOf<A>>;
export function dealPortalAnalyticsControllerGetPast24Route<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalAnalyticsControllerGetPast24Params>) {
  return {
    match: (q ? dealPortalAnalyticsControllerGetPast24Path.then(query(t.intersection([dealPortalAnalyticsControllerGetPast24Query, q]))) : dealPortalAnalyticsControllerGetPast24Path.then(query(dealPortalAnalyticsControllerGetPast24Query))).then(end),
    pathParts: dealPortalAnalyticsControllerGetPast24PathParts,
  };
}

export const dealPortalAnalyticsControllerGetPast24Codecs: {
  input: imported0_EmptyObjectC,
  output: imported6_ActivityFC<imported4_EmailContactActorC, imported12_ActorC>
} = { input: imported0_emptyObjectC, output: imported6_activityFC(imported4_emailContactActorC, imported12_actorC) };

export const dealPortalAnalyticsControllerGetPast24 = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported6_ActivityFC<imported4_EmailContactActorC, imported12_ActorC>> =>
  urlInterfaceIO("GET", dealPortalAnalyticsControllerGetPast24Route().match.formatter.run(Route.empty, {}).toString(true), dealPortalAnalyticsControllerGetPast24Codecs);

const dealPortalInvestorActivityControllerGetPath = lit("v2").then(lit("deal-portal")).then(lit("analytics")).then(lit("investor-activity"));
const dealPortalInvestorActivityControllerGetPathParts = [
  "v2", "deal-portal", "analytics", "investor-activity"
] as const;
export type DealPortalInvestorActivityControllerGetPathParts = typeof dealPortalInvestorActivityControllerGetPathParts;

const dealPortalInvestorActivityControllerGetQuery = t.exact(t.type({
  "startDate": LocalDateC,
  "endDate": LocalDateC,
  "includeUnassociatedEmails": BooleanFromString
}));
export type DealPortalInvestorActivityControllerGetParams = Describe<typeof dealPortalInvestorActivityControllerGetPath._A & typeof dealPortalInvestorActivityControllerGetQuery._A>;

export type DealPortalInvestorActivityControllerGetRoute<A = {}> = { match: Match<DealPortalInvestorActivityControllerGetParams & A>; pathParts: DealPortalInvestorActivityControllerGetPathParts };

export function dealPortalInvestorActivityControllerGetRoute(): DealPortalInvestorActivityControllerGetRoute;
export function dealPortalInvestorActivityControllerGetRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalInvestorActivityControllerGetParams>): DealPortalInvestorActivityControllerGetRoute<t.TypeOf<A>>;
export function dealPortalInvestorActivityControllerGetRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalInvestorActivityControllerGetParams>) {
  return {
    match: (q ? dealPortalInvestorActivityControllerGetPath.then(query(t.intersection([dealPortalInvestorActivityControllerGetQuery, q]))) : dealPortalInvestorActivityControllerGetPath.then(query(dealPortalInvestorActivityControllerGetQuery))).then(end),
    pathParts: dealPortalInvestorActivityControllerGetPathParts,
  };
}

export const dealPortalInvestorActivityControllerGetCodecs: {
  input: imported0_OfferingAndRfpIdsC,
  output: imported0_InvestorActivityTableC
} = { input: imported0_offeringAndRfpIdsC, output: imported0_investorActivityTableC };

export const dealPortalInvestorActivityControllerGet = (p: DealPortalInvestorActivityControllerGetParams): UrlInterfaceIO<"POST", imported0_OfferingAndRfpIdsC, imported0_InvestorActivityTableC> =>
  urlInterfaceIO("POST", dealPortalInvestorActivityControllerGetRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalInvestorActivityControllerGetCodecs);

const dealPortalInvestorActivityControllerProfilePath = lit("v2").then(lit("deal-portal")).then(lit("analytics")).then(lit("investor-activity")).then(lit("details"));
const dealPortalInvestorActivityControllerProfilePathParts = [
  "v2", "deal-portal", "analytics", "investor-activity", "details"
] as const;
export type DealPortalInvestorActivityControllerProfilePathParts = typeof dealPortalInvestorActivityControllerProfilePathParts;

const dealPortalInvestorActivityControllerProfileQuery = t.exact(t.type({
  "userId": NumberFromString,
  "startDate": LocalDateC,
  "endDate": LocalDateC
}));
export type DealPortalInvestorActivityControllerProfileParams = Describe<typeof dealPortalInvestorActivityControllerProfilePath._A & typeof dealPortalInvestorActivityControllerProfileQuery._A>;

export type DealPortalInvestorActivityControllerProfileRoute<A = {}> = { match: Match<DealPortalInvestorActivityControllerProfileParams & A>; pathParts: DealPortalInvestorActivityControllerProfilePathParts };

export function dealPortalInvestorActivityControllerProfileRoute(): DealPortalInvestorActivityControllerProfileRoute;
export function dealPortalInvestorActivityControllerProfileRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalInvestorActivityControllerProfileParams>): DealPortalInvestorActivityControllerProfileRoute<t.TypeOf<A>>;
export function dealPortalInvestorActivityControllerProfileRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalInvestorActivityControllerProfileParams>) {
  return {
    match: (q ? dealPortalInvestorActivityControllerProfilePath.then(query(t.intersection([dealPortalInvestorActivityControllerProfileQuery, q]))) : dealPortalInvestorActivityControllerProfilePath.then(query(dealPortalInvestorActivityControllerProfileQuery))).then(end),
    pathParts: dealPortalInvestorActivityControllerProfilePathParts,
  };
}

export const dealPortalInvestorActivityControllerProfileCodecs: {
  input: imported1_OfferingAndRfpIdsC,
  output: imported0_InvestorProfileInfoC
} = { input: imported1_offeringAndRfpIdsC, output: imported0_investorProfileInfoC };

export const dealPortalInvestorActivityControllerProfile = (p: DealPortalInvestorActivityControllerProfileParams): UrlInterfaceIO<"POST", imported1_OfferingAndRfpIdsC, imported0_InvestorProfileInfoC> =>
  urlInterfaceIO("POST", dealPortalInvestorActivityControllerProfileRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalInvestorActivityControllerProfileCodecs);

const dealPortalInvestorActivityControllerRecentActivityPath = lit("v2").then(lit("deal-portal")).then(lit("recent-activity"));
const dealPortalInvestorActivityControllerRecentActivityPathParts = ["v2", "deal-portal", "recent-activity"] as const;
export type DealPortalInvestorActivityControllerRecentActivityPathParts = typeof dealPortalInvestorActivityControllerRecentActivityPathParts;

const dealPortalInvestorActivityControllerRecentActivityQuery = t.strict({});
export type DealPortalInvestorActivityControllerRecentActivityParams = Describe<typeof dealPortalInvestorActivityControllerRecentActivityPath._A & typeof dealPortalInvestorActivityControllerRecentActivityQuery._A>;

export type DealPortalInvestorActivityControllerRecentActivityRoute<A = {}> = { match: Match<DealPortalInvestorActivityControllerRecentActivityParams & A>; pathParts: DealPortalInvestorActivityControllerRecentActivityPathParts };

export function dealPortalInvestorActivityControllerRecentActivityRoute(): DealPortalInvestorActivityControllerRecentActivityRoute;
export function dealPortalInvestorActivityControllerRecentActivityRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalInvestorActivityControllerRecentActivityParams>): DealPortalInvestorActivityControllerRecentActivityRoute<t.TypeOf<A>>;
export function dealPortalInvestorActivityControllerRecentActivityRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalInvestorActivityControllerRecentActivityParams>) {
  return {
    match: (q ? dealPortalInvestorActivityControllerRecentActivityPath.then(query(t.intersection([dealPortalInvestorActivityControllerRecentActivityQuery, q]))) : dealPortalInvestorActivityControllerRecentActivityPath.then(query(dealPortalInvestorActivityControllerRecentActivityQuery))).then(end),
    pathParts: dealPortalInvestorActivityControllerRecentActivityPathParts,
  };
}

export const dealPortalInvestorActivityControllerRecentActivityCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<t.TupleC<[imported6_MinimalActorC, imported4_NonEmailSendActivityCU]>>
} = {
  input: imported0_emptyObjectC,
  output: t.readonlyArray(t.tuple([imported6_minimalActorC, imported5_NonEmailSendActivityCU]))
};

export const dealPortalInvestorActivityControllerRecentActivity = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<t.TupleC<[imported6_MinimalActorC, imported4_NonEmailSendActivityCU]>>> =>
  urlInterfaceIO("GET", dealPortalInvestorActivityControllerRecentActivityRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalInvestorActivityControllerRecentActivityCodecs);

const dealPortalBondOfferingsControllerGetAllPath = lit("v2").then(lit("deal-portal")).then(lit("bond-offerings"));
const dealPortalBondOfferingsControllerGetAllPathParts = ["v2", "deal-portal", "bond-offerings"] as const;
export type DealPortalBondOfferingsControllerGetAllPathParts = typeof dealPortalBondOfferingsControllerGetAllPathParts;

const dealPortalBondOfferingsControllerGetAllQuery = t.exact(t.partial({
  "archived": BooleanFromString
}));
export type DealPortalBondOfferingsControllerGetAllParamsRaw = Describe<typeof dealPortalBondOfferingsControllerGetAllPath._A & typeof dealPortalBondOfferingsControllerGetAllQuery._A>;
export type DealPortalBondOfferingsControllerGetAllParams = Describe<NullableToOptionalFields<DealPortalBondOfferingsControllerGetAllParamsRaw>>;

export type DealPortalBondOfferingsControllerGetAllRoute<A = {}> = { match: Match<DealPortalBondOfferingsControllerGetAllParamsRaw & A>; pathParts: DealPortalBondOfferingsControllerGetAllPathParts };

export function dealPortalBondOfferingsControllerGetAllRoute(): DealPortalBondOfferingsControllerGetAllRoute;
export function dealPortalBondOfferingsControllerGetAllRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalBondOfferingsControllerGetAllParamsRaw>): DealPortalBondOfferingsControllerGetAllRoute<t.TypeOf<A>>;
export function dealPortalBondOfferingsControllerGetAllRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalBondOfferingsControllerGetAllParamsRaw>) {
  return {
    match: (q ? dealPortalBondOfferingsControllerGetAllPath.then(query(t.intersection([dealPortalBondOfferingsControllerGetAllQuery, q]))) : dealPortalBondOfferingsControllerGetAllPath.then(query(dealPortalBondOfferingsControllerGetAllQuery))).then(end),
    pathParts: dealPortalBondOfferingsControllerGetAllPathParts,
  };
}

export const dealPortalBondOfferingsControllerGetAllCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported14_WithIssuerC<imported824_WithStatusCU<imported250_WithModInfoC<imported34_BondOfferingC>>>>
} = {
  input: imported0_emptyObjectC,
  output: t.readonlyArray(imported14_withIssuerC(imported825_WithStatusCU(imported250_withModInfoC(imported34_bondOfferingC))))
};

export const dealPortalBondOfferingsControllerGetAll = (p: DealPortalBondOfferingsControllerGetAllParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported14_WithIssuerC<imported824_WithStatusCU<imported250_WithModInfoC<imported34_BondOfferingC>>>>> =>
  urlInterfaceIO("GET", dealPortalBondOfferingsControllerGetAllRoute().match.formatter.run(Route.empty, optionalFieldsToNullable(p)).toString(true), dealPortalBondOfferingsControllerGetAllCodecs);

const dealPortalBondOfferingsControllerGetDealFileSummaryPath = lit("v2").then(lit("deal-portal")).then(lit("bond-offerings")).then(lit("deal-summary")).then(int("offeringId"));
const dealPortalBondOfferingsControllerGetDealFileSummaryPathParts = [
  "v2", "deal-portal", "bond-offerings", "deal-summary", "offeringId"
] as const;
export type DealPortalBondOfferingsControllerGetDealFileSummaryPathParts = typeof dealPortalBondOfferingsControllerGetDealFileSummaryPathParts;

const dealPortalBondOfferingsControllerGetDealFileSummaryQuery = t.strict({});
export type DealPortalBondOfferingsControllerGetDealFileSummaryParams = Describe<typeof dealPortalBondOfferingsControllerGetDealFileSummaryPath._A & typeof dealPortalBondOfferingsControllerGetDealFileSummaryQuery._A>;

export type DealPortalBondOfferingsControllerGetDealFileSummaryRoute<A = {}> = { match: Match<DealPortalBondOfferingsControllerGetDealFileSummaryParams & A>; pathParts: DealPortalBondOfferingsControllerGetDealFileSummaryPathParts };

export function dealPortalBondOfferingsControllerGetDealFileSummaryRoute(): DealPortalBondOfferingsControllerGetDealFileSummaryRoute;
export function dealPortalBondOfferingsControllerGetDealFileSummaryRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalBondOfferingsControllerGetDealFileSummaryParams>): DealPortalBondOfferingsControllerGetDealFileSummaryRoute<t.TypeOf<A>>;
export function dealPortalBondOfferingsControllerGetDealFileSummaryRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalBondOfferingsControllerGetDealFileSummaryParams>) {
  return {
    match: (q ? dealPortalBondOfferingsControllerGetDealFileSummaryPath.then(query(t.intersection([dealPortalBondOfferingsControllerGetDealFileSummaryQuery, q]))) : dealPortalBondOfferingsControllerGetDealFileSummaryPath.then(query(dealPortalBondOfferingsControllerGetDealFileSummaryQuery))).then(end),
    pathParts: dealPortalBondOfferingsControllerGetDealFileSummaryPathParts,
  };
}

export const dealPortalBondOfferingsControllerGetDealFileSummaryCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_BondOfferingWithDealFileRelatedContentC
} = { input: imported0_emptyObjectC, output: imported0_bondOfferingWithDealFileRelatedContentC };

export const dealPortalBondOfferingsControllerGetDealFileSummary = (p: DealPortalBondOfferingsControllerGetDealFileSummaryParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_BondOfferingWithDealFileRelatedContentC> =>
  urlInterfaceIO("GET", dealPortalBondOfferingsControllerGetDealFileSummaryRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalBondOfferingsControllerGetDealFileSummaryCodecs);

const dealPortalBondOfferingsControllerGetDealFileDetailsPath = lit("v2").then(lit("deal-portal")).then(lit("bond-offerings")).then(lit("deal-details")).then(int("offeringId"));
const dealPortalBondOfferingsControllerGetDealFileDetailsPathParts = [
  "v2", "deal-portal", "bond-offerings", "deal-details", "offeringId"
] as const;
export type DealPortalBondOfferingsControllerGetDealFileDetailsPathParts = typeof dealPortalBondOfferingsControllerGetDealFileDetailsPathParts;

const dealPortalBondOfferingsControllerGetDealFileDetailsQuery = t.strict({});
export type DealPortalBondOfferingsControllerGetDealFileDetailsParams = Describe<typeof dealPortalBondOfferingsControllerGetDealFileDetailsPath._A & typeof dealPortalBondOfferingsControllerGetDealFileDetailsQuery._A>;

export type DealPortalBondOfferingsControllerGetDealFileDetailsRoute<A = {}> = { match: Match<DealPortalBondOfferingsControllerGetDealFileDetailsParams & A>; pathParts: DealPortalBondOfferingsControllerGetDealFileDetailsPathParts };

export function dealPortalBondOfferingsControllerGetDealFileDetailsRoute(): DealPortalBondOfferingsControllerGetDealFileDetailsRoute;
export function dealPortalBondOfferingsControllerGetDealFileDetailsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalBondOfferingsControllerGetDealFileDetailsParams>): DealPortalBondOfferingsControllerGetDealFileDetailsRoute<t.TypeOf<A>>;
export function dealPortalBondOfferingsControllerGetDealFileDetailsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalBondOfferingsControllerGetDealFileDetailsParams>) {
  return {
    match: (q ? dealPortalBondOfferingsControllerGetDealFileDetailsPath.then(query(t.intersection([dealPortalBondOfferingsControllerGetDealFileDetailsQuery, q]))) : dealPortalBondOfferingsControllerGetDealFileDetailsPath.then(query(dealPortalBondOfferingsControllerGetDealFileDetailsQuery))).then(end),
    pathParts: dealPortalBondOfferingsControllerGetDealFileDetailsPathParts,
  };
}

export const dealPortalBondOfferingsControllerGetDealFileDetailsCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_DealFileDetailsC
} = { input: imported0_emptyObjectC, output: imported0_dealFileDetailsC };

export const dealPortalBondOfferingsControllerGetDealFileDetails = (p: DealPortalBondOfferingsControllerGetDealFileDetailsParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_DealFileDetailsC> =>
  urlInterfaceIO("GET", dealPortalBondOfferingsControllerGetDealFileDetailsRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalBondOfferingsControllerGetDealFileDetailsCodecs);

const dealPortalRfpsControllerGetAllPath = lit("v2").then(lit("deal-portal")).then(lit("rfps"));
const dealPortalRfpsControllerGetAllPathParts = ["v2", "deal-portal", "rfps"] as const;
export type DealPortalRfpsControllerGetAllPathParts = typeof dealPortalRfpsControllerGetAllPathParts;

const dealPortalRfpsControllerGetAllQuery = t.exact(t.partial({
  "archived": BooleanFromString
}));
export type DealPortalRfpsControllerGetAllParamsRaw = Describe<typeof dealPortalRfpsControllerGetAllPath._A & typeof dealPortalRfpsControllerGetAllQuery._A>;
export type DealPortalRfpsControllerGetAllParams = Describe<NullableToOptionalFields<DealPortalRfpsControllerGetAllParamsRaw>>;

export type DealPortalRfpsControllerGetAllRoute<A = {}> = { match: Match<DealPortalRfpsControllerGetAllParamsRaw & A>; pathParts: DealPortalRfpsControllerGetAllPathParts };

export function dealPortalRfpsControllerGetAllRoute(): DealPortalRfpsControllerGetAllRoute;
export function dealPortalRfpsControllerGetAllRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalRfpsControllerGetAllParamsRaw>): DealPortalRfpsControllerGetAllRoute<t.TypeOf<A>>;
export function dealPortalRfpsControllerGetAllRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalRfpsControllerGetAllParamsRaw>) {
  return {
    match: (q ? dealPortalRfpsControllerGetAllPath.then(query(t.intersection([dealPortalRfpsControllerGetAllQuery, q]))) : dealPortalRfpsControllerGetAllPath.then(query(dealPortalRfpsControllerGetAllQuery))).then(end),
    pathParts: dealPortalRfpsControllerGetAllPathParts,
  };
}

export const dealPortalRfpsControllerGetAllCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported15_WithIssuerC<imported826_WithStatusCU<imported251_WithModInfoC<imported24_GenRfpWithRelatedContentC<t.ReadonlyArrayC<imported827_WithStatusCU<imported252_WithModInfoC<imported12_BidSubmissionDataC>>>>>>>>
} = {
  input: imported0_emptyObjectC,
  output: t.readonlyArray(imported15_withIssuerC(imported828_WithStatusCU(imported251_withModInfoC(imported24_genRfpWithRelatedContentC(t.readonlyArray(imported829_WithStatusCU(imported252_withModInfoC(imported12_bidSubmissionDataC))))))))
};

export const dealPortalRfpsControllerGetAll = (p: DealPortalRfpsControllerGetAllParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported15_WithIssuerC<imported826_WithStatusCU<imported251_WithModInfoC<imported24_GenRfpWithRelatedContentC<t.ReadonlyArrayC<imported827_WithStatusCU<imported252_WithModInfoC<imported12_BidSubmissionDataC>>>>>>>>> =>
  urlInterfaceIO("GET", dealPortalRfpsControllerGetAllRoute().match.formatter.run(Route.empty, optionalFieldsToNullable(p)).toString(true), dealPortalRfpsControllerGetAllCodecs);

const dealPortalRfpsControllerGetDealFileSummaryPath = lit("v2").then(lit("deal-portal")).then(lit("rfps")).then(lit("deal-summary")).then(int("rfpId"));
const dealPortalRfpsControllerGetDealFileSummaryPathParts = [
  "v2", "deal-portal", "rfps", "deal-summary", "rfpId"
] as const;
export type DealPortalRfpsControllerGetDealFileSummaryPathParts = typeof dealPortalRfpsControllerGetDealFileSummaryPathParts;

const dealPortalRfpsControllerGetDealFileSummaryQuery = t.strict({});
export type DealPortalRfpsControllerGetDealFileSummaryParams = Describe<typeof dealPortalRfpsControllerGetDealFileSummaryPath._A & typeof dealPortalRfpsControllerGetDealFileSummaryQuery._A>;

export type DealPortalRfpsControllerGetDealFileSummaryRoute<A = {}> = { match: Match<DealPortalRfpsControllerGetDealFileSummaryParams & A>; pathParts: DealPortalRfpsControllerGetDealFileSummaryPathParts };

export function dealPortalRfpsControllerGetDealFileSummaryRoute(): DealPortalRfpsControllerGetDealFileSummaryRoute;
export function dealPortalRfpsControllerGetDealFileSummaryRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalRfpsControllerGetDealFileSummaryParams>): DealPortalRfpsControllerGetDealFileSummaryRoute<t.TypeOf<A>>;
export function dealPortalRfpsControllerGetDealFileSummaryRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalRfpsControllerGetDealFileSummaryParams>) {
  return {
    match: (q ? dealPortalRfpsControllerGetDealFileSummaryPath.then(query(t.intersection([dealPortalRfpsControllerGetDealFileSummaryQuery, q]))) : dealPortalRfpsControllerGetDealFileSummaryPath.then(query(dealPortalRfpsControllerGetDealFileSummaryQuery))).then(end),
    pathParts: dealPortalRfpsControllerGetDealFileSummaryPathParts,
  };
}

export const dealPortalRfpsControllerGetDealFileSummaryCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_RfpWithDealFileRelatedContentC
} = { input: imported0_emptyObjectC, output: imported0_rfpWithDealFileRelatedContentC };

export const dealPortalRfpsControllerGetDealFileSummary = (p: DealPortalRfpsControllerGetDealFileSummaryParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_RfpWithDealFileRelatedContentC> =>
  urlInterfaceIO("GET", dealPortalRfpsControllerGetDealFileSummaryRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalRfpsControllerGetDealFileSummaryCodecs);

const dealPortalRfpsControllerGetDealFileDetailsPath = lit("v2").then(lit("deal-portal")).then(lit("rfps")).then(lit("deal-details")).then(int("rfpId"));
const dealPortalRfpsControllerGetDealFileDetailsPathParts = [
  "v2", "deal-portal", "rfps", "deal-details", "rfpId"
] as const;
export type DealPortalRfpsControllerGetDealFileDetailsPathParts = typeof dealPortalRfpsControllerGetDealFileDetailsPathParts;

const dealPortalRfpsControllerGetDealFileDetailsQuery = t.strict({});
export type DealPortalRfpsControllerGetDealFileDetailsParams = Describe<typeof dealPortalRfpsControllerGetDealFileDetailsPath._A & typeof dealPortalRfpsControllerGetDealFileDetailsQuery._A>;

export type DealPortalRfpsControllerGetDealFileDetailsRoute<A = {}> = { match: Match<DealPortalRfpsControllerGetDealFileDetailsParams & A>; pathParts: DealPortalRfpsControllerGetDealFileDetailsPathParts };

export function dealPortalRfpsControllerGetDealFileDetailsRoute(): DealPortalRfpsControllerGetDealFileDetailsRoute;
export function dealPortalRfpsControllerGetDealFileDetailsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalRfpsControllerGetDealFileDetailsParams>): DealPortalRfpsControllerGetDealFileDetailsRoute<t.TypeOf<A>>;
export function dealPortalRfpsControllerGetDealFileDetailsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalRfpsControllerGetDealFileDetailsParams>) {
  return {
    match: (q ? dealPortalRfpsControllerGetDealFileDetailsPath.then(query(t.intersection([dealPortalRfpsControllerGetDealFileDetailsQuery, q]))) : dealPortalRfpsControllerGetDealFileDetailsPath.then(query(dealPortalRfpsControllerGetDealFileDetailsQuery))).then(end),
    pathParts: dealPortalRfpsControllerGetDealFileDetailsPathParts,
  };
}

export const dealPortalRfpsControllerGetDealFileDetailsCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_DealFileRfpDetailsC
} = { input: imported0_emptyObjectC, output: imported0_dealFileRfpDetailsC };

export const dealPortalRfpsControllerGetDealFileDetails = (p: DealPortalRfpsControllerGetDealFileDetailsParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_DealFileRfpDetailsC> =>
  urlInterfaceIO("GET", dealPortalRfpsControllerGetDealFileDetailsRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalRfpsControllerGetDealFileDetailsCodecs);

const dealPortalRfpsControllerAwardBidPath = lit("v2").then(lit("deal-portal")).then(lit("rfps")).then(lit("award-bid"));
const dealPortalRfpsControllerAwardBidPathParts = ["v2", "deal-portal", "rfps", "award-bid"] as const;
export type DealPortalRfpsControllerAwardBidPathParts = typeof dealPortalRfpsControllerAwardBidPathParts;

const dealPortalRfpsControllerAwardBidQuery = t.strict({});
export type DealPortalRfpsControllerAwardBidParams = Describe<typeof dealPortalRfpsControllerAwardBidPath._A & typeof dealPortalRfpsControllerAwardBidQuery._A>;

export type DealPortalRfpsControllerAwardBidRoute<A = {}> = { match: Match<DealPortalRfpsControllerAwardBidParams & A>; pathParts: DealPortalRfpsControllerAwardBidPathParts };

export function dealPortalRfpsControllerAwardBidRoute(): DealPortalRfpsControllerAwardBidRoute;
export function dealPortalRfpsControllerAwardBidRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalRfpsControllerAwardBidParams>): DealPortalRfpsControllerAwardBidRoute<t.TypeOf<A>>;
export function dealPortalRfpsControllerAwardBidRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalRfpsControllerAwardBidParams>) {
  return {
    match: (q ? dealPortalRfpsControllerAwardBidPath.then(query(t.intersection([dealPortalRfpsControllerAwardBidQuery, q]))) : dealPortalRfpsControllerAwardBidPath.then(query(dealPortalRfpsControllerAwardBidQuery))).then(end),
    pathParts: dealPortalRfpsControllerAwardBidPathParts,
  };
}

export const dealPortalRfpsControllerAwardBidCodecs: {
  input: imported0_RfpWinningBidDetailsC,
  output: imported0_EmptyObjectC
} = { input: imported0_rfpWinningBidDetailsC, output: imported0_emptyObjectC };

export const dealPortalRfpsControllerAwardBid = (): UrlInterfaceIO<"POST", imported0_RfpWinningBidDetailsC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalRfpsControllerAwardBidRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalRfpsControllerAwardBidCodecs);

const dealPortalEmailControllerBulkUploadPath = lit("v2").then(lit("deal-portal")).then(lit("email")).then(lit("bulk-upload"));
const dealPortalEmailControllerBulkUploadPathParts = ["v2", "deal-portal", "email", "bulk-upload"] as const;
export type DealPortalEmailControllerBulkUploadPathParts = typeof dealPortalEmailControllerBulkUploadPathParts;

const dealPortalEmailControllerBulkUploadQuery = t.strict({});
export type DealPortalEmailControllerBulkUploadParams = Describe<typeof dealPortalEmailControllerBulkUploadPath._A & typeof dealPortalEmailControllerBulkUploadQuery._A>;

export type DealPortalEmailControllerBulkUploadRoute<A = {}> = { match: Match<DealPortalEmailControllerBulkUploadParams & A>; pathParts: DealPortalEmailControllerBulkUploadPathParts };

export function dealPortalEmailControllerBulkUploadRoute(): DealPortalEmailControllerBulkUploadRoute;
export function dealPortalEmailControllerBulkUploadRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalEmailControllerBulkUploadParams>): DealPortalEmailControllerBulkUploadRoute<t.TypeOf<A>>;
export function dealPortalEmailControllerBulkUploadRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalEmailControllerBulkUploadParams>) {
  return {
    match: (q ? dealPortalEmailControllerBulkUploadPath.then(query(t.intersection([dealPortalEmailControllerBulkUploadQuery, q]))) : dealPortalEmailControllerBulkUploadPath.then(query(dealPortalEmailControllerBulkUploadQuery))).then(end),
    pathParts: dealPortalEmailControllerBulkUploadPathParts,
  };
}

export const dealPortalEmailControllerBulkUploadCodecs: {
  input: imported0_BulkUploadEmailsPostC,
  output: EitherC<ReadonlyMapFromEntriesC<t.NumberC, t.UnknownC>, imported0_BulkUploadEmailsRespC>
} = {
  input: imported0_bulkUploadEmailsPostC,
  output: either(readonlyMapFromEntries(t.number, numberOrd, t.unknown), imported0_bulkUploadEmailsRespC)
};

export const dealPortalEmailControllerBulkUpload = (): UrlInterfaceIO<"POST", imported0_BulkUploadEmailsPostC, EitherC<ReadonlyMapFromEntriesC<t.NumberC, t.UnknownC>, imported0_BulkUploadEmailsRespC>> =>
  urlInterfaceIO("POST", dealPortalEmailControllerBulkUploadRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalEmailControllerBulkUploadCodecs);

const dealPortalEmailControllerPreviewPath = lit("v2").then(lit("deal-portal")).then(lit("email")).then(lit("preview"));
const dealPortalEmailControllerPreviewPathParts = ["v2", "deal-portal", "email", "preview"] as const;
export type DealPortalEmailControllerPreviewPathParts = typeof dealPortalEmailControllerPreviewPathParts;

const dealPortalEmailControllerPreviewQuery = t.strict({});
export type DealPortalEmailControllerPreviewParams = Describe<typeof dealPortalEmailControllerPreviewPath._A & typeof dealPortalEmailControllerPreviewQuery._A>;

export type DealPortalEmailControllerPreviewRoute<A = {}> = { match: Match<DealPortalEmailControllerPreviewParams & A>; pathParts: DealPortalEmailControllerPreviewPathParts };

export function dealPortalEmailControllerPreviewRoute(): DealPortalEmailControllerPreviewRoute;
export function dealPortalEmailControllerPreviewRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalEmailControllerPreviewParams>): DealPortalEmailControllerPreviewRoute<t.TypeOf<A>>;
export function dealPortalEmailControllerPreviewRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalEmailControllerPreviewParams>) {
  return {
    match: (q ? dealPortalEmailControllerPreviewPath.then(query(t.intersection([dealPortalEmailControllerPreviewQuery, q]))) : dealPortalEmailControllerPreviewPath.then(query(dealPortalEmailControllerPreviewQuery))).then(end),
    pathParts: dealPortalEmailControllerPreviewPathParts,
  };
}

export const dealPortalEmailControllerPreviewCodecs: {
  input: imported0_BankContactEmailPostBodyC,
  output: imported0_PreviewEmailC
} = { input: imported0_bankContactEmailPostBodyC, output: imported0_previewEmailC };

export const dealPortalEmailControllerPreview = (): UrlInterfaceIO<"POST", imported0_BankContactEmailPostBodyC, imported0_PreviewEmailC> =>
  urlInterfaceIO("POST", dealPortalEmailControllerPreviewRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalEmailControllerPreviewCodecs);

const dealPortalEmailControllerSendPath = lit("v2").then(lit("deal-portal")).then(lit("email")).then(lit("send"));
const dealPortalEmailControllerSendPathParts = ["v2", "deal-portal", "email", "send"] as const;
export type DealPortalEmailControllerSendPathParts = typeof dealPortalEmailControllerSendPathParts;

const dealPortalEmailControllerSendQuery = t.strict({});
export type DealPortalEmailControllerSendParams = Describe<typeof dealPortalEmailControllerSendPath._A & typeof dealPortalEmailControllerSendQuery._A>;

export type DealPortalEmailControllerSendRoute<A = {}> = { match: Match<DealPortalEmailControllerSendParams & A>; pathParts: DealPortalEmailControllerSendPathParts };

export function dealPortalEmailControllerSendRoute(): DealPortalEmailControllerSendRoute;
export function dealPortalEmailControllerSendRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalEmailControllerSendParams>): DealPortalEmailControllerSendRoute<t.TypeOf<A>>;
export function dealPortalEmailControllerSendRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalEmailControllerSendParams>) {
  return {
    match: (q ? dealPortalEmailControllerSendPath.then(query(t.intersection([dealPortalEmailControllerSendQuery, q]))) : dealPortalEmailControllerSendPath.then(query(dealPortalEmailControllerSendQuery))).then(end),
    pathParts: dealPortalEmailControllerSendPathParts,
  };
}

export const dealPortalEmailControllerSendCodecs: {
  input: imported1_BankContactEmailPostBodyC,
  output: imported0_SentEmailReportC
} = { input: imported1_bankContactEmailPostBodyC, output: imported0_sentEmailReportC };

export const dealPortalEmailControllerSend = (): UrlInterfaceIO<"POST", imported1_BankContactEmailPostBodyC, imported0_SentEmailReportC> =>
  urlInterfaceIO("POST", dealPortalEmailControllerSendRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalEmailControllerSendCodecs);

const dealPortalEmailControllerGetAllCampaignsPath = lit("v2").then(lit("deal-portal")).then(lit("email")).then(lit("email-campaigns"));
const dealPortalEmailControllerGetAllCampaignsPathParts = ["v2", "deal-portal", "email", "email-campaigns"] as const;
export type DealPortalEmailControllerGetAllCampaignsPathParts = typeof dealPortalEmailControllerGetAllCampaignsPathParts;

const dealPortalEmailControllerGetAllCampaignsQuery = t.strict({});
export type DealPortalEmailControllerGetAllCampaignsParams = Describe<typeof dealPortalEmailControllerGetAllCampaignsPath._A & typeof dealPortalEmailControllerGetAllCampaignsQuery._A>;

export type DealPortalEmailControllerGetAllCampaignsRoute<A = {}> = { match: Match<DealPortalEmailControllerGetAllCampaignsParams & A>; pathParts: DealPortalEmailControllerGetAllCampaignsPathParts };

export function dealPortalEmailControllerGetAllCampaignsRoute(): DealPortalEmailControllerGetAllCampaignsRoute;
export function dealPortalEmailControllerGetAllCampaignsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalEmailControllerGetAllCampaignsParams>): DealPortalEmailControllerGetAllCampaignsRoute<t.TypeOf<A>>;
export function dealPortalEmailControllerGetAllCampaignsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalEmailControllerGetAllCampaignsParams>) {
  return {
    match: (q ? dealPortalEmailControllerGetAllCampaignsPath.then(query(t.intersection([dealPortalEmailControllerGetAllCampaignsQuery, q]))) : dealPortalEmailControllerGetAllCampaignsPath.then(query(dealPortalEmailControllerGetAllCampaignsQuery))).then(end),
    pathParts: dealPortalEmailControllerGetAllCampaignsPathParts,
  };
}

export const dealPortalEmailControllerGetAllCampaignsCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported2_EmailCampaignWithStatsC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported2_emailCampaignWithStatsC) };

export const dealPortalEmailControllerGetAllCampaigns = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported2_EmailCampaignWithStatsC>> =>
  urlInterfaceIO("GET", dealPortalEmailControllerGetAllCampaignsRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalEmailControllerGetAllCampaignsCodecs);

const dealPortalEmailControllerGetCampaignHtmlPath = lit("v2").then(lit("deal-portal")).then(lit("email")).then(lit("campaign-html")).then(int("emailCampaignId"));
const dealPortalEmailControllerGetCampaignHtmlPathParts = [
  "v2", "deal-portal", "email", "campaign-html", "emailCampaignId"
] as const;
export type DealPortalEmailControllerGetCampaignHtmlPathParts = typeof dealPortalEmailControllerGetCampaignHtmlPathParts;

const dealPortalEmailControllerGetCampaignHtmlQuery = t.strict({});
export type DealPortalEmailControllerGetCampaignHtmlParams = Describe<typeof dealPortalEmailControllerGetCampaignHtmlPath._A & typeof dealPortalEmailControllerGetCampaignHtmlQuery._A>;

export type DealPortalEmailControllerGetCampaignHtmlRoute<A = {}> = { match: Match<DealPortalEmailControllerGetCampaignHtmlParams & A>; pathParts: DealPortalEmailControllerGetCampaignHtmlPathParts };

export function dealPortalEmailControllerGetCampaignHtmlRoute(): DealPortalEmailControllerGetCampaignHtmlRoute;
export function dealPortalEmailControllerGetCampaignHtmlRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalEmailControllerGetCampaignHtmlParams>): DealPortalEmailControllerGetCampaignHtmlRoute<t.TypeOf<A>>;
export function dealPortalEmailControllerGetCampaignHtmlRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalEmailControllerGetCampaignHtmlParams>) {
  return {
    match: (q ? dealPortalEmailControllerGetCampaignHtmlPath.then(query(t.intersection([dealPortalEmailControllerGetCampaignHtmlQuery, q]))) : dealPortalEmailControllerGetCampaignHtmlPath.then(query(dealPortalEmailControllerGetCampaignHtmlQuery))).then(end),
    pathParts: dealPortalEmailControllerGetCampaignHtmlPathParts,
  };
}

export const dealPortalEmailControllerGetCampaignHtmlCodecs: {
  input: imported0_EmptyObjectC,
  output: EitherC<imported12_BrowserRedirectC, HtmlC>
} = { input: imported0_emptyObjectC, output: either(imported12_browserRedirectC, htmlC) };

export const dealPortalEmailControllerGetCampaignHtml = (p: DealPortalEmailControllerGetCampaignHtmlParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, EitherC<imported12_BrowserRedirectC, HtmlC>> =>
  urlInterfaceIO("GET", dealPortalEmailControllerGetCampaignHtmlRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalEmailControllerGetCampaignHtmlCodecs);

const dealPortalEmailControllerGetCampaignRecipientsPath = lit("v2").then(lit("deal-portal")).then(lit("email")).then(lit("email-campaigns")).then(int("emailCampaignId"));
const dealPortalEmailControllerGetCampaignRecipientsPathParts = [
  "v2", "deal-portal", "email", "email-campaigns", "emailCampaignId"
] as const;
export type DealPortalEmailControllerGetCampaignRecipientsPathParts = typeof dealPortalEmailControllerGetCampaignRecipientsPathParts;

const dealPortalEmailControllerGetCampaignRecipientsQuery = t.strict({});
export type DealPortalEmailControllerGetCampaignRecipientsParams = Describe<typeof dealPortalEmailControllerGetCampaignRecipientsPath._A & typeof dealPortalEmailControllerGetCampaignRecipientsQuery._A>;

export type DealPortalEmailControllerGetCampaignRecipientsRoute<A = {}> = { match: Match<DealPortalEmailControllerGetCampaignRecipientsParams & A>; pathParts: DealPortalEmailControllerGetCampaignRecipientsPathParts };

export function dealPortalEmailControllerGetCampaignRecipientsRoute(): DealPortalEmailControllerGetCampaignRecipientsRoute;
export function dealPortalEmailControllerGetCampaignRecipientsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalEmailControllerGetCampaignRecipientsParams>): DealPortalEmailControllerGetCampaignRecipientsRoute<t.TypeOf<A>>;
export function dealPortalEmailControllerGetCampaignRecipientsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalEmailControllerGetCampaignRecipientsParams>) {
  return {
    match: (q ? dealPortalEmailControllerGetCampaignRecipientsPath.then(query(t.intersection([dealPortalEmailControllerGetCampaignRecipientsQuery, q]))) : dealPortalEmailControllerGetCampaignRecipientsPath.then(query(dealPortalEmailControllerGetCampaignRecipientsQuery))).then(end),
    pathParts: dealPortalEmailControllerGetCampaignRecipientsPathParts,
  };
}

export const dealPortalEmailControllerGetCampaignRecipientsCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmailCampaignWithRecipientsC
} = { input: imported0_emptyObjectC, output: imported0_emailCampaignWithRecipientsC };

export const dealPortalEmailControllerGetCampaignRecipients = (p: DealPortalEmailControllerGetCampaignRecipientsParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_EmailCampaignWithRecipientsC> =>
  urlInterfaceIO("GET", dealPortalEmailControllerGetCampaignRecipientsRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalEmailControllerGetCampaignRecipientsCodecs);

const dealPortalEmailControllerUpdateCampaignPath = lit("v2").then(lit("deal-portal")).then(lit("email")).then(lit("email-campaign"));
const dealPortalEmailControllerUpdateCampaignPathParts = ["v2", "deal-portal", "email", "email-campaign"] as const;
export type DealPortalEmailControllerUpdateCampaignPathParts = typeof dealPortalEmailControllerUpdateCampaignPathParts;

const dealPortalEmailControllerUpdateCampaignQuery = t.strict({});
export type DealPortalEmailControllerUpdateCampaignParams = Describe<typeof dealPortalEmailControllerUpdateCampaignPath._A & typeof dealPortalEmailControllerUpdateCampaignQuery._A>;

export type DealPortalEmailControllerUpdateCampaignRoute<A = {}> = { match: Match<DealPortalEmailControllerUpdateCampaignParams & A>; pathParts: DealPortalEmailControllerUpdateCampaignPathParts };

export function dealPortalEmailControllerUpdateCampaignRoute(): DealPortalEmailControllerUpdateCampaignRoute;
export function dealPortalEmailControllerUpdateCampaignRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalEmailControllerUpdateCampaignParams>): DealPortalEmailControllerUpdateCampaignRoute<t.TypeOf<A>>;
export function dealPortalEmailControllerUpdateCampaignRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalEmailControllerUpdateCampaignParams>) {
  return {
    match: (q ? dealPortalEmailControllerUpdateCampaignPath.then(query(t.intersection([dealPortalEmailControllerUpdateCampaignQuery, q]))) : dealPortalEmailControllerUpdateCampaignPath.then(query(dealPortalEmailControllerUpdateCampaignQuery))).then(end),
    pathParts: dealPortalEmailControllerUpdateCampaignPathParts,
  };
}

export const dealPortalEmailControllerUpdateCampaignCodecs: {
  input: imported0_BankEmailCampaignPostC,
  output: imported0_EmptyObjectC
} = { input: imported0_bankEmailCampaignPostC, output: imported0_emptyObjectC };

export const dealPortalEmailControllerUpdateCampaign = (): UrlInterfaceIO<"POST", imported0_BankEmailCampaignPostC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalEmailControllerUpdateCampaignRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalEmailControllerUpdateCampaignCodecs);

const dealPortalIssuerControllerGetPath = lit("v2").then(lit("deal-portal")).then(lit("issuers"));
const dealPortalIssuerControllerGetPathParts = ["v2", "deal-portal", "issuers"] as const;
export type DealPortalIssuerControllerGetPathParts = typeof dealPortalIssuerControllerGetPathParts;

const dealPortalIssuerControllerGetQuery = t.strict({});
export type DealPortalIssuerControllerGetParams = Describe<typeof dealPortalIssuerControllerGetPath._A & typeof dealPortalIssuerControllerGetQuery._A>;

export type DealPortalIssuerControllerGetRoute<A = {}> = { match: Match<DealPortalIssuerControllerGetParams & A>; pathParts: DealPortalIssuerControllerGetPathParts };

export function dealPortalIssuerControllerGetRoute(): DealPortalIssuerControllerGetRoute;
export function dealPortalIssuerControllerGetRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalIssuerControllerGetParams>): DealPortalIssuerControllerGetRoute<t.TypeOf<A>>;
export function dealPortalIssuerControllerGetRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalIssuerControllerGetParams>) {
  return {
    match: (q ? dealPortalIssuerControllerGetPath.then(query(t.intersection([dealPortalIssuerControllerGetQuery, q]))) : dealPortalIssuerControllerGetPath.then(query(dealPortalIssuerControllerGetQuery))).then(end),
    pathParts: dealPortalIssuerControllerGetPathParts,
  };
}

export const dealPortalIssuerControllerGetCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported0_IssuerWithDealsC>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported0_issuerWithDealsC) };

export const dealPortalIssuerControllerGet = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported0_IssuerWithDealsC>> =>
  urlInterfaceIO("GET", dealPortalIssuerControllerGetRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalIssuerControllerGetCodecs);

const dealPortalIssuerControllerPostPath = lit("v2").then(lit("deal-portal")).then(lit("issuers"));
const dealPortalIssuerControllerPostPathParts = ["v2", "deal-portal", "issuers"] as const;
export type DealPortalIssuerControllerPostPathParts = typeof dealPortalIssuerControllerPostPathParts;

const dealPortalIssuerControllerPostQuery = t.strict({});
export type DealPortalIssuerControllerPostParams = Describe<typeof dealPortalIssuerControllerPostPath._A & typeof dealPortalIssuerControllerPostQuery._A>;

export type DealPortalIssuerControllerPostRoute<A = {}> = { match: Match<DealPortalIssuerControllerPostParams & A>; pathParts: DealPortalIssuerControllerPostPathParts };

export function dealPortalIssuerControllerPostRoute(): DealPortalIssuerControllerPostRoute;
export function dealPortalIssuerControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalIssuerControllerPostParams>): DealPortalIssuerControllerPostRoute<t.TypeOf<A>>;
export function dealPortalIssuerControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalIssuerControllerPostParams>) {
  return {
    match: (q ? dealPortalIssuerControllerPostPath.then(query(t.intersection([dealPortalIssuerControllerPostQuery, q]))) : dealPortalIssuerControllerPostPath.then(query(dealPortalIssuerControllerPostQuery))).then(end),
    pathParts: dealPortalIssuerControllerPostPathParts,
  };
}

export const dealPortalIssuerControllerPostCodecs: {
  input: imported0_CreateIssuerPostC,
  output: imported59_IssuerC
} = { input: imported0_createIssuerPostC, output: imported55_issuerC };

export const dealPortalIssuerControllerPost = (): UrlInterfaceIO<"POST", imported0_CreateIssuerPostC, imported59_IssuerC> =>
  urlInterfaceIO("POST", dealPortalIssuerControllerPostRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalIssuerControllerPostCodecs);

const dealPortalIssuerControllerAboutPath = lit("v2").then(lit("deal-portal")).then(lit("issuers")).then(int("issuerId")).then(lit("about"));
const dealPortalIssuerControllerAboutPathParts = ["v2", "deal-portal", "issuers", "issuerId", "about"] as const;
export type DealPortalIssuerControllerAboutPathParts = typeof dealPortalIssuerControllerAboutPathParts;

const dealPortalIssuerControllerAboutQuery = t.strict({});
export type DealPortalIssuerControllerAboutParams = Describe<typeof dealPortalIssuerControllerAboutPath._A & typeof dealPortalIssuerControllerAboutQuery._A>;

export type DealPortalIssuerControllerAboutRoute<A = {}> = { match: Match<DealPortalIssuerControllerAboutParams & A>; pathParts: DealPortalIssuerControllerAboutPathParts };

export function dealPortalIssuerControllerAboutRoute(): DealPortalIssuerControllerAboutRoute;
export function dealPortalIssuerControllerAboutRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalIssuerControllerAboutParams>): DealPortalIssuerControllerAboutRoute<t.TypeOf<A>>;
export function dealPortalIssuerControllerAboutRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalIssuerControllerAboutParams>) {
  return {
    match: (q ? dealPortalIssuerControllerAboutPath.then(query(t.intersection([dealPortalIssuerControllerAboutQuery, q]))) : dealPortalIssuerControllerAboutPath.then(query(dealPortalIssuerControllerAboutQuery))).then(end),
    pathParts: dealPortalIssuerControllerAboutPathParts,
  };
}

export const dealPortalIssuerControllerAboutCodecs: {
  input: imported0_EmptyObjectC,
  output: OptionFromNullableC<imported830_WithStatusCU<imported36_ClientTextItemC>>
} = { input: imported0_emptyObjectC, output: optionFromNullable(imported831_WithStatusCU(imported36_clientTextItemC)) };

export const dealPortalIssuerControllerAbout = (p: DealPortalIssuerControllerAboutParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, OptionFromNullableC<imported830_WithStatusCU<imported36_ClientTextItemC>>> =>
  urlInterfaceIO("GET", dealPortalIssuerControllerAboutRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalIssuerControllerAboutCodecs);

const dealPortalIssuerControllerTeamPath = lit("v2").then(lit("deal-portal")).then(lit("issuers")).then(int("issuerId")).then(lit("team"));
const dealPortalIssuerControllerTeamPathParts = ["v2", "deal-portal", "issuers", "issuerId", "team"] as const;
export type DealPortalIssuerControllerTeamPathParts = typeof dealPortalIssuerControllerTeamPathParts;

const dealPortalIssuerControllerTeamQuery = t.strict({});
export type DealPortalIssuerControllerTeamParams = Describe<typeof dealPortalIssuerControllerTeamPath._A & typeof dealPortalIssuerControllerTeamQuery._A>;

export type DealPortalIssuerControllerTeamRoute<A = {}> = { match: Match<DealPortalIssuerControllerTeamParams & A>; pathParts: DealPortalIssuerControllerTeamPathParts };

export function dealPortalIssuerControllerTeamRoute(): DealPortalIssuerControllerTeamRoute;
export function dealPortalIssuerControllerTeamRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalIssuerControllerTeamParams>): DealPortalIssuerControllerTeamRoute<t.TypeOf<A>>;
export function dealPortalIssuerControllerTeamRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalIssuerControllerTeamParams>) {
  return {
    match: (q ? dealPortalIssuerControllerTeamPath.then(query(t.intersection([dealPortalIssuerControllerTeamQuery, q]))) : dealPortalIssuerControllerTeamPath.then(query(dealPortalIssuerControllerTeamQuery))).then(end),
    pathParts: dealPortalIssuerControllerTeamPathParts,
  };
}

export const dealPortalIssuerControllerTeamCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported832_WithStatusCU<imported14_OfficerC>>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported833_WithStatusCU(imported14_officerC)) };

export const dealPortalIssuerControllerTeam = (p: DealPortalIssuerControllerTeamParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported832_WithStatusCU<imported14_OfficerC>>> =>
  urlInterfaceIO("GET", dealPortalIssuerControllerTeamRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalIssuerControllerTeamCodecs);

const dealPortalIssuerControllerProjectsPath = lit("v2").then(lit("deal-portal")).then(lit("issuers")).then(int("issuerId")).then(lit("projects"));
const dealPortalIssuerControllerProjectsPathParts = ["v2", "deal-portal", "issuers", "issuerId", "projects"] as const;
export type DealPortalIssuerControllerProjectsPathParts = typeof dealPortalIssuerControllerProjectsPathParts;

const dealPortalIssuerControllerProjectsQuery = t.strict({});
export type DealPortalIssuerControllerProjectsParams = Describe<typeof dealPortalIssuerControllerProjectsPath._A & typeof dealPortalIssuerControllerProjectsQuery._A>;

export type DealPortalIssuerControllerProjectsRoute<A = {}> = { match: Match<DealPortalIssuerControllerProjectsParams & A>; pathParts: DealPortalIssuerControllerProjectsPathParts };

export function dealPortalIssuerControllerProjectsRoute(): DealPortalIssuerControllerProjectsRoute;
export function dealPortalIssuerControllerProjectsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalIssuerControllerProjectsParams>): DealPortalIssuerControllerProjectsRoute<t.TypeOf<A>>;
export function dealPortalIssuerControllerProjectsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalIssuerControllerProjectsParams>) {
  return {
    match: (q ? dealPortalIssuerControllerProjectsPath.then(query(t.intersection([dealPortalIssuerControllerProjectsQuery, q]))) : dealPortalIssuerControllerProjectsPath.then(query(dealPortalIssuerControllerProjectsQuery))).then(end),
    pathParts: dealPortalIssuerControllerProjectsPathParts,
  };
}

export const dealPortalIssuerControllerProjectsCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported834_WithStatusCU<imported38_ProjectC>>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported835_WithStatusCU(imported30_projectC)) };

export const dealPortalIssuerControllerProjects = (p: DealPortalIssuerControllerProjectsParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported834_WithStatusCU<imported38_ProjectC>>> =>
  urlInterfaceIO("GET", dealPortalIssuerControllerProjectsRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalIssuerControllerProjectsCodecs);

const dealPortalIssuerControllerBondsHeaderPhotoPath = lit("v2").then(lit("deal-portal")).then(lit("issuers")).then(int("issuerId")).then(lit("bonds-header-photo"));
const dealPortalIssuerControllerBondsHeaderPhotoPathParts = [
  "v2", "deal-portal", "issuers", "issuerId", "bonds-header-photo"
] as const;
export type DealPortalIssuerControllerBondsHeaderPhotoPathParts = typeof dealPortalIssuerControllerBondsHeaderPhotoPathParts;

const dealPortalIssuerControllerBondsHeaderPhotoQuery = t.strict({});
export type DealPortalIssuerControllerBondsHeaderPhotoParams = Describe<typeof dealPortalIssuerControllerBondsHeaderPhotoPath._A & typeof dealPortalIssuerControllerBondsHeaderPhotoQuery._A>;

export type DealPortalIssuerControllerBondsHeaderPhotoRoute<A = {}> = { match: Match<DealPortalIssuerControllerBondsHeaderPhotoParams & A>; pathParts: DealPortalIssuerControllerBondsHeaderPhotoPathParts };

export function dealPortalIssuerControllerBondsHeaderPhotoRoute(): DealPortalIssuerControllerBondsHeaderPhotoRoute;
export function dealPortalIssuerControllerBondsHeaderPhotoRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalIssuerControllerBondsHeaderPhotoParams>): DealPortalIssuerControllerBondsHeaderPhotoRoute<t.TypeOf<A>>;
export function dealPortalIssuerControllerBondsHeaderPhotoRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalIssuerControllerBondsHeaderPhotoParams>) {
  return {
    match: (q ? dealPortalIssuerControllerBondsHeaderPhotoPath.then(query(t.intersection([dealPortalIssuerControllerBondsHeaderPhotoQuery, q]))) : dealPortalIssuerControllerBondsHeaderPhotoPath.then(query(dealPortalIssuerControllerBondsHeaderPhotoQuery))).then(end),
    pathParts: dealPortalIssuerControllerBondsHeaderPhotoPathParts,
  };
}

export const dealPortalIssuerControllerBondsHeaderPhotoCodecs: {
  input: imported0_EmptyObjectC,
  output: OptionFromNullableC<imported836_WithStatusCU<imported88_MediaC>>
} = { input: imported0_emptyObjectC, output: optionFromNullable(imported837_WithStatusCU(imported84_mediaC)) };

export const dealPortalIssuerControllerBondsHeaderPhoto = (p: DealPortalIssuerControllerBondsHeaderPhotoParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, OptionFromNullableC<imported836_WithStatusCU<imported88_MediaC>>> =>
  urlInterfaceIO("GET", dealPortalIssuerControllerBondsHeaderPhotoRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalIssuerControllerBondsHeaderPhotoCodecs);

const dealPortalPortalUsersControllerGetPath = lit("v2").then(lit("deal-portal")).then(lit("portal-users"));
const dealPortalPortalUsersControllerGetPathParts = ["v2", "deal-portal", "portal-users"] as const;
export type DealPortalPortalUsersControllerGetPathParts = typeof dealPortalPortalUsersControllerGetPathParts;

const dealPortalPortalUsersControllerGetQuery = t.strict({});
export type DealPortalPortalUsersControllerGetParams = Describe<typeof dealPortalPortalUsersControllerGetPath._A & typeof dealPortalPortalUsersControllerGetQuery._A>;

export type DealPortalPortalUsersControllerGetRoute<A = {}> = { match: Match<DealPortalPortalUsersControllerGetParams & A>; pathParts: DealPortalPortalUsersControllerGetPathParts };

export function dealPortalPortalUsersControllerGetRoute(): DealPortalPortalUsersControllerGetRoute;
export function dealPortalPortalUsersControllerGetRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalPortalUsersControllerGetParams>): DealPortalPortalUsersControllerGetRoute<t.TypeOf<A>>;
export function dealPortalPortalUsersControllerGetRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalPortalUsersControllerGetParams>) {
  return {
    match: (q ? dealPortalPortalUsersControllerGetPath.then(query(t.intersection([dealPortalPortalUsersControllerGetQuery, q]))) : dealPortalPortalUsersControllerGetPath.then(query(dealPortalPortalUsersControllerGetQuery))).then(end),
    pathParts: dealPortalPortalUsersControllerGetPathParts,
  };
}

export const dealPortalPortalUsersControllerGetCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported312_WithIdC<imported0_DealPortalUserC>>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported312_withIdC(imported0_dealPortalUserC)) };

export const dealPortalPortalUsersControllerGet = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported312_WithIdC<imported0_DealPortalUserC>>> =>
  urlInterfaceIO("GET", dealPortalPortalUsersControllerGetRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalPortalUsersControllerGetCodecs);

const dealPortalPortalUsersControllerCreatePath = lit("v2").then(lit("deal-portal")).then(lit("portal-users")).then(lit("create"));
const dealPortalPortalUsersControllerCreatePathParts = ["v2", "deal-portal", "portal-users", "create"] as const;
export type DealPortalPortalUsersControllerCreatePathParts = typeof dealPortalPortalUsersControllerCreatePathParts;

const dealPortalPortalUsersControllerCreateQuery = t.strict({});
export type DealPortalPortalUsersControllerCreateParams = Describe<typeof dealPortalPortalUsersControllerCreatePath._A & typeof dealPortalPortalUsersControllerCreateQuery._A>;

export type DealPortalPortalUsersControllerCreateRoute<A = {}> = { match: Match<DealPortalPortalUsersControllerCreateParams & A>; pathParts: DealPortalPortalUsersControllerCreatePathParts };

export function dealPortalPortalUsersControllerCreateRoute(): DealPortalPortalUsersControllerCreateRoute;
export function dealPortalPortalUsersControllerCreateRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalPortalUsersControllerCreateParams>): DealPortalPortalUsersControllerCreateRoute<t.TypeOf<A>>;
export function dealPortalPortalUsersControllerCreateRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalPortalUsersControllerCreateParams>) {
  return {
    match: (q ? dealPortalPortalUsersControllerCreatePath.then(query(t.intersection([dealPortalPortalUsersControllerCreateQuery, q]))) : dealPortalPortalUsersControllerCreatePath.then(query(dealPortalPortalUsersControllerCreateQuery))).then(end),
    pathParts: dealPortalPortalUsersControllerCreatePathParts,
  };
}

export const dealPortalPortalUsersControllerCreateCodecs: {
  input: imported0_DealPortalUserCreateC,
  output: imported0_EmptyObjectC
} = { input: imported0_dealPortalUserCreateC, output: imported0_emptyObjectC };

export const dealPortalPortalUsersControllerCreate = (): UrlInterfaceIO<"POST", imported0_DealPortalUserCreateC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalPortalUsersControllerCreateRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalPortalUsersControllerCreateCodecs);

const dealPortalPortalUsersControllerUpdatePath = lit("v2").then(lit("deal-portal")).then(lit("portal-users")).then(lit("update"));
const dealPortalPortalUsersControllerUpdatePathParts = ["v2", "deal-portal", "portal-users", "update"] as const;
export type DealPortalPortalUsersControllerUpdatePathParts = typeof dealPortalPortalUsersControllerUpdatePathParts;

const dealPortalPortalUsersControllerUpdateQuery = t.strict({});
export type DealPortalPortalUsersControllerUpdateParams = Describe<typeof dealPortalPortalUsersControllerUpdatePath._A & typeof dealPortalPortalUsersControllerUpdateQuery._A>;

export type DealPortalPortalUsersControllerUpdateRoute<A = {}> = { match: Match<DealPortalPortalUsersControllerUpdateParams & A>; pathParts: DealPortalPortalUsersControllerUpdatePathParts };

export function dealPortalPortalUsersControllerUpdateRoute(): DealPortalPortalUsersControllerUpdateRoute;
export function dealPortalPortalUsersControllerUpdateRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalPortalUsersControllerUpdateParams>): DealPortalPortalUsersControllerUpdateRoute<t.TypeOf<A>>;
export function dealPortalPortalUsersControllerUpdateRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalPortalUsersControllerUpdateParams>) {
  return {
    match: (q ? dealPortalPortalUsersControllerUpdatePath.then(query(t.intersection([dealPortalPortalUsersControllerUpdateQuery, q]))) : dealPortalPortalUsersControllerUpdatePath.then(query(dealPortalPortalUsersControllerUpdateQuery))).then(end),
    pathParts: dealPortalPortalUsersControllerUpdatePathParts,
  };
}

export const dealPortalPortalUsersControllerUpdateCodecs: {
  input: imported0_BankerExistingUserUpdateC,
  output: imported0_EmptyObjectC
} = { input: imported0_bankerExistingUserUpdateC, output: imported0_emptyObjectC };

export const dealPortalPortalUsersControllerUpdate = (): UrlInterfaceIO<"POST", imported0_BankerExistingUserUpdateC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalPortalUsersControllerUpdateRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalPortalUsersControllerUpdateCodecs);

const dealPortalPortalUsersControllerDeletePath = lit("v2").then(lit("deal-portal")).then(lit("portal-users")).then(int("id"));
const dealPortalPortalUsersControllerDeletePathParts = ["v2", "deal-portal", "portal-users", "id"] as const;
export type DealPortalPortalUsersControllerDeletePathParts = typeof dealPortalPortalUsersControllerDeletePathParts;

const dealPortalPortalUsersControllerDeleteQuery = t.strict({});
export type DealPortalPortalUsersControllerDeleteParams = Describe<typeof dealPortalPortalUsersControllerDeletePath._A & typeof dealPortalPortalUsersControllerDeleteQuery._A>;

export type DealPortalPortalUsersControllerDeleteRoute<A = {}> = { match: Match<DealPortalPortalUsersControllerDeleteParams & A>; pathParts: DealPortalPortalUsersControllerDeletePathParts };

export function dealPortalPortalUsersControllerDeleteRoute(): DealPortalPortalUsersControllerDeleteRoute;
export function dealPortalPortalUsersControllerDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalPortalUsersControllerDeleteParams>): DealPortalPortalUsersControllerDeleteRoute<t.TypeOf<A>>;
export function dealPortalPortalUsersControllerDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalPortalUsersControllerDeleteParams>) {
  return {
    match: (q ? dealPortalPortalUsersControllerDeletePath.then(query(t.intersection([dealPortalPortalUsersControllerDeleteQuery, q]))) : dealPortalPortalUsersControllerDeletePath.then(query(dealPortalPortalUsersControllerDeleteQuery))).then(end),
    pathParts: dealPortalPortalUsersControllerDeletePathParts,
  };
}

export const dealPortalPortalUsersControllerDeleteCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const dealPortalPortalUsersControllerDelete = (p: DealPortalPortalUsersControllerDeleteParams): UrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalPortalUsersControllerDeleteRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalPortalUsersControllerDeleteCodecs);

const dealPortalPortalUsersControllerSearchUserByEmailPath = lit("v2").then(lit("deal-portal")).then(lit("portal-users")).then(lit("search-user-by-email")).then(str("email"));
const dealPortalPortalUsersControllerSearchUserByEmailPathParts = [
  "v2", "deal-portal", "portal-users", "search-user-by-email", "email"
] as const;
export type DealPortalPortalUsersControllerSearchUserByEmailPathParts = typeof dealPortalPortalUsersControllerSearchUserByEmailPathParts;

const dealPortalPortalUsersControllerSearchUserByEmailQuery = t.strict({});
export type DealPortalPortalUsersControllerSearchUserByEmailParams = Describe<typeof dealPortalPortalUsersControllerSearchUserByEmailPath._A & typeof dealPortalPortalUsersControllerSearchUserByEmailQuery._A>;

export type DealPortalPortalUsersControllerSearchUserByEmailRoute<A = {}> = { match: Match<DealPortalPortalUsersControllerSearchUserByEmailParams & A>; pathParts: DealPortalPortalUsersControllerSearchUserByEmailPathParts };

export function dealPortalPortalUsersControllerSearchUserByEmailRoute(): DealPortalPortalUsersControllerSearchUserByEmailRoute;
export function dealPortalPortalUsersControllerSearchUserByEmailRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalPortalUsersControllerSearchUserByEmailParams>): DealPortalPortalUsersControllerSearchUserByEmailRoute<t.TypeOf<A>>;
export function dealPortalPortalUsersControllerSearchUserByEmailRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalPortalUsersControllerSearchUserByEmailParams>) {
  return {
    match: (q ? dealPortalPortalUsersControllerSearchUserByEmailPath.then(query(t.intersection([dealPortalPortalUsersControllerSearchUserByEmailQuery, q]))) : dealPortalPortalUsersControllerSearchUserByEmailPath.then(query(dealPortalPortalUsersControllerSearchUserByEmailQuery))).then(end),
    pathParts: dealPortalPortalUsersControllerSearchUserByEmailPathParts,
  };
}

export const dealPortalPortalUsersControllerSearchUserByEmailCodecs: {
  input: imported0_EmptyObjectC,
  output: OptionFromNullableC<t.NumberC>
} = { input: imported0_emptyObjectC, output: optionFromNullable(t.number) };

export const dealPortalPortalUsersControllerSearchUserByEmail = (p: DealPortalPortalUsersControllerSearchUserByEmailParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, OptionFromNullableC<t.NumberC>> =>
  urlInterfaceIO("GET", dealPortalPortalUsersControllerSearchUserByEmailRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalPortalUsersControllerSearchUserByEmailCodecs);

const dealPortalHeaderPhotosControllerGetPath = lit("v2").then(lit("deal-portal")).then(lit("header-photos"));
const dealPortalHeaderPhotosControllerGetPathParts = ["v2", "deal-portal", "header-photos"] as const;
export type DealPortalHeaderPhotosControllerGetPathParts = typeof dealPortalHeaderPhotosControllerGetPathParts;

const dealPortalHeaderPhotosControllerGetQuery = t.strict({});
export type DealPortalHeaderPhotosControllerGetParams = Describe<typeof dealPortalHeaderPhotosControllerGetPath._A & typeof dealPortalHeaderPhotosControllerGetQuery._A>;

export type DealPortalHeaderPhotosControllerGetRoute<A = {}> = { match: Match<DealPortalHeaderPhotosControllerGetParams & A>; pathParts: DealPortalHeaderPhotosControllerGetPathParts };

export function dealPortalHeaderPhotosControllerGetRoute(): DealPortalHeaderPhotosControllerGetRoute;
export function dealPortalHeaderPhotosControllerGetRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalHeaderPhotosControllerGetParams>): DealPortalHeaderPhotosControllerGetRoute<t.TypeOf<A>>;
export function dealPortalHeaderPhotosControllerGetRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalHeaderPhotosControllerGetParams>) {
  return {
    match: (q ? dealPortalHeaderPhotosControllerGetPath.then(query(t.intersection([dealPortalHeaderPhotosControllerGetQuery, q]))) : dealPortalHeaderPhotosControllerGetPath.then(query(dealPortalHeaderPhotosControllerGetQuery))).then(end),
    pathParts: dealPortalHeaderPhotosControllerGetPathParts,
  };
}

export const dealPortalHeaderPhotosControllerGetCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<t.TupleC<[imported32_PhotoEditableCU, imported838_WithStatusCU<imported18_HeaderPhotoC>]>>
} = {
  input: imported0_emptyObjectC,
  output: t.readonlyArray(t.tuple([imported33_PhotoEditableCU, imported839_WithStatusCU(imported14_headerPhotoC)]))
};

export const dealPortalHeaderPhotosControllerGet = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<t.TupleC<[imported32_PhotoEditableCU, imported838_WithStatusCU<imported18_HeaderPhotoC>]>>> =>
  urlInterfaceIO("GET", dealPortalHeaderPhotosControllerGetRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalHeaderPhotosControllerGetCodecs);

const dealPortalHeaderPhotosControllerPostPath = lit("v2").then(lit("deal-portal")).then(lit("header-photos"));
const dealPortalHeaderPhotosControllerPostPathParts = ["v2", "deal-portal", "header-photos"] as const;
export type DealPortalHeaderPhotosControllerPostPathParts = typeof dealPortalHeaderPhotosControllerPostPathParts;

const dealPortalHeaderPhotosControllerPostQuery = t.strict({});
export type DealPortalHeaderPhotosControllerPostParams = Describe<typeof dealPortalHeaderPhotosControllerPostPath._A & typeof dealPortalHeaderPhotosControllerPostQuery._A>;

export type DealPortalHeaderPhotosControllerPostRoute<A = {}> = { match: Match<DealPortalHeaderPhotosControllerPostParams & A>; pathParts: DealPortalHeaderPhotosControllerPostPathParts };

export function dealPortalHeaderPhotosControllerPostRoute(): DealPortalHeaderPhotosControllerPostRoute;
export function dealPortalHeaderPhotosControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalHeaderPhotosControllerPostParams>): DealPortalHeaderPhotosControllerPostRoute<t.TypeOf<A>>;
export function dealPortalHeaderPhotosControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalHeaderPhotosControllerPostParams>) {
  return {
    match: (q ? dealPortalHeaderPhotosControllerPostPath.then(query(t.intersection([dealPortalHeaderPhotosControllerPostQuery, q]))) : dealPortalHeaderPhotosControllerPostPath.then(query(dealPortalHeaderPhotosControllerPostQuery))).then(end),
    pathParts: dealPortalHeaderPhotosControllerPostPathParts,
  };
}

export const dealPortalHeaderPhotosControllerPostCodecs: {
  input: imported0_HeaderPhotoPostC,
  output: OptionFromNullableC<imported840_WithStatusCU<imported19_HeaderPhotoC>>
} = {
  input: imported0_headerPhotoPostC,
  output: optionFromNullable(imported841_WithStatusCU(imported15_headerPhotoC))
};

export const dealPortalHeaderPhotosControllerPost = (): UrlInterfaceIO<"POST", imported0_HeaderPhotoPostC, OptionFromNullableC<imported840_WithStatusCU<imported19_HeaderPhotoC>>> =>
  urlInterfaceIO("POST", dealPortalHeaderPhotosControllerPostRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalHeaderPhotosControllerPostCodecs);

const dealPortalHeaderPhotosControllerDeletePath = lit("v2").then(lit("deal-portal")).then(lit("header-photos")).then(int("id"));
const dealPortalHeaderPhotosControllerDeletePathParts = ["v2", "deal-portal", "header-photos", "id"] as const;
export type DealPortalHeaderPhotosControllerDeletePathParts = typeof dealPortalHeaderPhotosControllerDeletePathParts;

const dealPortalHeaderPhotosControllerDeleteQuery = t.strict({});
export type DealPortalHeaderPhotosControllerDeleteParams = Describe<typeof dealPortalHeaderPhotosControllerDeletePath._A & typeof dealPortalHeaderPhotosControllerDeleteQuery._A>;

export type DealPortalHeaderPhotosControllerDeleteRoute<A = {}> = { match: Match<DealPortalHeaderPhotosControllerDeleteParams & A>; pathParts: DealPortalHeaderPhotosControllerDeletePathParts };

export function dealPortalHeaderPhotosControllerDeleteRoute(): DealPortalHeaderPhotosControllerDeleteRoute;
export function dealPortalHeaderPhotosControllerDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalHeaderPhotosControllerDeleteParams>): DealPortalHeaderPhotosControllerDeleteRoute<t.TypeOf<A>>;
export function dealPortalHeaderPhotosControllerDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalHeaderPhotosControllerDeleteParams>) {
  return {
    match: (q ? dealPortalHeaderPhotosControllerDeletePath.then(query(t.intersection([dealPortalHeaderPhotosControllerDeleteQuery, q]))) : dealPortalHeaderPhotosControllerDeletePath.then(query(dealPortalHeaderPhotosControllerDeleteQuery))).then(end),
    pathParts: dealPortalHeaderPhotosControllerDeletePathParts,
  };
}

export const dealPortalHeaderPhotosControllerDeleteCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const dealPortalHeaderPhotosControllerDelete = (p: DealPortalHeaderPhotosControllerDeleteParams): UrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalHeaderPhotosControllerDeleteRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalHeaderPhotosControllerDeleteCodecs);

const dealPortalBankTextItemsControllerGetPath = lit("v2").then(lit("deal-portal")).then(lit("text-items")).then(type("textType", t.union([
  t.literal("about"), t.literal("welcome"), t.literal("offering-info"), t.literal("contact-us"), t.literal("custom-page-1"), t.literal("custom-page-2"), t.literal("custom-page-3"), t.literal("custom-page-4"), t.literal("custom-page-5"), t.literal("portal-tip"), t.literal("esg-overview")
])));
const dealPortalBankTextItemsControllerGetPathParts = ["v2", "deal-portal", "text-items", "textType"] as const;
export type DealPortalBankTextItemsControllerGetPathParts = typeof dealPortalBankTextItemsControllerGetPathParts;

const dealPortalBankTextItemsControllerGetQuery = t.strict({});
export type DealPortalBankTextItemsControllerGetParams = Describe<typeof dealPortalBankTextItemsControllerGetPath._A & typeof dealPortalBankTextItemsControllerGetQuery._A>;

export type DealPortalBankTextItemsControllerGetRoute<A = {}> = { match: Match<DealPortalBankTextItemsControllerGetParams & A>; pathParts: DealPortalBankTextItemsControllerGetPathParts };

export function dealPortalBankTextItemsControllerGetRoute(): DealPortalBankTextItemsControllerGetRoute;
export function dealPortalBankTextItemsControllerGetRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalBankTextItemsControllerGetParams>): DealPortalBankTextItemsControllerGetRoute<t.TypeOf<A>>;
export function dealPortalBankTextItemsControllerGetRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalBankTextItemsControllerGetParams>) {
  return {
    match: (q ? dealPortalBankTextItemsControllerGetPath.then(query(t.intersection([dealPortalBankTextItemsControllerGetQuery, q]))) : dealPortalBankTextItemsControllerGetPath.then(query(dealPortalBankTextItemsControllerGetQuery))).then(end),
    pathParts: dealPortalBankTextItemsControllerGetPathParts,
  };
}

export const dealPortalBankTextItemsControllerGetCodecs: {
  input: imported0_EmptyObjectC,
  output: OptionFromNullableC<imported842_WithStatusCU<imported37_ClientTextItemC>>
} = { input: imported0_emptyObjectC, output: optionFromNullable(imported843_WithStatusCU(imported37_clientTextItemC)) };

export const dealPortalBankTextItemsControllerGet = (p: DealPortalBankTextItemsControllerGetParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, OptionFromNullableC<imported842_WithStatusCU<imported37_ClientTextItemC>>> =>
  urlInterfaceIO("GET", dealPortalBankTextItemsControllerGetRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalBankTextItemsControllerGetCodecs);

const dealPortalBankTextItemsControllerPostPath = lit("v2").then(lit("deal-portal")).then(lit("text-items"));
const dealPortalBankTextItemsControllerPostPathParts = ["v2", "deal-portal", "text-items"] as const;
export type DealPortalBankTextItemsControllerPostPathParts = typeof dealPortalBankTextItemsControllerPostPathParts;

const dealPortalBankTextItemsControllerPostQuery = t.strict({});
export type DealPortalBankTextItemsControllerPostParams = Describe<typeof dealPortalBankTextItemsControllerPostPath._A & typeof dealPortalBankTextItemsControllerPostQuery._A>;

export type DealPortalBankTextItemsControllerPostRoute<A = {}> = { match: Match<DealPortalBankTextItemsControllerPostParams & A>; pathParts: DealPortalBankTextItemsControllerPostPathParts };

export function dealPortalBankTextItemsControllerPostRoute(): DealPortalBankTextItemsControllerPostRoute;
export function dealPortalBankTextItemsControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalBankTextItemsControllerPostParams>): DealPortalBankTextItemsControllerPostRoute<t.TypeOf<A>>;
export function dealPortalBankTextItemsControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalBankTextItemsControllerPostParams>) {
  return {
    match: (q ? dealPortalBankTextItemsControllerPostPath.then(query(t.intersection([dealPortalBankTextItemsControllerPostQuery, q]))) : dealPortalBankTextItemsControllerPostPath.then(query(dealPortalBankTextItemsControllerPostQuery))).then(end),
    pathParts: dealPortalBankTextItemsControllerPostPathParts,
  };
}

export const dealPortalBankTextItemsControllerPostCodecs: {
  input: imported38_ClientTextItemC,
  output: OptionFromNullableC<imported844_WithStatusCU<imported39_ClientTextItemC>>
} = {
  input: imported38_clientTextItemC,
  output: optionFromNullable(imported845_WithStatusCU(imported39_clientTextItemC))
};

export const dealPortalBankTextItemsControllerPost = (): UrlInterfaceIO<"POST", imported38_ClientTextItemC, OptionFromNullableC<imported844_WithStatusCU<imported39_ClientTextItemC>>> =>
  urlInterfaceIO("POST", dealPortalBankTextItemsControllerPostRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalBankTextItemsControllerPostCodecs);

const dealPortalActivityReportTemplatesControllerDeletePath = lit("v2").then(lit("deal-portal")).then(lit("activity-report-templates")).then(int("id"));
const dealPortalActivityReportTemplatesControllerDeletePathParts = [
  "v2", "deal-portal", "activity-report-templates", "id"
] as const;
export type DealPortalActivityReportTemplatesControllerDeletePathParts = typeof dealPortalActivityReportTemplatesControllerDeletePathParts;

const dealPortalActivityReportTemplatesControllerDeleteQuery = t.strict({});
export type DealPortalActivityReportTemplatesControllerDeleteParams = Describe<typeof dealPortalActivityReportTemplatesControllerDeletePath._A & typeof dealPortalActivityReportTemplatesControllerDeleteQuery._A>;

export type DealPortalActivityReportTemplatesControllerDeleteRoute<A = {}> = { match: Match<DealPortalActivityReportTemplatesControllerDeleteParams & A>; pathParts: DealPortalActivityReportTemplatesControllerDeletePathParts };

export function dealPortalActivityReportTemplatesControllerDeleteRoute(): DealPortalActivityReportTemplatesControllerDeleteRoute;
export function dealPortalActivityReportTemplatesControllerDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalActivityReportTemplatesControllerDeleteParams>): DealPortalActivityReportTemplatesControllerDeleteRoute<t.TypeOf<A>>;
export function dealPortalActivityReportTemplatesControllerDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalActivityReportTemplatesControllerDeleteParams>) {
  return {
    match: (q ? dealPortalActivityReportTemplatesControllerDeletePath.then(query(t.intersection([dealPortalActivityReportTemplatesControllerDeleteQuery, q]))) : dealPortalActivityReportTemplatesControllerDeletePath.then(query(dealPortalActivityReportTemplatesControllerDeleteQuery))).then(end),
    pathParts: dealPortalActivityReportTemplatesControllerDeletePathParts,
  };
}

export const dealPortalActivityReportTemplatesControllerDeleteCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const dealPortalActivityReportTemplatesControllerDelete = (p: DealPortalActivityReportTemplatesControllerDeleteParams): UrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalActivityReportTemplatesControllerDeleteRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalActivityReportTemplatesControllerDeleteCodecs);

const dealPortalActivityReportTemplatesControllerGetPath = lit("v2").then(lit("deal-portal")).then(lit("activity-report-templates"));
const dealPortalActivityReportTemplatesControllerGetPathParts = [
  "v2", "deal-portal", "activity-report-templates"
] as const;
export type DealPortalActivityReportTemplatesControllerGetPathParts = typeof dealPortalActivityReportTemplatesControllerGetPathParts;

const dealPortalActivityReportTemplatesControllerGetQuery = t.strict({});
export type DealPortalActivityReportTemplatesControllerGetParams = Describe<typeof dealPortalActivityReportTemplatesControllerGetPath._A & typeof dealPortalActivityReportTemplatesControllerGetQuery._A>;

export type DealPortalActivityReportTemplatesControllerGetRoute<A = {}> = { match: Match<DealPortalActivityReportTemplatesControllerGetParams & A>; pathParts: DealPortalActivityReportTemplatesControllerGetPathParts };

export function dealPortalActivityReportTemplatesControllerGetRoute(): DealPortalActivityReportTemplatesControllerGetRoute;
export function dealPortalActivityReportTemplatesControllerGetRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalActivityReportTemplatesControllerGetParams>): DealPortalActivityReportTemplatesControllerGetRoute<t.TypeOf<A>>;
export function dealPortalActivityReportTemplatesControllerGetRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalActivityReportTemplatesControllerGetParams>) {
  return {
    match: (q ? dealPortalActivityReportTemplatesControllerGetPath.then(query(t.intersection([dealPortalActivityReportTemplatesControllerGetQuery, q]))) : dealPortalActivityReportTemplatesControllerGetPath.then(query(dealPortalActivityReportTemplatesControllerGetQuery))).then(end),
    pathParts: dealPortalActivityReportTemplatesControllerGetPathParts,
  };
}

export const dealPortalActivityReportTemplatesControllerGetCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported313_WithIdC<imported0_ActivityReportTemplateC>>
} = { input: imported0_emptyObjectC, output: t.readonlyArray(imported313_withIdC(imported0_activityReportTemplateC)) };

export const dealPortalActivityReportTemplatesControllerGet = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported313_WithIdC<imported0_ActivityReportTemplateC>>> =>
  urlInterfaceIO("GET", dealPortalActivityReportTemplatesControllerGetRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalActivityReportTemplatesControllerGetCodecs);

const dealPortalActivityReportTemplatesControllerPostPath = lit("v2").then(lit("deal-portal")).then(lit("activity-report-templates"));
const dealPortalActivityReportTemplatesControllerPostPathParts = [
  "v2", "deal-portal", "activity-report-templates"
] as const;
export type DealPortalActivityReportTemplatesControllerPostPathParts = typeof dealPortalActivityReportTemplatesControllerPostPathParts;

const dealPortalActivityReportTemplatesControllerPostQuery = t.strict({});
export type DealPortalActivityReportTemplatesControllerPostParams = Describe<typeof dealPortalActivityReportTemplatesControllerPostPath._A & typeof dealPortalActivityReportTemplatesControllerPostQuery._A>;

export type DealPortalActivityReportTemplatesControllerPostRoute<A = {}> = { match: Match<DealPortalActivityReportTemplatesControllerPostParams & A>; pathParts: DealPortalActivityReportTemplatesControllerPostPathParts };

export function dealPortalActivityReportTemplatesControllerPostRoute(): DealPortalActivityReportTemplatesControllerPostRoute;
export function dealPortalActivityReportTemplatesControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalActivityReportTemplatesControllerPostParams>): DealPortalActivityReportTemplatesControllerPostRoute<t.TypeOf<A>>;
export function dealPortalActivityReportTemplatesControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalActivityReportTemplatesControllerPostParams>) {
  return {
    match: (q ? dealPortalActivityReportTemplatesControllerPostPath.then(query(t.intersection([dealPortalActivityReportTemplatesControllerPostQuery, q]))) : dealPortalActivityReportTemplatesControllerPostPath.then(query(dealPortalActivityReportTemplatesControllerPostQuery))).then(end),
    pathParts: dealPortalActivityReportTemplatesControllerPostPathParts,
  };
}

export const dealPortalActivityReportTemplatesControllerPostCodecs: {
  input: imported0_ActivityReportTemplatePostC,
  output: imported314_WithIdC<imported1_ActivityReportTemplateC>
} = { input: imported0_activityReportTemplatePostC, output: imported314_withIdC(imported1_activityReportTemplateC) };

export const dealPortalActivityReportTemplatesControllerPost = (): UrlInterfaceIO<"POST", imported0_ActivityReportTemplatePostC, imported314_WithIdC<imported1_ActivityReportTemplateC>> =>
  urlInterfaceIO("POST", dealPortalActivityReportTemplatesControllerPostRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalActivityReportTemplatesControllerPostCodecs);

const dealPortalPrivateDocumentsControllerOfferingPostPath = lit("v2").then(lit("deal-portal")).then(lit("private-documents")).then(lit("offering"));
const dealPortalPrivateDocumentsControllerOfferingPostPathParts = [
  "v2", "deal-portal", "private-documents", "offering"
] as const;
export type DealPortalPrivateDocumentsControllerOfferingPostPathParts = typeof dealPortalPrivateDocumentsControllerOfferingPostPathParts;

const dealPortalPrivateDocumentsControllerOfferingPostQuery = t.strict({});
export type DealPortalPrivateDocumentsControllerOfferingPostParams = Describe<typeof dealPortalPrivateDocumentsControllerOfferingPostPath._A & typeof dealPortalPrivateDocumentsControllerOfferingPostQuery._A>;

export type DealPortalPrivateDocumentsControllerOfferingPostRoute<A = {}> = { match: Match<DealPortalPrivateDocumentsControllerOfferingPostParams & A>; pathParts: DealPortalPrivateDocumentsControllerOfferingPostPathParts };

export function dealPortalPrivateDocumentsControllerOfferingPostRoute(): DealPortalPrivateDocumentsControllerOfferingPostRoute;
export function dealPortalPrivateDocumentsControllerOfferingPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalPrivateDocumentsControllerOfferingPostParams>): DealPortalPrivateDocumentsControllerOfferingPostRoute<t.TypeOf<A>>;
export function dealPortalPrivateDocumentsControllerOfferingPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalPrivateDocumentsControllerOfferingPostParams>) {
  return {
    match: (q ? dealPortalPrivateDocumentsControllerOfferingPostPath.then(query(t.intersection([dealPortalPrivateDocumentsControllerOfferingPostQuery, q]))) : dealPortalPrivateDocumentsControllerOfferingPostPath.then(query(dealPortalPrivateDocumentsControllerOfferingPostQuery))).then(end),
    pathParts: dealPortalPrivateDocumentsControllerOfferingPostPathParts,
  };
}

export const dealPortalPrivateDocumentsControllerOfferingPostCodecs: {
  input: imported0_OfferingPrivateDocumentPostBodyC,
  output: imported0_EmptyObjectC
} = { input: imported0_offeringPrivateDocumentPostBodyC, output: imported0_emptyObjectC };

export const dealPortalPrivateDocumentsControllerOfferingPost = (): UrlInterfaceIO<"POST", imported0_OfferingPrivateDocumentPostBodyC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalPrivateDocumentsControllerOfferingPostRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalPrivateDocumentsControllerOfferingPostCodecs);

const dealPortalPrivateDocumentsControllerOfferingDeletePath = lit("v2").then(lit("deal-portal")).then(lit("private-documents")).then(lit("offering")).then(int("offeringId")).then(int("id"));
const dealPortalPrivateDocumentsControllerOfferingDeletePathParts = [
  "v2", "deal-portal", "private-documents", "offering", "offeringId", "id"
] as const;
export type DealPortalPrivateDocumentsControllerOfferingDeletePathParts = typeof dealPortalPrivateDocumentsControllerOfferingDeletePathParts;

const dealPortalPrivateDocumentsControllerOfferingDeleteQuery = t.strict({});
export type DealPortalPrivateDocumentsControllerOfferingDeleteParams = Describe<typeof dealPortalPrivateDocumentsControllerOfferingDeletePath._A & typeof dealPortalPrivateDocumentsControllerOfferingDeleteQuery._A>;

export type DealPortalPrivateDocumentsControllerOfferingDeleteRoute<A = {}> = { match: Match<DealPortalPrivateDocumentsControllerOfferingDeleteParams & A>; pathParts: DealPortalPrivateDocumentsControllerOfferingDeletePathParts };

export function dealPortalPrivateDocumentsControllerOfferingDeleteRoute(): DealPortalPrivateDocumentsControllerOfferingDeleteRoute;
export function dealPortalPrivateDocumentsControllerOfferingDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalPrivateDocumentsControllerOfferingDeleteParams>): DealPortalPrivateDocumentsControllerOfferingDeleteRoute<t.TypeOf<A>>;
export function dealPortalPrivateDocumentsControllerOfferingDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalPrivateDocumentsControllerOfferingDeleteParams>) {
  return {
    match: (q ? dealPortalPrivateDocumentsControllerOfferingDeletePath.then(query(t.intersection([dealPortalPrivateDocumentsControllerOfferingDeleteQuery, q]))) : dealPortalPrivateDocumentsControllerOfferingDeletePath.then(query(dealPortalPrivateDocumentsControllerOfferingDeleteQuery))).then(end),
    pathParts: dealPortalPrivateDocumentsControllerOfferingDeletePathParts,
  };
}

export const dealPortalPrivateDocumentsControllerOfferingDeleteCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const dealPortalPrivateDocumentsControllerOfferingDelete = (p: DealPortalPrivateDocumentsControllerOfferingDeleteParams): UrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalPrivateDocumentsControllerOfferingDeleteRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalPrivateDocumentsControllerOfferingDeleteCodecs);

const dealPortalPrivateDocumentsControllerRfpPostPath = lit("v2").then(lit("deal-portal")).then(lit("private-documents")).then(lit("rfp"));
const dealPortalPrivateDocumentsControllerRfpPostPathParts = ["v2", "deal-portal", "private-documents", "rfp"] as const;
export type DealPortalPrivateDocumentsControllerRfpPostPathParts = typeof dealPortalPrivateDocumentsControllerRfpPostPathParts;

const dealPortalPrivateDocumentsControllerRfpPostQuery = t.strict({});
export type DealPortalPrivateDocumentsControllerRfpPostParams = Describe<typeof dealPortalPrivateDocumentsControllerRfpPostPath._A & typeof dealPortalPrivateDocumentsControllerRfpPostQuery._A>;

export type DealPortalPrivateDocumentsControllerRfpPostRoute<A = {}> = { match: Match<DealPortalPrivateDocumentsControllerRfpPostParams & A>; pathParts: DealPortalPrivateDocumentsControllerRfpPostPathParts };

export function dealPortalPrivateDocumentsControllerRfpPostRoute(): DealPortalPrivateDocumentsControllerRfpPostRoute;
export function dealPortalPrivateDocumentsControllerRfpPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalPrivateDocumentsControllerRfpPostParams>): DealPortalPrivateDocumentsControllerRfpPostRoute<t.TypeOf<A>>;
export function dealPortalPrivateDocumentsControllerRfpPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalPrivateDocumentsControllerRfpPostParams>) {
  return {
    match: (q ? dealPortalPrivateDocumentsControllerRfpPostPath.then(query(t.intersection([dealPortalPrivateDocumentsControllerRfpPostQuery, q]))) : dealPortalPrivateDocumentsControllerRfpPostPath.then(query(dealPortalPrivateDocumentsControllerRfpPostQuery))).then(end),
    pathParts: dealPortalPrivateDocumentsControllerRfpPostPathParts,
  };
}

export const dealPortalPrivateDocumentsControllerRfpPostCodecs: {
  input: imported0_RfpPrivateDocumentPostBodyC,
  output: imported0_EmptyObjectC
} = { input: imported0_rfpPrivateDocumentPostBodyC, output: imported0_emptyObjectC };

export const dealPortalPrivateDocumentsControllerRfpPost = (): UrlInterfaceIO<"POST", imported0_RfpPrivateDocumentPostBodyC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalPrivateDocumentsControllerRfpPostRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalPrivateDocumentsControllerRfpPostCodecs);

const dealPortalPrivateDocumentsControllerRfpDeletePath = lit("v2").then(lit("deal-portal")).then(lit("private-documents")).then(lit("rfp")).then(int("rfpId")).then(int("id"));
const dealPortalPrivateDocumentsControllerRfpDeletePathParts = [
  "v2", "deal-portal", "private-documents", "rfp", "rfpId", "id"
] as const;
export type DealPortalPrivateDocumentsControllerRfpDeletePathParts = typeof dealPortalPrivateDocumentsControllerRfpDeletePathParts;

const dealPortalPrivateDocumentsControllerRfpDeleteQuery = t.strict({});
export type DealPortalPrivateDocumentsControllerRfpDeleteParams = Describe<typeof dealPortalPrivateDocumentsControllerRfpDeletePath._A & typeof dealPortalPrivateDocumentsControllerRfpDeleteQuery._A>;

export type DealPortalPrivateDocumentsControllerRfpDeleteRoute<A = {}> = { match: Match<DealPortalPrivateDocumentsControllerRfpDeleteParams & A>; pathParts: DealPortalPrivateDocumentsControllerRfpDeletePathParts };

export function dealPortalPrivateDocumentsControllerRfpDeleteRoute(): DealPortalPrivateDocumentsControllerRfpDeleteRoute;
export function dealPortalPrivateDocumentsControllerRfpDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalPrivateDocumentsControllerRfpDeleteParams>): DealPortalPrivateDocumentsControllerRfpDeleteRoute<t.TypeOf<A>>;
export function dealPortalPrivateDocumentsControllerRfpDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalPrivateDocumentsControllerRfpDeleteParams>) {
  return {
    match: (q ? dealPortalPrivateDocumentsControllerRfpDeletePath.then(query(t.intersection([dealPortalPrivateDocumentsControllerRfpDeleteQuery, q]))) : dealPortalPrivateDocumentsControllerRfpDeletePath.then(query(dealPortalPrivateDocumentsControllerRfpDeleteQuery))).then(end),
    pathParts: dealPortalPrivateDocumentsControllerRfpDeletePathParts,
  };
}

export const dealPortalPrivateDocumentsControllerRfpDeleteCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const dealPortalPrivateDocumentsControllerRfpDelete = (p: DealPortalPrivateDocumentsControllerRfpDeleteParams): UrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalPrivateDocumentsControllerRfpDeleteRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalPrivateDocumentsControllerRfpDeleteCodecs);

const dealPortalPrivateDocumentsControllerDownloadPath = lit("v2").then(lit("deal-portal")).then(lit("private-documents")).then(int("id"));
const dealPortalPrivateDocumentsControllerDownloadPathParts = ["v2", "deal-portal", "private-documents", "id"] as const;
export type DealPortalPrivateDocumentsControllerDownloadPathParts = typeof dealPortalPrivateDocumentsControllerDownloadPathParts;

const dealPortalPrivateDocumentsControllerDownloadQuery = t.strict({});
export type DealPortalPrivateDocumentsControllerDownloadParams = Describe<typeof dealPortalPrivateDocumentsControllerDownloadPath._A & typeof dealPortalPrivateDocumentsControllerDownloadQuery._A>;

export type DealPortalPrivateDocumentsControllerDownloadRoute<A = {}> = { match: Match<DealPortalPrivateDocumentsControllerDownloadParams & A>; pathParts: DealPortalPrivateDocumentsControllerDownloadPathParts };

export function dealPortalPrivateDocumentsControllerDownloadRoute(): DealPortalPrivateDocumentsControllerDownloadRoute;
export function dealPortalPrivateDocumentsControllerDownloadRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalPrivateDocumentsControllerDownloadParams>): DealPortalPrivateDocumentsControllerDownloadRoute<t.TypeOf<A>>;
export function dealPortalPrivateDocumentsControllerDownloadRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalPrivateDocumentsControllerDownloadParams>) {
  return {
    match: (q ? dealPortalPrivateDocumentsControllerDownloadPath.then(query(t.intersection([dealPortalPrivateDocumentsControllerDownloadQuery, q]))) : dealPortalPrivateDocumentsControllerDownloadPath.then(query(dealPortalPrivateDocumentsControllerDownloadQuery))).then(end),
    pathParts: dealPortalPrivateDocumentsControllerDownloadPathParts,
  };
}

export const dealPortalPrivateDocumentsControllerDownloadCodecs: {
  input: imported0_EmptyObjectC,
  output: imported13_BrowserRedirectC
} = { input: imported0_emptyObjectC, output: imported13_browserRedirectC };

export const dealPortalPrivateDocumentsControllerDownload = (p: DealPortalPrivateDocumentsControllerDownloadParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported13_BrowserRedirectC> =>
  urlInterfaceIO("GET", dealPortalPrivateDocumentsControllerDownloadRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalPrivateDocumentsControllerDownloadCodecs);

const dealPortalContentNotesControllerOfferingPostPath = lit("v2").then(lit("deal-portal")).then(lit("content-notes")).then(lit("offering"));
const dealPortalContentNotesControllerOfferingPostPathParts = [
  "v2", "deal-portal", "content-notes", "offering"
] as const;
export type DealPortalContentNotesControllerOfferingPostPathParts = typeof dealPortalContentNotesControllerOfferingPostPathParts;

const dealPortalContentNotesControllerOfferingPostQuery = t.strict({});
export type DealPortalContentNotesControllerOfferingPostParams = Describe<typeof dealPortalContentNotesControllerOfferingPostPath._A & typeof dealPortalContentNotesControllerOfferingPostQuery._A>;

export type DealPortalContentNotesControllerOfferingPostRoute<A = {}> = { match: Match<DealPortalContentNotesControllerOfferingPostParams & A>; pathParts: DealPortalContentNotesControllerOfferingPostPathParts };

export function dealPortalContentNotesControllerOfferingPostRoute(): DealPortalContentNotesControllerOfferingPostRoute;
export function dealPortalContentNotesControllerOfferingPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalContentNotesControllerOfferingPostParams>): DealPortalContentNotesControllerOfferingPostRoute<t.TypeOf<A>>;
export function dealPortalContentNotesControllerOfferingPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalContentNotesControllerOfferingPostParams>) {
  return {
    match: (q ? dealPortalContentNotesControllerOfferingPostPath.then(query(t.intersection([dealPortalContentNotesControllerOfferingPostQuery, q]))) : dealPortalContentNotesControllerOfferingPostPath.then(query(dealPortalContentNotesControllerOfferingPostQuery))).then(end),
    pathParts: dealPortalContentNotesControllerOfferingPostPathParts,
  };
}

export const dealPortalContentNotesControllerOfferingPostCodecs: {
  input: imported0_OfferingContentNotePostC,
  output: imported0_EmptyObjectC
} = { input: imported0_offeringContentNotePostC, output: imported0_emptyObjectC };

export const dealPortalContentNotesControllerOfferingPost = (): UrlInterfaceIO<"POST", imported0_OfferingContentNotePostC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalContentNotesControllerOfferingPostRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalContentNotesControllerOfferingPostCodecs);

const dealPortalContentNotesControllerRfpPostPath = lit("v2").then(lit("deal-portal")).then(lit("content-notes")).then(lit("rfps"));
const dealPortalContentNotesControllerRfpPostPathParts = ["v2", "deal-portal", "content-notes", "rfps"] as const;
export type DealPortalContentNotesControllerRfpPostPathParts = typeof dealPortalContentNotesControllerRfpPostPathParts;

const dealPortalContentNotesControllerRfpPostQuery = t.strict({});
export type DealPortalContentNotesControllerRfpPostParams = Describe<typeof dealPortalContentNotesControllerRfpPostPath._A & typeof dealPortalContentNotesControllerRfpPostQuery._A>;

export type DealPortalContentNotesControllerRfpPostRoute<A = {}> = { match: Match<DealPortalContentNotesControllerRfpPostParams & A>; pathParts: DealPortalContentNotesControllerRfpPostPathParts };

export function dealPortalContentNotesControllerRfpPostRoute(): DealPortalContentNotesControllerRfpPostRoute;
export function dealPortalContentNotesControllerRfpPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalContentNotesControllerRfpPostParams>): DealPortalContentNotesControllerRfpPostRoute<t.TypeOf<A>>;
export function dealPortalContentNotesControllerRfpPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalContentNotesControllerRfpPostParams>) {
  return {
    match: (q ? dealPortalContentNotesControllerRfpPostPath.then(query(t.intersection([dealPortalContentNotesControllerRfpPostQuery, q]))) : dealPortalContentNotesControllerRfpPostPath.then(query(dealPortalContentNotesControllerRfpPostQuery))).then(end),
    pathParts: dealPortalContentNotesControllerRfpPostPathParts,
  };
}

export const dealPortalContentNotesControllerRfpPostCodecs: {
  input: imported0_RfpContentNotePostC,
  output: imported0_EmptyObjectC
} = { input: imported0_rfpContentNotePostC, output: imported0_emptyObjectC };

export const dealPortalContentNotesControllerRfpPost = (): UrlInterfaceIO<"POST", imported0_RfpContentNotePostC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalContentNotesControllerRfpPostRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalContentNotesControllerRfpPostCodecs);

const dealPortalContentNotesControllerOfferingDeletePath = lit("v2").then(lit("deal-portal")).then(lit("content-notes")).then(lit("offering")).then(int("offeringId")).then(int("id"));
const dealPortalContentNotesControllerOfferingDeletePathParts = [
  "v2", "deal-portal", "content-notes", "offering", "offeringId", "id"
] as const;
export type DealPortalContentNotesControllerOfferingDeletePathParts = typeof dealPortalContentNotesControllerOfferingDeletePathParts;

const dealPortalContentNotesControllerOfferingDeleteQuery = t.strict({});
export type DealPortalContentNotesControllerOfferingDeleteParams = Describe<typeof dealPortalContentNotesControllerOfferingDeletePath._A & typeof dealPortalContentNotesControllerOfferingDeleteQuery._A>;

export type DealPortalContentNotesControllerOfferingDeleteRoute<A = {}> = { match: Match<DealPortalContentNotesControllerOfferingDeleteParams & A>; pathParts: DealPortalContentNotesControllerOfferingDeletePathParts };

export function dealPortalContentNotesControllerOfferingDeleteRoute(): DealPortalContentNotesControllerOfferingDeleteRoute;
export function dealPortalContentNotesControllerOfferingDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalContentNotesControllerOfferingDeleteParams>): DealPortalContentNotesControllerOfferingDeleteRoute<t.TypeOf<A>>;
export function dealPortalContentNotesControllerOfferingDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalContentNotesControllerOfferingDeleteParams>) {
  return {
    match: (q ? dealPortalContentNotesControllerOfferingDeletePath.then(query(t.intersection([dealPortalContentNotesControllerOfferingDeleteQuery, q]))) : dealPortalContentNotesControllerOfferingDeletePath.then(query(dealPortalContentNotesControllerOfferingDeleteQuery))).then(end),
    pathParts: dealPortalContentNotesControllerOfferingDeletePathParts,
  };
}

export const dealPortalContentNotesControllerOfferingDeleteCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const dealPortalContentNotesControllerOfferingDelete = (p: DealPortalContentNotesControllerOfferingDeleteParams): UrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalContentNotesControllerOfferingDeleteRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalContentNotesControllerOfferingDeleteCodecs);

const dealPortalContentNotesControllerRfpDeletePath = lit("v2").then(lit("deal-portal")).then(lit("content-notes")).then(lit("rfp")).then(int("rfpId")).then(int("id"));
const dealPortalContentNotesControllerRfpDeletePathParts = [
  "v2", "deal-portal", "content-notes", "rfp", "rfpId", "id"
] as const;
export type DealPortalContentNotesControllerRfpDeletePathParts = typeof dealPortalContentNotesControllerRfpDeletePathParts;

const dealPortalContentNotesControllerRfpDeleteQuery = t.strict({});
export type DealPortalContentNotesControllerRfpDeleteParams = Describe<typeof dealPortalContentNotesControllerRfpDeletePath._A & typeof dealPortalContentNotesControllerRfpDeleteQuery._A>;

export type DealPortalContentNotesControllerRfpDeleteRoute<A = {}> = { match: Match<DealPortalContentNotesControllerRfpDeleteParams & A>; pathParts: DealPortalContentNotesControllerRfpDeletePathParts };

export function dealPortalContentNotesControllerRfpDeleteRoute(): DealPortalContentNotesControllerRfpDeleteRoute;
export function dealPortalContentNotesControllerRfpDeleteRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalContentNotesControllerRfpDeleteParams>): DealPortalContentNotesControllerRfpDeleteRoute<t.TypeOf<A>>;
export function dealPortalContentNotesControllerRfpDeleteRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalContentNotesControllerRfpDeleteParams>) {
  return {
    match: (q ? dealPortalContentNotesControllerRfpDeletePath.then(query(t.intersection([dealPortalContentNotesControllerRfpDeleteQuery, q]))) : dealPortalContentNotesControllerRfpDeletePath.then(query(dealPortalContentNotesControllerRfpDeleteQuery))).then(end),
    pathParts: dealPortalContentNotesControllerRfpDeletePathParts,
  };
}

export const dealPortalContentNotesControllerRfpDeleteCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_EmptyObjectC
} = { input: imported0_emptyObjectC, output: imported0_emptyObjectC };

export const dealPortalContentNotesControllerRfpDelete = (p: DealPortalContentNotesControllerRfpDeleteParams): UrlInterfaceIO<"POST", imported0_EmptyObjectC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalContentNotesControllerRfpDeleteRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalContentNotesControllerRfpDeleteCodecs);

const dealPortalContactSubmissionWithNotesControllerGetForOfferingPath = lit("v2").then(lit("deal-portal")).then(lit("contact-submissions")).then(lit("offering")).then(int("offeringId"));
const dealPortalContactSubmissionWithNotesControllerGetForOfferingPathParts = [
  "v2", "deal-portal", "contact-submissions", "offering", "offeringId"
] as const;
export type DealPortalContactSubmissionWithNotesControllerGetForOfferingPathParts = typeof dealPortalContactSubmissionWithNotesControllerGetForOfferingPathParts;

const dealPortalContactSubmissionWithNotesControllerGetForOfferingQuery = t.strict({});
export type DealPortalContactSubmissionWithNotesControllerGetForOfferingParams = Describe<typeof dealPortalContactSubmissionWithNotesControllerGetForOfferingPath._A & typeof dealPortalContactSubmissionWithNotesControllerGetForOfferingQuery._A>;

export type DealPortalContactSubmissionWithNotesControllerGetForOfferingRoute<A = {}> = { match: Match<DealPortalContactSubmissionWithNotesControllerGetForOfferingParams & A>; pathParts: DealPortalContactSubmissionWithNotesControllerGetForOfferingPathParts };

export function dealPortalContactSubmissionWithNotesControllerGetForOfferingRoute(): DealPortalContactSubmissionWithNotesControllerGetForOfferingRoute;
export function dealPortalContactSubmissionWithNotesControllerGetForOfferingRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalContactSubmissionWithNotesControllerGetForOfferingParams>): DealPortalContactSubmissionWithNotesControllerGetForOfferingRoute<t.TypeOf<A>>;
export function dealPortalContactSubmissionWithNotesControllerGetForOfferingRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalContactSubmissionWithNotesControllerGetForOfferingParams>) {
  return {
    match: (q ? dealPortalContactSubmissionWithNotesControllerGetForOfferingPath.then(query(t.intersection([dealPortalContactSubmissionWithNotesControllerGetForOfferingQuery, q]))) : dealPortalContactSubmissionWithNotesControllerGetForOfferingPath.then(query(dealPortalContactSubmissionWithNotesControllerGetForOfferingQuery))).then(end),
    pathParts: dealPortalContactSubmissionWithNotesControllerGetForOfferingPathParts,
  };
}

export const dealPortalContactSubmissionWithNotesControllerGetForOfferingCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported315_WithIdC<imported4_ContactFormSubmissionWithNoteC>>
} = {
  input: imported0_emptyObjectC,
  output: t.readonlyArray(imported315_withIdC(imported4_contactFormSubmissionWithNoteC))
};

export const dealPortalContactSubmissionWithNotesControllerGetForOffering = (p: DealPortalContactSubmissionWithNotesControllerGetForOfferingParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported315_WithIdC<imported4_ContactFormSubmissionWithNoteC>>> =>
  urlInterfaceIO("GET", dealPortalContactSubmissionWithNotesControllerGetForOfferingRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalContactSubmissionWithNotesControllerGetForOfferingCodecs);

const dealPortalContactSubmissionWithNotesControllerGetForRfpPath = lit("v2").then(lit("deal-portal")).then(lit("contact-submissions")).then(lit("rfp")).then(int("rfpId"));
const dealPortalContactSubmissionWithNotesControllerGetForRfpPathParts = [
  "v2", "deal-portal", "contact-submissions", "rfp", "rfpId"
] as const;
export type DealPortalContactSubmissionWithNotesControllerGetForRfpPathParts = typeof dealPortalContactSubmissionWithNotesControllerGetForRfpPathParts;

const dealPortalContactSubmissionWithNotesControllerGetForRfpQuery = t.strict({});
export type DealPortalContactSubmissionWithNotesControllerGetForRfpParams = Describe<typeof dealPortalContactSubmissionWithNotesControllerGetForRfpPath._A & typeof dealPortalContactSubmissionWithNotesControllerGetForRfpQuery._A>;

export type DealPortalContactSubmissionWithNotesControllerGetForRfpRoute<A = {}> = { match: Match<DealPortalContactSubmissionWithNotesControllerGetForRfpParams & A>; pathParts: DealPortalContactSubmissionWithNotesControllerGetForRfpPathParts };

export function dealPortalContactSubmissionWithNotesControllerGetForRfpRoute(): DealPortalContactSubmissionWithNotesControllerGetForRfpRoute;
export function dealPortalContactSubmissionWithNotesControllerGetForRfpRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalContactSubmissionWithNotesControllerGetForRfpParams>): DealPortalContactSubmissionWithNotesControllerGetForRfpRoute<t.TypeOf<A>>;
export function dealPortalContactSubmissionWithNotesControllerGetForRfpRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalContactSubmissionWithNotesControllerGetForRfpParams>) {
  return {
    match: (q ? dealPortalContactSubmissionWithNotesControllerGetForRfpPath.then(query(t.intersection([dealPortalContactSubmissionWithNotesControllerGetForRfpQuery, q]))) : dealPortalContactSubmissionWithNotesControllerGetForRfpPath.then(query(dealPortalContactSubmissionWithNotesControllerGetForRfpQuery))).then(end),
    pathParts: dealPortalContactSubmissionWithNotesControllerGetForRfpPathParts,
  };
}

export const dealPortalContactSubmissionWithNotesControllerGetForRfpCodecs: {
  input: imported0_EmptyObjectC,
  output: t.ReadonlyArrayC<imported316_WithIdC<imported5_ContactFormSubmissionWithNoteC>>
} = {
  input: imported0_emptyObjectC,
  output: t.readonlyArray(imported316_withIdC(imported5_contactFormSubmissionWithNoteC))
};

export const dealPortalContactSubmissionWithNotesControllerGetForRfp = (p: DealPortalContactSubmissionWithNotesControllerGetForRfpParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, t.ReadonlyArrayC<imported316_WithIdC<imported5_ContactFormSubmissionWithNoteC>>> =>
  urlInterfaceIO("GET", dealPortalContactSubmissionWithNotesControllerGetForRfpRoute().match.formatter.run(Route.empty, p).toString(true), dealPortalContactSubmissionWithNotesControllerGetForRfpCodecs);

const dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountPath = lit("v2").then(lit("deal-portal")).then(lit("contact-submissions")).then(lit("active-deals"));
const dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountPathParts = [
  "v2", "deal-portal", "contact-submissions", "active-deals"
] as const;
export type DealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountPathParts = typeof dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountPathParts;

const dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountQuery = t.strict({});
export type DealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountParams = Describe<typeof dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountPath._A & typeof dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountQuery._A>;

export type DealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountRoute<A = {}> = { match: Match<DealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountParams & A>; pathParts: DealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountPathParts };

export function dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountRoute(): DealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountRoute;
export function dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountParams>): DealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountRoute<t.TypeOf<A>>;
export function dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountParams>) {
  return {
    match: (q ? dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountPath.then(query(t.intersection([dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountQuery, q]))) : dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountPath.then(query(dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountQuery))).then(end),
    pathParts: dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountPathParts,
  };
}

export const dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountCodecs: {
  input: imported0_EmptyObjectC,
  output: imported0_ActiveDealsInfoRequestCountC
} = { input: imported0_emptyObjectC, output: imported0_activeDealsInfoRequestCountC };

export const dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCount = (): UrlInterfaceIO<"GET", imported0_EmptyObjectC, imported0_ActiveDealsInfoRequestCountC> =>
  urlInterfaceIO("GET", dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalContactSubmissionWithNotesControllerActiveDealsInfoRequestCountCodecs);

const dealPortalContactSubmissionWithNotesControllerPostPath = lit("v2").then(lit("deal-portal")).then(lit("contact-submissions"));
const dealPortalContactSubmissionWithNotesControllerPostPathParts = [
  "v2", "deal-portal", "contact-submissions"
] as const;
export type DealPortalContactSubmissionWithNotesControllerPostPathParts = typeof dealPortalContactSubmissionWithNotesControllerPostPathParts;

const dealPortalContactSubmissionWithNotesControllerPostQuery = t.strict({});
export type DealPortalContactSubmissionWithNotesControllerPostParams = Describe<typeof dealPortalContactSubmissionWithNotesControllerPostPath._A & typeof dealPortalContactSubmissionWithNotesControllerPostQuery._A>;

export type DealPortalContactSubmissionWithNotesControllerPostRoute<A = {}> = { match: Match<DealPortalContactSubmissionWithNotesControllerPostParams & A>; pathParts: DealPortalContactSubmissionWithNotesControllerPostPathParts };

export function dealPortalContactSubmissionWithNotesControllerPostRoute(): DealPortalContactSubmissionWithNotesControllerPostRoute;
export function dealPortalContactSubmissionWithNotesControllerPostRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DealPortalContactSubmissionWithNotesControllerPostParams>): DealPortalContactSubmissionWithNotesControllerPostRoute<t.TypeOf<A>>;
export function dealPortalContactSubmissionWithNotesControllerPostRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DealPortalContactSubmissionWithNotesControllerPostParams>) {
  return {
    match: (q ? dealPortalContactSubmissionWithNotesControllerPostPath.then(query(t.intersection([dealPortalContactSubmissionWithNotesControllerPostQuery, q]))) : dealPortalContactSubmissionWithNotesControllerPostPath.then(query(dealPortalContactSubmissionWithNotesControllerPostQuery))).then(end),
    pathParts: dealPortalContactSubmissionWithNotesControllerPostPathParts,
  };
}

export const dealPortalContactSubmissionWithNotesControllerPostCodecs: {
  input: imported0_ContentNoteForContactSubmissionPostC,
  output: imported0_EmptyObjectC
} = { input: imported0_contentNoteForContactSubmissionPostC, output: imported0_emptyObjectC };

export const dealPortalContactSubmissionWithNotesControllerPost = (): UrlInterfaceIO<"POST", imported0_ContentNoteForContactSubmissionPostC, imported0_EmptyObjectC> =>
  urlInterfaceIO("POST", dealPortalContactSubmissionWithNotesControllerPostRoute().match.formatter.run(Route.empty, {}).toString(true), dealPortalContactSubmissionWithNotesControllerPostCodecs);