import { WithStatusCU as imported611_WithStatusCU, WithStatusCU as imported612_WithStatusCU, WithStatusU as imported305_WithStatusU, WithModInfoC as imported185_WithModInfoC, withModInfoC as imported185_withModInfoC, WithStatusCU as imported610_WithStatusCU, WithId as imported244_WithId, withIdC as imported244_withIdC, WithIdC as imported244_WithIdC, WithModInfo as imported185_WithModInfo, WithStatusU as imported306_WithStatusU, WithStatusCU as imported613_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { TaggedContent as imported156_TaggedContent, TaggedContent as imported155_TaggedContent, TaggedContent as imported157_TaggedContent, taggedContentC as imported157_taggedContentC, TaggedContentC as imported157_TaggedContentC, TaggedContentC as imported155_TaggedContentC, TaggedContentC as imported156_TaggedContentC, taggedContentC as imported155_taggedContentC, taggedContentC as imported156_taggedContentC } from "./taggedContent";
import { RoadShowDataC as imported26_RoadShowDataC, RoadShowData as imported26_RoadShowData, roadShowDataC as imported26_roadShowDataC } from "./roadshow";
import { BondOfferingC as imported23_BondOfferingC, BondOffering as imported23_BondOffering, bondOfferingC as imported23_bondOfferingC } from "./bondOfferingBase";
import { RfpC as imported63_RfpC, Rfp as imported63_Rfp, rfpC as imported60_rfpC } from "./rfpBase";
import { ReadonlyMapFromEntriesC, readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";

export type RoadShowsListC = t.TypeC<{
  shows: t.ReadonlyArrayC<imported244_WithIdC<imported185_WithModInfoC<imported155_TaggedContentC<imported26_RoadShowDataC>>>>,
  offerings: ReadonlyMapFromEntriesC<t.NumberC, t.ReadonlyArrayC<imported612_WithStatusCU<imported156_TaggedContentC<imported23_BondOfferingC>>>>,
  rfps: ReadonlyMapFromEntriesC<t.NumberC, t.ReadonlyArrayC<imported613_WithStatusCU<imported157_TaggedContentC<imported63_RfpC>>>>
}>;
export type RoadShowsList = {
  shows: ReadonlyArray<imported244_WithId<imported185_WithModInfo<imported155_TaggedContent<imported26_RoadShowData>>>>,
  offerings: ReadonlyMap<number, ReadonlyArray<imported305_WithStatusU<imported156_TaggedContent<imported23_BondOffering>>>>,
  rfps: ReadonlyMap<number, ReadonlyArray<imported306_WithStatusU<imported157_TaggedContent<imported63_Rfp>>>>
};
export const roadShowsListC: RoadShowsListC = t.type({
  shows: t.readonlyArray(imported244_withIdC(imported185_withModInfoC(imported155_taggedContentC(imported26_roadShowDataC)))),
  offerings: readonlyMapFromEntries(t.number, numberOrd, t.readonlyArray(imported610_WithStatusCU(imported156_taggedContentC(imported23_bondOfferingC)))),
  rfps: readonlyMapFromEntries(t.number, numberOrd, t.readonlyArray(imported611_WithStatusCU(imported157_taggedContentC(imported60_rfpC))))
}) satisfies t.Type<RoadShowsList, unknown>;


