import * as t from "io-ts";
import { SubscribedC as imported29_SubscribedC, Subscribed as imported29_Subscribed, subscribedC as imported29_subscribedC } from "./subscribed";
import { RfpC as imported65_RfpC, Rfp as imported65_Rfp, rfpC as imported62_rfpC } from "./rfpBase";
import { StateInfoCU as imported15_StateInfoCU, StateInfoU as imported7_StateInfoU, StateInfoCU as imported14_StateInfoCU } from "../domaintables/states";
import { BondOfferingDetailsC as imported41_BondOfferingDetailsC, BondOfferingDetails as imported41_BondOfferingDetails, bondOfferingDetailsC as imported41_bondOfferingDetailsC } from "./bondOffering";
import { WithIdC as imported251_WithIdC, withIdC as imported251_withIdC, WithIdC as imported250_WithIdC, WithId as imported250_WithId, WithId as imported251_WithId, withIdC as imported250_withIdC } from "./threadThrough";
import { WithIssuerC as imported11_WithIssuerC, WithIssuerC as imported10_WithIssuerC, IssuerWithAddressC as imported5_IssuerWithAddressC, WithIssuer as imported10_WithIssuer, withIssuerC as imported11_withIssuerC, issuerWithAddressC as imported5_issuerWithAddressC, issuerC as imported47_issuerC, IssuerC as imported51_IssuerC, withIssuerC as imported10_withIssuerC, WithIssuer as imported11_WithIssuer, IssuerWithAddress as imported5_IssuerWithAddress, Issuer as imported51_Issuer } from "./issuer";

export type WatchlistIssuerC = t.TypeC<{
  issuer: imported29_SubscribedC<imported5_IssuerWithAddressC>,
  offerings: t.NumberC,
  rfps: t.NumberC
}>;
export type WatchlistIssuer = {
  issuer: imported29_Subscribed<imported5_IssuerWithAddress>,
  offerings: number,
  rfps: number
};
export const watchlistIssuerC: WatchlistIssuerC = t.type({
  issuer: imported29_subscribedC(imported5_issuerWithAddressC),
  offerings: t.number,
  rfps: t.number
}) satisfies t.Type<WatchlistIssuer, unknown>;


export type WatchlistStateDataC = t.TypeC<{
  state: imported15_StateInfoCU,
  issuer: t.ReadonlyArrayC<imported51_IssuerC>,
  offerings: t.ReadonlyArrayC<imported10_WithIssuerC<imported250_WithIdC<imported41_BondOfferingDetailsC>>>,
  rfps: t.ReadonlyArrayC<imported11_WithIssuerC<imported251_WithIdC<imported65_RfpC>>>
}>;
export type WatchlistStateData = {
  state: imported7_StateInfoU,
  issuer: ReadonlyArray<imported51_Issuer>,
  offerings: ReadonlyArray<imported10_WithIssuer<imported250_WithId<imported41_BondOfferingDetails>>>,
  rfps: ReadonlyArray<imported11_WithIssuer<imported251_WithId<imported65_Rfp>>>
};
export const watchlistStateDataC: WatchlistStateDataC = t.type({
  state: imported14_StateInfoCU,
  issuer: t.readonlyArray(imported47_issuerC),
  offerings: t.readonlyArray(imported10_withIssuerC(imported250_withIdC(imported41_bondOfferingDetailsC))),
  rfps: t.readonlyArray(imported11_withIssuerC(imported251_withIdC(imported62_rfpC)))
}) satisfies t.Type<WatchlistStateData, unknown>;


