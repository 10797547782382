import { sequenceS } from "fp-ts/lib/Apply";
import { pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { fold, none, some } from "fp-ts/lib/Option";
import type { TaskEither } from "fp-ts/lib/TaskEither";
import { bimap as bimapT } from "fp-ts/lib/These";
import type * as t from "io-ts";

import type { BLConfigWithLog } from "@scripts/bondlink";
import type { FetchParsedResp, RespOrErrors } from "@scripts/fetch";
import { fetchJson, fetchJsonUnsafe, handleFetchedResp } from "@scripts/fetch";
import type { Ap } from "@scripts/fp-ts";
import { TE } from "@scripts/fp-ts";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { Method, UrlInterface, UrlInterfaceIO } from "@scripts/routes/urlInterface";
import { logErrors } from "@scripts/util/log";

export type ApiData<A> = { resp: Response, data: A };
export type ApiRespData<A> = TaskEither<RespOrErrors, ApiData<A>>;
export type OnError = (resp: RespOrErrors) => void;
export type OnSuccess<A> = (resp: ApiData<A>) => void;
export type TEWithEffect<E, A> = (onError: (err: E) => void, onSuccess: (resp: A) => void) => TE.TaskEither<E, A>;
export type TERespWithEffect<A> = (onError: (resp: RespOrErrors) => void, onSuccess: (resp: ApiData<A>) => void) => TaskEither<RespOrErrors, ApiData<A>>;
export type ErrorHandlerApiReq<A> = TEWithEffect<RespOrErrors, ApiData<A>>;

type MultiApiRespData<A> = { [K in keyof A]: ApiRespData<A[K]> };
type MultiApiData<A> = { [K in keyof A]: ApiData<A[K]> };

export const mergeApiData = <A extends object>(data: MultiApiData<A>): ApiData<A> => {
  const entries = Object.entries<ApiData<A[keyof A]>>(data);
  const initialData: ApiData<A> = {
    resp: (entries[0]?.[1].resp) ?? new Response(),
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    data: {} as A,
  };

  return entries.reduce((acc: ApiData<A>, [k, val]) => ({
    resp: acc.resp.ok ? val.resp : acc.resp,
    data: {
      ...acc.data,
      [k]: val.data,
    },
  }),
    initialData);
};

const failureResp = (config: BLConfigWithLog) => (e: { tpe: string, url: UrlInterface<Method>, data?: unknown }):
  (or: Option<Response>) => Option<Response> => fold(
    () => {
      config.log.error(`API ${e.url.method} Request Failed`, e);
      return none;
    },
    (r: Response) => {
      config.log.error(`API ${e.url.method} Request Failed`, Object.assign({}, e, { response: r }));
      return some(r);
    }
  );

const toApiResp = (config: BLConfigWithLog) =>
  <A, I, O>(url: UrlInterfaceIO<Method, unknown, t.Type<A, O, I>>): (r: FetchParsedResp<A>) => ApiRespData<A> =>
    TE.bimap(
      (re: RespOrErrors) => {
        const errors = { tpe: url.output.name, url: url };
        bimapT(
          () => failureResp(config)(errors),
          logErrors
        )(re);
        return re;
      },
      (ra: [Response, A]) => ({ resp: ra[0], data: ra[1] })
    );

export const _apiFetchUnsafe = (config: BLConfigWithLog) =>
  <A, O, I>(url: UrlInterfaceIO<"GET", unknown, t.Type<A, O, I>>, opts?: RequestInit): ApiRespData<A> =>
    pipe(
      fetchJson(config)(url.output, `Fetching ${url.url}`)(url, opts),
      toApiResp(config)(url),
    );

export const _apiFetchWithCredUnsafe =
  (config: BLConfigWithLog) =>
    <A, O, I>(url: UrlInterfaceIO<"POST" | "DELETE", t.Type<A, O, I>, unknown>) => (data: A): TaskEither<RespOrErrors, ApiData<A>> => {
      return pipe(
        fetchJsonUnsafe(config)(url.input.encode(data))(url),
        handleFetchedResp(url.input, `Fetching ${url.url}`),
        TE.bimap(
          (re: RespOrErrors) => { failureResp(config)({ tpe: url.input.name, url, data }); return re; },
          (ra: [Response, A]) => ({ resp: ra[0], data: ra[1] })
        ),
      );
    };

export type ApiFetchWithCredRespUnsafe =
  <A extends t.Mixed, R extends t.Mixed>(url: UrlInterfaceIO<"POST", A, R>, opts?: RequestInit) =>
    (data: t.TypeOf<A>) => TaskEither<RespOrErrors, ApiData<t.TypeOf<R>>>;

export const _apiFetchWithCredRespUnsafe =
  (config: BLConfigWithLog): ApiFetchWithCredRespUnsafe =>
    <A extends t.Mixed, R extends t.Mixed>(url: UrlInterfaceIO<"POST", A, R>, opts?: RequestInit) =>
      (data: t.TypeOf<A>): ApiRespData<t.TypeOf<R>> =>
        pipe(
          fetchJsonUnsafe(config)(url.input.encode(data))(url, opts),
          handleFetchedResp(url.output, `Fetching ${url.url}`),
          toApiResp(config)(url)
        );

const headerFromIssuer = (issuer: Issuer): RequestInit =>
  ({ headers: { "BL-IssuerId": String(issuer.id) } });

const _apiFetchWithSuccessAndError = <A>(data: ApiRespData<A>): TERespWithEffect<A> =>
  (onError: (resp: RespOrErrors) => void, onSuccess: (resp: ApiData<A>) => void): TaskEither<RespOrErrors, ApiData<A>> => pipe(
    data,
    TE.biTap(onError, onSuccess)
  );

export const apiFetch = (config: BLConfigWithLog) =>
  <A, O, I>(url: UrlInterfaceIO<"GET", unknown, t.Type<A, O, I>>, opts?: RequestInit): TERespWithEffect<A> =>
    _apiFetchWithSuccessAndError(_apiFetchUnsafe(config)(url, opts));

export const apiFetchWithCred =
  (config: BLConfigWithLog) =>
    <A, O, I>(url: UrlInterfaceIO<"POST" | "DELETE", t.Type<A, O, I>, unknown>) => (data: A): TERespWithEffect<A> =>
      _apiFetchWithSuccessAndError(_apiFetchWithCredUnsafe(config)(url)(data));

export type ApiFetchWithCredResp =
  <A extends t.Mixed, R extends t.Mixed>(url: UrlInterfaceIO<"POST", A, R>, opts?: RequestInit) =>
    (data: t.TypeOf<A>) =>
      (onError: (resp: RespOrErrors) => void, onSuccess: (resp: ApiData<t.TypeOf<A>>) => void) =>
        ApiRespData<t.TypeOf<R>>;

export const apiFetchWithCredResp = (config: BLConfigWithLog) =>
  <A extends t.Mixed, R extends t.Mixed>(url: UrlInterfaceIO<"POST", A, R>, opts?: RequestInit) =>
    (data: t.TypeOf<A>): TERespWithEffect<t.TypeOf<R>> =>
      _apiFetchWithSuccessAndError(_apiFetchWithCredRespUnsafe(config)(url, opts)(data));

export type FetchIssuer = (issuer: Issuer) =>
  <A extends t.Mixed, R extends t.Mixed>(url: UrlInterfaceIO<"POST", A, R>) =>
    (data: t.TypeOf<A>) => TERespWithEffect<t.TypeOf<R>>;

export const fetchIssuer =
  (config: BLConfigWithLog): FetchIssuer =>
    (issuer) => (url) => apiFetchWithCredResp(config)(url, headerFromIssuer(issuer));

export const makeBatchedErrorHandlingRequest = <
  RespType extends object,
  >(requests: Ap.EnforceNonEmptyRecord<MultiApiRespData<RespType>>): TERespWithEffect<RespType> =>
  (onError: OnError, onSuccess: OnSuccess<RespType>): TaskEither<RespOrErrors, ApiData<RespType>> => pipe(
    sequenceS(TE.ApplicativePar)(requests) as TaskEither<RespOrErrors, MultiApiData<RespType>>, // eslint-disable-line @typescript-eslint/consistent-type-assertions
    TE.map(mergeApiData),
    TE.biTap(onError, onSuccess)
  );
