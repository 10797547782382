import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { issuerRatingC as imported11_issuerRatingC, programRatingC as imported11_programRatingC, ProgramRating as imported14_ProgramRating, IssuerRating as imported14_IssuerRating, IssuerRatingC as imported14_IssuerRatingC, ProgramRatingC as imported14_ProgramRatingC } from "./ratingBase";
import { RatingAgencyCU as imported61_RatingAgencyCU, RatingAgencyU as imported30_RatingAgencyU, RatingAgencyCU as imported63_RatingAgencyCU, RatingAgencyU as imported31_RatingAgencyU, RatingAgencyCU as imported60_RatingAgencyCU, RatingAgencyCU as imported62_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { MediaC as imported49_MediaC, Media as imported49_Media, mediaC as imported46_mediaC } from "./media";
import * as O from "fp-ts/lib/Option";
import { BondProgramC as imported18_BondProgramC, BondProgram as imported18_BondProgram, bondProgramC as imported14_bondProgramC } from "./bondProgramBase";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { WithStatusU as imported223_WithStatusU, WithStatusU as imported222_WithStatusU, WithStatusCU as imported446_WithStatusCU, withIdC as imported209_withIdC, WithStatusCU as imported444_WithStatusCU, WithStatusCU as imported447_WithStatusCU, WithStatusCU as imported445_WithStatusCU, WithIdC as imported209_WithIdC, WithId as imported209_WithId } from "./threadThrough";
import { IssuerC as imported38_IssuerC, Issuer as imported38_Issuer, issuerC as imported35_issuerC } from "./issuer";

export type IssuerOverviewCardC = t.TypeC<{
  issuer: imported38_IssuerC,
  offerings: t.NumberC,
  issuerRatings: t.ReadonlyArrayC<t.TupleC<[imported62_RatingAgencyCU, ReadonlyNonEmptyArrayType<imported446_WithStatusCU<imported14_IssuerRatingC>>]>>,
  programRatings: t.ReadonlyArrayC<t.TupleC<[imported209_WithIdC<imported18_BondProgramC>, ReadonlyNonEmptyArrayType<t.TupleC<[imported63_RatingAgencyCU, ReadonlyNonEmptyArrayType<imported447_WithStatusCU<imported14_ProgramRatingC>>]>>]>>,
  subscribed: t.BooleanC,
  media: OptionFromNullableC<imported49_MediaC>,
  outstandingDebt: OptionFromNullableC<t.NumberC>
}>;
export type IssuerOverviewCard = {
  issuer: imported38_Issuer,
  offerings: number,
  issuerRatings: ReadonlyArray<[imported30_RatingAgencyU, RNEA.ReadonlyNonEmptyArray<imported222_WithStatusU<imported14_IssuerRating>>]>,
  programRatings: ReadonlyArray<[imported209_WithId<imported18_BondProgram>, RNEA.ReadonlyNonEmptyArray<[imported31_RatingAgencyU, RNEA.ReadonlyNonEmptyArray<imported223_WithStatusU<imported14_ProgramRating>>]>]>,
  subscribed: boolean,
  media: O.Option<imported49_Media>,
  outstandingDebt: O.Option<number>
};
export const issuerOverviewCardC: IssuerOverviewCardC = t.type({
  issuer: imported35_issuerC,
  offerings: t.number,
  issuerRatings: t.readonlyArray(t.tuple([imported60_RatingAgencyCU, readonlyNonEmptyArrayC(imported444_WithStatusCU(imported11_issuerRatingC))])),
  programRatings: t.readonlyArray(t.tuple([imported209_withIdC(imported14_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported61_RatingAgencyCU, readonlyNonEmptyArrayC(imported445_WithStatusCU(imported11_programRatingC))]))])),
  subscribed: t.boolean,
  media: optionFromNullable(imported46_mediaC),
  outstandingDebt: optionFromNullable(t.number)
}) satisfies t.Type<IssuerOverviewCard, unknown>;


export type IssuerOverviewCardsC = t.TypeC<{
  cards: t.ReadonlyArrayC<IssuerOverviewCardC>,
  nextPage: t.BooleanC
}>;
export type IssuerOverviewCards = {
  cards: ReadonlyArray<IssuerOverviewCard>,
  nextPage: boolean
};
export const issuerOverviewCardsC: IssuerOverviewCardsC = t.type({
  cards: t.readonlyArray(issuerOverviewCardC),
  nextPage: t.boolean
}) satisfies t.Type<IssuerOverviewCards, unknown>;


