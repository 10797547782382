import { IssuerC as imported37_IssuerC, Issuer as imported37_Issuer, issuerC as imported34_issuerC } from "./issuer";
import * as t from "io-ts";
import { AddressC as imported7_AddressC, Address as imported7_Address, addressC as imported6_addressC } from "./address";
import { BondOffering as imported18_BondOffering, bondOfferingC as imported18_bondOfferingC, BondOffering as imported19_BondOffering, BondOfferingC as imported19_BondOfferingC, bondOfferingC as imported19_bondOfferingC, BondOfferingC as imported18_BondOfferingC } from "./bondOfferingBase";
import { RfpC as imported50_RfpC, rfpC as imported48_rfpC, RfpC as imported51_RfpC, Rfp as imported50_Rfp, Rfp as imported51_Rfp, rfpC as imported49_rfpC } from "./rfpBase";

export type IssuerWithDealsC = t.TypeC<{
  issuer: imported37_IssuerC,
  address: imported7_AddressC,
  activeOfferings: t.ReadonlyArrayC<imported18_BondOfferingC>,
  archivedOfferings: t.ReadonlyArrayC<imported19_BondOfferingC>,
  activeRfps: t.ReadonlyArrayC<imported50_RfpC>,
  archivedRfps: t.ReadonlyArrayC<imported51_RfpC>
}>;
export type IssuerWithDeals = {
  issuer: imported37_Issuer,
  address: imported7_Address,
  activeOfferings: ReadonlyArray<imported18_BondOffering>,
  archivedOfferings: ReadonlyArray<imported19_BondOffering>,
  activeRfps: ReadonlyArray<imported50_Rfp>,
  archivedRfps: ReadonlyArray<imported51_Rfp>
};
export const issuerWithDealsC: IssuerWithDealsC = t.type({
  issuer: imported34_issuerC,
  address: imported6_addressC,
  activeOfferings: t.readonlyArray(imported18_bondOfferingC),
  archivedOfferings: t.readonlyArray(imported19_bondOfferingC),
  activeRfps: t.readonlyArray(imported48_rfpC),
  archivedRfps: t.readonlyArray(imported49_rfpC)
}) satisfies t.Type<IssuerWithDeals, unknown>;


