import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const address = {
  _tag: `Address`,
  name: `Address`
} as const;

export type AddressTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Address`>
}>;
export const addressTaggedC: AddressTaggedC = t.type({
  _tag: t.literal(`Address`)
});
export type AddressTagged = t.TypeOf<AddressTaggedC>;
export type Address = AddressTagged & typeof address;
export type AddressC = t.Type<Address, AddressTagged>;
export const addressC: AddressC = pipe(addressTaggedC, c => new t.Type<Address, AddressTagged>(
  `Address`,
  (u: unknown): u is Address => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Address> => pipe(c.decode(u), E.map(x => ({ ...x, ...address }))),
  (x: Address): AddressTagged => ({ ...x, _tag: `Address`}),
)) satisfies t.Type<Address, unknown>;


export const media = {
  _tag: `Media`,
  name: `Media`
} as const;

export type MediaTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Media`>
}>;
export const mediaTaggedC: MediaTaggedC = t.type({
  _tag: t.literal(`Media`)
});
export type MediaTagged = t.TypeOf<MediaTaggedC>;
export type Media = MediaTagged & typeof media;
export type MediaC = t.Type<Media, MediaTagged>;
export const mediaC: MediaC = pipe(mediaTaggedC, c => new t.Type<Media, MediaTagged>(
  `Media`,
  (u: unknown): u is Media => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Media> => pipe(c.decode(u), E.map(x => ({ ...x, ...media }))),
  (x: Media): MediaTagged => ({ ...x, _tag: `Media`}),
)) satisfies t.Type<Media, unknown>;


export const emmaDoc = {
  _tag: `EmmaDoc`,
  name: `EmmaDocument`
} as const;

export type EmmaDocTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EmmaDoc`>
}>;
export const emmaDocTaggedC: EmmaDocTaggedC = t.type({
  _tag: t.literal(`EmmaDoc`)
});
export type EmmaDocTagged = t.TypeOf<EmmaDocTaggedC>;
export type EmmaDoc = EmmaDocTagged & typeof emmaDoc;
export type EmmaDocC = t.Type<EmmaDoc, EmmaDocTagged>;
export const emmaDocC: EmmaDocC = pipe(emmaDocTaggedC, c => new t.Type<EmmaDoc, EmmaDocTagged>(
  `EmmaDoc`,
  (u: unknown): u is EmmaDoc => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmmaDoc> => pipe(c.decode(u), E.map(x => ({ ...x, ...emmaDoc }))),
  (x: EmmaDoc): EmmaDocTagged => ({ ...x, _tag: `EmmaDoc`}),
)) satisfies t.Type<EmmaDoc, unknown>;


export const homepageImg = {
  _tag: `HomepageImg`,
  name: `HomepageImg`
} as const;

export type HomepageImgTaggedC = t.TypeC<{
  _tag: t.LiteralC<`HomepageImg`>
}>;
export const homepageImgTaggedC: HomepageImgTaggedC = t.type({
  _tag: t.literal(`HomepageImg`)
});
export type HomepageImgTagged = t.TypeOf<HomepageImgTaggedC>;
export type HomepageImg = HomepageImgTagged & typeof homepageImg;
export type HomepageImgC = t.Type<HomepageImg, HomepageImgTagged>;
export const homepageImgC: HomepageImgC = pipe(homepageImgTaggedC, c => new t.Type<HomepageImg, HomepageImgTagged>(
  `HomepageImg`,
  (u: unknown): u is HomepageImg => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HomepageImg> => pipe(c.decode(u), E.map(x => ({ ...x, ...homepageImg }))),
  (x: HomepageImg): HomepageImgTagged => ({ ...x, _tag: `HomepageImg`}),
)) satisfies t.Type<HomepageImg, unknown>;


export const calloutField = {
  _tag: `CalloutField`,
  name: `CalloutField`
} as const;

export type CalloutFieldTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CalloutField`>
}>;
export const calloutFieldTaggedC: CalloutFieldTaggedC = t.type({
  _tag: t.literal(`CalloutField`)
});
export type CalloutFieldTagged = t.TypeOf<CalloutFieldTaggedC>;
export type CalloutField = CalloutFieldTagged & typeof calloutField;
export type CalloutFieldC = t.Type<CalloutField, CalloutFieldTagged>;
export const calloutFieldC: CalloutFieldC = pipe(calloutFieldTaggedC, c => new t.Type<CalloutField, CalloutFieldTagged>(
  `CalloutField`,
  (u: unknown): u is CalloutField => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CalloutField> => pipe(c.decode(u), E.map(x => ({ ...x, ...calloutField }))),
  (x: CalloutField): CalloutFieldTagged => ({ ...x, _tag: `CalloutField`}),
)) satisfies t.Type<CalloutField, unknown>;


export const textItem = {
  _tag: `TextItem`,
  name: `TextItem`
} as const;

export type TextItemTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TextItem`>
}>;
export const textItemTaggedC: TextItemTaggedC = t.type({
  _tag: t.literal(`TextItem`)
});
export type TextItemTagged = t.TypeOf<TextItemTaggedC>;
export type TextItem = TextItemTagged & typeof textItem;
export type TextItemC = t.Type<TextItem, TextItemTagged>;
export const textItemC: TextItemC = pipe(textItemTaggedC, c => new t.Type<TextItem, TextItemTagged>(
  `TextItem`,
  (u: unknown): u is TextItem => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TextItem> => pipe(c.decode(u), E.map(x => ({ ...x, ...textItem }))),
  (x: TextItem): TextItemTagged => ({ ...x, _tag: `TextItem`}),
)) satisfies t.Type<TextItem, unknown>;


export const disclaimer = {
  _tag: `Disclaimer`,
  name: `Disclaimer`
} as const;

export type DisclaimerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Disclaimer`>
}>;
export const disclaimerTaggedC: DisclaimerTaggedC = t.type({
  _tag: t.literal(`Disclaimer`)
});
export type DisclaimerTagged = t.TypeOf<DisclaimerTaggedC>;
export type Disclaimer = DisclaimerTagged & typeof disclaimer;
export type DisclaimerC = t.Type<Disclaimer, DisclaimerTagged>;
export const disclaimerC: DisclaimerC = pipe(disclaimerTaggedC, c => new t.Type<Disclaimer, DisclaimerTagged>(
  `Disclaimer`,
  (u: unknown): u is Disclaimer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Disclaimer> => pipe(c.decode(u), E.map(x => ({ ...x, ...disclaimer }))),
  (x: Disclaimer): DisclaimerTagged => ({ ...x, _tag: `Disclaimer`}),
)) satisfies t.Type<Disclaimer, unknown>;


export const rating = {
  _tag: `Rating`,
  name: `Rating`
} as const;

export type RatingTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Rating`>
}>;
export const ratingTaggedC: RatingTaggedC = t.type({
  _tag: t.literal(`Rating`)
});
export type RatingTagged = t.TypeOf<RatingTaggedC>;
export type Rating = RatingTagged & typeof rating;
export type RatingC = t.Type<Rating, RatingTagged>;
export const ratingC: RatingC = pipe(ratingTaggedC, c => new t.Type<Rating, RatingTagged>(
  `Rating`,
  (u: unknown): u is Rating => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Rating> => pipe(c.decode(u), E.map(x => ({ ...x, ...rating }))),
  (x: Rating): RatingTagged => ({ ...x, _tag: `Rating`}),
)) satisfies t.Type<Rating, unknown>;


export const analyst = {
  _tag: `Analyst`,
  name: `Analyst`
} as const;

export type AnalystTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Analyst`>
}>;
export const analystTaggedC: AnalystTaggedC = t.type({
  _tag: t.literal(`Analyst`)
});
export type AnalystTagged = t.TypeOf<AnalystTaggedC>;
export type Analyst = AnalystTagged & typeof analyst;
export type AnalystC = t.Type<Analyst, AnalystTagged>;
export const analystC: AnalystC = pipe(analystTaggedC, c => new t.Type<Analyst, AnalystTagged>(
  `Analyst`,
  (u: unknown): u is Analyst => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Analyst> => pipe(c.decode(u), E.map(x => ({ ...x, ...analyst }))),
  (x: Analyst): AnalystTagged => ({ ...x, _tag: `Analyst`}),
)) satisfies t.Type<Analyst, unknown>;


export const cusip6 = {
  _tag: `Cusip6`,
  name: `Cusip6`
} as const;

export type Cusip6TaggedC = t.TypeC<{
  _tag: t.LiteralC<`Cusip6`>
}>;
export const cusip6TaggedC: Cusip6TaggedC = t.type({
  _tag: t.literal(`Cusip6`)
});
export type Cusip6Tagged = t.TypeOf<Cusip6TaggedC>;
export type Cusip6 = Cusip6Tagged & typeof cusip6;
export type Cusip6C = t.Type<Cusip6, Cusip6Tagged>;
export const cusip6C: Cusip6C = pipe(cusip6TaggedC, c => new t.Type<Cusip6, Cusip6Tagged>(
  `Cusip6`,
  (u: unknown): u is Cusip6 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip6> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip6 }))),
  (x: Cusip6): Cusip6Tagged => ({ ...x, _tag: `Cusip6`}),
)) satisfies t.Type<Cusip6, unknown>;


export const emma = {
  _tag: `Emma`,
  name: `Emma`
} as const;

export type EmmaTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Emma`>
}>;
export const emmaTaggedC: EmmaTaggedC = t.type({
  _tag: t.literal(`Emma`)
});
export type EmmaTagged = t.TypeOf<EmmaTaggedC>;
export type Emma = EmmaTagged & typeof emma;
export type EmmaC = t.Type<Emma, EmmaTagged>;
export const emmaC: EmmaC = pipe(emmaTaggedC, c => new t.Type<Emma, EmmaTagged>(
  `Emma`,
  (u: unknown): u is Emma => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Emma> => pipe(c.decode(u), E.map(x => ({ ...x, ...emma }))),
  (x: Emma): EmmaTagged => ({ ...x, _tag: `Emma`}),
)) satisfies t.Type<Emma, unknown>;


export const relatedIssuer = {
  _tag: `RelatedIssuer`,
  name: `RelatedIssuer`
} as const;

export type RelatedIssuerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`RelatedIssuer`>
}>;
export const relatedIssuerTaggedC: RelatedIssuerTaggedC = t.type({
  _tag: t.literal(`RelatedIssuer`)
});
export type RelatedIssuerTagged = t.TypeOf<RelatedIssuerTaggedC>;
export type RelatedIssuer = RelatedIssuerTagged & typeof relatedIssuer;
export type RelatedIssuerC = t.Type<RelatedIssuer, RelatedIssuerTagged>;
export const relatedIssuerC: RelatedIssuerC = pipe(relatedIssuerTaggedC, c => new t.Type<RelatedIssuer, RelatedIssuerTagged>(
  `RelatedIssuer`,
  (u: unknown): u is RelatedIssuer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RelatedIssuer> => pipe(c.decode(u), E.map(x => ({ ...x, ...relatedIssuer }))),
  (x: RelatedIssuer): RelatedIssuerTagged => ({ ...x, _tag: `RelatedIssuer`}),
)) satisfies t.Type<RelatedIssuer, unknown>;


export const calendar = {
  _tag: `Calendar`,
  name: `Calendar`
} as const;

export type CalendarTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Calendar`>
}>;
export const calendarTaggedC: CalendarTaggedC = t.type({
  _tag: t.literal(`Calendar`)
});
export type CalendarTagged = t.TypeOf<CalendarTaggedC>;
export type Calendar = CalendarTagged & typeof calendar;
export type CalendarC = t.Type<Calendar, CalendarTagged>;
export const calendarC: CalendarC = pipe(calendarTaggedC, c => new t.Type<Calendar, CalendarTagged>(
  `Calendar`,
  (u: unknown): u is Calendar => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Calendar> => pipe(c.decode(u), E.map(x => ({ ...x, ...calendar }))),
  (x: Calendar): CalendarTagged => ({ ...x, _tag: `Calendar`}),
)) satisfies t.Type<Calendar, unknown>;


export const news = {
  _tag: `News`,
  name: `News`
} as const;

export type NewsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`News`>
}>;
export const newsTaggedC: NewsTaggedC = t.type({
  _tag: t.literal(`News`)
});
export type NewsTagged = t.TypeOf<NewsTaggedC>;
export type News = NewsTagged & typeof news;
export type NewsC = t.Type<News, NewsTagged>;
export const newsC: NewsC = pipe(newsTaggedC, c => new t.Type<News, NewsTagged>(
  `News`,
  (u: unknown): u is News => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, News> => pipe(c.decode(u), E.map(x => ({ ...x, ...news }))),
  (x: News): NewsTagged => ({ ...x, _tag: `News`}),
)) satisfies t.Type<News, unknown>;


export const faq = {
  _tag: `Faq`,
  name: `Faq`
} as const;

export type FaqTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Faq`>
}>;
export const faqTaggedC: FaqTaggedC = t.type({
  _tag: t.literal(`Faq`)
});
export type FaqTagged = t.TypeOf<FaqTaggedC>;
export type Faq = FaqTagged & typeof faq;
export type FaqC = t.Type<Faq, FaqTagged>;
export const faqC: FaqC = pipe(faqTaggedC, c => new t.Type<Faq, FaqTagged>(
  `Faq`,
  (u: unknown): u is Faq => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Faq> => pipe(c.decode(u), E.map(x => ({ ...x, ...faq }))),
  (x: Faq): FaqTagged => ({ ...x, _tag: `Faq`}),
)) satisfies t.Type<Faq, unknown>;


export const href = {
  _tag: `Href`,
  name: `Href`
} as const;

export type HrefTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Href`>
}>;
export const hrefTaggedC: HrefTaggedC = t.type({
  _tag: t.literal(`Href`)
});
export type HrefTagged = t.TypeOf<HrefTaggedC>;
export type Href = HrefTagged & typeof href;
export type HrefC = t.Type<Href, HrefTagged>;
export const hrefC: HrefC = pipe(hrefTaggedC, c => new t.Type<Href, HrefTagged>(
  `Href`,
  (u: unknown): u is Href => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Href> => pipe(c.decode(u), E.map(x => ({ ...x, ...href }))),
  (x: Href): HrefTagged => ({ ...x, _tag: `Href`}),
)) satisfies t.Type<Href, unknown>;


export const issuerOfficer = {
  _tag: `IssuerOfficer`,
  name: `IssuerOfficer`
} as const;

export type IssuerOfficerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerOfficer`>
}>;
export const issuerOfficerTaggedC: IssuerOfficerTaggedC = t.type({
  _tag: t.literal(`IssuerOfficer`)
});
export type IssuerOfficerTagged = t.TypeOf<IssuerOfficerTaggedC>;
export type IssuerOfficer = IssuerOfficerTagged & typeof issuerOfficer;
export type IssuerOfficerC = t.Type<IssuerOfficer, IssuerOfficerTagged>;
export const issuerOfficerC: IssuerOfficerC = pipe(issuerOfficerTaggedC, c => new t.Type<IssuerOfficer, IssuerOfficerTagged>(
  `IssuerOfficer`,
  (u: unknown): u is IssuerOfficer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerOfficer> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerOfficer }))),
  (x: IssuerOfficer): IssuerOfficerTagged => ({ ...x, _tag: `IssuerOfficer`}),
)) satisfies t.Type<IssuerOfficer, unknown>;


export const paymentToken = {
  _tag: `PaymentToken`,
  name: `PaymentToken`
} as const;

export type PaymentTokenTaggedC = t.TypeC<{
  _tag: t.LiteralC<`PaymentToken`>
}>;
export const paymentTokenTaggedC: PaymentTokenTaggedC = t.type({
  _tag: t.literal(`PaymentToken`)
});
export type PaymentTokenTagged = t.TypeOf<PaymentTokenTaggedC>;
export type PaymentToken = PaymentTokenTagged & typeof paymentToken;
export type PaymentTokenC = t.Type<PaymentToken, PaymentTokenTagged>;
export const paymentTokenC: PaymentTokenC = pipe(paymentTokenTaggedC, c => new t.Type<PaymentToken, PaymentTokenTagged>(
  `PaymentToken`,
  (u: unknown): u is PaymentToken => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PaymentToken> => pipe(c.decode(u), E.map(x => ({ ...x, ...paymentToken }))),
  (x: PaymentToken): PaymentTokenTagged => ({ ...x, _tag: `PaymentToken`}),
)) satisfies t.Type<PaymentToken, unknown>;


export const project = {
  _tag: `Project`,
  name: `Project`
} as const;

export type ProjectTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Project`>
}>;
export const projectTaggedC: ProjectTaggedC = t.type({
  _tag: t.literal(`Project`)
});
export type ProjectTagged = t.TypeOf<ProjectTaggedC>;
export type Project = ProjectTagged & typeof project;
export type ProjectC = t.Type<Project, ProjectTagged>;
export const projectC: ProjectC = pipe(projectTaggedC, c => new t.Type<Project, ProjectTagged>(
  `Project`,
  (u: unknown): u is Project => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Project> => pipe(c.decode(u), E.map(x => ({ ...x, ...project }))),
  (x: Project): ProjectTagged => ({ ...x, _tag: `Project`}),
)) satisfies t.Type<Project, unknown>;


export const invoice = {
  _tag: `Invoice`,
  name: `Invoice`
} as const;

export type InvoiceTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Invoice`>
}>;
export const invoiceTaggedC: InvoiceTaggedC = t.type({
  _tag: t.literal(`Invoice`)
});
export type InvoiceTagged = t.TypeOf<InvoiceTaggedC>;
export type Invoice = InvoiceTagged & typeof invoice;
export type InvoiceC = t.Type<Invoice, InvoiceTagged>;
export const invoiceC: InvoiceC = pipe(invoiceTaggedC, c => new t.Type<Invoice, InvoiceTagged>(
  `Invoice`,
  (u: unknown): u is Invoice => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Invoice> => pipe(c.decode(u), E.map(x => ({ ...x, ...invoice }))),
  (x: Invoice): InvoiceTagged => ({ ...x, _tag: `Invoice`}),
)) satisfies t.Type<Invoice, unknown>;


export const offeringParticipant = {
  _tag: `OfferingParticipant`,
  name: `OfferingParticipant`
} as const;

export type OfferingParticipantTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OfferingParticipant`>
}>;
export const offeringParticipantTaggedC: OfferingParticipantTaggedC = t.type({
  _tag: t.literal(`OfferingParticipant`)
});
export type OfferingParticipantTagged = t.TypeOf<OfferingParticipantTaggedC>;
export type OfferingParticipant = OfferingParticipantTagged & typeof offeringParticipant;
export type OfferingParticipantC = t.Type<OfferingParticipant, OfferingParticipantTagged>;
export const offeringParticipantC: OfferingParticipantC = pipe(offeringParticipantTaggedC, c => new t.Type<OfferingParticipant, OfferingParticipantTagged>(
  `OfferingParticipant`,
  (u: unknown): u is OfferingParticipant => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferingParticipant> => pipe(c.decode(u), E.map(x => ({ ...x, ...offeringParticipant }))),
  (x: OfferingParticipant): OfferingParticipantTagged => ({ ...x, _tag: `OfferingParticipant`}),
)) satisfies t.Type<OfferingParticipant, unknown>;


export const offeringData = {
  _tag: `OfferingData`,
  name: `OfferingData`
} as const;

export type OfferingDataTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OfferingData`>
}>;
export const offeringDataTaggedC: OfferingDataTaggedC = t.type({
  _tag: t.literal(`OfferingData`)
});
export type OfferingDataTagged = t.TypeOf<OfferingDataTaggedC>;
export type OfferingData = OfferingDataTagged & typeof offeringData;
export type OfferingDataC = t.Type<OfferingData, OfferingDataTagged>;
export const offeringDataC: OfferingDataC = pipe(offeringDataTaggedC, c => new t.Type<OfferingData, OfferingDataTagged>(
  `OfferingData`,
  (u: unknown): u is OfferingData => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferingData> => pipe(c.decode(u), E.map(x => ({ ...x, ...offeringData }))),
  (x: OfferingData): OfferingDataTagged => ({ ...x, _tag: `OfferingData`}),
)) satisfies t.Type<OfferingData, unknown>;


export const offeringSeries = {
  _tag: `OfferingSeries`,
  name: `OfferingSeries`
} as const;

export type OfferingSeriesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`OfferingSeries`>
}>;
export const offeringSeriesTaggedC: OfferingSeriesTaggedC = t.type({
  _tag: t.literal(`OfferingSeries`)
});
export type OfferingSeriesTagged = t.TypeOf<OfferingSeriesTaggedC>;
export type OfferingSeries = OfferingSeriesTagged & typeof offeringSeries;
export type OfferingSeriesC = t.Type<OfferingSeries, OfferingSeriesTagged>;
export const offeringSeriesC: OfferingSeriesC = pipe(offeringSeriesTaggedC, c => new t.Type<OfferingSeries, OfferingSeriesTagged>(
  `OfferingSeries`,
  (u: unknown): u is OfferingSeries => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferingSeries> => pipe(c.decode(u), E.map(x => ({ ...x, ...offeringSeries }))),
  (x: OfferingSeries): OfferingSeriesTagged => ({ ...x, _tag: `OfferingSeries`}),
)) satisfies t.Type<OfferingSeries, unknown>;


export const siteTemplate = {
  _tag: `SiteTemplate`,
  name: `SiteTemplate`
} as const;

export type SiteTemplateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`SiteTemplate`>
}>;
export const siteTemplateTaggedC: SiteTemplateTaggedC = t.type({
  _tag: t.literal(`SiteTemplate`)
});
export type SiteTemplateTagged = t.TypeOf<SiteTemplateTaggedC>;
export type SiteTemplate = SiteTemplateTagged & typeof siteTemplate;
export type SiteTemplateC = t.Type<SiteTemplate, SiteTemplateTagged>;
export const siteTemplateC: SiteTemplateC = pipe(siteTemplateTaggedC, c => new t.Type<SiteTemplate, SiteTemplateTagged>(
  `SiteTemplate`,
  (u: unknown): u is SiteTemplate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SiteTemplate> => pipe(c.decode(u), E.map(x => ({ ...x, ...siteTemplate }))),
  (x: SiteTemplate): SiteTemplateTagged => ({ ...x, _tag: `SiteTemplate`}),
)) satisfies t.Type<SiteTemplate, unknown>;


export const headerPhoto = {
  _tag: `HeaderPhoto`,
  name: `HeaderPhoto`
} as const;

export type HeaderPhotoTaggedC = t.TypeC<{
  _tag: t.LiteralC<`HeaderPhoto`>
}>;
export const headerPhotoTaggedC: HeaderPhotoTaggedC = t.type({
  _tag: t.literal(`HeaderPhoto`)
});
export type HeaderPhotoTagged = t.TypeOf<HeaderPhotoTaggedC>;
export type HeaderPhoto = HeaderPhotoTagged & typeof headerPhoto;
export type HeaderPhotoC = t.Type<HeaderPhoto, HeaderPhotoTagged>;
export const headerPhotoC: HeaderPhotoC = pipe(headerPhotoTaggedC, c => new t.Type<HeaderPhoto, HeaderPhotoTagged>(
  `HeaderPhoto`,
  (u: unknown): u is HeaderPhoto => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HeaderPhoto> => pipe(c.decode(u), E.map(x => ({ ...x, ...headerPhoto }))),
  (x: HeaderPhoto): HeaderPhotoTagged => ({ ...x, _tag: `HeaderPhoto`}),
)) satisfies t.Type<HeaderPhoto, unknown>;


export const marketInsight = {
  _tag: `MarketInsight`,
  name: `MarketInsight`
} as const;

export type MarketInsightTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MarketInsight`>
}>;
export const marketInsightTaggedC: MarketInsightTaggedC = t.type({
  _tag: t.literal(`MarketInsight`)
});
export type MarketInsightTagged = t.TypeOf<MarketInsightTaggedC>;
export type MarketInsight = MarketInsightTagged & typeof marketInsight;
export type MarketInsightC = t.Type<MarketInsight, MarketInsightTagged>;
export const marketInsightC: MarketInsightC = pipe(marketInsightTaggedC, c => new t.Type<MarketInsight, MarketInsightTagged>(
  `MarketInsight`,
  (u: unknown): u is MarketInsight => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MarketInsight> => pipe(c.decode(u), E.map(x => ({ ...x, ...marketInsight }))),
  (x: MarketInsight): MarketInsightTagged => ({ ...x, _tag: `MarketInsight`}),
)) satisfies t.Type<MarketInsight, unknown>;


export const nonIceDebtInstrument = {
  _tag: `NonIceDebtInstrument`,
  name: `NonIceDebtInstrument`
} as const;

export type NonIceDebtInstrumentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`NonIceDebtInstrument`>
}>;
export const nonIceDebtInstrumentTaggedC: NonIceDebtInstrumentTaggedC = t.type({
  _tag: t.literal(`NonIceDebtInstrument`)
});
export type NonIceDebtInstrumentTagged = t.TypeOf<NonIceDebtInstrumentTaggedC>;
export type NonIceDebtInstrument = NonIceDebtInstrumentTagged & typeof nonIceDebtInstrument;
export type NonIceDebtInstrumentC = t.Type<NonIceDebtInstrument, NonIceDebtInstrumentTagged>;
export const nonIceDebtInstrumentC: NonIceDebtInstrumentC = pipe(nonIceDebtInstrumentTaggedC, c => new t.Type<NonIceDebtInstrument, NonIceDebtInstrumentTagged>(
  `NonIceDebtInstrument`,
  (u: unknown): u is NonIceDebtInstrument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NonIceDebtInstrument> => pipe(c.decode(u), E.map(x => ({ ...x, ...nonIceDebtInstrument }))),
  (x: NonIceDebtInstrument): NonIceDebtInstrumentTagged => ({ ...x, _tag: `NonIceDebtInstrument`}),
)) satisfies t.Type<NonIceDebtInstrument, unknown>;


export const esgImg = {
  _tag: `EsgImg`,
  name: `EsgImg`
} as const;

export type EsgImgTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EsgImg`>
}>;
export const esgImgTaggedC: EsgImgTaggedC = t.type({
  _tag: t.literal(`EsgImg`)
});
export type EsgImgTagged = t.TypeOf<EsgImgTaggedC>;
export type EsgImg = EsgImgTagged & typeof esgImg;
export type EsgImgC = t.Type<EsgImg, EsgImgTagged>;
export const esgImgC: EsgImgC = pipe(esgImgTaggedC, c => new t.Type<EsgImg, EsgImgTagged>(
  `EsgImg`,
  (u: unknown): u is EsgImg => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EsgImg> => pipe(c.decode(u), E.map(x => ({ ...x, ...esgImg }))),
  (x: EsgImg): EsgImgTagged => ({ ...x, _tag: `EsgImg`}),
)) satisfies t.Type<EsgImg, unknown>;


export const issuerLink = {
  _tag: `IssuerLink`,
  name: `IssuerLink`
} as const;

export type IssuerLinkTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerLink`>
}>;
export const issuerLinkTaggedC: IssuerLinkTaggedC = t.type({
  _tag: t.literal(`IssuerLink`)
});
export type IssuerLinkTagged = t.TypeOf<IssuerLinkTaggedC>;
export type IssuerLink = IssuerLinkTagged & typeof issuerLink;
export type IssuerLinkC = t.Type<IssuerLink, IssuerLinkTagged>;
export const issuerLinkC: IssuerLinkC = pipe(issuerLinkTaggedC, c => new t.Type<IssuerLink, IssuerLinkTagged>(
  `IssuerLink`,
  (u: unknown): u is IssuerLink => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerLink> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerLink }))),
  (x: IssuerLink): IssuerLinkTagged => ({ ...x, _tag: `IssuerLink`}),
)) satisfies t.Type<IssuerLink, unknown>;


export const rfp = {
  _tag: `Rfp`,
  name: `Rfp`
} as const;

export type RfpTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Rfp`>
}>;
export const rfpTaggedC: RfpTaggedC = t.type({
  _tag: t.literal(`Rfp`)
});
export type RfpTagged = t.TypeOf<RfpTaggedC>;
export type Rfp = RfpTagged & typeof rfp;
export type RfpC = t.Type<Rfp, RfpTagged>;
export const rfpC: RfpC = pipe(rfpTaggedC, c => new t.Type<Rfp, RfpTagged>(
  `Rfp`,
  (u: unknown): u is Rfp => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Rfp> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfp }))),
  (x: Rfp): RfpTagged => ({ ...x, _tag: `Rfp`}),
)) satisfies t.Type<Rfp, unknown>;


export const reminder = {
  _tag: `Reminder`,
  name: `Reminder`
} as const;

export type ReminderTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Reminder`>
}>;
export const reminderTaggedC: ReminderTaggedC = t.type({
  _tag: t.literal(`Reminder`)
});
export type ReminderTagged = t.TypeOf<ReminderTaggedC>;
export type Reminder = ReminderTagged & typeof reminder;
export type ReminderC = t.Type<Reminder, ReminderTagged>;
export const reminderC: ReminderC = pipe(reminderTaggedC, c => new t.Type<Reminder, ReminderTagged>(
  `Reminder`,
  (u: unknown): u is Reminder => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Reminder> => pipe(c.decode(u), E.map(x => ({ ...x, ...reminder }))),
  (x: Reminder): ReminderTagged => ({ ...x, _tag: `Reminder`}),
)) satisfies t.Type<Reminder, unknown>;


export const externalLink = {
  _tag: `ExternalLink`,
  name: `ExternalLink`
} as const;

export type ExternalLinkTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ExternalLink`>
}>;
export const externalLinkTaggedC: ExternalLinkTaggedC = t.type({
  _tag: t.literal(`ExternalLink`)
});
export type ExternalLinkTagged = t.TypeOf<ExternalLinkTaggedC>;
export type ExternalLink = ExternalLinkTagged & typeof externalLink;
export type ExternalLinkC = t.Type<ExternalLink, ExternalLinkTagged>;
export const externalLinkC: ExternalLinkC = pipe(externalLinkTaggedC, c => new t.Type<ExternalLink, ExternalLinkTagged>(
  `ExternalLink`,
  (u: unknown): u is ExternalLink => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ExternalLink> => pipe(c.decode(u), E.map(x => ({ ...x, ...externalLink }))),
  (x: ExternalLink): ExternalLinkTagged => ({ ...x, _tag: `ExternalLink`}),
)) satisfies t.Type<ExternalLink, unknown>;


export const contentNote = {
  _tag: `ContentNote`,
  name: `ContentNote`
} as const;

export type ContentNoteTaggedC = t.TypeC<{
  _tag: t.LiteralC<`ContentNote`>
}>;
export const contentNoteTaggedC: ContentNoteTaggedC = t.type({
  _tag: t.literal(`ContentNote`)
});
export type ContentNoteTagged = t.TypeOf<ContentNoteTaggedC>;
export type ContentNote = ContentNoteTagged & typeof contentNote;
export type ContentNoteC = t.Type<ContentNote, ContentNoteTagged>;
export const contentNoteC: ContentNoteC = pipe(contentNoteTaggedC, c => new t.Type<ContentNote, ContentNoteTagged>(
  `ContentNote`,
  (u: unknown): u is ContentNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ContentNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...contentNote }))),
  (x: ContentNote): ContentNoteTagged => ({ ...x, _tag: `ContentNote`}),
)) satisfies t.Type<ContentNote, unknown>;


export const bondProgram = {
  _tag: `BondProgram`,
  name: `BondProgram`
} as const;

export type BondProgramTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondProgram`>
}>;
export const bondProgramTaggedC: BondProgramTaggedC = t.type({
  _tag: t.literal(`BondProgram`)
});
export type BondProgramTagged = t.TypeOf<BondProgramTaggedC>;
export type BondProgram = BondProgramTagged & typeof bondProgram;
export type BondProgramC = t.Type<BondProgram, BondProgramTagged>;
export const bondProgramC: BondProgramC = pipe(bondProgramTaggedC, c => new t.Type<BondProgram, BondProgramTagged>(
  `BondProgram`,
  (u: unknown): u is BondProgram => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondProgram> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondProgram }))),
  (x: BondProgram): BondProgramTagged => ({ ...x, _tag: `BondProgram`}),
)) satisfies t.Type<BondProgram, unknown>;


export const bidSubmissionTemplate = {
  _tag: `BidSubmissionTemplate`,
  name: `BidSubmissionTemplate`
} as const;

export type BidSubmissionTemplateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BidSubmissionTemplate`>
}>;
export const bidSubmissionTemplateTaggedC: BidSubmissionTemplateTaggedC = t.type({
  _tag: t.literal(`BidSubmissionTemplate`)
});
export type BidSubmissionTemplateTagged = t.TypeOf<BidSubmissionTemplateTaggedC>;
export type BidSubmissionTemplate = BidSubmissionTemplateTagged & typeof bidSubmissionTemplate;
export type BidSubmissionTemplateC = t.Type<BidSubmissionTemplate, BidSubmissionTemplateTagged>;
export const bidSubmissionTemplateC: BidSubmissionTemplateC = pipe(bidSubmissionTemplateTaggedC, c => new t.Type<BidSubmissionTemplate, BidSubmissionTemplateTagged>(
  `BidSubmissionTemplate`,
  (u: unknown): u is BidSubmissionTemplate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidSubmissionTemplate> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidSubmissionTemplate }))),
  (x: BidSubmissionTemplate): BidSubmissionTemplateTagged => ({ ...x, _tag: `BidSubmissionTemplate`}),
)) satisfies t.Type<BidSubmissionTemplate, unknown>;


export const bidSubmissionTemplateDocument = {
  _tag: `BidSubmissionTemplateDocument`,
  name: `BidSubmissionTemplateDocument`
} as const;

export type BidSubmissionTemplateDocumentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BidSubmissionTemplateDocument`>
}>;
export const bidSubmissionTemplateDocumentTaggedC: BidSubmissionTemplateDocumentTaggedC = t.type({
  _tag: t.literal(`BidSubmissionTemplateDocument`)
});
export type BidSubmissionTemplateDocumentTagged = t.TypeOf<BidSubmissionTemplateDocumentTaggedC>;
export type BidSubmissionTemplateDocument = BidSubmissionTemplateDocumentTagged & typeof bidSubmissionTemplateDocument;
export type BidSubmissionTemplateDocumentC = t.Type<BidSubmissionTemplateDocument, BidSubmissionTemplateDocumentTagged>;
export const bidSubmissionTemplateDocumentC: BidSubmissionTemplateDocumentC = pipe(bidSubmissionTemplateDocumentTaggedC, c => new t.Type<BidSubmissionTemplateDocument, BidSubmissionTemplateDocumentTagged>(
  `BidSubmissionTemplateDocument`,
  (u: unknown): u is BidSubmissionTemplateDocument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidSubmissionTemplateDocument> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidSubmissionTemplateDocument }))),
  (x: BidSubmissionTemplateDocument): BidSubmissionTemplateDocumentTagged => ({ ...x, _tag: `BidSubmissionTemplateDocument`}),
)) satisfies t.Type<BidSubmissionTemplateDocument, unknown>;


export const bidSubmissionTemplateQuestion = {
  _tag: `BidSubmissionTemplateQuestion`,
  name: `BidSubmissionTemplateQuestion`
} as const;

export type BidSubmissionTemplateQuestionTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BidSubmissionTemplateQuestion`>
}>;
export const bidSubmissionTemplateQuestionTaggedC: BidSubmissionTemplateQuestionTaggedC = t.type({
  _tag: t.literal(`BidSubmissionTemplateQuestion`)
});
export type BidSubmissionTemplateQuestionTagged = t.TypeOf<BidSubmissionTemplateQuestionTaggedC>;
export type BidSubmissionTemplateQuestion = BidSubmissionTemplateQuestionTagged & typeof bidSubmissionTemplateQuestion;
export type BidSubmissionTemplateQuestionC = t.Type<BidSubmissionTemplateQuestion, BidSubmissionTemplateQuestionTagged>;
export const bidSubmissionTemplateQuestionC: BidSubmissionTemplateQuestionC = pipe(bidSubmissionTemplateQuestionTaggedC, c => new t.Type<BidSubmissionTemplateQuestion, BidSubmissionTemplateQuestionTagged>(
  `BidSubmissionTemplateQuestion`,
  (u: unknown): u is BidSubmissionTemplateQuestion => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidSubmissionTemplateQuestion> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidSubmissionTemplateQuestion }))),
  (x: BidSubmissionTemplateQuestion): BidSubmissionTemplateQuestionTagged => ({ ...x, _tag: `BidSubmissionTemplateQuestion`}),
)) satisfies t.Type<BidSubmissionTemplateQuestion, unknown>;


export const bidSubmission = {
  _tag: `BidSubmission`,
  name: `BidSubmissioTemplate`
} as const;

export type BidSubmissionTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BidSubmission`>
}>;
export const bidSubmissionTaggedC: BidSubmissionTaggedC = t.type({
  _tag: t.literal(`BidSubmission`)
});
export type BidSubmissionTagged = t.TypeOf<BidSubmissionTaggedC>;
export type BidSubmission = BidSubmissionTagged & typeof bidSubmission;
export type BidSubmissionC = t.Type<BidSubmission, BidSubmissionTagged>;
export const bidSubmissionC: BidSubmissionC = pipe(bidSubmissionTaggedC, c => new t.Type<BidSubmission, BidSubmissionTagged>(
  `BidSubmission`,
  (u: unknown): u is BidSubmission => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidSubmission> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidSubmission }))),
  (x: BidSubmission): BidSubmissionTagged => ({ ...x, _tag: `BidSubmission`}),
)) satisfies t.Type<BidSubmission, unknown>;


export const bidSubmissionDocument = {
  _tag: `BidSubmissionDocument`,
  name: `BidSubmissionDocument`
} as const;

export type BidSubmissionDocumentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BidSubmissionDocument`>
}>;
export const bidSubmissionDocumentTaggedC: BidSubmissionDocumentTaggedC = t.type({
  _tag: t.literal(`BidSubmissionDocument`)
});
export type BidSubmissionDocumentTagged = t.TypeOf<BidSubmissionDocumentTaggedC>;
export type BidSubmissionDocument = BidSubmissionDocumentTagged & typeof bidSubmissionDocument;
export type BidSubmissionDocumentC = t.Type<BidSubmissionDocument, BidSubmissionDocumentTagged>;
export const bidSubmissionDocumentC: BidSubmissionDocumentC = pipe(bidSubmissionDocumentTaggedC, c => new t.Type<BidSubmissionDocument, BidSubmissionDocumentTagged>(
  `BidSubmissionDocument`,
  (u: unknown): u is BidSubmissionDocument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidSubmissionDocument> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidSubmissionDocument }))),
  (x: BidSubmissionDocument): BidSubmissionDocumentTagged => ({ ...x, _tag: `BidSubmissionDocument`}),
)) satisfies t.Type<BidSubmissionDocument, unknown>;


export const bidSubmissionAnswer = {
  _tag: `BidSubmissionAnswer`,
  name: `BidSubmissionAnswer`
} as const;

export type BidSubmissionAnswerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BidSubmissionAnswer`>
}>;
export const bidSubmissionAnswerTaggedC: BidSubmissionAnswerTaggedC = t.type({
  _tag: t.literal(`BidSubmissionAnswer`)
});
export type BidSubmissionAnswerTagged = t.TypeOf<BidSubmissionAnswerTaggedC>;
export type BidSubmissionAnswer = BidSubmissionAnswerTagged & typeof bidSubmissionAnswer;
export type BidSubmissionAnswerC = t.Type<BidSubmissionAnswer, BidSubmissionAnswerTagged>;
export const bidSubmissionAnswerC: BidSubmissionAnswerC = pipe(bidSubmissionAnswerTaggedC, c => new t.Type<BidSubmissionAnswer, BidSubmissionAnswerTagged>(
  `BidSubmissionAnswer`,
  (u: unknown): u is BidSubmissionAnswer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidSubmissionAnswer> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidSubmissionAnswer }))),
  (x: BidSubmissionAnswer): BidSubmissionAnswerTagged => ({ ...x, _tag: `BidSubmissionAnswer`}),
)) satisfies t.Type<BidSubmissionAnswer, unknown>;


export const allDraftTypeC = [addressC, mediaC, emmaDocC, homepageImgC, calloutFieldC, textItemC, disclaimerC, ratingC, analystC, cusip6C, emmaC, relatedIssuerC, calendarC, newsC, faqC, hrefC, issuerOfficerC, paymentTokenC, projectC, invoiceC, offeringParticipantC, offeringDataC, offeringSeriesC, siteTemplateC, headerPhotoC, marketInsightC, nonIceDebtInstrumentC, esgImgC, issuerLinkC, rfpC, reminderC, externalLinkC, contentNoteC, bondProgramC, bidSubmissionTemplateC, bidSubmissionTemplateDocumentC, bidSubmissionTemplateQuestionC, bidSubmissionC, bidSubmissionDocumentC, bidSubmissionAnswerC] as const;
export const allDraftTypeNames = [`Address`, `Media`, `EmmaDoc`, `HomepageImg`, `CalloutField`, `TextItem`, `Disclaimer`, `Rating`, `Analyst`, `Cusip6`, `Emma`, `RelatedIssuer`, `Calendar`, `News`, `Faq`, `Href`, `IssuerOfficer`, `PaymentToken`, `Project`, `Invoice`, `OfferingParticipant`, `OfferingData`, `OfferingSeries`, `SiteTemplate`, `HeaderPhoto`, `MarketInsight`, `NonIceDebtInstrument`, `EsgImg`, `IssuerLink`, `Rfp`, `Reminder`, `ExternalLink`, `ContentNote`, `BondProgram`, `BidSubmissionTemplate`, `BidSubmissionTemplateDocument`, `BidSubmissionTemplateQuestion`, `BidSubmission`, `BidSubmissionDocument`, `BidSubmissionAnswer`] as const;
export type DraftTypeName = (typeof allDraftTypeNames)[number];

export type DraftTypeCU = t.UnionC<[AddressC, MediaC, EmmaDocC, HomepageImgC, CalloutFieldC, TextItemC, DisclaimerC, RatingC, AnalystC, Cusip6C, EmmaC, RelatedIssuerC, CalendarC, NewsC, FaqC, HrefC, IssuerOfficerC, PaymentTokenC, ProjectC, InvoiceC, OfferingParticipantC, OfferingDataC, OfferingSeriesC, SiteTemplateC, HeaderPhotoC, MarketInsightC, NonIceDebtInstrumentC, EsgImgC, IssuerLinkC, RfpC, ReminderC, ExternalLinkC, ContentNoteC, BondProgramC, BidSubmissionTemplateC, BidSubmissionTemplateDocumentC, BidSubmissionTemplateQuestionC, BidSubmissionC, BidSubmissionDocumentC, BidSubmissionAnswerC]>;
export type DraftTypeU = Address | Media | EmmaDoc | HomepageImg | CalloutField | TextItem | Disclaimer | Rating | Analyst | Cusip6 | Emma | RelatedIssuer | Calendar | News | Faq | Href | IssuerOfficer | PaymentToken | Project | Invoice | OfferingParticipant | OfferingData | OfferingSeries | SiteTemplate | HeaderPhoto | MarketInsight | NonIceDebtInstrument | EsgImg | IssuerLink | Rfp | Reminder | ExternalLink | ContentNote | BondProgram | BidSubmissionTemplate | BidSubmissionTemplateDocument | BidSubmissionTemplateQuestion | BidSubmission | BidSubmissionDocument | BidSubmissionAnswer;
export const DraftTypeCU: DraftTypeCU = t.union([addressC, mediaC, emmaDocC, homepageImgC, calloutFieldC, textItemC, disclaimerC, ratingC, analystC, cusip6C, emmaC, relatedIssuerC, calendarC, newsC, faqC, hrefC, issuerOfficerC, paymentTokenC, projectC, invoiceC, offeringParticipantC, offeringDataC, offeringSeriesC, siteTemplateC, headerPhotoC, marketInsightC, nonIceDebtInstrumentC, esgImgC, issuerLinkC, rfpC, reminderC, externalLinkC, contentNoteC, bondProgramC, bidSubmissionTemplateC, bidSubmissionTemplateDocumentC, bidSubmissionTemplateQuestionC, bidSubmissionC, bidSubmissionDocumentC, bidSubmissionAnswerC]) satisfies t.Type<DraftTypeU, unknown>;

export const draftTypeOrd: Ord.Ord<DraftTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDraftType = [address, media, emmaDoc, homepageImg, calloutField, textItem, disclaimer, rating, analyst, cusip6, emma, relatedIssuer, calendar, news, faq, href, issuerOfficer, paymentToken, project, invoice, offeringParticipant, offeringData, offeringSeries, siteTemplate, headerPhoto, marketInsight, nonIceDebtInstrument, esgImg, issuerLink, rfp, reminder, externalLink, contentNote, bondProgram, bidSubmissionTemplate, bidSubmissionTemplateDocument, bidSubmissionTemplateQuestion, bidSubmission, bidSubmissionDocument, bidSubmissionAnswer] as const;
export type DraftTypeMap<A> = { [K in DraftTypeName]: A };


