
import { useMemo, useState } from "react";
import type { Event, Scope, SeverityLevel } from "@sentry/browser";
import { captureException, configureScope, getCurrentHub, init, withScope } from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import type { SamplingContext, Span, SpanContext, Transaction } from "@sentry/types/types";
import { absurd, apply, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as TE from "fp-ts/lib/TaskEither";
import { useStableO } from "fp-ts-react-stable-hooks";
import semverSatisfies from "semver/functions/satisfies";

import type { BLConfigWithLog } from "./bondlink";
import { isDev } from "./bondlink";
import type { UserWithRoles } from "./generated/models/user";
import { Scrub } from "./scrub";
import * as Blang from "./util/blang";
import type { LogLevel } from "./util/log";

export { useProfiler, withProfiler, getCurrentHub } from "@sentry/react";

export const defaultSampleRate = 0.25;

export const hasExactErrorMessage = (message: string) => Blang.expr((event: Event): boolean =>
  event.message === message);

export const hasPartialErrorMessage = (message: string) => Blang.expr((event: Event): boolean =>
  event.message?.includes(message) ?? false);

export const hasBrowserName = (name: string) => Blang.expr((event: Event): boolean => {
  if (event.contexts?.["browser"]?.["name"]) {
    return event.contexts["browser"]["name"] === name;
  } else {
    return false;
  }
});

export const hasBrowserVersionRange = (version: string) => Blang.expr((event: Event): boolean => {
  if (
    event.contexts?.["browser"]?.["version"]
    && typeof event.contexts["browser"]["version"] === "string"
  ) {
    return semverSatisfies(event.contexts["browser"]["version"], version);
  } else {
    return false;
  }
});

/* eslint-disable curly-quotes/no-straight-quotes */
const errorsToIgnore = Blang.or([
  hasPartialErrorMessage(
    "Non-Error promise rejection captured with value: Object Not Found Matching Id", // MS Scraper error
  ),
  hasExactErrorMessage(
    "Blocked 'script' from 'eval:'", // No information reported to sentry so I'm not sure where this comes from but it happens a lot - JDL
  ),
  Blang.and([
    hasBrowserName("Safari"),
    hasBrowserVersionRange("< 10.1"),
    hasExactErrorMessage(
      "Unexpected token '*'", // This is likely caused by Safari 10.0's lack of support for the ES2016 exponentiation operator (**). It's supported in Safari >= 10.1.
    ),
  ]),
]);
/* eslint-enable curly-quotes/no-straight-quotes */

const shouldIgnoreError = (error: Event) => pipe(errorsToIgnore, Blang.interpretWith(apply(error)));

type Property = "Corporate" | "Portals" | "Sites" | "Ssr";

const dsnLookup: { [key in Property]: string } = {
  Corporate: "https://5ef1c4e60fac40ccb7cba43ab9dac689@o60855.ingest.sentry.io/4505320042987520",
  Portals: "https://c64d3695a971481c82a2ba5956cc2b5d@o60855.ingest.sentry.io/130541",
  Sites: "https://d3c5b6701866cd94f3c5129f9608e2e6@o60855.ingest.sentry.io/4505846078046208",
  Ssr: "https://f48bb5b1a816419cb604ffade757e8b0@o60855.ingest.sentry.io/4505318579699712",
};

export const bootstrapSentry = (config: BLConfigWithLog, property: Property) => {
  if (!isDev(config)) {
    init({
      dsn: dsnLookup[property],
      release: config.commit,
      environment: config.environment._tag,
      beforeSend: (e) => {
        if (shouldIgnoreError(e)) {
          return null;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return Scrub.scrubData(e);
        }
      },
      integrations: [
        new BrowserTracing({}),
      ],
      tracesSampler: (samplingContext: SamplingContext) => {
        return samplingContext.transactionContext.metadata?.sampleRate ?? 0.25;
      },
    });

    O.map((u: UserWithRoles) => {
      configureScope((scope: Scope) => scope.setUser({ id: u.user.id.toString(), email: u.user.email }));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
    })(O.fromNullable((globalThis as any).BLSession?.user));
  }
};

type BLErrorType = "BLError" | "BConsoleError";

export class BLError extends Error {
  message: string;
  arguments: unknown[];
  name: BLErrorType;

  constructor(message: string, args: unknown[], name: BLErrorType) {
    super(message);
    this.message = message;
    this.arguments = args;
    this.name = name;
  }
}

export const sentry = {
  capture: (error: BLError, level: LogLevel): void => {
    withScope((s: Scope) => {
      s.setLevel(levelToSeverity(level));
      captureException(error);
    });
  },
  captureMessage: (message: string, args: unknown[] = [], name: BLErrorType = "BLError", level: LogLevel = "error") =>
    sentry.capture(new BLError(message, args, name), level),
};

export function levelToSeverity(level: LogLevel): SeverityLevel {
  switch (level) {
    case "fatal": return "fatal";
    case "error": return "error";
    case "warn": return "warning";
    case "info": return "info";
    case "debug": return "debug";
  }

  return absurd(level);
}

export const getCurrentScope = () => O.fromNullable(getCurrentHub().getScope());
export const getCurrentTransaction = () => O.fromNullable(getCurrentHub().getScope().getTransaction());
export const getCurrentSpan = () => O.fromNullable(getCurrentHub().getScope().getSpan());

export const withNewChildSpanTE = <E, A>(
  config: {
    parent: Transaction | Span;
    context: SpanContext;
    onLeft?: (childSpan: Span, e: E) => void;
    onRight?: (childSpan: Span, a: A) => void;
  },
  taskEither: TE.TaskEither<E, A>,
): TE.TaskEither<E, A> => () => {
  const span = config.parent.startChild(config.context);
  return pipe(
    taskEither,
    TE.bimap(
      e => {
        if (config.onLeft) { config.onLeft(span, e); }
        span.finish();
        return e;
      },
      a => {
        if (config.onRight) { config.onRight(span, a); }
        span.finish();
        return a;
      }
    )
  )();
};

export const useActiveHubVolatile = getCurrentHub;
export const useActiveScopeVolatile = getCurrentScope;
export const useActiveTransactionVolatile = getCurrentTransaction;
export const useActiveSpanVolatile = getCurrentSpan;

export const useActiveHubStable = () => useMemo(getCurrentHub, [getCurrentHub]);

export const useActiveScopeStable = () => {
  const [scope, setScope] = useStableO(getCurrentScope());
  const result = useMemo(() => ({
    scope,
    refreshScope: () => setScope(getCurrentScope),
  }), [scope, setScope]);
  return result;
};

export const useActiveTransactionStable = () => {
  const [transaction, setTransaction] = useStableO(getCurrentTransaction());
  return useMemo(() => ({
    transaction,
    refreshTransaction: () => setTransaction(getCurrentTransaction),
  }), [transaction, setTransaction]);
};

export const useActiveSpanStable = () => {
  const [span, setSpan] = useStableO(getCurrentSpan());
  return useMemo(() => ({
    span,
    refreshSpan: () => setSpan(getCurrentSpan),
  }), [span, setSpan]);
};

export const useNewSpanStable = (config: { parent: Transaction | Span, context: SpanContext }) => {
  const [span, setSpan] = useState(() => config.parent.startChild(config.context));
  return useMemo(() => ({
    span,
    finishAndReplaceSpan: (
      Config: {
        parent: Transaction | Span;
        context: SpanContext;
      },
      endTimestamp?: number
    ) => setSpan((prevSpan) => {
      if (prevSpan.endTimestamp == null) {
        endTimestamp != null ? prevSpan.finish(endTimestamp) : prevSpan.finish();
      }
      return Config.parent.startChild(Config.context);
    }),
  }), [span]);
};
