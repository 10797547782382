import { useState } from "react";

import { O, RA } from "@scripts/fp-ts";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import type { RfpsSitesList } from "@scripts/generated/models/rfp";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { klass } from "@scripts/react/util/classnames";
import type { RfpsUrlParams } from "@scripts/routes/routing/ssr/issuersites";
import { rfps } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { makeSitesJumpLinks } from "@scripts/syntax/sidebarLinks";

import { useRfpSubscribe } from "../../api/watchlist";
import { DirectSitesPageDescriptionLayout } from "../../components/DirectSitesPageLayout";
import { IssuerSitesRfpCard } from "../../components/RfpCard";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

export type RfpsProps = Required<RfpsUrlParams> & { rfps: RfpsSitesList };

const RfpCard = (props: {
  rfp: RfpsSitesList["active"][number];
  setRfp: (r: RfpsSitesList["active"][number]) => void;
}) => {
  const issuer = useIssuerSitesSelector("issuer");
  const user = useIssuerSitesSelector("user");
  const userSubscribedToIssuer = useIssuerSitesSelector("userSubscribed");

  const [isSubscribed, setIsSubscribed] = useState(props.rfp.subscribed);

  const makeOnSubscribe = useRfpSubscribe();

  return <IssuerSitesRfpCard
    issuer={issuer}
    rfp={props.rfp}
    setRfp={props.setRfp}
    isSubscribed={isSubscribed}
    onSubscribe={makeOnSubscribe(
      { issuerId: O.some(issuer.id), bankId: O.none },
      !isSubscribed,
      props.rfp.data.data.id,
      setIsSubscribed,
      user,
      userSubscribedToIssuer
    )}
  />;
};

const jumpLinks = (ffs: ClientFeatureFlags, pages: ReadonlyArray<PageConfig<PageU>>): ReadonlyArray<JumpLink> =>
  makeSitesJumpLinks(ffs, pages)(jl.rfps.allStatic);

export const Rfps = (props: RfpsProps) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");

  const page = rfps({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const pageTitle = page.title(pages);
  const description = page.description(pages, iffs, issuer);

  const [active, setActive] = useState(props.rfps.active);
  const [archived, setArchived] = useState(props.rfps.archived);

  const updateRfps = (idx: number, rs: RfpsSitesList["active"], setRfps: (rs: RfpsSitesList["active"]) => void) => (r: RfpsSitesList["active"][number]) => {
    O.map(setRfps)(RA.updateAt(idx, r)(rs));
  };

  return <DirectSitesPageDescriptionLayout
    description={description}
    sidebarContent={<>
      <SidebarLinksSites
        headerLinkAnchorContent={page.title(pages)}
        jumpLinks={jumpLinks(iffs, pages)}
        routeMeta={page}
      />
      <GetAlertsActionSection containerKlass={"d-none-until-md"} />
    </>}
  >
    {jl.rfps.activeRfps.enabled(iffs)
      && <section id={jl.rfps.activeRfps.sectionId} {...klass("accent-border-top")}>
        <h2 {...klass("title")}>Active {pageTitle}</h2>
        {!active.length && <p>There are currently no active {pageTitle} listed.</p>}
        <div {...klass("grid card-grid")}>
          {active.map((rfp, i) => <div key={rfp.data.data.id} {...klass("c-24")}>
            <RfpCard rfp={rfp} setRfp={updateRfps(i, active, setActive)} />
          </div>)}
        </div>
      </section>
    }
    {jl.rfps.archivedRfps.enabled(iffs)
      && <section id={jl.rfps.archivedRfps.sectionId} {...klass("accent-border-top")}>
        <h2 {...klass("title")}>Archived {pageTitle}</h2>
        {!archived.length && <p>There are currently no archived {pageTitle} listed.</p>}
        <div {...klass("grid card-grid")}>
          {archived.map((rfp, i) => <div key={rfp.data.data.id} {...klass("c-24")}>
            <RfpCard rfp={rfp} setRfp={updateRfps(i, archived, setArchived)} />
          </div>)}
        </div>
      </section>
    }
  </DirectSitesPageDescriptionLayout>;
};
