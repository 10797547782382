import { WithStatusCU as imported731_WithStatusCU, WithStatusU as imported365_WithStatusU, WithStatusCU as imported730_WithStatusCU } from "./threadThrough";
import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as E from "fp-ts/lib/Either";
import { LocalDateC, LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { DayToDay as imported9_DayToDay, dayToDayC as imported5_dayToDayC, DayToDayC as imported7_DayToDayC, dayToDayC as imported7_dayToDayC, DayToDay as imported8_DayToDay, dayToDayC as imported6_dayToDayC, DayToDayC as imported9_DayToDayC, DayToDay as imported7_DayToDay, DayToDayC as imported8_DayToDayC } from "../domaintables/dateQualifiers";
import { MethodOfSaleCU as imported5_MethodOfSaleCU, MethodOfSaleU as imported2_MethodOfSaleU, MethodOfSaleCU as imported4_MethodOfSaleCU } from "../domaintables/methodsOfSale";
import { LocalDate, LocalDateTime } from "@js-joda/core";
import { EitherC, either } from "io-ts-types/lib/either";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { StateInfoCU as imported19_StateInfoCU, StateInfoU as imported9_StateInfoU, StateInfoCU as imported18_StateInfoCU } from "../domaintables/states";
import { DateQualifierRange as imported8_DateQualifierRange, DateQualifierRange as imported6_DateQualifierRange, DateQualifier as imported25_DateQualifier, dateQualifierRangeC as imported6_dateQualifierRangeC, DateQualifierRangeC as imported8_DateQualifierRangeC, DateQualifierRange as imported7_DateQualifierRange, DateQualifierRangeC as imported10_DateQualifierRangeC, DateQualifierRangeC as imported11_DateQualifierRangeC, DateQualifierC as imported25_DateQualifierC, DateQualifierRange as imported10_DateQualifierRange, DateQualifierRangeC as imported6_DateQualifierRangeC, dateQualifierRangeC as imported9_dateQualifierRangeC, dateQualifierRangeC as imported10_dateQualifierRangeC, DateQualifierRange as imported9_DateQualifierRange, dateQualifierRangeC as imported8_dateQualifierRangeC, DateQualifierRangeC as imported9_DateQualifierRangeC, DateQualifierRangeC as imported7_DateQualifierRangeC, dateQualifierRangeC as imported7_dateQualifierRangeC, DateQualifierRange as imported11_DateQualifierRange, dateQualifierC as imported25_dateQualifierC, dateQualifierRangeC as imported11_dateQualifierRangeC } from "./dateQualifier";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { SectorCU as imported41_SectorCU, SectorU as imported20_SectorU, SectorCU as imported40_SectorCU } from "../domaintables/sectors";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";
import { OfferingTypeCU as imported3_OfferingTypeCU, OfferingTypeU as imported1_OfferingTypeU, OfferingTypeCU as imported2_OfferingTypeCU } from "../domaintables/offeringTypes";
import * as t from "io-ts";

export type BondOfferingSeriesC = t.TypeC<{
  order: t.NumberC,
  name: t.StringC,
  parAmount: OptionFromNullableC<t.NumberC>,
  taxStatus: OptionFromNullableC<t.StringC>,
  creditEnhancement: OptionFromNullableC<t.StringC>,
  offeringType: OptionFromNullableC<imported3_OfferingTypeCU>,
  methodOfSale: OptionFromNullableC<imported5_MethodOfSaleCU>,
  saleDate: OptionFromNullableC<EitherC<imported6_DateQualifierRangeC, imported7_DayToDayC>>,
  closingDate: OptionFromNullableC<imported7_DateQualifierRangeC>,
  retailOrderDate: OptionFromNullableC<imported25_DateQualifierC>,
  datedDate: OptionFromNullableC<LocalDateC>,
  callDate: OptionFromNullableC<LocalDateC>,
  firstInterestDate: OptionFromNullableC<LocalDateC>,
  firstPrincipalDate: OptionFromNullableC<LocalDateC>,
  freeToTradeDate: OptionFromNullableC<LocalDateC>,
  callFeatures: OptionFromNullableC<t.StringC>
}>;
export type BondOfferingSeries = {
  order: number,
  name: string,
  parAmount: O.Option<number>,
  taxStatus: O.Option<string>,
  creditEnhancement: O.Option<string>,
  offeringType: O.Option<imported1_OfferingTypeU>,
  methodOfSale: O.Option<imported2_MethodOfSaleU>,
  saleDate: O.Option<E.Either<imported6_DateQualifierRange, imported7_DayToDay>>,
  closingDate: O.Option<imported7_DateQualifierRange>,
  retailOrderDate: O.Option<imported25_DateQualifier>,
  datedDate: O.Option<LocalDate>,
  callDate: O.Option<LocalDate>,
  firstInterestDate: O.Option<LocalDate>,
  firstPrincipalDate: O.Option<LocalDate>,
  freeToTradeDate: O.Option<LocalDate>,
  callFeatures: O.Option<string>
};
export const bondOfferingSeriesC: BondOfferingSeriesC = t.type({
  order: t.number,
  name: t.string,
  parAmount: optionFromNullable(t.number),
  taxStatus: optionFromNullable(t.string),
  creditEnhancement: optionFromNullable(t.string),
  offeringType: optionFromNullable(imported2_OfferingTypeCU),
  methodOfSale: optionFromNullable(imported4_MethodOfSaleCU),
  saleDate: optionFromNullable(either(imported6_dateQualifierRangeC, imported5_dayToDayC)),
  closingDate: optionFromNullable(imported7_dateQualifierRangeC),
  retailOrderDate: optionFromNullable(imported25_dateQualifierC),
  datedDate: optionFromNullable(LocalDateC),
  callDate: optionFromNullable(LocalDateC),
  firstInterestDate: optionFromNullable(LocalDateC),
  firstPrincipalDate: optionFromNullable(LocalDateC),
  freeToTradeDate: optionFromNullable(LocalDateC),
  callFeatures: optionFromNullable(t.string)
}) satisfies t.Type<BondOfferingSeries, unknown>;


export type BondOfferingC = t.TypeC<{
  order: t.NumberC,
  name: t.StringC,
  sector: OptionFromNullableC<imported41_SectorCU>,
  state: OptionFromNullableC<imported19_StateInfoCU>,
  notes: OptionFromNullableC<MarkdownC>,
  contactEmail: OptionFromNullableC<t.StringC>,
  hidden: t.BooleanC,
  cusip6: OptionFromNullableC<t.StringC>,
  bondProgramId: OptionFromNullableC<t.NumberC>,
  loginRequired: t.BooleanC,
  isArchived: t.BooleanC,
  archiveDateTime: OptionFromNullableC<LocalDateTimeFromIsoStringC>,
  series: ReadonlyNonEmptyArrayType<imported731_WithStatusCU<BondOfferingSeriesC>>,
  totalParAmount: OptionFromNullableC<t.NumberC>,
  firstSaleDate: OptionFromNullableC<EitherC<imported8_DateQualifierRangeC, imported8_DayToDayC>>,
  lastSaleDate: OptionFromNullableC<EitherC<imported9_DateQualifierRangeC, imported9_DayToDayC>>,
  firstClosingDate: OptionFromNullableC<imported10_DateQualifierRangeC>,
  lastClosingDate: OptionFromNullableC<imported11_DateQualifierRangeC>,
  displayProgramRatings: t.BooleanC,
  displayIssuerRatings: t.BooleanC
}>;
export type BondOffering = {
  order: number,
  name: string,
  sector: O.Option<imported20_SectorU>,
  state: O.Option<imported9_StateInfoU>,
  notes: O.Option<Markdown>,
  contactEmail: O.Option<string>,
  hidden: boolean,
  cusip6: O.Option<string>,
  bondProgramId: O.Option<number>,
  loginRequired: boolean,
  isArchived: boolean,
  archiveDateTime: O.Option<LocalDateTime>,
  series: RNEA.ReadonlyNonEmptyArray<imported365_WithStatusU<BondOfferingSeries>>,
  totalParAmount: O.Option<number>,
  firstSaleDate: O.Option<E.Either<imported8_DateQualifierRange, imported8_DayToDay>>,
  lastSaleDate: O.Option<E.Either<imported9_DateQualifierRange, imported9_DayToDay>>,
  firstClosingDate: O.Option<imported10_DateQualifierRange>,
  lastClosingDate: O.Option<imported11_DateQualifierRange>,
  displayProgramRatings: boolean,
  displayIssuerRatings: boolean
};
export const bondOfferingC: BondOfferingC = t.type({
  order: t.number,
  name: t.string,
  sector: optionFromNullable(imported40_SectorCU),
  state: optionFromNullable(imported18_StateInfoCU),
  notes: optionFromNullable(markdownC),
  contactEmail: optionFromNullable(t.string),
  hidden: t.boolean,
  cusip6: optionFromNullable(t.string),
  bondProgramId: optionFromNullable(t.number),
  loginRequired: t.boolean,
  isArchived: t.boolean,
  archiveDateTime: optionFromNullable(LocalDateTimeFromIsoStringC),
  series: readonlyNonEmptyArrayC(imported730_WithStatusCU(bondOfferingSeriesC)),
  totalParAmount: optionFromNullable(t.number),
  firstSaleDate: optionFromNullable(either(imported8_dateQualifierRangeC, imported6_dayToDayC)),
  lastSaleDate: optionFromNullable(either(imported9_dateQualifierRangeC, imported7_dayToDayC)),
  firstClosingDate: optionFromNullable(imported10_dateQualifierRangeC),
  lastClosingDate: optionFromNullable(imported11_dateQualifierRangeC),
  displayProgramRatings: t.boolean,
  displayIssuerRatings: t.boolean
}) satisfies t.Type<BondOffering, unknown>;


