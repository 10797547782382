import type { LinkableU, PageLink } from "@scripts/codecs/linkable";
import { flow, type Mn, O, pipe, RA, RM, RNEA } from "@scripts/fp-ts";
import { nav } from "@scripts/generated/domaintables/hrefLocations";
import { allCustomPage, BucketCU, bucketOrd, type BucketU, type CustomPageU, type PageU, resourcesPage } from "@scripts/generated/domaintables/pages";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { Href } from "@scripts/generated/models/href";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { IssuerPreferences } from "@scripts/generated/models/issuerPreferences";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as SR from "@scripts/generated/routers/sitesRouter";
import type { SitesJumpLink } from "@scripts/react/components/SidebarLinks";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { filterByHrefLocation } from "@scripts/syntax/href";
import { getPage } from "@scripts/syntax/pageConfig";
import { isPageEnabled } from "@scripts/syntax/sidebarLinks";
import { deepLink } from "@scripts/util/url";

import * as rm from "../issuersites";

type BaseParams = { issuerId: number, issuerSlug: string };

type WithDesc<A extends Record<string, unknown>> = A & { description: O.Option<string> };
type LinkWithDesc<A> = WithDesc<{ link: A }>;

const withDesc = <A extends Record<string, unknown>>(a: A, description: O.Option<string>): WithDesc<A> => ({ ...a, description });
const linkWithDesc = <A>(link: A, description: O.Option<string>): LinkWithDesc<A> => withDesc({ link }, description);

type PageRouteMeta = {
  title: rm.BaseTitleFn;
  url: () => string;
  staticJumpLinks: rm.StaticJumpLinksFn;
};

type TopLevelPageRouteMeta = PageRouteMeta & { photoPage: rm.BasePhotoPageFn };

export const pageLink = (displayName: string, url: string, description: O.Option<string>, enabled: boolean): PageLink => ({
  _tag: "PageLink",
  displayName,
  url,
  description,
  enabled,
});

const pageLinkFromPageRouteMeta = (issuer: Issuer, pages: ReadonlyArray<PageConfig<PageU>>) => (
  p: WithDesc<Omit<PageRouteMeta, "staticJumpLinks">>,
  enabled: boolean,
): PageLink =>
  pageLink(p.title(pages, issuer), p.url(), p.description, enabled);

type SubLink = WithDesc<SitesJumpLink> | LinkWithDesc<(p: BaseParams) => PageRouteMeta>;

const isSitesJumpLink = (l: SubLink): l is WithDesc<SitesJumpLink> => !("link" in l && typeof l.link == "function");

const defaultBucket: BucketU = resourcesPage;

type ByBucket<A> = ReadonlyMap<BucketU, ReadonlyArray<A>>;
const byBucketMonoid = <A>(): Mn.Monoid<ByBucket<A>> => RM.getUnionMonoid(bucketOrd, RA.getSemigroup<A>());

const mkByBucket = <A>(getBucket: (a: A) => O.Option<BucketU>): (as: ReadonlyArray<A>) => ByBucket<A> =>
  RA.foldMap(byBucketMonoid<A>())(a => RM.singleton(O.getOrElse((): BucketU => defaultBucket)(getBucket(a)), [a]));

const mkCustomPagesByBucket = (
  ffs: ClientFeatureFlags,
  pages: ReadonlyArray<PageConfig<PageU>>,
  prefs: O.Option<IssuerPreferences>,
): ByBucket<CustomPageU> => {
  const limit = pipe(prefs, O.fold(() => 0, _ => _.customPages));
  return pipe(
    allCustomPage.filter(_ => _.index <= limit && isPageEnabled(ffs)(_)),
    mkByBucket(_ => pipe(getPage(_)(pages), O.chain(p => p.pageHeader))),
  );
};

const mkHrefsByBucket: (hrefs: ReadonlyArray<WithStatusU<Href>>) => ByBucket<WithStatusU<Href>> =
  flow(filterByHrefLocation(nav), mkByBucket(_ => _.data.record.pageHeader));

const hrefPageLink = (params: BaseParams) => (href: WithStatusU<Href>): PageLink =>
  pipe(
    href.data.record.media,
    O.fold(
      () => pageLink(href.data.record.text, href.data.record.url, O.none, true),
      m => pageLink(
        href.data.record.text,
        SR.issuersitesReportsControllerDownloadRedirect({ ...params, mediaId: m.id }).url,
        O.none,
        true,
      ),
    )
  );

const mkLinks = (
  topLevel: LinkWithDesc<(p: BaseParams) => TopLevelPageRouteMeta>,
  machineName: string,
  subLinks: ReadonlyArray<SubLink>,
) => (
  issuer: Issuer,
  ffs: ClientFeatureFlags,
  pages: ReadonlyArray<PageConfig<PageU>>,
  customPagesByBucket: ByBucket<CustomPageU>,
  hrefsByBucket: ByBucket<WithStatusU<Href>>,
): [PageLink, string, ReadonlyArray<LinkableU>] => {
    const p: BaseParams = { issuerId: issuer.id, issuerSlug: issuer.slug };
  const tl = topLevel.link(p);
    const bucket: O.Option<BucketU> = O.fromPredicate(BucketCU.is)(tl.photoPage(pages));
    return [
      pageLink(tl.title(pages, issuer), tl.url(), topLevel.description, true),
      machineName,
      subLinks.map(subLink => {
        if (isSitesJumpLink(subLink)) {
          return pageLink(
            subLink.text(pages),
            deepLink({ type: "Basic", route: () => ({ method: "GET", url: tl.url() }), selector: subLink.sectionId })({}).url,
            subLink.description,
            subLink.enabled(ffs),
          );
        } else {
          const l = subLink.link(p);
          return pageLinkFromPageRouteMeta(issuer, pages)(
            withDesc(l, subLink.description),
            RA.isNonEmpty(l.staticJumpLinks(issuer, ffs, pages).filter(_ => _.enabled(ffs)))
          );
        }
      }).filter(_ => _.enabled).concat(pipe(
        bucket,
        O.chain(_ => RM.lookup(bucketOrd)(_, customPagesByBucket)),
        O.getOrElse((): ReadonlyArray<CustomPageU> => []),
        RA.map(_ => pageLinkFromPageRouteMeta(issuer, pages)(withDesc(rm.customPage({ ...p, i: _.index }), O.none), true)),
      )).concat(pipe(
        bucket,
        O.chain(_ => RM.lookup(bucketOrd)(_, hrefsByBucket)),
        O.getOrElse((): ReadonlyArray<WithStatusU<Href>> => []),
        RA.map(hrefPageLink(p))
      )),
    ];
  };

export const makeNavLinks = (
  issuer: Issuer,
  ffs: ClientFeatureFlags,
  pages: ReadonlyArray<PageConfig<PageU>>,
  prefs: O.Option<IssuerPreferences>,
  hrefs: ReadonlyArray<WithStatusU<Href>>,
  hasIrmaLetter: boolean,
): RNEA.ReadonlyNonEmptyArray<[PageLink, string, ReadonlyArray<LinkableU>]> => {
  const customPagesByBucket = mkCustomPagesByBucket(ffs, pages, prefs);
  const hrefsByBucket = mkHrefsByBucket(hrefs);
  const irmaLetterLink: ReadonlyArray<SubLink> = RA.fromOption(O.fromPredicate(() => hasIrmaLetter)(linkWithDesc(rm.irmaLetter, O.none)));

  return pipe([
    mkLinks(
      linkWithDesc(rm.about, O.some(`Learn more about ${issuer.name}`)),
      "about",
      [
        linkWithDesc(rm.esgProgram, O.some("View content related to our program")),
        linkWithDesc(rm.newsAndEvents, O.some("Get the latest updates")),
        linkWithDesc(rm.projects, O.some("View project details and photos")),
        linkWithDesc(rm.team, O.some("Read bios and get contact info")),
      ],
    ),
    mkLinks(
      linkWithDesc(rm.bonds, O.some("Get an overview of our bonds and ratings")),
      "bonds",
      [
        withDesc(jl.bonds.bondSales, O.some("View upcoming bond sales")),
        linkWithDesc(rm.bondArchive, O.some("Sort, filter, and view archived bond offerings")),
        linkWithDesc(rm.rfps, O.some("Get details and submission information")),
        withDesc(jl.bonds.roadshows, O.some("Watch roadshow presentations")),
        linkWithDesc(rm.ratings, O.some("View credit agency ratings")),
      ],
    ),
    mkLinks(
      linkWithDesc(rm.documentCategories, O.some("Browse documents by category")),
      "documents",
      [
        linkWithDesc(rm.downloads, O.some("Sort, filter, and download documents")),
        linkWithDesc(rm.archivedDocuments, O.some("Sort, filter, and download archived documents")),
        ...irmaLetterLink,
      ],
    ),
    mkLinks(
      linkWithDesc(rm.resources, O.some("Get additional related info")),
      "resources",
      [
        linkWithDesc((p: BaseParams) => rm.emmaLinks({ ...p, cusip9: O.none }), O.some("Find CUSIPs and links")),
        linkWithDesc(rm.faq, O.some("Get answers to common questions")),
        linkWithDesc(rm.links, O.some("View links to related sites")),
        withDesc(jl.resources.contact, O.some("Email and contact info")),
      ],
    ),
  ], RNEA.map(f => f(issuer, ffs, pages, customPagesByBucket, hrefsByBucket)));
};
