import { omit } from "@scripts/fp-ts/struct";
import type { RfpSitesRelatedContent } from "@scripts/generated/models/rfp";
import type { Rfp } from "@scripts/generated/models/rfpBase";
import { SitesJumpLink } from "@scripts/react/components/SidebarLinks";
import { type RfpWithSitesRelatedContent, showQuestionsAndAnswersSection } from "@scripts/syntax/rfp";

import * as bonds from "./bondsJumpLinks";
import { relatedContent as genRelatedContent } from "./relatedContentJumpLinks";

export const summary = SitesJumpLink("Summary", []);

export const winningBid = (r: Rfp): SitesJumpLink => SitesJumpLink("Winning Bid", [], { hidden: !r.showWinningBid });

export const roadshows = (rs: RfpSitesRelatedContent["roadShows"]): SitesJumpLink =>
  SitesJumpLink(bonds.roadshows.defaultText, [], { hidden: rs.length === 0 });

export const participants = (ps: RfpSitesRelatedContent["participants"]): SitesJumpLink =>
  SitesJumpLink(bonds.participants.defaultText, [], { hidden: ps.length === 0 });

export const questionsAndAnswers = (r: RfpWithSitesRelatedContent): SitesJumpLink =>
  SitesJumpLink("Questions & Answers", [], { hidden: !showQuestionsAndAnswersSection(r.rfp, r.relatedContent.faqs) });

export const relatedContent = (rc: RfpSitesRelatedContent): SitesJumpLink =>
  genRelatedContent(omit("roadShows", "participants", "documents", "faqs", "program", "bidSubmissionTemplate", "bidSubmission")(rc));

export const all = (r: RfpWithSitesRelatedContent): ReadonlyArray<SitesJumpLink> => [
  summary,
  winningBid(r.rfp),
  roadshows(r.relatedContent.roadShows),
  participants(r.relatedContent.participants),
  questionsAndAnswers(r),
  relatedContent(r.relatedContent),
];
