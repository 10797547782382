import { type BLConfigWithLog, formatS3CdnUrl } from "@scripts/bondlink";
import type { PageLink } from "@scripts/codecs/linkable";
import { O, pipe } from "@scripts/fp-ts";
import type * as p from "@scripts/generated/domaintables/partners";
import type { IssuerPartnerLanderPageData } from "@scripts/generated/models/issuerPageData";
import * as AssetsRouter from "@scripts/generated/routers/assetsRouter";
import * as CorpRouter from "@scripts/generated/routers/corpRouter";
import { AnchorWithChildren } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { useConfig } from "@scripts/react/context/Config";
import { klassNullableProp } from "@scripts/react/util/classnames";
import type { PageRouteBasic } from "@scripts/routes/routing/base";

import { PartnerLanderAbout } from "./PartnerLanderAbout";
import { PartnerLanderIssuerHeader } from "./PartnerLanderIssuerHeader";
import { PartnerLanderLink } from "./PartnerLanderLink";
import { PartnerLanderLinks } from "./PartnerLanderLinks";
import { PartnerLanderMainImg } from "./PartnerLanderMainImg";
import { PartnerLanderOfficer } from "./PartnerLanderOfficer";
import { PartnerLanderQuickFacts } from "./PartnerLanderQuickFacts";
import { PartnerLanderWelcomeLetterOrProjects } from "./PartnerLanderWelcomeLetterOrProjects";

export type PartnerWithPage = p.PartnerU & { readonly _tag: p.BondNav["_tag"] | p.Fidelity["_tag"] | p.ICE["_tag"] };

const partnerLogoLink = (config: BLConfigWithLog) => (partner: PartnerWithPage): O.Option<PageRouteBasic> => {
  switch (partner._tag) {
    case "BondNav":
    case "ICE":
      return O.none;

    case "Fidelity":
      return O.some({
        title: "Back to Fidelity",
        route: {
          method: "GET",
          url: "https://fixedincome.fidelity.com/ftgw/fi/FIMuniDisplay?name=MUNIBD&refpr=obrfind18&requestpage=FISearchMunicipal",
        },
      });

    default: return config.exhaustive(partner);
  }
};

const bondLinkLogoImgUrl = (config: BLConfigWithLog) => (partner: PartnerWithPage): string => {
  switch (partner._tag) {
    case "BondNav":
    case "Fidelity":
      return AssetsRouter.assetImgLogosBondlink2019InvertedSvg().url;

    case "ICE": return AssetsRouter.assetImgLogosBondlink2019BlackSvg().url;

    default: return config.exhaustive(partner);
  }
};

const partnerLogoImgUrl = (config: BLConfigWithLog) => (partner: PartnerWithPage): string => {
  switch (partner._tag) {
    case "BondNav": return formatS3CdnUrl(config)("images/landers/bondnav-white.svg");
    case "ICE": return formatS3CdnUrl(config)("images/landers/ice-black.png");
    case "Fidelity": return formatS3CdnUrl(config)("images/landers/fidelity-white.png");
    default: return config.exhaustive(partner);
  }
};

const PartnerLogoImg = (props: { partner: PartnerWithPage }) => {
  const config = useConfig();
  return <img
    src={partnerLogoImgUrl(config)(props.partner)}
    alt={`${props.partner._tag} logo`}
    className="nav-logo"
  />;
};

export const PartnerLanderPage = (props: {
  partner: PartnerWithPage;
  data: IssuerPartnerLanderPageData;
  links: ReadonlyArray<PageLink>;
}) => {
  const config = useConfig();
  const officer = PartnerLanderOfficer();

  return <>
    <nav id="main-nav" className={`box-shadow-2 py-05 partner-${props.partner._tag.toLowerCase()}`}>
      <div className="container d-flex justify-content-between align-items-center">
        <PartnerLanderLink
          issuer={O.none}
          partner={props.partner}
          klasses="d-flex align-items-center no-decoration"
          url={CorpRouter.corporateStaticIndex()}
          aria-label="BondLink Homepage"
        >
          <img className="nav-logo" src={bondLinkLogoImgUrl(config)(props.partner)} alt="BondLink Logo" />
          <span className="ml-05 d-none d-md-block">INVESTOR RELATIONS PLATFORM</span>
        </PartnerLanderLink>
        {pipe(
          partnerLogoLink(config)(props.partner),
          O.fold(
            () => <PartnerLogoImg partner={props.partner} />,
            route => <AnchorWithChildren
              klasses="d-flex flex-col justify-content-center no-decoration"
              target="_self"
              route={route}
              aria-label={route.title}
            >
              <PartnerLogoImg partner={props.partner} />
              <div className="back-to-text mt-025">{route.title}</div>
            </AnchorWithChildren>,
          )
        )}
      </div>
    </nav>
    <div className="mt-1 container"><PartnerLanderIssuerHeader partner={props.partner} /></div>
    <div className="mt-05 mb-1 mb-md-2 relative">
      <div className="bg-stripe" />
      <div className="container py-1 py-md-0 relative">
        <div className="grid-75-25-simple mt-0">
          <div className="grid-left">
            <PartnerLanderMainImg mainImg={props.data.mainImg} />
          </div>
          <div className="quick-facts grid-right py-md-2">
            <h2>Quick Facts</h2>
            <PartnerLanderQuickFacts quickFacts={props.data.quickFacts} />
          </div>
        </div>
      </div>
    </div>
    <div className="container content-container">
      <div className="grid-75-25-simple mt-0">
        <div className="grid-left">
          <div className="grid grid-sx-1">
            <div {...klassNullableProp(pipe(officer, O.map(() => "c-md-16")))}>
              <PartnerLanderAbout aboutText={props.data.aboutText} />
            </div>
            {pipe(officer, mapOrEmpty(_ => <div className="c-md-8">{_}</div>))}
          </div>
          <PartnerLanderWelcomeLetterOrProjects data={props.data} />
        </div>
        <div className="grid-right mt-15 mt-md-0">
          <h2 className="my-0">More Info</h2>
          <PartnerLanderLinks partner={props.partner} links={props.links} />
        </div>
      </div>
    </div >
  </>;
};
