import { Fragment, type ReactElement, type ReactNode } from "react";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { makeDeepLinkModalWithDataUrl } from "@scripts/codecs/deepLinkTarget";
import { constVoid, E, O, pipe, R, RA, RNEA } from "@scripts/fp-ts";
import { sitesEventModal } from "@scripts/generated/domaintables/deepLinkTypes";
import { financeCalendar, news, projects } from "@scripts/generated/domaintables/featureFlags";
import { requestMoreInfoButton } from "@scripts/generated/domaintables/pages";
import type { BondOfferingWithRelatedContent } from "@scripts/generated/models/bondOffering";
import type { ClientFeatureFlags } from "@scripts/generated/models/clientFeatureFlags";
import type { Cusip9 } from "@scripts/generated/models/cusip";
import type { DateQualifier } from "@scripts/generated/models/dateQualifier";
import type { EmptyObject } from "@scripts/generated/models/emptyObject";
import type { Event } from "@scripts/generated/models/event";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { IssuerNewsRelatedContent } from "@scripts/generated/models/issuerNews";
import type { IssuerNews } from "@scripts/generated/models/issuerNewsBase";
import type { ProjectRelatedContent as ProjectRelatedContentType } from "@scripts/generated/models/project";
import type {
  RelatedCusip9Link,
  RelatedEventLink,
  RelatedNewsLink,
} from "@scripts/generated/models/relatedContent";
import type { Subscribed } from "@scripts/generated/models/subscribed";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { HasManyLink, WithStatusU } from "@scripts/generated/models/threadThrough";
import type { UserWithRoles } from "@scripts/generated/models/user";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { emmaName } from "@scripts/literals/emma";
import { AnchorIcon, AnchorIconSubtitle, AnchorUnsafe } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import {
  BondsRelatedContent,
  DocumentsRelatedContent,
  LeafIconLayout,
  LinksRelatedContent,
  makeRelatedContentDataO,
  ProgramsRelatedContent,
  type ProjectRelatedContent,
  ProjectsRelatedContent,
  RelatedContentItem,
  type RelatedContentItemVariant,
  RfpsRelatedContent,
} from "@scripts/react/components/offering-pages/RelatedContent";
import { Table } from "@scripts/react/components/table/Table";
import type { TableColumnRow } from "@scripts/react/components/table/tableSyntax";
import { useConfig } from "@scripts/react/context/Config";
import { klass } from "@scripts/react/util/classnames";
import { rfps } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { urlInterface } from "@scripts/routes/urlInterface";
import { emmaCusipActivityUrl } from "@scripts/syntax/cusip";
import { qualifierFormat } from "@scripts/syntax/date/dateQualifier";
import { dateTBDConst, humanDateFull } from "@scripts/syntax/date/joda";
import { getCustomTitleO } from "@scripts/syntax/pageTitles";
import { parentIdOrId } from "@scripts/syntax/threadThrough";

import { ContactModalCallout } from "@scripts-ssr/components/ContactModal";

import eventIcon from "@svgs/calendar.svg";
import newsIcon from "@svgs/newspaper.svg";

import { useIssuerSitesSelector } from "../../state/store";
import { IssuerSitesLeafIcon } from "../LeafIcon";

type NewsRelatedContent = WithStatusU<TaggedContent<IssuerNews>>;
export const newsMap = (_: NewsRelatedContent, issuer: Issuer) =>
  <div {...klass("d-flex", "flex-col")}>
    <AnchorIcon
      textOrAriaLabel={E.left(<LeafIconLayout leafIcon={IssuerSitesLeafIcon} text={_.data.record.data.newsTitle} leafIconProps={{ taggedContent: _.data.record }} />)}
      icon={newsIcon}
      route={{
        title: _.data.record.data.newsTitle,
        route: SitesRouter.issuersitesAboutControllerNewsItem({ issuerSlug: issuer.slug, issuerId: issuer.id, newsId: parentIdOrId(_) }),
      }}
      target={"_self"}
    />
    {pipe(
      _.data.record.data.newsDate,
      mapOrEmpty(newsDate =>
        <AnchorIconSubtitle>
          Published {humanDateFull(newsDate)}
        </AnchorIconSubtitle>
      )
    )}
  </div>;

export const NewsRelatedContent = (props: { news: RNEA.ReadonlyNonEmptyArray<NewsRelatedContent>, variant: RelatedContentItemVariant }) => {
  const issuer = useIssuerSitesSelector("issuer");
  return <RelatedContentItem
    headline={"News"}
    items={props.news}
    mapFn={newsMap}
    issuer={issuer}
    variant={props.variant}
  />;
};

type EventRelatedContent = HasManyLink<WithStatusU<Event>, RelatedEventLink>;
const eventMap = (_: EventRelatedContent, issuer: Issuer) =>
  <div {...klass("d-flex", "flex-col")}>
    <AnchorIcon
      icon={eventIcon}
      route={{
        title: _.data.data.record.eventTitle,
        route: urlInterface(
          "GET",
          makeDeepLinkModalWithDataUrl(SitesRouter.issuersitesAboutControllerNewsEvents({ issuerSlug: issuer.slug, issuerId: issuer.id }))({
            modalId: sitesEventModal,
            data: _.data,
          }, [])
        ),
      }}
      target={"_self"}
    />
    {pipe(
      _.data.data.record.eventDate,
      mapOrEmpty(eventDate =>
        <AnchorIconSubtitle>
          {humanDateFull(eventDate)}
          {pipe(
            _.data.data.record.endDate,
            mapOrEmpty(endDate => <>&nbsp;-&nbsp;{humanDateFull(endDate)}</>)
          )}
        </AnchorIconSubtitle>
      )
    )}
  </div>;

export const EventsRelatedContent = (props: { events: RNEA.ReadonlyNonEmptyArray<EventRelatedContent>, variant: RelatedContentItemVariant }) => {
  const issuer = useIssuerSitesSelector("issuer");
  return <RelatedContentItem
    headline={"Events"}
    items={props.events}
    mapFn={eventMap}
    issuer={issuer}
    variant={props.variant}
  />;
};

type CusipTableColumns = {
  issueDate: O.Option<DateQualifier>;
  maturityDate: O.Option<DateQualifier>;
  name: string;
  cusip9: string;
};

type CusipTableModel = TableColumnRow<CusipTableColumns, EmptyObject, ["link"]>;

const cusipTableColumns = (config: BLConfigWithLog): CusipTableModel["Columns"] => ({
  "issueDate": {
    title: "Issue Date",
    dataCellKlass: O.none,
    headerComponent: "title",
    dataCellComponent: row => pipe(
      row.issueDate,
      O.fold(
        dateTBDConst,
        qualifierFormat(config)
      )
    ),
  },
  "maturityDate": {
    title: "Maturity Date",
    dataCellKlass: O.none,
    headerComponent: "title",
    dataCellComponent: row => pipe(
      row.maturityDate,
      O.fold(
        dateTBDConst,
        qualifierFormat(config)
      )
    ),
  },
  "name": {
    title: "Name",
    dataCellKlass: O.none,
    headerComponent: "title",
  },
  "cusip9": {
    title: "CUSIP-9",
    dataCellKlass: O.some("cusip-format"),
    headerComponent: "title",
  },
  "link": {
    title: "Link",
    dataCellKlass: O.none,
    headerComponent: "empty",
    dataCellComponent: (row) =>
      <AnchorUnsafe
        arrowType="right"
        externalLinkLocation="none"
        href={emmaCusipActivityUrl(row.cusip9)}
        target={"_blank"}
        title={`View on ${emmaName}`}
      />,
  },
});

export const CusipsTable = (props: { cusips: RNEA.ReadonlyNonEmptyArray<HasManyLink<WithStatusU<Cusip9>, RelatedCusip9Link>> }) => {
  const config = useConfig();

  const tableData = pipe(
    props.cusips,
    RA.map((cusip): CusipTableModel["Row"] => ({
      __klass: O.none,
      __metadata: {},
      __rowId: cusip.data.data.id,
      issueDate: cusip.data.data.record.issueDate,
      name: cusip.data.data.record.bondName,
      maturityDate: cusip.data.data.record.maturityDate,
      cusip9: cusip.data.data.record.cusip,
    }))
  );

  return (
    <Table
      columns={cusipTableColumns(config)}
      data={tableData}
      detailCell={O.none}
      exporter={O.none}
      onParamsChanged={constVoid}
      paginate={O.none}
      searchable={O.none}
      sortable={O.none}
      store={O.none}
      tableAction={O.none}
      variant="issuersites"
    />
  );
};

export const makeProjectsDataO = (iffs: ClientFeatureFlags, projectsArr: ReadonlyArray<ProjectRelatedContent>) =>
  makeRelatedContentDataO(projects, iffs, projectsArr);
export const makeNewsDataO = (iffs: ClientFeatureFlags, newsArr: ReadonlyArray<HasManyLink<NewsRelatedContent, RelatedNewsLink>>) =>
  makeRelatedContentDataO(news, iffs, newsArr.map(_ => _.data));
export const makeEventsDataO = (iffs: ClientFeatureFlags, events: ReadonlyArray<EventRelatedContent>) =>
  makeRelatedContentDataO(financeCalendar, iffs, events);

export const SharedRelatedContent = (props: {
  projects: O.Option<RNEA.ReadonlyNonEmptyArray<ProjectRelatedContent>>;
  news: O.Option<RNEA.ReadonlyNonEmptyArray<NewsRelatedContent>>;
  events: O.Option<RNEA.ReadonlyNonEmptyArray<EventRelatedContent>>;
}) => {
  const issuer = useIssuerSitesSelector("issuer");
  return (
    <>
      {pipe(props.projects, mapOrEmpty(ps => <ProjectsRelatedContent leafIcon={IssuerSitesLeafIcon} projects={ps} issuer={issuer} variant="page" />))}
      {pipe(props.news, mapOrEmpty(n => <NewsRelatedContent news={n} variant="page" />))}
      {pipe(props.events, mapOrEmpty(es => <EventsRelatedContent events={es} variant="page" />))}
    </>
  );
};

export const RelatedContentSection = (props: { children?: ReactNode }) => {
  const pages = useIssuerSitesSelector("pages");
  return (
    <AccentDividerSection jumpLink={jl.relatedContent({})} pages={pages}>
      {props.children}
    </AccentDividerSection>
  );
};

export type RelatedContentTypes = Omit<ProjectRelatedContentType, "photos" | "quickFacts"> & IssuerNewsRelatedContent;
export type RelatedContentType<K> = K extends keyof RelatedContentTypes ? RelatedContentTypes[K] : never;

export const AllRelatedContentSection = <RC extends Partial<RelatedContentTypes>>(props: {
  relatedContent: RC & ({ [K in keyof RC]: RelatedContentType<K> }) & Record<string, unknown>;
}) => {
  const config = useConfig();
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const baseRCProps = { issuer, variant: "page", leafIcon: IssuerSitesLeafIcon } as const;

  const withRC = <A,>(as: ReadonlyArray<A>) => (f: (as: RNEA.ReadonlyNonEmptyArray<A>) => ReactElement) =>
    pipe(RNEA.fromReadonlyArray(as), mapOrEmpty(f));

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const rcKeys = R.keys(props.relatedContent) as ReadonlyArray<keyof RC & keyof RelatedContentTypes>;

  return <RelatedContentSection>
    {rcKeys.map(k => <Fragment key={k}>{(() => {
      switch (k) {
        case "documents":
          return withRC(props.relatedContent[k])(docs => <DocumentsRelatedContent
            {...baseRCProps}
            documents={pipe(docs, RNEA.map(_ => _.data))}
            headline="Documents"
            downloadRoute={(issuerId, mediaId) => SitesRouter.issuersitesReportsControllerViewFile({ issuerId, issuerSlug: issuer.slug, mediaId })}
          />);

        case "events":
          return withRC(props.relatedContent[k])(events => <EventsRelatedContent {...baseRCProps} events={events} />);

        case "externalLinks":
          return withRC(props.relatedContent[k])(links => <LinksRelatedContent {...baseRCProps} links={links} />);

        case "news":
          return withRC(props.relatedContent[k])(ns => <NewsRelatedContent {...baseRCProps} news={pipe(ns, RNEA.map(_ => _.data))} />);

        case "offerings":
          return withRC(props.relatedContent[k])(bonds => <BondsRelatedContent {...baseRCProps} bonds={bonds} />);

        case "programs":
          return withRC(props.relatedContent[k])(programs => <ProgramsRelatedContent {...baseRCProps} programs={programs} />);

        case "projects":
          return withRC(props.relatedContent[k])(ps => <ProjectsRelatedContent {...baseRCProps} projects={ps} />);

        case "rfps":
          return withRC(props.relatedContent[k])(rs => <RfpsRelatedContent
            {...baseRCProps}
            rfps={rs}
            rfpsTitle={rfps({ issuerSlug: issuer.slug, issuerId: issuer.id }).title(pages)}
          />);

        default:
          return config.exhaustive(k);
      }
    })()}</Fragment>)}
  </RelatedContentSection>;
};

export const BondOfferingContactModal = (props: { offering: Subscribed<WithStatusU<TaggedContent<BondOfferingWithRelatedContent>>> }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const user = useIssuerSitesSelector("user");
  const customTitle = getCustomTitleO(requestMoreInfoButton)(useIssuerSitesSelector("pages"));
  return (
    <ContactModalCallout
      btnText={O.getOrElse(() => "Request More Info")(customTitle)}
      bondOfferingId={O.some(props.offering.data.data.id)}
      header={O.getOrElse(() => "Request More Information")(customTitle)}
      introText={"Contact us to get more information about this bond offering"}
      issuer={issuer}
      rfpId={O.none}
      klasses={"mt-0"}
      user={O.map((u: UserWithRoles) => u.user)(user)}
    />
  );
};
