import { useState } from "react";
import { useStableMemo } from "fp-ts-react-stable-hooks";
import { Lens } from "monocle-ts";

import { E, Eq, flow, N, O, Ord, pipe, RA, RNEA, s } from "@scripts/fp-ts";
import { documents, showArchivedDocs } from "@scripts/generated/domaintables/featureFlags";
import type { MediaCategoryU } from "@scripts/generated/domaintables/mediaCategories";
import type { DocumentType } from "@scripts/generated/models/document";
import { AnchorWithChildren } from "@scripts/react/components/Anchor";
import { type ChecklistItem, ChecklistWithEq } from "@scripts/react/components/Checklist";
import { constEmpty } from "@scripts/react/components/Empty";
import { Filtering } from "@scripts/react/components/filters/Filter";
import { type SelectOption, SelectRaw } from "@scripts/react/components/form/Select";
import { GridCol } from "@scripts/react/components/layout/Grid";
import { Grid7525, GridLeft, GridRight } from "@scripts/react/components/layout/PageGrid";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import { FilterButtonSites } from "@scripts/react/components/table/Filter";
import type { SetState } from "@scripts/react/syntax/react";
import { klass } from "@scripts/react/util/classnames";
import { archivedDocuments, downloads, type DownloadsPageData } from "@scripts/routes/routing/ssr/issuersites";
import { isFFEnabled } from "@scripts/syntax/featureFlags";

import { IssuerSitesSearchInputGrid } from "../../components/SearchInput";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";
import { type Category, categoryEq, documentsFilters } from "./documentsFilterSyntax";
import { DocumentsDataMapFn, type DocumentsSortColumnTypes, DocumentsTable } from "./DocumentsTable";

type DocumentData = DownloadsPageData["documents"][number];

const makeMediaCategoryOption = (_: MediaCategoryU): ChecklistItem<Category> => ({ label: _.categoryName, id: E.right(_) });
const makeDocTypeCategory = (_: DocumentType): ChecklistItem<Category> => ({ label: _.categoryName, id: E.left(_) });

const makeYearOption = (year: number): SelectOption<O.Option<number>> => ({ label: year.toString(), value: O.some(year) });

const makeYearOptions = flow(
  RA.map((_: DocumentData) => _.data.record.data.document.mediaDate.date.year()),
  RA.uniq(N.Eq),
  RA.map(makeYearOption),
  RA.prepend<SelectOption<O.Option<number>>>({ label: "All Years", value: O.none })
);

const getSelectedYear = (year: O.Option<number>) => RA.findFirst((_: SelectOption<O.Option<number>>) => O.getEq(N.Eq).equals(_.value, year));

const makeTitle = (defaultTitle: string) => flow(
  RNEA.fromReadonlyArray<Category>,
  O.fold(
    () => defaultTitle,
    categories => {
      if (categories.length === 1) {
        return pipe(
          RNEA.head(categories),
          E.toUnion,
          _ => _.categoryName,
        );
      }
      return `${categories.length} Categories Selected`;
    }
  )
);

const makeCategoryOptions = flow(
  RA.map((document: DocumentData) => pipe(
    document.data.record.data.docType,
    O.fold(
      () => makeMediaCategoryOption(document.data.record.data.category),
      makeDocTypeCategory,
    )
  )),
  RA.uniq(Eq.contramap((_: ChecklistItem<Category>) => _.id)(categoryEq)),
  RA.sort(Ord.contramap((_: ChecklistItem<Category>) => E.toUnion(_.id).categoryName)(s.Ord))
);

type DocumentsFilterState = {
  sortBy: DocumentsSortColumnTypes;
  category: ReadonlyArray<Category>;
  search: O.Option<string>;
  year: O.Option<number>;
  page: O.Option<number>;
};

const initialDocumentsFilterState = (category: O.Option<Category>): DocumentsFilterState => ({
  sortBy: "date-desc",
  category: pipe(category, O.fold(() => [], _ => [_])),
  search: O.none,
  year: O.none,
  page: O.some(1),
});

const documentsFiltersLens = Lens.fromProp<DocumentsFilterState>();

const setFilterState = (state: DocumentsFilterState, setState: SetState<DocumentsFilterState>) =>
  <K extends keyof DocumentsFilterState>(key: K) =>
    (value: DocumentsFilterState[K]) => setState(documentsFiltersLens(key).set(value)(state));

export const DocumentsPage = (props: {
  data: DownloadsPageData;
  variant: "archive" | "downloads";
}) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");
  const downloadsRouteMeta = downloads({ issuerId: issuer.id, issuerSlug: issuer.slug });
  const archiveRouteMeta = archivedDocuments({ issuerId: issuer.id, issuerSlug: issuer.slug });
  const routeMeta = props.variant === "downloads" ? downloadsRouteMeta : archiveRouteMeta;
  const antiRouteMeta = props.variant === "downloads" ? archiveRouteMeta : downloadsRouteMeta;
  const antiRouteText = props.variant === "downloads" ? "View Archived Documents" : "View Current Documents";
  const antiRouteFF = props.variant === "downloads" ? showArchivedDocs : documents;

  const [filters, setFilters] = useState<DocumentsFilterState>(initialDocumentsFilterState(props.data.category));
  const setFilter = setFilterState(filters, setFilters);
  const getFilter = <K extends keyof DocumentsFilterState>(key: K) => documentsFiltersLens<K>(key).get(filters);

  const category = getFilter("category");
  const search = getFilter("search");
  const sortBy = getFilter("sortBy");
  const year = getFilter("year");
  const page = getFilter("page");

  const description = routeMeta.description(pages, iffs, issuer);
  const yearOptions = useStableMemo(() => makeYearOptions(props.data.documents), [props.data.documents], Eq.tuple(RA.getEq(Eq.contramap((_: DocumentData) => _.data.id)(N.Eq))));
  const categoryOptions = useStableMemo(() => makeCategoryOptions(props.data.documents), [props.data.documents], Eq.tuple(RA.getEq(Eq.contramap((_: DocumentData) => _.data.id)(N.Eq))));

  return (
    <div {...klass("container")}>
      <Grid7525>
        <GridLeft klasses="b-0">
          <div {...klass("page-summary large mb-1")}>
            <Markdown content={description} klasses={["last-child-mb-0"]} />
          </div>
          {isFFEnabled(antiRouteFF)(iffs)
            && <AnchorWithChildren aria-label={antiRouteText} target="_self" route={antiRouteMeta} klasses="pagelink mb-1" >{antiRouteText}</AnchorWithChildren>}
        </GridLeft>
        <GridRight hasBorder>
          <GetAlertsActionSection containerKlass={"d-none-until-md"} suppressBorder />
        </GridRight>
      </Grid7525>
      <AccentDividerSection klasses="accent-border-top" title={O.some(makeTitle(props.variant === "downloads" ? "All Documents" : "All Archived Documents")(category))}>
        <FilterButtonSites title="Filter by category">
          <div {...klass("pt-05 pb-1")}>
            <ChecklistWithEq
              items={categoryOptions}
              showSelectButtons
              selectedItems={category}
              setSelectedItems={setFilter("category")}
              variant="column"
              eq={categoryEq}
              getKey={E.fold(_ => _.categoryName, _ => _.categoryName)}
              listKlasses="ml-05"
            />
          </div>
        </FilterButtonSites>
        <IssuerSitesSearchInputGrid
          ariaLabelledById="document-search"
          placeholder={O.some("Search")}
          labelOrAriaLabel={E.right("Document Search")}
          search={search}
          setSearch={setFilter("search")}
        >
          <GridCol cols={[".c-md-5", ".c-lg-4"]} klasses="mb-1 mb-md-0">
            <SelectRaw
              value={getSelectedYear(year)(yearOptions)}
              error={O.none}
              onChange={_ => setFilter("year")(_.value)}
              options={yearOptions}
              isSearchable={false}
              labelOrAriaLabel={E.right("Select year")}
              placeholder={O.none}
              indicatorSeparator={constEmpty}
              required={false}
            />
          </GridCol>
        </IssuerSitesSearchInputGrid>
        <Filtering
          params={filters}
          defaultParams={{ category: [], year: O.none }}
          data={props.data.documents}
          transform={DocumentsDataMapFn}
          filters={documentsFilters}
        >
          {filteredDocs => <DocumentsTable
            documents={filteredDocs}
            sort={{ sortBy, setSortBy: setFilter("sortBy") }}
            search={search}
            page={page}
            setPage={setFilter("page")}
          />}
        </Filtering>
      </AccentDividerSection>
    </div>
  );
};
