import { WithStatusCU as imported501_WithStatusCU, WithStatusU as imported250_WithStatusU, WithStatusCU as imported500_WithStatusCU } from "./threadThrough";
import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { ProgramRatingC as imported17_ProgramRatingC, ProgramRating as imported17_ProgramRating, programRatingC as imported13_programRatingC } from "./ratingBase";
import { RatingAgencyCU as imported69_RatingAgencyCU, RatingAgencyU as imported34_RatingAgencyU, RatingAgencyCU as imported68_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { RatingWithRelatedContentC as imported21_RatingWithRelatedContentC, RatingWithRelatedContent as imported21_RatingWithRelatedContent, ratingWithRelatedContentC as imported21_ratingWithRelatedContentC } from "./rating";
import { BondProgramC as imported21_BondProgramC, BondProgram as imported21_BondProgram, bondProgramC as imported17_bondProgramC } from "./bondProgramBase";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";

export type BondProgramWithRatingsC = t.TypeC<{
  program: imported21_BondProgramC,
  ratings: t.ReadonlyArrayC<t.TupleC<[imported69_RatingAgencyCU, ReadonlyNonEmptyArrayType<imported501_WithStatusCU<imported21_RatingWithRelatedContentC<imported17_ProgramRatingC>>>]>>
}>;
export type BondProgramWithRatings = {
  program: imported21_BondProgram,
  ratings: ReadonlyArray<[imported34_RatingAgencyU, RNEA.ReadonlyNonEmptyArray<imported250_WithStatusU<imported21_RatingWithRelatedContent<imported17_ProgramRating>>>]>
};
export const bondProgramWithRatingsC: BondProgramWithRatingsC = t.type({
  program: imported17_bondProgramC,
  ratings: t.readonlyArray(t.tuple([imported68_RatingAgencyCU, readonlyNonEmptyArrayC(imported500_WithStatusCU(imported21_ratingWithRelatedContentC(imported13_programRatingC)))]))
}) satisfies t.Type<BondProgramWithRatings, unknown>;


