import { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { fold as foldE } from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import type { Parser } from "fp-ts-routing/lib";
import { Route } from "fp-ts-routing/lib";
import type { Dispatch } from "redux";

import type { PortalTypeU } from "@scripts/bootstrap";
import { PortalConfig } from "@scripts/bootstrap";
import { parseDeepLinks } from "@scripts/globals";
import { type NotificationAction } from "@scripts/react/state/notifications";
import { pushState, replaceState } from "@scripts/routes/router";
import type { AnyPageMeta, PageRouting, PageRoutingWithRender } from "@scripts/routes/routing/base";
import { getCurrentPage } from "@scripts/routes/routing/base";
import type { LogErrors, LogValidation } from "@scripts/util/log";

import { analytics, blPageView, gtagPageView } from "../analytics";
import { useConfig } from "./context/Config";
import type { FlashAction } from "./state/flash";
import { flashRemoveAll } from "./state/flash";
import type { RouterAction } from "./state/router";
import { routerSetPageMeta } from "./state/router";
import type { BaseActions } from "./state/store";
import { cleanupListeners, createListeners } from "./util/buildVersion";
import { useSessionChange } from "./util/useSessionChange";

function isAnchorEl(e: Element | null): e is HTMLAnchorElement {
  return e != null && e.tagName === "A";
}

function getAnchorEl(e: Element | null): O.Option<HTMLAnchorElement> {
  return isAnchorEl(e)
    ? O.some(e)
    : e == null
      ? O.none
      : getAnchorEl(e.parentElement);
}

export function interceptTargetClickEvent<T extends BaseActions, P extends PageRouting>
  (ev: MouseEvent, baseUrl: string, router: Parser<P>, dispatch: () => T[]): void {
  pipe(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    O.fromNullable(ev.target as Element | null),
    O.filterMap(getAnchorEl),
    O.bindTo("target"),
    O.bind("href", ({ target }) => O.fromNullable(target.getAttribute("href"))),
    O.map(({ target, href }) => {
      if (href.startsWith(baseUrl) && O.isSome(router.run(Route.parse(href)))) {
        ev.preventDefault();

        const sentryHub = Sentry.getCurrentHub();
        const sentryScope = sentryHub.getScope();
        sentryScope.getTransaction()?.finish();
        sentryScope.setSpan(sentryHub.startTransaction({ name: baseUrl }));

        if (target.target === "_blank") {
          window.open(href.startsWith("https") ? href : window.location.origin + href, "_blank");
        } else {
          document.body.classList.remove("modal-open");
          pushState(href);
          window.scrollTo(0, 0);
          parseDeepLinks(window.location.hash);
          dispatch();
        }
      }
    })
  );
}

export const updateWindowTitle = (location: PortalTypeU, pageTitle: string, url: string): void => {
  const title = `${pageTitle} - ${location} Portal | BondLink`;
  document.title = title;
  replaceState(url);
};

export const bootstrap = <C,>(w: LogValidation<C>, left: (e: LogErrors) => void, right: (w: C) => void, location: PortalTypeU): void => {
  analytics("Portals", location);
  pipe(
    w,
    foldE(left, right)
  );
};

export const useInterceptClickAndPopstate = <A extends PageRoutingWithRender>(
  dispatch: Dispatch<FlashAction | RouterAction>,
  notFound: () => A,
  portalPath: (typeof PortalConfig[keyof typeof PortalConfig])["path"],
  router: Parser<A>,
) => {
  const config = useConfig();

  useEffect(() => {
    const dispatchRouteActions = () => [
      dispatch(flashRemoveAll()),
      dispatch(routerSetPageMeta(getCurrentPage(router, notFound))),
    ];

    const interceptClickEvent = (ev: MouseEvent) => {
      interceptTargetClickEvent(ev, portalPath, router, dispatchRouteActions);
    };

    window.addEventListener("popstate", dispatchRouteActions, { capture: true });
    document.addEventListener("click", interceptClickEvent, { capture: true });

    return () => {
      window.removeEventListener("popstate", dispatchRouteActions);
      document.removeEventListener("click", interceptClickEvent);
    };
  }, [config, dispatch, notFound, portalPath, router]);
};

const usePageViewAndTitleUpdate = (
  page: AnyPageMeta,
  portal: typeof PortalConfig[keyof typeof PortalConfig],
) => {
  const config = useConfig();

  useEffect(() => {
    pipe(
      [page.title(), page.url()],
      ([title, u]: [string, string]) => {
        updateWindowTitle(portal.name, title, u);
        gtagPageView(title, u);
        blPageView(config, u, portal.trackPageview);
      }
    );
  }, [config, page, portal]);
};

export const useVersionChange = (dispatch: Dispatch<NotificationAction>) => {
  const config = useConfig();

  useEffect(() => {
    const commitHashInterval = createListeners(config)(dispatch);

    return () => {
      cleanupListeners(config)(dispatch, commitHashInterval);
    };
  }, [config, dispatch]);
};

export const useBootstrap = <A extends PageRoutingWithRender>(
  dispatch: Dispatch<NotificationAction>,
  notFound: () => A,
  page: AnyPageMeta,
  portal: keyof typeof PortalConfig,
  router: Parser<A>,
) => {
  const portalConfig = PortalConfig[portal];
  useInterceptClickAndPopstate(dispatch, notFound, portalConfig.path, router);
  usePageViewAndTitleUpdate(page, portalConfig);
  useSessionChange(dispatch);
  useVersionChange(dispatch);
};
