import type { ReactElement } from "react";
import { flow, identity, pipe } from "fp-ts/lib/function";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { E, O, RA, type RNEA } from "@scripts/fp-ts";
import type { DayToDay } from "@scripts/generated/domaintables/dateQualifiers";
import type { MethodOfSaleU } from "@scripts/generated/domaintables/methodsOfSale";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { SectorU } from "@scripts/generated/domaintables/sectors";
import type { StateInfoU } from "@scripts/generated/domaintables/states";
import type { BondOfferingWithRelatedContent } from "@scripts/generated/models/bondOffering";
import type { BondOfferingSeries } from "@scripts/generated/models/bondOfferingBase";
import type { DateQualifier } from "@scripts/generated/models/dateQualifier";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import type { Subscribed } from "@scripts/generated/models/subscribed";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import type { FactType } from "@scripts/react/components/Facts";
import { FactsColumn, FactsRow, makeFactO } from "@scripts/react/components/Facts";
import { Markdown } from "@scripts/react/components/Markdown";
import { useConfig } from "@scripts/react/context/Config";
import type { KlassProp } from "@scripts/react/util/classnames";
import { klass } from "@scripts/react/util/classnames";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { qualifierFormat } from "@scripts/syntax/date/dateQualifier";
import { dateTBD, humanDateFull } from "@scripts/syntax/date/joda";
import { currency } from "@scripts/util/currency";

import { AccentDividerSection, DividerSection } from "../layout/Section";
import type { LeafIconAsProp } from "../LeafIcon";
import { type DocumentRelatedContent, type LinkRelatedContent, SummaryRelatedContent } from "./RelatedContent";

export type OfferingSummaryProps = {
  issuer: Issuer;
  pages: ReadonlyArray<PageConfig<PageU>>;
  offering: Subscribed<WithStatusU<TaggedContent<BondOfferingWithRelatedContent>>>;
  sectionId?: string;
  documentsSectionId?: string;
  contactModal: O.Option<ReactElement>;
  documentsO: O.Option<RNEA.ReadonlyNonEmptyArray<DocumentRelatedContent>>;
  linksO: O.Option<RNEA.ReadonlyNonEmptyArray<LinkRelatedContent>>;
  klasses?: KlassProp;
};

const defaultSaleDateFact = {
  title: "Sale Date",
  value: dateTBD,
};

export const saleDateFactO = (config: BLConfigWithLog, saleDate: O.Option<E.Either<DateQualifier, DayToDay>>) => pipe(
  makeFactO(
    defaultSaleDateFact.title,
    saleDate,
    flow(E.toUnion, qualifierFormat(config)),
  ),
  O.alt((): O.Option<FactType> => O.some(defaultSaleDateFact))
);
export const parAmountFactO = (parAmount: O.Option<number>) => makeFactO("Par Amount", parAmount, currency);
export const stateFactO = (state: O.Option<StateInfoU>) => makeFactO("State", state, s => s.name);
export const sectorFactO = (sector: O.Option<SectorU>) => makeFactO("Sector", sector, s => s.name);
export const methodOfSaleFactO = (methodOfSale: O.Option<MethodOfSaleU>) => makeFactO("Method of Sale", methodOfSale, m => m.name);
export const taxStatusFactO = (taxStatus: O.Option<string>) => makeFactO("Tax Status", taxStatus, identity);

export const makeHorizontalFactOs = (config: BLConfigWithLog, seriesOffering: OfferingFactsProps["seriesOffering"]) =>
  [
    parAmountFactO(seriesOffering.data.record.parAmount),
    taxStatusFactO(seriesOffering.data.record.taxStatus),
    saleDateFactO(config, seriesOffering.data.record.saleDate),
  ];

const makeVerticalFactOs = (config: BLConfigWithLog, offering: OfferingFactsProps["offering"], seriesOffering: OfferingFactsProps["seriesOffering"]) =>
  [
    sectorFactO(offering.data.data.record.data.offering.sector),
    stateFactO(offering.data.data.record.data.offering.state),
    makeFactO("Bond Type", seriesOffering.data.record.offeringType, (bt) => bt.name),
    methodOfSaleFactO(seriesOffering.data.record.methodOfSale),
    makeFactO("Closing Date", seriesOffering.data.record.closingDate, qualifierFormat(config)),
    makeFactO("Retail Order Period Begins", seriesOffering.data.record.retailOrderDate, qualifierFormat(config)),
    makeFactO("Call Features", seriesOffering.data.record.callFeatures, identity),
    makeFactO("Dated Date", seriesOffering.data.record.datedDate, humanDateFull),
    makeFactO("Call Date", seriesOffering.data.record.callDate, humanDateFull),
    makeFactO("First Interest Date", seriesOffering.data.record.firstInterestDate, humanDateFull),
    makeFactO("First Principal Date", seriesOffering.data.record.firstPrincipalDate, humanDateFull),
    makeFactO("Date Free to Trade", seriesOffering.data.record.freeToTradeDate, humanDateFull),
    makeFactO("Credit Enhancement", seriesOffering.data.record.creditEnhancement, identity),
  ];

type OfferingFactsProps = Pick<OfferingSummaryProps, "offering"> & {
  isMultiSeries: boolean;
  seriesOffering: WithStatusU<BondOfferingSeries>;
};

const OfferingFacts = (props: OfferingFactsProps) => {
  const config = useConfig();
  const horizontalFactItems = RA.compact(makeHorizontalFactOs(config, props.seriesOffering));
  const verticalFactItems = RA.compact(makeVerticalFactOs(config, props.offering, props.seriesOffering));

  return (
    <div>
      {props.isMultiSeries && <h5>{props.seriesOffering.data.record.name}</h5>}
      <div {...klass("mb-1")}>
        <FactsRow items={horizontalFactItems} variant={"bold"} />
      </div>
      <FactsColumn items={verticalFactItems} />
    </div>
  );
};

const AdditionalInfo = (props: Pick<OfferingSummaryProps, "offering" | "issuer" | "contactModal">) =>
  <DividerSection
    title={pipe(
      props.offering.data.data.record.data.offering.notes,
      O.map(() => "Additional Information")
    )}
  >
    <div {...klass("d-flex", "flex-col", "gap-1")}>
      {pipe(
        props.offering.data.data.record.data.offering.notes,
        mapOrEmpty(info => (
          <Markdown content={info} klasses={["last-child-mb-0"]} />
        ))
      )}
      {mapOrEmpty((m: ReactElement) => <div {...klass("mt-05")}>{m}</div>)(props.contactModal)}
    </div>
  </DividerSection>;

export const OfferingSummary = (props: LeafIconAsProp & OfferingSummaryProps) => {
  const showAdditionalInfo = O.isSome(props.offering.data.data.record.data.offering.notes) || O.isSome(props.contactModal);
  return (
    <AccentDividerSection jumpLink={jl.bondOffering.offeringSummary} pages={props.pages} klasses={props.klasses}>
      <DividerSection title={O.none}>
        <div {...klass("d-flex", "flex-col", "gap-1")}>
          {props.offering.data.data.record.data.offering.series.map(s =>
            <OfferingFacts
              isMultiSeries={props.offering.data.data.record.data.offering.series.length > 1}
              key={s.data.id}
              offering={props.offering}
              seriesOffering={s}
            />
          )}
        </div>
      </DividerSection>
      <SummaryRelatedContent
        documentsO={props.documentsO}
        documentsHeadline="Offering Documents"
        documentDownloadRoute={(issuerId, mediaId) => V2Router.investorPortalOfferingsControllerDownloadDocument({ issuerId, offeringId: props.offering.data.data.id, mediaId })}
        issuer={props.issuer}
        linksO={props.linksO}
        variant="page"
        leafIcon={props.leafIcon}
        sectionId={props.documentsSectionId}
      />
      {pipe(
        showAdditionalInfo,
        trueOrEmpty(
          <AdditionalInfo
            contactModal={props.contactModal}
            issuer={props.issuer}
            offering={props.offering}
          />
        )
      )}
    </AccentDividerSection>
  );
};
