import { Route, query, lit, str, end, type, Match } from "fp-ts-routing/lib";
import * as t from "io-ts";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { UUID } from "io-ts-types/lib/UUID";

import { HtmlC, htmlC } from "../../codecs/html";
import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { Describe } from "../../fp-ts/lib/types/describe";
import { bankIdC } from "../../routes/routing/params/bankId";
import { UrlInterfaceIO, urlInterfaceIO } from "../../routes/urlInterface";
import { BankCalendarDataC as imported0_BankCalendarDataC, bankCalendarDataC as imported0_bankCalendarDataC } from "../models/bankCalendar";
import { ClientTextItemC as imported40_ClientTextItemC, clientTextItemC as imported40_clientTextItemC } from "../models/clientTextItem";
import { DealViewDataC as imported0_DealViewDataC, dealViewDataC as imported0_dealViewDataC } from "../models/dealView";
import { EmptyObjectC as imported0_EmptyObjectC, emptyObjectC as imported0_emptyObjectC } from "../models/emptyObject";
import { WithStatusCU as imported846_WithStatusCU, WithStatusCU as imported847_WithStatusCU } from "../models/threadThrough";

const bankSitesBankControllerCalendarPath = str("bankSlug").then(lit("calendar")).then(type("bankId", bankIdC));
const bankSitesBankControllerCalendarPathParts = ["bankSlug", "calendar", "bankId"] as const;
export type BankSitesBankControllerCalendarPathParts = typeof bankSitesBankControllerCalendarPathParts;

const bankSitesBankControllerCalendarQuery = t.strict({});
export type BankSitesBankControllerCalendarParams = Describe<typeof bankSitesBankControllerCalendarPath._A & typeof bankSitesBankControllerCalendarQuery._A>;

export type BankSitesBankControllerCalendarRoute<A = {}> = { match: Match<BankSitesBankControllerCalendarParams & A>; pathParts: BankSitesBankControllerCalendarPathParts };

export function bankSitesBankControllerCalendarRoute(): BankSitesBankControllerCalendarRoute;
export function bankSitesBankControllerCalendarRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BankSitesBankControllerCalendarParams>): BankSitesBankControllerCalendarRoute<t.TypeOf<A>>;
export function bankSitesBankControllerCalendarRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BankSitesBankControllerCalendarParams>) {
  return {
    match: (q ? bankSitesBankControllerCalendarPath.then(query(t.intersection([bankSitesBankControllerCalendarQuery, q]))) : bankSitesBankControllerCalendarPath.then(query(bankSitesBankControllerCalendarQuery))).then(end),
    pathParts: bankSitesBankControllerCalendarPathParts,
  };
}

export const bankSitesBankControllerCalendarCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_BankCalendarDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_bankCalendarDataC };

export const bankSitesBankControllerCalendar = (p: BankSitesBankControllerCalendarParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  urlInterfaceIO("GET", bankSitesBankControllerCalendarRoute().match.formatter.run(Route.empty, p).toString(true), bankSitesBankControllerCalendarCodecs);

const bankSitesBankControllerGetTextItemPath = lit("v2").then(str("bankSlug")).then(lit("text-items")).then(type("textType", t.union([
  t.literal("about"), t.literal("welcome"), t.literal("offering-info"), t.literal("contact-us"), t.literal("custom-page-1"), t.literal("custom-page-2"), t.literal("custom-page-3"), t.literal("custom-page-4"), t.literal("custom-page-5"), t.literal("portal-tip"), t.literal("esg-overview")
]))).then(type("bankId", bankIdC));
const bankSitesBankControllerGetTextItemPathParts = ["v2", "bankSlug", "text-items", "textType", "bankId"] as const;
export type BankSitesBankControllerGetTextItemPathParts = typeof bankSitesBankControllerGetTextItemPathParts;

const bankSitesBankControllerGetTextItemQuery = t.strict({});
export type BankSitesBankControllerGetTextItemParams = Describe<typeof bankSitesBankControllerGetTextItemPath._A & typeof bankSitesBankControllerGetTextItemQuery._A>;

export type BankSitesBankControllerGetTextItemRoute<A = {}> = { match: Match<BankSitesBankControllerGetTextItemParams & A>; pathParts: BankSitesBankControllerGetTextItemPathParts };

export function bankSitesBankControllerGetTextItemRoute(): BankSitesBankControllerGetTextItemRoute;
export function bankSitesBankControllerGetTextItemRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BankSitesBankControllerGetTextItemParams>): BankSitesBankControllerGetTextItemRoute<t.TypeOf<A>>;
export function bankSitesBankControllerGetTextItemRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BankSitesBankControllerGetTextItemParams>) {
  return {
    match: (q ? bankSitesBankControllerGetTextItemPath.then(query(t.intersection([bankSitesBankControllerGetTextItemQuery, q]))) : bankSitesBankControllerGetTextItemPath.then(query(bankSitesBankControllerGetTextItemQuery))).then(end),
    pathParts: bankSitesBankControllerGetTextItemPathParts,
  };
}

export const bankSitesBankControllerGetTextItemCodecs: {
  input: imported0_EmptyObjectC,
  output: OptionFromNullableC<imported846_WithStatusCU<imported40_ClientTextItemC>>
} = { input: imported0_emptyObjectC, output: optionFromNullable(imported847_WithStatusCU(imported40_clientTextItemC)) };

export const bankSitesBankControllerGetTextItem = (p: BankSitesBankControllerGetTextItemParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, OptionFromNullableC<imported846_WithStatusCU<imported40_ClientTextItemC>>> =>
  urlInterfaceIO("GET", bankSitesBankControllerGetTextItemRoute().match.formatter.run(Route.empty, p).toString(true), bankSitesBankControllerGetTextItemCodecs);

const bankSitesDealViewControllerDealViewPath = str("bankSlug").then(lit("deal-view")).then(type("bankId", bankIdC));
const bankSitesDealViewControllerDealViewPathParts = ["bankSlug", "deal-view", "bankId"] as const;
export type BankSitesDealViewControllerDealViewPathParts = typeof bankSitesDealViewControllerDealViewPathParts;

const bankSitesDealViewControllerDealViewQuery = t.exact(t.type({
  "id": UUID
}));
export type BankSitesDealViewControllerDealViewParams = Describe<typeof bankSitesDealViewControllerDealViewPath._A & typeof bankSitesDealViewControllerDealViewQuery._A>;

export type BankSitesDealViewControllerDealViewRoute<A = {}> = { match: Match<BankSitesDealViewControllerDealViewParams & A>; pathParts: BankSitesDealViewControllerDealViewPathParts };

export function bankSitesDealViewControllerDealViewRoute(): BankSitesDealViewControllerDealViewRoute;
export function bankSitesDealViewControllerDealViewRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BankSitesDealViewControllerDealViewParams>): BankSitesDealViewControllerDealViewRoute<t.TypeOf<A>>;
export function bankSitesDealViewControllerDealViewRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BankSitesDealViewControllerDealViewParams>) {
  return {
    match: (q ? bankSitesDealViewControllerDealViewPath.then(query(t.intersection([bankSitesDealViewControllerDealViewQuery, q]))) : bankSitesDealViewControllerDealViewPath.then(query(bankSitesDealViewControllerDealViewQuery))).then(end),
    pathParts: bankSitesDealViewControllerDealViewPathParts,
  };
}

export const bankSitesDealViewControllerDealViewCodecs: {
  input: imported0_EmptyObjectC,
  output: HtmlC,
  ssrInput: imported0_DealViewDataC
} = { input: imported0_emptyObjectC, output: htmlC, ssrInput: imported0_dealViewDataC };

export const bankSitesDealViewControllerDealView = (p: BankSitesDealViewControllerDealViewParams): UrlInterfaceIO<"GET", imported0_EmptyObjectC, HtmlC> =>
  urlInterfaceIO("GET", bankSitesDealViewControllerDealViewRoute().match.formatter.run(Route.empty, p).toString(true), bankSitesDealViewControllerDealViewCodecs);