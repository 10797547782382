import { type ReactNode, useEffect } from "react";

import { O, pipe, RA } from "@scripts/fp-ts";
import { bankCalendar } from "@scripts/generated/domaintables/featureFlags";
import { viewBond } from "@scripts/generated/domaintables/loginReasons";
import { bondsPage } from "@scripts/generated/domaintables/pages";
import type { Bank } from "@scripts/generated/models/bank";
import { klass } from "@scripts/react/util/classnames";
import { useModal } from "@scripts/react/util/useModal";
import { isFFEnabled } from "@scripts/syntax/featureFlags";
import { tagEq } from "@scripts/util/compare";

import { PrimaryChrome as BanksitesChrome } from "@scripts-ssr/components/banksites-chrome/PrimaryChrome";
import { Footer } from "@scripts-ssr/components/Footer";
import { LoginWallModal } from "@scripts-ssr/components/LoginWall";

import { useIssuerSitesDispatch, useIssuerSitesSelector } from "../state/store";
import { DirectIssuerMainNav } from "./DirectIssuerMainNav";
import { IssuerFooter } from "./IssuerFooter";
import { Masthead } from "./Masthead";
import type { PageProps } from "./Page";

export const Chrome = (props: Pick<PageProps, "routeMeta" | "title" | "photoPage"> & { children: ReactNode }) => {
  const disclaimers = useIssuerSitesSelector("disclaimers");
  const footerDisclaimerTypes = useIssuerSitesSelector("footerDisclaimerTypes");
  const footerLinks = useIssuerSitesSelector("footerLinks");
  const prefs = useIssuerSitesSelector("prefs");

  return (
    <>
      <DirectIssuerMainNav />
      <main {...klass("content-container")}>
        <Masthead routeMeta={props.routeMeta} title={props.title} photoPage={props.photoPage} />
        {props.children}
      </main>
      <IssuerFooter
        disclaimers={disclaimers}
        footerDisclaimerTypes={footerDisclaimerTypes}
        footerLinks={footerLinks}
        prefs={prefs}
      />
      <Footer disclaimerType="general" />
    </>
  );
};

export const BankIssuerChrome = (props: { bank: Bank, children: ReactNode, loginRequired: boolean, loginRedirectUrl: string }) => {
  const bankFeatureFlags = useIssuerSitesSelector("bankFeatureFlags");
  const disclaimers = useIssuerSitesSelector("disclaimers");
  const flash = useIssuerSitesSelector("flash");
  const footerDisclaimerTypes = useIssuerSitesSelector("footerDisclaimerTypes");
  const footerLinks = useIssuerSitesSelector("footerLinks");
  const headerPhotos = useIssuerSitesSelector("headerPhotos");
  const issuer = useIssuerSitesSelector("issuer");
  const notifications = useIssuerSitesSelector("notifications");
  const prefs = useIssuerSitesSelector("prefs");
  const user = useIssuerSitesSelector("user");

  const dispatch = useIssuerSitesDispatch();

  const isLoggedIn = O.isSome(user);
  const [loginWallOpen, openLoginWall, closeLoginWall] = useModal("BLP Offering Login Wall");
  useEffect(() => {
    if (props.loginRequired && !isLoggedIn) {
      openLoginWall();
    } else {
      closeLoginWall();
    }
  }, [props.loginRequired, isLoggedIn, openLoginWall, closeLoginWall]);

  return (<>
    <BanksitesChrome
      bank={props.bank}
      dispatch={dispatch}
      flash={flash}
      notifications={notifications.notifications}
      imageO={pipe(headerPhotos, RA.findFirst(p => tagEq().equals(p[0], bondsPage)))}
      issuerFooter={O.some(
        <IssuerFooter
          disclaimers={disclaimers}
          footerDisclaimerTypes={footerDisclaimerTypes}
          footerLinks={footerLinks}
          prefs={prefs}
        />
      )}
      title={issuer.name}
      user={user}
      issuer={O.some(issuer)}
      hasCalendar={isFFEnabled(bankCalendar)(bankFeatureFlags)}
    >
      {props.children}
    </BanksitesChrome>
    <LoginWallModal
      modalOpen={loginWallOpen}
      redirect={O.some(props.loginRedirectUrl)}
      reason={O.some(viewBond)}
    />
  </>
  );
};
