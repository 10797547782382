import type { PropsWithChildren } from "react";

import { Svg } from "@scripts/react/components/Svg";
import { klass } from "@scripts/react/util/classnames";

import uploadIcon from "@svgs/upload.svg";

import { LoadingCircle } from "../LoadingCircle";

export const UploadArea = (p: PropsWithChildren<object> & { inProgress: boolean }) =>
  p.inProgress ? (
    <div  {...klass("d-flex", "align-self-start")}>
      <LoadingCircle loadingMsg={"Uploading file..."} />
    </div>
  ) : (
    <div  {...klass("d-flex", "flex-col", "align-items-center")}>
      {p.children}
        <Svg src={uploadIcon} />
      <span {...klass("text-center")}>Drag and drop a file here or <a {...klass("no-decoration", "d-inline-block")} target="_blank">select from your device</a></span>
    </div>
  );
