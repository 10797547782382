import { useCallback, useEffect, useState } from "react";
import * as RS from "fp-ts/lib/ReadonlySet";
import { useStable, useStableO } from "fp-ts-react-stable-hooks";

import { apiFetchWithCredResp } from "@scripts/api/methods";
import { type BLConfigWithLog, formatS3CdnUrl } from "@scripts/bondlink";
import { Static } from "@scripts/bondlinkStatic";
import { constVoid, flow, N, O, pipe, RA, TE } from "@scripts/fp-ts";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { RoadShowSlide } from "@scripts/generated/models/roadshow";
import type { WithId } from "@scripts/generated/models/threadThrough";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { ContactBondLinkAnchor, ReloadAnchor } from "@scripts/react/components/Anchor";
import { ButtonPrimary, ButtonSecondary } from "@scripts/react/components/Button";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Html } from "@scripts/react/components/Html";
import { DividerSection } from "@scripts/react/components/layout/Section";
import { Modal } from "@scripts/react/components/modal/Modal";
import { useConfig } from "@scripts/react/context/Config";
import { type AudioErrorAction, useAudioPlayer } from "@scripts/react/hooks/useAudioPlayer";
import { closeModalFn, useModal } from "@scripts/react/util/useModal";
import type { RoadshowPlayerData } from "@scripts/routes/routing/ssr/issuersites";
import { markdownToHtml } from "@scripts/syntax/markdown";
import { bondlinkAdminRoles, hasBLRoles } from "@scripts/syntax/user";
import { isServerSide } from "@scripts/util/ssr";

import { useIssuerSitesSelector } from "../../../state/store";
import { Header } from "./Header";
import { prevActiveAndNextSlides } from "./roadshowSyntax";
import { Slide } from "./Slide";
import { SlideList } from "./SlideList";

export type RoadshowPlayerProps = { data: RoadshowPlayerData };

const postReportProgress = (
  config: BLConfigWithLog,
  issuer: Issuer,
  data: RoadshowPlayerData,
  furthestSeek: number,
  slidesViewed: number,
): void => {
  TE.run(apiFetchWithCredResp(config)(SitesRouter.issuersitesRoadShowControllerReportProgress())({
    issuerId: issuer.id,
    roadShowId: data.show.id,
    sessionId: data.sessionId,
    slidesViewed,
    furthestSeek,
  })(constVoid, constVoid));
};

export const RoadshowPlayer = (props: { data: RoadshowPlayerData }) => {
  const config = useConfig();
  const issuer = useIssuerSitesSelector("issuer");
  const user = useIssuerSitesSelector("user");
  const hasAudio = props.data.show.record.data.show.audio;
  const [slideListOpen, setSlideListOpen] = useState(true);
  const [viewedSlideIds, setViewedSlideIds] = useStable<ReadonlySet<number>>(new Set(), RS.getEq(N.Eq));
  const [furthestReportedSeek, setFurthestReportedSeek] = useState(pipe(props.data.view, O.fold(() => 0, _ => _.furthestSeek)));
  const [reportedSlidesViewed, setReportedSlidesViewed] = useState(pipe(props.data.view, O.fold(() => 0, _ => _.slidesViewed)));
  const [activeSlide, setActiveSlide] = useStableO<[WithId<RoadShowSlide>, number]>(O.none);
  const [nextSlide, setNextSlide] = useStableO<WithId<RoadShowSlide>>(O.none);
  const [prevSlide, setPrevSlide] = useStableO<WithId<RoadShowSlide>>(O.none);
  const [errorAction, setErrorAction] = useStableO<AudioErrorAction>(O.none);

  const [resumePlayModalOpen, openResumePlayModal, closeResumePlayModal] = useModal("Roadshow resume play modal");
  const [disclaimerModalOpen, openDisclaimerModal, closeDisclaimerModal] = useModal("Roadshow disclaimer modal");
  const [errorModalOpen, openErrorModal, closeErrorModal] = useModal("Roadshow error modal");

  const updateViewedSlideIds = useCallback((seekTime: number) => pipe(
    prevActiveAndNextSlides(props.data.show.record.data.slides, seekTime, hasAudio),
    O.fold(
      () => {
        setPrevSlide(O.none);
        setActiveSlide(O.none);
        setNextSlide(O.none);
        return viewedSlideIds;
      },
      ([prev, active, next]) => {
        setPrevSlide(prev);
        setActiveSlide(O.some(active));
        const newViewedSlideIds = RS.insert(N.Eq)(active[0].id)(viewedSlideIds);
        setViewedSlideIds(newViewedSlideIds);
        setNextSlide(next);
        return newViewedSlideIds;
      },
    )
  ), [props.data.show.record.data.slides, hasAudio, setPrevSlide, setActiveSlide, viewedSlideIds, setViewedSlideIds, setNextSlide]);

  const reportProgress = useCallback((seek0: number) => {
    const seek = Math.floor(seek0);
    const slidesViewed = updateViewedSlideIds(seek).size;

    if (seek <= furthestReportedSeek && slidesViewed <= reportedSlidesViewed) {
      return;
    }

    setFurthestReportedSeek(seek);
    setReportedSlidesViewed(slidesViewed);
    postReportProgress(config, issuer, props.data, seek, slidesViewed);
  }, [updateViewedSlideIds, furthestReportedSeek, reportedSlidesViewed, config, issuer, props.data]);

  /*
  Seeking forward is only allowed if any of the following are true:
    1. The logged in user is a BL admin
    2. The roadshow has no audio
    3. The roadshow was marked as `skippable`
  */
  const canSeekForward = pipe(user, O.exists(u => O.isSome(hasBLRoles(bondlinkAdminRoles, u))))
    || !hasAudio
    || props.data.show.record.data.show.skippable;

  const audio = useAudioPlayer({
    audioUrl: O.fromPredicate(() => hasAudio)(formatS3CdnUrl(config)(props.data.show.record.data.mp3.uploadResponse.uri)),
    initFurthestSeek: pipe(props.data.view, O.map(_ => _.furthestSeek)),
    canSeekForward,
    reportProgress,
    errorHandler: action => setErrorAction(O.some(action)),
  });

  const resumableView = useCallback(() => pipe(
    props.data.view,
    O.filter(_ => props.data.show.record.data.show.audio && _.furthestSeek > 0),
  ), [props.data.view, props.data.show.record.data.show.audio]);

  useEffect(() => {
    pipe(resumableView(), O.map(() => openResumePlayModal()));
    openDisclaimerModal();
  }, [resumableView, openResumePlayModal, openDisclaimerModal]);

  // Update previous, active, and next slides based on `seekTime`
  useEffect(() => {
    updateViewedSlideIds(audio.seekTime);
  }, [updateViewedSlideIds, audio.seekTime]);

  useEffect(() => {
    pipe(errorAction, O.map(() => openErrorModal()));
  }, [errorAction, openErrorModal]);

  // Open the slide list at LG+, otherwise close it
  const [media] = useStableO(isServerSide() ? O.none : O.some(window.matchMedia(Static.matchMedia.lg)));
  const mediaChangeListener = (m: MediaQueryListEvent) => setSlideListOpen(m.matches);

  useEffect(() => pipe(media, O.fold(
    () => constVoid,
    m => {
      setSlideListOpen(m.matches);
      m.addEventListener("change", mediaChangeListener);
      return () => m.removeEventListener("change", mediaChangeListener);
    }
  )), [media]);

  return <>
    <div className="roadshow">
      <Header {...props} slideListOpen={slideListOpen} setSlideListOpen={setSlideListOpen} />
      <div
        className="roadshow-container"
        ref={flow(O.fromNullable, O.map(_ => _.oncontextmenu = e => e.preventDefault()))}
      >
        <SlideList
          {...props}
          {...audio}
          prevSlide={prevSlide}
          activeSlide={activeSlide}
          nextSlide={nextSlide}
          slideListOpen={slideListOpen}
          setSlideListOpen={setSlideListOpen}
          viewedSlideIds={viewedSlideIds}
        />
        <Slide
          {...props}
          {...audio}
          prevSlide={prevSlide}
          activeSlide={activeSlide}
          nextSlide={nextSlide}
        />
      </div>
    </div>
    {pipe(resumableView(), mapOrEmpty(v => <Modal
      id="roadshow-resume-play-modal"
      title="Resume Play"
      body={<>
        Do you want to resume play from where you left off?
        <div className="mt-1">
          <ButtonPrimary
            className="mr-1"
            onClick={() => {
              audio.seekToTime(v.furthestSeek);
              audio.setPlaying(true);
              setViewedSlideIds(pipe(
                props.data.show.record.data.slides,
                RA.takeLeft(v.slidesViewed),
                RA.map(s => s.id),
                RS.fromReadonlyArray(N.Eq),
              ));
              closeResumePlayModal();
            }}
          >Resume</ButtonPrimary>
          <ButtonSecondary onClick={closeResumePlayModal}>Cancel</ButtonSecondary>
        </div>
      </>}
      dismissAction={closeResumePlayModal}
      icon={O.none}
      type="primary"
      open={resumePlayModalOpen}
      size="modal-sm"
    />))}
    <Modal
      id="roadshow-disclaimer-modal"
      title="Disclaimer"
      body={<>
        <DividerSection title={O.none}>
          <Html content={markdownToHtml(config)(props.data.show.record.data.show.disclaimer)} />
        </DividerSection>
        <DividerSection title={O.none} klasses={"pt-05"}>
          <ButtonPrimary onClick={() => {
            audio.load();
            closeDisclaimerModal();
          }}
          >Accept</ButtonPrimary>
        </DividerSection>
      </>}
      dismissAction={closeDisclaimerModal}
      escapable={false}
      icon={O.none}
      type="primary"
      open={disclaimerModalOpen}
      size="modal-lg"
    />
    {pipe(errorAction, mapOrEmpty(action =>
      <Modal
        id="roadshow-error-modal"
        title="Audio Error"
        body={<>
          <p>We detected an issue while {action} this audio. Please try <ReloadAnchor title="reloading" /> the page.</p>
          <p className="mb-0">
            If the problem continues, please ensure you have a working sound device and that your internet browser
            is capable of playing MP3 audio files, or <ContactBondLinkAnchor title="contact BondLink Support" />.
          </p>
        </>}
        dismissAction={closeModalFn(() => {
          closeErrorModal();
          setErrorAction(O.none);
        })}
        icon={O.none}
        type="primary"
        open={errorModalOpen}
        size="modal-sm"
      />
    ))}
  </>;
};
