import type { ReactElement } from "react";
import type * as t from "io-ts";

import type { Markdown, MarkdownTag } from "@scripts/codecs/markdown";
import { constVoid, flow, O, pipe } from "@scripts/fp-ts";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { BLConfig } from "@scripts/generated/models/blConfig";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import { type DataMetaBase, displayName } from "@scripts/meta/dataMeta";
import type { UrlInterface } from "@scripts/routes/urlInterface";
import { getPage } from "@scripts/syntax/pageConfig";
import { getCustomTitleO } from "@scripts/syntax/pageTitles";

export type RouteMeta<A, O> = {
  readonly _tag: string;
  propsCodec: t.Type<A, O>;
  url: () => string;
};

export type RouteMetaWithRender<A, O> = RouteMeta<A, O> & {
  render: (props: A) => ReactElement<A>;
};

export const navigate = (config: BLConfig) => (urlInterface: UrlInterface<"GET">): void => O.fold<Location, void>(
  constVoid,
  (location) => location.href = `${config.baseUrl}${urlInterface.url}`
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
)(O.fromNullable(globalThis?.location));

export const getEditableTitleOrDefault = (page: PageU, dataMeta: DataMetaBase<string>) =>
  (pages: ReadonlyArray<PageConfig<PageU>>): string =>
    O.getOrElse(() => displayName(dataMeta))(getCustomTitleO(page)(pages));

export const getPageCustomDescriptionOrDefault = (
  page: PageU,
  makeDefaultIntro: (p: O.Option<PageConfig<PageU>>) => Markdown,
): (as: ReadonlyArray<PageConfig<PageU>>) => MarkdownTag => {
  return flow(
    getPage(page),
    O.fold(
      () => makeDefaultIntro(O.none),
      (p) => pipe(
        p.introSentence,
        O.getOrElse(() => makeDefaultIntro(O.some(p)))
      ),
    ),
  );
};
