import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { RankedMetricC as imported4_RankedMetricC, RankedMetric as imported4_RankedMetric, rankedMetricC as imported4_rankedMetricC } from "./profileMetrics";
import * as O from "fp-ts/lib/Option";
import { LocalDateTime } from "@js-joda/core";
import { EitherC, either } from "io-ts-types/lib/either";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { NextReminderDataC as imported1_NextReminderDataC, NextReminderData as imported1_NextReminderData, nextReminderDataC as imported1_nextReminderDataC } from "./reminders";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type PortalTipC = t.TypeC<{
  tip: MarkdownC
}>;
export type PortalTip = {
  tip: Markdown
};
export const portalTipC: PortalTipC = t.type({
  tip: markdownC
}) satisfies t.Type<PortalTip, unknown>;


export type RecentEngagementC = t.TypeC<{
  engagements: t.NumberC,
  investors: t.NumberC,
  time: EitherC<t.NumberC, LocalDateTimeFromIsoStringC>
}>;
export type RecentEngagement = {
  engagements: number,
  investors: number,
  time: E.Either<number, LocalDateTime>
};
export const recentEngagementC: RecentEngagementC = t.type({
  engagements: t.number,
  investors: t.number,
  time: either(t.number, LocalDateTimeFromIsoStringC)
}) satisfies t.Type<RecentEngagement, unknown>;


export type WelcomeBoxDataC = t.TypeC<{
  recentEngagement: OptionFromNullableC<RecentEngagementC>,
  portalTip: OptionFromNullableC<PortalTipC>,
  issuerPortalTip: OptionFromNullableC<PortalTipC>,
  personalizedPortalTip: OptionFromNullableC<imported4_RankedMetricC>,
  nextReminder: OptionFromNullableC<imported1_NextReminderDataC>,
  openDraftCount: OptionFromNullableC<t.NumberC>,
  pendingCusipCount: OptionFromNullableC<t.NumberC>
}>;
export type WelcomeBoxData = {
  recentEngagement: O.Option<RecentEngagement>,
  portalTip: O.Option<PortalTip>,
  issuerPortalTip: O.Option<PortalTip>,
  personalizedPortalTip: O.Option<imported4_RankedMetric>,
  nextReminder: O.Option<imported1_NextReminderData>,
  openDraftCount: O.Option<number>,
  pendingCusipCount: O.Option<number>
};
export const welcomeBoxDataC: WelcomeBoxDataC = t.type({
  recentEngagement: optionFromNullable(recentEngagementC),
  portalTip: optionFromNullable(portalTipC),
  issuerPortalTip: optionFromNullable(portalTipC),
  personalizedPortalTip: optionFromNullable(imported4_rankedMetricC),
  nextReminder: optionFromNullable(imported1_nextReminderDataC),
  openDraftCount: optionFromNullable(t.number),
  pendingCusipCount: optionFromNullable(t.number)
}) satisfies t.Type<WelcomeBoxData, unknown>;


