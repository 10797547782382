import { formatS3CdnUrl } from "@scripts/bondlink";
import { O } from "@scripts/fp-ts";
import type { Issuer } from "@scripts/generated/models/issuer";
import * as AssetsRouter from "@scripts/generated/routers/assetsRouter";
import * as CorpRouter from "@scripts/generated/routers/corpRouter";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { AnchorWithChildren } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { useConfig } from "@scripts/react/context/Config";
import type { PageRouteBasic } from "@scripts/routes/routing/base";
import { absoluteUrl } from "@scripts/util/url";

export const BondlinkLogoForNav = () => {
  const config = useConfig();
  return <div className="bondlink-logo">
    <AnchorWithChildren
      aria-label="BondLink Homepage"
      target="_self"
      route={{
        title: "BondLink",
        route: absoluteUrl(config)(CorpRouter.corporateStaticIndex()),
      }}
      klasses="no-decoration"
    >
      <img src={AssetsRouter.assetImgLogosBondlink2019InvertedSvg().url} alt="BondLink" />
    </AnchorWithChildren>
  </div>;
};

export const nameLengthToClass = (name: string, bankOrIssuer: "bank" | "issuer") => {
  const shortMax = bankOrIssuer === "bank" ? 20 : 25;
  const mediumMax = bankOrIssuer === "bank" ? 35 : 40;
  return name.length <= shortMax
    ? "short"
    : name.length <= mediumMax
      ? "medium"
      : "long";
};

export const IssuerLogoImg = (props: { issuer: Issuer }) => {
  const config = useConfig();

  return mapOrEmpty<string>((logoUrl) =>
    <img src={formatS3CdnUrl(config)(logoUrl)} alt={`${props.issuer.name} - Official Seal or Logo`} />
  )(props.issuer.logoUrl);
};

const IssuerLogoChildren = (props: { issuer: Issuer }) =>
  <>
    <div className={`image ${props.issuer.logoIsRectangle ? "horizontal" : ""}`}>
      <IssuerLogoImg issuer={props.issuer} />
    </div>
    <div className="issuer-headers">
      <h2 className={`headers-primary ${nameLengthToClass(props.issuer.name, "issuer")}`}>{props.issuer.name}</h2>
      {mapOrEmpty<string>((subheader) => <h3 className="headers-secondary">{subheader}</h3>)(props.issuer.subheader)}
    </div>
  </>;

export const issuerLogoRoute = (issuer: Issuer): PageRouteBasic => ({
  title: issuer.name,
  route: {
    method: "GET",
    url: SitesRouter.issuersitesIssuerControllerIndex({ issuerSlug: issuer.slug, issuerId: issuer.id }).url,
  },
});

export const IssuerLogo = (props: { issuer: Issuer }) =>
  <div className={`issuer-logo ${props.issuer.logoIsRectangle ? "horizontal" : "square"}`}>
    {O.isNone(props.issuer.bankId)
      ? <AnchorWithChildren
        aria-label={`Homepage for ${props.issuer.name}`}
        target="_self"
        route={issuerLogoRoute(props.issuer)}
        klasses={`no-decoration d-flex align-items-center ${props.issuer.logoIsRectangle ? "flex-col" : ""}`}
      >
        <IssuerLogoChildren issuer={props.issuer} />
      </AnchorWithChildren>
      : <IssuerLogoChildren issuer={props.issuer} />}
  </div>;
