import { O, pipe, RA } from "@scripts/fp-ts";
import type { BondsSsrData } from "@scripts/generated/models/bondsSsrData";
import type { Issuer } from "@scripts/generated/models/issuer";
import { AnchorButtonWithChildren } from "@scripts/react/components/Anchor";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { RatingCard } from "@scripts/react/components/ratings/RatingCard";
import { RatingDetailsModal } from "@scripts/react/components/ratings/RatingDetailsModal";
import { klass } from "@scripts/react/util/classnames";
import { useModalStableO } from "@scripts/react/util/useModal";
import { ratings } from "@scripts/routes/routing/ssr/issuersites";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import type { CommonRatingsByAgency } from "@scripts/syntax/rating";

import { useIssuerSitesSelector } from "../../state/store";

export const ratingsSectionTitle = "Ratings";
export const RatingsSection = (props: { issuerRatings: BondsSsrData["issuerRatings"], programRatings: BondsSsrData["programRatings"], issuer: Issuer }) => {
  const pages = useIssuerSitesSelector("pages");
  const [detailsModalOpen, ratingDetails, openDetailsModal, closeDetailsModal] = useModalStableO<{ ratings: CommonRatingsByAgency, title: string }>("Rating Details Modal");

  const openDetailsModalFull = (title: string) => (ratingsByAgency: CommonRatingsByAgency) => openDetailsModal({ ratings: ratingsByAgency, title });

  const issuerRatingsSectionTitle = jl.ratings.issuerRatings(props.issuerRatings).text(pages);

  return <AccentDividerSection jumpLink={jl.bonds.ratings} pages={pages}>
    {!props.issuerRatings.length && !props.programRatings.length && <p>There are currently no ratings available.</p>}
    <div {...klass("grid grid-sx-2 rg-2")}>
      {pipe(
        props.issuerRatings,
        O.fromPredicate(RA.isNonEmpty),
        mapOrEmpty(r =>
          <div {...klass("c-24 c-lg-12")}>
            <RatingCard
              openDetailsModal={openDetailsModalFull(issuerRatingsSectionTitle)}
              programName={issuerRatingsSectionTitle}
              ratings={r}
            />
          </div>
        )
      )}
      {pipe(
        props.programRatings,
        RA.map(a =>
          <div {...klass("c-24 c-lg-12")} key={a[0].id}>
            <RatingCard
              openDetailsModal={openDetailsModalFull(a[0].record.name)}
              programName={a[0].record.name}
              ratings={a[1]}
            />
          </div>
        )
      )}
    </div>
    {pipe(
      ratingDetails,
      mapOrEmpty(({ ratings: ratingsByAgency, title }) =>
        <RatingDetailsModal
          issuer={props.issuer}
          modalOpen={detailsModalOpen}
          dismissAction={closeDetailsModal}
          name={title}
          ratings={ratingsByAgency}
          defaultAgency={O.none}
        />
      ))}
    <AnchorButtonWithChildren klasses="mt-2" target="_self" route={ratings({ issuerId: props.issuer.id, issuerSlug: props.issuer.slug })} variant="primary">View all Ratings</AnchorButtonWithChildren>
  </AccentDividerSection>;
};
