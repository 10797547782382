import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types";

export const pageLinkC = t.type({
  _tag: t.literal(`PageLink`),
  displayName: t.string,
  url: t.string,
  description: optionFromNullable(t.string),
  enabled: t.boolean,
});

export type PageLink = t.TypeOf<typeof pageLinkC>;

export const externalLinkC = t.type({
  _tag: t.literal(`ExternalLink`),
  displayName: t.string,
  url: t.string,
});

export type ExternalLink = t.TypeOf<typeof externalLinkC>;

export const downloadLinkC = t.type({
  _tag: t.literal(`DownloadLink`),
  displayName: t.string,
  url: t.string,
  mediaId: t.number,
});

export type DownloadLink = t.TypeOf<typeof downloadLinkC>;

export type LinkableU = PageLink | ExternalLink | DownloadLink;
export const LinkableCU = t.union([pageLinkC, externalLinkC, downloadLinkC]);
