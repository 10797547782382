import { flow } from "fp-ts/lib/function";
import type { Ord } from "fp-ts/lib/Ord";
import { fromCompare } from "fp-ts/lib/Ord";
import type { Ordering } from "fp-ts/lib/Ordering";
import * as s from "fp-ts/lib/string";
import V from "voca";

export function concat(s1: string, s2: string): string;
export function concat(s1: string): (s2: string) => string;
export function concat(s1: string, s2o?: string): string | ((s2: string) => string) {
  return typeof s2o === "string" ? s1 + s2o : (s2: string) => s1 + s2;
}

export const deCamelify = (str: string) => V.capitalize(str.replace(/([A-Z])/g, " $1").trim());

export const underscoreToHumanReadable = flow(V.camelCase, deCamelify);

export const sortCaseInsensitive = (a: string, b: string): Ordering => {
  return s.Ord.compare(a.toLowerCase(), b.toLowerCase());
};

export const caseInsensitiveOrd: Ord<string> = fromCompare(sortCaseInsensitive);

export const stringOrToString = (a: number | string) => s.isString(a) ? a : a.toString();

/*
 * This is useful for stripping the leading whitespace of multiline strings such as within multiline `` strings.
 */
export const stripLeadingWhitespace = (str: string): string => str.replace(/^[^\S\r\n]+|[^\S\r\n]+$/gm, "");
